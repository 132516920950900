/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import { useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState, Fragment } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSvg from "../../../../assets/svg/LoadingSVG";
import ButtonStateActions from "../../../../Store/Actions/ButtonStateActions";
import http from "../../../../Store/API/HttpMethods";
import validationHelper from "../../../../utils/helpers/orderFormValidationHelper";
import toastMessages from "../../../../utils/helpers/toastMessage";
import Button from "../../../atomic/Button";
import CustomFileUploader from "../../../global/CustomFIleUploader";
import CustomInput from "../../../global/CustomInput";
import CustomPopup from "../../../global/CustomPopup";
import { InView } from "react-intersection-observer";
import scrollHelper from "../../../../utils/helpers/scrollHelper";
import { updatePhysicianSignCheck } from "../../../../Store/Actions/OnlineOrderFormActions";
import useSignCheckbox from "../../../../utils/customHooks/showSignCheckbox";
import useEnableLOMN from "../../../../utils/customHooks/enableLOMN";
import NotificationActions from "../../../../Store/Actions/NotificationsActions";
import RequiredFieldTooltip from "../../../global/RequiredFieldTooltip";

const TotalFields = {
  skippedDocs: "skippedDocs",
  pathology_report: "pathology_report",
  insurance_cards: "insurance_cards",
  patient_info_demographics: "patient_info_demographics",
  current_prescription_medications: "current_prescription_medications",
  // signed_letter: "signed_letter",
};

const AllFiles = {
  pathology_report: "pathology_report",
  insurance_cards: "insurance_cards",
  patient_info_demographics: "patient_info_demographics",
  current_prescription_medications: "current_prescription_medications",
  // signed_letter: "signed_letter",
};

function SupportingDocsSection({
  initialValues,
  isValid,
  isUpdate,
  values,
  setFieldValue,
  errors,
  touched,
  setFieldError,
  setValidSections,
  validateForm,
  setSelectedTab,
  selectedTab,
  isEdit,
  firstScroll,
}: any): JSX.Element {
  const docs: any[] = [
    {
      id: 1,
      name: "pathology_report",
      label: "Pathology Report",
      file: null,
    },
    {
      id: 2,
      name: "insurance_cards",
      label: "Insurance Cards/Billing Face Sheet",
      file: null,
    },
    {
      id: 3,
      name: "patient_info_demographics",
      label: "Patient Info/Demographics sheet",
      file: null,
    },
    {
      id: 4,
      name: "current_prescription_medications",
      label: "Current Prescription Medications (Patient MAR)",
      file: null,
    },
    // {
    //     id: 4,
    //     name: 'signed_letter',
    //     label: 'Signed letter of medical necessity',
    //     file: null
    // },
  ];
  const { selectedPhysician, selectedOrganization, discardForm } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const { authState } = useOktaAuth();
  const { state }: any = useLocation();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [disableGenerate, setDisableGenerate] = useState<boolean>(false);
  const [letterFile, setLetterFile] = useState<any>("");
  const [generating, setGenerating] = useState<boolean>(false);
  const [generated, setGenerated] = useState<boolean>(false);
  const [initialPhysician, setInitialPhysician] =
    useState<any>(selectedPhysician);
  const [initialTest, setInitialTest] = useState<any>(null);
  const [resetFiles, setResetFiles] = useState<number>(1);
  const [resetLetter, setResetLetter] = useState(0);
  const showSignCheckbox = useSignCheckbox();
  const disableLOMN = useEnableLOMN();
  const signPermissionCheck = true;
  const [filesUploaded, setFilesUploaded] = useState<any>({
    pathology_report: false,
    insurance_cards: false,
    patient_info_demographics: false,
    signed_letter: false,
    current_prescription_medications: false,
  });
  const dispatch = useDispatch();
  const { selectedTest } = useSelector((state: any) => state.OnlineOrderForm);
  const { profile } = useSelector((state: any) => state.Profile);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    setFilesUploaded({
      pathology_report: values.pathology_report ?? false,
      insurance_cards: values.insurance_cards ?? false,
      patient_info_demographics: values.patient_info_demographics ?? false,
      current_prescription_medications:
        values?.current_prescription_medications ?? false,
      // signed_letter: values.signed_letter ?? false,
    });
    if (values.generate_letter === true) {
      setGenerated(true);
    }
    validationHelper(
      validateForm,
      setValidSections,
      values,
      TotalFields,
      "supporting documents",
      enableSubmit,
      disableSubmit
    );
  }, [
    // values?.signed_letter,
    // values?.pathology_report,
    // values?.insurance_cards,
    // values?.patient_info_demographics,
    // values?.generate_letter,
    discardForm,
  ]);
  function handleUploadStart(): void {
    disableSubmit();
    setSubmitDisabled(true);
  }
  function enableSubmit(): void {
    dispatch(ButtonStateActions.toggleSumbit(true));
    setButtonDisabled(false);
  }
  function disableSubmit(): void {
    dispatch(ButtonStateActions.toggleSumbit(false));
    setDisableGenerate(true);
    setButtonDisabled(true);
  }
  function onRemove(updatedValues: any, filename: string) {
    let anyFilePresent = Object.keys(AllFiles).some(
      (key: any) => !!updatedValues[key]
    );
    if (!anyFilePresent) {
      setFieldValue("skippedDocs", null);
      updatedValues.skippedDocs = null;
    }
    setFilesUploaded((prevState: any) => ({
      ...prevState,
      [filename]: false,
    }));
    validationHelper(
      validateForm,
      setValidSections,
      updatedValues,
      TotalFields,
      "supporting documents",
      () => {
        if (generating) return;
        return enableSubmit;
      },
      disableSubmit
    );
  }
  function onUploadEnd(updatedValues: any, filename: string): any {
    setFieldValue("skippedDocs", false);
    setSubmitDisabled(false);
    // dispatch(ButtonStateActions.toggleSaveAndExit(false));
    setFilesUploaded((prevState: any) => ({
      ...prevState,
      [filename]: true,
    }));
    setGenerating(false);

    validationHelper(
      validateForm,
      setValidSections,
      updatedValues,
      TotalFields,
      "supporting documents",
      enableSubmit,
      disableSubmit
    );
  }
  function onUploadStart() {
    setGenerating(true);
    // dispatch(ButtonStateActions.toggleSaveAndExit(true));
    disableSubmit();
  }
  function getExt(filename: string): any {
    const ext = filename?.split(".")?.pop();
    if (ext === filename) return "";
    return ext;
  }
  function hidePopup(): void {
    setShowPopup(false);
  }
  async function generateLetter(): Promise<void> {
    // || !!values?.clinician_id === false || !!selectedPhysician?.id === false || !!values?.patient_firstname === false || !!values?.patient_lastname === false || !!values?.patient_dob === false
    if (!!values?.selectedTest?.id === false) {
      setFieldValue("generate_letter", false);
      return toastMessages.info("Test is requried to generate letter!");
    }
    if (
      !!values?.patient_firstname === false ||
      !!values?.patient_lastname === false ||
      !!values?.patient_dob === false
    ) {
      setFieldValue("generate_letter", false);
      return toastMessages.info(
        "Patient information is required to generate letter!"
      );
    }

    setResetLetter((prevState) => prevState + 1);
    setFieldValue("generate_letter", true);
    setFieldValue("signed_letter", "");
    setFieldValue("signed_letterFile", null);
    setFieldValue("signed_letterUrl", "");
    setGenerating(true);
    setGenerated(false);
    onRemove(
      {
        ...values,
        signed_letter: "",
        signed_letterUrl: "",
        signed_letterFile: null,
      },
      "signed_letter"
    );
    disableSubmit();
    try {
      const body = {
        testTypeId: `${values.selectedTest.id}`,
        physicianId: isEdit ? values.clinician_id : selectedPhysician.id,
        patient: {
          firstName: values.patient_firstname,
          lastName: values.patient_lastname,
          birthDate: values.patient_dob,
        },
      };
      const result = await http.generateSignedLetter(
        "/v1/generate-letter",
        body,
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }
      );
      const { data } = result;

      if (data.path) {
        let updatedValues = { ...values };
        updatedValues.signed_letter = data.path;
        updatedValues.skippedDocs = false;
        setLetterFile((prevState: any) => (prevState === null ? "" : null));
        setFieldValue("signed_letter", data.path);
        setFieldValue("signed_letterUrl", data.url);
        setFieldValue("signed_letterFile", {
          name: data.filename,
          type: getExt(data.filename),
        });

        onUploadEnd(updatedValues, "signed_letter");
        setGenerating(false);
        setGenerated(true);
      }
      setGenerating(false);
    } catch (error: any) {
      const { response } = error;

      if (response?.data?.message) {
        toastMessages.error(
          response.data.message ?? "Error generating signed letter"
        );
      } else {
        toastMessages.error(error.message ?? "Error generating signed letter");
      }

      setFieldError("signed_letter", "Error generating signed letter");
      setFieldValue("generate_letter", false);
      setGenerating(false);
      setGenerated(false);
    }
  }
  function handleReset(): void {
    setResetLetter((prevState) => prevState + 1);
    setFieldValue("generate_letter", false);
    setFieldValue("signed_letter", null);
    setFieldValue("signed_letterFile", null);
    setGenerated(false);
    onRemove(
      { ...values, signed_letter: "", signed_letterFile: null },
      "signed_letter"
    );
    setShowPopup(false);
  }
  function handleSkip() {
    let updatedValues = {
      ...values,
      skippedDocs: true,
      pathology_report: "",
      pathology_reportFile: null,
      insurance_cards: "",
      insurance_cardsFile: null,
      patient_info_demographics: "",
      patient_info_demographicsFile: null,
      current_prescription_medications: "",
      current_prescription_medicationsFile: null,
      signed_letter: "",
      signed_letterFile: null,
      generate_letter: "",
    };
    Object.keys(updatedValues).forEach((key: any) => {
      setFieldValue(key, updatedValues[key]);
    });
    setGenerating(false);
    setValidSections((prevState: any) => [
      ...prevState,
      "supporting documents",
    ]);
    validationHelper(
      validateForm,
      setValidSections,
      updatedValues,
      TotalFields,
      "supporting documents",
      enableSubmit,
      disableSubmit
    );
  }
  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(
      inView,
      setSelectedTab,
      firstScroll,
      "supporting documents"
    );
  }
  function handleGenerate(e: any): void {
    if (!e.target.checked && !!values.signed_letter) {
      setShowPopup(true);
    } else {
      setFieldValue("generate_letter", e.target.checked);
      if (e.target.checked) {
        generateLetter();
      }
    }
  }

  function handleSOFError(error: any) {
    const errors: any = {
      noSOF: {
        type: "error",
        message: (
          <div>
            <span className="font-semibold text-royalBlue">
              Signature on File Does Not Exist:
            </span>
            <br />
            <span>
              {" "}
              The clinician selected does not have a Signature on File (SOF)
              with Castle. Please submit a SOF to be authorized to order.
            </span>
          </div>
        ),
        customFunction: () => {},
        buttons: [
          {
            onClick: () => dispatch(NotificationActions.hideNotifications()),
            title: "OK",
            type: "primary",
          },
        ],
      },
      isExpiredSOF: {
        type: "error",
        message: (
          <div>
            <span className="font-semibold text-royalBlue">
              Signature on File Expired:
            </span>
            <br />
            <span>
              {" "}
              The Signature on File for the selected clinician has expired. This
              order can be submitted but Castle must obtain an updated
              authorization.
            </span>
          </div>
        ),
        customFunction: () => {},
        buttons: [
          {
            onClick: () => dispatch(NotificationActions.hideNotifications()),
            title: "OK",
            type: "primary",
          },
        ],
      },
      sofMismatch: {
        type: "error",
        message: (
          <div>
            <span className="font-semibold text-royalBlue">
              Signature on File Test Mismatch:
            </span>
            <br />
            <span>
              {" "}
              The clinician selected does not have a Signature on File for the
              selected Castle test. This order can be submitted but an updated
              authorization must be obtained.
            </span>
          </div>
        ),
        customFunction: () => {},
        buttons: [
          {
            onClick: () => dispatch(NotificationActions.hideNotifications()),
            title: "OK",
            type: "primary",
          },
        ],
      },
    };
    // To access Object key's
    type ObjectKey = keyof typeof errors;
    const myKey = error as ObjectKey;
    toastMessages.warning(errors[myKey].message);
  }

  async function getSOF() {
    // if user is COA user
    if (profile?.roles?.includes("Collaborative Order Agreement")) return;

    //  if user is dual role and selected physician is himself
    if (
      profile?.roles?.length > 0 &&
      profile.roles.includes("Physician") &&
      selectedPhysician?.id === profile?.id
    ) {
      const errorKeys: any = {
        noSOF: "noSOF",
        isExpiredSOF: "isExpiredSOF",
        sofMismatch: "sofMismatch",
      };
      Object.keys(errorKeys).forEach((key) => {
        setFieldValue(key, false);
      });
      return;
    }

    // if user is physician only
    if (profile?.roles?.length == 1 && profile?.roles.includes("Physician"))
      return;

    let secondaryTestId = "";

    if (values?.selectedTest?.checkedIndexes.length > 1) {
      secondaryTestId = values?.selectedTest?.checkedIndexes.filter(
        (testId: number) => testId !== values?.selectedTest?.id
      );
    }

    const body = {
      organizationId: selectedOrganization?.id,
      physicianId: selectedPhysician?.id,
      primaryTest: values?.selectedTest?.id ? values?.selectedTest?.id : "",
      secondaryTest: secondaryTestId,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    };
    dispatch(ButtonStateActions.toggleSumbit(false));
    try {
      const result = await http.getSOF("v1/sof/sof-verification", body, config);
      const { statusCode, data } = result;
      if (statusCode == 200) {
        let errorType = Object.keys(data).find(
          (key: string) => data[key] === true
        );
        if (errorType) {
          handleSOFError(errorType);

          const errorKeys: any = {
            noSOF: "noSOF",
            isExpiredSOF: "isExpiredSOF",
            sofMismatch: "sofMismatch",
          };
          Object.keys(data).forEach((key) => {
            setFieldValue(errorKeys[key], data[key]);
          });
        }
      }
      dispatch(ButtonStateActions.toggleSumbit(true));
    } catch (error: any) {
      toastMessages.error(
        error?.message
          ? error.message
          : "Something went wrong while retrieving SOF data"
      );
      setFieldValue("isPhysicianSignPermission", false);
      validationHelper(
        validateForm,
        setValidSections,
        { ...values, isPhysicianSignPermission: false },
        TotalFields,
        "supporting documents",
        enableSubmit,
        disableSubmit
      );
    }
  }
  useEffect(() => {
    if (initialTest === null) {
      setInitialTest({
        id: values?.selectedTest?.id,
        checkedIndexes:
          values?.selectedTest?.checkedIndexes &&
          values?.selectedTest?.checkedIndexes?.length > 0
            ? [...values?.selectedTest?.checkedIndexes]
            : [],
      });
    } else if (initialTest?.id !== values?.selectedTest?.id) {
      if (values?.selectedTest !== null && values.isPhysicianSignPermission) {
        getSOF();
      }
      if (values?.selectedTest?.id === 8 && !!values?.pathology_report) {
        setFieldValue("pathology_report", "");
        setFieldValue("pathology_reportFile", null);
        const updatedValues = {
          ...values,
          pathology_report: "",
          pathology_reportFile: null,
        };
        onRemove(updatedValues, "pathology_report");
      } else if (!!values?.current_prescription_medications) {
        setFieldValue("current_prescription_medications", "");
        setFieldValue("current_prescription_medicationsFile", null);
        const updatedValues = {
          ...values,
          current_prescription_medications: "",
          current_prescription_medicationsFile: null,
        };
        onRemove(updatedValues, "current_prescription_medications");
      }
      setInitialTest({
        id: values?.selectedTest?.id,
        checkedIndexes:
          values?.selectedTest?.checkedIndexes &&
          values?.selectedTest?.checkedIndexes?.length > 0
            ? [...values?.selectedTest?.checkedIndexes]
            : [],
      });

      if (values?.generate_letter === true) {
        toastMessages.info(
          "You have changed the test . Please regenerate the LOMN"
        );
        handleReset();
      }
    } else if (
      initialTest?.checkedIndexes?.length !==
      values?.selectedTest?.checkedIndexes?.length
    ) {
      setInitialTest({
        id: values?.selectedTest?.id,
        checkedIndexes:
          values?.selectedTest?.checkedIndexes &&
          values?.selectedTest?.checkedIndexes?.length > 0
            ? [...values?.selectedTest?.checkedIndexes]
            : [],
      });
      if (values?.generate_letter === true) {
        toastMessages.info(
          "You have changed the test . Please regenerate the LOMN"
        );
        handleReset();
      }
    }
    if (selectedOrganization && selectedPhysician) {
      let foundOrganization = null;
      let physicianOrganizationRoles = !!selectedPhysician?.profile
        ?.organizationRoles
        ? selectedPhysician?.profile?.organizationRoles
        : !!selectedPhysician?.organizationRoles
        ? selectedPhysician?.organizationRoles
        : null;

      if (physicianOrganizationRoles) {
        if (typeof physicianOrganizationRoles === "string")
          foundOrganization = JSON?.parse(physicianOrganizationRoles)[
            selectedOrganization?.id
          ];
        else
          foundOrganization =
            physicianOrganizationRoles[selectedOrganization?.id];
      }
      if (
        foundOrganization &&
        foundOrganization?.roles?.includes("Physician") &&
        selectedPhysician?.id === profile?.id
      ) {
        setFieldValue("isPhysicianSignPermission", true);
        dispatch(updatePhysicianSignCheck(true));
      }
    }

    if (
      initialPhysician?.id !== selectedPhysician?.id &&
      localStorage.getItem("discardLetter") === "true"
    ) {
      if (values.generate_letter) {
        // generateLetter()
        handleReset();
      }
      setFieldValue("isPhysicianSignPermission", false);
      dispatch(updatePhysicianSignCheck(false));
      setInitialPhysician(selectedPhysician);
    } else {
      setFieldValue(
        "isPhysicianSignPermission",
        values?.isPhysicianSignPermission
      );
      dispatch(
        updatePhysicianSignCheck(
          values?.isPhysicianSignPermission ? true : false
        )
      );
    }

    if (selectedPhysician?.sofs?.length === 0 && !!state?.orderValue) {
      setFieldValue("isPhysicianSignPermission", false);
      dispatch(updatePhysicianSignCheck(false));
    }
    if(!!values?.selectedTest?.id === false){
      setFieldValue("isPhysicianSignPermission", false);
      dispatch(updatePhysicianSignCheck(false));
    }
  }, [
    profile,
    selectedPhysician?.id,
    state?.orderValue,
    localStorage.getItem("discardLetter"),
    values?.selectedTest?.id,
    selectedTest?.checkedIndexes?.length,
  ]);

  return (
    <InView
      as={"div"}
      // className={'scroll-mt-36'}
      className={
        selectedTab === "select test"
          ? "scroll-my-64 tab:scroll-my-72"
          : "scroll-my-36"
      }
      id="supporting documents"
      onChange={handleMouseScroll}
      threshold={window.innerWidth < 1280 ? 0.5 : 0.8}
    >
      <div className="pb-14 flex flex-col">
        <CustomPopup
          buttons={[]}
          hidePopup={hidePopup}
          handleOk={handleReset}
          visible={showPopup}
          title={"Generate"}
        >
          <p className="mt-5 text-royalBlue">
            Selecting Generate will discard the file that you have uploaded.
          </p>
        </CustomPopup>
        <p className="text-royalBlue mb-2">
          Please upload the relevant documentation below. Maximum file size
          limit is 50 MB. You can also click on the file icon after uploading to
          review the respective file.
        </p>

        <p className="text-royalBlue mb-2">
          Note:{" "}
          {selectedTest && selectedTest.id !== 8
            ? " Pathology reports and insurance "
            : " Insurance "}
          {selectedTest && selectedTest?.id == 1
            ? "information will be required to process the order. Provide the endoscopy report if available."
            : "information will be required to process the order."}
        </p>
        <div className="justify-end items-end flex flex-col">
          <div className="w-full focus:outline-primary p-5 my-5 shadow-sm border border-[0.5px] rounded-md flex flex-col hover:border-primary hover:border">
            <div className="flex justify-between items-center w-full">
              <p className="text-royalBlue font-medium text-base">
                Upload Documents
              </p>
              {!!values?.signed_letter ||
              !!values?.insurance_cards ||
              !!values?.pathology_report ||
              !!values?.patient_info_demographics ||
              !!values?.current_prescription_medications ||
              !!values?.generate_letter ? (
                ""
              ) : (
                <Button
                  title={"Skip"}
                  btnType="primary"
                  className={"shadow-sm"}
                  onClick={handleSkip}
                  disabled={generating}
                />
              )}
            </div>
            <div className={`flex flex-wrap`}>
              {docs
                .filter((item: any) => {
                  if (selectedTest?.id === 8 && item.name == "pathology_report")
                    return false;

                  if (selectedTest?.id == 8) {
                    return true;
                  }
                  return item.name !== "current_prescription_medications";
                })
                .map((item) => (
                  <CustomFileUploader
                    key={item.name}
                    label={item.label}
                    id={item.name}
                    values={values}
                    isEdit={isEdit}
                    name={item.name}
                    onUploadStart={onUploadStart}
                    onRemove={(updatedValues: any) =>
                      onRemove(updatedValues, item.name)
                    }
                    onUploadEnd={(updatedValues: any) =>
                      onUploadEnd(
                        { ...updatedValues, skippedDocs: false },
                        item.name
                      )
                    }
                    errors={errors}
                    reset={resetFiles}
                    touched={touched}
                    setFieldError={setFieldError}
                    setFieldValue={setFieldValue}
                    placeholder={""}
                    viewStyling={`mt-2`}
                    required={false}
                  />
                ))}
            </div>
          </div>
        </div>

        <div className="focus:outline-primary p-5 my-5 shadow-sm border border-[0.5px] rounded-md flex flex-col hover:border-primary hover:border">
          <p className="text-royalBlue font-medium text-base">
            Additional Notes
          </p>
          <CustomInput
            id="additional_notes"
            name="additional_notes"
            label="Notes"
            required={false}
            isTextArea={true}
            placeholder={"Enter notes"}
            value={values.additional_notes}
            errors={errors}
            touched={touched}
            onChange={(e: any) => {
              setFieldValue("additional_notes", e.currentTarget.textContent);
            }}
            // className={'-mb-5'}
          />
        </div>
        {showSignCheckbox && (
          <div className="flex bg-[#F9F9FA] border-2 shadow-sm rounded-md px-6 py-6">
            <RequiredFieldTooltip
              tooltipText={
                !!values?.selectedTest?.id === false
                  ? "Please select test"
                  : profile?.roles?.includes("Collaborative Order Agreement")
                  ? "Order Agreement is required to submit order"
                  : "Sign permission is required to submit order"
              }
              required={
                (profile?.roles?.length == 1 &&
                  profile?.roles?.includes("Physician") &&
                  selectedTest?.id == 8 &&
                  profile?.id !== selectedPhysician?.id) ||
                profile?.roles?.includes("Collaborative Order Agreement") ||
                !!values?.selectedTest?.id === false
              }
            >
              <CustomInput
                id={"isPhysicianSignPermission"}
                key={"isPhysicianSignPermission"}
                name={"isPhysicianSignPermission"}
                signPermissionCheck={signPermissionCheck}
                label={
                  values?.selectedTest?.id === 8
                    ? `By my signature below, I, the healthcare provider, authorize performance of NeuroIDgenetix 902 and indicate that the purpose of the test(s), the procedures, the benefits and risks involved have been explained to the patient and patient informed consent has been obtained in accordance with state and local laws. I attest that this patient has a diagnosis for which pharmacologic therapy is reasonable and necessary, and that the drug or drugs that I intend to prescribe are reasonable and necessary for the treatment of this patient's diagnosis. I have made an initial personalized decision for this patient based on the patient's non-genetic factors. I have a record of what drug(s) is/are being considered and for what indication(s) to ensure the test(s) performed is reasonable and necessary. The test results will be utilized by me to determine a dose change to the patient's current medication and/or prescribe a new medication. I have informed the patient that Castle Biosciences, Inc. may be an out-of-network provider under their insurance plan and that I do not have any financial interest in Castle Biosciences, Inc.`
                    : profile?.roles?.includes(
                        "Collaborative Order Agreement"
                      ) && selectedPhysician?.id !== profile?.id
                    ? `By selecting this checkbox, I hereby acknowledge, that I will be submitting this order on behalf of the clinician's collaborative ordering agreement.`
                    : "By selecting this checkbox, I hereby acknowledge, that I will be submitting this order on behalf of the clinician using the wet signature on file."
                }
                checked={values.isPhysicianSignPermission}
                required={
                  (profile?.roles?.length == 1 &&
                    profile?.roles?.includes("Physician") &&
                    selectedTest?.id == 8) ||
                  profile?.roles?.includes("Collaborative Order Agreement")
                }
                disabled={
                  (disableLOMN &&
                    !profile?.roles?.includes(
                      "Collaborative Order Agreement"
                    )) ||
                  !!values?.selectedTest?.id === false
                }
                showToolTip={
                  !(
                    selectedPhysician?.sofs?.length > 0 &&
                    profile?.roles?.includes("Physician") &&
                    selectedTest?.id == 8
                  ) &&
                  !profile?.roles?.includes("Collaborative Order Agreement") &&
                  !(
                    profile?.roles?.includes("Office Manager") &&
                    selectedPhysician?.sofs?.length > 0
                  ) && // added to remove blockage from OM ordering even when sof exists of clinician
                  !(
                    selectedTest?.id === 8 &&
                    profile?.id === selectedPhysician?.id
                  ) // disabled when test id is 8 and profile?.id is same as of selectedPhysician id
                }
                placeholder={""}
                errors={errors}
                onChange={(e: any) => {
                  setFieldValue("isPhysicianSignPermission", e.target.checked);
                  dispatch(updatePhysicianSignCheck(e.target.checked));
                  if (e.target.checked) getSOF();
                }}
                type="checkbox"
                touched={touched}
                checkboxContainerStyle={
                  values?.selectedTest?.id === 8
                    ? `!items-start justify-start`
                    : ""
                }
                checkBoxTextStyling={values?.selectedTest?.id !== 8}
                labelClassName={
                  values?.selectedTest?.id === 8 ? "font-semibold" : ""
                }
              />
            </RequiredFieldTooltip>
          </div>
        )}
      </div>
    </InView>
  );
}

export default SupportingDocsSection;
