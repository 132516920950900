/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import LoginProcessIndicator from "../../components/global/LoadingIndicators/LoginProcessIndicator";
import { useHistory } from "react-router-dom";
// import { useNavigate } from 'react-router-dom'

function RedirectEmail(): JSX.Element {
  const { oktaAuth, authState } = useOktaAuth();
  // const navigate = useNavigate()
  const history = useHistory();

  useEffect(() => {
    if (authState === null) {
      oktaAuth
        .signInWithRedirect({ originalUri: "/login-process" })
        .then((success: any) => 
        console.log(success))
        .catch((error: any) => {
          // console.log(error);
          history.replace("/");
          // navigate('/', { replace: true })
        });
    }
  }, []);
  return <LoginProcessIndicator />;
}

export default RedirectEmail;
