import * as actions from "./types";
import http from "../API/HttpMethods";

const fetchSuffix = (options: actions.ActionArgs) => async (dispatch: any) => {
 const { onSuccess, onError } = options;
 dispatch({ type: actions.FETCHING_SUFFIX_DATA });
 try {
  const response = await http.getSuffix("/v1/suffixes");
  const { statusCode, error, data } = response;
  if (error && onError != null) {
   dispatch({ type: actions.FETCHING_SUFFIX_ERROR });
   return onError();
  }
  if (statusCode == 200) {
   dispatch({ type: actions.FETCHING_SUFFIX_SUCCESS, payload: data });
  }
  return data;
 } catch (error) {
  // console.log(error)
  dispatch({ type: actions.FETCHING_SUFFIX_ERROR });
 }
};
const fetchSpeciality =
 (options: actions.ActionArgs) => async (dispatch: any) => {
  const { onSuccess, onError } = options;
  dispatch({ type: actions.FETCHING_SPECIALITY_DATA });
  try {
   const response = await http.getSpeciality("/v1/specialties");
   const { statusCode, error, data } = response;
   if (error && onError != null) {
    dispatch({ type: actions.FETCHING_SPECIALITY_ERROR });
    return onError();
   }
   if (statusCode == 200) {
    dispatch({ type: actions.FETCHING_SPECIALITY_SUCCESS, payload: data });
   }
   return data;
  } catch (error) {
   // console.log(error)
   dispatch({ type: actions.FETCHING_SPECIALITY_ERROR });
  }
 };

const SuffixAndSpecialityActions = {
 fetchSuffix,
 fetchSpeciality,
};

export default SuffixAndSpecialityActions;
