import * as React from 'react'
import { SVGProps } from 'react'

const FormTabCheck = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? 15.5} height={props.height ?? 15.5} {...props}>
    <g
      data-name="Component 6354 \u2013 2"
      transform="translate(.75 .75)"
      stroke={props.stroke ?? '#d0d4db'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <circle data-name="Ellipse 588" cx={7} cy={7} r={7} fill={props.fill ?? 'none'} />
      <path data-name="Path 5122" d="m4 7 2 2 4-4" stroke={props.isvalid} fill={props.fill ?? 'rgba(0,0,0,0)'} />
    </g>
  </svg>
)

export default FormTabCheck
