/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import { BlockBlobClient } from '@azure/storage-blob'
import { useOktaAuth } from '@okta/okta-react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import CloseIcon from '../../assets/svg/CloseIcon'
import FileIcon from '../../assets/svg/FileIcon'
import LoadingSvg from '../../assets/svg/LoadingSVG'
import http from '../../Store/API/HttpMethods'
import toastMessages from '../../utils/helpers/toastMessage'
import Button from '../atomic/Button'
import FileDetail from './FileDetail'
import RequiredFieldTooltip from './RequiredFieldTooltip'

function CustomFileUploader({ label, name, id, sofFileUploader , approvalsChooseFileButtonStyle, required, errors, touched, setFieldValue, values, containerStyle, buttonStyle, disabled, setFieldError, reset, onUploadEnd, onUploadStart, isEdit, withoutOkta, setFiles, onRemove, currentFile }: any): JSX.Element {
  const { authState } = useOktaAuth()
  const [uploading, setUploading] = useState<boolean>(false)
  const { discardForm } = useSelector((state: any) => state.OnlineOrderForm)
  const inputRef = useRef<any>()
  const [file, setFile] = useState<any>(null)

  useEffect(() => {
    inputRef.current.value = ''
    setFile(currentFile)
  }, [currentFile])

  // useEffect(() => {
  //   handleRemove()
  // }, [reset, discardForm])

  useEffect(() => {
    if (isEdit && values[name]) {
      setFile({ name: values[name], type: getExt(values[name]) })
      setFieldValue(name, values[name])
      setFieldValue(name + "File", { name: values[name], type: getExt(values[name]) })
    } else if (!!values[name + "File"] && (values[name] !== null || values[name] !== '')) {
      setFile(values[name + "File"])
      setFieldValue(name, values[name])

      if (name === 'signed_letter' && values.generate_letter === true) {
        setFieldValue(name, values[name])
      }

    } else {
      if (isEdit && values?.skippedDocs === null)
        handleRemove()
    }
  }, [discardForm, reset])

  function handleRemove(): void {
    setFile(null)
    setFieldValue(name, null)
    if (setFiles)
      setFiles((prevState: any) => prevState.filter((file: any) => file.orderId !== name))
    setFieldValue(name + "File", null)
    let updatedValues = { ...values }
    updatedValues[name] = null

    if (onRemove)
      onRemove && onRemove(updatedValues)
    else
      onUploadEnd(updatedValues)
    inputRef.current.value = ''
  }

  function handleUpload(e: any): void {
    inputRef?.current?.click()
  }
  function getExt(filename: string): any {
    const ext = filename?.split('.')?.pop()
    if (ext === filename) return ''
    return ext
  }

  const validateFile = (ext: any) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'png', 'jpg', 'jpeg', 'doc', 'docm', 'dot', 'docx', 'pdf']
    if (!validTypes.includes(ext)) {
      // alert('Invalid file format')
      toastMessages.error(`Invalid file format`)
      return false
    }
    return true
  }
  function handleChange(e: any): any {
    e.preventDefault()
    e.stopPropagation()
    const file = e?.target?.files[0]

    const sizeInMB = file.size / 1024 / 1024
    if (sizeInMB > 50) {
      setFieldError(name, 'File size limit is being exceeded')
      return e.target.value = ''
    }
    if (validateFile(getExt(file.name)?.toLowerCase())) {
      setFile(file)
      getPresignedUrlAndUpload(file);
    }
  }
  async function handleDrop(event: any) {
    if (file) return
    event.preventDefault()
    event.stopPropagation()
    const files = event.dataTransfer.files
    const sizeInMB = files[0].size / 1024 / 1024

    if (files?.length > 1) return toastMessages.error('Please select a single file')

    if (sizeInMB > 50) {
      setFieldError(name, 'File size limit is being exceeded')
      return event.target.value = ''
    }
    if (validateFile(getExt(files[0]?.name)?.toLowerCase())) {
      setFile(files[0])
      getPresignedUrlAndUpload(files[0]);
    }
  }


  async function uploadToAzure(url: string, filePath: string, inputFile: any): Promise<void> {

    const blockBlobClient = new BlockBlobClient(url)

    try {
      const response = await blockBlobClient.uploadData(inputFile, { blobHTTPHeaders: { blobContentType: inputFile.type } })
      if (response) {
        let updatedValues = { ...values }
        updatedValues[name] = filePath

        setFieldValue(name, filePath)
        setUploading(false)
        setFieldValue(`${name}File`, inputFile)
        setFieldValue(`${name}Url`, url)
        if (setFiles)
          setFiles((prevState: any) => [...prevState, { orderId: name, filePath }])
        onUploadEnd && onUploadEnd(updatedValues)
      }
    } catch (error: any) {
      setUploading(false)
      toastMessages.error(`${inputFile.name} uploading failed`)
    }
  }
  async function getPresignedUrlAndUpload(inputFile: any): Promise<void> {
    if (!inputFile) return
    if (uploading) return

    onUploadStart && onUploadStart()
    setUploading(true)
    try {
      const extension = getExt(inputFile.name)
      const response = await http.getPresignedUrl(`v1/pre-signed-url/documents/${inputFile?.name.replace(/ /g, "_")}`, { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } })
      const { url, filepath } = response

      if (url) { return await uploadToAzure(url, filepath, inputFile) }

      // onUploadEnd && onUploadEnd()
    } catch (error: any) {
      setUploading(false)
      if (error.response) {
        toastMessages.error(error.response.data.message)
      } else {
        toastMessages.error(error.message)
      }
    }
    setUploading(false)
  }
  return <div className={`mt-5 ${approvalsChooseFileButtonStyle && 'w-full'} flex flex-col ${sofFileUploader && 'px-1 mx-12 max-w-[180px]'} ${containerStyle}`}
    onDragOver={e => e.preventDefault()}
    onDragEnter={e => e.preventDefault()}
    onDragLeave={e => e.preventDefault()}
    onDrop={handleDrop}
  >
    <RequiredFieldTooltip required={required} tooltipText={'Required Field'}>
      <label
        htmlFor={id}
        className={`${sofFileUploader ? 'text-[#647188] text-sm mb-4':'text-xs text-royalBlue'} font-normal ${label === 'Current Prescription Medications (Patient MAR)' && 'ml-5'} ${label === 'Patient Info/Demographics sheet' && 'ml-5'} ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''}`}>
        {label}
      </label>
    </RequiredFieldTooltip>
    <div className='mt-auto'>
    {file
      ? <FileDetail handleRemove={handleRemove} approvalsChooseFileButtonStyle={approvalsChooseFileButtonStyle} sofFileUploader={sofFileUploader} disabled={disabled} file={file} url={values[name + "Url"] ?? ''} hideRemove={uploading} uploading={uploading} />
      : <Button
        btnType="secondary"
        disabled={disabled}
        title={'Choose file'}
        className={`!text-sm mr-5 ${label === 'Current Prescription Medications (Patient MAR)' && 'ml-5'} ${sofFileUploader ? 'bg-white my-3':'mt-1'} ${label === 'Patient Info/Demographics sheet' && 'ml-5'}  focus:border-[#647188] outline-[#647188] ${buttonStyle ?? ''}`}
        onClick={handleUpload}
      />}
      </div>

    <input
      type='file' id='file'
      name={name}
      // accept="image/*,.doc,.pdf"
      accept=".png, .jpeg, .pdf, .doc"
      ref={inputRef}
      // onChange={onChange}
      onChange={handleChange}
      style={{ display: 'none' }} />
    {errors && errors[name] && errors[name].includes('null') === false && (
      <p className="text-xs text-requiredRed mt-1">{errors[name]}</p>
    )}
  </div>
}
export default CustomFileUploader
