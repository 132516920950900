import { Form, Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import CustomInput from "../global/CustomInput"
import { useEffect, useState } from "react"
import Button from "../atomic/Button"
import * as Yup from "yup";
import { cityStateHelper } from "../../utils/helpers/cityStateHelper"
import dateFormatter from "../../utils/helpers/dateFormatter"

const validationSchemas =
  Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required!")
      .max(100, "First Name must be no longer than 100 characters"),
    lastName: Yup.string()
      .required("Last Name is required!")
      .max(100, "Last Name must be no longer than 100 characters"),
    email: Yup.string().email("Email ID is invalid").required("Email ID is required!"),
    address: Yup.string()
      .required("Address is required!")
      .test(
        "no-empty-blank-space",
        "Address cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ).max(35, 'Address should not be more than 35 characters'),
    city: Yup.string()
      .required("City is required!")
      .test(
        "no-empty-blank-space",
        "City cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    state: Yup.string()
      .required("State is required!")
      .test(
        "no-empty-blank-space",
        "State cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    zipCode: Yup.string()
      .min(5, "Zip code length must equal to 5")
      .max(5, "Zip code length must equal to 5")
      .matches(/^[0-9]+$/, "Zip code must be numbers").required('Zip Code is required!'),
    phone: Yup.string().test("phone-validation", "Phone must contain 10 digits with the following format 000-000-0000", (value) => {
      if (!value) {
        return true;
      }
      return /\d{3}-\d{3}-\d{4}/.test(value);
    }).required('Phone is required!'),
    shipmentDate: Yup.string()
      .test(
        "custom-date-validator",
        (value: string | undefined) => !!dateFormatter(value)
      )
      .required("Shipment Date is required!"),
  })


function RenderFields({ isCompletion, submitting, isPage, initialValues, setFieldValue, reset, errors, touched, setImage, isValid, values, orgId, validateForm, submitForm, checkedIdTests, ...rest }: any) {
  const dispatch = useDispatch()
  const { data: states, error } = useSelector((state: any) => state.StateAndCity)
  const date = new Date()
  const maxDate = date
  const [cities, setCities] = useState([])
  const [isError, setError] = useState<boolean>(false)
  useEffect(() => {

    if (values && values?.state) {
      const foundState = states.find((state: any) => `${state.name} ${state.state_code}`?.includes(values.state))
      if (foundState) {
        setCities(foundState?.cities)
      }
      setFieldValue('stateValue', foundState?.name)
    }
  }, [])


  function handleChange(name: string, type: 'event' | 'val', data: any, setFieldValue: any) {
    const updatedValues = { ...values }
    if (type == 'event') {
      setFieldValue(name, data.target.value)
      updatedValues[name] = data.target.value
    } else {
      if (name == 'state') {
        updatedValues[name] = data
        setFieldValue(name, data)
        cityStateHelper(states, setCities, data, values, setFieldValue, 'city')
        setFieldValue('stateValue', states.find((state: any) => state?.state_code === data)?.name ?? data)
        updatedValues.city = ''
      } else {
        updatedValues[name] = data
        setFieldValue(name, data)
      }
    }

    validationSchemas
      .validate(updatedValues, { abortEarly: false })
      .then((res) => {
        // Validation succeeded, set isDisable to false
        setError(false)

      })
      .catch((errors: any) => {
        // Validation failed, set isDisable to true
        setError(true)
      });
  }
  return <div className={`w-[100%]`}>
    <h3 className="text-royalBlue font-semibold text-sm py-3">Shipper Information</h3>
    <p className="text-royalBlue text-sm">Please enter shipper information below</p>

    <div className="flex justify-between">
      <CustomInput
        id={'firstName'}
        key={'firstName'}
        name={'firstName'}
        label={'First Name'}
        isInternal
        value={values?.firstName}
        onChange={(val: any) => handleChange('firstName', 'event', val, setFieldValue)}
        required
        placeholder={'Enter first name'}
        errors={errors}
        touched={touched}
      />
      <CustomInput
        id={'lastName'}
        key={'lastName'}
        name={'lastName'}
        label={'Last Name'}
        isInternal
        value={values?.lastName}
        onChange={(val: any) => handleChange('lastName', 'event', val, setFieldValue)}
        className="ml-2"
        required
        placeholder={'Enter last name'}
        errors={errors}
        touched={touched}
      />
    </div>

    <div className="flex justify-between">
      <CustomInput
        id={'email'}
        key={'email'}
        name={'email'}
        label={'Email'}
        required
        placeholder={'Enter your email'}
        value={values.email}
        errors={errors}
        isInternal
        onChange={(val: any) => handleChange('email', 'event', val, setFieldValue)}
        touched={touched}
      />
      <CustomInput
        id={'phone'}
        key={'phone'}
        name={'phone'}
        label={'Phone'}
        required
        placeholder={'000-000-0000'}
        type={'tel'}
        value={values.phone}
        // onChange={(val: any) => setFieldValue('phone', val, true)}
        isInternal
        onChange={(val: any) => handleChange('phone', 'event', val, setFieldValue)}
        errors={errors}
        touched={touched}
        className={'ml-2'}
      />
    </div>
    <CustomInput
      id={'address'}
      key={'address'}
      name={'address'}
      label={'Address'}
      required
      placeholder={'Enter street address'}
      value={values.address}
      errors={errors}
      isInternal
      onChange={(val: any) => handleChange('address', 'event', val, setFieldValue)}
      touched={touched}
    />
    <div className="flex justify-between">
      <CustomInput
        id={'state'}
        key={'state'}
        name={'state'}
        label={'State/Province'}
        isInternal
        required
        placeholder={'Enter state'}
        suggestions={!error && states.length > 0 ? states.map((state: any) => ({ label: state.name, value: state.state_code })) : []}
        onChange={states && states.length > 0 ? (val: any) => handleChange('state', 'val', val, setFieldValue) : (val: any) => handleChange('state', 'event', val, setFieldValue)}
        value={values.stateValue ? values.stateValue : !!values.state && states && states?.length > 0 ? states?.find((state: any) => `${state?.name} ${state?.state_code}`?.includes(values?.state))?.name : values.state}
        errors={errors}
        touched={touched}
      />
      <CustomInput
        id={'city'}
        key={'city'}
        name={'city'}
        label={'City'}
        required
        placeholder={'Enter city name'}
        suggestions={cities && cities.length > 0 ? cities.map((city: any) => ({ label: city.name, value: city.name })) : []}
        value={values.city}
        errors={errors}
        className="ml-2"
        isInternal
        touched={touched}
        onChange={cities && cities.length > 0 ? (val: any) => handleChange('city', 'val', val, setFieldValue) : (val: any) => handleChange('city', 'event', val, setFieldValue)}
      />
    </div>
    <div className="flex justify-between">

      <CustomInput
        id={'zipCode'}
        key={'zipCode'}
        name={'zipCode'}
        label={'Zip Code'}
        required
        placeholder={'Enter zip code'}
        value={values.zipCode}
        errors={errors}
        isInternal
        onChange={(val: any) => handleChange('zipCode', 'event', val, setFieldValue)}
        className={'mr-2'}
        touched={touched}
      />
      <CustomInput
        id={'shipmentDate'}
        key={'shipmentDate'}
        name={'shipmentDate'}
        label={'Shipment Date'}
        isInternal
        value={values?.shipmentDate}
        max={new Date(maxDate)}
        type={'date'}
        // onChange={(val: any) => setFieldValue('shipmentDate', val)}
        onChange={(e: any) => handleChange('shipmentDate', 'val', e, setFieldValue)}
        required
        placeholder={'MM/DD/YYYY'}
        errors={errors}
        className={'relative mr-2'}
        touched={touched}
      />
    </div>

    {checkedIdTests?.includes(4) &&
      <div className="flex flex-col">
        <p className="text-royalBlue my-2 text-sm">
          You have chosen the UM test option, please confirm if this shipment contains FNAB (Fine-needle aspiration biopsy) samples.
        </p>
        <div>
          <CustomInput
            id={'sampleUM'}
            key={'sampleUM'}
            name={'sampleUM'}
            label={'Yes, the shipment contains samples of FNAB sample(s).'}
            isInternal
            value={values?.sampleUM}
            type='checkbox'
            // checked={values?.sampleUM === true}
            onChange={(e: any) => setFieldValue('sampleUM', e.target.checked)}
            required={false}
            placeholder=""
            errors={errors}
            className={'text-xl'}
            touched={touched}
          />
        </div>
      </div>
    }
    <div className="flex justify-end">
      <Button
        btnType="primary"
        onClick={submitForm}
        className={`py-1.5 mt-3 font-semibold`}
        title={'Generate'}
        submitting={submitting}
        disabled={!isValid || submitting || isError}
      />
    </div>
  </div>
}
function GenerateLabelForm({ onSubmit, submitting, checkedIdTests }: { onSubmit: (values: any) => any, submitting: boolean, checkedIdTests?: any }): JSX.Element {
  const { selectedOrganization, selectedPathLab } = useSelector((state: any) => state.OnlineOrderForm)
  const { profile } = useSelector((state: any) => state.Profile)
  let isPLU = profile?.roles?.includes('PathLab User')
  let OrganisationORPathlab = isPLU ? selectedPathLab : selectedOrganization
  const [reset, setReset] = useState<boolean>(false)
  const { data: states, error } = useSelector((state: any) => state.StateAndCity)
  const date = new Date()
  const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`;

  function Render({ errors, touched, setFieldValue, isValid, values, ...rest }: any) {
    return <Form>
      <div
        className={'overflow-auto px-1 mb-4'}
      >
        <RenderFields
          isValid={isValid}
          values={values}
          reset={reset}
          initialValues={initialValues}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          submitting={submitting}
          checkedIdTests={checkedIdTests}
          {...rest}
        />

      </div>
    </Form>
  }

  const initialValues = {
    firstName: profile?.firstName ? profile?.firstName : '',
    lastName: profile?.lastName ? profile?.lastName : '',
    email: profile?.email ? profile?.email : '',
    address: OrganisationORPathlab?.address ? OrganisationORPathlab.address : '',
    city: OrganisationORPathlab?.city ? OrganisationORPathlab.city : '',
    state: OrganisationORPathlab?.state ? states?.find((e: any) => ((e?.state_code === OrganisationORPathlab?.state) || (e?.state === OrganisationORPathlab?.state)))?.state_code : '',
    stateValue: OrganisationORPathlab?.state?.value ? OrganisationORPathlab?.state?.value : '',
    zipCode: OrganisationORPathlab?.zipcode ? OrganisationORPathlab.zipcode : '',
    phone: OrganisationORPathlab?.phone ? OrganisationORPathlab.phone : '',
    shipmentDate: formattedDate,
    orgId: OrganisationORPathlab?.id,
    sampleUM: false
  }

  function handleReset() {
    setReset((prevState: boolean) => !prevState)
  }

  return <Formik
    // innerRef={formRef}
    initialValues={initialValues}
    validationSchema={validationSchemas}
    onSubmit={onSubmit}
    onReset={handleReset}
    validateOnMount
    // initialStatus={false}
    render={Render}
  />
}

export default GenerateLabelForm