import { Fragment, useEffect, useState } from "react";
import ShipmentsLayout from "../../../Containers/ShipmentsLayout";
import TestOrdersTable from "../../../components/TestOrders/TestOrdersTable";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotificationActions from "../../../Store/Actions/NotificationsActions";
import { motion } from 'framer-motion'
import Button from "../../../components/atomic/Button";
import CustomPopup from "../../../components/global/CustomPopup";
import GenerateLabelForm from "../../../components/organism/GenerateLabelForm";
import ActiveLabelsTable from "../../../components/organism/Shipments/ActiveLabelsTable";
import http from "../../../Store/API/HttpMethods";
import { useOktaAuth } from "@okta/okta-react";
import toastMessages from "../../../utils/helpers/toastMessage";
import SchedulePickup from "./SchedulePickup";
import PickupsTable from "../../../components/organism/Shipments/PickupsTable";
import { formRef } from "../ManageScreen";
import ProgressBar from "../../../components/molecular/ProgressBar";


interface ShipmentsHomeScreenProps {
  screen: 'unshipped-orders' | 'active-labels' | 'pick-ups' | 'home' | 'schedule-pickup'
}

interface CustomPopupProps { visible: boolean, hidePopup: Function, title: string, orderIds?: object, checkedIdTests?: object, checkedIds?: number[], refetchOrders?: Function | undefined }

function GenerateLabelPopup({ visible, hidePopup, title, orderIds, checkedIdTests, refetchOrders }: CustomPopupProps) {
  const { authState } = useOktaAuth();
  const [submitting, setSubmitting] = useState<boolean>(false)
  async function generateData(values: any) {
    setSubmitting(true)
    try {
      const { firstName, lastName, ...restValues } = values;
      const personName = `${firstName} ${lastName}`;
      const response = await http.postActiveLabels(
        "v1/fedex/generate-label",
        {
          ...restValues,
          personName,
          orderIds: orderIds,
        },
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }
      );
      const { statusCode, message } = response;
      if (response.message === 'success') {
        toastMessages.success("Label generated!");
        refetchOrders?.()
        if (response?.labelUrl) {
          window.open(response?.labelUrl, '_blank');
        }
        hidePopup()
      }
      setSubmitting(false)
    } catch (error: any) {
      if (error?.response?.data?.errors[0]?.message) {
        toastMessages.error(error?.response?.data?.errors[0]?.message)
      } else {
        toastMessages.error(error?.message)
      }
      setSubmitting(false)
    }
  }
  return <CustomPopup
    visible={visible}
    hideButtons
    hidePopup={hidePopup}
    title={title}
    containerClass={"py-2"}
    generateLabel={'ml-1'}
    contentContainerClass={"w-[40%] overflow-auto rounded-xl"}
    submitting={submitting}
  >
    <GenerateLabelForm onSubmit={(values) => {
      const { stateValue, ...valuesWithoutStateValue } = values;
      generateData(valuesWithoutStateValue)

    }} submitting={submitting} checkedIdTests={checkedIdTests} />
  </CustomPopup>
}

function AssociateLabelsPopup({ visible, hidePopup, title, checkedIds, refetchOrders }: CustomPopupProps) {
  return <CustomPopup
    visible={visible}
    hideButtons
    hidePopup={hidePopup}
    title={title}
    containerClass={"py-2"}
    contentContainerClass={"w-4/5 overflow-auto rounded-xl"}
  >
    <ActiveLabelsTable
      showActionsColumn
      className="mt-5"
      checkedIds={checkedIds ? checkedIds : []}
      hideViewOrder={true}
      refetchOrders={refetchOrders}
    />
  </CustomPopup>
}
function ActionsHeader({ associateExistingLabel, generateNewLabel, buttonsDisabled, screen, scheduleDisabled, submittingSchedule }: { associateExistingLabel: (e: React.FormEvent<HTMLButtonElement>) => any, generateNewLabel: (e: React.FormEvent<HTMLButtonElement>) => any, buttonsDisabled: boolean, screen: string, scheduleDisabled: boolean, submittingSchedule: boolean }) {
  const history = useHistory()
  return <motion.div
    key={'null'}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    {(screen === 'schedule-pickup' || screen === 'pick-ups') ?
      <Button
        btnType="primary"
        onClick={() => {
          if (screen === 'schedule-pickup') return formRef?.current?.submitForm()
          history.push('schedule-pickup')
        }}
        disabled={screen === 'schedule-pickup' ? scheduleDisabled : false}
        submitting={screen === 'schedule-pickup' ? submittingSchedule : false}
        title={'SCHEDULE A PICKUP'}
        className={'py-1.5 ml-5 my-0 font-semibold'}
        showTooltip={screen === 'schedule-pickup' ? scheduleDisabled && !submittingSchedule : false}
        tooltipText={'Please fill all the required fields.'}
      /> :
      <Fragment>
        <Button
          btnType="secondary"
          onClick={associateExistingLabel}
          disabled={buttonsDisabled}
          place={"left"}
          title={'ASSOCIATE EXISTING LABEL'}
          className={'py-1.5 ml-5 my-0 font-semibold'}
          showTooltip={buttonsDisabled}
          tooltipText={buttonsDisabled ? 'Please select an order first to associate a label' : ''}
        />
        <Button
          btnType="primary"
          onClick={generateNewLabel}
          disabled={buttonsDisabled}
          place={"left"}
          className={`py-1.5 ml-5 my-0 font-semibold `}
          title={'CREATE LABEL'}
          showTooltip={buttonsDisabled}
          tooltipText={buttonsDisabled ? 'Orders eligible for shipments are shown. Select 1 or more orders to start a new label' : ''}
        />
      </Fragment>
    }
  </motion.div>
}

function ShipmentsHomeScreen({ screen }: ShipmentsHomeScreenProps) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { selectedOrganization, selectedPathLab } = useSelector((state: any) => state.OnlineOrderForm)
  const [ids, setIds] = useState<string[]>([])
  const [pdfIds, setPdfIds] = useState<any[]>([])
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(true)
  const [checkedIds, setCheckedIds] = useState<any[]>([])
  const [checkedIdTests, setCheckedIdTests] = useState<any[]>([])
  const [checkedPhysicianIds, setCheckedPhysicianIds] = useState<any>([])
  const [showGeneratePopup, setShowGeneratePopup] = useState<boolean>(false)
  const [refetch, setRefetch] = useState(0)
  const [showActiveLabelsPopup, setShowActiveLabelsPopup] = useState<boolean>(false)
  const [scheduleDisabled, setScheduleDisabled] = useState<boolean>(true)
  const [submittingSchedule, setSubmittingSchedule] = useState<boolean>(false)
  const { profile } = useSelector((state: any) => state.Profile)
  let isPLU = profile?.roles?.includes('PathLab User')


  function handleAssociateLabel() {
    setShowActiveLabelsPopup(true)
  }
  function handleGenerateNewLabel() {
    setShowGeneratePopup(true)
  }

  function hideGeneratePopup() {
    setShowGeneratePopup(false)
  }
  function hideActiveLabelsPopup() {
    setShowActiveLabelsPopup(false)
  }
  const { sessionDestroyed } = useSelector((state: any) => state.Session)

  useEffect(() => {
    if (isPLU && !!selectedPathLab?.id === false && !sessionDestroyed) {
      dispatch(NotificationActions.showNotification({
        type: 'error',
        title: 'Shipments',
        message: 'Please select a path lab before proceeding',
        hideClose: true,
        customFunction() {
          history.replace("/dashboard");
        },
        buttons: [
          {
            onClick: () => {
              history.replace("/dashboard")
              dispatch(NotificationActions.hideNotifications())
            },
            title: 'Ok',
            type: "primary",
          }
        ]
      }))
      return;
    }
    if (!!selectedOrganization?.id === false && !isPLU && !sessionDestroyed) {
      dispatch(NotificationActions.showNotification({
        type: 'error',
        title: 'Shipments',
        message: 'Please select an organization before proceeding',
        hideClose: true,
        customFunction() {
          history.replace("/dashboard");
        },
        buttons: [
          {
            onClick: () => {
              history.replace("/dashboard")
              dispatch(NotificationActions.hideNotifications())
            },
            title: 'Ok',
            type: "primary",
          }
        ]
      }))
    }
    if (screen === 'home') {
      history.push('/shipments/unshipped-orders')
    }
    setCheckedIds([])
  }, [selectedOrganization?.id, selectedPathLab?.id, refetch,sessionDestroyed])

  return <ShipmentsLayout
    CustomButtons={() => (screen === 'unshipped-orders' || screen === 'pick-ups' || screen === 'schedule-pickup') &&
      <ActionsHeader
        associateExistingLabel={handleAssociateLabel}
        generateNewLabel={handleGenerateNewLabel}
        buttonsDisabled={checkedIds.length === 0}
        scheduleDisabled={scheduleDisabled || submittingSchedule}
        submittingSchedule={submittingSchedule}
        screen={screen}

      />}
  >
    {screen === 'unshipped-orders' &&
      <div className="col-span-4 xl:col-span-6 px-8">
        <p className='text-royalBlue font-medium mt-7'>Unshipped Orders</p>
        <TestOrdersTable
          screen='unshipped-orders'
          hideButtons
          className="w-full"
          setAllPdfIds={setIds}
          setButtonsDisabled={setButtonsDisabled}
          setCheckedIds={setCheckedIds}
          setCheckedIdTests={setCheckedIdTests}
          setPdfIds={setPdfIds}
          refetch={refetch}
          setCheckedPhysicianIds={setCheckedPhysicianIds}
        />
      </div>}
    {screen === 'active-labels' &&
      <div className="col-span-4 xl:col-span-6 px-8">
        <p className='text-royalBlue font-medium my-7'>Active Labels</p>
        <ActiveLabelsTable
          className="w-full"
          showActionsColumn={false}
          checkedIds={checkedIds}
          hideViewOrder={false}
          refetchOrders={undefined}
        />
      </div>}
    {screen === 'pick-ups' &&
      <div className="col-span-4 xl:col-span-6 px-8">
        <p className='text-royalBlue font-medium my-7'>Pickups</p>
        <PickupsTable
          className="w-full"
        />
      </div>}
    {screen === 'schedule-pickup' &&
      <div className="col-span-4 xl:col-span-6 px-8">
        <p className='text-royalBlue font-medium mt-7'>Pickups</p>

        <SchedulePickup
          setScheduleDisabled={setScheduleDisabled}
          setSubmitting={setSubmittingSchedule}
        />

      </div>}
    <GenerateLabelPopup
      visible={showGeneratePopup}
      hidePopup={hideGeneratePopup}
      title="Create Label"
      orderIds={checkedIds}
      checkedIdTests={checkedIdTests}
      refetchOrders={() => setRefetch(prevState => prevState + 1)}
    />
    <AssociateLabelsPopup
      visible={showActiveLabelsPopup}
      hidePopup={hideActiveLabelsPopup}
      title="Active Labels"
      checkedIds={checkedIds}
      refetchOrders={() => setRefetch(prevState => prevState + 1)}
    />
  </ShipmentsLayout>
}

export default ShipmentsHomeScreen