// Auth Action types

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

// side menu actions
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU'

// Sign up form actions

export const SET_SIGNUP_VALUES = 'SET_SIGNUP_VALUES'
export const RESET_SIGNUP_VALUES = 'RESET_SIGNUP_VALUES'
export const SET_SIGNUP_MESSAGE = 'SET_SIGNUP_MESSAGE'

// Button state actions

export const SET_BUTTON_STATE = 'SET_BUTTON_STATE'
export const RESET_BUTTON_STATE = 'RESET_BUTTON_STATE'
export const TOGGLE_SUBMIT = 'TOGGLE_SUBMIT'
export const TOGGLE_FORM_SUBMIT = 'TOGGLE_FORM_SUBMIT'
export const TOGGLE_BUTTON_VISIBILITY = 'TOGGLE_BUTTON_VISIBILITY'
export const TOGGLE_SAVE_AND_EXIT = 'TOGGLE_SAVE_AND_EXIT'
export const TOGGLE_SAVE_ADDITIONAL_CLINICIAN = 'TOGGLE_SAVE_ADDITIONAL_CLINICIAN'

// Invite user actions
export const SUBMIT_FORM = 'SUBMIT_INVITE_USER_FORM'
export const RESPONSE_SUCCESS = 'INVITE_USER_RESPONSE_SUCCESS'
export const RESPONSE_FAIL = 'INVITE_USER_RESPONSE_FAIL'
export const SET_INVITE_MEMBERS = 'SET_INVITE_MEMBERS'
export const RESET_INVITE_MEMBERS = 'RESET_INVITE_MEMBERS'

// Online order form actions
export const UPDATE_SELECTED_TEST = 'UPDATE_SELECTED_TEST'
export const RESET_SELECTED_TEST = 'RESET_SELECTED_TEST'
export const RESET_SAVE_ORDER = 'RESET_SAVE_ORDER'
export const ADD_SECTION = 'ADD_SECTION'
export const REMOVE_SECTION = 'REMOVE_SECTION'
export const TOGGLE_MODE = 'TOGGLE_MODE'
export const RESET_MODE = 'RESET_MODE'
export const SET_MODE_EDIT = 'SET_MODE_EDIT'
export const UPDATE_SELECTED_ORGANIZATION = 'UPDATE_SELECTED_ORGANIZATION'
export const UPDATE_SELECTED_PHYSICANS = 'UPDATE_SELECTED_PHYSICANS'
export const RESET_SELECTED_PHYSICIAN = 'RESET_SELECTED_PHYSICIAN'
export const FETCH_TESTS_ICD_CODES = 'FETCH_TESTS_ICD_CODES'
export const FETCH_TESTS_ICD_CODES_SUCCESS = 'FETCH_TESTS_ICD_CODES_SUCCESS'
export const FETCH_TESTS_ICD_CODES_ERROR = 'FETCH_TESTS_ICD_CODES_ERROR'
export const FETCH_PATIENTS = 'FETCH_PATIENTS'
export const FETCH_PATIENTS_SUCCESS = 'FETCH_PATIENTS_SUCCESS'
export const FETCH_PATIENTS_ERROR = 'FETCH_PATIENTS_ERROR'
export const CREATE_ONLINE_ORDER = 'CREATE_ONLINE_ORDER'
export const CREATE_ONLINE_ORDER_SUCCESS = 'CREATE_ONLINE_ORDER_SUCCESS'
export const CREATE_ONLINE_ORDER_ERROR = 'CREATE_ONLINE_ORDER_ERROR'
export const TOGGLE_CLINICAL_INFO_VALID = 'TOGGLE_CLINICAL_INFO_VALID'
export const FETCH_LABS = 'FETCH_LABS'
export const FETCH_LABS_SUCCESS = 'FETCH_LABS_SUCCESS'
export const FETCH_LABS_ERROR = 'FETCH_LABS_ERROR'
export const FETCH_LAB_BY_ID = 'FETCH_LAB_BY_ID'
export const FETCH_LAB_BY_ID_SUCCESS = 'FETCH_LAB_BY_ID_SUCCESS'
export const FETCH_LAB_BY_ID_ERROR = 'FETCH_LAB_BY_ID_ERROR'
export const SAVE_ONLINE_ORDER = 'SAVE_ONLINE_ORDER'
export const SAVE_ONLINE_ORDER_SUCCESS = 'SAVE_ONLINE_ORDER_SUCCESS'
export const SAVE_ONLINE_ORDER_ERROR = 'SAVE_ONLINE_ORDER_ERROR'
export const DISCARD_FORM = 'DISCARD_FORM'
export const FETCH_ORDER_BY_ID = 'FETCH_ORDER_BY_ID'
export const FETCH_ORDER_BY_ID_SUCCESS = 'FETCH_ORDER_BY_ID_SUCCESS'
export const FETCH_ORDER_BY_ID_ERROR = 'FETCH_ORDER_BY_ID_ERROR'
export const ADD_NOTES = 'ADD_NOTES'
export const ADD_NOTES_ERROR = 'ADD_NOTES_ERROR'
export const ADD_NOTES_SUCCESS = 'ADD_NOTES_SUCCESS'
export const UPDATE_IS_PHYSICIAN_SIGN_PERMISSION = 'UPDATE_IS_PHYSICIAN_SIGN_PERMISSION'
export const RETRY_ONLINE_ORDER = 'RETRY_ONLINE_ORDER'
export const RETRY_ONLINE_ORDER_ERROR = 'RETRY_ONLINE_ORDER_ERROR'
export const RETRY_ONLINE_ORDER_SUCCESS = 'RETRY_ONLINE_ORDER_SUCCESS'

// Organizations actions
export const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS'
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS'
export const FETCH_ORGANIZATIONS_ERROR = 'FETCH_ORGANIZATIONS_ERROR'
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
export const CREATE_ORGANIZATION_ERROR = 'CREATE_ORGANIZATION_ERROR'
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR'
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS'
export const FETCH_ORG_USERS = 'FETCH_ORG_USERS'
export const FETCH_ORG_USERS_SUCCESS = 'FETCH_ORG_USERS_SUCCESS'
export const FETCH_ORG_USERS_ERROR = 'FETCH_ORG_USERS_ERROR'
export const SET_FETCH_USERS_FALSE = 'SET_FETCH_USERS_FALSE'
export const FETCH_ORG_DETAIL = 'FETCH_ORG_DETAIL'
export const FETCH_ORG_DETAIL_SUCCESS = 'FETCH_ORG_DETAIL_SUCCESS'
export const FETCH_ORG_DETAIL_ERROR = 'FETCH_ORG_DETAIL_ERROR'
export const UPDATE_REDUX_ORG_LIST = 'UPDATE_REDUX_ORG_LIST'

// Pathlab Actions
export const FETCH_PATHLAB = 'FETCH_PATHLAB'
export const FETCH_PATHLAB_SUCCESS = 'FETCH_PATHLAB_SUCCESS'
export const FETCH_PATHLAB_ERROR = 'FETCH_PATHLAB_ERROR'
export const UPDATE_SELECTED_PATHLAB = 'UPDATE_SELECTED_PATHLAB'
export const RESET_SELECTED_PATHLAB = 'RESET_SELECTED_PATHLAB'
export const FETCH_PORTAL_USERS_BY_PATHLAB = "FETCH_PORTAL_USERS_BY_PATHLAB"
export const FETCH_PORTAL_USERS_BY_PATHLAB_ERROR = "FETCH_PORTAL_USERS_BY_PATHLAB_ERROR"
export const FETCH_PORTAL_USERS_BY_PATHLAB_SUCCESS = "FETCH_PORTAL_USERS_BY_PATHLAB_SUCCESS"

// State and city data actions
export const FETCHING_STATE_DATA = 'FETCHING_STATE_DATA'
export const FETCHING_STATE_SUCCESS = 'FETCHING_STATE_SUCCESS'
export const FETCHING_STATE_ERROR = 'FETCHING_STATE_ERROR'

// Suffix
export const FETCHING_SUFFIX_DATA = 'FETCHING_SUFFIX_DATA'
export const FETCHING_SUFFIX_SUCCESS = 'FETCHING_SUFFIX_SUCCESS'
export const FETCHING_SUFFIX_ERROR = 'FETCHING_SUFFIX_ERROR'

// Speciality
export const FETCHING_SPECIALITY_DATA = 'FETCHING_SPECIALITY_DATA'
export const FETCHING_SPECIALITY_SUCCESS = 'FETCHING_SPECIALITY_SUCCESS'
export const FETCHING_SPECIALITY_ERROR = 'FETCHING_SPECIALITY_ERROR'

// Portal user actions
export const FETCH_PORTAL_USERS = 'FETCH_PORTAL_USERS'
export const FETCH_PORTAL_USERS_ERROR = 'FETCH_PORTAL_USERS_ERROR'
export const FETCH_PORTAL_USERS_SUCCESS = 'FETCH_PORTAL_USERS_SUCCESS'
export const FETCH_PORTAL_USERS_BY_ORG = 'FETCH_PORTAL_USERS_BY_ORG'
export const FETCH_PORTAL_USERS_BY_ORG_SUCCESS = 'FETCH_PORTAL_USERS_BY_ORG_SUCCESS'
export const FETCH_PORTAL_USERS_BY_ORG_ERROR = 'FETCH_PORTAL_USERS_BY_ORG_ERROR'
export const GET_SINGLE_PORTAL_USER = 'GET_SINGLE_PORTAL_USER'
export const FETCH_PORTAL_USER_BY_ID = 'FETCH_PORTAL_USER_BY_ID'
export const FETCH_PORTAL_USER_BY_ID_SUCCESS = 'FETCH_PORTAL_USER_BY_ID_SUCCESS'
export const FETCH_PORTAL_USER_BY_ID_ERROR = 'FETCH_PORTAL_USER_BY_ID_ERROR'
export const UPDATE_PORTAL_USER = 'UPDATE_PORTAL_USER'
export const UPDATE_PORTAL_USER_ERROR = 'UPDATE_PORTAL_USER_ERROR'
export const UPDATE_PORTAL_USER_SUCCESS = 'UPDATE_PORTAL_USER_SUCCESS'
export const UPDATE_SELECTED_USER_STAUTS = 'UPDATE_SELECTED_USER_STAUTS'
export const FETCH_PORTAL_USER_OA = 'FETCH_PORTAL_USER_OA'
export const FETCH_PORTAL_USER_OA_SUCCESS = 'FETCH_PORTAL_USER_OA_SUCCESS'
export const FETCH_PORTAL_USER_OA_ERROR = 'FETCH_PORTAL_USER_OA_ERROR'
export const RESET_SELECTED_PORTAL_USER = 'RESET_SELECTED_PORTAL_USER'
export const FETCH_PROFILE_OA = 'FETCH_PROFILE_OA'
export const FETCH_PROFILE_OA_SUCCESS = 'FETCH_PROFILE_OA_SUCCESS'
export const FETCH_PROFILE_OA_ERROR = 'FETCH_PROFILE_OA_ERROR'

// Profile Actions
export const FETCH_PROFILE = 'FETCH_PROFILE'
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS'
export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR'
export const UPDATE_LOGGEDIN_USER = 'UPDATE_LOGGEDIN_USER'
export const UPDATE_LOGGEDIN_USER_SUCCESS = 'UPDATE_LOGGEDIN_USER_SUCCESS'
export const UPDATE_LOGGEDIN_USER_ERROR = 'UPDATE_LOGGEDIN_USER_ERROR'

// Notifications Actions
export const SHOW_SUCCESS_NOTIFICATION = 'SHOW_SUCCESS_NOTIFICATION'
export const SHOW_ERROR_NOTIFICATION = 'SHOW_ERROR_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'

// Get all insurance company
export const FETCHING_INSURANCE_DATA = 'FETCHING_INSURANCE_DATA'
export const FETCHING_INSURANCE_SUCCESS = 'FETCHING_INSURANCE_SUCCESS'
export const FETCHING_INSURANCE_ERROR = 'FETCHING_INSURANCE_ERROR'

// Dashboard actions
export const FETCHING_DASHBOARD_DATA = 'FETCHING_DASHBOARD_DATA'
export const FETCHING_DASHBOARD_DATA_ERROR = 'FETCHING_DASHBOARD_DATA_ERROR'
export const FETCHING_DASHBOARD_DATA_SUCCESS = 'FETCHING_DASHBOARD_DATA_SUCCESS'
export const RESET_INITIAL_CALL = 'RESET_INITIAL_CALL'

// PDF Order actions
export const CREATE_PDF_ORDER = 'CREATE_PDF_ORDER'
export const CREATE_PDF_ORDER_FAIL = 'CREATE_PDF_ORDER_FAIL'
export const CREATE_PDF_ORDER_SUCCESS = 'CREATE_PDF_ORDER_SUCCESS'

// Session

export const TOGGLE_SESSION_STATE = 'TOGGLE_SESSION_STATE'

// Sync
export const SYNCHRONIZING = 'SYNCHRONIZING';
export const SYNCHRONIZING_SUCCESS = 'SYNCHRONIZING_SUCCESS';
export const SYNCHRONIZING_ERROR = 'SYNCHRONIZING_ERROR'

// Settings
export const FETCH_SETTINGS = 'FETCH_SETTINGS'
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS'
export const FETCH_SETTINGS_ERROR = 'FETCH_SETTINGS_ERROR'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_ERROR = 'UPDATE_SETTINGS_ERROR'

//Active Tests Actions
export const FETCH_ACTIVE_TESTS = 'FETCH_ACTIVE_TESTS'
export const FETCH_ACTIVE_TESTS_ERROR = 'FETCH_ACTIVE_TESTS_ERROR'
export const FETCH_ACTIVE_TESTS_SUCCESS = 'FETCH_ACTIVE_TESTS_SUCCESS'

// Help 
export const FETCHING_HELP_DATA = 'FETCHING_HELP_DATA'
export const FETCHING_HELP_DATA_ERROR = 'FETCHING_HELP_DATA_ERROR'
export const FETCHING_HELP_DATA_SUCCESS = 'FETCHING_HELP_DATA_SUCCESS'

/* NOTIFICATIONS */

// Notification medium 
export const FETCHING_NOTIFICATION_MEDIUM = 'FETCHING_NOTIFICATION_MEDIUM'
export const FETCHING_NOTIFICATION_MEDIUM_SUCCESS = 'FETCHING_NOTIFICATION_MEDIUM_SUCCESS'
export const FETCHING_NOTIFICATION_MEDIUM_ERROR = 'FETCHING_NOTIFICATION_MEDIUM_ERROR'
// Notification type 
export const FETCHING_NOTIFICATION_TYPE = 'FETCHING_NOTIFICATION_TYPE'
export const FETCHING_NOTIFICATION_TYPE_SUCCESS = 'FETCHING_NOTIFICATION_TYPE_SUCCESS'
export const FETCHING_NOTIFICATION_TYPE_ERROR = 'FETCHING_NOTIFICATION_TYPE_ERROR'

export const UPDATE_FILTERS = 'UPDATE_FILTERS'
export const FETCHING_UNREAD_COUNT = 'FETCHING_UNREAD_COUNT'
export const FETCHING_UNREAD_COUNT_SUCCESS = 'FETCHING_UNREAD_COUNT_SUCCESS'
export const FETCHING_UNREAD_COUNT_ERROR = 'FETCHING_UNREAD_COUNT_ERROR'
export const RESET_UNREAD_COUNT = 'RESET_UNREAD_COUNT'

// Organization notification medium
export const ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM = 'ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM'
export const ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM_SUCCESS = 'ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM_SUCCESS'
export const ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM_ERROR = 'ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM_ERROR'

// User notification medium
export const USER_FETCHING_NOTIFICATION_MEDIUM = 'USER_FETCHING_NOTIFICATION_MEDIUM'
export const USER_FETCHING_NOTIFICATION_MEDIUM_SUCCESS = 'USER_FETCHING_NOTIFICATION_MEDIUM_SUCCESS'
export const USER_FETCHING_NOTIFICATION_MEDIUM_ERROR = 'USER_FETCHING_NOTIFICATION_MEDIUM_ERROR'

// User notification type
export const USER_FETCHING_NOTIFICATION_TYPE = 'USER_FETCHING_NOTIFICATION_TYPE'
export const USER_FETCHING_NOTIFICATION_TYPE_SUCCESS = 'USER_FETCHING_NOTIFICATION_TYPE_SUCCESS'
export const USER_FETCHING_NOTIFICATION_TYPE_ERROR = 'USER_FETCHING_NOTIFICATION_TYPE_ERROR'

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS'
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR'
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const UPDATE_NOTIFICATION_UNREAD_COUNT = 'UPDATE_NOTIFICATION_UNREAD_COUNT'

// Schedule pick up post
export const SCHEDULE_PICKUP = 'SCHEDULE_PICKUP' 
export const SCHEDULE_PICKUP_SUCCESS = 'SCHEDULE_PICKUP_SUCCESS'
export const SCHEDULE_PICKUP_ERROR = 'SCHEDULE_PICKUP_ERROR'
export interface ActionArgs {
  data?: any
  onSuccess?: Function
  onError?: Function
  config?: any
}
