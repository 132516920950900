import reduxActionType from "../../models/reduxActionType";
import {
 CREATE_ONLINE_ORDER,
 FETCHING_DASHBOARD_DATA,
 FETCHING_DASHBOARD_DATA_ERROR,
 FETCHING_DASHBOARD_DATA_SUCCESS,
 LOGOUT,
 RESET_INITIAL_CALL,
} from "../Actions/types";

const initialState = {
 fetching: false,
 error: false,
 isInitialCall: true,
 data: [],
 physicianId: null,
 organizationId: null,
 pathLabId: null,
};

function DashboardReducer(state = initialState, action: reduxActionType) {
 switch (action.type) {
  case FETCHING_DASHBOARD_DATA: {
   return {
    ...state,
    fetching: true,
    error: false,
    physicianId: action.payload.physicianId,
    organizationId: action.payload.organizationId,
    pathLabId: action.payload.pathLabId,
    isInitialCall: false,
   };
  }
  case FETCHING_DASHBOARD_DATA_SUCCESS: {
   return {
    ...state,
    fetching: false,
    error: false,
    data: action.payload,
   };
  }
  case FETCHING_DASHBOARD_DATA_ERROR: {
   return {
    ...state,
    fetching: false,
    error: true,
   };
  }
  case CREATE_ONLINE_ORDER: {
   return {
    ...state,
    isInitialCall: true,
   };
  }
  case RESET_INITIAL_CALL: {
   return {
    ...state,
    isInitialCall: true,
   };
  }
  case LOGOUT:
   return initialState;
  default:
   return state;
 }
}

export default DashboardReducer;
