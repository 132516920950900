import { AnimatePresence, motion } from "framer-motion"
import Header from "../components/global/Header"

function SingleElementContainer({ headerTitle, CustomButtons, children, containerClassName }: any): JSX.Element {
  return <AnimatePresence exitBeforeEnter>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Header
        isInternal
        CustomButtons={CustomButtons ? () => <CustomButtons /> : null}
        title={headerTitle}
      />
      <div className={`overflow-auto ${containerClassName ? containerClassName : ''}`}>
        {children}
      </div>

    </motion.div>
  </AnimatePresence >
}

export default SingleElementContainer