import * as React from 'react'
import { SVGProps } from 'react'

const CheckMarkSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={102} height={102} {...props}>
        <defs>
            <linearGradient
                id="a"
                x1={0.129}
                y1={0.163}
                x2={0.867}
                y2={0.875}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#31bdbf" />
                <stop offset={1} stopColor="#3f76a5" />
            </linearGradient>
        </defs>
        <g data-name="Group 14181">
            <g data-name="Component 6407 \u2013 2">
                <g data-name="Ellipse 667" stroke="#18b5b7" fill="url(#a)">
                    <circle cx={51} cy={51} r={51} stroke="none" />
                    <circle cx={51} cy={51} r={50.5} fill="none" />
                </g>
            </g>
            <g data-name="Group 13624">
                <path
                    data-name="Path 5128"
                    d="M26.435 51.152 42.964 67.68l33.057-33.057"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={10}
                />
            </g>
        </g>
    </svg>
)

export default CheckMarkSVG
