import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

function printOrder(id: string, handlePrint: any): void {
 let divToPrint: any = document.getElementById(id ?? "pdf");
 divToPrint?.classList.remove("hidden");
 handlePrint();
 divToPrint.classList.add("hidden");
}
function generateImageURL(element: any) {
 return new Promise((resolve, reject) => {
  html2canvas(element)
   .then((canvas) => {
    resolve({ url: canvas.toDataURL("image/png"), canvas });
   })
   .catch((error) => reject(error));
 });
}
function generatePDF(imgData: string, canvas: any, doc: any) {
 let imgWidth;

 // const imgWidth = 290
 // const pageHeight = 290
 // const imgWidth = 215;
 if (window.innerWidth <= 1440) {
  imgWidth = window.innerWidth * 0.182;
 } else {
  imgWidth = window.innerWidth * 0.185;
 }

 const pageHeight = 295;
 const imgHeight = (canvas.height * imgWidth) / canvas.width;
 let heightLeft = imgHeight;
 //  const doc = new jsPDF("p", "mm");
 let position = 0;
 doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 25, "SLOW");
 heightLeft -= pageHeight;
 while (heightLeft >= 0) {
  position = heightLeft - imgHeight;
  doc.addPage();
  doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 25, "SLOW");
  heightLeft -= pageHeight;
 }

 // doc.save(`${id ?? "download"}.pdf`);
 //  doc.save("download.pdf");
}
const download = (id: string, multiprint: boolean): void => {
 let multiPrintDiv = null;
 if (multiprint) {
  multiPrintDiv = document.getElementById("multiprint");
 }
 let divToPrint: any = document.getElementById(id ?? "pdf");
 multiPrintDiv?.classList.remove("hidden");
 divToPrint?.classList.remove("hidden");
 //  const { clientWidth, clientHeight } = divToPrint;
 divToPrint?.classList?.add("divToPrint");

 //  const sections = divToPrint.children[0].children;
 //  let promises: any = [];

 //  for (let i = 0; i < sections.length; i++) {
 //   promises.push(sections[i]);
 //  }

 //  Promise.all(promises.map((item: any) => generateImageURL(item))).then(
 //   (results: any) => {
 //    const parentDoc = new jsPDF("p", "mm");
 //    results.map((imageObject: any) =>
 //     generatePDF(imageObject.url, imageObject.canvas, parentDoc)
 //    );
 //    parentDoc.save('download.pdf')
 //   }
 //  );

 // eslint-disable-next-line @typescript-eslint/no-floating-promises
 html2canvas(divToPrint).then((canvas) => {
  let imgWidth;
  const imgData = canvas.toDataURL("image/png");
  // const imgWidth = 290
  // const pageHeight = 290
  // const imgWidth = 215;
  if (window.innerWidth <= 1440) {
   imgWidth = window.innerWidth * 0.182;
  } else {
   imgWidth = window.innerWidth * 0.185;
  }

  const pageHeight = 295;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  let heightLeft = imgHeight;
  const doc = new jsPDF("p", "mm");
  let position = 0;
  doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 25, "SLOW");
  heightLeft -= pageHeight;
  while (heightLeft >= 0) {
   position = heightLeft - imgHeight;
   doc.addPage();
   doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 25, "SLOW");
   heightLeft -= pageHeight;
  }
  doc.save(`${id ?? "download"}.pdf`);
 });
 if (multiprint === false) {
  divToPrint?.classList.add("hidden");
 }
 multiPrintDiv?.classList.add("hidden");
};

const pdfHelper = {
 download,
 printOrder,
};
export default pdfHelper;
