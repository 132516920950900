/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector, connect } from 'react-redux'
import { toggleSumbit } from '../../../../Store/Actions/ButtonStateActions'
import validationHelper from '../../../../utils/helpers/orderFormValidationHelper'
import CustomInput from '../../../global/CustomInput'
import { fetchInsuranceCompany } from '../../../../Store/Actions/OnlineOrderFormActions'
import toastMessages from '../../../../utils/helpers/toastMessage'
import { useOktaAuth } from '@okta/okta-react'
import { InView } from 'react-intersection-observer'
import scrollHelper from '../../../../utils/helpers/scrollHelper'
import MultiSelectDropdown from '../../../global/MultiselectDropdown'
import RequiredFieldTooltip from '../../../global/RequiredFieldTooltip'
import http from '../../../../Store/API/HttpMethods'
import WarningIcon from '../../../../assets/svg/WarningIcon'
import WarningBanner from '../../../global/WarningBanner'

const billingIct: any = {
  icd_10: 'icd_10'
}
interface ICode {
  id: number;
}

function BillingInfoSection({ submitting, loading, error, values, setFieldValue, errors, touched, setFieldError, setSelectedTab, validateForm, setValidSections, fetchInsuranceCompany, selectedTab, isUpdate, firstScroll, ...rest }: any): JSX.Element {
  const InputFields: any = {
    icd_10: 'icd_10',
    insurance: 'insurance',
    icdCodeId: 'icdCodeId',
    icn: 'icn',
    billing_phone: 'billing_phone',
    policy: 'policy',
    medicare: 'medicare',
    additionalCodes: 'additionalCodes',
    date_of_discharge: 'date_of_discharge'
  }

  const { authState } = useOktaAuth()
  const prevSelectedTestId = useRef();
  const dispatch = useDispatch()
  const { state }: any = useLocation()
  const { selectedTest, orderData } = useSelector((state: any) => state.OnlineOrderForm)
  const { insurance_company, fetchingTestsAndIcdCodes, icdCodes } = useSelector((state: any) => state.OnlineOrderForm)
  const [initiallyEntered, setInitiallyEntered] = useState<boolean>(true)
  // date of discharge max date limit
  const date = new Date()
  const dateOffset = (24 * 60 * 60 * 1000) * 1
  // const maxDate = date.setTime(date.getTime() - dateOffset)
  const maxDate = date
  // const selectedIdIcd10 = icdCodes?.find((icdCode: any) => icdCode?.value === values?.icd_10?.split(' ')?.[0])?.id
  const selectedIdIcd10 = typeof (values.icd_10) === 'number' ? values.icd_10 : icdCodes?.find((icdCode: any) => icdCode?.value === values?.icd_10?.split(' ')?.[0])?.id

  const [icdMismatch, setIcdMismatch] = useState<boolean>(false)


  function resetIctCode(): void {
    let updatedValues = { ...values }
    // Object.keys(billingIct).forEach((field: string): void => {
    setFieldValue('icd_10', '')
    setFieldValue('icdCodeId', '')
    setFieldValue('icdCodeName', '')
    setFieldValue('icdCodeValue', '')
    setFieldValue('additionalCodes', [])
    updatedValues['icd_10'] = ''
    updatedValues['icdCodeId'] = ''
    updatedValues['icdCodeName'] = ''
    updatedValues['icdCodeValue'] = ''
    updatedValues['additionalCodes'] = []
    validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
  }
  function enableSubmit(): void {
    dispatch(toggleSumbit(true))
  }
  function disableSubmit(): void {
    dispatch(toggleSumbit(false))
  }

  function handleChange(name: string, val: any): void {
    function handleNewInsurancePayloadId(insuranceData: any): void {
      if (insuranceData && val !== 'Enter new insurance') {
        setFieldValue('primaryInsuranceId', insuranceData.id)
      } else {
        setFieldValue('primaryInsuranceId', '')
      }
    }
    if (name === 'icd_10') {
      const id = icdCodes.find((icdCode: any) => icdCode?.value === val.split(' ')[0])?.id
      let additionalCodes = values.additionalCodes
      let updatedValues = { ...values }
      updatedValues[name] = val
      setFieldValue(name, val)

      if (id) {
        setFieldValue('icdCodeId', id)
        updatedValues.icdCodeId = id
        if (additionalCodes.length > 0 && values?.selectedTest?.id === 8) {
          let filteredCodes = additionalCodes.filter((addCode: any) => addCode != id)
          // updatedValues.additionalCodes = filteredCodes
          // setFieldValue('additionalCodes', filteredCodes)
          updatedValues.additionalCodes = []
        }
        setFieldValue('additionalCodes', [])
        setFieldValue('icd10CodeMismatch', false)
        setFieldValue('medicationMismatch', false)
        setFieldValue('checkMedicationsMismatch', true)

        setTimeout(() => {
          setFieldValue('checkMedicationsMismatch', false)
        }, 500)

      } else {
        setFieldValue('icdCodeId', "")
        setFieldValue('icd10CodeMismatch', false)
        setFieldValue('medicationMismatch', false)
        setFieldValue('additionalCodes', [])
        updatedValues.icdCodeId = ""
      }
      validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
    } else if (name === 'insurance') {
      const insuranceData = insurance_company.find((company: any) => company.name === val)
      const updatedValues = { ...values, primaryInsuranceId: insuranceData?.id }
      updatedValues[name] = val
      if (insuranceData && (insuranceData.id == 10 || insuranceData.id == 1)) {
        setFieldValue('medicare', '')
        setFieldValue('date_of_discharge', '')
      }
      handleNewInsurancePayloadId(insuranceData)
      setFieldValue(name, val)
      validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
    } else {
      const updatedValues = { ...values }
      updatedValues[name] = val
      setFieldValue(name, val)
      validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
    }
  }
  async function detectMismatch(id: any): Promise<void> {
    try {
      const { data } = await http.detectIcdMismatch(`/v1/test-type/icd10-verification?icd10Code=${[selectedIdIcd10, ...id].map(item => Number(item))}`, {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`
        }
      })
      const { icd10CodeMismatch } = data

      if (icd10CodeMismatch) {
        setIcdMismatch(true)
        toastMessages.warning('Multiple ICD-10 codes have been selected for the same diagnosis. These diagnoses contradict each other in duration and/or intensity. Please revise')
      } else {
        setIcdMismatch(false)
      }
      setFieldValue('icd10CodeMismatch', icd10CodeMismatch)
    } catch (error: any) {
      const message = error?.response?.data?.message ? error?.response?.data?.message : error.message
      toastMessages.error(message)
    }
  }

  function handleAdditionalCodes(item: any) {
    let additionalCodes = values.additionalCodes
    let updatedValues = { ...values }
    setFieldValue('checkMedicationsMismatch', true)
    setTimeout(() => {
      setFieldValue('checkMedicationsMismatch', false)
    }, 500)
    if (additionalCodes.length === 0) {
      additionalCodes.push(`${item.id}`)
      setFieldValue('additionalCodes', additionalCodes)
      rest?.setFieldTouched('additionalCodes')
      updatedValues.additionalCodes = additionalCodes
      detectMismatch(updatedValues?.additionalCodes)
      return validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
    }

    if (additionalCodes.find((code: any) => code == item.id)) {
      additionalCodes = additionalCodes.filter((code: any) => code != item.id)
      updatedValues.additionalCodes = additionalCodes
      setFieldValue('additionalCodes', additionalCodes)
      if (updatedValues?.additionalCodes?.length > 0) {
        detectMismatch(updatedValues?.additionalCodes)
      } else {
        setFieldValue('icd10CodeMismatch', false)
      }
      return validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
    }

    additionalCodes.push(`${item.id}`)
    setFieldValue('additionalCodes', additionalCodes)
    rest?.setFieldTouched('additionalCodes')
    updatedValues.additionalCodes = additionalCodes
    detectMismatch(updatedValues?.additionalCodes)
    return validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
  }

  useEffect(() => {
    const options = {
      onError: (message: string) => {
        toastMessages.error(message)
      },
      config: {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`
        }
      }
    }
    if (loading === false || error === false) {
      fetchInsuranceCompany(options)
    }
    if (isUpdate && !fetchingTestsAndIcdCodes) {
      let icd_10 = icdCodes.find((code: any) => code.id === values?.icdCodeId)
      let icd10Value = icd_10?.value
      let icd10Name = icd_10?.name

      if (icd_10) {
        setFieldValue('icd_10', `${icd10Value} ${icd10Name}`)
        const updatedValues = { ...values }
        updatedValues.icd_10 = `${icd10Value} ${icd10Name}`
        validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
      } else {
        validationHelper(validateForm, setValidSections, values, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
      }
      setInitiallyEntered(false)
    } else {
      validationHelper(validateForm, setValidSections, values, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
    }

  }, [fetchingTestsAndIcdCodes, values?.additionalCodes])

  useEffect(() => {
    if (isUpdate || !!state?.orderValue) {
      if (!initiallyEntered) {
        resetIctCode()
      }
    } else {
      resetIctCode()
    }

  }, [selectedTest.id, selectedTest?.checkedIndexes?.length])

  useEffect(() => {
    if (values.insurance === 'Medicare' || values.insurance === 'Medicaid' || values.insurance === 'Attach Insurance in Supporting Documents' || values.insurance === 'JN-FCSO (Medicare)' || values.insurance === 'Medicaid' || values.insurance === 'Aetna' || values.insurance === 'BCBS' || values.insurance === 'Cigna' || values.insurance === 'Humana' || values.insurance === 'Tricare' || values.insurance === 'UHC' || values.insurance === 'CGS (Medicare)' || values.insurance === 'JN-FCSO (Medicare)' || values.insurance === 'NGS (Medicare)' || values.insurance === 'Noridian (Medicare)' || values.insurance === 'Novitas (Medicare)' || values.insurance === 'Palmetto (Medicare)' || values.insurance === 'WPS (Medicare)') {
      setFieldValue('icn', '')
      validationHelper(validateForm, setValidSections, values, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
    } else if (values.insurance === 'Enter new insurance') {
      setFieldValue('icn', '')
    }
  }, [values.insurance])

  useEffect(() => {
    if (!!state?.orderValue) {
      if (values?.medicare === false || values?.medicare === '' || values?.medicare === 'NA') {
        let updatedValues = { ...values }
        updatedValues['medicare'] = ''
        setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'billing info & medicare'))
        validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
      }

      let icd_10 = icdCodes.find((code: ICode) => code.id === values?.icdCodeId)
      let icd10Value = icd_10?.value
      let icd10Name = icd_10?.name
      if (icd_10 && !!state?.orderValue) {
        setFieldValue('icd_10', `${icd10Value} ${icd10Name}`)
        const updatedValues = { ...values }
        updatedValues.icd_10 = `${icd10Value} ${icd10Name}`
      }

      if (prevSelectedTestId.current) {
        // Compare previous and current selectedTest?.id
        if (prevSelectedTestId.current !== values?.selectedTest?.id) {
          resetIctCode()
        }
      }

      // After all checks and operations, update prevSelectedTestId for the next render
      prevSelectedTestId.current = values?.selectedTest?.id;
    }
  }, [selectedTest?.id, state?.orderValue])


  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, firstScroll, 'billing info & medicare')
    if (!inView && rest.touchedSections.includes('patient info'))
      Object.keys(InputFields).forEach((key) => {
        rest.setFieldTouched(InputFields[key], true, true)
      })
  }

  function handleBlur() {
    rest.setTouchedSections((prevState: any) => [...prevState, 'billing info & medicare'])
  }

  return <InView
    id={'billing info & medicare'}
    as={'div'}
    className={selectedTab === 'select test' ? 'scroll-my-[260px] tab:scroll-my-[274px]' : 'scroll-my-36'}
    threshold={window.innerWidth <= 1280 ? 0.5 : 0.7} onChange={handleMouseScroll}>
    {values.icd10CodeMismatch && <WarningBanner warning='Multiple ICD-10 codes have been selected for the same diagnosis. These diagnoses contradict each other in duration and/or intensity. Please revise' />}
    <div>
      {values?.insurance === 'Roster/Client Bill' && <div tabIndex={4} className="p-5 my-5 border border-[#FE954F] bg-[#FFF2DC] rounded-lg shadow-sm">
        <p className="text-royalBlue font-medium text-base">Notice</p>
        <p className="text-royalBlue mt-5 text-sm">Client Bill is for contracted entities only
        </p>
      </div>}


      <div className="focus:outline-primary p-5 my-5 shadow-sm border border-[0.5px] rounded-md flex flex-col hover:border-primary hover:border">
        <p className="text-royalBlue font-medium text-base">Billing Information</p>
        <CustomInput
          id={'icd_10'}
          key={'icd_10'}
          name={'icd_10'}
          label={values?.selectedTest?.id === 8 ? 'Primary Diagnosed ICD-10.' : 'ICD-10 Code'}
          isInternal
          value={values.icd_10}
          suggestions={values.selectedTest ? icdCodes.map((icdCode: any) => ({ label: `${icdCode.value} ${icdCode.name}`, value: `${icdCode.value} ${icdCode.name}` })) : []}
          onChange={values.selectedTest ? (val: any) => handleChange('icd_10', val) : (e: any) => handleChange('icd_10', e.target.value)}
          required
          onBlurCapture={handleBlur}
          placeholder={'Select an ICD-10 Code'}
          errors={errors}
          className={'mr-5 text-xs'}
          touched={touched}
          dropDownContainerStyle={'max-h-[250px]'}
        />

        {values?.selectedTest?.id === 8 && <MultiSelectDropdown
          label='Additional Diagnosed ICD-10s. Maximum 3 allowed.'
          required={false}
          errors={errors}
          touched={touched}
          disabled={values?.icd_10 ? false : true}
          name='additionalCodes'
          id='additionalCodes'
          onOptionClick={handleAdditionalCodes}
          options={values.selectedTest ? icdCodes.filter((icdCode: any) => {
            if (values?.icdCodeId) {
              return icdCode.id != values?.icdCodeId
            }
            return true
          }).map((icdCode: any, index: number) => {
            if (values?.additionalCodes.find((item: any) => item == icdCode.id)) {
              return {
                id: icdCode.id,
                label: `${icdCode.value} ${icdCode.name}`,
                value: `${icdCode.value}`,
                selected: true
              }
            }
            return {
              id: icdCode.id,
              label: `${icdCode.value} ${icdCode.name}`,
              value: `${icdCode.value}`,
              selected: false
            }
          }) : []}
        />
        }
        <CustomInput
          id={'insurance'}
          key={'insurance'}
          name={'insurance'}
          label={'Primary Insurance Co. Name. (If Insurance Cards/Billing Face Sheet is uploaded, this section can be left blank)'}
          isInternal
          onBlurCapture={handleBlur}
          value={values.insurance}
          // suggestions={insurance_company.map((val: any) => val.name)}
          suggestions={insurance_company.map((val: any) => ({ label: val.name, value: val.name }))}
          onChange={(val: any) => handleChange('insurance', val)}
          required={false}
          placeholder={'Select an insurance company'}
          errors={errors}
          className={'mr-5'}
          touched={touched}
        />
        {values.insurance === 'Enter new insurance' &&
          <CustomInput
            id={'icn'}
            key={'icn'}
            name={'icn'}
            isInternal
            label={'Insurance Company Name'}
            value={values.icn}
            onChange={(e: any) => handleChange('icn', e.target.value)}
            // required={values.insurance === 'Enter new insurance' ?? false}
            required={false}
            placeholder={'Enter company name'}
            errors={errors}
            onBlurCapture={handleBlur}
            touched={touched}
          />
        }
        <div className="2xl:flex xl:flex lg:flex md:block justify-between items-center md:mt-8 2xl:mt-0 xl:mt-0 lg:mt-0">
          {values.insurance != 'Attach Insurance in Supporting Documents' &&
            <CustomInput
              id={'policy'}
              key={'policy'}
              name={'policy'}
              label={'Policy Number'}
              required={false}
              onBlurCapture={handleBlur}
              // required={values.insurance === 'Enter new insurance' ?? false}
              placeholder={'Enter policy number'}
              isInternal
              value={values?.policy}
              onChange={(e: any) => handleChange('policy', e.target.value)}
              errors={errors}
              touched={touched}
            />
          }
          {values.insurance != 'Attach Insurance in Supporting Documents' &&
            <CustomInput
              id={'billing_phone'}
              key={'billing_phone'}
              name={'billing_phone'}
              label={'Phone'}
              onBlurCapture={handleBlur}
              required={false}
              // required={values.insurance === 'Enter new insurance' ?? false}
              placeholder={'000-000-0000'}
              type={'tel'}
              isInternal
              onChange={(e: any) => handleChange('billing_phone', e.target.value)}
              errors={errors}
              touched={touched}
              className={'2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-0'}
            />
          }
        </div>
        {values?.selectedTest?.id !== 8 && <Fragment>
          <div className="mt-2.5">

            <RequiredFieldTooltip required={true} showLeft={true} tooltipText={'Required Field'}>
              <p className="text-royalBlue text-sm">Type of facility (where tissue was collected)

                {
                  (values.primaryInsuranceId !== 10 &&
                    values.primaryInsuranceId !== 11) &&
                  <span className="text-requiredRed ml-1">*</span>
                }
              </p>
            </RequiredFieldTooltip>
            <div className="flex mt-2.5 flex-wrap">
              <CustomInput
                id={'non_hospital'}
                key={'non_hospital'}
                name={'medicare'}
                onBlurCapture={handleBlur}
                label={'Non-Hospital'}
                isInternal
                value={'Non-Hospital'}
                type="radio"
                checked={values.medicare === 'Non-Hospital' ?? false}
                onChange={(e: any) => handleChange('medicare', e.target.value)}
                required
                placeholder={''}
                errors={errors}
                touched={touched}
              />
              <CustomInput
                id={'out_patient'}
                key={'out_patient'}
                name={'medicare'}
                onBlurCapture={handleBlur}
                label={'Hospital Outpatient'}
                isInternal
                type="radio"
                value={'Hospital Outpatient'}
                checked={values.medicare === 'Hospital Outpatient' ?? false}
                onChange={(e: any) => handleChange('medicare', e.target.value)}
                required
                placeholder={''}
                errors={errors}
                touched={touched}
              />
              <CustomInput
                id={'in_patient'}
                key={'in_patient'}
                name={'medicare'}
                onBlurCapture={handleBlur}
                label={'Hospital Inpatient'}
                isInternal
                type="radio"
                value={'Hospital Inpatient'}
                checked={values.medicare === 'Hospital Inpatient' ?? false}
                onChange={(e: any) => handleChange('medicare', e.target.value)}
                required
                placeholder={''}
                errors={errors}
                touched={touched}
              />
            </div>

            {values.medicare === 'Hospital Inpatient' && <CustomInput
              id={'date_of_discharge'}
              key={'date_of_discharge'}
              name={'date_of_discharge'}
              label={'Date of Discharge'}
              onBlurCapture={handleBlur}
              isInternal
              value={values.date_of_discharge}
              type={'date'}
              onChange={(e: any) => handleChange('date_of_discharge', e)}
              required
              placeholder={'MM/DD/YYYY'}
              errors={errors}
              max={new Date(maxDate)}
              className={'mr-5 !placeholder:text-requiredRed w-[320px]'}
              touched={touched}
            />
            }
          </div>
        </Fragment>
        }

      </div>

    </div>
  </InView >
}
const mapStateToProps = (state: any): any => ({
  loading: state.OnlineOrderForm.fetchingInsuranceCompany,
  error: state.OnlineOrderForm.fetchingInsuranceCompanyError,
  insurance_company: state.OnlineOrderForm.insurance_company
})
const mapDispatchToProps = {
  fetchInsuranceCompany: fetchInsuranceCompany
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfoSection)