import reduxActionType from '../../models/reduxActionType'
import { FETCHING_SUFFIX_DATA, FETCHING_SUFFIX_ERROR, FETCHING_SUFFIX_SUCCESS, LOGOUT } from '../Actions/types'

const initialState = {
  fetchingSuffix: false,
  errorSuffix: false,
  suffix: []
}

function SuffixReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case FETCHING_SUFFIX_DATA:
      return {
        ...state,
        fetchingSuffix: true,
        errorSuffix: false
      }
    case FETCHING_SUFFIX_SUCCESS: {
      return {
        ...state,
        fetchingSuffix: false,
        errorSuffix: false,
        suffix: [...action.payload]
      }
    }
    case FETCHING_SUFFIX_ERROR: {
      return {
        ...state,
        fetchingSuffix: false,
        errorSuffix: true
      }
    }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
// function SpecialityReducer(state = initialState, action: reduxActionType)
// {
//     case FETCHING_SPECIALITY_DATA:
//             return {
//                 ...state,
//                 fetchingSpeciality: true,
//                 errorSpeciality: false
//             }
//         case FETCHING_SPECIALITY_SUCCESS: {
//             return {
//                 ...state,
//                 fetchingSpeciality: false,
//                 errorSpeciality: false,
//                 speciality: [...action.payload]
//             }
//         }
//         case FETCHING_SPECIALITY_ERROR: {
//             return {
//                 ...state,
//                 fetchingSpeciality: false,
//                 errorSpeciality: true
//             }
//         }}
export default SuffixReducer
