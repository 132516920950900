import ActiveTestsState from '../../models/activetestsstate.type'
import reduxActionType from '../../models/reduxActionType'
import * as actions from '../Actions/types'


const initialState: ActiveTestsState = {
  fetching: false,
  error: false,
  activeTests: []
}

function ActiveTestsReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case actions.FETCH_ACTIVE_TESTS:
      {

        return {
          ...state,
          fetching: true,
          error: false
        }
      }
    case actions.FETCH_ACTIVE_TESTS_ERROR:
      {

        return {
          ...state,
          fetching: false,
          error: true
        }
      }
    case actions.FETCH_ACTIVE_TESTS_SUCCESS:
      {
        const { payload } = action
        return {
          ...state,
          fetching: false,
          error: false,
          activeTests: payload
        }
      }
    case actions.LOGOUT:
      return initialState
    default:
      return state
  }
}

export default ActiveTestsReducer
