import { useOktaAuth } from "@okta/okta-react";
import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Button from "../../../components/atomic/Button";
import CustomFileUploader from "../../../components/global/CustomFIleUploader";
import CustomInput from "../../../components/global/CustomInput";
import CustomPopup from "../../../components/global/CustomPopup";
import SummarySectionIndicator from "../../../components/global/LoadingIndicators/SummaryScreenIndicators/SummarySectionIndicator";
import OnlineOrderPDF from "../../../components/organism/OnlineOrderForm/OnlineOrderPDF";
import ReviewSection from "../../../components/organism/OnlineOrderForm/ReviewSection";
import ApprovalsLayout from "../../../Containers/ApprovalsLayout";
import http from "../../../Store/API/HttpMethods";
import DownloadHelper from "../../../utils/helpers/fileDownloader";
import orderResponseFormatter from "../../../utils/helpers/orderResponseFormatter";
import pdfHelper from "../../../utils/helpers/pdfHelper";
import useQuery from "../../../utils/helpers/QueryHelper";
import toastMessages from "../../../utils/helpers/toastMessage";
import ApproveOrderValidation from "../../../validations/approve_order_validation";
import OrderFormValidation from "../../../validations/orderformvalidation";
import { formRef } from "../ManageScreen";
import pageStyle from "../../../constants/pageStyle";
import testNameSplitter from "../../../utils/helpers/testNameSplitter";
import moment from "moment";
import DashboardCallModel from "../../../models/DashboardCallModel";
import DashboardActions from "../../../Store/Actions/DashboardActions";
import { RESET_INITIAL_CALL } from "../../../Store/Actions/types";

function AuthorizeAsPhysician({
  ids,
  hasIDG,
  handleSubmit,
}: {
  ids: any[];
  hasIDG: boolean
  handleSubmit: (data: any) => any;
}): JSX.Element {
  const initialValues = {
    username: "",
    password: "",
    currentDate: moment().format("MM-DD-YYYY"),
    orderIds: ids,
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      validationSchema={ApproveOrderValidation}
      onSubmit={handleSubmit}
      render={({
        errors,
        touched,
        setFieldValue,
        isValid,
        values,
        isSubmitting,
        submitForm,
      }) => {
        return (
          <Form>
            <div className="border border-[#DCDFE4] p-4 rounded-md bg-[#F9F9FA] my-3 tab:mt-9">
              <h3 className="font-semibold !text-sm text-royalBlue">
                Authorize as clinician
              </h3>
              <h5 className="!text-sm text-royalBlue py-3">
                Please enter the clinician credentials below.
              </h5>
              <CustomInput
                id="email"
                type="email"
                name="username"
                label="Username or Email ID"
                placeholder="Enter username or email id"
                required
                errors={errors}
                touched={touched}
                className={"bg-[#F9F9FA]"}
                inputClassName={"bg-[#F9F9FA]"}
                onChange={(val: any) => {
                  val;
                }}
              />
              <CustomInput
                id="password"
                type="password"
                name="password"
                label="Password"
                placeholder="Enter password"
                required
                errors={errors}
                touched={touched}
                className={"bg-[#F9F9FA]"}
                inputClassName={"bg-[#F9F9FA]"}
                onChange={(val: any) => {
                  val;
                }}
              />
              <div className="w-full flex flex-col justify-end items-end">
                <Button
                  disabled={!isValid || isSubmitting}
                  submitting={isSubmitting}
                  onClick={isSubmitting ? () => { } : submitForm}
                  btnType="primary"
                  className="py-2 !text-sm"
                  title="Submit as clinician"
                />
              </div>
              {hasIDG &&
                <p className="text-royalBlue text-xs mt-5">By my signature below, I, the healthcare provider, authorize performance of NeuroIDgenetix 902 and indicate that the purpose of the test(s), the procedures, the benefits and risks involved have been explained to the patient and patient informed consent has been obtained in accordance with state and local laws. I attest that this patient has a diagnosis for which pharmacologic therapy is reasonable and necessary, and that the drug or drugs that I intend to prescribe are reasonable and necessary for the treatment of this patient's diagnosis. I have made an initial personalized decision for this patient based on the patient's non-genetic factors. I have a record of what drug(s) is/are being considered and for what indication(s) to ensure the test(s) performed is reasonable and necessary. The test results will be utilized by me to determine a dose change to the patient's current medication and/or prescribe a new medication. I have informed the patient that Castle Biosciences, Inc. may be an out-of-network provider under their insurance plan and that I do not have any financial interest in Castle Biosciences, Inc.</p>
              }
            </div>
          </Form>
        );
      }}
    />
  );
}
function ManualSubmission({
  ids,
  labels,
  navigationLabels,
  hasIDG,
  downloading,
  downloadOrders,
  refetchDashboard
}: {
  ids: any[];
  labels: any[];
  navigationLabels: any[];
  hasIDG: boolean
  downloading: boolean;
  downloadOrders: () => any;
  refetchDashboard: () => void
}): JSX.Element {
  const [files, setFiles] = useState<any>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { authState } = useOktaAuth();
  const history = useHistory();

  async function authorizeOrderWithPDF() {
    if (submitting) return
    setSubmitting(true);

    const data = {
      files,
      currentDate: moment().format("MM-DD-YYYY"),
    }

    try {
      const response = await http.authorizePDFDocuments(
        "v1/orders/new-flow/add-authorized-documents",
        data,
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }
      );
      const { statusCode } = response;
      if (statusCode == 200) {
        history.replace(`/approvals/order-authorized?ids=${navigationLabels}`);
        refetchDashboard()
      }
      setSubmitting(false);
    } catch (error: any) {
      if (error.response) {
        toastMessages.error(
          error.response.data.message ?? "Authorization using pdf failed"
        );
      } else {
        toastMessages.error(error.message);
      }
      setSubmitting(false);
    }
  }
  const initialValues = {
    username: "",
    password: "",
    orderIds: ids,
  };

  return (
    <Formik
      initialValues={initialValues}
      isInitialValid={false}
      validationSchema={ApproveOrderValidation}
      onSubmit={() => { }}
      render={({
        errors,
        touched,
        setFieldValue,
        isValid,
        values,
        initialValues,
        setFieldError,
        validateField,
      }) => {
        return (
          <Form>
            <div className="border border-[#DCDFE4] p-4 rounded-md bg-[#F9F9FA] my-3">
              <h3 className="font-semibold !text-sm text-royalBlue">
                Download Orders
              </h3>
              <h5 className="!text-sm text-royalBlue py-3">
                Please download all test order's PDF for authorization.
              </h5>
              <Button
                onClick={downloadOrders}
                submitting={downloading}
                disabled={submitting || downloading}
                btnType="secondary"
                className="py-1.5 bg-white"
                title="Download all"
              />

              <div className="mb-2 mt-6">
                <h3 className="font-semibold !text-sm text-royalBlue">
                  Upload Authorized Order
                </h3>
                <h5 className="!text-sm text-royalBlue py-3">
                  Please upload the scan of the signed test order by the
                  clinician below.
                </h5>
                <div className="flex flex-wrap mb-4">
                  {ids.map((test: number, index: number) => (
                    <CustomFileUploader
                      key={test}
                      label={`${labels[index]}`}
                      id={test}
                      values={values}
                      name={test}
                      onUploadStart={() => { }}
                      onUploadEnd={() => { }}
                      errors={errors}
                      touched={touched}
                      setFiles={setFiles}
                      setFieldError={setFieldError}
                      setFieldValue={setFieldValue}
                      placeholder={""}
                      required
                      approvalsChooseFileButtonStyle
                    />
                  ))}
                </div>
                <div className="w-full flex flex-col justify-end items-end">
                  <Button
                    disabled={submitting ? true : files.length === ids.length ? false : true}
                    onClick={authorizeOrderWithPDF}
                    btnType="primary"
                    submitting={submitting}
                    className="mb-0"
                    title="Submit"
                  />
                </div>
                {hasIDG &&
                  <p className="text-royalBlue text-xs mt-5">By my signature below, I, the healthcare provider, authorize performance of NeuroIDgenetix 902 and indicate that the purpose of the test(s), the procedures, the benefits and risks involved have been explained to the patient and patient informed consent has been obtained in accordance with state and local laws. I attest that this patient has a diagnosis for which pharmacologic therapy is reasonable and necessary, and that the drug or drugs that I intend to prescribe are reasonable and necessary for the treatment of this patient's diagnosis. I have made an initial personalized decision for this patient based on the patient's non-genetic factors. I have a record of what drug(s) is/are being considered and for what indication(s) to ensure the test(s) performed is reasonable and necessary. The test results will be utilized by me to determine a dose change to the patient's current medication and/or prescribe a new medication. I have informed the patient that Castle Biosciences, Inc. may be an out-of-network provider under their insurance plan and that I do not have any financial interest in Castle Biosciences, Inc.</p>
                }
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

function ApprovalsReviewScreen(): JSX.Element {
  const query = useQuery();
  const { authState } = useOktaAuth();
  const [fetching, setFetching] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [tabs, setTabs] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [headerTitle, setHeaderTitle] = useState<string>("");
  const [labels, setLabels] = useState<any[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [disclaimerVisible, setDisclaimerVisible] = useState<boolean>(false)
  // const [hasIDG, setHasIDG] = useState<boolean>(false)
  const history = useHistory();
  const multiPrintRef = useRef<any>();
  let hasIDG = tabs.filter((item: any) => item.checked).some((item: any) => item.testId == 8)
  const { selectedPhysician, selectedOrganization, selectedPathLab } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const dispatch: any = useDispatch()
  const {
    profile: { roles },
  } = useSelector((state: any) => state.Profile);

  function getOrderData(id: string) {
    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await http.getOrderById(`/v1/orders/new-flow/${id}`, config);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }

  function getAllData(ids: any[]) {
    setFetching(true);
    Promise.allSettled(ids.map((id: any) => getOrderData(id)))
      .then((results: any) => {
        setFetching(false);
        if (
          results.some(
            (data: any) =>
              data?.value?.orderStatus?.toLowerCase() !== "pending approval"
          )
        ) {
          history.replace("/approvals");
          toastMessages.error("Please select valid order to approve");
        }
        setTabs(
          results.map((data: any, index: number) => {
            let label = `${data?.value?.patientInfo?.firstName ?? "First Name"
              } - ${data?.value?.testTypes?.name ? testNameSplitter(data?.value?.testTypes?.name) : "Test"}`;
            // setHeaderTitle((prevState: any) =>
            //   index === 0 ? label : prevState
            // );
            setLabels((prevState: any) => [...prevState, label]);
            return {
              checked: true,
              label: `${index + 1}.${label}`,
              testId: data?.value?.testTypes?.id ?? 1,
              formId: `${data?.value?.id}_${data?.value?.patientInfo?.firstName} ${data?.value?.patientInfo?.lastName}_${data?.value?.testTypes?.name}`,
              orderId: data?.value?.id,
            };
          })
        );
        if (results.length > 1) {
          setHeaderTitle('Approvals - Multiple Orders');
        } else if (results.length === 1) {
          setHeaderTitle(
            `${results[0]?.value?.patientInfo?.firstName ?? "First Name"
            } - ${results[0]?.value?.testTypes?.name ? testNameSplitter(results[0]?.value?.testTypes?.name) : "Test"}`
          );
        }
        setData(
          results.map((data: any) => {
            return orderResponseFormatter(data.value);
          })
        );
      })
      .catch((error: any) => {
        history.replace("/approvals");
        toastMessages.error(error);
      });
  }

  function refetchDashboard() {
    dispatch({ type: RESET_INITIAL_CALL })

    let isPLU = roles?.includes('PathLab User')
    const options: DashboardCallModel = {
      onError: (message: string) => toastMessages.error(message ? message : 'Something Went wrong'),
      config: { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken} ` } },
      pathLabId: selectedPathLab?.id ? selectedPathLab.id : null,
      organizationId: selectedOrganization?.id ? selectedOrganization.id : null,
      physicianId: selectedPhysician?.id ? selectedPhysician.id : null,
      isPLU
    }

    dispatch(DashboardActions.fetchDashboard(options))
  }

  async function sendForApproval(data: any) {
    if (submitting) return
    try {
      const response = await http.authorizeOrders(
        "v1/orders/new-flow/approval",
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        },
        data
      );
      const { statusCode, message } = response;

      if (statusCode === 200) {
        history.replace(`/approvals/order-authorized?ids=${labels}`);
        refetchDashboard()
      }
      setSubmitting(false);
    } catch (error: any) {
      const { response } = error;
      if (response) {
        if (response?.data.message) {
          // onError(response.data.message);
          toastMessages.error(response.data.message);
        } else {
          toastMessages.error(
            error.message ?? "Failed submitting for authorization"
          );
        }
      } else {
        toastMessages.error(
          error.message ?? "Failed submitting for authorization"
        );
      }
      setSubmitting(false);
    }
  }

  useEffect(() => {
    if (query.get("ids")) {
      const ids: any = query.get("ids")?.split(",");
      let newTabs = Array.from(Array(ids?.length).keys());
      setTabs(
        newTabs.map((tab: any, index: number) => {
          return {
            checked: true,
            label: `PatientName ${index + 1}`,
            orderId: ids[index],
            formId: "",
            testId: 1,
          };
        })
      );
      getAllData(ids);
    }
  }, []);

  function Render({
    errors,
    touched,
    setFieldValue,
    isValid,
    values,
    initialValues,
    setFieldError,
    validateField,
    ...rest
  }: any): JSX.Element {
    return (
      <Fragment>
        {/* <OnlineOrderPDF values={values} isApproval formId={values.formCount} /> */}
        <Form className="col-span-4 flex flex-col  w-full overflow-aut block">
          <div className="flex flex-col overflow-auto items-start">
            <div className="px-10 lg:px-[109px]">
              <div className="w-full flex flex-col items-start pt-5">
                <CustomInput
                  name="name"
                  type="checkbox"
                  placeholder=""
                  id=""
                  checked={
                    tabs?.find(
                      (tab: any, index: number) => values.formCount === index
                    ).checked ?? false
                  }
                  required={false}
                  errors={{}}
                  touched={{}}
                  withoutForm
                  className="mb-0 w-4 h-4"
                  label={
                    tabs?.find(
                      (tab: any, index: number) => values.formCount === index
                    ).label ?? false
                  }
                  labelClassName="!text-base font-semibold text-[#084E8B]"
                  isInternal
                  onChange={() => handleCheckboxChange(values.formCount)}
                />
                <div className="px-6">
                  <ReviewSection
                    errors={errors}
                    // testId={data[0]?.selectedTest?.id}
                    isApproval
                    id={
                      tabs?.find(
                        (tab: any, index: number) => values.formCount === index
                      ).label ?? false
                    }
                    // id={values.formCount}
                    className={"pb-0"}
                    // openOrder={() => history.replace(`/test-orders/online-order/${id}`)}
                    touched={touched}
                    isValid={isValid}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    selectedTab={""}
                    setSelectedTab={() => { }}
                    values={values}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Fragment>
    );
  }

  function handleCheckboxChange(index: number) {
    setTabs((prevState: any) =>
      prevState.map((item: any, childIndex: number) => {
        if (childIndex === index) {
          return {
            ...item,
            checked: !item.checked,
          };
        }
        return {
          ...item,
        };
      })
    );
  }

  function onDownload() {
    const token = authState?.accessToken?.accessToken ?? "";
    if (downloading || !!token === false) return;

    const pdfs = tabs
      .filter((item: any) => item.checked)
      .map((item: any) => ({ id: item.orderId, fileName: item.formId }));
    const onError = (message: string) => {
      toastMessages.error(message);
      setDownloading(false);
    };
    const onSuccess = () => {
      setDownloading(false);
    };
    setDownloading(true);
    DownloadHelper.downloadMultiplePDF(pdfs, token, true, onError, onSuccess);
  }

  const handlePrint = useReactToPrint({
    content: () => multiPrintRef.current,
    pageStyle,
    copyStyles: false,
  });

  function onPrint(): void {
    let divToPrint: any = document.getElementById("multiPrint");
    divToPrint?.classList.remove("hidden");
    handlePrint();
    divToPrint?.classList.add("hidden");
  }
  function hidePopup() {
    setModalVisible(false);
  }

  function onAuthorize() {
    if (roles[0]?.includes("Physician")) {
      if (hasIDG)
        return setDisclaimerVisible(true)

      setSubmitting(true);
      const data = {
        username: "",
        password: "",
        currentDate: moment().format("MM-DD-YYYY"),
        orderIds: tabs
          .filter((item: any) => item.checked)
          .map((item: any) => Number(item.orderId)),
      };
      sendForApproval(data);
    } else {
      setModalVisible(true);
    }
  }

  function returnButtonTitle(): string {
    let count: any = tabs?.filter((tab: any) => tab.checked).length;
    let title = count > 1 ? `Authorize All (${count})` : 'Authorize'


    if (roles.includes('Physician')) {
      if (hasIDG) {
        title = count > 1 ? `Approve and Submit All (${count})` : 'Approve and Submit'
      } else {
        title = count > 1 ? `Submit All (${count})` : 'Submit'
      }
    }
    return title
  }

  function CustomButtons(): JSX.Element {
    let count: any = tabs?.filter((tab: any) => tab.checked).length;

    return (
      <div className={"flex items-center justify-between"}>
        <Button
          btnType="secondary"
          onClick={onPrint}
          // disabled={count > 0 ? false : true}
          disabled={count == 0 || submitting || fetching}
          title={"print"}
          className={"mr-6"}
        />
        <Button
          btnType="secondary"
          onClick={onDownload}
          submitting={downloading}
          // disabled={count > 0 && submitting === false && fetching === false ? false : true}
          disabled={count == 0 || submitting || fetching || downloading}
          title={count > 1 ? "Download Orders" : `Download Order`}
          className={"mr-6"}
        />
        <Button
          btnType="primary"
          submitting={submitting}
          disabled={count > 0 && !submitting && !fetching ? false : true}
          onClick={onAuthorize}
          title={returnButtonTitle()}
        />
      </div>
    );
  }



  return (
    <Fragment>
      <CustomPopup
        visible={disclaimerVisible}
        hideButtons
        hidePopup={() => setDisclaimerVisible(false)}
        title={""}
        containerClass={"py-2"}
        contentContainerClass={"w-2/5 overflow-auto"}
      >
        <div className="border border-[#DCDFE4] p-4 rounded-md bg-[#F9F9FA] my-3">
          <h3 className="font-semibold !text-sm text-royalBlue">
            Disclaimer
          </h3>
          {true &&
            <p className="text-royalBlue text-xs mt-5">By my signature below, I, the healthcare provider, authorize performance of NeuroIDgenetix 902 and indicate that the purpose of the test(s), the procedures, the benefits and risks involved have been explained to the patient and patient informed consent has been obtained in accordance with state and local laws. I attest that this patient has a diagnosis for which pharmacologic therapy is reasonable and necessary, and that the drug or drugs that I intend to prescribe are reasonable and necessary for the treatment of this patient's diagnosis. I have made an initial personalized decision for this patient based on the patient's non-genetic factors. I have a record of what drug(s) is/are being considered and for what indication(s) to ensure the test(s) performed is reasonable and necessary. The test results will be utilized by me to determine a dose change to the patient's current medication and/or prescribe a new medication. I have informed the patient that Castle Biosciences, Inc. may be an out-of-network provider under their insurance plan and that I do not have any financial interest in Castle Biosciences, Inc.</p>
          }
          <Button
            disabled={submitting}
            submitting={submitting}
            onClick={submitting ? () => { } : () => {
              setSubmitting(true);
              const data = {
                username: "",
                password: "",
                currentDate: moment().format("MM-DD-YYYY"),
                orderIds: tabs
                  .filter((item: any) => item.checked)
                  .map((item: any) => Number(item.orderId)),
              };
              sendForApproval(data);
            }}
            btnType="primary"
            className="py-1.5 !text-sm mt-5"
            // title={returnButtonTitle()}
            title={"Agree and Submit"}
          />
        </div>
      </CustomPopup>
      <CustomPopup
        visible={modalVisible}
        hideButtons
        hidePopup={hidePopup}
        title={"Authorization"}
        containerClass={"py-2"}
        contentContainerClass={"h-full tab:h-[90%] w-2/5 tab:w-[70%] overflow-auto"}
      >
        <AuthorizeAsPhysician
          hasIDG={tabs.filter((item: any) => item.checked).some((item: any) => item.testId == 8)}
          ids={tabs
            .filter((item: any) => item.checked)
            .map((item: any) => Number(item.orderId))}
          handleSubmit={sendForApproval}
        />
        <div className="w-full flex flex-col justify-center items-center my-2">
          <div className="h-[16px] bg-dropDownBorder w-[1px]" />
          <p className="text-royalBlue !text-sm">OR</p>
          <div className="h-[16px] bg-dropDownBorder w-[1px]" />
        </div>
        <ManualSubmission
          downloadOrders={onDownload}
          refetchDashboard={refetchDashboard}
          hasIDG={tabs.filter((item: any) => item.checked).some((item: any) => item.testId == 8)}
          downloading={downloading}
          navigationLabels={labels}
          labels={tabs
            .filter((item: any) => item.checked)
            .map((item: any) => item.label)}
          ids={tabs
            .filter((item: any) => item.checked)
            .map((item: any) => Number(item.orderId))}
        />
      </CustomPopup>
      <ApprovalsLayout
        fetching={fetching}
        headerTitle={headerTitle}
        handleCheckboxChange={(index: number) => handleCheckboxChange(index)}
        tabs={tabs}
        CustomButtons={CustomButtons}
        checkHeaderTitle
      >
        {fetching && (
          <div className="col-span-4 px-10 lg:px-[109px]">
            <SummarySectionIndicator />
          </div>
        )}
        <div id="multiPrint" ref={multiPrintRef} className="hidden col-span-4">
          {!fetching &&
            data &&
            data.length > 0 &&
            data
              .filter((form: any, index: number) => {
                let found = tabs.find(
                  (tab: any) =>
                    tab.formId ===
                    `${tab.orderId}_${form?.patient_firstname} ${form?.patient_lastname}_${form?.selectedTest?.name}`
                );
                if (found && found.checked) {
                  return true;
                }
                return false;
              })
              .map((form: any, index: number) => (
                <OnlineOrderPDF
                  key={index + 1}
                  values={form}
                  isApproval
                  addPageBreak={index !== 0}
                  isVisible
                  formId={`${form.orderId}_${form?.patient_firstname} ${form?.patient_lastname}_${form?.selectedTest?.name}`}
                />
              ))}
        </div>
        {!fetching &&
          data &&
          data.length > 0 &&
          data.map((form: any, index: number) => (
            <Formik
              key={index + 1}
              innerRef={formRef}
              validationSchema={OrderFormValidation}
              initialValues={{ ...form, formCount: index }}
              initialStatus={false}
              onSubmit={() => { }}
              isInitialValid={false}
              render={Render}
            />
          ))}
      </ApprovalsLayout>
    </Fragment>
  );
}

export default ApprovalsReviewScreen;
