/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditIcon from '../../../assets/svg/EditIcon'
import LogoSVG from '../../../assets/svg/Logo'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { fetchTestsAndIcdCodes, toggleMode } from '../../../Store/Actions/OnlineOrderFormActions'
import { connect } from 'react-redux'
import toastMessages from '../../../utils/helpers/toastMessage'
import { useOktaAuth } from '@okta/okta-react'
import FieldValue from '../../TestOrders/FieldValue'
import dataConstants from '../../../constants/dataConstants'
//checking if pipeline works
const data = [
  {
    title: 'Clinician Information',
    addPageBreak: false,
    sections: [
      {
        title: 'Organization',
        fields: {
          name: 'Organization Name',
          email: 'Organization Email ID ',
          phone: 'Phone',
          fax: 'Fax',
          description: 'Description',
          address: 'Address',
          city: 'City',
          state: 'State/Province'
        }
      },
      {
        title: 'Ordering Clinician',
        fields: {
          clinician_ssn: 'SSN ',
          clinician_mrn: 'MRN'
        }
      },
      {
        title: 'Clinician Information',
        fields: {
          clinician_full_name: 'Full Name ',
          clinician_email: 'Email ID',
          clinician_phone: 'Phone',
          clinician_suffix: 'M.D',
          clinician_speciality: 'Speciality',
          clinician_npi: 'NPI',
          clinician_account_number: 'Account Number',
          clinician_address: 'Address',
          clinician_city: 'City',
          clinician_zip_code: 'Zip Code',
          clinician_state: 'State/Province'
        }
      }
    ]
  },
  {
    title: 'Test Menu',
    addPageBreak: false,
    sections: [
    ]
  },
  {
    title: 'Patient Information',
    addPageBreak: true,
    sections: [
      {
        title: '',
        fields: {
          patient_firstname: 'First Name',
          patient_middleinitial: 'Middle Initial',
          patient_lastname: 'Last Name',
          patient_dob: 'Date of Birth',
          patient_gender: 'Sex at Birth'
        }
      },
      {
        title: 'Additional Patient Identifier (Optional)',
        fields: {
          patient_ssn: 'SSN',
          patient_mrn: 'MRN'
        }
      },
      {
        title: 'Patient Contact Information',
        fields: {
          patient_email: 'Email ID',
          patient_address: 'Address',
          patient_city: 'City',
          patient_state: 'State/Province',
          patient_phone: 'Phone',
          patient_zip_code: 'ZIP Code'
        }
      }
    ]
  },
  {
    title: 'Billing information',
    addPageBreak: false,
    sections: [
      {
        title: '',
        fields: {
          icd_10: 'ICD-10 Code',
          additionalCodes: "Additional Diagnosed ICD-10s.",
          insurance: 'Primary Insurance Co. Name. (If Insurance Cards/Billing Face Sheet is uploaded, this section can be left blank)',
          // insurance: 'Primary Insurance Company Name',
          icn: 'Insurance Company Name',
          billing_phone: 'Phone',
          policy: 'Policy Number',
          medicare: 'Type of facility (where tissue was collected) ',
          date_of_discharge: 'Date of Discharge'
        }
      },
      // {
      //   title: 'Medicare',
      //   fields: {
      //     medicare: 'Type of facility (where tissue was collected) ',
      //     date_of_discharge: 'Date of Discharge'
      //   }
      // }
    ]
  },

  {
    title: 'Clinical Information',
    addPageBreak: true,
    sections: []
  },
  {
    title: 'Additional Information',
    addPageBreak: false,
    sections: [
      {
        title: '',
        fields: {
          full_name: 'Full Name',
          email_id: 'Email ID',
          phone: 'Phone',
          fax: 'Fax',
          suffix: 'Suffix',
          speciality: 'Speciality'
        }
      }
    ]
  },
  {
    title: 'Laboratory Information',
    addPageBreak: true,
    subtitle1: 'Type of specimen being submitted for testing',
    subtitle2: 'Slides form formalin fixed paraffin embedded tumor tissue',
    sections: [
      {
        title: '',
        fields: {
          name_of_facility: 'Name of facility where tissue is maintained',
          date_of_collection: 'Date of Collection',
          specimenId: "Specimen ID",
          medicarePullDate: 'Medicare Pull Date',
          // facility_contact_person: 'Facility of contact person',
          lab_phone: 'Phone',
          lab_fax: 'Fax',
          isTeleHealth: "Telehealth Order",
          tubeBarcode: "Tube Barcode",
          pathologist: 'Pathologist',
          tumorSite: 'Tumor Site',
          workingDiagnosis: 'Working differential/differential diagnosis',
          facilityType: 'Location of Sample Collection'
        }
      }
    ]
  },
  {
    title: 'Additional Notes',
    addPageBreak: false,
    // subtitle1: 'Files will only be saved/uploaded on final submission after signature.',
    // subtitle2: 'If the Online Order is exited for future completion, documents will not be saved.',
    sections: [
      // {
      //   title: 'Upload Documents',
      //   fields: {
      //     pathology_report: 'Pathology Report',
      //     insurance_cards: 'Insurance Cards',
      //     patient_info_demographics: 'Patient Info/Demographics sheet',
      //     signed_letter: 'Signed letter of medical necessity'
      //   }
      // },
      {
        title: '',
        fields: {
          additional_notes: 'Notes'
        }
      },
      // {
      //   title: 'Upload and transmit more documents',
      //   fields: {}
      // }
    ]
  },
  {
    title: 'Signature Allowed',
    addPageBreak: false,
    // subtitle1: 'Files will only be saved/uploaded on final submission after signature.',
    // subtitle2: 'If the Online Order is exited for future completion, documents will not be saved.',
    sections: [
      // {
      //   title: 'Upload Documents',
      //   fields: {
      //     pathology_report: 'Pathology Report',
      //     insurance_cards: 'Insurance Cards',
      //     patient_info_demographics: 'Patient Info/Demographics sheet',
      //     signed_letter: 'Signed letter of medical necessity'
      //   }
      // },
      {
        title: '',
        fields: {
          isPhysicianSignPermission: 'Signature Allowed'
        }
      },
      // {
      //   title: 'Upload and transmit more documents',
      //   fields: {}
      // }
    ]
  }
]
function PrintHeader({ values, isApproval, addPageBreak }: any): JSX.Element {
  const date = new Date()
  const { testsAndIcdCodes } = useSelector((state: any) => state.OnlineOrderForm)

  function testInformation() {
    let test = null
    if (values?.selectedTest) {
      if (isApproval) {
        test = { name: values?.selectedTest?.name }
      } else {
        if (testsAndIcdCodes.length > 0)
          test = testsAndIcdCodes.find((test: any) => test.id === values?.selectedTest?.id)
        else
          test = { ...values.selectedTest }
      }
    }
    if (test !== null) {
      return <span>{test?.name}</span>
    }
    return test
  }

  useEffect(() => {

  }, [values?.selectedTest])

  return <Fragment>
    <div className={`flex flex-col items-start justify-start my-10 mt-4`}>
      <LogoSVG height={50} width={150} />
      <h3 className='text-base font-medium my-2'>Online Ordering eRequest generated for: {testInformation()}</h3>
      <p>eRequest Transmission Status: {values?.orderStatus}</p>
      {/* <p>DateTime Transmitted: {values?.submittedDate ? values.submittedDate : <span>{date.toLocaleDateString()} {date.toLocaleTimeString()}</span>}</p> */}
      <p>DateTime Transmitted: {values?.submittedDate ? values.submittedDate : '---'}</p>
    </div>
    <div className='my-5'>
      <p>Order Confirmation # {values.eRequestId}</p>
    </div>
  </Fragment>
}

function SectionCard({ title, fields, values, className }: any): JSX.Element {

  if (title === 'Signature Allowed')
    return <div>
      <p>{values?.isPhysicianSignPermission ? 'Yes' : 'No'}</p>
    </div>
  return <div className={`${className ?? ''}`}>
    {title === 'Additional Patient Identifier (Optional)' && values?.selectedTest?.id !== 4 ? null :
      <div className="flex justify-between items-center">
        {
          title && <p className="text-royalBlue text-base font-medium uppercase  my-2">{title}:</p>
        }
      </div>
    }

    {fields && Object.keys(fields).length > 0 && <div className="">
      {
        Object.entries(fields).map(([field, value]: any) => <FieldValue
          key={field}
          field={field}
          value={value}
          values={values}
          isFile={false}
          isPDF />)
      }
    </div>}
  </div>
}

function DXSccReview({ values, handleClick }: any): JSX.Element {
  const [questions, setQuestions] = useState(dataConstants.SCCQuestions)

  return <div className="my-6 page-break">
    <p className="text-royalBlue font-medium uppercase mb-6 ">Clinical Information</p>

    <div className="">
      {questions.map(question => (<div key={question.questionId} className="mb-3">
        <p className="text-royalBlue text-base font-semibold my-2">{question.label}</p>

        {
          question.options
            .filter(option => values[option.name]?.options?.includes(option.value))
            .map((item: any) => (<p className="text-royalBlue text-base capitalize my-1">
              {!!item?.customLabelHTML ? <span dangerouslySetInnerHTML={{ __html: item.customLabelHTML }} /> : item.label}
            </p>))
        }
        {question.questionId === 1 && values.other && <p className="text-royalBlue text-base capitalize my-1">{values.other}</p>}

      </div>))}
    </div>
  </div>
}
function IDGFormReview({ values, handleClick }: any): JSX.Element {
  function updateLabelsWithSuperscript(arr: any) {
    return arr.map((item: any) => {
      const updatedLabel = item.label.replace(
        /®/g,
        `<sup style={{ verticalAlign: 'super', marginTop:'10px' }}>®</sup>`
      );
      return { ...item, label: updatedLabel };
    });
  }

  const updatedisAltheaDxNeuropsychotropicMedications = updateLabelsWithSuperscript(
    dataConstants.isAltheaDxNeuropsychotropicMedicationsValues
  );
  const updatedisIDgNeuropsychotropicMedicationsValues = updateLabelsWithSuperscript(
    dataConstants.isIDgNeuropsychotropicMedicationsValues
  );
  const updatedisIDgNeuropsychotropicMedicationsCurrentValues = updateLabelsWithSuperscript(
    dataConstants.isIDgNeuropsychotropicMedicationsCurrentValues
  );

  const [questions, setQuestions] = useState([
    {
      questionId: 1,
      label: 'Select ALL the NeuroIDgenetix medications you are considering for new treatment and/or dosage change for this patient.',
      tooltip: '',
      name: 'isAltheaDxNeuropsychotropicMedications',
      options: updatedisAltheaDxNeuropsychotropicMedications
    },

    // {
    //   questionId: 3,
    //   label: `Patient's condition appears difficult to treat as evidenced by therapeutic failure of the following current or previous psychiatric medications.`,
    //   name: 'isIDgNeuropsychotropicMedications',
    //   tooltip: '',
    //   options: updatedisIDgNeuropsychotropicMedicationsValues
    // },
    {
      questionId: 4,
      label: `CURRENT PRESCRIPTION MEDICATIONS - Please select all current psychiatric and non-psychiatric prescription medications. (If patient MAR sheet is uploaded, this section can be left blank)`,
      name: 'isIDgNeuropsychotropicMedicationsCurrent',
      tooltip: '',
      options: updatedisIDgNeuropsychotropicMedicationsCurrentValues
    },
    {
      questionId: 2,
      label: `LIFESTYLE FACTORS, OTC MEDICATIONS & OTHER - Please select all that the patient regularly takes or uses any of the items below`,
      name: 'isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement',
      tooltip: '',

      options: dataConstants.isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplementValues
    },
  ])

  return <div className="mb-6">
    <p className="text-royalBlue font-medium uppercase mb-6 ">Patient Healthcare Information</p>

    <div className="">
      {questions.map(question => (<div key={question.questionId} className="mb-3">
        <p className="text-royalBlue text-base font-semibold my-2">{question.label}</p>

        {
          question.options
            .filter((option: any) => values[option.name]?.options?.includes(option.value))
            .map((item: any) => (<p className="text-royalBlue text-base capitalize my-1" dangerouslySetInnerHTML={{ __html: item.label }} />))
        }
        {question.questionId === 2 && values.other && <p className="text-royalBlue text-base capitalize my-1">{values.other}</p>}

      </div>))}
    </div>
  </div>
}

function DiffDxReview({ values, handleClick }: any): JSX.Element {
  const [questions, setQuestions] = useState<any>([
    // {
    //   id: 1,
    //   label: 'Is the malignant potential of this melanocytic lesion uncertain?',
    //   tooltip: 'Select Yes if the malignant potential of this melanocytic lesion uncertain.'
    // },
    {
      id: 1,
      label: `I attest the specimen being submitted for testing is a primary cutaneous melanocytic neoplasm for which the
      diagnosis is uncertain, (despite the performance of standard-of-care test procedures and relevant ancillary
      tests), the patient may be subjected to additional intervention as a result of the diagnostic uncertainty, and this
      patient was not receiving immunosuppressant or radiation therapy at the time of biopsy.`,
    },
    // {
    //   id: 2,
    //   label: 'Does this lesion have spitzoid features?',
    //   tooltip: 'Select Yes if  this lesion have spitzoid features.'
    // },
    // {
    //   id: 3,
    //   label: 'Is this lesion junctional/intraepidermal?', tooltip: 'Select Yes if this lesion junctional/intraepidermal.'
    // },
    // {
    //   id: 4,
    //   label: 'Please select if you only wish to have one of the three tests.',
    //   tooltip: '',
    // }
  ])

  return <div className="my-6 page-break">
    <p className="text-royalBlue font-medium uppercase mb-6 ">Clinical Information</p>
    <div className="">
      {questions.map((question: any, index: number) => {
        if (question.id == 4) {
          return <div key={question.label} className="mt-4">
            <p className="text-royalBlue text-base font-medium">{question.label}</p>
            <p className="text-royalBlue text-base capitalize">{values[`question${index + 1}`] === 'CST' ? "Castle Standard Testing"
              : values[`question${index + 1}`] === 'MPO' ? 'MyPath Only' : "DiffDx-Melanoma Only"}</p>
          </div>
        }
        return <div key={question.label} className="mt-4">
          <p className="text-royalBlue text-base font-medium">{question.label}</p>
          <p className="text-royalBlue text-base capitalize">{values[`question${index + 1}`] === 'N/A' ? 'I don\'t know' : values[`question${index + 1}`]}</p>
        </div>
      })}
    </div>
  </div>
}

function UMUMSeqReview({ values, handleClick }: any): JSX.Element {
  const [questions, setQuestions] = useState<any>([
    {
      label: "Has the tissue in this sample been exposed to radiation?",
    }
  ])

  useEffect(() => {
    if (values?.selectedTest?.checkedIndexes?.length == 1 && values?.selectedTest.checkedIndexes.includes(7)) {
      setQuestions((prevState: any) => prevState.filter((item: any) => item.label !== 'Add-On Testing: DecisionDx-Prame?'))
    } else {
      setQuestions([
        {
          label: "Has the tissue in this sample been exposed to radiation?",
        },
        {
          label: "Add-On Testing: DecisionDx-Prame?",
        }
      ])
    }
  }, [values?.selectedTest?.checkedIndexes?.length])

  return <div className="page-break my-6">
    <p className="text-royalBlue font-medium uppercase mb-6 ">Clinical Information</p>
    <div className="">
      {questions.map((question: any, index: number) => (<div key={question.label} className="mt-4">
        <p className="text-royalBlue text-base font-medium">{question.label}</p>
        <p className="text-royalBlue text-base capitalize">{values[`question${index + 1}`] === 'N/A' ? 'I don\'t know' : values[`question${index + 1}`]}</p>
      </div>))}
    </div>
  </div>
}
function UMReview({ values, handleClick }: any): JSX.Element {
  const [questions, setQuestions] = useState<any>([
    {
      label: "Add-On Testing: DecisionDx-Prame",
    }
  ])

  return <div className="my-6 page-break">
    <p className="text-royalBlue font-medium uppercase mb-6 ">Clinical Information</p>
    <div className="">
      {questions.map((question: any, index: number) => (<div key={question.label} className="mt-4">
        <p className="text-royalBlue text-base font-medium">{question.label}</p>
        <p className="text-royalBlue text-base capitalize">{values[`question${index + 1}`] === 'N/A' ? 'I don\'t know' : values[`question${index + 1}`]}</p>
      </div>))}
    </div>
  </div>
}

function DXMelanomaReview({ values, handleClick }: any): JSX.Element {
  const [questions, setQuestions] = useState<any>([
    {
      label: 'Has the patient had a sentinel lymph node biopsy for this melanoma?',
      note: 'Please provide the surgical pathology report in \'Supporting Documents\' tab, if available',
      tooltip: 'Select Yes if a SLNB was performed for this melanoma.\nIf SLNB was performed, select the SLNB result.',
      extraField: {
        label: 'Lymph Node Status',
        name: 'lymph_node_status',
        placeholder: 'Select lymph node status',
        suggestions: ['val1', 'val2', 'val3']
      }
    },
    {
      label: 'Does/Did this patient have clinically palpable nodes?',
      tooltip: 'Select Yes if the patient has ever had clinically palpable nodes'
    },
    {
      label: 'Does/Did this patient have intransit metastasis?',
      tooltip: 'Select Yes if the patient has ever had  intransit metastasis'
    }
  ])

  return <div className="my-6 page-break">
    <p className="text-royalBlue font-medium uppercase mb-6 ">Clinical Information</p>

    <div className="">
      {questions.map((question: any, index: number) => (<div key={question.label} className="mt-4">
        <p className="text-royalBlue text-base font-medium">{question.label}</p>
        <p className="text-royalBlue text-base capitalize">{values[`question${index + 1}`] === 'N/A' ? 'I don\'t know' : values[`question${index + 1}`]}</p>
        {question.extraField && question.extraField.name === 'lymph_node_status' && <p>Lymph Node Status: {values.lymphNodeStatus}</p>}
      </div>))}
    </div>
  </div>
}

function ClinicalSection({ values, setSelectedTab }: any): any {
  const dispatch = useDispatch()

  function handleClick(): void {
    dispatch(toggleMode())
    setSelectedTab('clinical info')
  }
  if (values?.selectedTest?.id === 3) { return <DXMelanomaReview values={values} handleClick={handleClick} /> }

  if (values?.selectedTest?.id === 2) { return <DXSccReview values={values} handleClick={handleClick} /> }
  if (values?.selectedTest?.id === 8) { return <IDGFormReview values={values} handleClick={handleClick} /> }

  if (values?.selectedTest?.id === 5) { return <DiffDxReview values={values} handleClick={handleClick} /> }

  if (values?.selectedTest?.id === 3) {
    return <UMUMSeqReview values={values} handleClick={handleClick} />;
  }

  return null
}

function AdditionalInfoReview({ values, setSelectedTab }: any): any {
  const [clinicians, setClinicians] = useState<any>([])
  // const { selectedOrganization } = useSelector((state: any) => state.OnlineOrderForm)
  const fields = {
    fullName: 'Full Name',
    fax: 'Fax',
    npi: 'NPI',
    email: 'Email ID',
    // phone: 'Phone',
    // suffix: 'Suffix',
    // speciality: 'Speciality'
  }
  useEffect(() => {
    setClinicians(values?.additionalClinician ? values?.additionalClinician.map((item: any) => ({ ...item, fullName: `${item?.firstName} ${item?.lastName}` })) : []);
  }, [values?.additionalClinician])

  if (values.has_additional_clinician) {
    return <div className="mb-6 page-break my-6 ">
      <p className="text-royalBlue font-medium uppercase mb-6">Additional Information</p>
      <div className="">
        <div className="flex justify-between items-center my-3">
          <p className="text-royalBlue text-base font-medium">Additional Clinician (to receive the final report)</p>
        </div>
        {clinicians.length > 0 && clinicians.map((clinician: string) => {
          return <div className="flex flex-wrap items-center">
            {
              Object.entries(fields).map(([field, value]: any) => (
                <div key={field} className="mr-6">
                  <p className="text-royalBlue font-xs font-semibold my-2">{value}</p>
                  <p className="text-royalBlue text-base capitalize">{clinician[field] ? clinician[field] : '---'}</p>
                </div>
              ))
            }
          </div>
        })}
      </div>
    </div>
  }
  return null
}

function ClinicianInfoSection({ isSummary, isApproval, values }: any): JSX.Element {
  const { selectedOrganization, selectedPhysician } = useSelector((state: any) => state.OnlineOrderForm)
  const { data: states, error } = useSelector(
    (state: any) => state.StateAndCity
  );
  let organizationKeys = {}
  let physicianKeys = {}
  if (isSummary || isApproval) {
    organizationKeys = {
      organization_name: 'Organization Name',
      organization_address: 'Organization Address',
      organization_city: 'Organization City',
      organization_state: 'Organization State',
      zipcode: 'Organization Zip',
      organization_phone: 'Organization Phone',
      organization_fax: 'Organization Fax',
      // organization_id: 'Organization Record ID',
      organization_limsRecordId: 'Organization Record ID'
    }
    // physicianKeys = {
    //   clinician_id: 'Ordering Clinician Record Id',
    //   clinician_firstName: 'Clinician First Name',
    //   clinician_lastName: 'Clinician Last Name',
    //   clinician_suffix: 'Clinician Suffix',
    //   clinician_npi: 'Clinician NPI',
    //   clinician_speciality: 'Clinician Speciality',
    //   clinician_email: 'Email'
    // }
    physicianKeys = {
      // clinician_id: 'Ordering Clinician Record Id',
      clinician_limsRecordId: 'Ordering Clinician Record Id',
      clinician_full_name: 'Clinician First Name',
      clinician_speciality: 'Clinician Specialty',
      clinician_lastName: 'Clinician Last Name',
      clinician_npi: 'Clinician NPI',
      clinician_suffix: 'Clinician Suffix',
      clinician_email: 'Clinician Email'
    }
  } else {
    organizationKeys = {
      name: 'Organization Name',
      address: 'Organization Address',
      city: 'Organization City',
      state: 'Organization State',
      zipcode: 'Organization Zip',
      phone: 'Organization Phone',
      fax: 'Organization Fax',
      // id: 'Organization Record ID',
      limsRecordId: 'Organization Record ID',
    }
    physicianKeys = {
      // id: 'Ordering Clinician Record Id',
      physicianLimsRecordId: 'Ordering Clinician Record Id',
      firstName: 'Clinician First Name',
      lastName: 'Clinician Last Name',
      suffix: 'Clinician Suffix',
      npi: 'Clinician NPI',
      specialty: 'Clinician Speciality',
      email: 'Clinician Email'
    }
  }


  return <div className="mb-6">
    <p className="text-royalBlue font-medium uppercase mb-6">Clinician Information</p>
    <div className="">
      {
        Object.entries(organizationKeys).map(([field, value]: any) => {
          type ObjectKey = keyof typeof organizationKeys
          const myKey = field as ObjectKey

          return <div key={field} className="mr-6 flex items-center">
            <p className="text-royalBlue text-base my-1 mr-2">{value}:</p>
            {isSummary || isApproval ?
              <p className="text-royalBlue text-base capitalize">{values[myKey] ? values[myKey] : '---'}</p>
              :
              <p className="text-royalBlue text-base capitalize"> {selectedOrganization && selectedOrganization[field]
                ? value?.toLowerCase().includes('state') ? states &&
                  states.length > 0 &&
                  states.find((state: any) =>
                    `${state?.name} ${state?.state_code}`.includes(
                      selectedOrganization?.state,
                    )
                  )?.name
                  ? states.find((state: any) =>
                    `${state?.name} ${state?.state_code}`.includes(
                      selectedOrganization?.state,
                    )
                  )?.name : '---' : selectedOrganization[field]
                : "---"}</p>
            }
          </div>
        })
      }
      {
        Object.entries(physicianKeys).map(([field, value]: any) => {
          type ObjectKey = keyof typeof physicianKeys
          const myKey = field as ObjectKey

          return <div key={field} className="mr-6 flex items-center">
            <p className="text-royalBlue text-base my-1 mr-2">{value}:</p>
            {isSummary || isApproval ?
              <p className="text-royalBlue text-base capitalize">{values[myKey] ? values[myKey] : '---'}</p>
              :
              <Fragment>
                {selectedPhysician?.profile ?
                  <p className="text-royalBlue text-base capitalize">{selectedPhysician?.profile[field] ? selectedPhysician?.profile[field] : '---'}</p>
                  : selectedPhysician && <p className="text-royalBlue text-base capitalize">{selectedPhysician[field] ? selectedPhysician[field] : '---'}</p>
                }
              </Fragment>
              // <p className="text-royalBlue text-base capitalize">{selectedPhysician?.profile && selectedPhysician?.profile[field] ? selectedPhysician?.profile[field] : '---'}</p>
            }
          </div>
          return (
            <FieldValue
              key={field}
              field={field}
              value={value}
              values={values}
              isFile={false}
              isPDF={true}
              isApproval={isApproval}
            />
          )
        })
      }
    </div>
  </div>
}
function TestMenuSection({ values, isApproval }: any): JSX.Element {
  const { testsAndIcdCodes, fetchingTestsAndIcdCodes } = useSelector((state: any) => state.OnlineOrderForm)
  const [foundTest, setFoundTest] = useState<any>()

  useEffect(() => {
    if (isApproval) {
      if (values?.selectedTest) {
        setFoundTest({ data: [{ label: values?.selectedTest?.name, id: values.selectedTest.id, checkedIndexes: values.selectedTest.checkedIndexes }] })
      }
    } else if (!fetchingTestsAndIcdCodes && testsAndIcdCodes.length > 0 && values?.selectedTest) {
      let foundTest = null
      testsAndIcdCodes.forEach((item: any) => {
        let foundItem = item?.tests?.find((test: any) => test?.id === values?.selectedTest?.id)
        if (foundItem) {
          foundTest = foundItem
        }
      })
      if (foundTest) {
        setFoundTest(foundTest)
      }
    } else {
      if (values?.selectedTest) {
        setFoundTest({ data: [{ label: values?.selectedTest?.name, id: values?.selectedTest?.id, checkedIndexes: values.selectedTest.checkedIndexes }] })
      } else
        setFoundTest(null)
    }
  }, [fetchingTestsAndIcdCodes, values?.selectedTest])

  return <div className="mb-6">
    <p className="text-royalBlue font-medium uppercase mb-6">Test Menu</p>
    {foundTest && foundTest?.data?.map((type: any) => <div className='flex items-center'>
      <p className='text-royalBlue mr-2'>{type?.label}:</p>
      <p className='text-royalBlue'>{values?.selectedTest?.checkedIndexes?.includes(type.id) ? 'Ordered' : 'Not Selected'}</p>
    </div>)}
  </div>
}
export const printRef = React.createRef<any>()

function OnlineOrderPDF({ values, isSummary, isApproval, formId, customRef, isVisible, addPageBreak }: any): JSX.Element {

  return <div ref={customRef ?? printRef} id={formId ?? 'pdf'} className={`col-span-4 flex flex-col w-full ${isVisible ? '' : 'hidden'} -z-50`}>
    <div className="pb-14 px-10" >
      {addPageBreak ? <div className='h-[70vh] page-break' /> : ''}
      <PrintHeader values={values} isApproval={isApproval} />
      {
        data.map(mainSection => {
          if (mainSection.title === 'Test Menu') { return <TestMenuSection isApproval={isApproval} values={values} /> }
          // if (mainSection.title === 'Supporting Documents') { return <SupportingDocsReview values={values} addPageBreak={addPageBreak} /> }

          if (mainSection.title === 'Additional Information') { return <AdditionalInfoReview key={mainSection.title} values={values} /> }

          if (mainSection.title === 'Clinical Information') { return <ClinicalSection key={mainSection.title} values={values} /> }

          if (mainSection.title === 'Clinician Information') { return <ClinicianInfoSection isApproval={isApproval} isSummary={isSummary} values={values} key={mainSection.title} /> }

          return <div key={mainSection.title} className={`mb-6 ${mainSection.addPageBreak ? 'page-break my-6' : ''}`}>
            <p className="text-royalBlue font-medium uppercase mb-6">{mainSection.title === "Laboratory Information" && values?.selectedTest?.id === 8 ? 'Specimen Information' : mainSection.title}</p>
            {mainSection.subtitle1 &&
              <p className="text-royalBlue mb-3 !text-base">{mainSection.subtitle1}</p>
            }
            {mainSection.subtitle2 && (
              <p className="text-royalBlue mb-6 !text-base">
                {mainSection.title === "Laboratory Information" && !!values?.typeofspecimen ? values?.typeofspecimen?.includes('Slides') ? 'Slides/Block' : values.typeofspecimen : 'Slides/Block'}
              </p>
            )}
            <div className="">
              {mainSection.sections.length > 0 && mainSection.sections.map(section => <SectionCard
                key={section.title}
                values={values}
                title={section.title}
                fields={section.fields}
              />)}
            </div>
          </div >
        })}
      {isApproval && <div className="mb-6 ">
        <p className="text-royalBlue font-medium mb-11" >Sincerely,</p>
        <p className="text-royalBlue capitalize b">{values?.clinician_firstName || ''} {values?.clinician_lastName || ''}</p>
        <div className="border-b-2 border-black w-1/5 extraClass" style={{ marginBottom: '10px' }}><hr /></div>
      </div>
      }
    </div>
  </div>
}


export default OnlineOrderPDF
