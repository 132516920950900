import * as React from 'react'
import { SVGProps } from 'react'

const QuestionMarkSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={102} height={102} {...props}>
        <defs>
            <linearGradient
                id="a"
                x1={0.129}
                y1={0.163}
                x2={0.867}
                y2={0.875}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#31bdbf" />
                <stop offset={1} stopColor="#3f76a5" />
            </linearGradient>
        </defs>
        <g data-name="Group 14533">
            <g data-name="Component 6407 \u2013 1">
                <g data-name="Ellipse 667" stroke="#18b5b7" fill="url(#a)">
                    <circle cx={51} cy={51} r={51} stroke="none" />
                    <circle cx={51} cy={51} r={50.5} fill="none" />
                </g>
            </g>
            <g
                data-name="Group 14301"
                transform="translate(15.363 15.364)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={6}
            >
                <circle
                    data-name="Ellipse 668"
                    cx={27}
                    cy={27}
                    r={27}
                    transform="translate(8.636 8.636)"
                />
                <path data-name="Line 1324" d="M35.636 50.484v.03" />
                <path
                    data-name="Path 5286"
                    d="M35.635 40.09a4.454 4.454 0 0 1 2.97-4.454 7.721 7.721 0 1 0-8.909-11.879"
                />
            </g>
        </g>
    </svg>
)

export default QuestionMarkSVG
