/* eslint-disable @typescript-eslint/space-before-function-paren */
import { connect, useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import Button from "../atomic/Button";
import { formRef } from "../../Pages/Admin/ManageScreen/index";
import ButtonStateActions from "../../Store/Actions/ButtonStateActions";
import { useHistory } from "react-router-dom";
import {
  createOnlineOrder,
  resetSelectedTest,
  toggleMode,
  updateOnlineOrder,
} from "../../Store/Actions/OnlineOrderFormActions";
import React, { Fragment, useEffect, useRef, useState } from "react";
import http from "../../Store/API/HttpMethods";
import { useOktaAuth } from "@okta/okta-react";
import PortalUsersActions from "../../Store/Actions/PortalUsersActions";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { printRef } from "../organism/OnlineOrderForm/OnlineOrderPDF";
import { click } from "@testing-library/user-event/dist/click";
import { OnlineOrderOptions } from "../../models/forms/online_order_options.interface";
import toastMessages from "../../utils/helpers/toastMessage";
import DropDownIcon from "../../assets/svg/DropdownIcon";
import DownloadHelper from "../../utils/helpers/fileDownloader";
import NotificationActions from "../../Store/Actions/NotificationsActions";
import pageStyle from "../../constants/pageStyle";
import useOrderButtonTitle from "../../utils/customHooks/buttonTitle";
import DashboardCallModel from "../../models/DashboardCallModel";
import DashboardActions from "../../Store/Actions/DashboardActions";
import OrderFormValidation from "../../validations/orderformvalidation";
interface RenderButtonProps {
  screen: string;
  mode: string;
  selectedUser?: any;
  profile?: any;
  click1: () => any;
  submit?: boolean;
  saveAndExit?: boolean;
  clinicalInfoValid?: boolean;
  formSubmit?: boolean;
  submitting?: boolean;
  createOrder?: any;
  updateOrder?: any;
  savingOrder?: boolean;
  toggleSubmit: (state: boolean) => any;
  setButtonState: (state: string) => any;
  toggleMode: () => any;
  fetchDashboard: (options: DashboardCallModel) => void;
  hideButton?: boolean;
  gettingUser?: any;
  user?: any;
}

function RenderButtons({
  screen,
  click1,
  submit,
  saveAndExit,
  clinicalInfoValid,
  mode,
  profile,
  formSubmit,
  hideButton,
  submitting,
  selectedUser,
  toggleSubmit,
  toggleMode,
  createOrder,
  updateOrder,
  savingOrder,
  setButtonState,
  fetchDashboard,
  gettingUser,
  user,
}: RenderButtonProps) {
  const history = useHistory();

  const isCOA = profile?.profile?.roles?.includes(
    "Collaborative Order Agreement"
  );
  const {
    selectedPhysician,
    selectedOrganization,
    creatingOrder,
    selectedTest,
    isPhysicianSignPermission,
    selectedPathLab,
  } = useSelector((state: any) => state.OnlineOrderForm);
  const dualRoleCOA =
    profile?.profile?.roles?.includes("Collaborative Order Agreement") &&
    profile?.profile?.roles?.includes("Physician") &&
    profile?.profile?.id === selectedPhysician?.id;
  const [updatingStatus, setUpdatingStatus] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [deletingOrder, setDeletingOrder] = useState<boolean>(false);

  const { authState } = useOktaAuth();
  const pdfRef = useRef<any>();
  const dispatch = useDispatch();
  const buttonTitle = useOrderButtonTitle();
  const isPLU_User =
    (profile?.roles?.includes("PathLab User") ||
      profile?.profile?.roles?.includes("PathLab User")) &&
    (profile?.roles?.length == 1 || profile?.profile?.roles?.length == 1);

  function handleDiscard() {
    formRef.current.resetForm();
    toggleSubmit(false);
    history.push("/manage/organizations");
  }
  function handlePDFOrder() {
    history.push("/documents");
  }
  function handleCancel() {
    setButtonState("organization detail");
  }
  function handleUpdatePortalUser() {
    if (selectedUser) {
      history.push(
        `/manage/portal-users/profile/update/${selectedUser.user.id}`
      );
    }
  }
  function handleCancelInUpdatePortalUser() {
    history.push(`/manage/portal-users/profile/${selectedUser.user.id}`);
  }

  function handleEditMyProfile() {
    history.push("/profile/editprofile");
    setButtonState("edit my profile");
  }
  function handleCancelInEditMyProfile() {
    history.push("/profile", { replace: true });
    setButtonState("my profile");
  }
  function handleInviteUser() {
    history.push("/manage/portal-users/invite", { replace: true });
    setButtonState("invite screen");
  }

  function handleCreateOnlineOrder() {
    history.push("/test-orders/online-order", { replace: true });
    setButtonState("online order");
  }

  function handleReviewAndEdit(): void {
    toggleMode();
    setTimeout(() => {
      const section: any = document?.getElementById("select test");
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 200);
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
    copyStyles: false,
  });

  function printOrder(): void {
    let divToPrint: any = document.getElementById("pdf");
    divToPrint?.classList.remove("hidden");
    handlePrint();
    divToPrint.classList.add("hidden");
  }

  const download = (): void => {
    let divToPrint: any = document.getElementById("pdf");
    divToPrint?.classList.remove("hidden");
    const { clientWidth, clientHeight } = divToPrint;
    divToPrint.classList.add("divToPrint");

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    html2canvas(divToPrint).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      // const imgWidth = 290
      // const pageHeight = 290)
      const imgWidth = window.innerWidth * 0.153;
      // const pageHeight = 295
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("p", "mm");
      let position = 0;
      doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 25);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 25);
        heightLeft -= pageHeight;
      }
      doc.save("download.pdf");
    });
    divToPrint?.classList.add("hidden");
  };
  async function handleUserStatus(): Promise<any> {
    if (updatingStatus) return;
    setUpdatingStatus(true);
    try {
      const body: any = {
        userId: selectedUser.id,
        status: selectedUser.status
          ? selectedUser.status.toLowerCase() === "active"
            ? "block"
            : "active"
          : "",
      };
      const config: any = {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      };
      const response = await http.updateStatus(
        "/v1/users/change-status-latest",
        body,
        config
      );
      const { statusCode, message, error } = response;

      if (error) {
        setUpdatingStatus(false);
      }

      if (statusCode === 200) {
        setUpdatingStatus(false);
        dispatch(
          PortalUsersActions.updateSelectedUserStatus(
            selectedUser.status.toLowerCase() === "active" ? "block" : "active"
          )
        );
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toastMessages.error(error?.response?.data?.message);
      } else
        toastMessages.error(
          `Something went wrong. Unable to change ${
            selectedUser?.profile?.firstName +
            " " +
            selectedUser?.profile?.lastName
          } status.`
        );
      setUpdatingStatus(false);
    }
  }

  function RefetchDashboard() {
    let isPLU = profile?.profile?.roles?.includes("PathLab User");

    const options: DashboardCallModel = {
      onError: (message: string) =>
        toastMessages.error(message ? message : "Something Went wrong"),
      config: {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken} `,
        },
      },
      pathLabId: selectedPathLab?.id ? selectedPathLab.id : null,
      organizationId: selectedOrganization?.id ? selectedOrganization.id : null,
      physicianId: selectedPhysician?.id ? selectedPhysician.id : null,
      isPLU,
    };

    fetchDashboard(options);
  }

  async function handleSaveAndExit() {
    if (savingOrder === true) return;
    if (!!formRef.current === false) return;

    const data = { ...formRef.current.values, isSave: "true" };
    if (
      data?.selectedTest?.id === null ||
      data?.selectedTest?.id === undefined
    ) {
      return toastMessages.error("Please select test type in order to save");
    }
    if (
      !!data?.patient_firstname === false ||
      !!data?.patient_lastname === false ||
      !data?.patient_firstname.trim() ||
      !data?.patient_lastname.trim()
    ) {
      return toastMessages.error(
        "Please enter patient first and last name in order to save"
      );
    }

    const validate = await formRef?.current?.validateForm(data);
    const errorsOtherThanRequired = Object?.keys(validate)?.filter((key) => {
      if (key === "isAltheaDxNeuropsychotropicMedications") {
        validate[key] = validate[key]?.options;
        return validate[key]?.includes("required") === false;
      }

      if (
        typeof validate[key] === "string" &&
        validate[key]?.includes("required") === false &&
        validate[key] !==
          "additionalClinician field must have at least 1 items" &&
        validate[key]?.includes("skippedDocs must be a `boolean") === false
      )
        return true;
      return false;
    });

    let errors: any[] = [];
    Object.entries(validate).forEach(([key, value]) => {
      if (errorsOtherThanRequired.includes(key)) {
        if (Array.isArray(value)) {
          value.map((error, index) => {
            if (!!error)
              errors.push(`\n${key}${index + 1} ${JSON.stringify(error)}`);
          });
        } else errors.push(value);
      }
    });

    if (errorsOtherThanRequired.length > 0)
      return toastMessages.error(`${errors.join(",")}`);

    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    };
    const options: OnlineOrderOptions = {
      data,
      config,
      selectedOrganization:
        data.organization_id ?? selectedOrganization?.id ?? "",
      selectedPhysician: data?.clinician_id ?? selectedPhysician.id,
      onError: (message: string) => toastMessages.error(message),
      onSuccess: (message: string) => {
        history.replace("/test-orders");
        RefetchDashboard();
      },
    };
    if (screen === "edit online order") {
      updateOrder(options);
    } else {
      createOrder(options);
    }
  }

  async function handleDeleteOrder() {
    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    };
    try {
      setDeletingOrder(true);
      const response = await http.discardOrder(
        `v1/orders/new-flow/${formRef.current.values.orderId}`,
        config
      );
      console.log({ deleteOrderResponse: response });
      history.replace("/test-orders");
      setDeletingOrder(false);
    } catch (error: any) {
      toastMessages.error(error?.message ?? "Cannot delete order");
      setDeletingOrder(false);
    }
  }

  function handleDiscardTestOrder() {
    history.replace("/test-orders");
    formRef.current.resetForm();
  }

  function showBlockButton() {
    let show = false;
    let areSameUser = selectedUser?.id == profile?.id;
    let userStatus = selectedUser?.status;

    if (userStatus?.toLowerCase() === "pending") {
      show = false;
      return show;
    }

    if (profile?.roles?.includes("Office Manager") && !areSameUser) {
      show = true;
      return show;
    }

    if (profile?.roles?.includes("Physician") && !areSameUser) {
      show = true;
      return show;
    }

    return show;
  }
  const shouldRenderEditButton = () => {
    const isOfficeManager =
      profile?.roles?.includes("Office Manager") ||
      profile?.profile?.roles?.includes("Office Manager");
    const isPhysician =
      profile?.roles?.includes("Physician") ||
      profile?.profile?.roles?.includes("Physician");
    const isCOA =
      profile?.roles?.includes("Collaborative Order Agreement") ||
      profile?.profile?.roles?.includes("Collaborative Order Agreement");

    return (
      !(isCOA && location?.pathname?.includes("pathlabs")) &&
      (isOfficeManager || isPhysician) &&
      location?.pathname !== "/help"
    );
  };

  function handleFormAction() {
    if (formSubmit) return;

    return click1();
  }

  function handleDownloadPDF() {
    if (downloading) return;
    let data = formRef.current.values;
    let token = authState?.accessToken?.accessToken ?? "";
    let fileName = `${data?.orderId}_${
      !!data.patientName
        ? data?.patientName == "N/A"
          ? "Patient"
          : data?.patientName
        : "Patient"
    }_${data?.selectedTest.name ?? ""}`;
    if (token) {
      setDownloading(true);
      DownloadHelper.downloadMultiplePDF(
        [{ id: data.orderId, fileName }],
        token,
        false,
        (message: any) => {
          toastMessages.error(message);
          setDownloading(false);
        },
        () => {
          setDownloading(false);
        }
      );
    }
  }
  async function isOA() {
    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    };
    try {
      const { statusCode, data } = await http.viewSOF(
        `v1/order-agreement/${selectedPhysician?.id}`,
        config
      );
      if (statusCode === 200 && data) {
        setData(data);
      }
    } catch (error: any) {}
  }

  const [prevSelectedPathLabId, setPrevSelectedPathLabId] = useState(
    selectedPathLab?.id
  );
  useEffect(() => {
    if (
      isCOA &&
      selectedPhysician?.id &&
      (prevSelectedPathLabId !== selectedPathLab?.id ||
        history?.location?.pathname === "/approvals" ||
        history?.location?.pathname === "/test-orders" ||
        history?.location?.pathname === "/dashboard") &&
      !dualRoleCOA
    ) {
      isOA();
    }
    setPrevSelectedPathLabId(selectedPathLab?.id);
  }, [selectedPathLab?.id, selectedPhysician?.id]);

  const convertedPathLabIdFromArray = data?.map(
    (item: any) => item?.pathLab?.id
  );

  switch (screen) {
    case "edit online order":
      return (
        <motion.div
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`flex flex-wrap items-center justify-end w-full ${
            mode === "edit" || formRef?.current?.values?.status < 40
              ? "w-4/6"
              : "w-3/6"
          }`}
          transition={{ duration: 0.5 }}
        >
          {mode === "edit" && (
            <Fragment>
              <Button
                btnType="secondary"
                className={"py-1.5 lg:mr-2 xl:mr-5 2xl:mr-5 tab:my-1 tab:mx-1"}
                onClick={printOrder}
                title={"Print"}
              />
              <Button
                btnType="secondary"
                className={"py-1.5 lg:mr-2 xl:mr-5 2xl:mr-5 tab:my-1 tab:mx-1"}
                // onClick={download}
                submitting={downloading}
                onClick={handleDownloadPDF}
                title={"Download Order"}
              />
            </Fragment>
          )}
          <Button
            btnType="secondary"
            className={"py-1.5 lg:mr-2 xl:mr-5 2xl:mr-5 tab:my-1 tab:mx-1"}
            // onClick={() => {
            //   formRef.current.resetForm()
            //   history.replace('/test-orders')
            // }}
            disabled={savingOrder || formSubmit}
            onClick={() => {
              dispatch(
                NotificationActions.showNotification({
                  type: "error",
                  hideClose: true,
                  message:
                    "Are you sure you want to exit? all the changes will be discarded",
                  title: "Online Order",
                  customFunction: handleDiscardTestOrder,
                })
              );
            }}
            title={"discard changes"}
          />
          {formRef &&
            formRef.current &&
            formRef?.current?.values?.status < 40 && (
              <Button
                btnType="secondary"
                className={"py-1.5 lg:mr-2 xl:mr-5 2xl:mr-5 tab:my-1 tab:mx-1"}
                // onClick={() => {
                //   formRef.current.resetForm()
                //   history.replace('/test-orders')
                // }}
                disabled={deletingOrder}
                submitting={deletingOrder}
                onClick={() => {
                  dispatch(
                    NotificationActions.showNotification({
                      type: "error",
                      hideClose: true,
                      message: "Are you sure you want to delete order?",
                      title: "Online Order",
                      customFunction: handleDeleteOrder,
                    })
                  );
                }}
                title={"delete order"}
              />
            )}
          <Button
            btnType="secondary"
            submitting={savingOrder}
            disabled={savingOrder || saveAndExit === true || formSubmit}
            className={"py-1.5 lg:mr-2 xl:mr-5 2xl:mr-5 tab:my-1 tab:mx-1"}
            onClick={handleSaveAndExit}
            title={"save and exit"}
          />
          <Button
            btnType="secondary"
            className={"py-1.5 tab:my-1 tab:mx-1"}
            disabled={savingOrder || formSubmit}
            onClick={handleReviewAndEdit}
            title={mode}
          />
          <Button
            btnType="primary"
            onClick={handleFormAction}
            // disabled={!submit && clinicalInfoValid}
            disabled={
              savingOrder ||
              submit === false ||
              formSubmit ||
              (clinicalInfoValid === false &&
                (selectedTest.id == 2 ||
                  selectedTest.id == 3 ||
                  selectedTest.id == 5 ||
                  selectedTest?.id == 4 ||
                  selectedTest?.id == 8)) ||
              (selectedTest?.id == 8 &&
                formRef?.current?.values?.isPhysicianSignPermission == false &&
                profile?.profile?.roles?.length == 1 &&
                profile?.profile?.roles.includes("Physician")) ||
              (formRef?.current?.values?.isPhysicianSignPermission == false &&
                profile?.profile?.roles.includes(
                  "Collaborative Order Agreement"
                ) &&
                profile?.profile?.id !== selectedPhysician?.id)
            }
            submitting={formSubmit}
            className={
              "tab:my-1 tab:mx-1 py-1.5 lg:ml-2 xl:ml-5 2xl:ml-5 my-0 'border border-primary"
            }
            // title={profile && Array.isArray(profile.roles) && profile.roles.includes('Physician') ? 'Submit' : 'Send for approval'}
            // title={createOrderButtonTitle()}
            title={buttonTitle}
          />
        </motion.div>
      );
    case "online order":
      return (
        <motion.div
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={
            savingOrder || formSubmit ? "flex items-center justify-between" : ""
          }
          transition={{ duration: 0.5 }}
        >
          {mode === "edit" && (
            <Fragment>
              <Button
                btnType="secondary"
                className={"py-1.5 mr-5"}
                onClick={printOrder}
                title={"Print"}
              />
              <Button
                btnType="secondary"
                className={"py-1.5 mr-5"}
                onClick={download}
                disabled
                title={"Download Order"}
              />
            </Fragment>
          )}
          <Button
            btnType="secondary"
            className={"py-1.5 mr-5 tab:mr-2"}
            disabled={savingOrder || formSubmit}
            onClick={() => {
              dispatch(
                NotificationActions.showNotification({
                  type: "error",
                  hideClose: true,
                  message:
                    "Are you sure you want to exit? all the changes will be discarded",
                  title: "Online Order",
                  customFunction: handleDiscardTestOrder,
                })
              );
            }}
            title={"discard"}
          />

          <Button
            btnType="secondary"
            submitting={savingOrder}
            // disabled={formSubmit}
            disabled={savingOrder || saveAndExit === true || formSubmit}
            className={"py-1.5 mr-5 tab:mr-2"}
            onClick={handleSaveAndExit}
            title={"save and exit"}
          />

          <Button
            btnType="secondary"
            className={`py-1.5 ${mode === "edit" && "tab:mt-2"}`}
            onClick={handleReviewAndEdit}
            disabled={savingOrder || formSubmit}
            title={mode}
          />
          <Button
            btnType="primary"
            onClick={handleFormAction}
            disabled={
              savingOrder ||
              submit === false ||
              formSubmit ||
              (clinicalInfoValid === false &&
                (selectedTest.id == 2 ||
                  selectedTest.id == 3 ||
                  selectedTest.id == 5 ||
                  selectedTest?.id == 4 ||
                  selectedTest?.id == 8)) ||
              (selectedTest?.id == 8 &&
                formRef?.current?.values?.isPhysicianSignPermission == false &&
                profile?.profile?.roles?.length == 1 &&
                profile?.profile?.roles.includes("Physician")) ||
              (formRef?.current?.values?.isPhysicianSignPermission == false &&
                profile?.profile?.roles.includes(
                  "Collaborative Order Agreement"
                ) &&
                profile?.profile?.id !== selectedPhysician?.id)
            }
            submitting={formSubmit}
            className={
              "py-1.5 lg:ml-2 xl:ml-5 2xl:ml-5 tab:ml-2 my-0 'border border-primary"
            }
            title={buttonTitle}
          />
        </motion.div>
      );
    case "create order":
      return (
        <motion.div
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {location?.pathname !== "/help" && (
            <>
              {/* <Button
              btnType="secondary"
              onClick={handlePDFOrder}
              className={'py-1.5 ml-5 my-0 font-AvenirNext-DemiBold'}
              disabled={isPLU_User || profile?.fetching}
              showTooltip={isPLU_User}
              tooltipText={isPLU_User ? 'You do not have permission to place orders as a Path Lab User' : ''}
              title={'new pdf order'}
            /> */}
              <Button
                btnType="primary"
                onClick={handleCreateOnlineOrder}
                className={
                  "py-1.5 ml-5 my-0 'border border-primary font-AvenirNext-DemiBold"
                }
                title={"new online order"}
                showTooltip={
                  isPLU_User ||
                  (isCOA && selectedPhysician?.id !== profile?.profile?.id)
                }
                tooltipText={
                  isPLU_User
                    ? "You do not have permission to place orders as a Path Lab User"
                    : isCOA && selectedPathLab?.id === undefined
                    ? "Please select a path lab first"
                    : selectedPhysician?.id === undefined
                    ? "Please select a clinician first"
                    : convertedPathLabIdFromArray?.includes(
                        selectedPathLab?.id
                      ) === false
                    ? "You cannot place an order as the order agreement does not exist in your selected path lab"
                    : ""
                }
                disabled={
                  isPLU_User ||
                  profile?.fetching ||
                  (isCOA &&
                    (selectedPathLab === null ||
                      selectedPhysician === null ||
                      convertedPathLabIdFromArray?.includes(
                        selectedPathLab?.id
                      ) === false) &&
                    !dualRoleCOA)
                }
              />
            </>
          )}
        </motion.div>
      );
    case "invite screen":
      return (
        <motion.div
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={"flex items-center justify-between"}
          transition={{ duration: 0.5 }}
        >
          <Button
            btnType="secondary"
            onClick={() => history.replace("/manage/portal-users")}
            title={"Cancel"}
          />

          <Button
            btnType="primary"
            submitting={submitting}
            disabled={!submit || submitting}
            onClick={click1}
            className={`py-1.5 ml-5 my-0 ${
              !submit
                ? "border border-primaryDisabled"
                : "border border-primary"
            }`}
            title={"invite"}
          />
        </motion.div>
      );
    case "help screen":
      return (
        <motion.div
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={"flex items-center justify-between"}
          transition={{ duration: 0.5 }}
        ></motion.div>
      );
    case "notification settings":
      return (
        <motion.div
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={"flex items-center justify-between"}
          transition={{ duration: 0.5 }}
        >
          <Button
            btnType="primary"
            className={"py-1.5 ml-5 my-0 font-AvenirNext-DemiBold"}
            onClick={handleCancel}
            title={"SAVE"}
          />
        </motion.div>
      );
    case "editing organization":
      return (
        <motion.div
          key={screen}
          className={formSubmit ? "flex items-center justify-between" : ""}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Button
            btnType="secondary"
            className={"py-1.5 ml-5 my-0 font-AvenirNext-DemiBold"}
            onClick={handleCancel}
            title={"cancel"}
          />
          <Button
            btnType="primary"
            submitting={formSubmit}
            disabled={!submit || formSubmit}
            onClick={handleFormAction}
            className={`py-1.5 ml-5 my-0 font-semibold ${
              !submit
                ? "border border-primaryDisabled"
                : "border border-primary"
            }`}
            title={"save"}
          />
        </motion.div>
      );
    case "organization detail":
      return (
        <motion.div
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {shouldRenderEditButton() && (
            <Button
              btnType="primary"
              onClick={() => setButtonState("editing organization")}
              className={"py-1.5 ml-5 my-0 shadow font-AvenirNext-DemiBold"}
              disabled={
                profile?.roles?.includes("Office Manager") === false
                  ? true
                  : false
              }
              title={"Edit"}
            />
          )}
        </motion.div>
      );
    case "create organization":
      return (
        <motion.div
          className="flex items-center"
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Button
            btnType="secondary"
            className={"py-1.5"}
            onClick={handleDiscard}
            title={"discard"}
          />
          <Button
            btnType="primary"
            submitting={formSubmit}
            disabled={!submit || formSubmit}
            onClick={handleFormAction}
            className={`py-1.5 ml-5 my-0 ${
              !submit
                ? "border border-primaryDisabled"
                : "border border-primary"
            }`}
            title={"create"}
          />
        </motion.div>
      );
    case "portal-users":
      return (
        <motion.div
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {(profile?.profile?.roles?.includes("Office Manager") ||
            profile?.profile?.roles?.includes("PathLab User") ||
            profile?.profile?.roles?.includes(
              "Collaborative Order Agreement"
            ) ||
            profile?.roles?.includes("Office Manager") ||
            profile?.roles?.includes("PathLab User") ||
            profile?.roles?.includes("Collaborative Order Agreement")) && (
            <Button
              btnType="primary"
              onClick={handleInviteUser}
              title={"Invite Users"}
            />
          )}
        </motion.div>
      );
    case "UserProfile":
      return (
        <motion.div
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`flex items-center`}
          transition={{ duration: 0.5 }}
        >
          {showBlockButton() && (
            <Button
              btnType="secondary"
              onClick={handleUserStatus}
              submitting={updatingStatus}
              disabled={updatingStatus || gettingUser}
              title={
                selectedUser && selectedUser?.status.toLowerCase() === "active"
                  ? "BLOCK"
                  : "ACTIVE"
              }
              className={"mr-6"}
            />
          )}

          {/* {showEditButton() && <Button
            btnType="primary"
            onClick={handleUpdatePortalUser}
            title={'EDIT'}
            disabled={gettingUser}
          />} */}
          <Button
            btnType="primary"
            onClick={handleUpdatePortalUser}
            title={"EDIT"}
            disabled={gettingUser}
          />
        </motion.div>
      );
    case "UpdatePortalUser":
      return (
        <motion.div
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={formSubmit ? "flex items-center justify-between" : ""}
          transition={{ duration: 0.5 }}
        >
          <Button
            btnType="secondary"
            onClick={handleCancelInUpdatePortalUser}
            title={"CANCEL"}
            className={"mr-6"}
          />
          <Button
            btnType="primary"
            submitting={formSubmit}
            disabled={!submit || formSubmit}
            // disabled={true}
            onClick={handleFormAction}
            // className={`py-1.5 ml-5 my-0 ${
            //   !submit
            //     ? "border border-primaryDisabled"
            //     : "border border-primary"
            // }`}
            title={"save"}
          />
        </motion.div>
      );
    case "my profile":
      return (
        <motion.div
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Button
            btnType="primary"
            onClick={handleEditMyProfile}
            className={"py-1.5 px-4 ml-5 my-0 font-semibold shadow"}
            title={"EDIT"}
          />
        </motion.div>
      );
    case "edit my profile":
      return (
        <motion.div
          key={screen}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={formSubmit ? "flex items-center justify-between" : ""}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Button
            btnType="secondary"
            onClick={handleCancelInEditMyProfile}
            title={"CANCEL"}
            className={"py-1.5 ml-5 my-0 font-semibold"}
          />
          <Button
            btnType="primary"
            submitting={formSubmit}
            disabled={!submit || formSubmit}
            // disabled={true}
            onClick={handleFormAction}
            className={`py-1.5 ml-5 my-0 font-semibold ${
              !submit
                ? "border border-primaryDisabled"
                : "border border-primary"
            }`}
            title={"save"}
          />
        </motion.div>
      );
    // case 'shipment':
    //   return (
    //     <motion.div
    //       key={screen}
    //       initial={{ opacity: 0 }}
    //       animate={{ opacity: 1 }}
    //       exit={{ opacity: 0 }}
    //       transition={{ duration: 0.5 }}
    //     >

    //     <Button
    //         btnType="secondary"
    //         onClick={()=>{}}
    //         title={'ASSOCIATE EXISTING LABEL'}
    //         className={'py-1.5 ml-5 my-0 font-semibold'}
    //       />
    //       <Button
    //         btnType="primary"
    //         // disabled={true}
    //         onClick={()=>{}}
    //         className={`py-1.5 ml-5 my-0 font-semibold `}
    //         title={'GENERATE NEW LABEL'}
    //       />
    //     </motion.div>
    //   )
    default:
      return null;
  }
}

const mapStateToProps = (state: any): any => ({
  screen: state.ButtonState.screen,
  submit: state.ButtonState.submit,
  saveAndExit: state.ButtonState.saveAndExit,
  hideButton: state.ButtonState.hideButton,
  profile: state.Profile,
  formSubmit: state.ButtonState.formSubmit,
  clinicalInfoValid: state.OnlineOrderForm.clinicalInfoValid,
  submitting: state.InviteUser.submitting,
  selectedUser: {
    ...state?.PortalUsers?.selectedUser?.user,
    ...state?.PortalUsers?.selectedUser,
  },
  gettingUser: state.PortalUsers.fetching,
  mode: state.OnlineOrderForm.mode,
  selectedTest: state.OnlineOrderForm,
  savingOrder: state.OnlineOrderForm.savingOrder,
});

const mapDispatchToProps = {
  toggleSubmit: ButtonStateActions.toggleSumbit,
  setButtonState: ButtonStateActions.setButtonState,
  createOrder: createOnlineOrder,
  updateOrder: updateOnlineOrder,
  fetchDashboard: DashboardActions.fetchDashboard,
  toggleMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(RenderButtons);
