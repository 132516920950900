import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import PortalUsersActions from "../../Store/Actions/PortalUsersActions"
import toastMessages from "../helpers/toastMessage"
import { useOktaAuth } from "@okta/okta-react"
import { useHistory } from "react-router-dom"

function useEnableLOMN(physicianSignPermission?: any, initialPhysicianID?: any) {
  const dispatch: any = useDispatch()
  const { authState } = useOktaAuth()
  const [disabled, setDisabled] = useState(false)
  const { data: OrgData } = useSelector((state: any) => state.Organizations)
  const { selectedOrganization, selectedPhysician, isPhysicianSignPermission } = useSelector((state: any) => state.OnlineOrderForm)
  const { profile } = useSelector((state: any) => state.Profile)
  const history = useHistory()
  function handleEsignatureChecks(selectedPhysician: any) {
    if (selectedPhysician?.sofs?.length > 0) {
      setDisabled(false)
    } else
      setDisabled(true)
  }

  function determineDualRole(selectedPhysician: any) {
    let foundOrganization = null
    let physicianOrganizationRoles = !!selectedPhysician?.organizationRoles ? selectedPhysician?.organizationRoles : !!selectedPhysician?.organizationRoles ? selectedPhysician?.organizationRoles : null

    if (physicianOrganizationRoles) {
      if (typeof (physicianOrganizationRoles) === 'string')
        foundOrganization = JSON?.parse(physicianOrganizationRoles)[selectedOrganization?.id]
      else
        foundOrganization = physicianOrganizationRoles[selectedOrganization?.id]
    }

    if (foundOrganization && foundOrganization?.roles?.includes("Physician") && selectedPhysician?.id === profile?.id) {
      setDisabled(false)
    } else {
      handleEsignatureChecks(selectedPhysician)
    }
  }

  function getUserData() {
    // testing
    if (authState?.accessToken)
      dispatch(PortalUsersActions.fetchPortalUserById(selectedPhysician?.id, {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      }, (message: string) => {
        toastMessages.error(message)
      }))
  }

  useEffect(() => {
    if (selectedPhysician?.id && history.location.pathname.includes('online-order'))
      getUserData()
  }, [selectedPhysician?.id])

  useEffect(() => {
    let physician: any = null
    if (initialPhysicianID) {
      OrgData?.some((organization: any) => organization?.users?.filter((user: any) => {
        if (user.id === initialPhysicianID) {
          physician = user
          return true
        }
        return false
      }))
    } else {
      physician = selectedPhysician
    }
    if (selectedOrganization && physician)
      determineDualRole(physician)
  }, [selectedOrganization, selectedPhysician, isPhysicianSignPermission, physicianSignPermission])

  return disabled
}

export default useEnableLOMN