/* eslint-disable semi */
/* eslint-disable quotes */
/* eslint-disable comma-dangle */

import React from "react";
import LandingPage from "./Pages/Auth/LandingPage";
import Logout from "./components/global/LogoutComponent";
import SignUpPage from "./Pages/Auth/SignUpPage";
import ExistingSignUpPage from "./Pages/Auth/ExistingSignUp";
import VerifyEmail from "./Pages/Auth/VerifyEmail";
import VerificationSuccess from "./Pages/Auth/VerificationSuccess";
import NotFound from "./Pages/NotFound";
import SetPasswordScreen from "./Pages/Auth/SetPasswordScreen";
import ProfileCompletion from "./Pages/Auth/ProfileCompletion";
import DashboardScreen from "./Pages/Admin/DashboardScreen";
import ManageHomeScreen from "./Pages/Admin/ManageScreen/index";
import NewOrganizationScreen from "./Pages/Admin/ManageScreen/NewOrganization";
import OrganizationDetailScreen from "./Pages/Admin/ManageScreen/OrganizationDetailScreen";
import PortalUsersListScreen from "./Pages/Admin/ManageScreen/PortalUsers/UsersList";
import PortalInviteUsersScreen from "./Pages/Admin/ManageScreen/PortalUsers/InviteUsers";
import ApprovalsScreen from "./Pages/Admin/Approvals/ApprovalsScreen";
import TestOrdersScreen from "./Pages/Admin/Test Orders";
import ReferralOrdersScreen from "./Pages/Admin/ReferralOrders";
import CreateOrderScreen from "./Pages/Admin/Test Orders/CreateOrderScreen";
import OrderCreated from "./Pages/Admin/Test Orders/OrderCreated";
import UserProfile from "./Pages/Admin/ManageScreen/PortalUsers/UserProfile";
import ProfileHomeScreen from "./Pages/Admin/Profile";
import ResetPassword from "./Pages/Auth/ResetPassword";
import ForgetPassword from "./Pages/Auth/ForgetPassword";
import EditMyProfile from "./Pages/Admin/ManageScreen/PortalUsers/EditMyProfile";
import RedirectEmail from "./Pages/Auth/RedirectEmailLogin";
import UpdatePortalUserDetail from "./Pages/Admin/ManageScreen/PortalUsers/UpdatePortalUserDetail";
import AccessDenied from "./Pages/Auth/AccessDenied";
import SummaryScreen from "./Pages/Admin/Test Orders/SummaryScreen";
import UpdateOrderScreen from "./Pages/Admin/Test Orders/UpdateOrderScreen";
import ApprovalsReviewScreen from "./Pages/Admin/Approvals/ApprovalsReview";
import OrdersAuthorized from "./Pages/Admin/Test Orders/OrderAuthorized";
import OrderReview from "./Pages/Admin/Test Orders/OrderReview";
import DocumentsScreen from "./Pages/Admin/Documents/DocumentsScreen";
import PathLabDetailScreen from "./Pages/Admin/ManageScreen/PathLabDetailScreen";
import HelpSection from "./components/HelpScreen/HelpSection";
import NotificationsPage from "./Pages/Notifications";
import NotificationSettingsLayout from "./Pages/Notifications/NotificationsSettingsLayout";
import OrganisationSettings from "./Pages/Notifications/OrganisationSettings";
import PersonalSettings from "./Pages/Notifications/PersonalSettings";
import DownloadStorage from "./Pages/DownloadStorage";
import ShipmentsLayout from "./Containers/ShipmentsLayout";
import ShipmentsHomeScreen from "./Pages/Admin/Shipments";

export default [
 //  {
 //   path: "/",
 //   exact: true,
 //   auth: false,
 //   title: "Home | CSTL",
 //   index: true,
 //   element: <LandingPage />,
 //   template: "normal",
 //   templateClassName: "flex flex-col h-screen overflow-hidden",
 //  },
 {
  path: "/login",
  auth: false,
  title: "Login | CSTL",
  index: false,
  element: <RedirectEmail />,
  template: "none",
  childContainerClassName: "",
 },
 //  {
 //   path: "/login/callback",
 //   auth: false,
 //   title: "Login | CSTL",
 //   index: false,
 //   element: <LoginCallback />,
 //   template: "none",
 //  },
 //  {
 //   path: "/login-process",
 //   auth: false,
 //   title: "Login | CSTL",
 //   index: false,
 //   element: <LoginProcess />,
 //   template: "none",
 //  },
 {
  path: "/signup",
  auth: false,
  title: "Signup | CSTL",
  index: false,
  element: <SignUpPage />,
  template: "none",
  childContainerClassName: "",
 },
 {
  path: "/signup-existing",
  title: "Existing Signup | CSTL",
  auth: false,
  index: false,
  element: <ExistingSignUpPage />,
  template: "normal",
  templateClassName: "h-screen flex flex-col",
  childContainerClassName: "",
 },
 {
  path: "/forget-password",
  title: "Forgot Password | CSTL",
  auth: false,
  index: false,
  element: <ForgetPassword />,
  template: "none",
  templateClassName: "h-screen flex flex-col",
  childContainerClassName: "",
 },
 {
  path: "/initial-password",
  auth: false,
  title: "Reset Password | CSTL",
  index: false,
  element: <SetPasswordScreen />,
  childContainerClassName: "",
  template: "none",
 },
 {
  path: "/reset-password",
  auth: false,
  title: "Reset Password | CSTL",
  index: false,
  element: <ResetPassword />,
  template: "none",
 },
 {
  path: "/profile-completion",
  auth: true,
  title: "Profile Completion | CSTL",
  index: false,
  element: <ProfileCompletion />,
  template: "none",
 },
 {
  path: "/verify-email",
  title: "Verify Email | CSTL",
  auth: false,
  index: false,
  element: <VerifyEmail />,
  template: "normal",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/verification-complete",
  title: "Verification Complete | CSTL",
  auth: false,
  index: false,
  element: <VerificationSuccess />,
  template: "normal",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/dashboard",
  title: "Manage | CSTL",
  auth: true,
  index: false,
  element: <DashboardScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/test-orders",
  title: "Test Orders | CSTL",
  auth: true,
  index: false,
  element: <TestOrdersScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/referral-orders",
  title: "Referral Orders | CSTL",
  auth: true,
  index: false,
  element: <ReferralOrdersScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/test-orders/online-order",
  title: "Online Order | CSTL",
  auth: true,
  index: false,
  element: <CreateOrderScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/test-orders/online-order/:id",
  title: "Update Online Order | CSTL",
  auth: true,
  index: false,
  element: <UpdateOrderScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/test-orders/order-review/:id",
  title: "Update Online Order | CSTL",
  auth: true,
  index: false,
  element: <OrderReview />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/test-orders/summary/:id",
  title: "Order Summary | CSTL",
  auth: true,
  index: false,
  element: <SummaryScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/test-orders/order-created",
  title: "Order Created | CSTL",
  auth: true,
  index: false,
  element: <OrderCreated />,
  template: "normal",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/approvals/order-authorized",
  title: "Order Authorized | CSTL",
  auth: true,
  index: false,
  element: <OrdersAuthorized />,
  template: "normal",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/approvals",
  title: "Approvals | CSTL",
  auth: true,
  index: false,
  element: <ApprovalsScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/approvals/review",
  title: "Approvals | CSTL",
  auth: true,
  index: false,
  element: <ApprovalsReviewScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 // { path: '/manage/*', title: 'Manage | CSTL', auth: true, index: false, element: <ManageScreen />, template: 'admin', templateClassName: 'h-screen flex flex-col', },
 {
  path: "/shipments",
  title: "Shipments | CSTL",
  auth: true,
  index: true,
  element: <ShipmentsHomeScreen screen="home" />,
  template: "admin",
  templateClassName: "h-screen flex flex-col overflow-hidden",
 },
 {
  path: "/shipments/unshipped-orders",
  title: "Shipments | CSTL",
  auth: true,
  index: true,
  element: <ShipmentsHomeScreen screen="unshipped-orders" />,
  template: "admin",
  templateClassName: "h-screen flex flex-col overflow-hidden",
 },
 {
  path: "/shipments/active-labels",
  title: "Shipments | CSTL",
  auth: true,
  index: true,
  element: <ShipmentsHomeScreen screen={"active-labels"} />,
  template: "admin",
  templateClassName: "h-screen flex flex-col overflow-hidden",
 },
 {
    path: "/shipments/pick-ups",
    title: "Shipments | CSTL",
    auth: true,
    index: true,
    element: <ShipmentsHomeScreen screen={"pick-ups"} />,
    template: "admin",
    templateClassName: "h-screen flex flex-col overflow-hidden",
 },
 {
    path: "/shipments/schedule-pickup",
    title: "Shipments | CSTL",
    auth: true,
    index: true,
    element: <ShipmentsHomeScreen screen={"schedule-pickup"} />,
    template: "admin",
    templateClassName: "h-screen flex flex-col overflow-hidden",
  },
 {
  path: "/manage",
  title: "Manage | CSTL",
  auth: true,
  index: true,
  element: <ManageHomeScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col overflow-hidden",
 },
 {
  path: "/manage/organizations",
  title: "Organizations | CSTL",
  auth: true,
  index: true,
  element: <ManageHomeScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col overflow-hidden",
 },
 {
  path: "/manage/organizations/new",
  title: "Create Organization | CSTL",
  auth: true,
  index: false,
  element: <NewOrganizationScreen headerTitle={"Add Organization"} />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/manage/organizations/:id",
  title: "Organizations | CSTL",
  auth: true,
  index: false,
  element: <OrganizationDetailScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/manage/pathlabs",
  title: "Path Labs | CSTL",
  auth: true,
  index: true,
  element: <ManageHomeScreen isPathLab />,
  template: "admin",
  templateClassName: "h-screen flex flex-col overflow-hidden",
 },
 {
  path: "/manage/pathlabs/:id",
  title: "Path Labs | CSTL",
  auth: true,
  index: true,
  element: <PathLabDetailScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col overflow-hidden",
 },
 {
  path: "/manage/portal-users",
  title: "Users | CSTL",
  auth: true,
  index: false,
  element: <PortalUsersListScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/manage/portal-users/invite",
  title: "Invite Users | CSTL",
  auth: true,
  index: false,
  element: <PortalInviteUsersScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/manage/portal-users/:orgId",
  title: "Users | CSTL",
  auth: true,
  index: false,
  element: <PortalUsersListScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/manage/portal-users/profile/:id",
  title: "User Profile | CSTL",
  auth: true,
  index: false,
  element: <UserProfile />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },

 {
  path: "/manage/portal-users/profile/update/:id",
  title: "User Profile | CSTL",
  auth: true,
  index: false,
  element: <UpdatePortalUserDetail />,
  // element: <UpdatePortalUser />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/help",
  title: "Help | CSTL",
  auth: true,
  index: true,
  element: <HelpSection headerTitle={"Help"} />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/profile",
  title: "Profile | CSTL",
  auth: true,
  index: true,
  element: <ProfileHomeScreen headerTitle={"My Profile"} />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/profile/editprofile",
  title: "Profile | CSTL",
  auth: true,
  index: true,
  element: <EditMyProfile headerTitle={"My Profile"} />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/documents",
  title: "Documents | CSTL",
  auth: true,
  index: true,
  element: <DocumentsScreen />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
 },
 {
  path: "/notifications",
  title: "Notifications | CSTL",
  auth: true,
  index: true,
  element: <NotificationsPage headerTitle="Notifications" />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
  childContainerClassName: "bg-[#F9F9FA]",
 },
 {
  path: "/notifications/settings/organization-settings",
  title: "Organization Settings | CSTL",
  auth: true,
  index: true,
  element: <OrganisationSettings headerTitle="Notifications Settings" />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
  childContainerClassName: "bg-[#F9F9FA]",
 },
 {
  path: "/notifications/settings/personal-settings",
  title: "Personal Settings | CSTL",
  auth: true,
  index: true,
  element: <PersonalSettings headerTitle="Notifications Settings" />,
  template: "admin",
  templateClassName: "h-screen flex flex-col",
  childContainerClassName: "bg-[#F9F9FA]",
 },
 {
    path: "/unauthorized",
    title: "Access Denied | CSTL",
    auth: false,
    index: false,
    element: <AccessDenied />,
    template: "normal",
    templateClassName: "h-screen flex flex-col",
   },
   
 {
  path: "*",
  title: "Not Found | CSTL",
  auth: false,
  index: false,
  element: <NotFound />,
  template: "normal",
  templateClassName: "h-screen flex flex-col",
 },
];
