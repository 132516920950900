import reduxActionType from "../../models/reduxActionType";
import { LOGOUT, TOGGLE_SESSION_STATE } from "../Actions/types";

const initialState = {
  sessionDestroyed: false
}

function SessionReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case TOGGLE_SESSION_STATE:
      return {
        ...state,
        sessionDestroyed: action.payload
      }
    default:
      return state;
  }
}

export default SessionReducer