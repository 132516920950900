import moment from "moment";
import NotificationCardProps from "../../models/notificationsModels/notificationCard.model";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_NOTIFICATION_UNREAD_COUNT } from "../../Store/Actions/types";
import React from "react";

function commentsTruncate(message: string) {
  let finalText = message;
  const splitMessage = message.split('information');

  if (splitMessage.length > 1) {
    let comments = splitMessage[1];

    if (comments.length > 100) {
      comments = comments.slice(0, 100) + '...';
    }

    finalText = `${splitMessage[0]}information ${comments}`;
  }

  return finalText;
}

function NotificationCard({ message, time, contentType, isNew, hideBorder, onClick, iconURL }: NotificationCardProps): JSX.Element {
  const history = useHistory()
  const dispatch = useDispatch()
  const { unReadCount } = useSelector((state: any) => state.UserNotifications)

  function decreaseCount() {
    let latestCount = unReadCount - 1
    if (latestCount >= 0)
      dispatch({ type: UPDATE_NOTIFICATION_UNREAD_COUNT, payload: latestCount })
  }
  return <button onClick={onClick ? () => {
    onClick(history)
    isNew && decreaseCount()
  } : () => {
    isNew && decreaseCount()
  }} className={`bg-white hover:bg-[#EDEFF1] w-full p-4 flex justify-between ${!hideBorder ? 'border-b' : ''} text-left`}>
    <div className="flex items-center">
      <div className="mx-5 shrink-0 h-[36px] w-[36px] rounded-full bg-[#F9F9FA] border border-[#D0D4DB] justify-center items-center flex flex-col self-center overflow-hidden">
        <img src={iconURL} className="h-full w-full object-contain" />
      </div>
      <div>
        {/* <p className="font-royalBlue">{commentsTruncate(message)}</p> */}
        <React.Suspense fallback={<p>Loading...</p>}>
          <div className="font-royalBlue" dangerouslySetInnerHTML={{ __html: commentsTruncate(message) }} />
        </React.Suspense>
        <p className="text-[#647188] text-sm font-normal">{moment(time).format('MM-DD-YYYY hh:mm a')?.replaceAll('-', '/')}</p>
      </div>
    </div>
    {isNew && <div className="h-[8px] w-[8px] bg-primary rounded-full" />}
  </button>
}

export default NotificationCard;