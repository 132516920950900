interface DataItem {
  key: string;
  value: any;
  disabled: boolean;
}

interface Organization {
  1?: { data?: DataItem[] };
  2?: { data?: DataItem[] };
}

interface Values {
  [key: string]: any;
}

export const getFilteredValues = (tableBody: object[]): Values[] => {
  const keys1 = ['PORTAL', 'EMAIL', 'SMS'];
  const keys2 = ['FAX', 'SECURE_EMAIL','PORTAL'];

  const getValues = (organization: Organization): Values => {
    const values: Values = {};

    keys1.forEach((key) => {
      const data = organization[1]?.data?.find(({ key: k }) => k === key);
      if (data) {
        values[key] = data.value;
        values[`${key}_DISABLED`] = data.disabled;
      }
    });

    keys2.forEach((key) => {
      const data = organization[2]?.data?.find(({ key: k }) => k === key);
      if (data) {
        values[key] = data.value;
        values[`${key}_DISABLED`] = data.disabled;
      }
    });

    return values;
  };

  const values: Values[] = tableBody.map(getValues);
  const allKeys = [...keys1, ...keys2];

  return values.filter((item) => !allKeys.every((key) => item[`${key}_DISABLED`]));
};
