/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import OrganizationsList from '../../../components/organism/OrganizationsList'
import ManageScreenLayout from '../../../Containers/ManageScreenLayout'
import ButtonStateActions from '../../../Store/Actions/ButtonStateActions'
import PathLabDetail from '../../../components/ManageScreen/PathLabDetail'
import PathLabList from '../../../components/organism/PathLabList'

function PathLabDetailScreen(): JSX.Element {
    const { id } = useParams<any>()
    const { screen } = useSelector((state: any) => state.ButtonState)
    const dispatch = useDispatch()

    useEffect((): any => {
        dispatch(ButtonStateActions.setButtonState('organization detail'))
    }, [id])

    return <ManageScreenLayout>
        <PathLabList />

        <div className="col-span-3 flex flex-col items-stretch 2xl:h-screen xl:h-screen lg:h-screen">
            <div className="py-5 px-12 flex-none">
                <p className="text-royalBlue font-semibold pb-2">PathLab Detail</p>
            </div>
            <AnimatePresence exitBeforeEnter>
                <motion.div
                    key={screen}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <PathLabDetail id={id} />

                </motion.div>
            </AnimatePresence>
        </div>
    </ManageScreenLayout>
}
export default PathLabDetailScreen
