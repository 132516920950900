/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ExistingSignUpContents from '../../../components/molecular/ExisitingSignUpContents'
import OrganizationScreenSignUpFlow from '../../../components/organism/OrganizationScreenSignUpFlow'
import OrganizationsList from '../../../components/organism/OrganizationsList'
import ManageScreenLayout from '../../../Containers/ManageScreenLayout'
import ButtonStateActions from '../../../Store/Actions/ButtonStateActions'
import NewOrganizationLoadingIndicator from '../../../components/global/LoadingIndicators/NewOrganizationLoadingIndicator'

function NewOrganizationScreen({ headerTitle }: any): JSX.Element {
  const isMounted = useRef(null)
  const dispatch = useDispatch()
  const { profile, fetching, error } = useSelector((state: any) => state.Profile)

  function RenderContent() {
    if (fetching)
      return <NewOrganizationLoadingIndicator />
    if (profile && profile?.roles?.includes('Office Manager') == false)
      return <div className="flex flex-col h-full self-center justify-center items-center w-full col-span-3" >
        <ExistingSignUpContents isPage={false} handleBack={() => { }} handleSupport={() => { }} />
      </div>
    return <OrganizationScreenSignUpFlow
    />
  }

  useEffect(() => {
    if (isMounted.current !== null) { return }
    dispatch(ButtonStateActions.setButtonState('add organization'))
  }, [])


  return <ManageScreenLayout headerTitle={headerTitle}>
    <OrganizationsList />
    <RenderContent />
  </ManageScreenLayout>
}

export default NewOrganizationScreen
