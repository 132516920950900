/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import { Form, Formik } from 'formik'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import CustomInput from '../../../../components/global/CustomInput'
import OrganizationPillBox from '../../../../components/global/OrganizationPillBox'
import ManageScreenLayout from '../../../../Containers/ManageScreenLayout'
import ButtonStateActions, { setButtonState } from '../../../../Store/Actions/ButtonStateActions'
import InviteUserActions from '../../../../Store/Actions/InviteUserActions'
import InviteUserValidation from '../../../../validations/inviteuwervalidation'
import { useOktaAuth } from '@okta/okta-react'
import { formRef } from '../index'
import { useHistory } from 'react-router-dom'
import Associations from '../../../../components/global/InviteUser/Associations'
import toastMessages from '../../../../utils/helpers/toastMessage'
import { useSelector } from 'react-redux'
import PathLabList from '../../../../components/organism/PathLabList'
import PathLabActions from '../../../../Store/Actions/PathLabActions'
import SuffixAndSpecialityActions from '../../../../Store/Actions/SuffixAndSpecialtyActions'

function PortalInviteUsersScreen({ submitForm, fetching, pathLabs: pathLabsList, submitting, loadingProfile, profile, toggleSumbit, organizationsList, loadingOrganizations, loadingOrganizationsError, loadingPathLabs, loadingPathLabsError, setButtonState, getSuffix, getSpeciality }: any): JSX.Element {

  const { authState } = useOktaAuth()
  const history = useHistory()
  const { selectedPathLab } = useSelector(
    (state: any) => state.PathLab
  );
  const dispatch: any = useDispatch()

  // const roles = ['Physician', 'Office Staff', 'Office Manager']

  let initialValues: any

  if (profile && Array.isArray(profile.roles) && profile.roles?.length == 1 && profile?.roles?.includes('Physician')) {
    initialValues = {
      email: '',
      role: 'Physician',
      // members: []
    }
  } else {
    initialValues = {
      email: '',
      role: '',
      // members: []
    }
  }

  const [organizations, setOrganizations] = useState<any[]>([])
  const { members } = useSelector((state: any) => state.InviteUser)
  const [pathLabs, setPathLabs] = useState<any>([])
  const [roles, setRoles] = useState<any[]>([])
  const [role, setRole] = useState<string>('')
  const [fetchingData, setFetchingData] = useState<boolean>(false)
  const { suffixData, specialityData } = useSelector((state: any) => {
    return {
      suffixData: state.Suffix.suffix,
      specialityData: state.Speciality.speciality
    }
  })

  useEffect(() => {
    toggleSumbit(false)
    setButtonState('invite screen')

    if (profile && Array.isArray(profile.roles) && profile?.roles?.length == 1 && (profile?.roles?.includes('Staff') || profile?.roles?.includes('Physician'))) {
      toastMessages.error('You are unauthorized to access the following section')
      return history.replace('/manage/portal-users')
    }

    if (profile?.roles?.includes('PathLab User') || profile?.roles?.includes('Collaborative Order Agreement')) {
      setRoles([{ label: 'Path Lab User', value: 'PathLab User' }])
    } else {
      setRoles([{ label: 'Clinician', value: 'Physician' }, { label: 'Office Manager', value: 'Office Manager' }])
    }
    getSuffix({
      data: {
        token: authState?.accessToken?.accessToken
      },
      onError: () => {
        // alert('error occured')
      }
    })
    getSpeciality({
      data: {
        token: authState?.accessToken?.accessToken
      },
      onError: () => {
        // alert('error occured')
      }
    })

  }, [loadingOrganizations, loadingPathLabs])

  function missingOrganization(): boolean {
    if (organizations.some((organization: any) => organization.selected === true)) { return false }
    return true
  }
  function missingPathlabs(): boolean {
    if (pathLabs.some((pathlab: any) => pathlab.selected === true)) { return false }
    return true
  }

  function handleOrganizationClick(index: number, isValid: boolean) {
    return () => {
      const temp = [...organizations].filter((item: any) => {
        if (role == '')
          return null
        if (role == 'Physician') {
          return profile?.organizationRoles[item.id]?.roles?.includes('Office Manager')
        }
        return item
      })

      temp[index].selected = !temp[index].selected
      setOrganizations(temp)
      // setFetchingData((prevState: boolean) => !prevState)

      const nonSelected = temp.filter(data => data.selected === false).length === organizationsList.length

      if (nonSelected) {
        toggleSumbit(false)
      } else {

        toggleSumbit(isValid)

      }
    }
  }
  function handlePluCOAClick(index: number, isValid: boolean) {

    return () => {
      const temp = [...pathLabs].filter((item: any) => {
        if (role == '')
          return null
        return item
      })
      temp[index].selected = !temp[index].selected
      setPathLabs(temp)
      // setFetchingData((prevState: boolean) => !prevState)
      const nonSelected = temp.filter(data => data.selected === false).length === pathLabs.length

      if (nonSelected) {
        toggleSumbit(false)
      } else {
        toggleSumbit(isValid)

      }
    }
  }
  let prevRole: string | null = null
  function handleChange(name: string, type: 'event' | 'val', data: any, setFieldValue: (name: string, val: any, validate: boolean) => any, validateForm: (values: any) => any, values: any): void {
    function validateFormData(callback: (res: any) => void, name: string, val: string) {
      const updatedValues = { ...values, [name]: val }
      validateForm(updatedValues).then(callback)
    }
    function handleValue(val: any): void {
      if (val === '') {
        toggleSumbit(false);
      } else {
        if (profile.roles.includes('PathLab User') || profile.roles.includes('Collaborative Order Agreement')) {
          validateFormData((res) => {
            if (Object.keys(res).length > 0) {
              toggleSumbit(false)
            } else {
              toggleSumbit(!missingPathlabs());
            }
          }, name, val)
        } else {
          validateFormData((res: any) => {
            if (Object.keys(res).length > 0) {
              toggleSumbit(false)
            } else {
              toggleSumbit(!missingOrganization());
            }
          }, name, val)
        }
      }
      if (name === 'role') {
        if (val !== prevRole) {
          toggleSumbit(false)
          setFieldValue('physicianIds', [], true)
          setFieldValue('role', val, true)
        }
        prevRole = val;
        if (val === 'Office Manager') {
          setFieldValue('suffix', '', false)
          setFieldValue('specialty', '', false)
          setFieldValue('npi', '', false)
        }
      }
    }
    if (type === 'event') {
      setFieldValue(name, data.target.value, true)
      handleValue(data.target.value)
    } else {
      if (name === 'role')
        setRole(data)
      let filteredOrganisations = organizationsList.filter((organisation: any) => {
        if (data === '')
          return false
        if (data === 'Physician' || data === 'Office Manager')
          return profile?.organizationRoles[organisation.id]?.roles?.includes('Office Manager')
        return true
      })

      let filteredPathLabs = pathLabsList.filter((organisation: any) => {
        if (data === '')
          return false
        else if (data === 'PathLab User' || data === 'Collaborative Order Agreement')
          return profile?.organizationRoles[organisation?.id]?.roles?.includes('PathLab User') || profile?.organizationRoles[organisation?.id]?.roles?.includes('Collaborative Order Agreement')
        return true
      })

      if (profile.roles.includes('PathLab User') || profile.roles.includes('Collaborative Order Agreement')) {
        setPathLabs(filteredPathLabs.map((pathlab: any) => { return { name: pathlab.name, id: pathlab.id, selected: false, ...pathlab } }))
      } else {
        setOrganizations(filteredOrganisations.map((organization: any) => { return { name: organization.name, id: organization.id, selected: false, ...organization } }))
      }

      setFieldValue(name, data, true)
      handleValue(data)
    }

  }
  const isValidRole = (val: string): boolean => roles?.some(role => role?.value?.toLowerCase() === val?.toLowerCase())
  function handleSubmit(data: any, { resetForm, setFieldError }: any): void {
    let selectedData;
    if (profile.roles.includes('PathLab User') || profile.roles.includes('Collaborative Order Agreement')) {
      selectedData = pathLabs
    } else {
      selectedData = organizations
    }
    if (submitting) { return }
    if (!isValidRole(data.role)) { return setFieldError('role', `Please use on of the following roles ${roles.map(item => `${item} `)}`) }

    const accessToken = authState?.accessToken?.accessToken ?? ''
    const requestData = {
      ...data,
      // members: [],
      organizationIds: selectedData.filter((org: any) => org.selected === true)?.map((org: any) => org.id)
    }

    const options = {
      accessToken,
      data: requestData,
      onError: (message: any) => {
        toastMessages.error(message ?? 'Invitation Failed', 3000)
      },
      onSuccess: (message: string) => {
        { profile?.roles?.includes('PathLab User') || profile.roles.includes('Collaborative Order Agreement') ? setPathLabs(pathLabsList) : setOrganizations(organizationsList) }

        toastMessages.success(message ?? 'Invitation Successful', 3000)
        resetForm()
        toggleSumbit(false)
        history.push('/manage/portal-users')
      }
    }
    submitForm(options)
  }
  // useEffect(() => {
  //   let foundPathLabs = data?.map((e: any) => {
  //     return {
  //       id: e.id,
  //       label: e?.name,
  //       image: e?.logoUrl,
  //       city: e.city,
  //       state: e.state,
  //       address: e.address,
  //     };
  //   });
  //   if (foundPathLabs) {
  //     setPathLabs(foundPathLabs);
  //   }

  // }, [])
  return <ManageScreenLayout headerTitle={'Invite Users'}>
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className='col-span-4 h-screen overflow-y-auto'>
        <div className="py-5 px-12 flex-none pb-32">
          <p className="text-royalBlue font-semibold">Add User</p>
          <Formik
            initialValues={initialValues}
            isInitialValid={false}
            onSubmit={handleSubmit}
            innerRef={formRef}
            validationSchema={InviteUserValidation}
            render={({ errors, touched, setFieldValue, isValid, values, isSubmitting, validateForm }) => (
              <Form className="">
                <div className="w-full lg:w-5/6 lg:flex justify-between items-center">
                  {/* <div className='h-24 w-5/6 lg:w-4/6 flex pt-1'> */}
                  {profile && Array.isArray(profile.roles) && (profile.roles.includes('PathLab User') || profile.roles.includes('Office Manager') || profile.roles.includes('Collaborative Order Agreement')) &&
                    <CustomInput
                      id={'role'}
                      key={'role'}
                      name={'role'}
                      label={'Role'}
                      disabled={submitting}
                      required
                      isInternal
                      disableSuggestiveFiltering
                      placeholder={'Enter Role'}
                      suggestions={roles}
                      value={values.role === 'Physician' ? 'Clinician' : values?.role}
                      errors={errors}
                      className={'mr-6'}
                      onChange={(val: string) => handleChange('role', 'val', val, setFieldValue, validateForm, values)}
                      touched={touched}
                    />}
                  <CustomInput
                    id={'firstName'}
                    key={'firstName'}
                    name={'firstName'}
                    label={'First Name'}
                    required={true}
                    disabled={submitting}
                    placeholder={'Enter your first name'}
                    className="mr-6"
                    onChange={(val: any) => handleChange('firstName', 'event', val, setFieldValue, validateForm, values)}
                    value={values.firstName}
                    errors={errors}
                    isInternal
                    touched={touched}
                  />
                  <CustomInput
                    id={'lastName'}
                    key={'lastName'}
                    name={'lastName'}
                    label={'Last Name'}
                    required={true}
                    disabled={submitting}
                    placeholder={'Enter your last name'}
                    className="mr-6"
                    onChange={(val: any) => handleChange('lastName', 'event', val, setFieldValue, validateForm, values)}
                    value={values.lastName}
                    errors={errors}
                    isInternal
                    touched={touched}
                  />
                  <CustomInput
                    id={'email'}
                    key={'email'}
                    name={'email'}
                    label={'Email ID'}
                    required
                    disabled={submitting}
                    placeholder={'Enter email ID'}
                    className="mr-6"
                    onChange={(val: any) => handleChange('email', 'event', val, setFieldValue, validateForm, values)}
                    value={values.email}
                    errors={errors}
                    isInternal
                    touched={touched}
                  />
                </div>

                {
                  values?.role === 'Physician' &&
                  <div className="w-full lg:w-4/6 flex justify-between items-center">
                    <CustomInput
                      id={'suffix'}
                      key={'suffix'}
                      name={'suffix'}
                      label={'Suffix'}
                      required={true}
                      placeholder={'Enter suffix'}
                      suggestions={
                        suffixData.length > 0
                          ? suffixData.map((state: any) => ({ label: state.name, value: state.name }))
                          : []
                      }
                      isInternal
                      onChange={(val: string) => handleChange('suffix', 'val', val, setFieldValue, validateForm, values)}
                      // className={`${isCompletion ? 'w-[83%]' : ''}`}
                      value={values?.suffix}
                      errors={errors}
                      touched={touched}
                      className='mr-5 '
                    />
                    <CustomInput
                      id={"specialty"}
                      key={"specialty"}
                      name={"specialty"}
                      label={"Speciality"}
                      required
                      disabled={false}
                      placeholder={"Select Speciality"}
                      suggestions={specialityData.length > 0
                        ? specialityData.map((state: any) => ({ label: state.name, value: state.name }))
                        : []
                      }
                      isInternal
                      onChange={(val: any) => handleChange('specialty', 'val', val, setFieldValue, validateForm, values)}
                      value={values.specialty}
                      errors={errors}
                      touched={touched}
                      className={"mr-5"}
                    />
                    <CustomInput
                      id={"npi"}
                      key={"npi"}
                      name={"npi"}
                      label={"NPI"}
                      required
                      disabled={false}
                      placeholder={"0000000000"}
                      isInternal
                      onChange={(val: any) => handleChange('npi', 'event', val, setFieldValue, validateForm, values)}
                      value={values.npi}
                      errors={errors}
                      touched={touched}
                      className={""}
                    />
                  </div>

                }

                <p className='text-royalBlue mt-5'>
                  <span className='font-semibold '>Note:</span> {role == '' ? 'Please select user role' : role === 'Office Manager' ? 'Please associate an Organization and Clinician' : `Please associate ${role === 'PathLab User' ? 'a Path Lab' : 'an Organization'}`}
                </p>
                <p className="text-royalBlue font-semibold py-3 after:content-['*'] after:ml-1 after:text-requiredRed">Associate with {profile && Array.isArray(profile.roles) && (profile.roles.includes('PathLab User') || profile.roles.includes('Collaborative Order Agreement') ? 'path labs' : 'organizations')}</p>
                {(profile.roles.includes('PathLab User') || profile.roles.includes('Collaborative Order Agreement')) ?
                  loadingPathLabs && !loadingPathLabsError
                    ? <div>
                      <p>Loading...</p>
                    </div>
                    : !loadingPathLabs && loadingPathLabsError
                      ? <div>Something went wrong</div>
                      : role ? <div className={`flex flex-wrap min-w-full ${values?.role === 'Physician' && 'tab:pb-[15%]'}`}>
                        {pathLabs.length > 0 ? pathLabs.map((organization: any, index: number) => <OrganizationPillBox
                          key={organization.id}
                          disabled={fetchingData}
                          // disabled={role === 'Office Manager' || 'PathLab User' || 'Collaborative Order Agreement' ? false : fetchingData}
                          organization={organization}
                          onClick={handlePluCOAClick(index, isValid)}
                        />) : <p>You have no associated path labs to invite PathLabs User</p>}
                      </div> : <p>Please select user role</p>
                  :
                  loadingOrganizations && !loadingOrganizationsError
                    ? <div>
                      <p>Loading...</p>
                    </div>
                    : !loadingOrganizations && loadingOrganizationsError
                      ? <div>Something went wrong</div>
                      : role ? <div className={`flex flex-wrap min-w-full ${values?.role === 'Physician' && 'tab:pb-[15%]'}`}>
                        {organizations.length > 0 ? organizations.map((organization: any, index: number) => <OrganizationPillBox
                          key={organization.id}
                          disabled={fetchingData}
                          // disabled={role === 'Office Manager' || 'PathLab User' || 'Collaborative Order Agreement' ? false : fetchingData}
                          organization={organization}
                          onClick={handleOrganizationClick(index, isValid)}
                        />) : <p>You have no associated organization to invite {role}</p>}
                      </div> : <p>Please select user role</p>
                }
              </Form>

            )}
          />
          {role === 'Office Manager' && <Associations
            organizationsList={organizationsList}
            setButtonDisable={(val: boolean) => setFetchingData(val)}
            selectedOrganizations={organizationsList.filter((org: any) => {
              const find = organizations.find((item: any) => item.id === org.id)
              if (find) { return find.selected }
              return false
            })}
            role={role}
          />}
        </div>
      </motion.div>
    </AnimatePresence>
  </ManageScreenLayout>
}

const mapStateToProps = (state: any): any => ({
  profile: state.Profile.profile,
  loadingProfile: state.Profile.fetching,
  submitting: state.InviteUser.submitting,
  loadingOrganizations: state.Organizations.fetching,
  loadingOrganizationsError: state.Organizations.error,
  organizationsList: state.Organizations.data,
  loadingPathLabs: state.PathLab.fetching,
  loadingPathLabsError: state.PathLab.error,
  pathLabs: state.PathLab.data
})
const mapDispatchToProps = {
  submitForm: InviteUserActions.submitForm,
  toggleSumbit: ButtonStateActions.toggleSumbit,
  setButtonState: ButtonStateActions.setButtonState,
  getSpeciality: SuffixAndSpecialityActions.fetchSpeciality,
  getSuffix: SuffixAndSpecialityActions.fetchSuffix,
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalInviteUsersScreen)
