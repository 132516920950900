import * as Yup from "yup";
import dateFormatter from "../utils/helpers/dateFormatter";

const OrderFormValidation = Yup.object().shape({
 patient_firstname: Yup.string()
  .when("patientNotFound", {
   is: (patientNotFound: boolean) => {
    return patientNotFound;
   },
   then: Yup.number().test(
    "patientNotFound",
    "Patient Not Found",
    (value: any) => {
     return true;
    }
   ),
  })
  .required("First name is required!")
  .test(
   "no-empty-blank-space",
   "First name cannot be an empty blank space(s)",
   (value: any) => {
    return value && value.trim() !== "";
   }
  ),
 patient_lastname: Yup.string()
  .required("Last name is required!")
  .test(
   "no-empty-blank-space",
   "Last name cannot be an empty blank space(s)",
   (value: any) => {
    return value && value.trim() !== "";
   }
  ),
 patient_middleinitial: Yup.string(),
 patient_gender: Yup.string()
  .required("Sex at Birth is required")
  .test(
   "no-empty-blank-space",
   "Sex at Birth cannot be an empty blank space(s)",
   (value: any) => {
    return value && value.trim() !== "";
   }
  ),
 patient_email: Yup.string().email("Email ID is invalid!"),
 // .required("Patient email ID is required!"),
 patient_address: Yup.string() /* .required("Address is required") */,
 patient_phone: Yup.string().matches(
  /\d{3}-\d{3}-\d{4}/,
  "Phone must contain 10 digits with following format 000-000-0000."
 ),
 patient_city: Yup.string() /* .required("City is required!") */,
 patient_zip_code: Yup.string()
  .min(5, "Zip code length must equal to 5")
  .max(5, "Zip code length must equal to 5")
  .matches(/^[0-9]+$/, "Zip code must be numbers"),
 /* .required("Zip code is required!") */ patient_state:
  Yup.string() /* .required("State/Province is required!") */,
 patient_dob: Yup.string()
  .test(
   "custom-date-validator",
   "Date of birth is Invalid",
   (value: string | undefined) => !!dateFormatter(value)
  )
  .required("Date of Birth is required!"),
 medicare_pull_date: Yup.string().nullable(),
 patient_ssn: Yup.string()
  .max(11)
  .matches(
   /\d{3}-\d{2}-\d{4}/,
   "SSN must contain 9 digits with following format 000-00-0000"
  )
  // .when("patient_mrn", {
  //  is: (patient_mrn: string, selectedTest: any) => {
  //   let regex = new RegExp(/^[a-zA-Z0-9]+$/);

  //   return !patient_mrn || patient_mrn.length === 0 || !regex.test(patient_mrn);
  //  },
  .when(["patient_mrn", "selectedTest"], {
   is: (patient_mrn: string, selectedTest: any) => {
    let regex = new RegExp(/^[a-zA-Z0-9]+$/);

    return (
     (!patient_mrn || patient_mrn.length === 0 || !regex.test(patient_mrn)) &&
     selectedTest?.id === 4
    );
   },
   then: Yup.string()
    .required("SSN is required")
    .max(11)
    .matches(
     /\d{3}-\d{2}-\d{4}/,
     "SSN must contain 9 digits with following format 000-00-0000"
    ),
  }),
 patient_mrn: Yup.string().matches(
  /^[a-zA-Z0-9]+$/,
  "MRN must be alphanumeric"
 ),
 additionalCodes: Yup.array().max(3),
 icd_10: Yup.string()
  .required("ICD 10 Code is required!")
  .test("icdCodeId", "Please Select a valid ICD code", function () {
   const { icdCodeId } = this.parent;

   return !!icdCodeId;
  }),
 insurance: Yup.string(),
 icn: Yup.string(),
 billing_phone: Yup.string().matches(
  /\d{3}-\d{3}-\d{4}/,
  "Phone must contain 10 digits with following format 000-000-0000."
 ),
 policy: Yup.string().max(
  100,
  "Policy Number must be no longer than 100 characters"
 ),
 name_of_facility: Yup.string().when(["typeofspecimen", "selectedTest"], {
  is: (typeofspecimen: string, selectedTest: any) => {
   if (selectedTest?.id == 4) {
    return typeofspecimen === "Fine Needle Aspiration Biopsy" ? false : true;
   }
   if (selectedTest?.id === 8) return false;
   return true;
  },
  then: Yup.string()
   .required("Facility is required!")
   .test(
    "no-empty-blank-space",
    "Name of facility cannot be an empty blank space(s)",
    (value: any) => {
     return value && value.trim() !== "";
    }
   ),
 }),
 date_of_discharge: Yup.string().when("medicare", {
  is: (medicare: string) => {
   return medicare === "Hospital Inpatient";
  },
  then: Yup.string()
   .required("Date of discharge is required!")
   .test(
    "custom-date-validator",
    (value: string | undefined) => !!dateFormatter(value)
   ),
 }),

 isTeleHealth: Yup.boolean().when(["selectedTest"], {
  is: (selectedTest: any) => selectedTest?.id === 8,
  then: Yup.boolean().required("Telehealth is required!"),
  otherwise: Yup.boolean(),
 }),
 date_of_collection: Yup.string().when(
  ["typeofspecimen", "selectedTest", "isTeleHealth"],
  {
   is: (typeofspecimen: string, selectedTest: any, isTeleHealth: boolean) => {
    if (selectedTest?.id == 4) {
     return typeofspecimen === "Fine Needle Aspiration Biopsy" ? false : true;
    }
    if (selectedTest?.id == 8) return !isTeleHealth;

    return true;
   },
   then: Yup.string()
    .required("Date of collection is required!")
    .test({
     name: "custom-date-validator",
     message: "Date of collection is invalid",
     test: (value: string | undefined) => !!dateFormatter(value),
    }),
   otherwise: Yup.string().nullable(),
  }
 ),
 facilityType: Yup.string().when(["selectedTest"], {
  is: (selectedTest: any, isTeleHealth: boolean) => {
   return selectedTest?.id === 8;
  },
  then: Yup.string().required("Location of Sample Collection is required"),
 }),
 tubeBarcode: Yup.string()
  .when(["selectedTest", "isTeleHealth"], {
   is: (selectedTest: any, isTeleHealth: boolean) => {
    return selectedTest?.id === 8 && !isTeleHealth;
   },
   then: Yup.string()
    .matches(
     /^[0-9][A-Z]{4}(-A|-B)/,
     "Tube Barcode can only have a digit as the first character, followed by four uppercase letters. It must end with '-A' or '-B'."
    )
    .required("Tube Barcode is required!"),
   otherwise: Yup.string(),
  })
  .max(100, "Tube Barcode must be no longer than 100 characters"),
 medicare: Yup.string().when(["primaryInsuranceId", "selectedTest"], {
  is: (primaryInsuranceId: number, selectedTest: any) => {
   if (selectedTest?.id == 8) return false;
   if (!!primaryInsuranceId == false) return true;
   return primaryInsuranceId !== 10 && primaryInsuranceId !== 11;
  },
  then: Yup.string().required("Type of Facility is required!"),
 }),
 additionalClinician: Yup.array()
  .of(
   Yup.object().shape({
    firstName: Yup.string()
     .required("First Name is required!")
     .max(100, "First Name must be no longer than 100 characters"),
    lastName: Yup.string()
     .required("Last Name is required!")
     .max(100, "Last Name must be no longer than 100 characters"),
    fax: Yup.string()
     .test(
      "fax-validation",
      "Fax must contain 10 digits with the following format 000-000-0000",
      (value) => {
       if (!value) {
        return true; // Allow empty values
       }
       return /\d{3}-\d{3}-\d{4}/.test(value);
      }
     )
     .nullable(),
    npi: Yup.string()
     .matches(/^[0-9]{10}$/, "NPI must be a 10-digit number")
     .required("NPI is required!"),
    email: Yup.string().email("Email ID is invalid!").nullable(),
   })
  )
  .when("has_additional_clinician", {
   is: (has_additional_clinician: boolean) => {
    if (!!has_additional_clinician || has_additional_clinician === null)
     return true;
    return false;
   },
   then: Yup.array()
    .of(
     Yup.object().shape({
      firstName: Yup.string()
       .required("First Name is required!")
       .test(
        "no-empty-blank-space",
        "First name cannot be an empty blank space(s)",
        (value: any) => {
         return value && value.trim() !== "";
        }
       ),
      lastName: Yup.string()
       .required("Last Name is required!")
       .test(
        "no-empty-blank-space",
        "Last Name cannot be an empty blank space(s)",
        (value: any) => {
         return value && value.trim() !== "";
        }
       ),

      npi: Yup.string()
       .matches(/^[0-9]{10}$/, "NPI must be a 10-digit number")
       .required("NPI is required!"),
      fax: Yup.string()
       .test(
        "fax-validation",
        "Fax must contain 10 digits with the following format 000-000-0000",
        (value) => {
         if (!value) {
          return true; // Allow empty values
         }
         return /\d{3}-\d{3}-\d{4}/.test(value);
        }
       )
       .nullable(),
     })
    )
    .min(1)
    .required("Additional Clinician is required"),
  }),
 tumorSite: Yup.string()
  .when(["selectedTest"], {
   is: (selectedTest: any) => {
    return selectedTest?.id === 5;
   },
   then: Yup.string()
    .required("Tumor site is required!")
    .test(
     "no-empty-blank-space",
     "Tumor site cannot be empty blank space(s)",
     (value: any) => {
      return value && value.trim() !== "";
     }
    )
    .max(100, "Tumor site must be no longer than 100 characters"),
  })
  .max(100, "Tumor site must be no longer than 100 characters"),
 //  workingDiagnosis: Yup.string()
 //   .when(["selectedTest"], {
 //    is: (selectedTest: any) => {
 //     return selectedTest?.id === 5;
 //    },
 //    then: Yup.string()
 //     .required("Working differential / differential diagnoses is required!")
 //     .test(
 //      "no-empty-blank-space",
 //      "Working differential / differential diagnoses cannot be empty blank space(s)",
 //      (value: any) => {
 //       return value && value.trim() !== "";
 //      }
 //     ),
 //   })
 //   .max(
 //    100,
 //    "Working differential / differential diagnosis must be no longer than 100 characters"
 //   ),
 specimenId: Yup.string()
  .when(["selectedTest"], {
   is: (selectedTest: any) => {
    return selectedTest?.id === 5;
   },
   then: Yup.string()
    .required("Accession# / Specimen ID is required!")
    .test(
     "no-empty-blank-space",
     "Accession# / Specimen ID cannot be empty blank space(s)",
     (value: any) => {
      return value && value.trim() !== "";
     }
    )
    .max(100, "Accession# / Specimen ID must be no longer than 100 characters"),
  })
  .max(100, "Specimen ID must be no longer than 100 characters"),

 skippedDocs: Yup.boolean().required(),
 pathology_report: Yup.string().nullable(),
 // .required("Pathology Report is required!"),
 insurance_cards: Yup.string().nullable(),
 // .required("Insurance card is required!"),
 patient_info_demographics: Yup.string().nullable(),
 isAltheaDxNeuropsychotropicMedications: Yup.object({
  id: Yup.number(),
  name: Yup.string(),
  options: Yup.array(),
 })
  .when(["selectedTest"], {
   is: (selectedTest: any) => {
    return selectedTest?.id === 8;
   },
   then: Yup.object({
    options: Yup.array().min(1, "At least one value is required!"),
   }).required(),
  })
  .nullable(),
 // .required("Document is required!"),
 //  signed_letter: Yup.string().nullable(),
 //  .required("Signed Letter is required!"),
});

export default OrderFormValidation;
