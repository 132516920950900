/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { formRef } from '../../../Pages/Admin/ManageScreen'
import { toggleSumbit } from '../../../Store/Actions/ButtonStateActions'
import InviteUserActions from '../../../Store/Actions/InviteUserActions'
import DataTable from '../../DataTable/DataTable'
import CustomInput from '../CustomInput'

function RenderTable({ tableBody, tableHead, organizationsList }: any): JSX.Element {
  const [body, setBody] = useState(tableBody)
  const [search, setSearch] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const dispatch = useDispatch()

  function handleSearch(e: React.FormEvent<HTMLInputElement>): void {
    const { value } = e.currentTarget
    setSearch(value)

    let filteredList: any = [...body]

    filteredList = filteredList.filter((item: any) => {
      if (item.data.email.toLowerCase().includes(value.toLowerCase()) || item.data.name.value.toLowerCase().includes(value.toLowerCase())) { return true }
      return false
    })

    setFilteredList(filteredList)
  }
  function handleCheck(check: boolean, index: number, body: any[]): void {
    const temp = [...body]
    const isValid = formRef.current.isValid
    let members: any = []

    const newArray = temp.map((item: any, childIndex: any) => {
      if (childIndex === index) {
        const keys: any = {}

        organizationsList.map((organization: any): any => {
          if (Object.keys(item.data).includes(organization.name)) {
            keys[organization.name] = { ...item.data[organization.name] }
            keys[organization.name].value = !item.check
          }
          return organization
        })
        // if (item.check) {
        //   let tempMembers: any = [...members]
        //   tempMembers = tempMembers.filter((member: any) => member.id !== item.id)
        // }

        return {
          ...item,
          check: !item.check,
          data: {
            ...item.data,
            ...keys
          }
        }
      }

      return { ...item }
    })
    /* OLD flow */
    // members = newArray.map((member: any, childIndex: number) => {
    //   if (member.check) {
    //     const ids: any = []
    //     organizationsList.map((item: any) => {
    //       if (Object.keys(member.data).includes(item.name)) {
    //         if (member.data[item.name].value === true && member.data[item.name].disabled === false && ids.includes(item.id) === false) { ids.push(item.id) }
    //       }
    //       return item
    //     })

    //     if (ids.length === 0) {
    //       temp[index].check = false
    //     }

    //     if (isValid) { dispatch(toggleSumbit(true)) } else {
    //       dispatch(toggleSumbit(false))
    //     }

    //     const obj = { id: member.id, organizationIds: ids }
    //     return obj
    //   } else {
    //     dispatch(toggleSumbit(false))
    //   }
    //   return null
    // })

    // members = members.filter((member: any) => member?.organizationIds?.length > 0)
    members = newArray.filter((member: any) => member.check).map((member: any) => ({ id: member.id }))
    if (members.length > 0) {
      if (formRef?.current?.validateForm) {
        const updatedValues = { ...formRef.current.values }
        updatedValues.physicianIds = members.map((member: any) => member.id)
        formRef.current.setFieldValue('physicianIds', members.map((member: any) => member.id))
        formRef.current.validateForm(updatedValues).then((res: any) => {
          if (Object.keys(res).length == 0) {
            dispatch(toggleSumbit(true))
          } else {
            dispatch(toggleSumbit(false))
          }
        })
      }
    } else {
      if (formRef?.current?.validateForm) {
        const updatedValues = { ...formRef.current.values }
        updatedValues.physicianIds = []
        formRef.current.setFieldValue('physicianIds', [])
        formRef.current.validateForm(updatedValues).then((res: any) => {
          if (Object.keys(res).length == 0) {
            dispatch(toggleSumbit(true))
          } else {
            dispatch(toggleSumbit(false))
          }
        })
      }
    }

    dispatch(InviteUserActions.setMembers(members))

    setBody(newArray)
  }

  function handleOrganizationCheck(key: string, check: boolean, index: number, body: any[]): void {
    const isValid = formRef.current.isValid
    let members: any = []
    const temp = [...body]

    const newArray = temp.map((item: any, childIndex: number) => {
      if (childIndex === index) {
        return {
          ...item,
          check: true,
          data: {
            ...item.data,
            [key]: {
              ...item.data[key],
              value: !item.data[key].value
            }
          }
        }
      }

      return { ...item }
    })

    members = newArray.map((member: any, childIndex: number) => {
      if (member.check) {
        const ids: any = []
        organizationsList.map((item: any) => {
          if (Object.keys(member.data).includes(item.name)) {
            if (member.data[item.name].value === true && ids.includes(item.id) === false) { ids.push(item.id) }
          }
          return item
        })

        if (ids.length === 0) {
          member.check = false
        }

        if (isValid) { dispatch(toggleSumbit(true)) } else {
          dispatch(toggleSumbit(false))
        }

        const obj = { id: member.id, organizationIds: ids }
        return obj
      } else {
        dispatch(toggleSumbit(false))
      }
      return null
    })

    members = members.filter((member: any) => member?.organizationIds?.length > 0)

    if (members.length > 0) {
      dispatch(toggleSumbit(isValid))
    } else {
      dispatch(toggleSumbit(false))
    }

    dispatch(InviteUserActions.setMembers(members))

    setBody(newArray)
  }

  useEffect(() => {
    // reseting functions
    if (tableBody.length > 0) {
      setBody(tableBody.map((item: any) => {
        return {
          ...item,
          handleCheckbox: (key: string, check: boolean, index: number) => {
            handleOrganizationCheck(key, check, index, tableBody)
          },
          selectOnIndex: (check: boolean, index: number, data: any) => {
            handleCheck(check, index, tableBody)
          }
        }
      }))
    } else {
      setBody(tableBody)
      if (tableBody.length > 0) { dispatch(toggleSumbit(false)) }
    }
  }, [tableBody])

  return <Fragment>
    <CustomInput
      id={'search'}
      key={'search'}
      name={'search'}
      label={'Search'}
      required={false}
      placeholder={'Search by name or email id'}
      className="w-2/6 tab:w-4/6"
      onChange={handleSearch}
      errors={{}}
      withoutForm
      isInternal
      touched={{}}
    />
    <DataTable
      NavLink={''}
      showNumbering
      body={search ? filteredList : body}
      handleCheckbox={(key: string, check: boolean, index: number) => {
        handleOrganizationCheck(key, check, index, body)
      }}
      selectOnIndex={(check: boolean, index: number) => handleCheck(check, index, body)}
      header={tableHead} />
  </Fragment>
}

export default RenderTable
