function HeaderRightLoadingIndicator () {
  return <div className="flex justify-between items-center">
        <div className="p-2 bg-dropDownBorder rounded-lg mx-3 w-[85px] animate-pulse" />
        <div className="p-2 bg-dropDownBorder rounded-lg mx-3 w-[85px] animate-pulse" />
        <div className="mx-3 h-[25px] w-[25px] bg-dropDownBorder rounded-full animate-pulse" />

    </div>
}

export default HeaderRightLoadingIndicator
