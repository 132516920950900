/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../components/global/Header'
import SideNav from '../components/global/SideNav'
import templateProviderProps from '../models/templateprovider.model'
import { AnimatePresence, motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSideMenu } from '../Store/Actions/SideMenuActions'
import './styles.css'

function TemplateProvider({ children, className, type, childContainerClassName }: templateProviderProps): JSX.Element {
  const { authState } = useOktaAuth()
  const { menuOpen } = useSelector((state: any) => state.SideMenuReducer)
  const dispatch = useDispatch()
  const location = useLocation()
  const setMenuOpen = () => dispatch(toggleSideMenu())
  const [shouldShowAnimation, setShouldShowAnimation] = useState(true);
  const [previousPath, setPreviousPath] = useState(location?.pathname);
  const { profile } = useSelector((state: any) => state?.Profile)

  useEffect(() => {
    const isMovingBetweenSpecificRoutes =
      (previousPath === "/manage/organizations/105" ||
        previousPath === "/manage/portal-users") &&
      (location.pathname === "/manage/portal-users" ||
        location.pathname === "/manage/organizations/105");

    if (isMovingBetweenSpecificRoutes) {
      setShouldShowAnimation(false);
    } else {
      setShouldShowAnimation(true);
    }

    // Update the previous path with the current location
    setPreviousPath(location.pathname);
  }, [location.pathname, previousPath]);


  switch (type) {
    case 'none':
      return children

    case 'admin':
      return <div className={`${className ?? ''} overflow-hidden `}>
        <Header isAuthenticated setMenuOpen={setMenuOpen} />
        <div className={`flex h-[calc(100%_-_60px)] lg:h-[calc(100%_-_59px)] ${profile?.roles?.includes('Collaborative Order Agreement') && 'tab:h-[calc(100%_-_80px)]'} w-full layoutContainer justify-between`}>
          <SideNav menuOpen={menuOpen} />
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={'side'}
              initial={{ opacity: 0, width: '0%' }}
              animate={{ opacity: 1, width: '100%' }}
              exit={{ opacity: 0, width: '0%' }}
              transition={{ duration: 0.2 }}
              className={`w-full lg:w-[calc(100%_-_109px)] h-full ${(location.pathname.includes('manage') === true || location.pathname.includes('notification')) === false ? 'overflow-auto' : 'overflow-hidden'} ${childContainerClassName}`}
            // className={`${menuOpen ? 'w-[calc(100%_-_109px)]' : 'w-full'} ${location.pathname.includes('manage') === false ? 'overflow-auto' : ''}`}
            >
              {children}
            </motion.div>
          </AnimatePresence>

        </div>
      </div>
    default:
      return <div className={className ?? ''}>
        <Header setMenuOpen={setMenuOpen} isAuthenticated={location.pathname !== '/' && authState?.accessToken?.accessToken ? true : false} />
        {children}
      </div>
  }
}

export default TemplateProvider
