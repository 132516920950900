import * as React from 'react'
import { SVGProps } from 'react'

const PDFIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={13.444} height={17} {...props}>
        <g
            data-name="Component 6338"
            transform="translate(.5 .5)"
            fill="none"
            stroke={props.stroke ?? '#172b4d'}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect data-name="Rectangle 4189" width={12.444} height={16} rx={2} />
            <path data-name="Line 1239" d="M3.556 3.556h5.333" />
            <path data-name="Line 1240" d="M3.556 7.111h5.333" />
            <path data-name="Line 1241" d="M3.556 10.667h3.556" />
        </g>
    </svg>
)

export default PDFIcon
