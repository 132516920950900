/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useEffect, useState } from 'react'

function ProgressBarPerField({ validFields, totalFields }: { validFields: number, totalFields: number }): JSX.Element {
  const [percentage, setPercentage] = useState<string>('0%')

  useEffect(() => {
    let currentPercentage = ''
    let percentage = 0

    percentage = Number(((validFields / totalFields) * 100).toFixed(0))
    currentPercentage = `${percentage}%`

    setPercentage(currentPercentage)
  }, [validFields])

  return <div>
    <p className="transition-all" style={{ marginLeft: percentage }}>{percentage}</p>
    <div className="w-full bg-[#DCDFE4] rounded-full overflow-hidden">
      <div className="bg-gradient-to-r from-[#18B5B7] to-[#084E8B] rounded-full p-1 transition-all" style={{ width: percentage }} />
    </div>
  </div>
}

export default ProgressBarPerField
