import React, { Component } from 'react'
import UpIcon from '../../assets/Icon/arrow-up.svg'
import DownIcon from '../../assets/Icon/arrow-down.svg'
import './style.css'

type selectionCallbackFunction = (listItem: DropdownItem) => void
export interface DropdownItem {
  label: string
  key: string
}

interface DropdownProps {
  type?: 'boxed' | 'compact' | 'menu'
  label?: string
  hasError?: boolean
  placeholder?: string
  filter?: true
  dropDownStyle?: string
  labelStyle?: string
  inputStyle?: string
  currentSelection: DropdownItem | null
  dropdownList: DropdownItem[]
  onSelection: selectionCallbackFunction
}

interface DropdownState {
  dropdownMenuOpened: boolean
  filteredValue: string
}

export class Dropdown extends Component<DropdownProps, DropdownState> {
  private readonly wrapperRef: React.RefObject<any>

  constructor(props: DropdownProps) {
    super(props)

    this.state = {
      dropdownMenuOpened: false,
      filteredValue: ''
    }

    this.wrapperRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event: Event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ dropdownMenuOpened: false })
    }
  }

  selectFromDropdownList = (listItem: DropdownItem) => {

    if (this.state.filteredValue === listItem.label) {
      this.setState({ filteredValue: '' })
    } else {
      this.setState({ filteredValue: listItem.label })
    }

    if (this.props.onSelection) this.props.onSelection(listItem)
  }
  handleDropdownContainerOnClick = () => {
    const { filteredValue } = this.state

    let ascendingItem = {
      label: "Ascending",
      key: "ascending"
    }
    let descendingItem = {
      label: "Descending",
      key: "descending"
    }

    this.setState({ dropdownMenuOpened: !this.state.dropdownMenuOpened, filteredValue: filteredValue === '' || filteredValue === 'Descending' ? 'Ascending' : 'Descending' })
    if (this.props.onSelection) this.props.onSelection(filteredValue === 'Descending' || filteredValue == '' ? ascendingItem : descendingItem)

  }

  handleChange = (e: any) => {
    this.setState({ filteredValue: e.target.value })
  }


  render() {
    const { dropdownMenuOpened, filteredValue } = this.state
    const {
      type = 'boxed',
      dropdownList,
      label,
      placeholder,
      dropDownStyle,
      labelStyle,
      inputStyle,
      hasError,
      currentSelection,
      filter
    } = this.props

    const filteredDropdownList = dropdownList.filter(
      (suggestion: any) =>
        suggestion.key.toLowerCase().indexOf(filteredValue.toLowerCase()) > -1
    )

    return (
      <div
        className={'dropdown-container ' + type}
        onMouseDown={this.handleDropdownContainerOnClick}
      >
        {!!label && type !== 'menu' && (
          <div className="dropdown-label">
            <p className={`dropdown-label__text ${labelStyle ?? ''}`}>{label}</p>
          </div>
        )}

        <div
          className={
            'dropdown ' + (dropDownStyle ?? '') +
            (dropdownMenuOpened ? ' has-focus' : '') +
            (!dropdownList || dropdownList.length === 0 ? ' is-disabled' : '') +
            ((currentSelection != null) ? ' is-active' : '') +
            (hasError ? ' has-error' : '')
          }
          ref={this.wrapperRef}>

          <div className={
            'dropdown-text-container' + (dropdownMenuOpened ? ' is-open' : '')
          }>

            {filter
              ? (
                <input
                  className={`dropdown-text-container__input ${inputStyle}`}
                  placeholder={placeholder}
                  onChange={this.handleChange}
                  value={filteredValue}
                />
              )
              : (
                <p className={'dropdown-text-container__text'}>
                  {(type === 'menu')
                    ? label
                    : (
                      (!(currentSelection == null) && !!currentSelection.label) ? currentSelection.label : (placeholder || '')
                    )
                  }
                </p>
              )}
            <img src={filteredValue === "Ascending" ? UpIcon : DownIcon} alt="dropdown button" />
          </div>
          <ul
            className={
              'dropdown-menu ' + (dropdownMenuOpened ? 'is-visible' : '')
            }
          >
            {/* {filter
              ? filteredDropdownList.map((item, idx) => {
                return (
                  <li
                    className={'font-normal'}
                    onMouseDown={() => this.selectFromDropdownList(item)}
                    key={idx + item.key}
                  >
                    {item.label}
                  </li>
                )
              })
              : dropdownList.map((item, idx) => {
                return (
                  <li
                    className={!!filteredValue && filteredValue == item.label ? 'bg-[#edeff1] font-normal' : 'font-normal'}
                    onMouseDown={() => this.selectFromDropdownList(item)}
                    key={idx + item.key}
                  >
                    {item.label}
                  </li>
                )
              })} */}
          </ul>
        </div>
      </div>
    )
  }
}
export default Dropdown
