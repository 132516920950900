import * as React from 'react'
import { SVGProps } from 'react'

const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} {...props}>
        <g
            data-name="Component 6372 \u2013 1"
            transform="translate(.5 .5)"
            fill="none"
            stroke={props.stroke ?? '#97a0af'}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle
                data-name="Ellipse 598"
                cx={2}
                cy={2}
                r={2}
                transform="translate(8)"
            />
            <path data-name="Line 1280" d="M0 2h8" />
            <path data-name="Line 1281" d="M12 2h4" />
            <circle
                data-name="Ellipse 599"
                cx={2}
                cy={2}
                r={2}
                transform="translate(2 6)"
            />
            <path data-name="Line 1282" d="M0 8h2" />
            <path data-name="Line 1283" d="M6 8h10" />
            <circle
                data-name="Ellipse 600"
                cx={2}
                cy={2}
                r={2}
                transform="translate(11 12)"
            />
            <path data-name="Line 1284" d="M0 14h11" />
            <path data-name="Line 1285" d="M15 14h1" />
        </g>
    </svg>
)

export default FilterIcon
