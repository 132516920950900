import {
 FETCH_PATHLAB,
 FETCH_PATHLAB_ERROR,
 FETCH_PATHLAB_SUCCESS,
 FETCH_PORTAL_USERS_BY_PATHLAB,
 FETCH_PORTAL_USERS_BY_PATHLAB_ERROR,
 FETCH_PORTAL_USERS_BY_PATHLAB_SUCCESS,
} from "./types";
import http from "../API/HttpMethods";

/* Actions 
1. Fetch Pathlabs */

const fetchPathlabs = (options: any) => async (dispatch: any) => {
 const { config, onError, onEmpty } = options;
 dispatch({ type: FETCH_PATHLAB });

 try {
  const result = await http.getPathlabs(
   "v1/organizations/new-flow/list?type=pathologyLab",
   config
  );
  const { statusCode, data, error } = result;

  if (error) {
   onError(error);
   return dispatch({ type: FETCH_PATHLAB_ERROR });
  }

  if (data.length == 0 && onEmpty) {
   onEmpty();
  }

  dispatch({ type: FETCH_PATHLAB_SUCCESS, payload: data });
  return data;
 } catch (error: any) {
  dispatch({ type: FETCH_PATHLAB_ERROR });
  const { response } = error;
  if (response?.data) {
   onError(response?.data?.message);
  } else {
   onError(error?.message);
  }
 }
};
const fetchPortalUsersByPathLabID = (options: any) => async (dispatch: any) => {
 const { config, pathlabId } = options;
 dispatch({ type: FETCH_PORTAL_USERS_BY_PATHLAB });

 try {
  const result = await http.getPortalUsersByPathLabID(
   `/v1/users/new-flow/list/${pathlabId}`,
   config
  );
  const { statusCode, data, error } = result;

  if (error) return dispatch({ type: FETCH_PORTAL_USERS_BY_PATHLAB_ERROR });

  dispatch({ type: FETCH_PORTAL_USERS_BY_PATHLAB_SUCCESS, payload: data });
 } catch (error) {
  dispatch({ type: FETCH_PORTAL_USERS_BY_PATHLAB });
 }
};

const PathLabActions = {
 fetchPathlabs,
 fetchPortalUsersByPathLabID,
};

export default PathLabActions;
