/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../../components/global/Header';
import Tabs from '../../components/ManageScreen/Tabs';
import ButtonStateActions from '../../Store/Actions/ButtonStateActions';

function NotificationSettingsLayout({ children, headerTitle, CustomButtons,isSettingsChanged }: any): JSX.Element {
    const { screen } = useSelector((state: any) => state.ButtonState)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(ButtonStateActions.setButtonState('notification settings'))
    }, [])

    return <div className="flex flex-col h-full ">
        <Header
            isInternal
            showBack={screen === 'notification settings'}
            // onBackScreen={()=>alert('gell')}
            isSettingsChanged={isSettingsChanged}
            CustomButtons={CustomButtons}
            title={headerTitle ?? 'notification settings'}
        />
        <div className="grid h-full lg:grid-cols-7 tab:w-full tab:flex  overflow-auto  mobile:flex-col mobile:flex">
            <div className="sidebar lg:col-span-1 mobile:w-full">
                <Tabs />
            </div>
            <div className="col-span-6 flex flex-col items-stretch tab:w-full mobile:w-full bg-white h-full">
                {children}
            </div>
        </div>
    </div>
}

export default NotificationSettingsLayout
