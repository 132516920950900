import * as React from "react"
import { SVGProps } from "react"
const NotificationsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15.222} height={17} {...props}>
    <g
      fill="none"
      stroke={props?.stroke ? props.stroke : "#172b4d"}
      strokeLinecap="round"
      strokeLinejoin="round"
      data-name="Component 6417"
    >
      <path
        d="M5.833 2.278a1.778 1.778 0 1 1 3.556 0 6.222 6.222 0 0 1 3.556 5.333v2.667a3.556 3.556 0 0 0 1.778 2.667H.5a3.556 3.556 0 0 0 1.778-2.667V7.611a6.222 6.222 0 0 1 3.555-5.333"
        data-name="Path 5386"
      />
      <path
        d="M4.944 12.944v.889a2.667 2.667 0 0 0 5.333 0v-.889"
        data-name="Path 5387"
      />
    </g>
  </svg>
)
export default NotificationsIcon
