import reduxActionType from '../../models/reduxActionType'
import { FETCHING_STATE_DATA, FETCHING_STATE_ERROR, FETCHING_STATE_SUCCESS, LOGOUT } from '../Actions/types'

const initialState = {
  fetching: false,
  error: false,
  data: []
}

function StateAndCityReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case FETCHING_STATE_DATA:
      return {
        ...state,
        fetching: true,
        error: false
      }
    case FETCHING_STATE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        data: [...action.payload]
      }
    }
    case FETCHING_STATE_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true
      }
    }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default StateAndCityReducer
