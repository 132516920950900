function NotificationLoadingItem(): JSX.Element {
  return <div className="p-2 w-full my-5">
    <div className="h-[20px] w-full bg-dropDownBorder animate-pulse rounded-sm" />
  </div>
}

function NotificationsListLoadingIndicator(): JSX.Element {
  return <div className="bg-white p-6 border rounded-md my-6">
    <NotificationLoadingItem />
    <NotificationLoadingItem />
    <NotificationLoadingItem />
  </div>
}
export default NotificationsListLoadingIndicator