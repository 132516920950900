/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import Header from '../components/global/Header'
import Tabs from '../components/ManageScreen/Tabs'
import '../assets/css/custom.css';
import ButtonStateActions from '../Store/Actions/ButtonStateActions';

function ShipmentsLayout({ children, headerTitle, CustomButtons }: any): JSX.Element {
    const { screen } = useSelector((state: any) => state.ButtonState)
    const location = useLocation()
    const dispatch = useDispatch()
    useEffect(() => {   
        dispatch(ButtonStateActions.setButtonState('shipment'))
    }, []) 

    return <div className="flex flex-col h-full ">
        <Header
            isInternal
            showBack={location?.pathname === '/shipments/schedule-pickup'}
            onBackScreen={'shipment'}
            CustomButtons={CustomButtons}
            title={headerTitle ?? 'Shipments'}
        />
        <div className="grid h-full lg:grid-cols-7 tab:w-full tab:flex overflow-hidden mobile:flex-col mobile:flex">
            <div className="sidebar lg:col-span-1 mobile:w-full">
                <Tabs />
            </div>
            <div className={`rightContainer lg:col-span-6  lg:grid lg:grid-cols-4 ${location?.pathname === '/shipments/unshipped-orders' ? 'overflow-hidden':'overflow-auto'}`}>
                <div className="lg:col-span-6  lg:grid lg:grid-cols-4 tab:flex">
                    {children}
                </div>
            </div>
        </div>
    </div>
}

export default ShipmentsLayout
