/* eslint-disable @typescript-eslint/space-before-function-paren */
import { Form, Formik } from "formik";
import CustomInput from "../../../../components/global/CustomInput";
import EditMyProfileValues from "../../../../models/forms/editmyprofileformvalues.interface";
import EditMyProfileValidation from "../../../../validations/editmyprofilevalidation";
import ProfileScreenLayout from "../../../../Containers/ProfileLayout";
import Button from "../../../../components/atomic/Button";
import { useDispatch, useSelector, connect } from "react-redux";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import CustomImageUploader from "../../../../components/global/CustomImageUploader";
import { formRef } from "..";
import ButtonStateActions from "../../../../Store/Actions/ButtonStateActions";
import { missingTests } from "../../../../utils/helpers/missingTestsValidator";
import { ActionArgs } from "../../../../Store/Actions/types";
import ProfileActions from "../../../../Store/Actions/ProfileActions";
import { useOktaAuth } from "@okta/okta-react";
import { AnimatePresence, motion } from "framer-motion";
// import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import NotificationActions from "../../../../Store/Actions/NotificationsActions";
import PhysicianProfileValidation from "../../../../validations/physicianprofilevalidation";
import toastMessages from "../../../../utils/helpers/toastMessage";
import { cityStateHelper } from '../../../../utils/helpers/cityStateHelper'
import moment from "moment";

interface EditMyProfileFormProps {
  handleNext: (data: any) => any;
  setImage?: (image: any) => any;
  updateUser: (data: ActionArgs) => void;
  fetchprofile: (data: any) => void;
  isPage: boolean;
  orgId?: string;
  editProfileVals?: EditMyProfileValues;
}

function RenderFields({
  isPage,
  initialValues,
  setFieldValue,
  errors,
  touched,
  setImage,
  isValid,
  values,
  orgId,
  validateForm,
  profile
}: any) {
  const dispatch = useDispatch();
  const { data: states, error } = useSelector(
    (state: any) => state.StateAndCity
  );
  const { suffixData, specialityData } = useSelector((state: any) => {
    return {
      suffixData: state.Suffix.suffix,
      specialityData: state.Speciality.speciality,
    };
  });
  const [cities, setCities] = useState([]);

  function handleChange(name: string, type: "event" | "val", data: any): void {
    const updatedValues = { ...values };

    function handleValue(val: any) {
      updatedValues[name] = val;

      if (val == "") dispatch(ButtonStateActions.toggleSumbit(false));
      else {
        validateForm(updatedValues)
          .then((res: any) => {
            if (Object.keys(res).length === 0) {
              dispatch(ButtonStateActions.toggleSumbit(true));
            } else {
              dispatch(ButtonStateActions.toggleSumbit(false));
            }
          })
          .catch((err: any) => {
            dispatch(ButtonStateActions.toggleSumbit(isValid));
          });
      }
    }
    if (type == "event") {
      setFieldValue(name, data.target.value);
      handleValue(data.target.value);
    } else {
      if (name == "state") {
        // let labelValue = data.split(' ')[0] ? data.split(' ')[0] : data
        // let filterValue = data.split(' ')[1] ? data.split(' ')[1] : data
        cityStateHelper(states, setCities, data, values, setFieldValue, 'city')
        setFieldValue(name, data)
        setFieldValue('stateValue', states.find((state: any) => state.state_code == data)?.name ?? data)
        handleValue(data)
      } else {
        setFieldValue(name, data);
        handleValue(data);
      }

    }
  }

  function onUploadStart(): void {
    dispatch(ButtonStateActions.toggleSumbit(false));
  }
  function onUploadEnd(): void {
    dispatch(ButtonStateActions.toggleSumbit(isValid));
  }

  return (
    <div>
      <CustomImageUploader
        fieldName={"profileImage"}
        title={"Change Image"}
        onUploadStart={onUploadStart}
        onUploadEnd={onUploadEnd}
        initialImage={values.imageUrl ?? ""}
        setFieldValue={setFieldValue}
        handleImage={(val: any) => {
          // console.log(val);
        }}
      />

      <div className="w-full lg:w-3/6">
        <CustomInput
          id={"first_name"}
          key={"firstName"}
          name={"firstName"}
          disabled={orgId}
          label={"First Name"}
          isInternal={!isPage}
          onChange={(val: any) => handleChange("firstName", "event", val)}
          required
          placeholder={"Enter first name"}
          value={values.firstName}
          errors={errors}
          touched={touched}
          className={"mt-2 "}
        />

        <CustomInput
          id={"last_name"}
          key={"lastName"}
          name={"lastName"}
          label={"Last Name"}
          isInternal={!isPage}
          onChange={(val: any) => handleChange("lastName", "event", val)}
          required
          placeholder={"Enter last name"}
          value={values.lastName}
          errors={errors}
          touched={touched}
          className={""}
        />
        <CustomInput
          id={"email_id"}
          key={"email_id"}
          name={"email_id"}
          label={"Email ID"}
          required={false}
          disabled
          placeholder={"Enter email ID"}
          isInternal={!isPage}
          onChange={(val: any) => handleChange("email_id", "event", val)}
          value={values.email_id}
          errors={errors}
          touched={touched}
          className={""}
        />
        {/* {profile && (profile?.roles?.includes('Collaborative Order Agreement') || profile?.roles?.includes('PathLab User')) && <>
        <div className="flex flex-row">
          <CustomInput
            id={"fax"}
            key={"fax"}
            name={"fax"}
            label={"Fax"}
            required
            placeholder={"000-000-0000"}
            type={"tel"}
            value={values.fax}
            // onChange={(val: any) => setFieldValue('phone', val, true)}
            isInternal={!isPage}
            onChange={(val: any) => handleChange("fax", "event", val)}
            errors={errors}
            touched={touched}
            className={"mr-2"}
          />
          <CustomInput
            id={"primaryPhone"}
            key={"primaryPhone"}
            name={"primaryPhone"}
            label={"Phone"}
            required
            placeholder={"000-000-0000"}
            type={"tel"}
            value={values.primaryPhone}
            errors={errors}
            // onChange={(val: any) => setFieldValue('fax', val, true)}
            isInternal={!isPage}
            onChange={(val: any) => handleChange("primaryPhone", "event", val)}
            touched={touched}
            className={"ml-2"}
          />
        </div>
        <CustomInput
          id={"streetAddress"}
          key={"streetAddress"}
          name={"streetAddress"}
          label={"Address"}
          isInternal={!isPage}
          onChange={(val: any) => handleChange("streetAddress", "event", val)}
          required
          placeholder={"Enter Address"}
          value={values.streetAddress}
          errors={errors}
          touched={touched}
          className={"mt-2 "}
        />
        <CustomInput
          id={"state"}
          key={"state"}
          name={"state"}
          label={"State/Province"}
          required
          placeholder={"Select State/Province"}
          suggestions={
            !error && states.length > 0
              ? states.map((state: any) => {
                return ({ label: state.name, value: state.state_code });
              })
              : []
          }
          isInternal={!isPage}
          // onChange={(val: any) => handleChange("state", "val", val)}
          onChange={states && states.length > 0 ? (val: any) => handleChange('state', 'val', val) : (val: any) => handleChange('state', 'event', val)}
          value={values.stateValue ? values.stateValue : values.state ? states && states.length > 0 && states.find((state: any) => state?.state_code === values?.state)?.name : values.state}
          errors={errors}
          touched={touched}
        />
        <div className="flex flex-row">
          <CustomInput
            id={"city"}
            key={"city"}
            name={"city"}
            label={"City"}
            required
            placeholder={"Select City"}
            // suggestions={
            //   !error && cities.length > 0
            //     ? cities.map((state: any) => {
            //       return `${state.name}`;
            //     })
            //     : []
            // }
            suggestions={
              !error && cities.length > 0
                ? cities.map((state: any) => ({ label: state.name, value: state.name }))
                : []
            }
            isInternal={!isPage}
            // onChange={(val: any) => handleChange("city", "val", val)}
            onChange={cities && cities.length > 0 ? (val: any) => handleChange('city', 'val', val) : (val: any) => handleChange('city', 'event', val)}
            value={values.city}
            errors={errors}
            touched={touched}
            className={""}
          />
          <CustomInput
            id={"zipCode"}
            key={"zipCode"}
            name={"zipCode"}
            label={"Zip code"}
            isInternal={!isPage}
            onChange={(val: any) => handleChange("zipCode", "event", val)}
            required={false}
            placeholder={"Enter Zip Code"}
            value={values.zipCode}
            errors={errors}
            touched={touched}
            className={"ml-2"}
          />
        </div>
        </>} */}
        {profile && profile?.roles?.includes('Physician') && (
          <Fragment>
            <div className="flex flex-row items-center">
              <CustomInput
                id={"npi"}
                key={"npi"}
                name={"npi"}
                label={"NPI"}
                placeholder={"Enter NPI"}
                type={"number"}
                required={true}
                disabled
                value={values.npi}
                isInternal={!isPage}
                onChange={(val: any) => handleChange("npi", "event", val)}
                errors={errors}
                touched={touched}
                className={"mr-2"}
              />
              <CustomInput
                id={"suffix"}
                key={"suffix"}
                name={"suffix"}
                label={"Suffix"}
                required={true}
                disabled
                placeholder={"Select Suffix"}
                className="py-1"
                // suggestions={
                //   !error && suffixData.length > 0
                //     ? suffixData.map((state: any) => {
                //       return `${state.name}`;
                //     })
                //     : []
                // }
                suggestions={
                  !error && suffixData.length > 0
                    ? suffixData.map((state: any) => ({ label: state.name, value: state.name }))
                    : []
                }
                isInternal={!isPage}
                onChange={
                  suffixData && suffixData.length > 0
                    ? (val: any) => handleChange("suffix", "val", val)
                    : (val: any) => handleChange("suffix", "event", val)
                }
                value={values.suffix}
                errors={errors}
                touched={touched}
              />
            </div>
            <CustomInput
              id={"specialty"}
              key={"specialty"}
              name={"specialty"}
              label={"Speciality"}
              disabled
              required={true}
              placeholder={"Select Speciality"}
              // suggestions={
              //   !error && specialityData.length > 0
              //     ? specialityData.map((state: any) => {
              //       return `${state.name}`;
              //     })
              //     : []
              // }
              suggestions={
                !error && specialityData.length > 0
                  ? specialityData.map((state: any) => ({ label: state.name, value: state.name }))
                  : []
              }
              isInternal={!isPage}
              onChange={
                specialityData && specialityData.length > 0
                  ? (val: any) => handleChange("specialty", "val", val)
                  : (val: any) => handleChange("specialty", "event", val)
              }
              value={values.specialty}
              errors={errors}
              touched={touched}
              className={"pb-16"}
            />
            {/* <div className='flex'>
              <CustomInput
                id={'signPermission'}
                key={'signPermission'}
                name={'signPermission'}
                label={'I allow the Office Manager to use my e-signature to submit an order.'}
                checked={values.sof ? true : false}
                required={false}
                showToolTip={true}
                tooltipText="You are unable to edit this checkbox because it is dependent upon Signature on File (SOF)."
                disabled={true}
                placeholder={''}
                errors={errors}
                onChange={(e: any) => {
                  setFieldValue('signPermission', e.target.checked)
                }}
                type="checkbox"
                touched={touched}
                className="self-start"
              // className="w-[83%]"
              // signupFieldsStyle={'-mt-1'}
              />
            </div> */}
          </Fragment>
        )}
      </div>
      <div className="pb-16"></div>
    </div>
  );
}

function EditMyProfile({
  isPage,
  orgId,
  updateUser,
  fetchprofile,
  editProfileVals,
}: EditMyProfileFormProps) {
  const [image, setImage] = useState<any>();
  const { oktaAuth, authState } = useOktaAuth();
  const { screen } = useSelector((state: any) => state.ButtonState);
  const { profile } = useSelector((state: any) => state.Profile);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const history = useHistory()

  useEffect(() => {
    dispatch(ButtonStateActions.toggleSumbit(true));
    if (screen !== "edit my profile") {
      dispatch(ButtonStateActions.setButtonState("my profile"));
    }
  }, []);

  let initialValues: any;

  if (
    Array.isArray(profile.roles) &&
    profile.roles.includes('Physician')
  ) {
    initialValues = {
      firstName: profile ? profile.firstName : "",
      email_id: profile ? profile.email : "",
      lastName: profile ? profile.lastName : "",
      profileImage: profile ? profile.profileImage : "",
      imageUrl: profile ? profile.imageUrl : "",
      role: 'Physician',
      specialty: profile ? profile.specialty : "",
      suffix: profile ? profile.suffix : "",
      npi: profile ? profile.npi : "",
      // pinCode: profile ? profile.pinCode : "1234",
      signPermission: profile ? profile.signPermission : false,
      sof: profile ? profile?.sof : false,
      acceptTerms: profile ? profile.acceptTerms : false,
      acceptTermsDate: profile ? profile.acceptTermsDate : moment().format("MM-DD-YYYY"),
    };
  } else {
    initialValues = {
      firstName: profile ? profile.firstName : "",
      email_id: profile ? profile.email : "",
      lastName: profile ? profile.lastName : "",
      acceptTerms: profile ? profile.acceptTerms : false,
      acceptTermsDate: profile ? profile.acceptTermsDate : moment().format("MM-DD-YYYY"),
      // primaryPhone: profile ? profile.primaryPhone : "",
      // fax: profile ? profile.fax : "",
      // streetAddress: profile ? profile.streetAddress : "",
      // city: profile ? profile.city : "",
      // state: profile ? profile.state : "",
      // zipCode: profile ? profile.zipCode : "",
      // profileImage: profile ? profile.profileImage : "",
      profileImage: profile ? profile.profileImage : "",
      imageUrl: profile ? profile.imageUrl : "",
      role: profile
        ? Array.isArray(profile.roles)
          ? profile.roles[0]
          : profile.roles
        : "Office Manager",
      // pinCode: profile ? profile.pinCode : "",
      sof: profile ? profile?.sof : false
    };
  }

  function handleImage(image: any): void {
    setImage(image);
  }

  function onSubmit(data: EditMyProfileValues): void {
    const apiData = {
      ...data,
      accessToken: authState?.accessToken?.accessToken,
    };
    delete apiData.email_id;
    dispatch(ButtonStateActions.formSubmit(true));

    const onSuccess = (message: string) => {
      const options = {
        accessToken: authState?.accessToken?.accessToken,
        onSuccess: () => {
          // console.log("success");
        },
        onError: () => {
          // alert("Something went wrong");
        },
      };
      fetchprofile(options);
      toastMessages.success(message ?? 'Profile Updated')
      dispatch(ButtonStateActions.toggleSumbit(false))
      dispatch(ButtonStateActions.formSubmit(false))
      dispatch(ButtonStateActions.setButtonState("my profile"))
      history.push("/profile", { replace: true })
    }
    updateUser({
      data: apiData,
      onSuccess,
      onError: (message: any) => {
        toastMessages.error(message ?? 'Profile Updation Failed')
        dispatch(ButtonStateActions.formSubmit(false))
      }
    })
  }

  function Render({
    errors,
    touched,
    setFieldValue,
    isValid,
    values,
    headerTitle,
    ...rest
  }: any) {
    return (
      <ProfileScreenLayout headerTitle={headerTitle} screen={screen}>
        <div className="col-span-8 overflow-y-auto ">
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="col-span-4 "
            >
              <div className="my-5 px-12 ">
                <Form
                  // className={isPage ? 'justify-betweeen flex flex-col justify-around h-5/6' : 'flex flex-col overflow-scroll h-full'}
                  className={
                    isPage
                      ? "justify-betweeen flex flex-col justify-around h-5/6"
                      : " flex flex-col"
                  }
                  style={{ height: isPage ? '' : '20px' }}
                >
                  <div
                    // className={isPage ? 'overflow-auto px-1 h-4/5' : 'overflow-scroll px-12 h-3/6 pb-20 md:pb-5 lg:h-4/6 xl:pb-0 2xl:h-5/6'}
                    className={isPage ? "overflow-auto px-1 h-4/5" : ""}
                  >
                    <RenderFields
                      // isPage={isPage}
                      isValid={isValid}
                      orgId={orgId}
                      values={values}
                      initialValues={initialValues}
                      setFieldValue={setFieldValue}
                      setImage={handleImage}
                      errors={errors}
                      touched={touched}
                      profile={profile}
                      {...rest}
                    />
                  </div>
                  {/* </div> */}
                </Form>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </ProfileScreenLayout>
    );
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={
        profile &&
          Array.isArray(profile.roles) &&
          profile.roles.includes('Physician')
          ? PhysicianProfileValidation
          : EditMyProfileValidation
      }
      onSubmit={onSubmit}
      initialStatus={false}
      // isInitialValid={orgId ? true : false}
      render={Render}
    />
  );
}

const mapDispatchToProps = {
  updateUser: ProfileActions.updateUser,
  fetchprofile: ProfileActions.fetchprofile,
};
export default connect(null, mapDispatchToProps)(EditMyProfile);
