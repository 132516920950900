import { useEffect, useRef, useState } from 'react'
import UploaderImageIcon from '../../assets/svg/UploaderImageIcon'
import { BlockBlobClient } from '@azure/storage-blob'
import http from '../../Store/API/HttpMethods'
import { useOktaAuth } from '@okta/okta-react'
import CustomImage from './CustomImage'
import Button from '../atomic/Button'
import toastMessages from '../../utils/helpers/toastMessage'

function CustomImageUploader({ hideChange, disabled, title, upperTitle, className, handleImage, initialImage, setFieldValue, fieldName, onUploadStart, onUploadEnd, label, text, reset }: any) {
  const [image, setImage] = useState<any>(null)
  const [mode, setMode] = useState('view')
  const inputRef = useRef<any>()
  const { authState } = useOktaAuth()

  useEffect(() => {
    if (reset) {
      setImage(null)
      setFieldValue(fieldName, null)
    }
  }, [reset])

  function getExt(filename: string) {
    const ext = filename.split('.').pop()
    if (ext == filename) return ''
    return ext
  }

  async function uploadToAzure(url: string, filePath: string, file: any) {
    const blockBlobClient = new BlockBlobClient(url)

    try {
      const response = await blockBlobClient.uploadData(file, { blobHTTPHeaders: { blobContentType: file.type } })
      if (response) {
        setFieldValue(fieldName, filePath)
        onUploadEnd && onUploadEnd()
      }
    } catch (error: any) {
      // console.log(error.message)
    }
  }

  async function getPresignedUrlAndUpload(file: any) {
    if (!file) return

    onUploadStart && onUploadStart()

    try {
      const extension = getExt(file.name)
      const response = await http.getPresignedUrl(`v1/pre-signed-url/images/${file.name.replace(/ /g, "_")}`, { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } })
      const { url, filepath, statusCode } = response

      if (url) { return await uploadToAzure(url, filepath, file) }

      onUploadEnd && onUploadEnd()
    } catch (error: any) {
      // console.log(error)
    }
  }

  const validateFile = (file: any) => {
    const validTypes = ['image/jpg','image/jpeg','image/png']
    if (!validTypes.includes(file.type)) {
      toastMessages.error('Invalid file format')
      return false
    }
    return true
  }

  async function handleDrop(event: any) {
    event.preventDefault()
    const files = event.dataTransfer.files

    if (files.length > 1) { return alert('Please upload a single file') }
    if (validateFile(files[0])) {
      const reader = new FileReader()
      getPresignedUrlAndUpload(files[0])
      handleImage(files[0])
      setImage(files[0])
    }
  }

  function renderImage() {
    if (initialImage && !image) {
      return <img
        src={initialImage}
        className={'h-24 w-4/6 lg:w-3/6 object-fit my-2 cursor-pointer rounded'} />

    }
    if (image) {
      return <img
        src={URL.createObjectURL(image)}
        className={'h-24 w-4/6 lg:w-3/6 object-fit my-2 cursor-pointer rounded'} />

    }
    return <div
      className={`border border-borderGray bg-sideMenuHoverBg my-2 flex justify-center items-center border-dashed p-8 w-4/6 lg:w-3/6 ${className} rounded`}
    >
      <UploaderImageIcon />
    </div>
  }
  if (!hideChange) {
    if (mode === 'view') {
      return <div>
        <div className='text-sm mt-3 -mb-3'>{upperTitle}</div>
        <CustomImage
          alt={''}
          src={initialImage}
          className={'w-[80px] h-[80px] object-cover mt-6 rounded-full'}
        />
        <Button
          btnType='transparent'
          title={title}
          className='mt-2 tracking-[0.18em] !font-medium text-sm'
          onClick={() => setMode('upload')}
          disabled={disabled}
        />

      </div>
    }
  }

  return <div className='mt-6'>
    <p className="text-xs text-royalBlue">{!image ? `${label ?? 'Upload Logo'}` : `${label ?? 'Logo'}`}</p>

    <div
      onDragOver={e => e.preventDefault()}
      onDragEnter={e => e.preventDefault()}
      onDragLeave={e => e.preventDefault()}
      onClick={() => inputRef.current.click()}
      onDrop={handleDrop}
    >
      {renderImage()}
    </div>
    <p className='text-[#18b5b7] font-semibold text-xs m-0 p-0 lg:text-base uppercase mb-3 cursor-pointer' onClick={() => inputRef.current.click()}>{text}</p>
    <p className={`text-xs text-royalBlue mb-2 w-4/6 lg:w-3/6 ${className}`}>
      Add an image by clicking on the logo placeholder or drag and drop, image should be in png, or jpg format
    </p>
    <input
      type='file' id='file'
      accept="image/png,image/jpeg,image/jpg"
      ref={inputRef}
      onChange={(e: any) => {
        // console.log('On change called')
        const file = e?.target?.files[0]
        if (validateFile(file)) {
        e.preventDefault()
        e.stopPropagation()
        getPresignedUrlAndUpload(file)
        setImage(file)
        }
      }}
      style={{ display: 'none' }} />
  </div>
}

export default CustomImageUploader
