/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import CustomNavLink from "../atomic/CustomNavLink";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CustomPopup from "./CustomPopup";
import React from "react";
import dataConstants from "../../constants/dataConstants";
import SideNavLinkLoadingIndicator from "./LoadingIndicators/SideNavLinkLoadingIndicator";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory, useLocation } from "react-router-dom";
import './styles.css'
import ApiNotificationData from "../../models/notificationsModels/apiNotificationsData.model";

const PLUforbiddenLinks = ['/approvals', '/documents', '/profile', '/referral-orders']

function NotificationBadge({ unReadCount }: { unReadCount: number }): JSX.Element | null {
  if (unReadCount === 0) return null
  return <div
    className={`bg-[#FE4F4F] ${unReadCount > 99 ? 'h-[18px] w-[18px]' : 'h-[15px] w-[15px]'} rounded-full absolute   truncate  top-[15%] right-[36%]`}>
    <p className={`text-white ${unReadCount > 99 ? 'text-[8px] mt-1' : 'text-xs'} text-center`}>{unReadCount > 100 ? <span>100<sup>+</sup></span> : unReadCount}</p>
  </div>
}


function SideNav({ menuOpen }: { menuOpen: boolean }) {
  const location = useLocation()
  const { profile, loading, error, unReadCount } = useSelector((state: any) => ({
    profile: state.Profile,
    loading: state.Profile.fetching,
    error: state.Profile.error,
    unReadCount: state.UserNotifications.unReadCount
  }))
  const isPLUUser = profile?.roles?.includes('PathLab User') || profile?.profile?.roles?.includes('PathLab User') && (profile.roles?.length == 1 || profile?.profile?.roles.length == 1)
  const isNotAPhysician = !(profile?.roles?.includes('Physician') || profile?.profile?.roles?.includes('Physician'))
  const [shouldShowAnimation, setShouldShowAnimation] = useState(true);
  const history = useHistory()
  const [previousPath, setPreviousPath] = useState('');
  const [currentPath, setcurrentpath] = useState('')
  const previousPathRef = useRef('');

  useEffect(() => {
    const isMovingBetweenSpecificRoutes =
      (previousPathRef.current === "/manage/organizations/105" ||
        previousPathRef.current === "/manage/portal-users") &&
      (history.location.pathname === "/manage/portal-users" ||
        history.location.pathname === "/manage/organizations/105");

    if (isMovingBetweenSpecificRoutes) {
      setShouldShowAnimation(false);
    } else {
      setShouldShowAnimation(true);
    }

    previousPathRef.current = history.location.pathname;
  }, [history.location.pathname, previousPath]);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setPreviousPath(currentPath);
      setcurrentpath(history.location.pathname);
    }
  }, [location.pathname, history.location.pathname]);


  return (
    <AnimatePresence exitBeforeEnter>
      {menuOpen && <motion.div
        key={'side-nav'}
        initial={{ opacity: 0, scale: '0' }}
        animate={{ opacity: 1, scale: '1' }}
        exit={{ opacity: 0, scale: '0' }}
        transition={{ duration: 0.2 }}
        className="lg:h-full justify-between flex border-t lg:border-t-0 lg:flex-col border-r w-full lg:w-[109px]">
        <ul className="flex lg:block justify-between w-full ">
          {dataConstants.SideLinks.filter((link: any) => {
            if (isPLUUser) {
              return PLUforbiddenLinks.includes(link.to) === false
            }

            if (isNotAPhysician)
              return link.to !== "/referral-orders" && link.to !== '/profile'

            return link.to !== '/profile'
          }).map((link: any) => (loading ?
            <SideNavLinkLoadingIndicator
              key={link.to}
            /> :
            <CustomNavLink
              key={link.to}
              {...link}
            />))}
          <li className="lg:hidden">
            {loading ? <SideNavLinkLoadingIndicator
            /> :
              <div className="relative">
                <CustomNavLink
                  to={"/notifications"}
                  title={"Notifications"}
                  iconName={'notifications'}
                />
                <NotificationBadge unReadCount={unReadCount} />
              </div>

            }
          </li>
          <li className="lg:hidden">
            {loading ? <SideNavLinkLoadingIndicator
            /> :
              <CustomNavLink
                to={"/help"}
                title={"help"}
                iconName={'help'}
              />
            }
          </li>
          <li className="lg:hidden">
            {loading ? <SideNavLinkLoadingIndicator /> : <CustomNavLink
              to={"/terms_conditions"}
              title={"Terms and Conditions"}
              iconName={'terms_conditions'}
            />}
          </li>
          <li className="lg:hidden">
            {loading ? <SideNavLinkLoadingIndicator /> : <CustomNavLink
              to={"/profile"}
              title={"profile"}
              iconName={"profile"}
            />}
          </li>
        </ul>
        <ul className="side-nav-bottom-links">

          <li>
            {loading ? <SideNavLinkLoadingIndicator /> : <div className="relative">
              <CustomNavLink
                to={"/notifications"}
                title={"Notifications"}
                iconName={'notifications'}
              />
              <NotificationBadge unReadCount={unReadCount} />
            </div>}
          </li>
          <li>
            {loading ? <SideNavLinkLoadingIndicator /> : <CustomNavLink
              to={"/terms_conditions"}
              title={"Terms and Conditions"}
              iconName={'terms_conditions'}
            />}
          </li>
          <li>
            {loading ? <SideNavLinkLoadingIndicator /> : <CustomNavLink
              to={"/help"}
              title={"help"}
              iconName={'help'}
            />}
          </li>
          <li>
            {loading ? <SideNavLinkLoadingIndicator /> : <CustomNavLink
              to={"/profile"}
              title={"profile"}
              iconName={"profile"}
            />}
          </li>
        </ul>
      </motion.div>}
    </AnimatePresence>

  );
}
export default SideNav;
