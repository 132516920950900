import ManagerSignUpFormValues from '../../models/forms/managersignupformvalues.interface'
import OrganizationSignupValues from '../../models/forms/organizationsignupformvalues.interface'
import PhysicianFormValues from '../../models/forms/physicianfromValues'
import reduxActionType from '../../models/reduxActionType'
import * as actions from '../Actions/types'

export type SignUpFormValues = OrganizationSignupValues & ManagerSignUpFormValues & PhysicianFormValues

const initialState: SignUpFormValues = {
  organization_name: '',
  description: '',
  // email_id: '',
  phone: '',
  address: '',
  address2: '',
  city: '',
  zip_code: '',
  state: '',
  scc: false,
  cm: false,
  um: false,
  tc: false,
  ddm: false,
  idg: false,
  first_name: '',
  last_name: '',
  manager_email: '',
  // isPhysician: false,
  firstName: '',
  lastName: '',
  specialty: '',
  suffix: '',
  email_id: '',
  npi: ''
  // manager_phone: '',
  // manger_address: '',
  // manager_city: '',
  // manager_zip_code: '',
  // manager_state: '',
}

function SignUpFormReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case actions.SET_SIGNUP_VALUES: {
      return {
        ...state,
        ...action.payload
      }
    }
    case actions.RESET_SIGNUP_VALUES: {
      return initialState
    }
    case actions.SET_SIGNUP_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case actions.LOGOUT:
      return initialState
    default:
      return state
  }
}

export default SignUpFormReducer
