import * as React from "react"
import { SVGProps } from "react"

const Help = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} {...props}>
     <g
            data-name="Component 6411 \u2013 4"
            transform="translate(.5 .5)"
            fill={props.fill ?? 'none'}
            stroke={props.stroke ?? '#172b4d'}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
      <path
        d="M8.5 8.5H4.944A3.556 3.556 0 1 0 8.5 4.944 3.556 3.556 0 0 0 4.944 8.5"
        data-name="Path 5343"
      />
      <path d="M8.5 8.5h-8a8 8 0 1 0 8-8 8 8 0 0 0-8 8" data-name="Path 5344" />
      <path d="m11.167 11.167 2.978 2.978" data-name="Path 5345" />
      <path d="m5.834 11.167-2.978 2.978" data-name="Path 5346" />
      <path d="m2.856 2.856 2.978 2.978" data-name="Path 5347" />
      <path d="m14.145 2.856-2.978 2.978" data-name="Path 5348" />
    </g>
  </svg>
)
export default Help
