import { BlockBlobClient } from '@azure/storage-blob';
import { useOktaAuth } from '@okta/okta-react';
import { AnimatePresence, motion } from 'framer-motion'
import Header from '../../../components/global/Header';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/atomic/Button';
import CustomInput from '../../../components/global/CustomInput';
import FileDetail from '../../../components/global/FileDetail';
import TestOrdersTable from '../../../components/TestOrders/TestOrdersTable';
import { createPDFOrder } from '../../../Store/Actions/PDFOrderActions';
import http from '../../../Store/API/HttpMethods';
import DownloadHelper from '../../../utils/helpers/fileDownloader';
import toastMessages from '../../../utils/helpers/toastMessage';
import PDForderValidation from '../../../validations/pdf_order_validation';
import { useHistory } from 'react-router-dom';
import DocumentLoadingIndicator from '../../../components/global/LoadingIndicators/DocumentSectionLoadingIndicator';
import { setButtonState } from '../../../Store/Actions/ButtonStateActions';

function TestPdfDownloader({
  filename,
  blobUrl,
  title,
  subtitle
}: {
  filename: string;
  blobUrl: string;
  title: string;
  subtitle: string
}) {
  const [downloading, setDownloading] = useState<boolean>(false);
  function handleClick() {
    setDownloading(true);
    DownloadHelper.fileDownloader(
      blobUrl,
      filename,
      () => {
        setDownloading(false);
      },
      (error: any) => {
        setDownloading(false);
        toastMessages.error(
          error.message ?? `Failed downloading ${title}`
        );
      }
    );
  }

  return (
    <div className="flex flex-col w-[15%] tab:w-[30%]">
      <p className="text-royalBlue overflow-hidden overflow-ellipsis">
        {title}
      </p>
      <p className="text-royalBlue ">{subtitle}</p>
      <div className="mt-auto">
        <Button
          btnType="secondary"
          title={downloading === true ? "Downloading" : "Download"}
          submitting={downloading}
          disabled={downloading}
          className="!text-sm focus:border-[#647188] outline-[#647188] my-3"
          onClick={handleClick}
        />
      </div>
    </div>
  );
}


function DocumentsScreen({ submitting, error, success, createOrder, profile }: any) {
  const initialValues = {
    firstName: '',
    lastName: '',
    paperTRFDocuments: [],
    paperTRFDocumentsFiles: []
  }
  const { authState } = useOktaAuth()
  const [files, setFiles] = useState<any[]>([])
  const [newFiles, setNewFiles] = useState<any[]>([])
  const history = useHistory()
  const [testData, setTestData] = useState([]);
  const [fetchingDocument, setFetchingDocument] = useState(true)
  const dispatch = useDispatch()

  function handleSubmit(
    data: any,
    { resetForm, setFieldError }: any
  ): void {
    if (submitting) return

    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    };
    const options = {
      data,
      config,
      onError: (message: string) => toastMessages.error(message),
      onSuccess: (message: string) => {
        toastMessages.success(message)
        resetForm()
        setFiles([]);
        setNewFiles([])
      },
    };
    createOrder(options);
  }
  function Render({ errors,
    touched,
    setFieldValue,
    isValid,
    values,
    initialValues,
    setFieldError,
    validateField,
    validateForm,
    ...rest }: any) {
    const inputRef = useRef<any>();
    const [uploading, setUploading] = useState<boolean>(false)
    const { authState } = useOktaAuth()

    const validateFile = (file: any): boolean => {
      let sizeInMB = null;
      sizeInMB = file.size / 1024 / 1024;

      if (files.length > 0) {
        if (files.some((item: any) => item.name === file.name)) {
          alert("File already added");
          return false;
        }
      }

      if (sizeInMB > 50) {
        alert("File size limit is being exceeded");
        return false;
      }
      return true;
    };

    function handleFiles(event: any): void {
      let inputFiles = event.target.files;
      let temp: any[] = [];

      if (files.length > 0) {
        temp = [...files];
      }

      let temp2 = [...newFiles];
      for (const element of inputFiles) {
        if (validateFile(element)) {
          temp.push(element);
          temp2.push(element);
        }
      }
      setFiles(temp);
      setNewFiles(temp2);
      handleTransmit(temp2)
    }

    async function handleDrop(event: any) {
      event.preventDefault()
      event.stopPropagation()
      let inputFiles = event.dataTransfer.files;
      let temp: any[] = [];

      if (files.length > 0) {
        temp = [...files];
      }

      let temp2 = [...newFiles];
      for (const element of inputFiles) {
        if (validateFile(element)) {
          temp.push(element);
          temp2.push(element);
        }
      }
      setFiles(temp);
      setNewFiles(temp2);
      handleTransmit(temp2)
    }

    function handleRemove(mainIndex: number) {
      return async () => {
        let file = values?.paperTRFDocuments.find(
          (item: any, index: number) => index == mainIndex
        )
        let fileName = file ? file?.filePath?.split('/')[1] : ''

        if (!!fileName == false) return
        try {
          const { message } = await http.deleteFileFromAzure(`/v1/pre-signed-url/file?container=documents&filename=${fileName}`, {
            headers: {
              Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
            }
          })
          toastMessages.success(message)
          inputRef.current.value = "";
          setNewFiles((prevState: any) => prevState.filter((item: any) => item.name !== files[mainIndex].name))
          setFiles((prevState: any) =>
            prevState.filter((file: any, index: number) => index !== mainIndex)
          );

          if (values.paperTRFDocuments) {
            let paperTRFDocuments = [...values.paperTRFDocuments];
            let paperTRFDocumentsFiles = [...values.paperTRFDocumentsFiles];

            setFieldValue(
              "paperTRFDocuments",
              paperTRFDocuments.filter(
                (item: any, index: number) => index !== mainIndex
              )
            );

            setFieldValue(
              "paperTRFDocumentsFiles",
              paperTRFDocumentsFiles.filter(
                (item: any, index: number) => index !== mainIndex
              )
            );
            let updatedValues = {
              ...values,
              paperTRFDocuments: paperTRFDocumentsFiles.filter(
                (item: any, index: number) => index !== mainIndex
              )
            }
            // console.log({ updatedValues, isValid })
            validateForm(updatedValues).then((res: any) => {
              console.log({ res })
            })
          }
        } catch (error: any) {
          toastMessages.error(error?.message ?? `Failed to delete ${files[mainIndex].name}`)
        }

      };
    }

    function getExt(filename: string): any {
      const ext = filename.split(".").pop();
      if (ext === filename) return "";
      return ext;
    }

    function uploadToAzure(
      inputFile: any,
      url: string,
      filePath: string,
      resolve: any,
      reject: any
    ): void {
      const blockBlobClient = new BlockBlobClient(url);
      blockBlobClient
        .uploadData(inputFile, {
          blobHTTPHeaders: { blobContentType: inputFile.type },
        })
        .then(() => {
          resolve({ filePath, url });
        })
        .catch((error: any) => reject(error));
    }

    function getPresignedUrl(inputFile: any, resolve: any, reject: any): any {
      if (!inputFile) return reject("File missing");
      const extension = getExt(inputFile.name);
      http
        .getPresignedUrl(`v1/pre-signed-url/documents/${inputFile.name.replace(/ /g, "_")}`, {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        })
        .then(({ url, filepath }: any) => {
          if (url) {
            uploadToAzure(inputFile, url, filepath, resolve, reject);
          } else {
            reject("URL not provided");
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    }

    function uploadFile(inputFile: any): any {
      return new Promise((resolve, reject): void => {
        getPresignedUrl(inputFile, resolve, reject);
      });
    }
    function handleTransmit(newFiles: any[]): void {
      if (uploading) return;
      setUploading(true);
      Promise.all([...newFiles.map((file: any) => uploadFile(file))])
        .then((results: any) => {
          if (values.paperTRFDocuments && values.paperTRFDocuments.length > 0) {
            setFieldValue("paperTRFDocuments", [
              ...values.paperTRFDocuments,
              ...results,
            ]);
          } else {
            setFieldValue("paperTRFDocuments", results);
          }
          setFieldValue("paperTRFDocumentsFiles", files.map((file: any) => ({ ...file, existing: true })));
          validateForm({ ...values, paperTRFDocuments: [...values.paperTRFDocuments, ...results] }).then((res: any) => {
            console.log({ res })
          })
          setUploading(false);
          setNewFiles([]);
          // dispatch(ButtonStateActions.toggleSumbit(isValid));
        })
        .catch((error: any) => {
          toastMessages.error(error ?? "Something went wrong");
          setUploading(false);
        });
    }
    return <Form>
      <div className='flex justify-between items-center w-full'>
        <p className='font-semibold text-royalBlue'>Place an order via PDF</p>
        <Button
          title={'submit'}
          btnType={"primary"}
          type={'submit'}
          submitting={submitting}
          disabled={isValid == false || uploading || values.paperTRFDocuments.length == 0}
          onClick={() => { }}
        />
      </div>
      <p className='font-normal text-royalBlue py-3'>Please enter patient's first and last name</p>
      <div className="flex justify-between items-center w-full lg:w-3/6">
        <CustomInput
          id={'firstName'}
          key={'firstName'}
          name={'firstName'}
          label={'First Name'}
          className={'mr-5'}
          isInternal
          value={values.firstName}
          // onChange={(val: any) => setFieldValue('patient_middleinitial', val)}
          onChange={(e: any) => {
            setFieldValue(e.target.name, e.target.value)
          }}
          required
          placeholder={'Enter patient’s first name'}
          errors={errors}
          touched={touched}
        />
        <CustomInput
          id={'lastName'}
          key={'lastName'}
          name={'lastName'}
          label={'Last Name'}
          isInternal
          value={values.lastName}
          // onChange={(val: any) => setFieldValue('patient_middleinitial', val)}
          onChange={(e: any) => {
            setFieldValue(e.target.name, e.target.value)
          }}
          required
          placeholder={'Enter patient’s last name'}
          errors={errors}
          touched={touched}
        />
      </div>
      <div
        onDragOver={e => e.preventDefault()}
        onDragEnter={e => e.preventDefault()}
        onDragLeave={e => e.preventDefault()}
        onDrop={handleDrop}>
        <div className="my-2">
          <p className="text-royalBlue my-2">
            Please select and upload file(s)
          </p>
          {files && files.length > 0 && (
            <div className="my-3 flex items-center flex-wrap">
              {files.map((file: any, mainIndex: number) => (
                <FileDetail
                  key={file.name}
                  url={
                    values?.paperTRFDocuments &&
                    values?.paperTRFDocuments?.length > 0 &&
                    values?.paperTRFDocuments[mainIndex]?.url
                  }
                  handleRemove={handleRemove(mainIndex)}
                  file={file}
                  hideRemove={uploading}
                  uploading={uploading}
                  // hideRemove={(values?.paperTRFDocumentsFiles[mainIndex]?.existing || uploading) ?? false}
                  disabled={false}
                  isSummary
                />
              ))}
            </div>
          )}
          <Button
            btnType="secondary"
            title={"Upload File(s)"}
            disabled={
              values?.status === 90 ||
                values?.status === 100
                ? true
                : false
            }
            onClick={() => inputRef.current.click()}
          />

          <input
            type="file"
            id="file"
            multiple
            accept=".png, .jpeg, .pdf, .doc/*"
            ref={inputRef}
            // onChange={onChange}
            onChange={handleFiles}
            style={{ display: "none" }}
          />
        </div>
      </div>

    </Form >
  }

  async function handleGetDocuments() {

    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`
      }
    }
    setFetchingDocument(true)
    try {
      const { data } = await http.viewDocuments('/v1/documents?type=test-docs', config)
      setTestData(data)
      setFetchingDocument(false)
    } catch (error: any) {
      toastMessages.error('Failed to open documents')
      setFetchingDocument(false)
    }
  }

  useEffect(() => {
    if (profile?.roles?.includes('PathLab User') || profile?.profile?.roles?.includes('PathLab User') && (profile?.profile?.roles?.length == 1 || profile?.roles?.length == 1)) {
      return history.replace('/unauthorized')
    }
    handleGetDocuments()
    dispatch(setButtonState('documents screen'))
  }, [])

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Header
          isInternal
          CustomButtons={() => { <div></div> }}
          title={'Documents'}
        />
        <div className='overflow-auto'>
          <div className='mx-5 border-b pb-5'>
            <p className='font-semibold text-royalBlue py-5'>Download a PDF test form</p>
            <div className='flex flex-wrap pb-3'>
              {fetchingDocument ? <DocumentLoadingIndicator /> : testData.map((test: any) => (<TestPdfDownloader key={test.id} {...test} />))}
            </div>
          </div>
          <div className='mx-5 border-b py-5 overflow-auto'>
            <Formik
              initialValues={initialValues}
              isInitialValid={false}
              validationSchema={PDForderValidation}
              onSubmit={handleSubmit}
              render={Render}
            />
          </div>
          <div className='mx-5 border-b py-5 overflow-auto'>
            <TestOrdersTable screen='documents' hideButtons={false} className={`!h-[50vh]`} />
          </div>
        </div>

      </motion.div>
    </AnimatePresence>
  );

}

const mapStateToProps = (state: any) => ({
  submitting: state.PDFOrder.submitting,
  error: state.PDFOrder.error,
  success: state.PDFOrder.success,
  profile: state.Profile
})

const mapDispatchToProps = {
  createOrder: createPDFOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsScreen)