import reduxActionType from "../../models/reduxActionType";
import {
  FETCHING_HELP_DATA,
  FETCHING_HELP_DATA_ERROR,
  FETCHING_HELP_DATA_SUCCESS,
  LOGOUT,
} from "../Actions/types";

const initialState = {
  fetching: false,
  error: false,
  data: [],
};

function HelpReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case FETCHING_HELP_DATA: {
      return {
        ...state,
        fetching: true,
        error: false,
      };
    }
    case FETCHING_HELP_DATA_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        data: action.payload,
      };
    }
    case FETCHING_HELP_DATA_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true,
      };
    }
    case LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
}

export default HelpReducer;
