import FetchSettingsOptions from "../../models/fetchsettings.model";
import UpdateOrdersTableOptions from "../../models/update-ordertable-settigs.model";
import UpdateSettingsPayload from "../../models/updateSettings.model";
import http from "../API/HttpMethods";
import {
 FETCH_SETTINGS,
 FETCH_SETTINGS_ERROR,
 FETCH_SETTINGS_SUCCESS,
 UPDATE_SETTINGS,
 UPDATE_SETTINGS_SUCCESS,
} from "./types";

const fetchSettings =
 (options: FetchSettingsOptions) => async (dispatch: any) => {
  const { settingsType, accessToken, onSuccess, onError } = options;
  try {
   dispatch({ type: FETCH_SETTINGS });
   const { statusCode, data } = await http.getSettings(
    `v1/settings${settingsType ? `/${settingsType}` : ""}`,
    {
     headers: { Authorization: `Bearer ${accessToken}` },
    }
   );

   if (statusCode == 200) {
    dispatch({ type: FETCH_SETTINGS_SUCCESS, payload: data });
    onSuccess?.(data);
   }
  } catch (error: any) {
   dispatch({ type: FETCH_SETTINGS_ERROR });
   onError(error.message);
  }
 };

const updateOrderTableSettings =
 (options: UpdateOrdersTableOptions) => async (dispatch: any) => {
  const { accessToken, value, onError } = options;
  try {
   const body: UpdateSettingsPayload = {
    type: "ORDER_TABLE_FIELDS",
    value,
   };

   dispatch({ type: UPDATE_SETTINGS, payload: body });

   const { statusCode, data } = await http.updateSettings("v1/settings", body, {
    headers: { Authorization: `Bearer ${accessToken}` },
   });

   if (statusCode == 200 || statusCode == 201) {
    dispatch({ type: UPDATE_SETTINGS_SUCCESS, payload: data });
   }
  } catch (error: any) {
   onError(error.message);
  }
 };

const SettingsActions = {
 fetchSettings,
 updateOrderTableSettings,
};

export default SettingsActions;
