/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { AnimatePresence, motion } from 'framer-motion'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ButtonStateActions, { toggleSumbit } from '../../../../Store/Actions/ButtonStateActions'
import { toggleClinicalInfoValid } from '../../../../Store/Actions/OnlineOrderFormActions'
import Button from '../../../atomic/Button'
import CustomInput from '../../../global/CustomInput'
import { InView } from 'react-intersection-observer'
import ReactTooltip from "react-tooltip";
import scrollHelper from '../../../../utils/helpers/scrollHelper'
import dataConstants from '../../../../constants/dataConstants'
import validationHelper from '../../../../utils/helpers/orderFormValidationHelper'
import MultiSelectDropdown from '../../../global/MultiselectDropdown'
import RequiredFieldTooltip from '../../../global/RequiredFieldTooltip'
import TooltipHelper from '../../../global/TooltipHelper'
import WarningIcon from '../../../../assets/svg/WarningIcon'
import http from '../../../../Store/API/HttpMethods'
import toastMessages from '../../../../utils/helpers/toastMessage'
import { useOktaAuth } from '@okta/okta-react'
import NotificationActions from '../../../../Store/Actions/NotificationsActions'
import TermsAndConditionsPopup from '../../../global/TermsAndConditionsPopup'
import WarningBanner from '../../../global/WarningBanner'

function Tooltip({ text, id }: any): JSX.Element {
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
  return <div className="mt-1">
    <div style={{ display: tooltipVisible ? 'block' : 'none' }} className="absolute left-0 top-0 shadow-md p-2 bg-white rounded-sm ">
      <p className="text-sm text-royalBlue ">{text}</p>
    </div>
    <div
      // title={text}
      // data-for={id}
      data-tip={text}
      data-iscapture="true"
      // onMouseOver={() => { setTooltipVisible(true) }}
      // onMouseLeave={() => { setTooltipVisible(false) }}
      style={{ height: 15, width: 15 }} className={'self-end mb-2 cursor-pointer rounded-full bg-primary hover:bg-primaryHover text-center text-white flex flex-col justify-center items-center font-medium text-xs'}>
      ?
    </div>

  </div>
}


function QuestionCard({ handleInView, question, index, setFieldValue, errors, touched, values, isSectionValid, setValidSections, hideThirdOption, customOptions, onBlurCapture }: any): JSX.Element {
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
  const { selectedTest } = useSelector((state: any) => state.OnlineOrderForm)


  return <InView
    id='clinical info'
    as={'div'}
    // className={selectedTab === 'select test' ? 'scroll-my-64' : 'scroll-my-32'}
    threshold={1}
    onChange={handleInView}
  >
    <div className="flex flex-col justify-center">
      {question?.apiValue === "isPrimaryCutaneousMelanocyticNeoplasm" && values?.isPrimaryCutaneousMelanocyticNeoplasm === 'No' &&
        <div className="py-3 w-[100%] bg-pink border-requiredRed rounded-md border-[1px] justify-between px-3">

          <div className="flex items-center ">
            <WarningIcon className=' 2xl:w-[3%] lg:w-[10%] xl:w-[8%]' />
            <div className="text-requiredRed text-sm ml-2">This test can only be ordered for melanocytic lesions with diagnostic uncertainty.  Please contact your Castle Area Manager for additional information.</div>
          </div>
        </div>
      }
      <div className="focus:outline-primary p-6 my-4 shadow border-[0.5px] rounded-xl flex flex-col hover:border-primary hover:border">
        <div className="relative flex items-center">
          <RequiredFieldTooltip required={true} tooltipText={'Required Field'}>
            <p className="text-royalBlue text-sm mr-3">{question.label} <span className="text-requiredRed">*</span></p>
          </RequiredFieldTooltip>
          {!!question.tooltip && <Tooltip text={question.tooltip} />}
        </div>

        <div className="flex  items-center mt-5">
          {customOptions && customOptions.length > 0 ? <Fragment>
            {customOptions.map((option: any) => (<CustomInput
              id={option.id}
              name={option.name}
              key={option.id}
              label={option.label}
              onBlurCapture={onBlurCapture}
              isInternal
              type='radio'
              value={option.value}
              checked={values[option.name] === option.value ?? false}
              onChange={(e: any) => {

                setFieldValue(option.name, e.target.value)
                if (option.customMethod) {
                  option.customMethod()
                }
                const newValues = { ...values }
                newValues[option.name] = e.target.value
                isSectionValid(undefined, newValues)
              }}
              required
              placeholder={''}
              errors={errors}
              touched={touched}
            />))}
          </Fragment> : <Fragment>
            <CustomInput
              id={'question'}
              name={`question${index + 1}`}
              label={'Yes'}
              isInternal
              type='radio'
              value={'Yes'}
              onBlurCapture={onBlurCapture}
              checked={values[`question${index + 1}`] === 'Yes' ?? false}
              onChange={(e: any) => {
                if (question.apiValue) {
                  setFieldValue(question.apiValue, e.target.value)
                  setFieldValue(`question${index + 1}`, e.target.value)
                } else {
                  setFieldValue(`question${index + 1}`, e.target.value)
                }
                const newValues = { ...values }
                newValues[`question${index + 1}`] = e.target.value
                isSectionValid(undefined, newValues)
              }}
              required
              placeholder={''}
              errors={errors}
              touched={touched}
            />
            <CustomInput
              id={'question'}
              // name={`question`}
              name={`question${index + 1}`}
              label={'No'}
              value={'No'}
              isInternal
              onBlurCapture={onBlurCapture}
              type="radio"
              checked={values[`question${index + 1}`] === 'No' ?? false}
              onChange={(e: any) => {
                if (question.apiValue) {
                  setFieldValue(question.apiValue, e.target.value)
                  setFieldValue(`question${index + 1}`, e.target.value)
                } else {
                  setFieldValue(`question${index + 1}`, e.target.value)
                }

                const newValues = { ...values }
                newValues[`question${index + 1}`] = e.target.value
                isSectionValid(undefined, newValues)
              }}
              required
              placeholder={''}
              errors={errors}
              touched={touched}
            />
            {hideThirdOption ? null : <CustomInput
              id={'question'}
              // name={`question`}
              name={`question${index + 1}`}
              label={'I don\'t know'}
              type='radio'
              onBlurCapture={onBlurCapture}
              checked={values[`question${index + 1}`] === 'N/A' ?? false}
              value={'N/A'}
              onChange={(e: any) => {
                if (question.apiValue) {
                  setFieldValue(question.apiValue, e.target.value)
                  setFieldValue(`question${index + 1}`, e.target.value)
                  if (question.apiValue === 'isSentinelLymphNodeBiopsy')
                    setFieldValue('lymphNodeStatus', 'NX/Unknown')
                } else {
                  setFieldValue(`question${index + 1}`, e.target.value)
                }
                const newValues = { ...values }
                newValues[`question${index + 1}`] = e.target.value
                isSectionValid(undefined, newValues)
              }}
              required
              placeholder={''}
              errors={errors}
              touched={touched}
            />}
          </Fragment>
          }


        </div>

        <AnimatePresence exitBeforeEnter>
          {
            values[`question${index + 1}`] && values[`question${index + 1}`] === 'Yes' && question.note && <div className='relative flex items-center inline mt-3'><motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="text-royalBlue text-sm -mt-1 mr-3">{question.note}

            </motion.p>
              <Tooltip text={'If SLNB was performed, select the SLNB result'} />
            </div>
          }

          {
            values[`question${index + 1}`] && (values[`question${index + 1}`] === 'Yes' || (question?.extraField?.name === 'lymphNodeStatus' && values[`question${index + 1}`] === 'N/A')) && question?.extraField && <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}>
              <CustomInput
                id={question.extraField.label}
                name={question.extraField.name}
                onBlurCapture={onBlurCapture}
                label={question.extraField.label}
                value={values[question.extraField.name] ?? ''}
                // suggestions={question.extraField.suggestions}
                suggestions={question.extraField.suggestions && question?.extraField.suggestions.length > 0 ? question.extraField.suggestions.map((item: any) => ({ label: item, value: item })) : []}
                disableSuggestiveFiltering={question?.extraField?.name === 'lymphNodeStatus'}
                suggestionInputDisabled={question?.extraField?.name === 'lymphNodeStatus'}
                disabled={question?.extraField?.name === 'lymphNodeStatus' && values[`question${index + 1}`] === 'N/A'}
                onChange={(value: any) => {
                  let updatedValues = { ...values }
                  updatedValues[question.extraField.name] = value
                  setFieldValue(question.extraField.name, value)
                  isSectionValid(value, updatedValues)
                }}
                required
                placeholder={question.extraField.placeholder}
                errors={errors}
                touched={touched}
              />
            </motion.div>
          }

        </AnimatePresence>

      </div>
      <div className="flex flex-col relative items-end">
        {/* <Tooltip text={'Select Yes if a SLNB was performed for this melanoma.'} /> */}
      </div>
    </div>
  </InView>
}
function DXMelanomaForm({ errors, touched, setFieldValue, values, setValidSections, handleOk, isValid, setSelectedTab, selectedTab, firstScroll, ...rest }: any): JSX.Element {
  const [questions, setQuestions] = useState<any>([
    {
      label: 'Has the patient had a sentinel lymph node biopsy for this melanoma?',
      note: 'Please provide the surgical pathology report in \'Supporting Documents\' tab, if available',
      tooltip: 'Select Yes if a SLNB was performed for this melanoma.',
      apiValue: 'isSentinelLymphNodeBiopsy',
      extraField: {
        label: 'Lymph Node Status',
        name: 'lymphNodeStatus',
        placeholder: 'Select lymph node status',
        suggestions: ['NX/Unknown',
          'N0',
          'N1a',
          'N1b',
          'N1c',
          'N2a',
          'N2b',
          'N2c',
          'N3a',
          'N3b',
          'N3c',
          'Positive (unspecified)',
          'Positive'
        ]
      }
    },
    {
      label: 'Does/Did this patient have clinically palpable nodes?',
      apiValue: 'isClinicallyPalpableNodes',
      tooltip: 'Select Yes if the patient has ever had clinically palpable nodes'
    },
    {
      label: 'Does/Did this patient have intransit metastasis?',
      apiValue: 'isIntrasitMetastasis',
      tooltip: 'Select Yes if the patient has ever had  intransit metastasis'
    }
  ])
  const [sectionValid, setSectionValid] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    isSectionValid(undefined, values)
  }, [values.lymphNodeStatus])

  function isSectionValid(inputValue: any, values: any): any {
    const dataMissing = questions.some((question: any, index: number) => values[`question${index + 1}`] == '' || values[`question${index + 1}`] == undefined)

    if (dataMissing) {
      setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'))
      dispatch(ButtonStateActions.toggleSumbit(false))
      dispatch(toggleClinicalInfoValid(false))
      return setSectionValid(false)
    }

    if (inputValue !== undefined) {
      setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'))
      dispatch(ButtonStateActions.toggleSumbit(false))
      dispatch(toggleClinicalInfoValid(false))
      return setSectionValid(inputValue !== '' ?? false)
    }

    for (let i = 0; i < questions.length; i++) {
      if ((values[`question${i + 1}`] === 'Yes' || values[`question${i + 1}`] === 'N/A') && questions[i].extraField) {
        if (values[questions[i].extraField.name] === '') {
          setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'))
          dispatch(ButtonStateActions.toggleSumbit(false))
          dispatch(toggleClinicalInfoValid(false))
          setSectionValid(false)
        } else {
          setValidSections((prevState: any) => [...prevState, 'clinical info'])
          setSectionValid(true)
          dispatch(ButtonStateActions.toggleSumbit(isValid))
          dispatch(toggleClinicalInfoValid(true))
        }
        break
      } else {
        setValidSections((prevState: any) => [...prevState, 'clinical info'])
        dispatch(ButtonStateActions.toggleSumbit(isValid))
        dispatch(toggleClinicalInfoValid(true))
        setSectionValid(true)
      }
    }
  }

  function handleBlur() {
    rest.setTouchedSections((prevState: string[]) => [...prevState, 'clinical info'])
  }

  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, firstScroll, 'clinical info')
    // if (!inView && rest.touchedSections.includes('clinical info'))
    //   questions.forEach((question) => {
    //     rest.setFieldTouched(question.name, true, true)
    //   })
  }

  return <InView
    id='clinical info'
    as={'div'}
    className={(selectedTab === 'select test' || firstScroll) ? 'scroll-my-64' : 'scroll-my-32'}
    threshold={window.innerWidth <= 1280 ? 0.5 : 0.8}
    onChange={handleMouseScroll}>
    <div className="pb-14 flex flex-col">
      {questions.length > 0 && questions.map((question: any, index: number) => <QuestionCard
        key={index + 1}
        question={question}
        index={index}
        errors={errors}
        onBlurCapture={handleBlur}
        touched={touched}
        setFieldValue={setFieldValue}
        setSectionValid={setSectionValid}
        isSectionValid={isSectionValid}
        values={values}
        handleInView={handleMouseScroll}
      />)}
      <Button
        btnType="primary"
        title={'Ok'}
        disabled={!sectionValid}
        className={'self-end mt-4'}
        onClick={handleOk} />
    </div>
    <TooltipHelper
      // id={id}
      // place={'top'}
      // type={'dark'}
      effect={'float'}
      scrollHide={true}
      multiline={true}
    />
  </InView>
}
function DXSccForm({ errors, touched, setFieldValue, values, setValidSections, handleOk, isValid, setSelectedTab, selectedTab, firstScroll, ...rest }: any): JSX.Element {
  const [sectionValid, setSectionValid] = useState<boolean>(true)
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])
  const [showInput, setShowInput] = useState(false)
  const [questions, setQuestions] = useState(dataConstants.SCCQuestions)
  const dispatch = useDispatch()


  function handleChange(id: number, checkboxId: string, name: string, setFieldValue: any,): any {
    if (selectedOptions.length === 0) {
      const data = [{ id, name, options: [checkboxId] }]
      return setSelectedOptions(data)
    }

    const temp = [...selectedOptions]
    const foundIndex = temp.findIndex(item => item.id === id)

    if (foundIndex !== -1) {
      const currentOptions = temp[foundIndex].options
      const index = currentOptions.indexOf(checkboxId)

      if (index !== -1) {
        currentOptions.splice(index, 1)
      } else {
        currentOptions.push(checkboxId)
      }
    } else {
      temp.push({ id, name, options: [checkboxId] })
    }

    if (checkboxId === 'Other') {
      if (!!values?.other == false) {
        setSectionValid(false)
      } else {
        setSectionValid(temp.some(item => item.options.length == 0) ? false : true)
      }
      setShowInput(prevState => !prevState)
    }

    // if (temp.some(item => item.options.includes('Other'))) {
    //     setSectionValid(false)
    // } else {
    //     setSectionValid(temp.some(item => item.options.length == 0) ? false : true)
    // }
    setSelectedOptions(temp)
    setFieldValue(name, temp.find(item => item.name === name))
  }

  function handleBlur() {
    rest.setTouchedSections((prevState: string[]) => [...prevState, 'clinical info'])
  }

  useEffect(() => {
    const history_physical_exam = values.history_physical_exam?.options
    const pathology_surgical_findings = values.pathology_surgical_findings?.options

    setSelectedOptions([
      { id: 1, name: 'history_physical_exam', options: history_physical_exam ?? [] },
      { id: 2, name: 'pathology_surgical_findings', options: pathology_surgical_findings ?? [] }
    ])

    if (history_physical_exam?.length > 0 || pathology_surgical_findings?.length > 0) {
      if (history_physical_exam?.includes('Other')) {
        setShowInput(true)
        if (Array.isArray(values.other)) {
          setSectionValid(false)
          dispatch(ButtonStateActions.toggleSumbit(false))
          dispatch(toggleClinicalInfoValid(false))
          setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'))
        } else if (!!values?.other === false) {
          setSectionValid(false)
          dispatch(ButtonStateActions.toggleSumbit(false))
          dispatch(toggleClinicalInfoValid(false))
          setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'))
        } else {
          setSectionValid(true)
          dispatch(toggleClinicalInfoValid(true))
          dispatch(ButtonStateActions.toggleSumbit(isValid))
          setValidSections((prevState: any) => [...prevState, 'clinical info'])
        }
      } else {
        setSectionValid(true)
        dispatch(toggleClinicalInfoValid(true))
        dispatch(ButtonStateActions.toggleSumbit(isValid))
        setValidSections((prevState: any) => [...prevState, 'clinical info'])
      }
    } else {
      setSectionValid(false)
      dispatch(toggleClinicalInfoValid(false))
      dispatch(ButtonStateActions.toggleSumbit(false))
      setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'))
    }
  }, [values?.history_physical_exam?.options?.length, values?.pathology_surgical_findings?.options?.length, values?.other])

  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, firstScroll, 'clinical info')
    if (!inView && rest.touchedSections.includes('clinical info'))
      questions.forEach((question) => {
        rest.setFieldTouched(question.name, true, true)
      })
  }

  return <InView id='clinical info'
    className={(selectedTab === 'select test' || firstScroll) ? 'scroll-my-64' : 'scroll-my-32'}
    as="div"
    threshold={window.innerWidth <= 1280 ? 0.5 : 0.8} onChange={handleMouseScroll}>
    <div className="pb-14 flex flex-col">
      <div className="focus:outline-primary p-6 my-5 shadow-lg border border-[0.5px] rounded-md hover:border-primary hover:border oveflow-auto break-all">
        <div className="flex ">
          {questions.map((question, parentIndex) => (<div key={question.questionId}>
            {/* <p className="text-royalBlue mb-2 font-medium text-sm">{question.label} <span className="text-requiredRed">*</span></p> */}
            <div className="relative flex items-center">
              <RequiredFieldTooltip required={true} tooltipText={'Required Field'}>
                <p className="text-royalBlue text-sm mr-3 break-normal">{question.label} <span className="text-requiredRed">*</span></p>
              </RequiredFieldTooltip>

              <Tooltip
                id={question.tooltip}
                text={question.tooltip} />

            </div>
            {question.options.map((option: any) => (<CustomInput
              id={option.id.toString()}
              key={option.label}
              name={option.name}
              label={option.label}
              required={false}
              onBlurCapture={handleBlur}
              placeholder={''}
              checkboxContainerStyle={`!justify-start w-full ${option.customLabelHTML ? '!items-start' : '!items-center'} `}
              errors={errors}
              touched={touched}
              customLabelHTML={option?.customLabelHTML}
              labelClassName={`${option.customLabelHTML ? 'mt-1' : ''}`}
              className={'my-2'}
              checked={values[option.name]?.options?.includes(option.value) ?? false}
              onChange={(e: any) => {
                handleChange(question.questionId, option.value, option.name, setFieldValue)
              }}
              type='checkbox'
            />))}
            {question.questionId == 1 && showInput && <CustomInput
              id={'other'}
              key={'other'}
              name={'other'}
              label={'Other'}
              required={false}
              placeholder={'Enter other option'}
              checkboxContainerStyle={'!justify-start w-full !items-center'}
              errors={errors}
              touched={touched}
              value={values.other}
              onBlurCapture={handleBlur}
              className={'my-2 '}
              // isTextArea
              onChange={(e: any) => {
                // handleChange(question.questionId, option.id, option.name, setFieldValue);
                setFieldValue('other', e.target.value)
              }}
            />}
          </div>))}
        </div>
        <div className={`${showInput ? 'mt-20' : 'mt-5'}`}>
          <p className='text-center'>
            <div className='text-royalBlue text-md'>*DecisionDx-SCC has not been evaluated for testing in tissue from locally recurrent tumors*</div>
            <span className={`text-royalBlue text-xs mt-7 break-normal`}>
              <sup>A</sup>Acantholytic (adenoid), adenosquamous (showing mucin production), carcinosarcomatous (metaplastic) or desmoplastic subtypes (others will be considered on a case-by-case basis).
            </span>
          </p>
        </div>
      </div>
      <Button
        btnType="primary"
        title={'Ok'}
        disabled={!sectionValid}
        className={'self-end'}
        onClick={handleOk} />
    </div>
    <TooltipHelper
      // id={id}
      // place={'top'}
      // type={'dark'}
      effect={'float'}
      scrollHide={true}
      multiline={true}
    />
  </InView>
}

function IDGForm({ errors, touched, setFieldValue, values, setValidSections, handleOk, isValid, setSelectedTab, selectedTab, firstScroll, ...rest }: any): JSX.Element {
  const [sectionValid, setSectionValid] = useState<boolean>(true)
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])
  const [showInput, setShowInput] = useState(false)
  const { authState } = useOktaAuth()
  const { icdCodes } = useSelector((state: any) => state.OnlineOrderForm)
  const selectedIdIcd10 = typeof (values.icd_10) === 'number' ? values.icd_10 : icdCodes?.find((icdCode: any) => icdCode?.value === values?.icd_10?.split(' ')?.[0])?.id
  const [medicationValues, setMedicationValues] = useState<any>()
  const [questions, setQuestions] = useState<any>()
  const [fetchedMedications, setFetchedMedications] = useState<boolean>(false)
  const [fetchingMedications, setFetchingMedications] = useState<boolean>(false)

  // const [questions, setQuestions] = useState([
  //   {
  //     questionId: 1,
  //     label: 'Select ALL the NeuroIDgenetix medications you are considering for new treatment and/or dosage change for this patient.',
  //     tooltip: '',
  //     name: 'isAltheaDxNeuropsychotropicMedications',
  //     options: medicationValues,
  //     dangerouslySetLabel: true
  //   },
  //   // {
  //   //   questionId: 3,
  //   //   label: `Patient's condition appears difficult to treat as evidenced by therapeutic failure of the following current or previous psychiatric medications.`,
  //   //   name: 'isIDgNeuropsychotropicMedications',
  //   //   tooltip: '',
  //   //   options: dataConstants.isIDgNeuropsychotropicMedicationsValues,
  //   //   dangerouslySetLabel: true
  //   // },
  //   {
  //     questionId: 4,
  //     label: `CURRENT PRESCRIPTION MEDICATIONS - Please select all current psychiatric and non-psychiatric prescription medications. (If patient MAR sheet is uploaded, this section can be left blank)`,
  //     name: 'isIDgNeuropsychotropicMedicationsCurrent',
  //     tooltip: '',
  //     options: dataConstants.isIDgNeuropsychotropicMedicationsCurrentValues,
  //     dangerouslySetLabel: true
  //   },
  //   {
  //     questionId: 2,
  //     label: `LIFESTYLE FACTORS, OTC MEDICATIONS & OTHER - Please select all that the patient regularly takes or uses any of the items below`,
  //     name: 'isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement',
  //     tooltip: '',
  //     options: dataConstants.isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplementValues,
  //     dangerouslySetLabel: true
  //   },

  // ])
  const uniqueLabelsSet = new Set();

  // Filter out objects with duplicate labels
  const uniqueMedicationValues = medicationValues?.filter((medication: any) => {
    if (!uniqueLabelsSet.has(medication?.label)) {
      uniqueLabelsSet.add(medication?.label);
      return true;
    }
    return false;
  }).map((item: any) => ({ ...item, value: item.name, name: 'isAltheaDxNeuropsychotropicMedications' }));

  useEffect(() => {
    if (medicationValues) {
      setQuestions([
        {
          questionId: 1,
          label: 'Select ALL the NeuroIDgenetix medications you are considering for new treatment and/or dosage change for this patient.',
          tooltip: '',
          name: 'isAltheaDxNeuropsychotropicMedications',
          options: uniqueMedicationValues,
          dangerouslySetLabel: true
        },
        {
          questionId: 4,
          label: `CURRENT PRESCRIPTION MEDICATIONS - Please select all current psychiatric and non-psychiatric prescription medications. (If patient MAR sheet is uploaded, this section can be left blank)`,
          name: 'isIDgNeuropsychotropicMedicationsCurrent',
          tooltip: '',
          options: dataConstants.isIDgNeuropsychotropicMedicationsCurrentValues,
          dangerouslySetLabel: true
        },
        {
          questionId: 2,
          label: `LIFESTYLE FACTORS, OTC MEDICATIONS & OTHER - Please select all that the patient regularly takes or uses any of the items below`,
          name: 'isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement',
          tooltip: '',
          options: dataConstants.isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplementValues,
          dangerouslySetLabel: true
        },
      ]);
    }
  }, [medicationValues]);

  async function handleGetMedications() {
    if (fetchedMedications || fetchingMedications) return
    setFetchingMedications(true)

    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`
      }
    }
    // setFetchingDocument(true)
    try {
      const { data } = await http.getMedications('/v1/medications', config)
      setMedicationValues(data)
      if (data) {
        setFetchedMedications(true)
      }
      setFetchingMedications(false)
    } catch (error: any) {
      // console.log(error)
      setFetchingMedications(false)
    }
  }
  const dispatch = useDispatch()
  async function detectMismatch(item: any): Promise<void> {
    if (item.name !== "isAltheaDxNeuropsychotropicMedications") return
    let ids = item.options.map((item: any) => medicationValues.find((value: any) => value.name === item).id)
    let names = item.options.map((item: any) => medicationValues.find((value: any) => value.name === item).name)

    try {
      const { data } = await http.detectMedicationMismatch(`/v1/test-type/medication-verification?icd10Code=${[selectedIdIcd10, ...values?.additionalCodes].map(item => Number(item))}&medication=${[names.map((name: any) => name)]}`, {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`
        }
      })
      const { medicationMismatch } = data

      if (medicationMismatch) {
        toastMessages.warning('Medications selected for consideration do not match the selected ICD-10 Codes(s) diagnosis. Please revise')
      }
      setFieldValue('medicationMismatch', medicationMismatch)
    } catch (error: any) {
      toastMessages.error(error.message ?? error)
    }
  }

  function handleChange(id: number, checkboxId: string, name: string, setFieldValue: any,): any {
    if (selectedOptions.length === 0) {
      const data = [{ id, name, options: [checkboxId] }]
      return setSelectedOptions(data)
    }

    const temp = [...selectedOptions]
    const foundIndex = temp.findIndex(item => item.id === id)

    if (foundIndex !== -1) {
      let currentOptions = temp[foundIndex].options
      const index = currentOptions.indexOf(checkboxId)

      if (index !== -1) {
        currentOptions.splice(index, 1)
      } else {
        if (checkboxId === "-none-" || checkboxId === "n/a") {
          let temp = [...selectedOptions]
          temp = temp.map((item: any) => {
            if (item.name === name) {
              return { ...item, options: [checkboxId] }
            }
            return item
          })
          setSelectedOptions(temp)
          setFieldValue(name, temp.find(item => item.name === name))
          return
        } else {
          currentOptions.push(checkboxId)
        }
      }
    } else {
      temp.push({ id, name, options: [checkboxId] })
    }

    if (checkboxId?.toLowerCase() === 'other') {
      if (!!values?.other == false) {
        setSectionValid(false)
      } else {
        setSectionValid(temp.some(item => item.options.length == 0) ? false : true)
      }
      setShowInput(prevState => {
        let value = !prevState
        if (value === false) {
          setFieldValue('other', '')
        }

        return value
      })
    }

    setSelectedOptions(temp)
    setFieldValue(name, temp.find(item => item.name === name))

    if (!!selectedIdIcd10 && temp.find(item => item.name === name)?.options?.length > 0) {
      detectMismatch(temp.find(item => item.name === name))
    } else if (name === 'isAltheaDxNeuropsychotropicMedications' && temp.find(item => item.name === name)?.options?.length === 0)
      setFieldValue('medicationMismatch', false)
  }

  function handleBlur() {
    rest.setTouchedSections((prevState: string[]) => [...prevState, 'clinical info'])
  }

  useEffect(() => {
    const isAltheaDxNeuropsychotropicMedications = values.isAltheaDxNeuropsychotropicMedications?.options
    const isIDgNeuropsychotropicMedicationsCurrent = values.isIDgNeuropsychotropicMedicationsCurrent?.options
    const isIDgNeuropsychotropicMedications = values.isIDgNeuropsychotropicMedications?.options
    const isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement = values?.isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement?.options

    setSelectedOptions([
      { id: 1, name: 'isAltheaDxNeuropsychotropicMedications', options: isAltheaDxNeuropsychotropicMedications ?? [] },
      { id: 2, name: 'isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement', options: isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement ?? [] },
      // { id: 3, name: 'isIDgNeuropsychotropicMedications', options: isIDgNeuropsychotropicMedications ?? [] },
      { id: 4, name: 'isIDgNeuropsychotropicMedicationsCurrent', options: isIDgNeuropsychotropicMedicationsCurrent ?? [] },
    ])
    if (isAltheaDxNeuropsychotropicMedications?.length > 0/*  && isIDgNeuropsychotropicMedicationsCurrent?.length > 0 */ /* && isIDgNeuropsychotropicMedications?.length > 0 *//*  && isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement?.length > 0 */) {
      // setSectionValid(true)
      // dispatch(toggleClinicalInfoValid(true))
      // dispatch(ButtonStateActions.toggleSumbit(isValid))
      // setValidSections((prevState: any) => [...prevState, 'clinical info'])
      if (isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement?.includes('Other')) {
        setShowInput(true)
        if (Array.isArray(values.other)) {
          setSectionValid(false)
          dispatch(ButtonStateActions.toggleSumbit(false))
          dispatch(toggleClinicalInfoValid(false))
          setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'))
        } else if (!!values?.other === false) {
          setSectionValid(false)
          dispatch(ButtonStateActions.toggleSumbit(false))
          dispatch(toggleClinicalInfoValid(false))
          setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'))
        } else {
          setSectionValid(true)
          dispatch(toggleClinicalInfoValid(true))
          dispatch(ButtonStateActions.toggleSumbit(isValid))
          setValidSections((prevState: any) => [...prevState, 'clinical info'])
        }
      } else {
        setSectionValid(true)
        dispatch(toggleClinicalInfoValid(true))
        dispatch(ButtonStateActions.toggleSumbit(isValid))
        setValidSections((prevState: any) => [...prevState, 'clinical info'])
      }
    } else {
      setSectionValid(false)
      dispatch(toggleClinicalInfoValid(false))
      dispatch(ButtonStateActions.toggleSumbit(false))
      setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'))
    }
    handleGetMedications()
  }, [values?.isAltheaDxNeuropsychotropicMedications?.options?.length, values?.isIDgNeuropsychotropicMedicationsCurrent?.options?.length, values?.isIDgNeuropsychotropicMedications?.options?.length, values?.isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement?.options?.length, values?.other])

  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, firstScroll, 'clinical info')
    if (!inView && rest.touchedSections.includes('clinical info'))
      questions.forEach((question: any) => {
        rest.setFieldTouched(question.name, true, true)
      })
  }

  function handleDisabledField(question: any, values: any) {
    let currentArray = values[question.name]
    let disabledOptions = []
    if (currentArray?.options?.length > 0 && !currentArray?.options?.includes('-none-') || !currentArray?.options?.includes('n/a')) {
      disabledOptions = []
      // disabledOptions = question?.options.filter((option: any) => option.label.toLowerCase() == '-none-').map((option: any) => option.label)
    } else if (currentArray?.options?.includes('-none-') || currentArray?.options?.includes('n/a')) {
      disabledOptions = question?.options.filter((option: any) => option.value.toLowerCase() !== '-none-' || 'n/a').map((option: any) => option.label)
    } else {
      disabledOptions = []
    }
    return disabledOptions
  }

  return <InView id='clinical info'
    className={(selectedTab === 'select test' || firstScroll) ? 'scroll-my-64' : 'scroll-my-32'}
    as="div"
    threshold={window.innerWidth <= 1280 ? 0.5 : 0.1}
    onChange={handleMouseScroll}>
    <p className="text-royalBlue">Please select all options that apply.</p>
    {values.medicationMismatch && <WarningBanner warning='Medications selected for consideration do not match the selected ICD-10 Codes(s) diagnosis. Please revise' />}
    <div className="pb-14 flex flex-col">
      <div className="focus:outline-primary p-6 my-5 shadow-lg border border-[0.5px] rounded-md hover:border-primary hover:border oveflow-auto break-all">
        <div className="flex justify-between flex-wrap">
          {questions?.map((question: any, parentIndex: any) => (<div key={question.questionId} className="w-full" /* className=" basis-2/4" */>
            {/* <div className={`relative flex items-start min-h-[8vh] ${parentIndex > 1 ? 'my-2 !min-h-[5vh]' : ''}`}>
              <p className="text-royalBlue text-sm mr-3 break-normal">{question.label} <span className="text-requiredRed">*</span></p>
            </div> */}

            <MultiSelectDropdown
              dangerouslySetLabel={question.dangerouslySetLabel}
              touched={touched}
              errors={errors}
              required={question.questionId === 1 || question.questionId === 3}
              label={question?.label}
              filterByInput
              disabled={question.name === 'isAltheaDxNeuropsychotropicMedications' && values.icd_10 === false}
              optionsDisabled={handleDisabledField(question, values)}
              tooltipText={question.name === 'isAltheaDxNeuropsychotropicMedications' && !!values.icd_10 === false ? 'Please select ICD Code' : ''}
              name={question.name}
              labelStyle={'text-royalBlue !text-sm mr-3 break-normal'}
              className="flex flex-col justify-between"
              inputValuesStyle='flex-wrap overflow-auto capitalize'
              id={question.name}
              onBlurCapture={handleBlur}
              onOptionClick={(item) => {
                handleChange(question.questionId, item.value, question.name, setFieldValue)
              }}
              reset={question.name === 'isAltheaDxNeuropsychotropicMedications' ? values.resetisAltheaDxNeuropsychotropicMedications : false}
              options={question?.options/* .sort((a: any, b: any) => {
                if (a.label.toLowerCase() < b.label.toLowerCase()) {
                  return -1
                }
                if (a.label.toLowerCase() > b.label.toLowerCase()) {
                  return 1
                }
                return 0;
              }) */?.map((option: any) => ({
                label: option.label,
                name: option.name,
                value: option.value,
                selected: values[option.name]?.options?.includes(option.value) ?? false
              }))}
            />
            {question?.questionId == 2 && showInput && <CustomInput
              id={'other'}
              key={'other'}
              name={'other'}
              label={'Other'}
              required={false}
              placeholder={'Enter other option'}
              checkboxContainerStyle={'!justify-start w-full !items-center'}
              errors={errors}
              touched={touched}
              value={values.other}
              className={'my-2 pr-6 w-full'}
              // isTextArea
              onChange={(e: any) => {
                // handleChange(question.questionId, option.id, option.name, setFieldValue);
                setFieldValue('other', e.target.value)
              }}
            />}
            {/* {question.options.map(option => (<CustomInput
              id={option.id.toString()}
              key={option.label}
              name={option.name}
              label={option.label}
              required={false}
              placeholder={''}
              checkboxContainerStyle={'!justify-start w-full !items-center'}
              errors={errors}
              touched={touched}
              className={'my-2'}
              disabled={handleDisabledField(option, values)}
              // disabled={option.label !== '-none-' && values[option.name]?.options?.includes('-none-')}
              checked={values[option.name]?.options?.includes(option.label) ?? false}
              onChange={(e: any) => {
                handleChange(question.questionId, option.label, option.name, setFieldValue)
              }}
              type='checkbox'
            />))} */}
          </div>))}
        </div>
      </div>
      <Button
        btnType="primary"
        title={'Ok'}
        disabled={!sectionValid}
        className={'self-end'}
        onClick={handleOk} />
    </div>
    <TooltipHelper
      // id={id}
      // place={'top'}
      // type={'dark'}
      effect={'float'}
      scrollHide={true}
      multiline={true}
    />
  </InView>
}
function DiffDxForm({ question, index, errors, touched, setFieldValue, values, setValidSections, handleOk, isValid, setSelectedTab, selectedTab, firstScroll, isUpdate }: any): JSX.Element {
  const [questions, setQuestions] = useState<any>([
    // {
    //   id: 1,
    //   label: 'Is the malignant potential of this melanocytic lesion uncertain?',
    //   tooltip: 'Select Yes if the malignant potential of this melanocytic lesion uncertain.',
    //   apiValue: 'isMelanocyticLesionUncertain',
    // },
    {
      id: 1,
      label: `I attest the specimen being submitted for testing is a primary cutaneous melanocytic neoplasm for which the
      diagnosis is uncertain, (despite the performance of standard-of-care test procedures and relevant ancillary
      tests), the patient may be subjected to additional intervention as a result of the diagnostic uncertainty, and this
      patient was not receiving immunosuppressant or radiation therapy at the time of biopsy.`,
      tooltip: '',
      apiValue: 'isPrimaryCutaneousMelanocyticNeoplasm',
    },
  ])

  const [sectionValid, setSectionValid] = useState<boolean>(false)
  const dispatch = useDispatch()

  function handleInvalidSection(dispatch: Function, setSectionValid: Function, setValidSections: Function) {
    setSectionValid(false);
    dispatch(ButtonStateActions.toggleSumbit(false));
    dispatch(toggleClinicalInfoValid(false));
    setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'));
  }

  function handleValidFunction(dispatch: Function, setSectionValid: Function, setValidSections: Function) {
    setSectionValid(true)
    dispatch(toggleClinicalInfoValid(true))
    dispatch(ButtonStateActions.toggleSumbit(isValid))
    setValidSections((prevState: any) => [...prevState, 'clinical info'])
  }

  useEffect(() => {
    if (isUpdate) {
      isSectionValid(undefined, values)
    }

    if (values?.isPrimaryCutaneousMelanocyticNeoplasm === 'No' &&
      questions?.map((e: any) => e?.apiValue === "isPrimaryCutaneousMelanocyticNeoplasm")) {
      handleInvalidSection(dispatch, setSectionValid, setValidSections);
    } else if (values?.isPrimaryCutaneousMelanocyticNeoplasm === 'Yes' &&
      questions?.map((e: any) => e?.apiValue === "isPrimaryCutaneousMelanocyticNeoplasm")) {
      handleValidFunction(dispatch, setSectionValid, setValidSections);
    }
  }, [values?.isPrimaryCutaneousMelanocyticNeoplasm])

  function isSectionValid(inputValue: any, values: any): any {
    const dataMissing = questions.some((question: any, index: number) => values[`question${index + 1}`] == '' || values[`question${index + 1}`] == undefined)
    if (dataMissing) {
      dispatch(ButtonStateActions.toggleSumbit(false))
      dispatch(toggleClinicalInfoValid(false))
      return setSectionValid(false)
    }

    if (inputValue !== undefined) {
      dispatch(toggleClinicalInfoValid(inputValue !== '' ?? false))
      return setSectionValid(inputValue !== '' ?? false)
    }

  }
  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, firstScroll, 'clinical info')
  }

  return <InView
    id='clinical info'
    className={(selectedTab === 'select test' || firstScroll) ? 'scroll-my-64' : 'scroll-my-32'}
    as="div" threshold={0.5} onChange={handleMouseScroll}>
    <div className="pb-14 flex flex-col">
      {questions.length > 0 && questions.map((question: any, index: number) => <QuestionCard
        key={index + 1}
        question={question}
        index={index}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        customOptions={question.customOptions}
        setSectionValid={setSectionValid}
        isSectionValid={isSectionValid}
        values={values}
        handleInView={handleMouseScroll}
        hideThirdOption
      />)}
      <Button
        btnType="primary"
        title={'Ok'}
        disabled={!sectionValid}
        className={'self-end'}
        onClick={handleOk} />
      <TooltipHelper
        // id={id}
        // place={'top'}
        // type={'dark'}
        effect={'float'}
        scrollHide={true}
        multiline={true}
      />
    </div>
  </InView>
}

function UMUMSeq({ question, index, errors, touched, setFieldValue, values, setValidSections, handleOk, isValid, setSelectedTab, selectedTab, isUpdate, firstScroll, ...rest }: any): JSX.Element {
  const [questions, setQuestions] = useState<any>([
    {
      label: 'Has the tissue in this sample been exposed to radiation?',
      apiValue: 'isExposedToRadiation',
    },
    {
      label: 'Add-On Testing: DecisionDx-Prame',
      apiValue: 'isReportPrame',
    }
  ])
  const [sectionValid, setSectionValid] = useState<boolean>(false)
  const { selectedTest } = useSelector((state: any) => state.OnlineOrderForm)
  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedTest?.checkedIndexes?.length == 1 && selectedTest?.checkedIndexes[0] === 7) {
      setQuestions((prevState: any) => prevState.filter((item: any) => item.apiValue !== 'isReportPrame'))
      let updatedValues = {
        ...values,
        skippedDocs: values.skippedDocs,
        question2: !!values?.question2 ? values.question2 : "Yes"
      }
      setFieldValue("question2", !!values?.question2 ? values.question2 : "")
      setFieldValue("isReportPrame", !!values?.question2 ? values.question2 : "")
      isSectionValid(undefined, updatedValues)
    } else {
      setQuestions([
        {
          label: 'Has the tissue in this sample been exposed to radiation?',
          apiValue: 'isExposedToRadiation',
        },
        {
          label: 'Add-On Testing: DecisionDx-Prame',
          apiValue: 'isReportPrame',
        }
      ])
    }
    if (selectedTest?.checkedIndexes?.includes(4)) {
      let updatedValues = {
        ...values,
        skippedDocs: values.skippedDocs,
        question2: !!values?.question2 ? values.question2 : "Yes"
      }
      setFieldValue("question2", !!values?.question2 ? values.question2 : "Yes")
      setFieldValue("isReportPrame", !!values?.question2 ? values.question2 : "Yes")
      isSectionValid(undefined, updatedValues)
    } else
      isSectionValid(undefined, values)
  }, [selectedTest?.checkedIndexes?.length])

  function isSectionValid(inputValue: any, values: any): any {
    let dataMissing = true
    if (selectedTest?.checkedIndexes?.length == 1 && selectedTest?.checkedIndexes[0] === 7) {
      dataMissing = !!values?.question1 === false ? true : false
    } else {
      dataMissing = questions.some((question: any, index: number) => values[`question${index + 1}`] == '' || values[`question${index + 1}`] == undefined)
    }

    if (dataMissing) {
      dispatch(ButtonStateActions.toggleSumbit(false))
      dispatch(toggleClinicalInfoValid(false))
      setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'))

      return setSectionValid(false)
    }

    if (inputValue !== undefined) {
      dispatch(toggleClinicalInfoValid(inputValue !== '' ?? false))
      return setSectionValid(inputValue !== '' ?? false)
    }

    for (let i = 0; i < questions.length; i++) {
      if (values[`question${i + 1}`] === 'yes' && questions[i].extraField) {
        if (values[questions[i].extraField.name] === '') {
          setSectionValid(false)
          dispatch(ButtonStateActions.toggleSumbit(false))
          dispatch(toggleClinicalInfoValid(false))
          setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'clinical info'))
        }
        break
      } else {
        setSectionValid(true)
        dispatch(toggleClinicalInfoValid(true))
        dispatch(ButtonStateActions.toggleSumbit(isValid))
        setValidSections((prevState: any) => [...prevState, 'clinical info'])
      }
    }
  }

  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, firstScroll, 'clinical info')
  }

  function handleBlur() {
    rest.setTouchedSections((prevState: string[]) => [...prevState, 'clinical info'])
  }
  function RenderQuestions() {

    return <Fragment>
      {questions.map((question: any, index: number) => <QuestionCard
        key={index + 1}
        question={question}
        index={index}
        errors={errors}
        touched={touched}
        hideThirdOption={question.apiValue === 'isReportPrame'}
        setFieldValue={setFieldValue}
        setSectionValid={setSectionValid}
        isSectionValid={isSectionValid}
        onBlurCapture={handleBlur}
        values={values}
      // handleInView={handleInView}
      />)}
    </Fragment>
  }

  return <InView
    id='clinical info'
    className={selectedTab === 'select test' ? `scroll-my-64` : 'scroll-my-32'}
    as="div" threshold={0.5} onChange={handleMouseScroll}>

    <div className={`pb-14 flex flex-col`}>
      {RenderQuestions()}
      <Button
        btnType="primary"
        title={'Ok'}
        disabled={!sectionValid}
        className={'self-end'}
        onClick={handleOk} />

    </div>
  </InView>
}

function ClinicalInfoSection({ initialValues, values, setFieldValue, errors, touched, setFieldError, isValid, setValidSections, setSelectedTab, selectedTab, firstScroll, isUpdate, ...rest }: any): any {
  const { selectedTest } = useSelector((state: any) => state.OnlineOrderForm)
  const dispatch = useDispatch()
  function handleOk(): void {
    setSelectedTab('additional info')
    const section: any = document.getElementById('additional info')
    section.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
  function enableSubmit(): void {
    dispatch(toggleSumbit(true))
  }
  function disableSubmit(): void {
    dispatch(toggleSumbit(false))
  }

  if (selectedTest.id === 3) {
    return <DXMelanomaForm
      errors={errors}
      handleOk={handleOk}
      touched={touched}
      values={values}
      selectedTab={selectedTab}
      firstScroll={firstScroll}
      isValid={isValid}
      enableSubmit={enableSubmit}
      disableSubmit={disableSubmit}
      setValidSections={setValidSections}
      setFieldValue={setFieldValue}
      setSelectedTab={setSelectedTab}
      {...rest}
    />
  }

  if (selectedTest.id === 2) {
    return <DXSccForm
      errors={errors}
      handleOk={handleOk}
      isValid={isValid}
      touched={touched}
      selectedTab={selectedTab}
      values={values}
      firstScroll={firstScroll}
      enableSubmit={enableSubmit}
      disableSubmit={disableSubmit}
      setValidSections={setValidSections}
      setFieldValue={setFieldValue}
      setSelectedTab={setSelectedTab}
      {...rest}
    />
  }
  if (selectedTest.id === 8) {
    return <IDGForm
      errors={errors}
      handleOk={handleOk}
      isValid={isValid}
      touched={touched}
      selectedTab={selectedTab}
      values={values}
      firstScroll={firstScroll}
      enableSubmit={enableSubmit}
      disableSubmit={disableSubmit}
      setValidSections={setValidSections}
      setFieldValue={setFieldValue}
      setSelectedTab={setSelectedTab}
      {...rest}
    />
  }
  if (selectedTest.id === 5) {
    return <DiffDxForm
      errors={errors}
      handleOk={handleOk}
      isValid={isValid}
      touched={touched}
      firstScroll={firstScroll}
      values={values}
      selectedTab={selectedTab}
      isUpdate={isUpdate}
      enableSubmit={enableSubmit}
      disableSubmit={disableSubmit}
      setValidSections={setValidSections}
      setFieldValue={setFieldValue}
      setSelectedTab={setSelectedTab}
      {...rest}
    />
  }

  if (selectedTest.id === 4) {
    return <UMUMSeq
      errors={errors}
      handleOk={handleOk}
      isValid={isValid}
      touched={touched}
      values={values}
      isUpdate={isUpdate}
      selectedTab={selectedTab}
      firstScroll={firstScroll}
      enableSubmit={enableSubmit}
      disableSubmit={disableSubmit}
      setValidSections={setValidSections}
      setFieldValue={setFieldValue}
      setSelectedTab={setSelectedTab}
      {...rest}
    />
  }
  return null
}

export default ClinicalInfoSection
