import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import DropDownIcon from "../../assets/svg/DropdownIcon";
import { AnimatePresence, motion } from "framer-motion";
import DropDownItem from "../molecular/DropDownItem";
import CustomImage from "./CustomImage";
import ArrowUp from "../../assets/svg/ArrowUp";
import Button from "../atomic/Button";
import { useSelector } from "react-redux";
import RefreshIcon from "../../assets/svg/RefreshIcon";
import ReactTooltip from "react-tooltip";
import TooltipHelper from "./TooltipHelper";
import './styles.css'

function Dropdown({
  text,
  data,
  handleChange,
  selectedValue,
  selectedImage,
  disabled = false,
  style,
  loadMoreOrgs,
  loadMorePhysicians,
  stylingPhysician,
  checkOrganizationNull,
  isOrg,
  disable,
  showTooltip,
  tooltipText,
}: any): JSX.Element {
  const dropDownRef = useRef<any>();
  const [visibility, setVisiblity] = useState<boolean>(false);

  // const [selectedValue, setSelectedValue] = useState<string>('');
  const handleVisibility = useCallback(() => {
    setVisiblity((state: boolean) => !state);
  }, []);
  const { selectedPhysician, selectedOrganization } = useSelector(
    (state: any) => state.OnlineOrderForm
    );

  const { profile } = useSelector((state: any) => state?.Profile)
  const isCOA = profile?.roles?.includes('Collaborative Order Agreement')
  const checkVisibilityAndOrganizations = () => {
    handleVisibility();
    checkOrganizationNull();
  };

  function handleClick(value: string) {
    return () => {
      handleChange(value);
      setVisiblity(false);
    };
  }

  function renderDropdown() {
    if (data && data.length > 0) {
      return (
        <Fragment>
          {data?.map((value: any, index: any, image: any) => (
            <DropDownItem
              margin={selectedValue == "" ?? ""}
              onClick={handleClick(value)}
              key={index}
              isOrg={isOrg}
              value={value}
              image={image}
              disable={disable}
            />
          ))}

          {selectedOrganization?.users?.length > 0 ? loadMorePhysicians && (
            <div onClick={loadMorePhysicians} className='flex justify-center cursor-pointer hover:text-[#78dbdc]'>
              <Button
                btnType="transparent"
                title="REFRESH LIST"
                onClick={loadMorePhysicians}
                className="inline-block py-2 ml-2 hover:text-[#78dbdc]"
                refreshicon
              />
            </div>) : ''
          }
          {loadMoreOrgs && <div onClick={loadMoreOrgs} className='flex justify-center cursor-pointer'>
            <Button
              btnType="transparent"
              title="REFRESH LIST"
              onClick={loadMoreOrgs}
              className="inline-block py-2 hover:text-[#78dbdc]"
              refreshicon
            />
          </div>}

        </Fragment>
      );
    }
  }
  useEffect(() => {
    const unsubscribe = window.addEventListener("mousedown", (event) => {
      if (dropDownRef?.current && !dropDownRef?.current?.contains(event.target))
        return setVisiblity(false);
    });

    return () => unsubscribe;
  }, []);
  return (
    <AnimatePresence exitBeforeEnter>
      <div
        data-for="physicianDropDown"
        data-tip={tooltipText}
        data-iscapture="true"
        ref={dropDownRef}
        className={`relative ${((profile?.roles?.includes('Physician') || (profile?.roles?.includes('PathLab User')) && !isCOA) && profile?.roles?.length == 1) ? 'mx-11' : 'mx-3'} z-50`}
      >
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={selectedValue ?? "empty"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <button
              onClick={checkVisibilityAndOrganizations}
              type="button"
              className={`flex ${isCOA && 'tab:text-ellipsis tab:py-8 tab:h-[50px] tab:flex tab:justify-center tab:items-center'} justify-between overflow-hidden items-center w-full text-xs rounded-md border border-primary ${disable ? 'cursor-not-allowed' : ''} ${stylingPhysician} hover:bg-[#CBEEEF]  ${visibility ? "bg-[#CBEEEF]" : ""
                }`}
              disabled={disable}
            >
              {selectedValue == null ? (
                <p className={`p-1 px-3 text-royalBlue`}>{text}</p>
              ) : (
                <div className={`p-1 px-3 flex justify-between items-center`}>
                  {selectedImage != null ? (
                    <CustomImage
                      src={selectedImage}
                      className="h-3 w-5 mr-3 object-fit"
                      alt={""}
                    />
                  ) : (
                    ""
                  )}
                  <p className={`px-3 text-royalBlue}`}>{(window?.innerHeight === 1024 && window?.innerWidth === 768 && isCOA ? (selectedValue?.length > 20 ? selectedValue?.slice(0, 20) + ' ...' : selectedValue):selectedValue)}</p>
                </div>
              )}
              <div className={`${isCOA && 'tab:pr-[0.2px]'}`}>
              {disabled == true ? (
                <DropDownIcon height={8} width={25} />
              ) : visibility ? (
                <ArrowUp height={8} width={25} />
              ) : (
                <DropDownIcon height={8} width={25} />
              )}
              </div>
            </button>
          </motion.div>
        </AnimatePresence>

        {visibility && !disabled && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={style}
            className={`absolute shadow-md z-96 border rounded-md bg-white break-normal overflow-y-auto ${isCOA ? 'tab:top-[65px]':'top-7'}`}
          >
            <ul >{renderDropdown()}</ul>
          </motion.div>
        )}
        {showTooltip && !!tooltipText && <TooltipHelper
          place={'top'}
          id={"physicianDropDown"}
          // type={'dark'}
          backgroundColor={'#18B5B7'}
          className={'!text-xs !p-2 max-w-[300px]'}
          effect={'float'}
          scrollHide={true}
          multiline={true}
        />}
      </div>
    </AnimatePresence>
  );
}

export default Dropdown;
