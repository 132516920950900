import reduxActionType from '../../models/reduxActionType'
import {
  FETCHING_SPECIALITY_DATA,
  FETCHING_SPECIALITY_ERROR,
  FETCHING_SPECIALITY_SUCCESS,
  LOGOUT
} from '../Actions/types'

const initialState = {
  fetchingSpeciality: false,
  errorSpeciality: false,
  speciality: []
}

function SpecialityReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case FETCHING_SPECIALITY_DATA:
      return {
        ...state,
        fetchingSpeciality: true,
        errorSpeciality: false
      }
    case FETCHING_SPECIALITY_SUCCESS: {
      return {
        ...state,
        fetchingSpeciality: false,
        errorSpeciality: false,
        speciality: [...action.payload]
      }
    }
    case FETCHING_SPECIALITY_ERROR: {
      return {
        ...state,
        fetchingSpeciality: false,
        errorSpeciality: true
      }
    }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
export default SpecialityReducer
