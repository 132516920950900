import { motion } from "framer-motion";
import OrganizationSignUpForm from "../../components/organism/OrganizationSignUpForm";
import LogoSVG from "../../assets/svg/Logo";
import Button from "../../components/atomic/Button";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ManagerSignUpForm from "../../components/organism/ManagerSignUpForm";
import DropDownIcon from "../../assets/svg/DropdownIcon";
import { connect, useDispatch } from "react-redux";
import SignUpFormActions from "../../Store/Actions/SignUpFormActions";
import StateAndCityActions from "../../Store/Actions/StateAndCityActions";
import { useHistory } from "react-router-dom";
import toastMessages from "../../utils/helpers/toastMessage";
import RoleSelection from "../../components/organism/RoleSelection";
import PhysicianSignUpForm from "../../components/organism/PhysicianSignUpForm";

function RoleSelectionStep({ setFormValues, formValues, handleNext }: any): JSX.Element {
  function handleChange(e: any) {
    const { name, checked, value } = e.target;
    setFormValues({ ...formValues, [name]: value })

  }

  return <div className="flex flex-col justify-around w-full h-full">
    <div className="">
      <RoleSelection
        id="Office Manager"
        label="Office Manager"
        value="Office Manager"
        checked={formValues?.role === 'Office Manager'}
        onChange={handleChange}
        name="role"
        description="Has the capability to order and review results on behalf of associated clinicians."
      />
      <RoleSelection
        id="Physician"
        label="Clinician"
        value="Physician"
        checked={formValues?.role === 'Physician'}
        onChange={handleChange}
        name="role"
        description="Can authorize orders and view results for their patients at all locations within the associated organizations."
      />
      <RoleSelection
        id="Both"
        label="Both"
        value="Both"
        checked={formValues?.role === 'Both'}
        onChange={handleChange}
        name="role"
        description="Clinicians who order for themselves and view results for other clinician’s patients within the associated organizations."
      />
    </div>
    <div className="flex flex-col justify-around flex-1 w-full mt-5 mb-3">
      <Button
        type={'button'}
        btnType='primary'
        title={'Next'}
        disabled={!!formValues.role === false}
        className={`w-full `}
        onClick={handleNext}
      />
      <div className="flex items-center justify-center mt-4">
        <div className={'h-3 w-3 rounded-full bg-primaryDisabled mr-1'} />
        <div className={'h-3 w-3 rounded-full bg-primary mr-1'} />
        <div className={'h-3 w-3 rounded-full bg-primaryDisabled mr-1'} />
      </div>
    </div>
  </div>
}

function SignUpPage({ setFormValues, getStateAndCity, formValues }: any) {
  const [pageNo, setPageNo] = useState(1);

  const history = useHistory()
  const dispatch = useDispatch();
  useEffect(() => {
    getStateAndCity({
      onError: () => {
        // alert("error occured");
      },
    });

  }, []);

  function handleNextForPhysician(data: any) {
    dispatch(SignUpFormActions.updateFormValues(data));
    setPageNo(3);
  }

  function handleNext(data: any) {
    // setFormValues(data)
    dispatch(SignUpFormActions.updateFormValues(data));
    setPageNo(2);
  }
  const [isLoading, setLoading] = useState(false); // Add a loading state variable

  const handleSubmit = async (data: any, formRef: any) => {

    try {
      setLoading(true);
      // const requestData = { email: data?.email_id };
      // const response = await http.validateEmail('/v1/users/validate-email',
      //   requestData
      // );
      // if (response.status === true) {
      handleNextForPhysician(data);
      // }
    } catch (error: any) {
      // data.email_id = ''
      if (formRef.current) {
        const { setFieldValue, setFieldError } = formRef.current
        setFieldError('email_id', error?.response?.data?.message ? error?.response?.data?.message : 'Email verification failed')
      }
      toastMessages.error(error?.response?.data?.message)
    } finally {
      setLoading(false);
    }
  };


  function onLogoClick() {
    history.push('/')
    dispatch(SignUpFormActions.resetForm())
  }
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={"flex h-screen"}
    >
      <section className="w-2/6 lg:w-3/6 bg-gradient-to-b from-[#18B5B7] to-[#084E8B] border border-darkGray" />
      <section className="flex flex-col w-4/6 lg:w-3/6 justify-center items-center">
        <div className="w-full lg:w-4/6 flex flex-col overflow-hidden px-10">
          <div className="my-4">
            <LogoSVG height={38} width={112} className={"my-2 cursor-pointer"} onClick={onLogoClick} />
            {pageNo == 1 ? (
              <h3 className="mt-5 text-2xl font-bold text-royalBlue text-left mb-2">
                Sign Up
              </h3>
            ) : (
              <div className="flex justify-start items-center">
                <button
                  onClick={() => { pageNo === 2 ? setPageNo(1) : setPageNo(2) }}
                  type="button"
                  className={"rotate-90 -ml-3 mt-4 mr-3"}
                >
                  <DropDownIcon height={20} width={25} />
                </button>
                <h3 className="text-2xl font-bold text-royalBlue text-left mt-2">
                  Sign Up
                </h3>
              </div>
            )}

            {pageNo == 1 ? (
              <h5 className="text-base font-normal text-royalBlue text-left">
                Please enter organization details
              </h5>
            ) : pageNo == 2 ? (
              <h5 className="text-base font-normal text-royalBlue text-left mt-3">
                Please select user role
                {/*  Please enter clinician details */}
              </h5>
            ) : <h5 className="text-base font-normal text-royalBlue text-left mt-3">
              {/* *Optional: Enter office manager details to manage orders and results on behalf of the clinician. Click "SIGN UP" to skip. */}
            </h5>}
          </div>
          {pageNo == 1 ? (
            <OrganizationSignUpForm handleNext={handleNext} isPage />
          ) : pageNo == 2 ? (
            <RoleSelectionStep
              setFormValues={setFormValues}
              formValues={formValues}
              handleNext={() => setPageNo(3)}
            />

          ) :
            <Fragment>
              {(formValues?.role === 'Both' || formValues?.role === 'Physician') ? <PhysicianSignUpForm
                handleNext={handleSubmit}
                loading={isLoading}
                signUp={undefined}
                updateUser={undefined}
                isCompletion={false}
              /> : <ManagerSignUpForm
                signUp={undefined}
                updateUser={undefined}
                isCompletion={false}
              />}

            </Fragment>

          }
        </div>
      </section>
    </motion.section>
  );
}

const mapStateToProps = (state: any) => ({
  formValues: state.SignUpFormValues,
});
export default connect(mapStateToProps, {
  setFormValues: SignUpFormActions.updateFormValues,
  getStateAndCity: StateAndCityActions.fetchStateAndCity,
})(SignUpPage);
