/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useEffect } from 'react'
import { Route, Switch, useHistory, withRouter } from 'react-router-dom'
import MainLayout from '../Containers/MainLayout'
import routes from '../routes'
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import OktaConfig from '../config/oktaConfig'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LandingPage from '../Pages/Auth/LandingPage'
import LoginProcess from '../Pages/Auth/LoginProcess'
import { useDispatch, useSelector } from 'react-redux'
import { LOGOUT, } from '../Store/Actions/types'
import useSocket from '../utils/customHooks/useSocket'
import DownloadStorage from '../Pages/DownloadStorage'


function AppRoutes(): JSX.Element {
  const dispatch = useDispatch()
  const oktaAuth = new OktaAuth(OktaConfig.oktaAuthConfig)
  const history = useHistory()

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any): Promise<any> => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect()
  }

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState()
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin()
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      // setAuthRequiredModalOpen(true);
      // history.replace('/')
      oktaAuth.signOut({clearTokensBeforeRedirect:true});
      dispatch({ type: LOGOUT })
    }
  }
  const socket = useSocket(oktaAuth?.getAccessToken())

  return <Security
    oktaAuth={oktaAuth}
    onAuthRequired={customAuthHandler}
    restoreOriginalUri={restoreOriginalUri}
  >
    <Switch>
      <Route path={'/'} exact={true} component={() => <MainLayout
        pageTitle="Home | CSTL"
        auth={false}
        socket={socket}
        template='normal'
        templateClassName='flex flex-col h-screen overflow-hidden'
        Element={<LandingPage />} />} />

      <Route path={'/login/callback'} component={LoginCallback} />
      <Route path={'/login-process'} component={LoginProcess} />
      <Route path={'/jpBN'} component={DownloadStorage} />
      {routes.map(route => {
        if (route.auth) {
          return <SecureRoute
            key={route.path}
            exact
            path={route.path}
            render={() => <MainLayout
              pageTitle={route.title}
              socket={socket}
              auth={route.auth}
              template={route.template}
              templateClassName={route.templateClassName}
              childContainerClassName={route.childContainerClassName}
              Element={route.element} />
            }
          />
        }
        return <Route
          key={route.path}
          exact
          path={route.path}
          render={() => <MainLayout
            pageTitle={route.title}
            socket={socket}
            auth={route.auth}
            template={route.template}
            templateClassName={route.templateClassName}
            childContainerClassName={route.childContainerClassName}
            Element={route.element} />
          }
        />
      })}
    </Switch>

    <ToastContainer />
  </Security>
}

export default withRouter(AppRoutes)
