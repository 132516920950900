import HelpScreenLayout from "../../Containers/HelpScreenLayout";
import HelpScreen from "./HelpScreen";
import { Form } from "formik";
import { useEffect , useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import ButtonStateActions, {
  setButtonState,
} from "../../Store/Actions/ButtonStateActions";

function HelpSection({ headerTitle }: any) {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState<string>("documents");
  function handleTab(tab: string): void {
    setSelectedTab(tab);
  }
  useEffect(() => {
    dispatch(setButtonState("help screen"));
  }, []);

  return (
    <HelpScreenLayout 
    headerTitle={headerTitle}   
    selectedTab={selectedTab}
    setSelectedTab={handleTab}>
      <div className="col-span-4 flex flex-col w-full overflow-auto">
        <HelpScreen selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
      </div>
    </HelpScreenLayout>
  );
}

const mapStateToProps = (state: any) => ({ screen: state.ButtonState.screen });
const mapDispatchToProps = {
  setButtonState: ButtonStateActions.setButtonState,
};
export default connect(mapStateToProps, mapDispatchToProps)(HelpSection);
