import reduxActionType from '../../models/reduxActionType'
import physicianToClinician from '../../utils/helpers/physicianToClinician'
import {
  FETCH_PORTAL_USER_OA_SUCCESS,
  FETCH_PROFILE,
  FETCH_PROFILE_ERROR,
  FETCH_PROFILE_OA,
  FETCH_PROFILE_OA_ERROR,
  FETCH_PROFILE_OA_SUCCESS,
  FETCH_PROFILE_SUCCESS,
  LOGOUT,
  UPDATE_LOGGEDIN_USER,
  UPDATE_LOGGEDIN_USER_ERROR,
  UPDATE_LOGGEDIN_USER_SUCCESS
} from '../Actions/types'

const initialState = {
  fetching: false,
  error: false,
  tableData: [],
  orderAgreementTable: [],
  profile: {},
  updating: false,
  updateError: false,
  fetchingOA: false,
  fetchingOAError: false
}

function ProfileReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case FETCH_PROFILE:
      return {
        ...state,
        fetching: true,
        error: false
      }
    // case FETCH_PROFILE_SUCCESS:
    //   return {
    //     ...state,
    //     fetching: false,
    //     error: false,
    //     tableData: action?.payload?.organizations && action?.payload?.organizations?.length > 0 ? action?.payload?.organizations?.map((organisation: any) => ({
    //       id: 1,
    //       onRowClick: (id: number, navigate: any) => {
    //         // console.log(navigate)
    //         // navigate('/dashboard', { replace: true })
    //         // navigate.push(`/manage/portal-users/profile/${id}`)
    //       },
    //       data: {
    //         organisation: organisation?.name,
    //         // roles: JSON.parse(action.payload.organizationRoles)[organisation.id].roles.map((item: any) => `${item},`)
    //         roles: action?.payload?.organizationRoles && JSON?.parse(action?.payload?.organizationRoles)[organisation?.id]?.roles?.length > 0 ? JSON.parse(action?.payload?.organizationRoles)[organisation?.id]?.roles.map((item: any, index: any) => `${item}${(JSON.parse(action?.payload?.organizationRoles)[organisation?.id]?.roles?.length - 1) == index ? '' : ', '}`) : ['N/A']

    //       },
    //       tooltips: [{
    //         key: 'Organisation',
    //         value: `${organisation?.address ? `${organisation.address},` : ''} 
    //           ${organisation?.state ? ` ${organisation.state},` : ''} 
    //           ${organisation?.city || ''}`.trim() || 'N/A'
    //       }],

    //       check: false

    //     })) : [],
    //     profile: {
    //       ...action.payload,
    //       organizationRoles: action?.payload?.organizationRoles ? JSON?.parse(action?.payload?.organizationRoles) : ['N/A'],
    //       // roles: ["Physician"]
    //       // roles: ["Office Manager", "Physician"]
    //     }
    //   }
    // Uncomment this one once SOF changes are deployed
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: false,
        sofTable: action?.payload?.user?.sofs && action?.payload?.user?.sofs?.length > 0 ? action.payload.user.sofs.map((sof: any) => ({
          id: sof.id,
          onRowClick: (id: number, navigate: any) => { },
          data: {
            'Test Form': sof.testForm ? sof.testForm : 'N/A',
            status: sof.status,
            SOF: sof
          }
        })) : [],
        tableData: action?.payload?.organizationsRolesAndSOF && action?.payload?.organizationsRolesAndSOF?.length > 0 ? action?.payload?.organizationsRolesAndSOF?.map((organisation: any) => ({
          id: organisation.id,
          onRowClick: (id: number, navigate: any) => {
            // console.log(navigate)
            // navigate('/dashboard', { replace: true })
            // navigate.push(`/manage/portal-users/profile/${id}`)
          },
          data: {
            organisation: organisation.name,
            roles: physicianToClinician(organisation.roles),
            SOF: organisation.SOF ? organisation.SOF : 'N/A'
          },
          check: false
        })) : [],

        profile: {
          ...action?.payload?.user,
          organizationRoles: action?.payload?.user?.organizationRoles ? JSON.parse(action?.payload?.user?.organizationRoles) : 'N/A',
          // roles: ['PathLab User', 'Physician']
        }
      }
    case FETCH_PROFILE_ERROR:
      return {
        ...state,
        fetching: false,
        error: true
      }
    case UPDATE_LOGGEDIN_USER: {
      return {
        ...state,
        updating: true,
        updateError: false
      }
    }
    case UPDATE_LOGGEDIN_USER_ERROR: {
      return {
        ...state,
        updating: false,
        updateError: true
      }
    }
    case UPDATE_LOGGEDIN_USER_SUCCESS: {
      return {
        ...state,
        updating: false,
        updateError: false
      }
    }
    case FETCH_PROFILE_OA: {
      return {
        ...state,
        fetchingOA: true,
        fetchingOAError: false
      }
    }
    case FETCH_PROFILE_OA_ERROR: {
      return {
        ...state,
        fetchingOA: false,
        fetchingOAError: true
      }
    }
    case FETCH_PROFILE_OA_SUCCESS: {
      return {
        ...state,
        fetchingOA: false,
        fetchingOAError: false,
        orderAgreementTable: action.payload && action.payload?.length > 0 ? action.payload?.map((item: any) => ({
          id: item.pathLabId,
          onRowClick: (id: number, navigate: any) => {
          },
          data: {
            organisation: item?.pathLab?.name,
            OA: {
              id: item?.id,
              blobUrl: item?.blobUrl,
              testForm: item?.testForm,
              expiryDate: item?.expiryDate,
              userId: item?.userId
            }
          },
          check: false
        })) : []
      }
    }
    case LOGOUT: {
      return initialState
    }
    default:
      return state
  }
}

export default ProfileReducer
