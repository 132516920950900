import { Link, NavLink } from 'react-router-dom'
import NoImageIcon from '../../assets/svg/NoImageIcon'
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import TooltipHelper from '../global/TooltipHelper';
interface OrganizationListItemProps {
  className?: string
  activeClassName: any
  profile: any
  organizationStatus: any
  id: string | number
  isPathLab?: boolean
  onClick: () => any
}

function OrganizationListItem({ className, activeClassName, onClick, id, profile, organizationStatus, isPathLab }: OrganizationListItemProps) {
  const { data: states, error } = useSelector((state: any) => state.StateAndCity)


  return <NavLink
    to={`/manage/${isPathLab ? 'pathlabs' : 'organizations'}/${id}`} /* type="button" */
    // replace
    onClick={onClick}
    activeClassName="border-primary"
    className={`shadow flex justify-between border rounded-lg p-4 w-full hover:border hover:border-borderGray hover:shadow my-3 ${className ?? ''}`}
    data-for={id}
    data-tip={organizationStatus?.status === 'Pending' ? 'Pending Approval' : ''}
  // className={(activeClassName) => `shadow flex overflow-hidden justify-between border rounded-lg p-4 w-full ${activeClassName ? 'border-primary' : ''} hover:border hover:border-borderGray hover:shadow my-3 ${className ?? ''}`}
  >

    {profile?.logoUrl
      ? <div className="w-2/6 xl:w-1/6" >
        <img
          className="h-5 w-5 mt-1 object-center"
          src={profile?.logoUrl}
        />
      </div>
      : <div className="w-2/6 xl:w-1/6 " ><NoImageIcon /></div>}
    <div className="w-4/6 xl:w-5/6 flex flex-col"  >
      <div>
        <p className="text-xs xl:text-sm text-royalBlue break-normal">{profile?.name}</p>
        <div className='flex flex-wrap pt-1 '>
          <p className="text-xs text-[#949494] break-normal truncate">{profile?.address}</p>
          <p className="text-xs text-[#949494] break-normal ">{profile?.city ? ` ,\u00A0${profile?.city}` : ''}</p>
          {/* <p className="text-xs text-[#949494] break-normal ">{profile?.state ? ` ,${profile?.state}` : ''}</p> */}
          <p className="text-xs text-[#949494] break-normal ">{profile?.state ? `, ${profile?.state ? states && states?.length > 0 && states?.find((state: any) => `${state?.name} ${state?.state_code}`.includes(profile?.state))?.name : profile?.state}` : ''}</p>

        </div>
      </div>
      <p className={` text-xs break-normal mt-3 -mb-2 -mr-2 justify-self-end text-right ${organizationStatus?.status === 'Active' ? 'text-[#1BC472]' : 'text-[#FE4F4F]'}`}>{organizationStatus?.status}</p>
    </div>
    <TooltipHelper
      id={`${id}`}
      backgroundColor={'#18B5B7'}
      className={'!text-xs !p-2 '}
    />
  </NavLink>
}

export default OrganizationListItem
