/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import { useOktaAuth } from '@okta/okta-react'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { toggleSumbit } from '../../../Store/Actions/ButtonStateActions'
import validationHelper from '../../../utils/helpers/orderFormValidationHelper'
import PatientInfoFormLoadingIndicator from '../../global/LoadingIndicators/CreateOnlineOrderIndicators/PatientInfoLoadingIndicator'
import ContactInfoSection from './Sections/ContactInfoSection'
import { InView } from 'react-intersection-observer'
import PersonalInfoSection, { PatientKeys } from './Sections/PersonalInfoSection'
import SecuritySection from './Sections/SecuritySection'
import scrollHelper from '../../../utils/helpers/scrollHelper'

function PatientInfoForm({ initialValues, values, setFieldValue, errors, touched, patientType, setFieldError, validateForm, setValidSections, fetchPatients, patients, loading, error, setSelectedTab, selectedTab, setPatientType, isUpdate, ...rest }: any): JSX.Element {
  const dispatch = useDispatch()
  const { selectedOrganization, selectedTest } = useSelector((state: any) => state.OnlineOrderForm)
  function enableSubmit(): void {
    dispatch(toggleSumbit(true))
  }
  function disableSubmit(): void {
    dispatch(toggleSumbit(false))
  }

  const TotalFields = {
    patient_ssn: 'ssn',
    patientId: 'patientId',
    patient_mrn: 'mrn',
    patient_email: 'patient_email',
    patient_address: 'patient_address',
    patient_city: 'city',
    patient_state: 'state',
    patient_phone: 'phone',
    patient_zip_code: 'zip_code',
    patient_firstname: 'patient_firstname',
    patient_middleinitial: 'patient_middleinitial',
    patient_lastname: 'patient_lastname',
    patient_dob: 'patient_dob',
    patient_gender: 'patient_gender'
  }

  useEffect(() => {
    validationHelper(validateForm, setValidSections, values, TotalFields, 'patient info', enableSubmit, disableSubmit)
  }, [selectedOrganization?.id, selectedTest?.id])

  // if (loading === true) {
  //   return <PatientInfoFormLoadingIndicator />
  // }

  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, rest.firstScroll, 'patient info')
    if (!inView && rest.touchedSections.includes('patient info'))
      Object.keys(PatientKeys).forEach((key) => {
        rest.setFieldTouched(PatientKeys[key], true, true)
      })
  }

  return <AnimatePresence exitBeforeEnter>
    <InView
      threshold={window.innerWidth <= 1280 ? 0.3 : 0.5}
      as="div"
      id='patient info'
      className={selectedTab === 'select test' ? 'scroll-my-64 tab:scroll-my-72' : 'scroll-my-36'}
      onChange={handleMouseScroll}>
      <motion.div
        key={patientType}
        initial={{ opacity: 0, x: 1000 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 1000 }}
        transition={{ duration: 0.2 }}
        className="pb-14">

        <PersonalInfoSection
          patientType={patientType}
          values={values}
          InputFields={TotalFields}
          isUpdate={isUpdate}
          validateForm={validateForm}
          enableSubmit={enableSubmit}
          disableSubmit={disableSubmit}
          setValidSections={setValidSections}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          errors={errors}
          touched={touched}
          fetchPatients={() => { }}
          patients={[]}
          loading={false}
          error={undefined}
          rest={rest}
          setSelectedTab={setSelectedTab} />
        {selectedTest?.id === 4 && <SecuritySection
          values={values}
          setFieldValue={setFieldValue}
          validateForm={validateForm}
          setValidSections={setValidSections}
          setSelectedTab={setSelectedTab}
          enableSubmit={enableSubmit}
          disableSubmit={disableSubmit}
          InputFields={TotalFields}
          errors={errors}
          touched={touched}
          {...rest}
        />}
        <ContactInfoSection
          values={values}
          setFieldValue={setFieldValue}
          validateForm={validateForm}
          enableSubmit={enableSubmit}
          disableSubmit={disableSubmit}
          setSelectedTab={setSelectedTab}
          setValidSections={setValidSections}
          InputFields={TotalFields}
          errors={errors}
          touched={touched}
          {...rest}
        />
      </motion.div>
    </InView>
  </AnimatePresence >
}
const mapStateToProps = (state: any): any => ({
  loading: state.OnlineOrderForm.fetchingPatients,
  error: state.OnlineOrderForm.fetchingPatientsError
})

export default connect(mapStateToProps, null)(PatientInfoForm)
