import moment from "moment";
import OrderFormValues from "../../models/forms/onlineorderformvalues";
import { removeExtraSpace } from "./removeExtraSpace";

function orderFormPayloadCreator(
 data: OrderFormValues,
 selectedOrganization: number | string,
 selectedPhysician: number | string
) {
 let isAdditionalClinician: null | number = null;
 let additionalCodes: any = [];

 if (data?.icdCodeId && data?.additionalCodes) {
  additionalCodes = data?.additionalCodes.filter(
   (code: any) => code != data?.icdCodeId
  );
 }

 let secondaryTestId = data?.selectedTest?.checkedIndexes.find(
  (id: number) => id !== data.selectedTest.id
 );
 //  data.additionalClinician = data.additionalClinician
 //   .filter((clinician) => !!clinician?.id === false)
 //   .map((clinician) => {
 //    const { status, physician, ...rest } = clinician;
 //    return {
 //     ...rest,
 //     firstName: removeExtraSpace(clinician.firstName),
 //     lastName: removeExtraSpace(clinician.lastName),
 //    };
 //   });

 const filteredClinicians = data.additionalClinician
  .filter((clinician) => !!clinician?.id === false)
  .map((clinician) => {
   const { status, physician, ...rest } = clinician;
   return {
    ...rest,
    firstName: removeExtraSpace(clinician.firstName),
    lastName: removeExtraSpace(clinician.lastName),
   };
  });

 if (data?.has_additional_clinician === true && filteredClinicians.length > 0) {
  isAdditionalClinician = 1;
 } else if (
  data?.has_additional_clinician === true &&
  filteredClinicians.length == 0
 ) {
  isAdditionalClinician = 0;
 } else if (data?.has_additional_clinician === false) {
  isAdditionalClinician = 0;
 }

 let payload = {
  currentDate: moment().format("MM-DD-YYYY"),
  isSave: data?.isSave ?? "false",
  tubeBarcode: data?.tubeBarcode ?? "",
  isTeleHealth: data?.isTeleHealth ?? false,
  isAdditionalClinician,
  organizationId: selectedOrganization.toString(),
  physicianId: selectedPhysician.toString(),
  //  testTypeId: `${data?.selectedTest?.id}`,
  //  secondaryTestId: secondaryTestId ? `${secondaryTestId}` : "",
  testTypeId:
   secondaryTestId && data?.selectedTest.checkedIndexes.length == 1
    ? `${secondaryTestId}`
    : `${data?.selectedTest?.id}`,
  secondaryTestId:
   secondaryTestId && data?.selectedTest.checkedIndexes.length > 1
    ? `${secondaryTestId}`
    : "",
  notes: data?.additional_notes ? removeExtraSpace(data.additional_notes) : "",
  isPhysicianSignPermission: data?.isPhysicianSignPermission
   ? data?.isPhysicianSignPermission
   : false,
  isSentinelLymphNodeBiopsy:
   data?.isSentinelLymphNodeBiopsy === "N/A"
    ? "Unknown"
    : data?.isSentinelLymphNodeBiopsy ?? "",
  isClinicallyPalpableNodes: data?.isClinicallyPalpableNodes ?? "",
  isExposedToRadiation:
   data.isExposedToRadiation === "N/A" ? null : data.isExposedToRadiation,
  isReportPrame:
   data.isReportPrame === "Yes"
    ? true
    : data.isReportPrame === "No"
    ? false
    : false,
  isIntrasitMetastasis: data?.isIntrasitMetastasis ?? "",
  // isMelanocyticLesionUncertain: data?.isMelanocyticLesionUncertain
  //  ? data?.isMelanocyticLesionUncertain
  //  : "",
  isPrimaryCutaneousMelanocyticNeoplasm:
   data?.isPrimaryCutaneousMelanocyticNeoplasm
    ? data?.isPrimaryCutaneousMelanocyticNeoplasm
    : "",
  // isSpitzoidFeatures: data.isSpitzoidFeatures,
  // isLesionJunctional: data.isLesionJunctional ? data.isLesionJunctional : "",
  //  isCastleStandardTesting: data?.isCastleStandardTesting,
  //  isDDMNotReported: data?.isDDMNotReported,
  //  isMyPathNotReported: data?.isMyPathNotReported,
  isAltheaDxNeuropsychotropicMedications:
   data?.selectedTest?.id === 8
    ? data?.isAltheaDxNeuropsychotropicMedications?.options ?? []
    : [],
  isIDgNeuropsychotropicMedicationsCurrent:
   data?.selectedTest?.id === 8
    ? data?.isIDgNeuropsychotropicMedicationsCurrent?.options ?? []
    : [],
  // isIDgNeuropsychotropicMedications:
  //  data?.selectedTest?.id === 8
  //   ? data?.isIDgNeuropsychotropicMedications?.options ?? []
  //   : [],
  isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement:
   data?.selectedTest?.id === 8
    ? data?.isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement?.options ??
      []
    : [],
  additionalCodes: additionalCodes,
  historyPhysicalExam:
   data?.selectedTest?.id === 2
    ? data?.history_physical_exam?.options
      ? data?.history_physical_exam?.options.filter(
         (item: any) => item !== "Other"
        )
      : []
    : [],
  otherHistoryPhysicalExam:
   data.other && data?.selectedTest?.id === 2 ? [`${data.other}`] : [],
  otherIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement:
   data?.other && data?.selectedTest?.id === 8 ? [`${data.other}`] : [],
  pathologySurgicalFindings:
   data?.selectedTest?.id === 2
    ? data?.pathology_surgical_findings?.options ?? []
    : [],
  lymphNodeStatus: data?.lymphNodeStatus ?? "",
  patientId: data?.patientId ?? "",
  patientInfo: {
   firstName: removeExtraSpace(data?.patient_firstname),
   middleInitial: removeExtraSpace(data?.patient_middleinitial),
   lastName: removeExtraSpace(data?.patient_lastname),
   birthDate: data?.patient_dob ? data.patient_dob : "",
   gender: data?.patient_gender ? data.patient_gender.toLowerCase() : "",
   securityInfo: {
    ssn: data?.patient_ssn,
    mrn: data?.patient_mrn,
   },
   email: data?.patient_email,
   phone: data?.patient_phone,
   address: removeExtraSpace(data?.patient_address),
   city: removeExtraSpace(data?.patient_city),
   state: removeExtraSpace(data?.patient_state),
   zipcode: data?.patient_zip_code,
  },
  billingInfo: {
   icdCodeId: data?.icdCodeId ? `${data.icdCodeId}` : "",
   primaryInsuranceId: data?.primaryInsuranceId
    ? `${data?.primaryInsuranceId}`
    : "",
   insuranceCompanyName:
    data?.insurance === "Enter new insurance"
     ? removeExtraSpace(data?.icn)
     : removeExtraSpace(data?.insurance),
   phone: data?.billing_phone,
   policyNumber: removeExtraSpace(data?.policy),
   typeOfFacility: data?.selectedTest?.id === 8 ? null : data?.medicare,
   dateOfDischarge:
    data?.medicare === "Hospital Inpatient"
     ? data?.date_of_discharge
       ? data.date_of_discharge
       : ""
     : "",
  },
  additionalClinician: filteredClinicians,
  isAttestation: data.attestation,
  isSupportingDocuments:
   data.skippedDocs === true
    ? 0
    : data.skippedDocs === false
    ? 1
    : data.skippedDocs,
  //  additionalInfo: {
  //   physicianId: additionalClinician,
  //  },

  laboratoryInfo: {
   labortaryId: !!data?.labortaryId ? `${data.labortaryId}` : "",
   labortaryName: removeExtraSpace(data.name_of_facility),
   dateOfCollection: data?.date_of_collection ? data.date_of_collection : "",
   FacilityContactPerson: removeExtraSpace(data?.facility_contact_person),
   labortaryPhone: data?.lab_phone,
   labortaryFax: data?.lab_fax,
   specimenId: removeExtraSpace(data?.specimenId),
   typeOfSpecimen: data?.typeofspecimen,
  },
  supportingDocuments: {
   pathologyReport: data?.pathology_report,
   insuranceCard: data?.insurance_cards,
   demographicsSheet: data?.patient_info_demographics,
   currentPrescriptionMedications: data?.current_prescription_medications,
   // signedLetter: data?.signed_letter,
  },
  noSOF: data?.noSOF ?? false,
  isExpiredSOF: data?.isExpiredSOF ?? false,
  sofMismatch: data?.sofMismatch ?? false,
  transitDocuments: data?.transitDocuments
   ? data.transitDocuments.map((item: any) => item.filePath)
   : [],
  pathologist: data.pathologist ? removeExtraSpace(data.pathologist) : "",
  // workingDiagnosis: data.workingDiagnosis
  //  ? removeExtraSpace(data.workingDiagnosis)
  //  : "",
  tumorSite: data.tumorSite ? removeExtraSpace(data?.tumorSite) : "",
  facilityType: data?.facilityType ? removeExtraSpace(data?.facilityType) : "",
  icd10CodeMismatch: data?.icd10CodeMismatch,
  medicationMismatch: data?.medicationMismatch,
  medicarePullDate: data?.medicarePullDate,
 };
 return payload;
}

export default orderFormPayloadCreator;
