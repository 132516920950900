import React from "react";

function SidebarLoadingIndicator(): JSX.Element {
  return (
    <div className="flex flex-col border rounded-sm border-b-0 bg-[#F9F9FA]">
      <div className="w-full flex flex-col mt-4 items-start ml-[29px]">
        <div className="flex flex-inline">
        <div className="h-[16px] w-[16px] my-2.5 bg-dropDownBorder rounded-lg animate-pulse mr-0.5" />
        <div className="h-1 w-[73px] py-1.5 my-3 bg-dropDownBorder rounded-lg animate-pulse" />
        </div>
        
        <div className="flex flex-inline">
        <div className="h-[16px] w-[16px] my-2.5 bg-dropDownBorder rounded-lg animate-pulse mr-0.5" />
        <div className="h-1 w-[73px] py-1.5 my-3 bg-dropDownBorder rounded-lg animate-pulse" />
        </div>
        
        <div className="flex flex-inline">
        <div className="h-[16px] w-[16px] my-2.5 bg-dropDownBorder rounded-lg animate-pulse mr-0.5" />
        <div className="h-1 w-[144px] sm:w-[120px] tab:w-[100px] py-1.5 my-3 bg-dropDownBorder rounded-lg animate-pulse" />
        </div>
        
        <div className="flex flex-inline">
        <div className="h-[16px] w-[16px] my-2.5 bg-dropDownBorder rounded-lg animate-pulse mr-0.5" />
        <div className="h-1 w-[74px] py-1.5 my-3 bg-dropDownBorder rounded-lg animate-pulse" />
        </div>
        
        <div className="flex flex-inline">
        <div className="h-[16px] w-[16px] my-2.5 bg-dropDownBorder rounded-lg animate-pulse mr-0.5" />
        <div className="h-1 w-[94px] py-1.5 my-3 bg-dropDownBorder rounded-lg animate-pulse" />
        </div>

        
        <div className="flex flex-inline">
        <div className="h-[16px] w-[16px] my-2.5 bg-dropDownBorder rounded-lg animate-pulse mr-0.5" />
        <div className="h-1 w-[94px] py-1.5 my-3 bg-dropDownBorder rounded-lg animate-pulse" />
        </div>

        
        <div className="flex flex-inline">
        <div className="h-[16px] w-[16px] my-2.5 bg-dropDownBorder rounded-lg animate-pulse mr-0.5" />
        <div className="h-1 w-[148px] sm:w-[120px] tab:w-[100px] py-1.5 my-3 bg-dropDownBorder rounded-lg animate-pulse" />
        </div>

        
        <div className="flex flex-inline">
        <div className="h-[16px] w-[16px] my-2.5 bg-dropDownBorder rounded-lg animate-pulse mr-0.5" />
        <div className="h-1 w-[148px] sm:w-[120px] tab:w-[100px] py-1.5 my-3 bg-dropDownBorder rounded-lg animate-pulse" />
        </div>
      </div>
      </div>
  );
}

export default SidebarLoadingIndicator;
