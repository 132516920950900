import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toggleSumbit } from '../../../../Store/Actions/ButtonStateActions'
import validationHelper from '../../../../utils/helpers/orderFormValidationHelper'
import CustomInput from '../../../global/CustomInput'
import moment from 'moment'

function MedicareSection({ values, setFieldValue, errors, validateForm, setValidSections, touched }: any) {
  // moment(values.date_of_discharge).subtract(1,'days');

  const date = new Date()
  const dateOffset = (24 * 60 * 60 * 1000) * 1
  // const maxDate = date.setTime(date.getTime() - dateOffset)
  const maxDate = date

  const [dateReset, setDateReset] = useState<boolean>(false)
  const InputFields = {
    icd_10: 'icd_10',
    insurance: 'insurance',
    icn: 'icn',
    billing_phone: 'billing_phone',
    policy: 'policy',
    medicare: 'medicare',
    date_of_discharge: 'date_of_discharge'
  }
  const dispatch = useDispatch()

  function enableSubmit() {
    dispatch(toggleSumbit(true))
  }
  function disableSubmit() {
    dispatch(toggleSumbit(false))
  }

  function handleChange(name: string, val: any): void {
    if (name === 'medicare' && val !== 'Hospital Inpatient') {
      let updatedValues = { ...values }
      updatedValues.date_of_discharge = '1993-03-03'
      updatedValues[name] = val
      setFieldValue('date_of_discharge', '1993-03-03')
      setDateReset(true)
      setFieldValue(name, val)
      validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
    } else {
      if (dateReset) {
        let updatedValues = { ...values }
        updatedValues[name] = val
        updatedValues['date_of_discharge'] = ''
        setFieldValue(name, val)
        setFieldValue('date_of_discharge', '')
        setDateReset(false)
        validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
      } else {
        let updatedValues = { ...values }
        updatedValues[name] = val
        updatedValues.date_reset = false
        setFieldValue(name, val)
        validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
      }
    }
  }

  useEffect(() => {
    validationHelper(validateForm, setValidSections, values, InputFields, 'billing info & medicare', enableSubmit, disableSubmit)
  }, [values.medicare, values.date_of_discharge, values.icd_10])

  return <div tabIndex={5} className="focus:outline-primary p-5 my-5 shadow border border-[0.5px] rounded-md flex flex-col hover:border-primary hover:border">
    <p className="text-royalBlue font-medium text-base">Medicare</p>
    <div>
      <p className="text-royalBlue mt-5">Required  for patients with traditional Medicare as primary insurance</p>
    </div>
    <div className="mt-5">
      <p className="text-royalBlue text-sm">Type of facility (where tissue was collected) <span className="text-requiredRed">*</span></p>
      <div className="flex mt-2.5">
        <CustomInput
          id={'non_hospital'}
          key={'non_hospital'}
          name={'medicare'}
          label={'Non-Hospital'}
          isInternal
          value={'Non-Hospital'}
          type="radio"
          checked={values.medicare === 'Non-Hospital' ?? false}
          onChange={(e: any) => handleChange('medicare', e.target.value)}
          required
          placeholder={''}
          errors={errors}
          touched={touched}
        />
        <CustomInput
          id={'out_patient'}
          key={'out_patient'}
          name={'medicare'}
          label={'Hospital Outpatient'}
          isInternal
          type="radio"
          value={'Hospital Outpatient'}
          checked={values.medicare === 'Hospital Outpatient' ?? false}
          onChange={(e: any) => handleChange('medicare', e.target.value)}
          required
          placeholder={''}
          errors={errors}
          touched={touched}
        />
        <CustomInput
          id={'in_patient'}
          key={'in_patient'}
          name={'medicare'}
          label={'Hospital Inpatient'}
          isInternal
          type="radio"
          value={'Hospital Inpatient'}
          checked={values.medicare === 'Hospital Inpatient' ?? false}
          onChange={(e: any) => handleChange('medicare', e.target.value)}
          required
          placeholder={''}
          errors={errors}
          touched={touched}
        />
      </div>

      {values.medicare === 'Hospital Inpatient' && <CustomInput
        id={'date_of_discharge'}
        key={'date_of_discharge'}
        name={'date_of_discharge'}
        label={'Date of Discharge'}
        isInternal
        value={values.date_of_discharge}
        type={'date'}
        onChange={(e: any) => handleChange('date_of_discharge', e)}
        required
        placeholder={'MM/DD/YYYY'}
        errors={errors}
        max={new Date(maxDate)}
        className={'mr-5 !placeholder:text-requiredRed w-[320px]'}
        touched={touched}
      />}
    </div>
  </div>
}
export default MedicareSection
