function physicianToClinician(
 roles: string[] | null | undefined
): string[] | string | any {
 let finalData: string[] | string = "N/A";

 if (!!roles === false || roles?.length == 0) {
  return finalData;
 }

 if (!!roles && Array.isArray(roles)) {
  return (finalData = roles?.map((item: string) =>
   item.replace("Physician", "Clinician")
  ));
 }

 return finalData;
}
export default physicianToClinician;
