import { Fragment } from 'react'
import QuestionMarkSVG from '../../assets/svg/QuestionMarkSVG'
import Button from '../atomic/Button'

interface existingSignUpProps {
  handleBack: () => any
  handleSupport: () => any
  isPage: boolean
}
function sendEmail(){
    location.href='mailto:clinicalservices@castlebiosciences.com'
}

function ExistingSignUpContents ({ handleBack, handleSupport, isPage }: existingSignUpProps) {
  return <Fragment>
        <QuestionMarkSVG fill="#fff" />
        <div className={isPage ? 'w-4/6 md:w-2/6 text-center p-5' : 'w-4/6 text-center p-5'}>
            <p className="text-royalBlue font-medium">To add an existing organization, please ask the organization's Office Manager to invite you to the organization.</p>
            <p className="text-royalBlue font-medium py-5">If there is any problem in getting yourself invited or if you have any questions regarding the organization existing on the portal, please feel free to contact Clinical Services and we will be happy to help</p>
        </div>
        <div className="flex items-center">

            {isPage && <Button
                btnType="secondary"
                title={'Back'}
                onClick={handleBack}
                className={!isPage ? 'text-base pt-2 pb-2 mr-6' : 'mr-6'}
            />}
            <Button
                btnType="secondary"
                title={'contact support'}
                onClick={sendEmail}
                className={!isPage ? 'text-base pt-2 pb-2' : ''}
            />
        </div>
    </Fragment>
}
export default ExistingSignUpContents
