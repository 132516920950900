import * as React from "react"

const PageBack = (props: any) => {
  const [hover, setHover] = React.useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={7.811}
      height={14.121}
      {...props}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <path
        data-name="Path 5086"
        d="m6.75 1.061-3.5 3.5-2.5 2.5 6 6"
        fill="none"
        stroke={hover ? "#008080" : props.stroke ?? "#b1b8c3"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  )
}

export default PageBack
