import * as React from 'react'
import { SVGProps } from 'react'
interface iconProps {
  isVisible: boolean
}

type passwordVisibilityProps = SVGProps<SVGSVGElement> & iconProps

const PasswordVisibility = (props: passwordVisibilityProps) => (
  props.isVisible
    ? <svg
        xmlns="http://www.w3.org/2000/svg"
        width={21.5}
        height={20.121}
        style={{ marginBottom: 4 }}
        {...props}
    >
        <g
            data-name="view open"
            fill="none"
            stroke="#647188"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        >
            <path data-name="Line 1372" d="m1.75 1.061 18 18" />
            <path data-name="Path 5313" d="M9.334 8.648a2 2 0 1 0 2.828 2.83" />
            <path
                data-name="Path 5314"
                d="M8.113 3.426a9.466 9.466 0 0 1 2.637-.365q6 0 10 7a19.028 19.028 0 0 1-2.5 3.488m-2.14 1.861a9.38 9.38 0 0 1-5.36 1.651q-6 0-10-7A15.693 15.693 0 0 1 5.382 4.72"
            />
        </g>
    </svg>
    : <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21.773}
            height={15.5}
            {...props}
        >
            <g
                data-name="Component 6403 \u2013 1"
                transform="translate(.75 .75)"
                fill="none"
                stroke="#647188"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
            >
                <circle
                    data-name="Ellipse 615"
                    cx={2}
                    cy={2}
                    r={2}
                    transform="translate(8 5)"
                />
                <path data-name="Path 5279" d="M20 7q-4 7-10 7T0 7q4-7 10-7t10 7" />
            </g>
        </svg>
)

export default PasswordVisibility
