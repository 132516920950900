import * as React from "react"
import { SVGProps } from "react"
const CloseBigIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.121}
    height={14.121}
    {...props}
  >
    <g
      fill="none"
      stroke="#647188"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      data-name="Component 6419 \u2013 8"
    >
      <path d="m13.061 1.061-12 12" data-name="Line 1250" />
      <path d="m1.061 1.061 12 12" data-name="Line 1251" />
    </g>
  </svg>
)
export default CloseBigIcon
