import { AnimatePresence, motion } from 'framer-motion'
import { Fragment } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import OrganizationExistSVG from '../../assets/svg/OrganizationExistSVG'
import ButtonStateActions, { setButtonState } from '../../Store/Actions/ButtonStateActions'
import ExistingSignUpContents from '../molecular/ExisitingSignUpContents'
import SignUpFlowButtons from '../molecular/SignUpFlowButtons'
import OrganizationSignUpForm from './OrganizationSignUpForm'

function RenderContent({ state }: { state: string }) {
  switch (state) {
    case 'create organization':
      return <Fragment>
        <div className="py-5 px-12 flex-none">
          <p className="text-royalBlue font-medium">Organization Detail</p>
        </div>
        <OrganizationSignUpForm isPage={false} handleNext={() => { }} />
      </Fragment>
    case 'existing':
      return <div className="flex flex-col h-full self-center justify-center items-center" >
        <ExistingSignUpContents isPage={false} handleBack={() => { }} handleSupport={() => { }} />
      </div>
    default:
      return null
  }
}

function OrganizationScreenSignUpFlow({ setHeaderTitle }: any) {
  const { screen } = useSelector((state: any) => state.ButtonState)
  const dispatch = useDispatch()

  function handleCreate() {
    dispatch(setButtonState('create organization'))
  }

  function handleExisting() {
    dispatch(setButtonState('existing'))
  }

  return <div className="col-span-3 flex flex-col">
    {screen === 'add organization'
      ? <div className="flex flex-col justify-center items-center h-full">
        <div className="flex flex-col justify-center items-center">
          <OrganizationExistSVG />
          <p className="text-royalBlue mt-2 mb-3">Does your organization exist on the portal?</p>
        </div>
        <div className="mx-2 lg:mx-0">
          <SignUpFlowButtons
            handleCreate={handleCreate}
            handleExisting={handleExisting}
            isPage={false}
          />
        </div>
      </div>
      : <AnimatePresence exitBeforeEnter>
        <motion.div
          className="flex flex-col items-stretch h-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <RenderContent state={screen} />
        </motion.div>
      </AnimatePresence>
    }
  </div>
}
const mapStateToProps = (state: any) => ({ screen: state.ButtonState.screen })
const mapDispatchToProps = {
  setButtonState: ButtonStateActions.setButtonState
}
export default connect(mapStateToProps, mapDispatchToProps)(OrganizationScreenSignUpFlow)
