import { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { toggleSumbit } from "../../../../Store/Actions/ButtonStateActions";
import validationHelper from "../../../../utils/helpers/orderFormValidationHelper";
import scrollHelper from "../../../../utils/helpers/scrollHelper";
import CustomInput from "../../../global/CustomInput";
import { cityStateHelper } from "../../../../utils/helpers/cityStateHelper";

function ContactInfoSection({
  values,
  setFieldValue,
  errors,
  touched,
  validateForm,
  setValidSections,
  setSelectedTab,
  InputFields,
  handleDataValidation,
  ...rest
}: any): JSX.Element {
  const dispatch = useDispatch();
  const { data: states, error } = useSelector(
    (state: any) => state.StateAndCity
  );
  const { selectedTest } = useSelector((state: any) => state.OnlineOrderForm);

  const [cities, setCities] = useState([]);

  function enableSubmit() {
    dispatch(toggleSumbit(true));
  }
  function disableSubmit() {
    dispatch(toggleSumbit(false));
  }

  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(
      inView,
      setSelectedTab,
      undefined,
      "patient info"
    );
  }

  function handleChange(input: any, name: string) {
    const updatedValues = { ...values };
    updatedValues[name] = input;
    rest.setTouchedSections((prevState: any) => [...prevState, "patient info"]);
    if (name === "patient_state") {
      // const foundState = states.find((state: any) => `${state.name} ${state.state_code}` === input)
      // if (foundState) { setCities(foundState.cities) }
      cityStateHelper(
        states,
        setCities,
        input,
        values,
        setFieldValue,
        "patient_city"
      );
      setFieldValue(name, input);
      setFieldValue(
        "stateValue",
        states.find((state: any) => state.state_code === input)?.name ?? input
      );
      validationHelper(
        validateForm,
        setValidSections,
        updatedValues,
        InputFields,
        "patient info",
        enableSubmit,
        disableSubmit
      );
    } else {
      setFieldValue(name, input);
      validationHelper(
        validateForm,
        setValidSections,
        updatedValues,
        InputFields,
        "patient info",
        enableSubmit,
        disableSubmit
      );
    }
  }

  return (
    <InView threshold={0.8} onChange={handleMouseScroll}>
      <div
        tabIndex={3}
        className="focus:outline-primary p-5 my-5 shadow-sm border border-[0.5px] rounded-md flex flex-col hover:border-primary hover:border"
      >
        {/* {selectedTest?.id === 8 && (
          <p className="text-royalBlue mt-2">
            <span className="font-semibold">Note: </span>If this is a telehealth
            order and you'd like your patient to receive alerts about their kit,
            please enter patient email.
          </p>
        )} */}
        <p className="text-royalBlue font-medium text-base">
          Patient Contact Information
        </p>
        <div>
          <p className="text-royalBlue mt-5">
            If patient face sheet is uploaded, this section can be left blank
          </p>
          {values?.selectedTest?.id === 8 && 
           <p className="text-royalBlue mt-3 flex">
           <span className='font-semibold mr-2'>Note: </span> 
           <p> If this is a telehealth
             order and you'd like your patient to receive alerts about their kit,
             please enter patient email.</p>
           </p>}
         
        </div>
        <CustomInput
          id={"patient_email"}
          key={"patient_email"}
          name={"patient_email"}
          obscureInput
          label={"Email ID"}
          isInternal
          value={values.patient_email}
          // onChange={(val: any) => setFieldValue('email', val.target.value)}
          onChange={(e: any) => handleChange(e.target.value, "patient_email")}
          required={false}
          placeholder={"Enter email ID"}
          errors={errors}
          touched={touched}
        />
        <CustomInput
          id={"patient_address"}
          key={"patient_address"}
          name={"patient_address"}
          obscureInput
          label={"Address"}
          isInternal
          value={values.patient_address}
          // onChange={(val: any) => setFieldValue('address', val)}
          onChange={(e: any) => handleChange(e.target.value, "patient_address")}
          required={false}
          placeholder={"Enter street address"}
          errors={errors}
          touched={touched}
        />
        <div className="flex justify-between items-center">
          <CustomInput
            id={"patient_state"}
            key={"patient_state"}
            name={"patient_state"}
            label={"State/Province"}
            obscureInput
            required={false}
            placeholder={"Enter state"}
            className={"mr-5"}
            // suggestions={!error && states.length > 0 ? states.map((state: any) => { return `${state.name} ${state.state_code}` }) : []}
            suggestions={
              !error && states.length > 0
                ? states.map((state: any) => ({
                    label: state.name,
                    value: state.state_code,
                  }))
                : []
            }
            isInternal
            onChange={
              states && states.length > 0
                ? (val: any) => handleChange(val, "patient_state")
                : (val: any) => handleChange(val.target.value, "patient_state")
            }
            errors={errors}
            value={
              values?.stateValue
                ? values.stateValue
                : values.patient_state
                ? states &&
                  states.length > 0 &&
                  states.find((state: any) =>
                    `${state.name} ${state?.state_code}`.includes(
                      values?.patient_state
                    )
                  )?.name
                : values.patient_state
            }
            touched={touched}
          />
          <CustomInput
            id={"patient_city"}
            key={"patient_city"}
            name={"patient_city"}
            obscureInput
            label={"City"}
            isInternal
            value={values.patient_city}
            // suggestions={cities && cities.length > 0 ? cities.map((city: any) => { return city.name }) : []}
            suggestions={
              cities && cities.length > 0
                ? cities.map((city: any) => ({
                    label: city.name,
                    value: city.name,
                  }))
                : []
            }
            onChange={
              cities && cities.length > 0
                ? (val: any) => handleChange(val, "patient_city")
                : (val: any) => handleChange(val.target.value, "patient_city")
            }
            required={false}
            placeholder={"Enter city name"}
            errors={errors}
            touched={touched}
          />
        </div>
        <div className="flex justify-between items-center">
          <CustomInput
            id={"patient_phone"}
            key={"patient_phone"}
            name={"patient_phone"}
            obscureInput
            label={"Phone"}
            required={false}
            placeholder={"000-000-0000"}
            type={"tel"}
            isInternal
            // onChange={(val: any) => setFieldValue('phone', val)}
            onChange={(e: any) => handleChange(e.target.value, "patient_phone")}
            errors={errors}
            touched={touched}
            className={"mr-5"}
          />
          <CustomInput
            id={"patient_zip_code"}
            key={"patient_zip_code"}
            obscureInput
            name={"patient_zip_code"}
            label={"Zip Code"}
            required={false}
            value={values.patient_zip_code}
            placeholder={"Enter zip code"}
            errors={errors}
            isInternal
            // onChange={(val: any) => setFieldValue('zip_code', val)}
            onChange={(e: any) =>
              handleChange(e.target.value, "patient_zip_code")
            }
            touched={touched}
          />
        </div>
      </div>
    </InView>
  );
}
export default ContactInfoSection;
