import { boolean } from "yup";
import BodyColumnData, {
 BodyColumnValue,
} from "../components/NotificationsScreen/BodyColumnData.model";
import HeaderColumnData, {
 SubColumn,
} from "../models/notificationsSettingsTableModels/headerColumnData.model";
import { useDispatch } from "react-redux";
import NotificationActions from "../Store/Actions/NotificationsActions";

export interface ApiMediumValue {
 id: string;
 key: string;
 name: string;
 defaultValue: boolean;
 isEditable: boolean;
 createdAt: null | Date;
 updatedAt: null | Date;
 disabledHoverText: string;
}

export interface ApiOrganizationMediumSettingsValue {
 id: number;
 notificationMediumId: number;
 organizationId: number;
 value: boolean;
 createdAt: Date | null;
 updatedAt: Date | null;
}

export interface ApiUserNotificationMediumValue {
 id: number;
 notificationMediumId: number;
 organizationId: number;
 value: boolean;
 createdAt: Date | null;
 updatedAt: Date | null;
}

export interface ApiUserNotificationTypeValue {
 id: number;
 notificationTypeId: number;
 organizationId: number;
 userId: number;
 value: boolean;
 createdAt: Date | null;
 updatedAt: Date | null;
}

export interface ApiNotificationTypeValue {
 id: number | string;
 key: string;
 name: string;
 defaultValue: boolean;
 isOfficeManager: boolean;
 isPhysician: boolean;
 isCOA: boolean;
 isPLU: boolean;
 createdAt: Date | null;
 updatedAt: Date | null;
}

class NotificationSettingsServices {
 private mediumValues: ApiMediumValue[] = [];
 private organizationMediumValues: ApiOrganizationMediumSettingsValue[] = [];
 private userMediumValues: ApiUserNotificationMediumValue[] = [];
 private notificationTypes: ApiNotificationTypeValue[] = [];
 private userNotificationTypesValues: ApiUserNotificationTypeValue[] = [];
 private tableBody: Array<BodyColumnData[]> = [];
 private tableColumn: HeaderColumnData[] = [];
 private typeTableColumn: HeaderColumnData[] = [];
 private personalNotificationMediumTableData: Array<BodyColumnData[]> = [];
 private personalNotificationTypeTableData: Array<BodyColumnData[]> = [];
 private notificationMediumsSubColumns: SubColumn[] = [];
 private notificationTypeSubColumns: SubColumn[] = [];
 private organizations: any = [];

 constructor(
  organizations: any,
  mediumValues: ApiMediumValue[],
  organizationMediumValues: ApiOrganizationMediumSettingsValue[],
  userMediumValues?: ApiUserNotificationMediumValue[],
  notificationTypes?: ApiNotificationTypeValue[],
  userNotificationTypesValues?: ApiUserNotificationTypeValue[]
 ) {
  this.organizations = organizations;
  this.mediumValues = mediumValues;
  this.organizationMediumValues = organizationMediumValues;
  this.userMediumValues = userMediumValues ?? [];
  this.notificationTypes = notificationTypes ?? [];
  this.userNotificationTypesValues = userNotificationTypesValues ?? [];
 }
 private createNotificationMediums(isPersonalSetting:boolean) {
  // Map all notification mediums in the table header
  this.mediumValues.forEach((mediumValue) => {
   let settingExist = this?.organizationMediumValues?.some(
    (orgMediumValue) =>
     orgMediumValue.notificationMediumId == Number(mediumValue.id)
   );

   let mediumData: SubColumn = {
    key: mediumValue.key,
    id: mediumValue.id,
    name: mediumValue.name,
    allSelected: false,
    disabled:
     this.organizationMediumValues.length == 0 && isPersonalSetting
      ? true
      : settingExist == false && isPersonalSetting
      ? true
      : mediumValue.isEditable
      ? false
      : true,
   };

   this.notificationMediumsSubColumns.push(mediumData);
  });

  this.tableColumn.push({
   columnName: "Notification Medium",
   subColumns: this.notificationMediumsSubColumns,
  });
 }

 private createNotificationType() {
  // Map all notification type in the table header
  this.notificationTypes.forEach((notificationValue) => {
   let notificationData: SubColumn = {
    key: notificationValue.key,
    id: notificationValue.id,
    name: notificationValue.name,
    allSelected: false,
    disabled: false,
   };

   this.notificationTypeSubColumns.push(notificationData);
  });

  this.typeTableColumn.push({
   columnName: "Notification Types",
   subColumns: this.notificationTypeSubColumns,
  });
 }

 private createTableData(isPersonalSetting: boolean) {
  // Create Body data for table with respective notification medium values
  this.organizations.forEach((organization: any) => {
   let rowData = [];
   let notificationsMediums: BodyColumnValue[] = [];
   let organizationColumnData: BodyColumnValue = {
    disabled: false,
    hoverText: null,
    key: "Organization",
    name: organization.name,
    id: organization.id,
    value: organization.id,
    type: "text",
   };

   this.mediumValues.forEach((mediumValue) => {
    let mediumDataValue = mediumValue.defaultValue;
    let mediumDisabled = false;
    let hoverText = mediumValue.disabledHoverText;
    let organizationValueForTheMedium = this?.organizationMediumValues?.find(
     (orgMediumValue) =>
      orgMediumValue.notificationMediumId == Number(mediumValue.id) &&
      orgMediumValue.organizationId == organization.id
    )?.value;

    let personalSettingForTheMedium = this.userMediumValues.find(
     (userMedium) =>
      userMedium.organizationId === organization.id &&
      userMedium.notificationMediumId == Number(mediumValue.id)
    );

    if (isPersonalSetting) {
     mediumDataValue =
      organizationValueForTheMedium === true
       ? personalSettingForTheMedium?.value !== undefined
         ? personalSettingForTheMedium.value
         : organizationValueForTheMedium
       : mediumValue.defaultValue;

     mediumDisabled =
      organizationValueForTheMedium === undefined
       ? true
       : organizationValueForTheMedium === false
       ? true
       : mediumValue.isEditable == true
       ? false
       : true;
    } else {
     mediumDataValue = organizationValueForTheMedium
      ? organizationValueForTheMedium
      : mediumValue.defaultValue;
     mediumDisabled = mediumValue.isEditable == true ? false : true;
    }

    let organizationMediumData: BodyColumnValue = {
     key: mediumValue.key,
     id: mediumValue.id,
     name: mediumValue.name,
     value: mediumDataValue ? mediumDataValue : false,
     type: "switch",
     disabled: mediumDisabled,
     hoverText: mediumDisabled ? hoverText : null
    };
    notificationsMediums.push(organizationMediumData);
   });

   rowData.push({ data: { ...organizationColumnData } });
   rowData.push({ data: notificationsMediums });

   if (isPersonalSetting) {
    this.personalNotificationMediumTableData.push(rowData);
   } else {
    this.tableBody.push(rowData);
   }
  });
 }
 private createNotificationTypeTableData() {
  // Create Body data for table with respective notification medium values
  this.organizations.forEach((organization: any) => {
   let rowData = [];
   let notificationTypes: BodyColumnValue[] = [];
   let organizationColumnData: BodyColumnValue = {
    disabled: false,
    key: "Organization",
    name: organization.name,
    id: organization.id,
    value: organization.id,
    type: "text",
    hoverText: null,
   };

   this.notificationTypes.forEach((notificationValue) => {
    let organizationValueForTheType = this?.userNotificationTypesValues?.find(
     (userTypeValue) =>
      userTypeValue.notificationTypeId == notificationValue.id &&
      userTypeValue.organizationId == organization.id
    )?.value;

    let organizationTypeData: BodyColumnValue = {
     key: notificationValue.key,
     id: notificationValue.id,
     name: notificationValue.name,
     value:
      organizationValueForTheType !== undefined
       ? organizationValueForTheType
       : notificationValue.defaultValue,
     type: "switch",
     disabled: false,
     hoverText: null
    };

    notificationTypes.push(organizationTypeData);
   });

   rowData.push({ data: { ...organizationColumnData } });
   rowData.push({ data: notificationTypes });

   this.personalNotificationTypeTableData.push(rowData);
  });
 }

 handleSelectAll(
  id: string | number,
  tableColumn: HeaderColumnData[],
  tableBody: Array<BodyColumnData[]>,
  dispatch: any,
  value?: boolean
 ) {
  let disabledCount: number = 0;
  let tempTableColumn: HeaderColumnData[] = JSON.parse(
   JSON.stringify(tableColumn)
  );
  let tempTableBody: Array<BodyColumnData[]> = JSON.parse(
   JSON.stringify(tableBody)
  );
  let allSelectedValue = false;

  tempTableColumn = tempTableColumn.map((column) => ({
   ...column,
   subColumns: column.subColumns.map((subColumn) => {
    if (subColumn.id == id) {
     allSelectedValue = value !== undefined ? value : !subColumn.allSelected;
    }
    return {
     ...subColumn,
     allSelected:
      subColumn.id == id
       ? value !== undefined
         ? value
         : !subColumn.allSelected
       : subColumn.allSelected,
    };
   }),
  }));

  tempTableBody = tempTableBody.map((value) =>
   value.map((item) => {
    if (Array.isArray(item.data)) {
     disabledCount =
      disabledCount +
      item.data.filter((value) => value.id == id && value.disabled).length;
     return {
      ...item,
      data: item.data.map((value) => ({
       ...value,
       value:
        id == value.id && value.disabled == false
         ? allSelectedValue
         : value.value,
      })),
     };
    }
    return item;
   })
  );
  if(disabledCount > 0){
    dispatch(NotificationActions.showNotification({
      // type: 'suc',
      type: 'success',
      title: 'Notifications Status',
      hideClose: false,
      message: `${tempTableBody?.length - disabledCount} of ${tempTableBody?.length} notifications have been ${allSelectedValue === false ? 'disabled':'enabled'} but ${disabledCount} notification was restricted by organization.`,
      customFunction() {},
      buttons: [
        {
          onClick: () => {
            dispatch(NotificationActions.hideNotifications());
          },
          title: 'OK',
          type: 'primary',
        },
      ],
    }));
  }
 
  return {
   tableColumn: tempTableColumn,
   tableBody: tempTableBody
  };
 }

 createOrganizationsSettingsData() {
  this.tableColumn = [
   {
    columnName: "Organizations",
    subColumns: [],
   },
  ];
  this.createNotificationMediums(false);
  this.createTableData(false);
 }

 createPersonalOrganizationsSettingsData() {
  this.tableColumn = [
   {
    columnName: "Organizations",
    subColumns: [],
   },
  ];

  this.createNotificationMediums(true);
  this.createTableData(true);
 }

 createPersonalNotificationTypeData() {
  this.typeTableColumn = [
   {
    columnName: "Organizations",
    subColumns: [],
   },
  ];

  this.createNotificationType();
  this.createNotificationTypeTableData();
 }

 getOrganizationsSettingsData(): {
  tableBody: Array<BodyColumnData[]>;
  tableColumn: HeaderColumnData[];
 } {
  return {
   tableBody: this.tableBody,
   tableColumn: this.tableColumn,
  };
 }

 getPersonalOrganizationsSettingsData(): {
  tableBody: Array<BodyColumnData[]>;
  tableColumn: HeaderColumnData[];
 } {
  return {
   tableBody: this.personalNotificationMediumTableData,
   tableColumn: this.tableColumn,
  };
 }

 getPersonalNotificationTypeData(): {
  tableBody: Array<BodyColumnData[]>;
  tableColumn: HeaderColumnData[];
 } {
  return {
   tableBody: this.personalNotificationTypeTableData,
   tableColumn: this.typeTableColumn,
  };
 }
}

export default NotificationSettingsServices;
