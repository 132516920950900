import { Component, Fragment } from 'react'
import './style.css'
import Checkbox from '../Checkbox/Checkbox'
import Dropdown, { DropdownItem } from '../Dropdown/Dropdown'
import TextInput from '../TextInput/TextInput'
import { ListItemProps } from '../ListItem/ListItem'
import CustomInput from '../global/CustomInput'
import { SortableHeader } from './SortableHeader'
import { SortableItem } from '../../Pages/Admin/Documents/SortableItem'
import TableFilterDropdown from '../TestOrders/TableFilterDropdown'
import FilterIcon from '../../assets/svg/FilterIcon'
import ReactTooltip from 'react-tooltip'
import TooltipHelper from '../global/TooltipHelper'
interface headerAction {
  type: 'sort' | 'filter' | 'multiselect'
  onClick: (field: string, filter: string) => any
  dropdownList: DropdownItem[]
  currentSelection: DropdownItem | null
}
export interface tooltip {
  key: string
  value: string
}
export interface header {
  name: string
  filter?: boolean
  tooltip?: tooltip
  sticky?: boolean
  stickyClass?: string
  suggestions?: any[]
  handleMultiSelect?: (index: any) => any
  action?: headerAction
  multiSelect?: boolean
  disableSuggestiveFiltering?: boolean
  preventAutoSearch: boolean
  hideIds?: boolean
}
export interface ITableHeaderProps {
  check?: boolean
  usersListDebounce?: boolean
  externalCheckBox?: boolean
  // filter?: boolean;
  visibleFields?: any[]
  hasVerticalMenu?: boolean
  onFilter?: (data: any) => any
  data: header[]
  list: any
  initialFilters?: any
  onSelectAll?: (event: any) => any
  NavLink?: any
  nnProps?: any
  onMouseEnter?: any
  onMouseLeave?: any
  showNumbering?: boolean
  hideCheckBox?: boolean
  portaluserHeader?: any
}

interface ITableHeaderState {
  data: any
  filters: any
}
class DataTableHeader extends Component<ITableHeaderProps, ITableHeaderState> {
  constructor(props: ITableHeaderProps) {
    super(props)
    this.state = {
      data: this.props.list,
      filters: this.props.initialFilters ? { ...this.props.initialFilters } : {
      }
    }
  }

  filterData(inputValue: any, column: any) {
    const { list, visibleFields } = this.props
    const { data } = this.state
    const filteredList = list?.filter((item: any) =>
      item.content[Object.keys(item.content)[column]].match(inputValue)
    )
    if (inputValue === '') {
      return data
    } else {
      return filteredList
    }
  }

  componentDidUpdate(prevProps: Readonly<ITableHeaderProps>, prevState: Readonly<ITableHeaderState>, snapshot?: any): void {
    const { initialFilters } = prevProps;
    const { initialFilters: currentFilters } = this.props;

    if (initialFilters && currentFilters && Object.keys(initialFilters).length !== Object.keys(currentFilters).length) {
      this.setState({ filters: currentFilters ? { ...currentFilters } : {} });
    }
  }


  render() {
    const {
      data,
      NavLink,
      onSelectAll,
      check,
      nnProps,
      hasVerticalMenu,
      showNumbering,
      visibleFields,
      // filter,
      externalCheckBox,
      onFilter,
      hideCheckBox,
      onMouseEnter,
      onMouseLeave,
      portaluserHeader,
      usersListDebounce
    } = this.props
    const { filters } = this.state

    return (
      <thead className="data-table-header-container sticky top-0 z-[9] !overflow-visible">
        <tr>
          {showNumbering &&
            <th />
          }
          {
            externalCheckBox &&
            <th colSpan={0.5}>
              <Checkbox
                NavLink={NavLink}
                onChange={() => (onSelectAll != null) && onSelectAll(!check)}
                checked={check}
                nnProps={nnProps}
                hideCheckBox={hideCheckBox}
              />
            </th>
          }
          {(data || [])?.map((item, index) => {
            if (visibleFields?.find((field) => field?.label == item?.name)?.selected == false) { return null }
            // return <th key={index + 'dataHeader'} className={`${item?.sticky ? 'sticky left-0 bg-[#f9f9fa]' : ''}`}>
            return <SortableHeader
              key={index + 'dataHeader'}
              id={visibleFields?.find((field) => field?.label === item?.name)?.key}
              className={`${item?.sticky ? `sticky ${item?.stickyClass ? item.stickyClass : 'left-0'} bg-[#f9f9fa]` : ''}`}
            >
              <div onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} className={`mt-3 flex flex-col items-start h-[100px] ${item?.tooltip ? ' items-center' : ''} justify-start`}>
                <div className={`table-checkbox-container font-medium`}>
                  {index === 0 && !!externalCheckBox === false && (
                    <Checkbox
                      NavLink={NavLink}
                      onChange={() => (onSelectAll != null) && onSelectAll(!check)}
                      checked={check}
                      nnProps={nnProps}
                      hideCheckBox={hideCheckBox}
                    />
                  )}
                  {(item?.action != null) ? (
                    <Dropdown
                      label={item?.name}
                      type="menu"
                      placeholder={item?.name}
                      dropdownList={item?.action?.dropdownList}
                      currentSelection={item?.action?.currentSelection}
                      onSelection={(selected) => {
                        if (item?.action?.type === 'filter') {
                          if (filters[index] === selected.label) {
                            this.setState(
                              { filters: { ...filters, [index]: '' } },
                              () => {
                                (onFilter != null) && onFilter(this.state.filters)
                              }
                            )
                          } else {
                            this.setState(
                              { filters: { ...filters, [index]: selected.label } },
                              () => {
                                (onFilter != null) && onFilter(this.state.filters)
                              }
                            )
                          }
                        } else {
                          if (item?.action?.onClick)
                            item.action?.onClick(item?.name, selected.key)
                        }
                      }}
                    />
                  ) : (
                    item?.name
                  )}

                  {item?.tooltip && <Fragment>
                    <div
                      data-for={item?.name.toLowerCase()}
                      data-tip={item?.tooltip.value}
                      data-iscapture="true"

                      data-multiline={true}
                      style={{ minHeight: 15, minWidth: 15, borderRadius: 15 / 2 }}
                      className={
                        "ml-2 cursor-pointer bg-primary hover:bg-primaryHover text-center text-white flex flex-col justify-center items-center text-xs lowercase"
                      }
                    >
                      i
                    </div>
                    <TooltipHelper
                      id={item.name.toLowerCase()}
                      place={'top'}
                      // type={'dark'}
                      backgroundColor={'#18B5B7'}
                      className={'!text-xs !p-2 max-w-[200px]'}
                      effect={'float'}
                      scrollHide={true}
                      multiline={true}
                    />
                  </Fragment>}
                </div>
                {item?.filter /* && index + 1 < data.length - 1  */ ? (
                  <div
                    onMouseEnter={onMouseLeave}
                    onMouseLeave={onMouseEnter}
                    className={` font-normal mb-[10px] mt-auto ${portaluserHeader ? 'w-[80%]' : 'table-filter-container'}`}>
                    <TextInput
                      usersListDebounce={usersListDebounce}
                      label=""
                      type={item?.name.includes('Date') || item?.name.includes('DOB') || item?.name.includes('Time') ? 'date' : 'string'}
                      suggestions={item?.suggestions ?? []}
                      value={filters[index]}
                      placeholder="Filter"
                      multiSelect={item?.multiSelect}
                      disableSuggestiveFiltering={item.disableSuggestiveFiltering}
                      hideIds={item?.hideIds ?? true}
                      preventAutoSearch={item?.preventAutoSearch}
                      handleMultiSelect={item?.handleMultiSelect}
                      statusShipment = {item?.name.includes('Status')}
                      handleInput={(input) => {
                        this.setState(
                          { filters: { ...filters, [index]: input } },
                          () => {
                            (onFilter != null) && onFilter(this.state.filters)
                          }
                        )
                      }}
                      portalUserHeader
                    />
                  </div>
                ) : null}
              </div>
            </SortableHeader>
          })}
          {hasVerticalMenu && <th />}
        </tr>
      </thead >
    )
  }
}

export default DataTableHeader
