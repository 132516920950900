function handleInView(
 inView: boolean,
 setSelectedTab: any,
 firstScroll: any,
 currentTabId: string
): void {
 let forceTab = localStorage.getItem("forceTab");

 if (forceTab) {
  if (forceTab === currentTabId) {
   setTimeout(() => {
    localStorage.removeItem("forceTab");
   }, 1500);
  } else {
   return;
  }
 }

 if (inView && !!firstScroll === false) {
  setSelectedTab(currentTabId);
 }
}

const scrollHelper = {
 handleInView: handleInView,
};

export default scrollHelper;
