import {
 RESET_BUTTON_STATE,
 SET_BUTTON_STATE,
 TOGGLE_FORM_SUBMIT,
 TOGGLE_SUBMIT,
 TOGGLE_BUTTON_VISIBILITY,
 TOGGLE_SAVE_AND_EXIT,
 TOGGLE_SAVE_ADDITIONAL_CLINICIAN,
} from "./types";

export const setButtonState = (screen: string) => ({
 type: SET_BUTTON_STATE,
 payload: screen,
});
export const resetButtonState = () => ({ type: RESET_BUTTON_STATE });
export const toggleSumbit = (state: boolean) => ({
 type: TOGGLE_SUBMIT,
 payload: state,
});
export const toggleSaveAndExit = (state: boolean) => ({
 type: TOGGLE_SAVE_AND_EXIT,
 payload: state,
});
export const formSubmit = (state: boolean) => ({
 type: TOGGLE_FORM_SUBMIT,
 payload: state,
});
export const disableButton = (state: boolean) => ({
 type: TOGGLE_SAVE_ADDITIONAL_CLINICIAN,
 payload: state,
});
export const toggleButtonVisibility = (state: boolean) => ({
 type: TOGGLE_BUTTON_VISIBILITY,
 payload: state,
});

const ButtonStateActions = {
 setButtonState,
 resetButtonState,
 toggleSumbit,
 toggleSaveAndExit,
 formSubmit,
 toggleButtonVisibility,
 disableButton,
};

export default ButtonStateActions;
