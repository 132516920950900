/* eslint-disable @typescript-eslint/space-before-function-paren */
import { AnimatePresence, motion } from "framer-motion";
import { Fragment, useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import ButtonStateActions, { toggleSumbit } from "../../../../Store/Actions/ButtonStateActions";
import validationHelper from "../../../../utils/helpers/orderFormValidationHelper";
import scrollHelper from "../../../../utils/helpers/scrollHelper";
import Button from "../../../atomic/Button";
import CustomInput from "../../../global/CustomInput";
import RequiredFieldTooltip from "../../../global/RequiredFieldTooltip";
import { useLocation } from "react-router-dom";
import CloseBigIcon from "../../../../assets/svg/CloseBigIcon";
import NotificationActions from "../../../../Store/Actions/NotificationsActions";
import * as Yup from "yup";

// const validationSchemas = 
//   Yup.object().shape({
//     firstName: Yup.string()
//       .required("First Name is required!")
//       .max(100, "First Name must be no longer than 100 characters"),
//     lastName: Yup.string()
//       .required("Last Name is required!")
//       .max(100, "Last Name must be no longer than 100 characters"),
//     fax: Yup.string().test("fax-validation", "Fax must contain 10 digits with the following format 000-000-0000", (value) => {
//       if (!value) {
//         return true; // Allow empty values
//       }
//       return /\d{3}-\d{3}-\d{4}/.test(value);
//     }).nullable(),
//     email: Yup.string().email("Email ID is invalid").nullable(),
//     npi: Yup.string().required('NPI is required!').test("npi-validation", "NPI must be a 10-digit number or empty", (value) => {
//       if (!value) {
//         return true; // Allow empty values
//       }
//       return /^[0-9]{10}$/.test(value);
//     }),
//   })

const validationSchemas = Yup.array().of(
  Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required!")
      .max(100, "First Name must be no longer than 100 characters"),
    lastName: Yup.string()
      .required("Last Name is required!")
      .max(100, "Last Name must be no longer than 100 characters"),
    fax: Yup.string().test("fax-validation", "Fax must contain 10 digits with the following format 000-000-0000", (value) => {
      if (!value) {
        return true; // Allow empty values
      }
      return /\d{3}-\d{3}-\d{4}/.test(value);
    }).nullable(),
    email: Yup.string().email("Email ID is invalid").nullable(),
    npi: Yup.string().required('NPI is required!').test("npi-validation", "NPI must be a 10-digit number or empty", (value) => {
      if (!value) {
        return true; // Allow empty values
      }
      return /^[0-9]{10}$/.test(value);
    }),
  })
);


function ClinicianCard({
  clinician,
  handleRemove,
  index,
  setFieldValue,
  errors,
  touched,
  isValid,
  handleInView,
  values,
  validateForm,
  onBlurCapture,
  setValidSections,
  isSummary,
  // isDisable,
  // setIsDisable
}: any): JSX.Element {
  const dispatch = useDispatch();
  function enableSubmit(): void {
    dispatch(toggleSumbit(true))
  }
  function disableSubmit(): void {
    dispatch(toggleSumbit(false))
  }

  const item = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 },
  };

  function handleChange(name: string, value: string, index: number) {
    const InputFields = {
      additionalClinician: 'additionalClinician'
    }
    let additionalClinician = [...values.additionalClinician]

    additionalClinician[index] = {
      ...additionalClinician[index],
      [name.split('.')[1]]: value
    }

    let updatedValues = {
      ...values,
      additionalClinician
    }
    setFieldValue(name, value)
    const filteredAdditionalClinicians = (updatedValues?.additionalClinician || []).filter((obj: Object) => !('status' in obj));
    validationSchemas
      .validate(filteredAdditionalClinicians, { abortEarly: false })
      .then((res) => {
        // Validation succeeded, set isDisable to false
        dispatch(ButtonStateActions.disableButton(false))

        dispatch(ButtonStateActions.toggleSaveAndExit(false))
        // setIsDisable(false);
      })
      .catch((errors: any) => {
        // Validation failed, set isDisable to true
        dispatch(ButtonStateActions.disableButton(true))

        dispatch(ButtonStateActions.toggleSaveAndExit(true))
        // setIsDisable(true);
      });


    if (value === "" && name?.includes('firstName') && name?.includes('lastName') && name?.includes('npi')) {
      dispatch(ButtonStateActions.toggleSumbit(false));
      setValidSections?.((prevState: any) => prevState.filter((item: any) => item !== 'additional info'))
    } else {
      validationHelper(
        validateForm,
        setValidSections,
        updatedValues,
        InputFields,
        "additional info",
        enableSubmit,
        disableSubmit
      );
    }

  }
  function showPopUp() {
    dispatch(NotificationActions.showNotification({
      type: 'success',
      title: '',
      hideClose: true,
      message: 'Please contact CS Team to delete any additional clinician.',
      customFunction() {
      },
      buttons: [
        {
          onClick: () => {
            location.href = 'mailto:clinicalservices@castlebiosciences.com';

            dispatch(NotificationActions.hideNotifications())
          },
          title: 'Contact CS Team',
          type: "secondary",
        },
        {
          onClick: () => {
            dispatch(NotificationActions.hideNotifications())
          },
          title: 'OK',
          type: "primary",
        }
      ]
    }))
  }


  return (
    <InView
      onChange={handleInView}
      threshold={(values.selectedTest === 4 && window.innerWidth > 1120) ? 1 : 0.6}
    >
      <motion.div
        variants={item}
        className={`focus:outline-primary w-full ${isSummary && 'bg-[#F9F9FA]'}  p-6 my-5 shadow-sm border border-[0.5px] rounded-md hover:border-primary hover:border oveflow-auto break-all`}
      >
        <div className="flex justify-between items-center">
          <div className="flex flex-wrap">
            <p className="text-base font-medium text-royalBlue">
              Additional Clinician {clinician}
            </p>
            <div className="ml-6">
              {isSummary && values?.additionalClinician[clinician - 1]?.status &&
                <p className={` ${values?.additionalClinician[clinician - 1]?.status === 'Pending' ? 'bg-[#d7defe] text-[#4F6CFE]' : 'bg-[#cbeeef] text-[#18B5B7] '} py-1 block w-max px-2 rounded-md capitalize text-center !text-xs font-medium `}>{values?.additionalClinician[clinician - 1]?.status}</p>
              }
            </div>
          </div>
          {isSummary && values?.additionalClinician[clinician - 1]?.status ?
            <button type="button" onClick={showPopUp}>
              <CloseBigIcon /></button> :

            <Fragment>
              {
                !!values?.additionalClinician[clinician - 1]?.id == false && <button type="button" onClick={handleRemove(index)}>
                  <CloseBigIcon className="mr-2" />
                </button>
              }
            </Fragment>
          }
        </div>
        {isSummary && values?.additionalClinician[clinician - 1] && values?.additionalClinician[clinician - 1]?.status ? (
          <div className="flex flex-wrap space-x-7">
            <div>
              <p className="text-royalBlue font-xs font-semibold my-2">First name</p>
              <p className="text-royalBlue text-base capitalize">
                {values?.additionalClinician[clinician - 1]?.firstName ? values?.additionalClinician[clinician - 1]?.firstName : values?.additionalClinician[clinician - 1]?.physician?.firstName ? values?.additionalClinician[clinician - 1]?.physician?.firstName : '---'}
              </p>
            </div>
            <div>
              <p className="text-royalBlue font-xs font-semibold my-2">Last name</p>
              <p className="text-royalBlue text-base capitalize">
                {values?.additionalClinician[clinician - 1]?.lastName ? values?.additionalClinician[clinician - 1]?.lastName : values?.additionalClinician[clinician - 1]?.physician?.lastName ? values?.additionalClinician[clinician - 1]?.physician?.lastName : '---'}
              </p>
            </div>
            <div>
              <p className="text-royalBlue font-xs font-semibold my-2">NPI</p>
              <p className="text-royalBlue text-base capitalize">
                {values?.additionalClinician[clinician - 1]?.npi ? values?.additionalClinician[clinician - 1]?.npi : values?.additionalClinician[clinician - 1]?.physician?.npi ? values?.additionalClinician[clinician - 1]?.physician?.npi : '---'}
              </p>
            </div>
            <div>
              <p className="text-royalBlue font-xs font-semibold my-2">Fax</p>
              <p className="text-royalBlue text-base capitalize">
                {values?.additionalClinician[clinician - 1]?.fax ? values?.additionalClinician[clinician - 1]?.fax : values?.additionalClinician[clinician - 1]?.physician?.fax ? values?.additionalClinician[clinician - 1]?.physician?.fax : '---'}
              </p>
            </div>
            <div>
              <p className="text-royalBlue font-xs font-semibold my-2">Email</p>
              <p className="text-royalBlue text-base capitalize">
                {values?.additionalClinician[clinician - 1]?.email ? values?.additionalClinician[clinician - 1]?.email : values?.additionalClinician[clinician - 1]?.physician?.email ? values?.additionalClinician[clinician - 1]?.physician?.email : '---'}
              </p>
            </div>
          </div>
        ) : (
          <>
            <div className={`md:block 2xl:flex xl:flex lg:flex justify-between items-center`}>
              <CustomInput
                id={`additionalClinician[${clinician - 1}].firstName`}
                name={`additionalClinician[${clinician - 1}].firstName`}
                label={"First Name"}
                isInternal
                disabled={values?.additionalClinician[clinician - 1]?.id}
                onBlurCapture={onBlurCapture}
                value={values?.additionalClinician[clinician - 1]?.firstName ?? ''}
                className={"mt-6 mr-5"}
                onChange={(e: any) => handleChange(e.target.name, e.target.value, clinician - 1)}
                required={true}
                placeholder={"Enter First Name"}
                errors={errors}
                customTouched={touched && touched.additionalClinician && touched?.additionalClinician[clinician - 1]?.firstName ? touched?.additionalClinician[clinician - 1]?.firstName : false}
                customError={errors && errors.additionalClinician && errors?.additionalClinician[clinician - 1]?.firstName ? errors?.additionalClinician[clinician - 1]?.firstName : ''}
                touched={touched}
                isSummary={isSummary}
              />
              <CustomInput
                id={`additionalClinician[${clinician - 1}].lastName`}
                name={`additionalClinician[${clinician - 1}].lastName`}
                label={"Last Name"}
                onBlurCapture={onBlurCapture}
                isInternal
                disabled={values?.additionalClinician[clinician - 1]?.id}
                value={values?.additionalClinician[clinician - 1]?.lastName ?? ''}
                className={"mt-6 mr-5"}
                onChange={(e: any) => handleChange(e.target.name, e.target.value, clinician - 1)}
                required={true}
                placeholder={"Enter Last Name"}
                errors={errors}
                customTouched={touched && touched.additionalClinician && touched?.additionalClinician[clinician - 1]?.lastName ? touched?.additionalClinician[clinician - 1]?.lastName : false}
                customError={errors && errors.additionalClinician && errors?.additionalClinician[clinician - 1]?.lastName ? errors?.additionalClinician[clinician - 1]?.lastName : ''}
                touched={touched}
                isSummary={isSummary}
              />
            </div>
            <div className={`md:block 2xl:flex xl:flex lg:flex justify-between items-center`}>
              <CustomInput
                id={`additionalClinician[${clinician - 1}].npi`}
                name={`additionalClinician[${clinician - 1}].npi`}
                label={"NPI"}
                isInternal
                value={values?.additionalClinician[clinician - 1]?.npi ?? ''}
                disabled={values?.additionalClinician[clinician - 1]?.id}
                className={"mt-6 mr-5"}
                onChange={(e: any) => handleChange(e.target.name, e.target.value, clinician - 1)}
                required={true}
                placeholder={"0000000000"}
                errors={errors}
                customTouched={touched && touched.additionalClinician && touched?.additionalClinician[clinician - 1]?.npi ? touched?.additionalClinician[clinician - 1]?.npi : false}
                customError={errors && errors.additionalClinician && errors?.additionalClinician[clinician - 1]?.npi ? errors?.additionalClinician[clinician - 1]?.npi : ''}
                touched={touched}
                isSummary={isSummary}
              />
              <CustomInput
                id={`additionalClinician[${clinician - 1}].fax`}
                name={`additionalClinician[${clinician - 1}].fax`}
                label={"Fax"}
                disabled={values?.additionalClinician[clinician - 1]?.id}
                onBlurCapture={onBlurCapture}
                isInternal
                value={values?.additionalClinician[clinician - 1]?.fax ?? ''}
                onChange={(e: any) => handleChange(e.target.name, e.target.value, clinician - 1)}
                className={"mt-6 mr-5"}
                required={false}
                type='tel'
                placeholder={"000-000-0000"}
                errors={errors}
                customTouched={touched && touched.additionalClinician && touched?.additionalClinician[clinician - 1]?.fax ? touched?.additionalClinician[clinician - 1]?.fax : false}
                customError={errors && errors.additionalClinician && errors?.additionalClinician[clinician - 1]?.fax ? errors?.additionalClinician[clinician - 1]?.fax : ''}
                touched={touched}
                isSummary={isSummary}
              />
            </div>
            <CustomInput
              id={`additionalClinician[${clinician - 1}].email`}
              name={`additionalClinician[${clinician - 1}].email`}
              label={"Email ID"}
              disabled={values?.additionalClinician[clinician - 1]?.id}
              isInternal
              value={values?.additionalClinician[clinician - 1]?.email ?? ''}
              className={"mt-6 mr-5"}
              onChange={(e: any) => handleChange(e.target.name, e.target.value, clinician - 1)}
              required={false}
              placeholder={"Enter email ID"}
              errors={errors}
              customTouched={touched && touched.additionalClinician && touched?.additionalClinician[clinician - 1]?.email ? touched?.additionalClinician[clinician - 1]?.email : false}
              customError={errors && errors.additionalClinician && errors?.additionalClinician[clinician - 1]?.email ? errors?.additionalClinician[clinician - 1]?.email : ''}
              touched={touched}
              isSummary={isSummary}
            />
          </>)}
      </motion.div>
    </InView >
  );
}

function AdditionalInfoSection({
  initialValues,
  values,
  setFieldValue,
  errors,
  touched,
  setFieldError,
  setValidSections,
  setSelectedTab,
  selectedTab,
  validateForm,
  setFieldTouched,
  isValid,
  isUpdate,
  isSummary,
  firstScroll,
  fetchOrderById,
  ...rest
}: any): JSX.Element {
  const [clinicians, setClinicians] = useState<any[]>([]);
  const [selectedClinicians, setSelectedClinicians] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { state }: any = useLocation()
  const InputFields = {
    additionalClinician: 'additionalClinician'
  }
  const { selectedOrganization, selectedPhysician, mode } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const list = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const alreadyAddedClinician = values?.additionalClinician?.filter((e: any) => e?.status !== undefined).length > 0

  function handleAdditionalClinician(e: any): void {
    const val = e.target.checked;

    if (val) {
      dispatch(ButtonStateActions.toggleSumbit(false));
      // setValidSections?.((prevState: any) =>
      //   prevState.filter((item: string) => item !== "additional info")
      // );
      // setClinicians((prevState) => [...prevState, 1]);
    } else {
      // setClinicians([])
      resetValues();
      setFieldValue("attestation", false)
    }
    setFieldValue("has_additional_clinician", val);
  }
  function handleAttestation(e: any): void {
    const val = e.target.checked;

    if (val === true && clinicians.length === 0) {
      setValidSections?.((prevState: any) =>
        prevState.filter((item: string) => item !== "additional info")
      );
      setFieldValue('additionalClinician', [{ firstName: '', lastName: '', npi: '' }])
      dispatch(ButtonStateActions.toggleSumbit(false));
      setClinicians((prevState) => [...prevState, 1]);
    } else {
      // setClinicians([])
      if (val === false)
        resetValues();
    }
    const additionalClinician = [...values.additionalClinician];
  
    let updatedValues = {
      ...values,
      additionalClinician: additionalClinician,
    };
    if(updatedValues?.additionalClinician?.length === 0){
      setValidSections?.((prevState: any) =>
      prevState.filter((item: string) => item !== "additional info")
    );
    
    dispatch(ButtonStateActions.disableButton(true))
    }
    setFieldValue("attestation", val);
  }
  function handleAddMoreClinician(): void {
    /*  if (clinicians?.length < 3) { */
    const temp = [...clinicians];
    dispatch(ButtonStateActions.toggleSaveAndExit(true))
    setValidSections?.((prevState: any) =>
      prevState.filter((item: string) => item !== "additional info")
    );
    setFieldValue('additionalClinician', [...values.additionalClinician, { firstName: '', lastName: '', fax: '', npi: '', email: '' }])

    if (!clinicians.includes(temp.length + 1)) {
      temp.push(temp.length + 1);
      dispatch(ButtonStateActions.toggleSumbit(false));
    } else {
      temp.push(
        temp[temp.length - 1] - temp[0] < 0
          ? (temp[temp.length - 1] - temp[0]) * -1
          : temp[temp.length - 1] - temp[0] === 0
            ? 1
            : temp[temp.length - 1] - temp[0]
      );
      dispatch(ButtonStateActions.toggleSumbit(false));
    }

    setClinicians(temp);
    if (isSummary) {
      // setIsDisable(true)
      dispatch(ButtonStateActions.disableButton(true))
    }
    /* } */
  }

  function handleRemove(index: number) {
    return () => {
      const temp = [...clinicians];
      const additionalClinician = [...values.additionalClinician];
      const touchedClinicians = touched.additionalClinician
        ? [...touched.additionalClinician]
        : [];

      temp.splice(index, 1);
      additionalClinician.splice(index, 1);
      touchedClinicians.splice(index, 1);

      setClinicians(temp);
      let updatedValues = {
        ...values,
        additionalClinician: additionalClinician,
      };

      const filteredAdditionalClinicians = (updatedValues?.additionalClinician || []).filter((obj: Object) => !('status' in obj));
      validationSchemas
      .validate(filteredAdditionalClinicians, { abortEarly: false })
      .then((res) => {
        // Validation succeeded, set isDisable to false
        dispatch(ButtonStateActions.disableButton(false))

        dispatch(ButtonStateActions.toggleSaveAndExit(false))
        // setIsDisable(false);
      })
      .catch((errors: any) => {
        // Validation failed, set isDisable to true
        dispatch(ButtonStateActions.disableButton(true))

        dispatch(ButtonStateActions.toggleSaveAndExit(true))
        // setIsDisable(true);
      });
     /*  if (
        updatedValues?.additionalClinician[index]?.map(
          (e: any) => e?.firstName === "" || e?.lastName === "" || e?.npi === ""
        )
      ) {
        dispatch(ButtonStateActions.disableButton(true));
      } else */ if (temp?.length === 0) {
        resetValues();
      } else {
        dispatch(ButtonStateActions.disableButton(false));
      }

      setFieldValue(`additionalClinician`, additionalClinician);
      setFieldTouched(`additionalClinician`, touchedClinicians);

      if (temp.length === 0) {
        setValidSections?.((prevState: any) =>
          prevState.filter((item: string) => item !== "additional info")
        );
        setFieldValue("additionalClinician", []);
        resetValues();
      }

      if (temp.length !== additionalClinician.length) {
        return setValidSections?.((prevState: any) =>
          prevState.filter((item: string) => item !== "additional info")
        );
      }
      
      setValidSections?.((prevState: any) => [...prevState, "additional info"]);

      validationHelper(
        validateForm,
        setValidSections,
        updatedValues,
        InputFields,
        "additional info",
        enableSubmit,
        disableSubmit
      );

      const invalidCLinician = updatedValues.additionalClinician.findIndex((clinician: any) => clinician.firstName === '' || clinician.lastName === '' || clinician.npi === '')
      if (invalidCLinician !== -1) {
        dispatch(ButtonStateActions.toggleSaveAndExit(true))
      } else {
        dispatch(ButtonStateActions.toggleSaveAndExit(false))
      }

    };
  }



  function resetValues() {
    setFieldValue('has_additional_clinician', null)
    setFieldValue('attestation', false)
    setFieldValue('additionalClinician', [])
    setFieldTouched('additionalClinician', false)
    setValidSections?.((prevState: any) =>
      prevState.filter((item: string) => item !== "additional info")
    )
    setClinicians([]);
    dispatch(toggleSumbit(false))
    // Object.keys(keys).forEach((key) => {
    //   type ObjectKey = keyof typeof keys;
    //   const myKey = key as ObjectKey;
    //   setFieldValue(key, "");
    //   setFieldValue(keys[myKey], "");
    //   setValidSections((prevState: any) =>
    //     prevState.filter((item: string) => item !== "additional info")
    //   );
    //   setClinicians([]);
    // });
  }
  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, firstScroll, 'additional info')

    // if (clinicians.length === 1)
    //   setFieldValue('additionalClinician', [])
    if (!inView && rest?.touchedSections?.includes('additional info')) {
      clinicians?.map((clinician, index) => {
        setFieldTouched(`additionalClinician[${index}].firstName`, true)
        setFieldTouched(`additionalClinician[${index}].lastName`, true)
        setFieldTouched(`additionalClinician[${index}].npi`, true)
      })
    }
    // Object.keys(InputFields).forEach((key) => {
    //   rest.setFieldTouched(key, true, true)
    // })
  }
  function enableSubmit(): void {
    dispatch(toggleSumbit(true))
    dispatch(ButtonStateActions.toggleSaveAndExit(false))
  }
  function disableSubmit(): void {
    dispatch(toggleSumbit(false))
    dispatch(ButtonStateActions.toggleSaveAndExit(true))
  }

  function handleBlur() {
    rest.setTouchedSections((prevState: any) => [...prevState, 'additional info'])
  }

  useEffect(() => {
    if (isUpdate || !!state?.orderValue || isSummary) {
      let additionalClinician = values?.additionalClinician;
      setClinicians(additionalClinician.map((item: any, index: any) => index + 1))

      // Check Null
      if (values?.has_additional_clinician === null) {
        dispatch(ButtonStateActions.toggleSumbit(false));
        setValidSections?.((prevState: any) =>
          prevState.filter((item: string) => item !== "additional info")
        );
        return
      }
      //  Check and populate additional clinicians
      if (values?.additionalClinician?.length > 0 &&
        values?.has_additional_clinician == true && values?.attestation === true) {
        setValidSections?.((prevState: any) => [...prevState, "additional info"]);
        setClinicians(
          values?.additionalClinician?.map((item: any, index: number) => index + 1)
        );
        {
          !isSummary &&
            validationHelper(
              validateForm,
              setValidSections,
              values,
              InputFields,
              "additional info",
              enableSubmit,
              disableSubmit
            );
        }
        return
      }
      // Is empty
      // if (values.attestation === true) {
      setValidSections?.((prevState: any) => [...prevState, "additional info"])
      setClinicians([1])
      {
        !isSummary &&

          validationHelper(
            validateForm,
            setValidSections,
            values,
            InputFields,
            "additional info",
            enableSubmit,
            disableSubmit
          );
      }
      // }
      // dispatch(ButtonStateActions.toggleSumbit(isValid));
      return

    }
  }, [])

  useEffect(() => {
    if (alreadyAddedClinician) {
      setFieldValue('attestation', true)
      setFieldValue('has_additional_clinician', true)
      setClinicians(
        values?.additionalClinician?.map((item: any, index: number) => index + 1)
      );

      setValidSections?.((prevState: any) => [...prevState, "additional info"])
      dispatch(ButtonStateActions.toggleSaveAndExit(false))

      validationHelper(
        validateForm,
        setValidSections,
        values,
        InputFields,
        "additional info",
        enableSubmit,
        disableSubmit
      );
    }
  }, [values.attestation])

  return (
    <InView
      id="additional info"
      // className="pb-32 scroll-mt-32"
      className={`${selectedTab === "select test" || firstScroll ? "scroll-mt-64" : "scroll-my-32"
        } pb-32 ${isSummary && 'w-full'}`}
      onChange={isSummary ? (inView) => { if (inView) setSelectedTab('additional info') } : handleMouseScroll}
      threshold={0.75}
    >
      <div className="flex flex-col items-start">

        {!isSummary && <RequiredFieldTooltip required={true} tooltipText={'Required Field'}>
          <p className="text-royalBlue my-5">
            If you want to share the order report with additional clinician, please specify below. Otherwise, click “SKIP”
            <span className="text-requiredRed ml-2">*</span>
          </p>
        </RequiredFieldTooltip>}
        <div className="flex justify-between items-center w-full">
          <CustomInput
            id={"has_additional_clinician"}
            key={"has_additional_clinician"}
            name={"has_additional_clinician"}
            label={"Enter additional clinician"}
            required={false}
            placeholder={""}
            onBlurCapture={handleBlur}
            isInternal
            checkboxContainerStyle={"!justify-start w-full !items-center"}
            errors={errors}
            disabled={(isSummary && alreadyAddedClinician) || (isUpdate && alreadyAddedClinician)}
            touched={touched}
            className={"my-2"}
            checked={!!values.has_additional_clinician || (isSummary && values?.additionalClinician?.length > 0 || alreadyAddedClinician)}
            onChange={handleAdditionalClinician}
            type="checkbox"
          />
          {!values.has_additional_clinician && !isSummary && (
            <Button
              title={"Skip"}
              btnType="primary"
              className={"shadow-sm"}
              onClick={() => {
                setSelectedTab("laboratory info");
                setFieldValue('has_additional_clinician', false)
                dispatch(ButtonStateActions.toggleSumbit(isValid));
                setValidSections?.((prevState: any) => [
                  ...prevState,
                  "additional info",
                ]);
                const updatedValues = { ...values, has_additional_clinician: false }
                validationHelper(
                  validateForm,
                  setValidSections,
                  updatedValues,
                  InputFields,
                  "additional info",
                  enableSubmit,
                  disableSubmit
                );
                const section: any = document.getElementById("laboratory info");
                section.scrollIntoView({ behavior: "smooth", block: "start" });
              }}
            />
          )}
        </div>

        {(!!values.has_additional_clinician || (values?.additionalClinician?.length > 0)) && <div className="">
          <CustomInput
            id={"attestation"}
            key={"attestation"}
            name={"attestation"}
            label={"By checking this box, I, hereby, acknowledge and agree that these clinicians will receive the final test result report (PHI)."}
            required={false}
            placeholder={""}
            onBlurCapture={handleBlur}
            isInternal
            checkboxContainerStyle={"!justify-start w-full !items-center"}
            errors={errors}
            touched={touched}
            disabled={(isSummary && alreadyAddedClinician) || (isUpdate && alreadyAddedClinician)}
            className={"my-2"}
            checked={values.attestation || (isSummary && values?.additionalClinician?.length > 0 || alreadyAddedClinician)}
            onChange={handleAttestation}
            type="checkbox"
          />
          {/* <p className="text-royalBlue font-normal text-xs">By checking this box, I, hereby, acknowledge and agree that these physicians will receive the final test result report (PHI).</p> */}
        </div>}
        {!!values?.has_additional_clinician && !isSummary && !!values.attestation && clinicians && clinicians?.length > 0 && (
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="hidden"
              animate="visible"
              // exit={"hidden"}
              key={mode}
              variants={list}
              className={"w-full"}
            >
              {clinicians?.map((clinician, index) => (
                <ClinicianCard
                  selectedClinicians={selectedClinicians}
                  setSelectedClinicians={setSelectedClinicians}
                  key={clinician}
                  onBlurCapture={handleBlur}
                  clinician={index + 1}
                  index={index}
                  setValidSections={setValidSections}
                  validateForm={validateForm}
                  handleInView={handleMouseScroll}
                  isValid={isValid}
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleRemove={handleRemove}
                  setFieldValue={setFieldValue}
                  isSummary={isSummary}
                />
              ))}
            </motion.div>
            {/* {clinicians && clinicians?.length < 3 && */}
            <Button
              btnType="transparent"
              title={"+ add more"}
              onClick={handleAddMoreClinician}
            />
            {/* } */}

          </AnimatePresence>
        )}
        {isSummary && !!values?.has_additional_clinician && !!values?.attestation && (
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial="hidden"
              animate="visible"
              // exit={"hidden"}
              key={mode}
              variants={list}
              className={"w-full"}
            >
              {clinicians?.map((clinician, index) => (
                <ClinicianCard
                  selectedClinicians={selectedClinicians}
                  setSelectedClinicians={setSelectedClinicians}
                  key={clinician}
                  onBlurCapture={handleBlur}
                  clinician={index + 1}
                  index={index}
                  setValidSections={setValidSections}
                  validateForm={validateForm}
                  handleInView={handleMouseScroll}
                  isValid={isValid}
                  errors={errors}
                  values={values}
                  touched={touched}
                  handleRemove={handleRemove}
                  setFieldValue={setFieldValue}
                  isSummary={isSummary}
                // isSummary={isSummary}
                // isDisable={isDisable}
                // setIsDisable={setIsDisable}
                />
              ))}
            </motion.div>

            {isSummary && values?.attestation /* && clinicians?.length < 3 */ &&
              <Button
                btnType="transparent"
                title={"+ add more"}
                onClick={handleAddMoreClinician}
              />
            }
          </AnimatePresence>
        )}

      </div>
    </InView>
  );
}

export default AdditionalInfoSection;
