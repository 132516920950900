/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import ProfileActions from '../../Store/Actions/ProfileActions'
import StateAndCityActions from '../../Store/Actions/StateAndCityActions'
import { LOGOUT } from '../../Store/Actions/types'
import toastMessages from '../../utils/helpers/toastMessage'

function LoginProcess({ fetchProfile, getStateAndCity }: any): JSX.Element {
  const { authState, oktaAuth }: any = useOktaAuth()
  const dispatch = useDispatch()
  const history = useHistory()

  function handleFirstTimeLogin(): void {
    const options = {
      accessToken: authState?.accessToken?.accessToken,
      onSuccess: () => {
        history.push('/profile-completion')
      },
      onError: () => {
        // alert('Something went wrong')
        toastMessages.error('Failed to retrieve user data')
        oktaAuth.signOut()
        dispatch({ type: LOGOUT, payload: true });
      }
    }
    fetchProfile(options)
    getStateAndCity({})
  }

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      history.replace('/')
    } else {
      if (authState.accessToken?.claims?.isFirstLogin) {
        handleFirstTimeLogin()
      } else {
        // history.push('/dashboard')
        history.push('/dashboard')
      }
    }
  }, [authState, oktaAuth])

  return <div className='flex h-screen w-full justify-center items-center'>
    <div className='animate-spin'>
      <img src={require('../../assets/images/loadingPNG.png')} className={'h-[100px] w-[100px]'} />
    </div>
  </div>
}

const mapDispatchToProps = {
  fetchProfile: ProfileActions.fetchprofile,
  getStateAndCity: StateAndCityActions.fetchStateAndCity
}

export default connect(null, mapDispatchToProps)(LoginProcess)
