/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import React from 'react'
import Header from '../components/global/Header'

function ProfileScreenLayout({ children, headerTitle, screen }: any): JSX.Element {
    return <div className="flex flex-col justify-between h-full">
        <Header
            isInternal
            title={headerTitle ?? 'My Profile'}
        />
        <div className="grid h-full grid-cols-5">
            {children}
        </div>
    </div>
}

export default ProfileScreenLayout
