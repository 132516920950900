function OrganizationsListLoadingIndicator () {
  return <div className="col-span-1 border-r px-6 py-8 overflow-y-auto flex h-full flex-col h-full">
        <div className="bg-dropDownBorder w-3/5 rounded-full p-1.5 animate-pulse" />
        <div className="bg-dropDownBorder w-full rounded-full p-2 my-4 animate-pulse" />

        <div className="border rounded-lg border-dropDownBorder p-6 my-4 flex justify-between items-start animate-pulse">
            <div className="w-1/6 p-3 rounded-lg bg-dropDownBorder"/>
            <div className="w-full mx-2 ">
                <div className="p-2 bg-dropDownBorder w-full rounded-lg"/>
                <div className="p-2 bg-dropDownBorder w-2/5 my-2 rounded-lg"/>
            </div>
        </div>

        <div className="bg-dropDownBorder w-full rounded-full p-2 my-4 animate-pulse" />

    </div>
}

export default OrganizationsListLoadingIndicator
