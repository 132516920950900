import * as React from "react"
import { SVGProps } from "react"

const TickIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10.693}
    height={7.525}
    {...props}
  >
    <path
      data-name="Path 5122"
      d="m1.061 3.918 2.857 2.857 5.714-5.714"
      fill="rgba(0,0,0,0)"
      stroke={props.stroke ?? "#d0d4db"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
)

export default TickIcon
