import * as Yup from "yup";

const OrganizationSignupValidation = Yup.object().shape({
  organization_name: Yup.string()
    .required("Organization name is required!")
    .test(
      "no-empty-blank-space",
      "Organization name cannot be an empty blank space(s)",
      (value: any) => {
        return value && value.trim() !== "";
      }
    ),
  //  email_id: Yup.string()
  //   .email("Email ID is invalid!")
  //   .required("Organization email ID is required!"),
  address: Yup.string()
    .required("Address is required")
    .test(
      "no-empty-blank-space",
      "Address cannot be an empty blank space(s)",
      (value: any) => {
        return value && value.trim() !== "";
      }
    ),
  address2: Yup.string(),
  phone: Yup.string()
    .matches(
      /\d{3}-\d{3}-\d{4}/,
      // /\d?(\s?|-?|\+?|\.?)((\(\d{1,4}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)\d{3}(-|\.|\s)\d{4}/,
      "Phone must contain 10 digits with following format 000-000-0000."
    )
    .required("Phone is required!"),
  fax: Yup.string()
    .required("Fax is required!")
    .matches(
      /\d{3}-\d{3}-\d{4}/,
      // /\d?(\s?|-?|\+?|\.?)((\(\d{1,4}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)\d{3}(-|\.|\s)\d{4}/,
      "Fax must contain 10 digits with following format 000-000-0000."
    ),
  city: Yup.string()
    .required("City is required!")
    .test(
      "no-empty-blank-space",
      "City cannot be an empty blank space(s)",
      (value: any) => {
        return value && value.trim() !== "";
      }
    ),
  zip_code: Yup.string()
    .matches(/^[0-9]+$/, "Zip code must be numbers")
    .min(5, "Length must equal to 5")
    .max(5, "Length must equal to 5")
    .required("Zip code is required!"),
  state: Yup.string()
    .required("State/Province is required!")
    .test(
      "no-empty-blank-space",
      "State/Province cannot be an empty blank space(s)",
      (value: any) => {
        return value && value.trim() !== "";
      }
    ),
});

export default OrganizationSignupValidation;
