import React, { useEffect } from "react";
import splitTextToLines from "../../utils/helpers/LineSpliter";
import CustomImage from "../global/CustomImage";


function DropDownItem({ value, onClick, margin, isOrg}: any) {

  return (
    <li className="w-full border-b !border-[#E2E2E2]">
      <button
        onClick={onClick}
        type="button"
        className={`flex justify-between items-start py-1.5 text-left w-full ${value?.label === "You have no clinician added to any organization" ||
          value?.label === "You have no clinician added in this organization"
          ? "py-1 "
          : "hover:bg-[#EDEFF1]"
          }`}
      >
        {value?.image && (
          <div className="w-1/6 pt-1 pl-2">
            <CustomImage
              src={value?.image}
              className="object-fit h-4 w-12"
              alt={""}
            />
          </div>
        )}
        <div className={`text-sm ${value?.image ? 'w-5/6' : 'w-full pr-2'} text-royalBlue ml-2 ${margin ? "pl-2" : ""
          } ${value?.image === "" ? "ml-[55.5px]" : ""} ${value?.label ===
            "You have no clinician added to any organization" ||
            value?.label === "You have no clinician added in this organization"
            ? "w-[100%]"
            : ""
          }`}>
          <p className="break-normal">{value?.label}</p>

          {isOrg && <div className="flex flex-wrap pr-2 text-xs">
            <p className=" text-[#949494]">
              {/* {value?.address} */}
              {splitTextToLines(value?.address, 1, 40)}
            </p>
            <p className="text-[#949494]">{value?.city ? `,${value?.city}` : ''}</p>
            <p className="text-[#949494]">{value?.state ? `,${value?.state}` : ''}</p>
          </div>}
        </div>
      </button>
    </li>
  );
}

export default DropDownItem;
