import * as React from "react"
import { SVGProps } from "react"

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Component 6418 \u2013 12"
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    {...props}
  >
    <path data-name="Path 5364" d="M0 0h15v15H0Z" fill="none" />
    <circle
      data-name="Ellipse 741"
      cx={4.5}
      cy={4.5}
      r={4.5}
      transform="translate(3 3)"
      fill="none"
      stroke="#2c3e50"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
    />
    <path
      data-name="Line 1557"
      fill="none"
      stroke="#2c3e50"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M7.5 5.607h0"
    />
    <path
      data-name="Path 5365"
      d="M6.5 6.908h1v2.229h1"
      fill="none"
      stroke="#2c3e50"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
    />
  </svg>
)

export default InfoIcon
