/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import React from 'react'
import Header from '../components/global/Header'
import ApprovalTabs from '../components/TestOrders/ApprovalsTabs'

function ApprovalsLayout({ children, headerTitle, tabs, validSections, handleCheckboxChange, isEdit, setSelectedTab, CustomButtons, fetching, }: any): JSX.Element {
  return <div className="flex flex-col justify-between h-full overflow-hidden">
    <Header
      isInternal
      showBack
      showTitleLoading={fetching}
      CustomButtons={CustomButtons}
      title={headerTitle ?? 'Online Order'}
      checkHeaderTitle
    />
    <div className="grid h-full grid-cols-5 overflow-hidden">
      {tabs && tabs.length > 0 && <div className='block bg-[#F9F9FA] overflow-auto'>
        {tabs.map((tab: any, index: number) => <ApprovalTabs
          validSections={validSections}
          selectedTab={tab?.selectedTab}
          testId={tab.testId}
          checked={tab.checked}
          index={index}
          fetching={fetching}
          handleCheckboxChange={() => handleCheckboxChange(index)}
          label={tab.label}
          isEdit={isEdit}
          setSelectedTab={() => { }}
        />)}
      </div>}
      <div className="col-span-4 grid grid-cols-4 overflow-auto border-l">
        {children}
      </div>
    </div>
  </div>
}

export default ApprovalsLayout
