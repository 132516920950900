import reduxActionType from "../../models/reduxActionType";
import * as actions from "../Actions/types";

const initialState = {
 syncing: false,
 syncError: false,
 syncSuccess: false,
 shouldFetch: true,
};

function SyncReducer(state = initialState, action: reduxActionType) {
 switch (action.type) {
  case actions.SYNCHRONIZING: {
   return {
    ...state,
    syncing: true,
    syncError: false,
    syncSuccess: false,
    shouldFetch: false,
   };
  }
  case actions.SYNCHRONIZING_SUCCESS:
   return {
    ...state,
    syncing: false,
    syncError: false,
    syncSuccess: true,
    shouldFetch: action.payload,
   };

  case actions.SYNCHRONIZING_ERROR:
   return {
    ...state,
    syncing: false,
    syncError: true,
    syncSuccess: false,
    shouldFetch: true,
   };
  case actions.LOGOUT: {
   return initialState;
  }
  default:
   return state;
 }
}

export default SyncReducer;
