import SelectedCheckMark from "../../assets/svg/SelectedCheckMark"

function FilterItem({ selected, label, onClick, itemStyle, type, disabled, dangerouslySetLabel , notificationsDropdown}: any) {
  if (type == 2)
    return <button type="button" onClick={onClick} disabled={disabled} className={`flex items-center justify-start px-4 py-2 hover:bg-sideMenuHoverBg w-full ${itemStyle} ${disabled ? 'cursor-not-allowed' : ''}`}>
      <div className="w-[3%]">
        {selected && <SelectedCheckMark />}
      </div>
      {
        dangerouslySetLabel ? <p className="capitalize text-left w-5/6" dangerouslySetInnerHTML={{ __html: label }} /> :
          <p className={`capitalize text-left w-5/6  ${notificationsDropdown && 'ml-3'}`}>{label}</p>
      }
    </button>

  return <button type="button" onClick={onClick} disabled={disabled} className={`flex px-4 py-2 hover:bg-sideMenuHoverBg items-center justify-start w-full ${itemStyle} ${disabled ? 'cursor-not-allowed' : ''}`}>
    <div className="w-[97%] mr-2">
      {selected && <SelectedCheckMark />}
    </div>
    {
      dangerouslySetLabel ? <p className="capitalize text-left w-5/6" dangerouslySetInnerHTML={{ __html: label }} /> :
        <p className={`w-5/6 capitalize ${notificationsDropdown && 'ml-3'}`}>{label}</p>
    }
  </button>
}
export default FilterItem