function VideoLoadingIndicator() {
  return (
    <div>
    <div className="flex justify-between items-center py-5">
      <div className="p-20 bg-dropDownBorder rounded-lg mx-3 w-[85px] animate-pulse" />
      <div className="p-20 bg-dropDownBorder rounded-lg mx-3 w-[85px] animate-pulse" />
    </div>
    <div className="flex justify-between items-center py-5">
      <div className="p-20 bg-dropDownBorder rounded-lg mx-3 w-[85px] animate-pulse" />
      <div className="p-20 bg-dropDownBorder rounded-lg mx-3 w-[85px] animate-pulse" />
    </div>
  </div>
  );
}

export default VideoLoadingIndicator;
