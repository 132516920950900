import toastMessages from "../../utils/helpers/toastMessage";
import { useState } from "react";
import DownloadHelper from "../../utils/helpers/fileDownloader";
import Button from "../atomic/Button";
import { InView } from "react-intersection-observer";
import scrollHelper from "../../utils/helpers/scrollHelper";
import DocumentLoadingIndicator from "../global/LoadingIndicators/DocumentSectionLoadingIndicator";
import { useSelector } from "react-redux";

function TestPdfDownloader({
  fileName,
  blobUrl,
  title,
  subtitle,
}: {
  fileName: string;
  blobUrl: string;
  title: string;
  subtitle: string;
}) {
  const [downloading, setDownloading] = useState<boolean>(false);
  function handleClick() {
    setDownloading(true);
    DownloadHelper.fileDownloader(
      blobUrl,
      fileName,
      () => {
        setDownloading(false);
      },
      (error: any) => {
        setDownloading(false);
        toastMessages.error(
          error.message ?? `Failed downloading ${title} ${subtitle}`
        );
      }
    );
  }

  return (
    <div className="px-1 flex flex-col">
      <p className="text-royalBlue overflow-hidden overflow-ellipsis max-w-[200px]">
        {title}
      </p>
      <p className="text-royalBlue ">{subtitle}</p>
      <div className="mt-auto">
        <Button
          btnType="secondary"
          title={downloading === true ? "Downloading" : "Download"}
          submitting={downloading}
          disabled={downloading}
          className={` !text-sm mr-5 focus:border-[#647188] outline-[#647188] my-3`}
          onClick={handleClick}
        />
      </div>
    </div>
  );
}

function DocumentsSection({ setSelectedTab, firstScroll }: any) {
  const docs = useSelector((state: any) => state.Help);
  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, firstScroll, "documents");
  }

  return (
    <InView
      id={"documents"}
      as={"div"}
      className={"scroll-my-[260px] border-b overflow-y-visible"}
      threshold={window.innerWidth <= 1280 ? 0.5 : 0.7}
      onChange={handleMouseScroll}
    >
      <p className="font-bold text-royalBlue text-[24px]">Documents</p>

      <div className="flex flex-wrap">
        {docs?.fetching ? (
          <DocumentLoadingIndicator />
        ) : docs?.data?.documents?.length === 0 ? (
          <p className="font-medium text-royalBlue py-5">No documents found</p>
        ) : (
          <div>
            <p className="font-semibold text-royalBlue py-5">
              Download Instruction Files
            </p>
            <div className="flex flex-wrap pb-3">
              {docs?.data?.documents
                ?.sort((a: any, b: any) => a.title.localeCompare(b.title))
                .map((test: any) => (
                  <TestPdfDownloader key={test.id} {...test} />
                ))}
            </div>
          </div>
        )}
      </div>
    </InView>
  );
}

export default DocumentsSection;
