import { useOktaAuth } from '@okta/okta-react'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import Button from '../../../components/atomic/Button'
import CustomPopup from '../../../components/global/CustomPopup'
import Header from '../../../components/global/Header'
import TestOrdersTable from '../../../components/TestOrders/TestOrdersTable'
import { setButtonState } from '../../../Store/Actions/ButtonStateActions'
import DownloadHelper from '../../../utils/helpers/fileDownloader'
import toastMessages from '../../../utils/helpers/toastMessage'
import PDFPrint from '../../../components/TestOrders/PDFPrint'

function ActionsHeader({ onPrint, onAuthorize, onDownload, buttonsDisabled, submitting, printing }: any): JSX.Element {
    return <div className='flex justify-between items-center'>
        <p className='text-sm text-royalBlue font-medium'>Pending orders</p>
        <div className='flex items-center'>
            <Button
                btnType='transparent'
                title={printing ? 'PRINTING' : 'PRINT'}
                disabled={buttonsDisabled || printing}
                submitting={printing}
                onClick={onPrint}
                className={'mr-4'} />
            <Button
                btnType='transparent'
                title={submitting ? 'DOWNLOADING' : 'DOWNLOAD'}
                submitting={submitting}
                disabled={buttonsDisabled || submitting}
                onClick={onDownload}
                className={'mr-4'} />
            <Button
                btnType='transparent'
                title='AUTHORIZE'
                disabled={buttonsDisabled}
                onClick={onAuthorize}
            />
        </div>
    </div>
}

function ApprovalsScreen() {
    const dispatch = useDispatch()
    const { authState } = useOktaAuth()
    const profile = useSelector((state: any) => state.Profile)
    const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(true)
    const [checkedIds, setCheckedIds] = useState<any[]>([])
    const [checkedPhysicianIds, setCheckedPhysicianIds] = useState<any>([])
    const [pdfIds, setPdfIds] = useState<any[]>([])
    const [allPDFIds, setAllPDFIds] = useState<any[]>([])
    const [buttonRefs, setButtonRefs] = useState<any>([]);
    const [multiPrinting, setMultiPrinting] = useState<boolean>(false)
    const [popupVisible, setPopupVisible] = useState<boolean>(false)
    const [multipleDownloading, setMultipleDownloading] = useState<boolean>(false)
    const multiPrintRef = useRef<any>()
    const history = useHistory()


    useEffect(() => {
        dispatch(setButtonState('create order'))
        if ((profile?.roles?.includes('PathLab User') || profile?.profile?.roles?.includes('PathLab User')) && (profile?.roles?.length == 1 || profile?.profile?.roles?.length == 1)) {
            return history.replace('/unauthorized')
        }
    }, [])
    function allAreEqual(array: any) {
        const result = array.every((element: any) => {
            if (element === array[0]) {
                return true;
            }
        });

        return result;
    }
    function onAuthorize() {
        if (checkedPhysicianIds.length > 1 && !allAreEqual(checkedPhysicianIds)) {
            setPopupVisible(true)
        } else {
            history.push(`approvals/review?ids=${checkedIds}`)
        }
    }

    function printOrder(): void {
        setMultiPrinting(true)
    }
    function handleDownload() {
        let data = pdfIds.map((item: any) => ({ id: item.split('-')[0] ? Number(item.split('_')[0]) : 0, fileName: item }))
        let token = authState?.accessToken?.accessToken ?? null
        if (token) {
            setMultipleDownloading(true)
            DownloadHelper.downloadMultiplePDF(data, token, true, (message: any) => {
                toastMessages.error(message)
                setMultipleDownloading(false)
            }, () => {
                setMultipleDownloading(false)
            })
        }
    }

    function hidePopup(): void {
        setPopupVisible(false)
    }

    return <AnimatePresence exitBeforeEnter>
        <CustomPopup
            hideButtons
            visible={popupVisible}
            hidePopup={hidePopup}
            title={'Clinician authorization'}>
            <p className='text-requiredRed'>Please select orders belonging to the same Clinician</p>
        </CustomPopup>
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}

        >
            <Header
                isInternal
                title={'Approvals'}
            />
            <div className="p-4 py-8">
                <ActionsHeader
                    buttonsDisabled={checkedIds.length < 2}
                    submitting={multipleDownloading}
                    onAuthorize={onAuthorize}
                    onPrint={printOrder}
                    printing={multiPrinting}
                    onDownload={handleDownload}
                />
                {checkedIds.length > 0 && multiPrinting && <PDFPrint
                    ids={checkedIds.map((id: number) => id.toString())}
                    onError={(message: string) => {
                        toastMessages.error(message)
                        setMultiPrinting(false)
                    }}
                    onStart={() => setMultiPrinting(true)}
                    onSuccess={() => {
                        setTimeout(() => {
                            setMultiPrinting(false)
                        }, 500)
                    }}
                />}
                <TestOrdersTable
                    screen='approvals'
                    hideButtons
                    multiPrintRef={multiPrintRef}
                    buttonRefs={buttonRefs}
                    multiPrinting={multiPrinting}
                    setMultiPrinting={setMultiPrinting}
                    setButtonsDisabled={setButtonsDisabled}
                    setCheckedIds={setCheckedIds}
                    setPdfIds={setPdfIds}
                    setAllPdfIds={setAllPDFIds}
                    setCheckedPhysicianIds={setCheckedPhysicianIds}
                    setButtonRefs={setButtonRefs}
                />
            </div>
        </motion.div>
    </AnimatePresence>
}

export default ApprovalsScreen
