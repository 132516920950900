import reduxActionType from '../../models/reduxActionType'
import { HIDE_NOTIFICATION, LOGOUT, SHOW_ERROR_NOTIFICATION, SHOW_SUCCESS_NOTIFICATION } from '../Actions/types'

const initialState: { visible: boolean, title: string, message: string, customFunction: () => void, buttons: any, type: string, hideClose?: boolean, Component: JSX.Element | null } = {
  visible: false,
  title: '',
  message: '',
  customFunction: () => { },
  buttons: null,
  type: '',
  hideClose: false,
  Component: null
}

function NotificationsReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case SHOW_SUCCESS_NOTIFICATION:
      return {
        ...state,
        visible: true,
        title: action.payload.title,
        message: action.payload.message,
        customFunction: action.payload.customFunction,
        buttons: action.payload.buttons,
        type: 'success',
        Component: action.payload.Component,
        hideClose: action?.payload?.hideClose ? action.payload.hideClose : false
      }

    case SHOW_ERROR_NOTIFICATION:
      return {
        ...state,
        visible: true,
        title: action.payload.title,
        message: action.payload.message,
        customFunction: action.payload.customFunction,
        buttons: action.payload.buttons,
        hideClose: action?.payload?.hideClose ? action.payload.hideClose : false,
        Component: action.payload.Component,
        type: 'error'
      }
    case HIDE_NOTIFICATION:
      return {
        ...state,
        visible: false,
        title: '',
        message: '',
        type: '',
        hideClose: false,
        buttons: null,
        Component: null
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default NotificationsReducer
