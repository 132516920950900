import React from 'react';
import { AnimatePresence, motion } from 'framer-motion'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '../../assets/svg/CloseIcon'
import NotificationActions from '../../Store/Actions/NotificationsActions'
import Button from '../atomic/Button'

function CustomNotifier() {
  const { visible, title, message, type, customFunction, buttons, hideClose, Component } = useSelector((state: any) => state.Notifications)
  const dispatch = useDispatch()

  function hidePopup() {
    dispatch(NotificationActions.hideNotifications())
    // if (customFunction) {
    //   customFunction()
    // }
  }
  function handleYes() {
    dispatch(NotificationActions.hideNotifications())
    if (customFunction)
      customFunction()
  }

  if (visible) {
    return <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className="flex flex-col justify-center items-center h-screen w-full bg-modalBackdrop oveflow-hidden absolute top-0 bottom-0 left-0 right-0 z-[999] ">
        <div className="p-5 bg-white shadow-md rounded z-[1000] min-w-[30%] tab:min-w-[70%]">
          <div className="flex justify-between items-center">
            <p className="text-royalBlue font-medium text-base">{title}</p>
            {hideClose ? null : <button type="button" onClick={hidePopup}>
              <CloseIcon />
            </button>}
          </div>
          {!!Component /* && (typeof (Component) === 'function' || Object.keys(Component).length > 1) */ && <Component />}
          {!!Component === false && <p className={`mt-4 ${type == 'error' ? 'text-requiredRed' : 'text-royalBlue'}`}>{message}</p>}
          {!!Component === false && <div className="flex items-center justify-end mt-3 rounded-b">
            {
              buttons && buttons.length > 0 ? buttons.map((button: any, index: number) => <Button
                key={index}
                onClick={button.onClick}
                title={button.title} className={button.className ?? 'mr-2'}
                btnType={button.type}
                disabled={button.disabled}
              />) : <Fragment>
                <Button
                  onClick={hidePopup}
                  title={'cancel'}
                  className={'mr-2'}
                  btnType={'secondary'} />
                <Button
                  onClick={() => handleYes()}
                  title={'Yes'}
                  className={'shadow-none'}
                  btnType={'primary'} />
              </Fragment>
            }
          </div>}
        </div>
      </motion.div>
    </AnimatePresence>
  }

  return <Fragment></Fragment>
}
export default CustomNotifier
