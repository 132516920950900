import CustomInput from "../../../../components/global/CustomInput";

function AdditionalInformation({ values, errors, touched, setFieldValue }: any): JSX.Element {
  return <div className="focus:outline-primary p-5 my-5 shadow-sm border border-[0.5px] rounded-md flex flex-col hover:border-primary hover:border">
    <p className="text-royalBlue font-medium text-base">Additional Information</p>
    <CustomInput
      id="additionalInformation"
      name="additionalInformation"
      label="Notes"
      required={false}
      isTextArea={true}
      placeholder={"Enter text"}
      value={values.additionalInformation}
      errors={errors}
      touched={touched}
      onChange={(e: any) => {
        setFieldValue("additionalInformation", e.currentTarget.textContent);
      }}
    // className={'-mb-5'}
    />
  </div>
}

export default AdditionalInformation