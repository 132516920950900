import * as actions from "../Actions/types";
import { SignUpFormValues } from "../Reducers/SignUpFormReducer";
import http from "../API/HttpMethods";
import { removeExtraSpace } from "../../utils/helpers/removeExtraSpace";

const updateFormValues = (values: any) => ({
  type: actions.SET_SIGNUP_VALUES,
  payload: values,
});

const resetForm = () => ({ type: actions.RESET_SIGNUP_VALUES });

const signUpUser = (options: actions.ActionArgs) => async (dispatch: any) => {
  const { data, onSuccess, onError } = options || {};
  const cbsTests: any[] = [];

  const tests = {
    scc: "SCC",
    cm: "CM",
    um: "UM",
    tc: "TC",
    ddm: "DDM",
    idg: "IDG",
  };

  Object.keys(tests).map((key: any) => {
    type ObjectKey = keyof typeof tests;
    const myKey = key as ObjectKey;

    if (data[key] == true) {
      cbsTests.push(tests[myKey]);
    }
  });

  let apiData: any = {
    org: {
      name: removeExtraSpace(data.organization_name),
      description: removeExtraSpace(data.description) ?? "",
      //  email: data.email_id,
      phone: data.phone,
      fax: data.fax,
      address: removeExtraSpace(data.address),
      address2: removeExtraSpace(data.address2),
      city: removeExtraSpace(data.city),
      state: removeExtraSpace(data.state),
      zipcode: data.zip_code,
      cbsTests,
    },
    user: {
      firstName: removeExtraSpace(data.first_name),
      lastName: removeExtraSpace(data.last_name),
      email: data.manager_email,
      roles: ["Office Manager"],
    },
  };

  if (data.role === "Both" || data.role === "Physician") {
    apiData = {
      ...apiData,
      user: {
        ...apiData.user,
        firstName: removeExtraSpace(data.firstName),
        lastName: removeExtraSpace(data.lastName),
        email: data.email_id,
        suffix: removeExtraSpace(data.suffix),
        specialty: removeExtraSpace(data.specialty),
        npi: data.npi,
        roles:
          data.role === "Both"
            ? ["Office Manager", "Physician"]
            : ["Physician"],
      },
    };
  }

  //  if (!!data.manager_email) {
  //   apiData.officeManagerData = {
  //    firstName: removeExtraSpace(data.first_name),
  //    lastName: removeExtraSpace(data.last_name),
  //    email: data.manager_email,
  //    //  isPhysician: data.isPhysician ? 1 : 0,
  //    //    primaryPhone: data.manager_phone,
  //    //    streetAddress: data.manger_address,
  //    //    fax: data.manager_fax,
  //    //    city: data.manager_city,
  //    //    state: data.manager_state,
  //    //    zipcode: data.manager_zip_code,
  //   };
  //  }

  try {
    const result = await http.postSignup("/v1/auth/new-flow/register", apiData);
    const { success, statusCode, message, error, status } = result;
    //   console.log(result);
    if (error && onError != null) return onError(error);
    if (status == 200) {
      dispatch({ type: actions.RESET_SIGNUP_VALUES });
      dispatch({ type: actions.SET_SIGNUP_MESSAGE, payload: message });
      onSuccess != null && onSuccess(message);
    }
  } catch (error: any) {
    const { data: errorData } = error?.response;
    const message = errorData ? errorData.message : !!error;
    onError != null && onError(message);
  }
};

const SignUpFormActions = {
  updateFormValues,
  resetForm,
  signUpUser,
};

export default SignUpFormActions;
