import { useDispatch, useSelector } from "react-redux"
import dataConstants from "../../../constants/dataConstants"
import CustomInput from "../../global/CustomInput"
import ButtonStateActions from "../../../Store/Actions/ButtonStateActions"
import { missingTests } from "../../../utils/helpers/missingTestsValidator"
import TestsListLoadingIndicator from "../../global/LoadingIndicators/TestsListLoadingIndicator"
import ActiveTest from "../../../models/activetest.type"

interface TestsListProps {
  isPage: boolean
  touched: any
  errors: any
  values: any
  isValid: boolean
  setFieldValue: (name: string, value: boolean) => void
}

function filterList(checkbox: any, activeTests: ActiveTest[]) {
  let found = activeTests?.find((item: ActiveTest) => item.code.toLowerCase() === checkbox.name)
  return found && found.isActive
}

function TestsList({ isPage, touched, errors, values, setFieldValue, isValid }: TestsListProps): JSX.Element {
  const dispatch = useDispatch()
  const { fetching, error, activeTests } = useSelector((state: any) => state?.ActiveTests)

  if (fetching)
    return <TestsListLoadingIndicator />

  if (activeTests?.length == 0)
    return <div className="py-2">
      <p className="text-royalBlue text-sm">Please activate test(s) in order to proceed</p>
    </div>

  return <div role="group" aria-labelledby="checkbox-group" className={`flex my-2 ${!isPage && 'pb-5'} overflow-auto flex-wrap`}>
    {
      dataConstants.TestsData.map((item: any) => {
        const dataLength = item.tests.filter((checkbox: any) => filterList(checkbox, activeTests)).length
        if (dataLength > 0)
          return <div key={item.type} className={`xl:mr-0 2xl:mr-0 lg:mr-5 ml-1 mb-2`}>
            <p className='text-sm mb-2 whitespace-nowrap'>{item.type}</p>
            <div className='flex flex-col items-start'>
              {item.tests.filter((checkbox: any) => filterList(checkbox, activeTests)).map((checkbox: any, index: number) => <CustomInput
                id={checkbox.label}
                key={checkbox.label}
                name={checkbox.name}
                label={checkbox.label}
                required={false}
                placeholder={''}
                errors={errors}
                showToolTip
                tooltipText={checkbox.tooltip}
                checked={values[checkbox.name]}
                touched={touched}
                onChange={(e: any) => {
                  setFieldValue(checkbox.name, e.target.checked)
                  if (!isPage) {
                    // previous values are checked with newly toggled checkbox to avoid invalid results.
                    const updatedValues = { ...values, [checkbox.name]: e.target.checked }
                    dispatch(ButtonStateActions.toggleSumbit(!missingTests(updatedValues) && isValid))
                  }
                }}
                type='checkbox'
                className='my-1'
                isPage={isPage}
                styleCheckbox
              />

              )}
            </div>
          </div>
        return null
      })
    }
  </div>
}

export default TestsList