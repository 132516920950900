import { useEffect, useRef, useState } from "react";
import pageStyle from "../../constants/pageStyle";
import OnlineOrderPDF from "../organism/OnlineOrderForm/OnlineOrderPDF";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import http from "../../Store/API/HttpMethods";
import toastMessages from "../../utils/helpers/toastMessage";
import { useOktaAuth } from "@okta/okta-react";
import orderResponseFormatter from "../../utils/helpers/orderResponseFormatter";

interface PDFPrintProps {
  ids: string[],
  onStart: () => void,
  onSuccess: () => void,
  onError: (message: string) => void
}

function PDFPrint({ ids, onStart, onError, onSuccess }: PDFPrintProps): any {
  const [orders, setOrders] = useState<any>([])
  const [content, setContent] = useState<any>(null)
  const [visible, setVisible] = useState<boolean>(false)
  const componentRef = useRef(null)
  const { authState } = useOktaAuth()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
    onAfterPrint: () => {
      onSuccess()
      setContent(null)
      setVisible(false)
    },
    copyStyles: false
  })

  async function getMultipleOrders() {
    if (ids.length === 0) {
      setContent(null)
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`
        }
      }
      onStart()
      setContent(null)
      try {
        const response = await http.getMultipleOrders(`v1/orders/multiple-orders?orderIds=${ids.map((id: string, index: number) => `${id}${index !== 0 && index + 1 !== ids.length ? ',' : ''}`)}`, config)
        const { orders } = response?.data
        setOrders(orders)
        setContent(true)
        onSuccess()
      } catch (error: any) {
        setContent(null)
        onError(error?.message ? error.message : 'Failed printing order')
      }
    }

  }

  useEffect(() => {
    getMultipleOrders()
  }, [ids])

  useEffect(() => {
    if (content) {
      setVisible(true)
      handlePrint()
      setVisible(false)
    }
    return () => {
      setContent(null)
    }
  }, [content])

  return <div id="printPDFNewFlow" ref={componentRef} className={visible ? '' : 'hidden'}>
    {orders?.map((order: any, index: number) => <OnlineOrderPDF
      key={order.id}
      addPageBreak={index !== 0}
      // addPageBreak={index !== 0 && singlePrint == false && screen !== 'test-orders'}
      isVisible
      formId={''}
      values={orderResponseFormatter(order)}
      isApproval
    />)}
  </div>
}

export default PDFPrint