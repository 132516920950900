const pageStyle = `
.asd {
  background-color: red;
}
  @media all {
    .flex-col {
  flex-direction: column;
}
*{
  padding:0;
  margin:0;
}
.h-\[60vh\] {
  height: 60vh;
}
    @page { margin: 0; }
    .col-span-4 {
  grid-column: span 4 / span 4;
}
.pb-14 {
  padding-bottom: 3.5rem/* 56px */;
}
.px-10 {
  padding-left: 2.5rem/* 40px */;
  padding-right: 2.5rem/* 40px */;
}
.mb-6 {
  margin-bottom: 1.5rem/* 24px */;
}
.text-royalBlue {
  --tw-text-opacity: 1;
  color:#172B4D; 
}
.text-base {
  font-size: 1rem/* 16px */ !important;
  line-height: 1.5rem/* 24px */ !important;
}
.mb-3 {
  margin-bottom: 0.75rem/* 12px */;
}
.mb-11 {
  margin-bottom: 2.75rem/* 44px */;
}
.w-1/5 {
  width: 20%;
  color:black;
}
.extraClass{
  width:20%;
  border-color:black;
  border-bottom-width: 2px;
}
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.justify-start {
  justify-content: flex-start;
}
.items-center {
  align-items: center;
}
.items-start{
  align-items: flex-start;
}
.mr-6 {
  margin-right: 1.5rem/* 24px */;
}
.mb-2 {
  margin-bottom: 0.375rem/* 6px */;
}
.my-1 {
  margin-top: 0.25rem/* 4px */;
  margin-bottom: 0.25rem/* 4px */;
}
.my-2 {
  margin-top: 0.5rem/* 4px */;
  margin-bottom: 0.5rem/* 4px */;
}
.my-5{
  margin-top: 1.25rem; /* 20px */
  margin-bottom: 1.25rem; /* 20px */
}
.mr-2 {
  margin-right: 0.5rem/* 8px */;
}
.mt-4{
  margin-top: 1.25rem;
}
    body { margin: 1.5rem;  color:#172B4D; }
    .page-break {
      display:block;
      page-break-before: always;
    }
    .break-before-page {
      break-before: page;
    }
    .my-6 {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem
    }
    .font-medium {
      font-family:"AvenirNext-Medium"
      font-weight: 500;
    }
    
    .uppercase{
      text-transform: uppercase;
    }
    .capitalize{
      text-transform: capitalize;
    }
    .border-b-2{
      border-bottom-width: 2px;
    }
    .border-black{
      border-color: rgb(0 0 0);
    }
    .h-\[70vh\] {
      height: 70vh;
    }
  }

  @media print {
    *{
      padding:0;
      margin:0;
    }
    
    .flex-col {
  flex-direction: column;
}
.h-\[60vh\] {
  height: 60vh;
}
.h-\[70vh\] {
  height: 70vh;
}
    @page { margin: 0; }
      .col-span-4 {
      grid-column: span 4 / span 4;
    }
    .pb-14 {
      padding-bottom: 3.5rem/* 56px */;
    }
    .px-10 {
      padding-left: 2.5rem/* 40px */;
      padding-right: 2.5rem/* 40px */;
    }
    .mb-6 {
      margin-bottom: 1.5rem/* 24px */;
    }
    .mb-2 {
      margin-bottom: 0.375rem/* 6px */;
    }
    .text-royalBlue {
      --tw-text-opacity: 1;
      color:#172B4D;
    }
    .text-base {
      font-size: 1rem/* 16px */ !important;
      line-height: 1.5rem/* 24px */ !important;
    }
    .mb-3 {
      margin-bottom: 0.75rem/* 12px */;
    }
    .mb-11 {
      margin-bottom: 2.75rem/* 44px */;
    }
    .w-1/5 {
      width: 20%;
      color:black;
    }
    
.extraClass{
  width:20%;
  border-color:black;
  border-bottom-width: 2px;
}
    .flex {
      display: flex;
    }
    .justify-between {
      justify-content: space-between;
    }
    .justify-start {
      justify-content: flex-start;
    }
    .items-center {
      align-items: center;
    }
   .items-start{
      align-items: flex-start;
    }
    .mr-6 {
      margin-right: 1.5rem/* 24px */;
    }
    .my-1 {
      margin-top: 0.25rem/* 4px */;
      margin-bottom: 0.25rem/* 4px */;
    }   
    .my-5{
      margin-top: 1.25rem; /* 20px */
      margin-bottom: 1.25rem; /* 20px */
    }
    .my-2 {
      margin-top: 0.5rem/* 4px */;
      margin-bottom: 0.5rem/* 4px */;
    }
    .mr-2 {
      margin-right: 0.5rem/* 8px */;
    }
    body { margin: 1.5rem; color:#172B4D;}
    .page-break {
      display:block;
      page-break-before: always;
    }
    .break-before-page {
      break-before: page;
    }
    .my-6 {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem
    }
    .font-medium {
      font-family:"AvenirNext-Medium"
      font-weight: 500;
    }
    .uppercase{
      text-transform: uppercase;
    }
    .capitalize{
      text-transform: capitalize;
    }
    .mt-4{
      margin-top: 1.25rem;
    }
    .border-b-2{
      border-bottom-width: 2px;
    }
    .border-black{
      border-color: rgb(0 0 0);
    }
  }
`;

export default pageStyle;
