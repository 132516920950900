import { Fragment, useEffect, useState } from 'react'
import Checkbox from '../Checkbox/Checkbox'
import ThreeDotVerticalMenu from '../ThreeDotVerticalMenu/ThreeDotVerticalMenu'
import donwloadIcon from '../../assets/svg/download.svg'
import downloadReceipt from '../../assets/svg/Download Receipt.svg'
import uploadIcon from '../../assets/svg/upload.svg'
import editIcon from '../../assets/svg/edit.svg'
// import { useNavigate } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import CustomImage from '../global/CustomImage'
import Button from '../atomic/Button'
import ReactTooltip from "react-tooltip";
import { SortableItem } from '../../Pages/Admin/Documents/SortableItem'
import LoadingSvg from '../../assets/svg/LoadingSVG'
import InfoIcon from '../../assets/svg/InfoIcon'
import DropdownSOF from './DropdownSOF'
import moment from 'moment'
import { useSelector } from 'react-redux'
import RequiredFieldTooltip from '../global/RequiredFieldTooltip'
import CustomPopup from '../global/CustomPopup'
import DownloadHelper from '../../utils/helpers/fileDownloader'
import toastMessages from '../../utils/helpers/toastMessage'
import http from '../../Store/API/HttpMethods'
import { useOktaAuth } from "@okta/okta-react";
import TooltipHelper from '../global/TooltipHelper'

function Action({ onClick, type, submitting, printing, disabled }: any) {
    if (type === 'select')
        return <Button title='Select' submitting={submitting} disabled={submitting} btnType='transparent' className='!text-xs mr-3 w-full' onClick={onClick} />
    if (type === 'remove')
        return <Button title={submitting ? 'Removing' : 'Remove'} btnType='transparent' className='!text-xs mr-3 w-full' onClick={onClick} disabled={submitting} submitting={submitting} />

    if (type === 'viewOrder') {
        return <Button title='View' btnType='transparent' className='!text-xs mr-3 w-full' onClick={onClick} />
    }

    if (type === 'downloadReport') {
        return <Button submitting={submitting} disabled={submitting} title={submitting ? 'Downloading' : 'Download'} btnType='transparent' className='!text-xs mx-3' onClick={onClick} />
    }
    if (type === 'print') {
        return <Button title={printing ? 'printing' : 'print'} btnType='transparent' disabled={printing} submitting={printing} className='!text-xs' onClick={onClick} />
    }
    if (type == 'authorize') {
        return <Button title='authorize' btnType='transparent' className='!text-xs mr-3' onClick={onClick} />
    }
    if (type == 'download order') {
        return <button type="button" className="px-2 py-3" onClick={onClick}>
            {submitting ? <LoadingSvg /* stroke={'#18b5b7'} */ isSecondary className="animate-spin" /> :
                <img
                    data-tip={'Download Order'}
                    data-iscapture="true"
                    src={downloadReceipt} alt="not found" />
            }
        </button>
    }
    if (type == 'download receipt') {
        return <button type="button" className="px-2 py-3" onClick={onClick}>
            {submitting ? <LoadingSvg /* stroke={'#18b5b7'} */ isSecondary className="animate-spin" /> :
                <img
                    data-tip={'Download Receipt'}
                    data-iscapture="true"
                    src={downloadReceipt} alt="not found" />
            }
        </button>
    }
    if (type == 'download report') {
        return <button type="button" className="px-2 py-3" onClick={onClick}>
            {submitting ? <LoadingSvg /* stroke={'#18b5b7'} */ isSecondary className="animate-spin" /> :
                <img
                    data-tip={'Download Report'}
                    data-iscapture="true"
                    src={donwloadIcon} alt="not found" />}
        </button>
    }

    if (type == 'upload') {
        return <button type="button" className="px-2 py-3" onClick={onClick}>
            <img
                data-tip={'Upload Documents'}
                data-iscapture="true"
                src={uploadIcon} alt="not found" />
        </button>
    }
    if (type == 'edit') {
        return <button type="button" className="px-2 py-3" onClick={onClick}>
            <img
                data-tip={'Resume'}
                data-iscapture="true"
                src={editIcon} alt="not found" />
        </button>
    }

    if (type == 'Cancel') {
        return <Button title={submitting ? 'Cancelling Pick-up' : 'Cancel Pick-up'}
            disabled={submitting || disabled}
            submitting={submitting}
            btnType='transparent'
            className={'!text-xs self-center text-left w-full'}
            onClick={onClick}
        />
    }
    return null
}

function DataTableItem({ item, parentIndex, body, showCheckbox, selectOnIndex, handleCheckbox, visibleFields, onClick, actionClick, showNumbering, externalCheckbox, hasVerticalMenu, handleSOF }: any) {
    //   const navigate = useNavigate()
    const history = useHistory()
    const [showPopup, setshowPopup] = useState(false)
    const [downloadingOrderAgreement, setdownloadingOrderAgreement] = useState<boolean>(false)
    const { authState } = useOktaAuth()
    const [documentLink, setDocumentLink] = useState<any>(null)
    const [fetchingDocument, setFetchingDocument] = useState(true)
    const expiryDate = moment(item?.data?.SOF?.expiryDate).format('MM-DD-YYYY')
    const { profile } = useSelector((state: any) => state.Profile);
    const location = useLocation()

    function handleActionClick(e: any, type: any) {
        e.stopPropagation()
        actionClick({ type, item, index: parentIndex })
    }

    function renderActions(actions: any[], key: string, status?: string, orderId?: any) {
        return <SortableItem id="actions" className={`${(parentIndex + 1) == body.length ? '!border-none' : 'border-b border-[#dcdfe4]'} !p-0 !m-0 ${item?.stickyClass?.find((stickyClass: any) => stickyClass.key.toLowerCase() === key.toLowerCase())?.value}`}>{/* <td className={`${(parentIndex + 1) == body.length ? '!border-none' : 'border-b border-[#dcdfe4]'} !p-0 !m-0`}> */}
            {
                !!actions && actions?.length > 0
                    ? <div className={`flex justify-end mr-2 !border-b-0`}>
                        {actions?.map((action, index) => (<Action
                            key={action + index}
                            type={action}
                            submitting={item.downloading}
                            disabled={item.disabled}
                            printing={item.printing}
                            onClick={(e: any) => handleActionClick(e, action)}
                        />))}
                        <TooltipHelper
                            // id={'action-tooltip'}
                            backgroundColor={'#18B5B7'}
                            className={'!text-xs !p-2 '}
                            wrapper="span"
                            // type={'dark'}
                            place={'left'}
                            effect={'float'}
                            scrollHide={true}
                            multiline={true}
                        />
                    </div>
                    : <div className="py-4" />
            }
        </SortableItem>
        {/* </td> */ }
    }
    function renderStatus(status: string, key: string) {
        let bgColors: any = {
            'blocked': '#D0D4DB',
            'suspended': '#D0D4DB',
            'pending approval': '#FED7D7',
            'p.d.r.': '#F5E1DF',
            'pending submission': '#fff2dc',
            'in progress': '#d7defe',
            'pending results': '#ecd7fe',
            'pending lab results': '#ecd7fe',
            'pending tissue receipt': '#fed7ec',
            'pending receipt of tissue': '#fed7ec',
            'submitted': '#cbeeef',
            'completed': '#ccf1df',
            'active': '#CCF1DF',
            'dispatched': '#fed7ec',
            // 'pending': '#9bd1ff'
            'pending': '#cae6fe',
            'cancelled': '#FED7D7',
            'n/a': '#D0D4DB',
            'on hold': '#D0D4DB',
            'to_be_scheduled': 'transparent',
            'pick_up_completed': 'transparent',
            'pickup_scheduled': 'transparent',
            'pick_up_cancelled': 'transparent',
            'scheduling_failed': 'transparent'
        }
        let textColors: any = {
            'blocked': '#647188',
            'suspended': '#647188',
            'pending approval': '#FE4F4F',
            'p.d.r': '#D37C71',
            'pending submission': '#FE954F',
            'in progress': '#4F6CFE',
            'pending results': '#AD4FFE',
            'pending lab results': '#AD4FFE',
            'pending tissue receipt': '#FE4FAC',
            'pending receipt of tissue': '#FE4FAC',
            'submitted': '#18B5B7',
            'completed': '#1BC472',
            'active': '#1BC472',
            'dispatched': '#FE4FAC',
            'pending': '#064e8b',
            'cancelled': '#FE4F4F',
            'n/a': '#647188',
            'on hold': '#535353',
            // 'pick-up scheduled': '#084E8B',
            // 'pick-up completed': '#18B5B7',
            // 'pick-up cancelled': '#FE4F4F',
            // 'scheduling failed': '#647188'
            'to_be_scheduled': '#084E8B',
            'pickup_scheduled': '#084E8B',
            'pick_up_completed': '#18B5B7',
            'pick_up_cancelled': '#FE4F4F',
            'scheduling_failed': '#FE4F4F'
        }
        const statusMappings: { [key: string]: string } = {
            'PICKUP_SCHEDULED': 'PICK-UP SCHEDULED',
            'PICK_UP_COMPLETED': 'PICK-UP COMPLETED',
            'PICK_UP_CANCELLED': 'PICK-UP CANCELLED',
            'SCHEDULING_FAILED': 'SCHEDULING FAILED'
        };
        const displayStatus = statusMappings[status] || status;
        // if (status.toLocaleLowerCase() == 'blocked' || status.toLocaleLowerCase() == 'suspended') {
        //     return <td className={`${(parentIndex + 1) == body.length ? '!border-none' : ''}`}>
        //         <span className={'bg-[#D0D4DB] py-1  block px-2 rounded-full capitalize text-[#647188]'}>
        //             {status}
        //         </span>
        //     </td>
        // }
        return <SortableItem id={'status'} className={`${location?.pathname === '/shipments/unshipped-orders' && 'min-w-[200px]'} ${(parentIndex + 1) == body.length ? '!border-none' : ''} ${item?.stickyClass?.find((stickyClass: any) => stickyClass.key.toLowerCase() === key.toLowerCase())?.value}`}> {/* <td className={`${(parentIndex + 1) == body.length ? '!border-none' : ''}`}> */}
            <span style={{
                backgroundColor: bgColors[status.toLowerCase()],
                color: textColors[status.toLowerCase()]
            }} className={`py-1 block w-max px-2 rounded-full capitalize text-center ${status?.toLowerCase?.().includes('pick-up') === false ? '!text-xs' : ''} ${status?.toLowerCase()?.includes('pending') ? 'w-max' : ''}`}>
                {displayStatus}
            </span>
        </SortableItem >
        // </td>
    }
    function renderLabel(label: any | number, key: string) {
        return <SortableItem id={'status'} className={` text-[#084E8B] underline ${(parentIndex + 1) == body.length ? '!border-none' : ''} ${item?.stickyClass?.find((stickyClass: any) => stickyClass.key.toLowerCase() === key.toLowerCase())?.value}`}> {/* <td className={`${(parentIndex + 1) == body.length ? '!border-none' : ''}`}> */}
            {/* {label?.viewableUrl ? <a href={label?.viewableUrl} target="_blank">{label?.labelUrl?.split?.('/')?.[1]}</a> : 'N/A'} */}
            {label?.viewableUrl ? <a href={label?.viewableUrl} target="_blank">{!!label?.labelTitle ? label.labelTitle : 'N/A'}</a> : 'N/A'}
        </SortableItem >
    }
    function onRowClick() {
        if (onClick)
            onClick(item.id, history)
    }
    function SOFClick({ type, item }: { type: 'create' | 'update' | 'view' | 'delete' | string, item: any }) {
        handleSOF({ item, type })
    }
    useEffect(() => {
        ReactTooltip.rebuild()
    }, [])



    const hidePopup = () => {
        setshowPopup(false)
    }


    return <tr
        onClick={onRowClick}
        className={item.check ? 'active' : ''} key={parentIndex}>
        {showNumbering &&
            <td id={item.id} className={`${(parentIndex + 1) == body.length ? '!border-none' : ''} `}>{parentIndex + 1}</td>
        }

        {item.content && (
            <td>
                {Object.keys(item.content).map((key, index) => {
                    const { value, style } = item.content[key]
                    return index === 0
                        ? null
                        : (
                            <Fragment key={index}>
                                <span className="key-name">
                                    {key + ':'}
                                </span>{' '}
                                <span style={style}>
                                    {value || item.content[key] + ' '}
                                </span>
                            </Fragment>
                        )
                })}
            </td>
        )}
        {
            externalCheckbox && <td>
                <Checkbox
                    NavLink={''}
                    checked={item.check}
                    onChange={() => selectOnIndex(item.check, parentIndex)}
                />
            </td>
        }
        {item.data &&
            Object.keys(item.data).map((key, index) => {
                if (!item.data[key]) return null

                const { value, style, img, isCheckbox, disabled } = item.data[key]
                const findIfHidden = visibleFields?.find((item: any) => item.key === key)?.selected === false

                if (findIfHidden) { return null }

                if (key === 'name') {
                    return <Fragment key={index} >
                        {/* <td className={`${(parentIndex + 1) == body.length ? '!border-none' : ''} `}> */}
                        <SortableItem id={key} className={`${(parentIndex + 1) == body.length ? '!border-none' : ''} `}>
                            <div className={`${showCheckbox ? 'flex items-center' : ''}`}>
                                {showCheckbox && !!externalCheckbox == false && <div className='w-1/6'>
                                    < Checkbox
                                        NavLink={''}
                                        checked={item.check}
                                        onChange={() => selectOnIndex(item.check, parentIndex)}
                                    /></div>}
                                <span className="flex items-center capitalize text-[#084E8B] font-normal w-5/6">
                                    <CustomImage src={img} className="h-5 w-5 rounded-full mx-1" alt={value} />
                                    {/* <img
                                        className="h-5 w-5 rounded-full mr-3"
                                        src={img} /> */}

                                    {value || item?.data[key]}
                                </span>
                            </div>
                        </SortableItem>
                    </Fragment>
                }
                // if (key === 'patientName') {
                //     return <Fragment key={index} >
                //         {/* <td className={`${(parentIndex + 1) == body.length ? '!border-none' : ''} `}> */}
                //         <SortableItem id={key} className={`${(parentIndex + 1) == body.length ? '!border-none' : ''} `}>
                //             <div onClick={e => {
                //                 showCheckbox && !!externalCheckbox == false ? e.stopPropagation() : null
                //             }} className={`${showCheckbox ? 'flex items-center' : ''}`}>
                //                 {showCheckbox && !!externalCheckbox == false && <Checkbox
                //                     NavLink={''}
                //                     checked={item.check}
                //                     onChange={(e) => {
                //                         selectOnIndex(item.check, parentIndex)
                //                     }}
                //                 />}
                //                 <span className="flex items-center capitalize text-[#084E8B] font-normal">
                //                     {value || item?.data[key]}
                //                 </span>
                //             </div>
                //         </SortableItem>
                //     </Fragment>
                // }

                if (key === 'actions') {
                    return <Fragment key={index}>
                        {renderActions(item?.data[key], key, item?.data?.status, item?.id)}
                    </Fragment>
                }

                if (key === 'viewOrder') {
                    return <Fragment key={index}>
                        {renderActions(['viewOrder'], key)}
                    </Fragment>
                }

                if (key === 'status') {
                    return <Fragment key={index}>
                        {renderStatus(item.data[key], key)}
                    </Fragment>
                }

                if (key === 'label' || key === 'labelTitle') {
                    return <Fragment key={index}>
                        {renderLabel(item.data[key], key)}
                    </Fragment>
                }

                if (key === "placedBy") {
                    return <SortableItem id="actions" className={`${(parentIndex + 1) == body.length ? '!border-none' : 'border-b border-[#dcdfe4]'} !p-0 !m-0 `}>
                        <div className="flex justify-start mx-5 !border-b-0 py-1.5">
                            {Array.isArray(item.data.placedBy) && item?.data?.placedBy?.length > 1 ? item?.data?.placedBy[0] + ', ' + item?.data?.placedBy[1] : item?.data?.placedBy}
                        </div>
                    </SortableItem>
                }
                if (key === 'role') {
                    return <SortableItem id="actions" className={`${(parentIndex + 1) == body.length ? '!border-none' : 'border-b border-[#dcdfe4]'} !p-0 !m-0 `}>
                        <div className="flex justify-start mx-5 !border-b-0 py-1.5">
                            {item?.data?.role?.length > 1 ? item?.data?.role[0] + ', ' + item?.data?.role[1] : item?.data?.role}
                        </div>
                    </SortableItem>
                }
                if (key === 'roles') {
                    return <SortableItem id="actions" className={`${(parentIndex + 1) == body.length ? '!border-none' : 'border-b border-[#dcdfe4]'} !p-0 !m-0 `}>
                        <div className="flex justify-start mx-5 !border-b-0 py-1.5">
                            {item?.data?.roles?.length > 1 ? item?.data?.roles[0] + ', ' + item?.data?.roles[1] : item?.data?.roles}
                        </div>
                    </SortableItem>
                }
                if (key === 'pathLab') {
                    return <SortableItem id="actions" className={`${(parentIndex + 1) == body.length ? '!border-none' : 'border-b border-[#dcdfe4]'} !p-0 !m-0`}>
                        <div className="flex justify-start mx-5 !border-b-0 py-1.5">
                            {item?.data?.pathLab?.id ? item?.data?.pathLab?.name : 'N/A'}
                        </div>
                    </SortableItem>
                }
                if (key === 'SOF')
                    return <SortableItem key={item?.data?.SOF?.id} id="actions" className={`${(parentIndex + 1) == body.length ? '!border-none' : 'border-b border-[#dcdfe4]'} !p-0 !m-0`}>

                        <div className="flex justify-start mx-5 !border-b-0 py-1.5">
                            {
                                item?.data?.SOF?.id ?

                                    <DropdownSOF options={[
                                        { label: 'Update', labelValue: 'update' },
                                        { label: 'View', labelValue: 'view' },
                                        { label: 'Delete', labelValue: 'delete' },
                                    ]}
                                        getValueOfSof={(value: string) => {
                                            if (value === 'update' && item?.data?.SOF?.isUpdated) {
                                                return SOFClick({ type: 'update-req-pending', item })
                                            }
                                            SOFClick({ type: value, item })
                                        }}
                                        text='Signature on File (SOF) Actions'
                                        expiryDate={expiryDate}
                                    // disabled={item?.data?.SOF?.status.toLowerCase() == 'pending'}
                                    />
                                    :
                                    // <RequiredFieldTooltip id={'Create Signature on File (SOF)'} required={true} tooltipText='An active signature on file will allow authorized office staff to order specific Castle tests on behalf of the clinician.'>
                                    <RequiredFieldTooltip id={'Create Signature on File (SOF)'} required={true} tooltipText='New SOF (signature on file) creation has been disabled. Please use Clinician Approval workflow (option B in Ordering Instructions Guide) and reach out if you have any questions.'>
                                        <Button
                                            title='Create Signature on File (SOF)'
                                            btnType='transparent'
                                            className='-ml-1'
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                SOFClick({ type: 'create', item })
                                            }}
                                            disabled />
                                    </RequiredFieldTooltip>
                            }
                        </div>

                    </SortableItem>
                if (key === 'OA') {
                    return <SortableItem id="actions" className={`${(parentIndex + 1) == body.length ? '!border-none' : 'border-b border-[#dcdfe4]'} !p-0 !m-0`}>
                        <div className="flex justify-start mx-1 !border-b-0 py-1.5">
                            {item?.data?.OA?.id ?
                                <div className='flex '>
                                    <DropdownSOF
                                        options={[
                                            { label: 'View', labelValue: 'view' },
                                            { label: 'Delete', labelValue: 'delete' },
                                        ]}
                                        getValueOfSof={(value: string) => {
                                            SOFClick({ type: value, item: { ...item, parentIndex } })
                                        }}
                                        text='ORDER AGREEMENT ACTIONS'
                                        OAFlow
                                    />

                                    <p className={`pt-1.5 text-xs text-[#647188] pl-3 pr-4`}> {disabled ? '(Pending Approval)' : `(Expiry date : ${item?.data?.OA?.expiryDate?.replaceAll('-', '/')})`}</p>
                                </div>
                                :
                                <Button
                                    btnType="transparent"
                                    onClick={() => console.log('d')}
                                    title={'Download Order Agreement'}
                                    type="button"
                                    className={'py-[5px] px-[15px]'} />}
                        </div>
                    </SortableItem>
                }

                return /* index === 0 ? null :  */(
                    <Fragment key={index}>
                        {/* <td className={`${(parentIndex + 1) == body.length ? '!border-none ' : ''}  `}> */}
                        <SortableItem data-openreplay-obscured={item?.sensitiveFields?.find((field: string) => field === key) ? true : false} id={key} className={`${(parentIndex + 1) == body.length ? '!border-none ' : ''} `}>
                            {isCheckbox ? <div className="flex flex-col items-center">
                                <Checkbox
                                    NavLink={''}
                                    checked={value}
                                    disabled={disabled}
                                    onChange={handleCheckbox ? (e: any) => handleCheckbox(key, e.target.value, parentIndex) : undefined}
                                // onChange={item.handleCheckbox ? (e) => item.handleCheckbox(key, e.target.value, parentIndex) : undefined}
                                />
                            </div>
                                : <div className={item?.tooltips?.length > 0 ? 'flex justify-start items-center' : ''}>
                                    <span className={`key-name ${key === 'email' ? '!lowercase' : ''}`} style={style}>
                                        {value || item.data[key]}
                                    </span>
                                    {item?.tooltips?.length > 0 && item?.tooltips?.find((tooltip: any) => tooltip.key.toLowerCase() === key.toLowerCase()) && <Fragment>
                                        <div
                                            // title={text}
                                            data-for={item?.data[key].toLowerCase()}
                                            data-tip={item?.tooltips?.find((tooltip: any) => tooltip.key.toLowerCase() === key.toLowerCase())?.value}
                                            data-iscapture="true"
                                            // onMouseOver={() => { setTooltipVisible(true) }}
                                            // onMouseLeave={() => { setTooltipVisible(false) }}
                                            style={{ height: 15, width: 15 }}
                                            className={'self-end cursor-pointer rounded-full text-center text-white flex flex-col justify-center items-center font-medium text-xs'}
                                        >

                                            <InfoIcon />
                                        </div>

                                        <TooltipHelper
                                            id={item.data[key].toLowerCase()}
                                            place={'left'}
                                            effect={'float'}
                                            // type={'dark'}
                                            backgroundColor={'#18B5B7'}
                                            className={'!text-xs !p-2 max-w-[300px]'}
                                            scrollHide={true}
                                            multiline={true}
                                        />
                                    </Fragment>}
                                </div>
                            }

                        </SortableItem>

                    </Fragment>
                )
            })}
        {
            item.threeDotsActions && item?.threeDotsActions?.length > 0 ? <td className={`${(parentIndex + 1) == body.length ? '!border-none' : ''}`}>

                <ThreeDotVerticalMenu
                    position="position-bottom-left"
                    actions={item.threeDotsActions}
                    portaluserHeader
                />
            </td> : hasVerticalMenu && <td className={`${(parentIndex + 1) == body.length ? '!border-none' : ''}`} />
        }

    </tr >
}

export default DataTableItem
