function DocumentLoadingIndicator() {
    return (
      <div className="flex w-full">
      <div className="justify-between items-center py-5 mr-12">
        <div className="p-3 my-2 bg-dropDownBorder rounded-lg mx-3 w-[180%] animate-pulse" />
        <div className="p-3 my-2 bg-dropDownBorder rounded-lg mx-3 w-[180%] animate-pulse" />
      </div>
      <div className="justify-between items-center py-5 mx-12">
        <div className="p-3 my-2 bg-dropDownBorder rounded-lg mx-3 w-[180%] animate-pulse" />
        <div className="p-3 my-2 bg-dropDownBorder rounded-lg mx-3 w-[180%] animate-pulse" />
      </div>
      <div className="justify-between items-center py-5 mx-12">
        <div className="p-3 my-2 bg-dropDownBorder rounded-lg mx-3 w-[180%] animate-pulse" />
        <div className="p-3 my-2 bg-dropDownBorder rounded-lg mx-3 w-[180%] animate-pulse" />
      </div>
      </div>
      
    );
  }
  
  export default DocumentLoadingIndicator;
  