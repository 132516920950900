import reduxActionType from "../../models/reduxActionType";
import { CREATE_PDF_ORDER, CREATE_PDF_ORDER_FAIL, CREATE_PDF_ORDER_SUCCESS, LOGOUT } from "../Actions/types";

const initialState = {
  submitting: false,
  error: false,
  success: false
}

function PDFOrderReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case CREATE_PDF_ORDER:
      return {
        ...state,
        submitting: true,
        error: false,
        success: false
      }
    case CREATE_PDF_ORDER_FAIL:
      return {
        ...state,
        submitting: false,
        error: true,
        success: false
      }
    case CREATE_PDF_ORDER_SUCCESS:
      return {
        ...state,
        submitting: false,
        error: false,
        success: true
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
export default PDFOrderReducer