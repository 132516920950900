import * as React from 'react'
import { SVGProps } from 'react'

const FileIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? 19.667} height={props.height ?? 25} {...props}>
        <g
            data-name="Group 13476"
            fill="none"
            stroke={props.stroke ?? '#18b5b7'}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                data-name="Path 5125"
                d="M12.5.5v5.333a1.333 1.333 0 0 0 1.333 1.333h5.333"
            />
            <path
                data-name="Path 5126"
                d="M16.5 24.5H3.167A2.667 2.667 0 0 1 .5 21.833V3.167A2.667 2.667 0 0 1 3.167.5H12.5l6.667 6.667v14.666A2.667 2.667 0 0 1 16.5 24.5Z"
            />
            <path data-name="Line 1255" d="M5.833 8.5h1.333" />
            <path data-name="Line 1256" d="M5.833 13.833h8" />
            <path data-name="Line 1257" d="M5.833 19.167h8" />
        </g>
    </svg>
)

export default FileIcon
