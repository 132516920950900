/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import React, { useLayoutEffect, useState, useRef } from 'react'
import { Form, Formik } from 'formik'
import Button from '../atomic/Button'
import CustomInput from '../global/CustomInput'
import ManagerSignupValidation from '../../validations/managersignupvalidation'
import { useHistory } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import SignUpFormActions from '../../Store/Actions/SignUpFormActions'
import EditMyProfileValidation from '../../validations/editmyprofilevalidation'
import ProfileActions from '../../Store/Actions/ProfileActions'
import { useOktaAuth } from '@okta/okta-react'
import toastMessages from '../../utils/helpers/toastMessage'
import { cityStateHelper } from '../../utils/helpers/cityStateHelper'
import http from '../../Store/API/HttpMethods'

interface ManagerSignUpProps {
  signUp: any
  updateUser: any
  isCompletion?: any
  handleNext?: any
}

function ManagerSignUpForm({ signUp, updateUser, isCompletion, handleNext }: ManagerSignUpProps): JSX.Element {
  const history = useHistory()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { profile } = useSelector((state: any) => state.Profile)
  const { authState } = useOktaAuth()
  const formValues = useSelector((state: any) => state.SignUpFormValues)
  const [fields, setFields] = useState<any[]>([
    // {
    //   id: 'isPhysician',
    //   disabled: false,
    //   name: 'isPhysician',
    //   label: 'I am an ordering clinician',
    //   placeholder: '',
    //   required: true,
    //   type: 'event'
    // },
    {
      id: 'first_name',
      disabled: false,
      name: isCompletion ? 'firstName' : 'first_name',
      label: 'First Name',
      placeholder: 'Enter your first name',
      required: true,
      type: 'event'
    },
    {
      id: 'last_name',
      disabled: false,
      name: isCompletion ? 'lastName' : 'last_name',
      label: 'Last Name',
      placeholder: 'Enter your last name',
      required: true,
      type: 'event'
    },
    {
      id: 'manager_email',
      disabled: isCompletion ?? false,
      name: isCompletion ? 'email_id' : 'manager_email',
      label: 'Office Manager Email ID',
      placeholder: 'Enter email ID',
      required: true,
      type: 'event'
    },
    // {
    //   id: 'manager_phone',
    //   disabled: false,
    //   name: isCompletion ? 'primaryPhone' : 'manager_phone',
    //   label: 'Phone',
    //   isTel: true,
    //   placeholder: '000-000-0000',
    //   required: true,
    //   type: 'event'
    // },
    // {
    //   id: 'manager_fax',
    //   disabled: false,
    //   name: isCompletion ? 'fax' : 'manager_fax',
    //   label: 'Fax',
    //   placeholder: '000-000-0000',
    //   isTel: true,
    //   required: isCompletion ? true : '',
    //   type: 'event'
    // },
    // {
    //   id: 'manger_address',
    //   disabled: false,
    //   name: isCompletion ? 'streetAddress' : 'manger_address',
    //   label: 'Address',
    //   placeholder: 'Enter street address',
    //   required: isCompletion ?? false,
    //   type: 'event',
    //   isTel: false
    // },
    // {
    //   id: 'manager_state',
    //   disabled: false,
    //   name: isCompletion ? 'state' : 'manager_state',
    //   label: 'State/Province',
    //   suggestions: ['California', 'Texas'],
    //   placeholder: 'Enter state or province name',
    //   required: isCompletion ?? false,
    //   // required: isCompletion ? true : '',
    //   type: 'val',
    //   isTel: false
    // },
    // {
    //   id: 'manager_city',
    //   disabled: false,
    //   name: isCompletion ? 'city' : 'manager_city',
    //   label: 'City',
    //   suggestions: ['New York', 'Las Vegas'],
    //   placeholder: 'Enter city name',
    //   required: isCompletion ?? false,
    //   type: 'val',
    //   isTel: false

    // },
    // {
    //   id: 'manager_zip_code',
    //   disabled: false,
    //   name: isCompletion ? 'zipCode' : 'manager_zip_code',
    //   label: 'Zip Code',
    //   placeholder: 'Enter zip code',
    //   required: isCompletion ?? false,
    //   type: 'event',
    //   isTel: false
    // },


  ])

  let initialValues: any
  if (isCompletion) {
    initialValues = {
      firstName: profile ? profile.firstName ? profile.firstName : '' : '',
      email_id: profile ? profile.email ? profile.email : '' : '',
      lastName: profile ? profile.lastName ? profile.lastName : '' : '',
      primaryPhone: profile ? profile.primaryPhone ? profile.primaryPhone : '' : '',
      fax: profile ? profile.fax ? profile.fax : '' : '',
      streetAddress: profile ? profile.streetAddress ? profile.streetAddress : '' : '',
      city: profile ? profile.city ? profile.city : '' : '',
      state: profile ? profile.state ? profile.state : '' : '',
      zipCode: profile ? profile.zipCode ? profile.zipCode : '' : '',
      profileImage: profile ? profile.profileImage ? profile.profileImage : '' : '',
      imageUrl: profile ? profile.imageUrl ? profile.imageUrl : '' : '',
      role: profile ? Array.isArray(profile.roles) ? profile.roles[0] : profile.roles : '',
      specialty: profile ? profile.specialty ? profile.specialty : '' : '',
      suffix: profile ? profile.suffix ? profile.suffix : '' : '',
      npi: profile ? profile.npi ? profile.npi : '' : '',
      pinCode: profile ? profile.pinCode ? profile.pinCode : '' : '',
      isPhysician: profile ? profile.isPhysician ? profile.isPhysician : false : false
    }
  } else {
    initialValues = {
      first_name: formValues.first_name ?? '',
      last_name: formValues.last_name ?? '',
      manager_email: formValues.manager_email ?? '',
      // manager_phone: formValues.manger_phone ?? '',
      // manager_fax: formValues.manager_fax ?? '',
      // manger_address: formValues.manger_address ?? '',
      // manager_city: formValues.manager_city ?? '',
      // manager_zip_code: formValues.manager_zip_code ?? '',
      // manager_state: formValues.manager_state ?? '',
      // isPhysician: formValues.isPhysician ?? false
    }
  }

  useLayoutEffect(() => {
    if (profile?.roles?.includes('Collaborative Order Agreement') || profile?.roles?.includes('PathLab User') || isCompletion) {
      setFields((prevState: any) => prevState.filter((field: any) => field.name !== 'isPhysician'))
    }
  }, [])

  const { data: states, error } = useSelector((state: any) => state.StateAndCity)
  const [cities, setCities] = useState([])

  const [loading, setLoading] = useState<boolean>()

  function handleSubmit(data: any): void {
    if (submitting) return

    if (!isCompletion) {
      setSubmitting(true)
      signUp({
        data: { ...formValues, ...data },
        onSuccess: (message: string) => {
          toastMessages.success(message ?? 'Organization and user has been created')
          history.push('/verify-email')
          setSubmitting(false)
        },
        onError: (message: any) => {
          toastMessages.error(message ?? 'Organization Creation Failed')
          setSubmitting(false)
        }
      })
    } else {
      setSubmitting(true)
      const apiData = {
        firstName: data.firstName ? data.firstName : '',
        lastName: data.lastName ? data.lastName : '',
        primaryPhone: data.primaryPhone ? data.primaryPhone : '',
        fax: data.fax ? data.fax : '',
        streetAddress: data.streetAddress ? data.streetAddress : "",
        city: data.city ? data.city : '',
        state: data.state ? data.state : '',
        zipCode: data.zipCode ? data.zipCode : '',
        profileImage: data.profileImage ? data.profileImage : '',
        role: Array.isArray(data.role) ? data.role[0] : data.role,
        specialty: data.specialty ? data.specialty : '',
        suffix: data.suffix ? data.suffix : '',
        npi: data.npi ? data.npi : '',
        isPhysician: data?.isPhysician,
        accessToken: authState?.accessToken?.accessToken
      }
      updateUser({
        data: { ...apiData },
        onSuccess: (message: string) => {
          toastMessages.success(message ?? 'Profile Completed')
          history.push('/dashboard', { replace: true })
        },
        onError: (message: any) => {
          toastMessages.error(message ?? 'Profile Completion Failed')
          setSubmitting(false)
        }
      })
    }
  }
  const handleSubmitAndValidate = async (data: any, formRef: any) => {
    try {
      setLoading(true);
      const requestData = { email: data?.manager_email };

      // Check if data?.manager_email is empty, and directly call handleSubmit function
      if (data?.manager_email === '') {
        handleSubmit(data);
        setLoading(false);
        return;
      }
      // const response = await http.validateEmail('v1/users/validate-email', requestData);
      // if (response.status === true) {
      handleSubmit(data);
      // }
    } catch (error: any) {
      if (formRef.current) {
        const { setFieldValue, setFieldError } = formRef.current;
        setFieldError(
          'manager_email',
          error?.response?.data?.message ? error?.response?.data?.message : 'Email verification failed'
        );
      }
      toastMessages.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const formRef = useRef<any>()

  return <Formik
    initialValues={initialValues}
    validationSchema={isCompletion ? EditMyProfileValidation : ManagerSignupValidation}
    // onSubmit={handleSubmitAndValidate}
    onSubmit={isCompletion ? handleSubmit : (data) => handleSubmitAndValidate(data, formRef)}
    innerRef={formRef}
    render={({ errors, touched, setFieldValue, values, validateForm }) => (<Form
      className="flex flex-col justify-around overflow-auto h-full">
      <div className="flex flex-col overflow-auto px-1 overflow-hidden">
        {fields.map((field, index) => {

          if (field.name === 'isPhysician') {
            return <div className='w-full flex items-start flex-col pb-3'>
              <CustomInput
                id={field.id}
                key={field.name}
                name={field.name}
                label={field.label}
                checked={values[field.name]}
                required={field.required}
                placeholder={field.placeholder}
                errors={errors}
                onChange={(e: any) => {
                  setFieldValue(field.name, e.target.checked)
                }}
                type="checkbox"
                touched={touched}
                className="self-start mt-1"
              // className="w-[83%]"
              // signupFieldsStyle={'-mt-1'}
              />
            </div>
          }
          return <CustomInput
            id={field.id}
            key={field.id}
            name={field.name}
            disabled={field.disabled}
            label={field.label}
            type={field.isTel ? 'tel' : 'text'}
            required={field.required}
            value={values[field.name]}
            suggestions={field.suggestions}
            onChange={field.type === 'event' ? (e: any) => setFieldValue(field.name, e.target.value, true) : (val: any) => setFieldValue(field.name, val, true)}
            placeholder={field.placeholder}
            errors={errors}
            touched={touched}
            className={index === 0 ? 'mt-0 w-[83%]' : 'w-[83%]'}
            signupFieldsStyle={'-mt-1'}
          />
        }
        )}
      </div>

      <div className="flex flex-col justify-around flex-1 w-[83%] mt-5 mb-3">
        <Button
          type={'submit'}
          btnType='primary'
          submitting={loading || submitting}
          title={isCompletion ? 'Done' : 'sign up'}
          className={`w-full  ${loading || submitting ? 'justify-center' : ''} ${isCompletion ? 'mt-1 mb-[34px]' : ''}`}
          onClick={() => { }}
        />
        {!isCompletion && <div className="flex items-center justify-center mt-4">
          <div className={'h-3 w-3 rounded-full bg-primaryDisabled mr-1'} />
          <div className={'h-3 w-3 rounded-full bg-primaryDisabled mr-1'} />
          <div className={'h-3 w-3 rounded-full bg-primary mr-1'} />
        </div>}
      </div>
    </Form>
    )} />
}
const mapStateToProps = (state: any): any => ({ formValues: state.SignUpFormValues })
const mapDispatchToProps = {
  signUp: SignUpFormActions.signUpUser,
  updateUser: ProfileActions.updateUser
}
export default connect(mapStateToProps, mapDispatchToProps)(ManagerSignUpForm)
