import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
// import { Outlet, useHistory } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import ButtonStateActions from '../../Store/Actions/ButtonStateActions'
import CustomInput from '../global/CustomInput'
import OrganizationsListLoadingIndicator from '../global/LoadingIndicators/OrganizationsListLoadingIndicator'
import OrganizationListItem from '../molecular/OrganizationsListItem'

function RenderList({ search, filteredList, data, error, fetching, profile }: any) {
    const dispatch = useDispatch();
    const history = useHistory()

    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const [listHeight, setListHeight] = useState(window.innerHeight - (window.innerHeight * 0.22))

    const setDimension = () => {
        if (window.innerHeight <= 370) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.65))
        } else if (window.innerHeight <= 400) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.6))
        } else if (window.innerHeight <= 450) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.55))
        } else if (window.innerHeight <= 500) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.5))
        } else if (window.innerHeight <= 530) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.46))
        } else if (window.innerHeight <= 570) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.42))
        } else if (window.innerHeight <= 600) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.4))
        } else if (window.innerHeight <= 630) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.37))
        } else if (window.innerHeight <= 655) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.36))
        } else if (window.innerHeight <= 680) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.34))
        } else if (window.innerHeight <= 700) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.3))
        } else if (window.innerHeight <= 715) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.32))
        } else if (window.innerHeight <= 750) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.33))
        } else if (window.innerHeight <= 850) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.3))
        } else if (window.innerHeight <= 850) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.3))
        } else if (window.innerHeight <= 900) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.27))
        } else {
            setListHeight(window.innerHeight - (window.innerHeight * 0.24))
        }
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        // calling to initially set list height on basis of screen height
        setDimension()
    }, [])

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    function handleItemClick(item: any) {
        return () => {
            dispatch(ButtonStateActions.toggleSumbit(false))
            dispatch(ButtonStateActions.setButtonState('organization detail'))
            let org = profile?.organizationRoles[item.id]

            if (!!org?.roles && org?.roles?.length > 0) {
                dispatch(ButtonStateActions.toggleButtonVisibility(org.roles?.includes('Office Manager') == false ? true : false))
            } else {
                dispatch(ButtonStateActions.toggleButtonVisibility(true))
            }
        }
    }
    if (search) {
        return <div><div className='overflow-auto flex flex-col' style={{ height: listHeight }}>
            {!error && !fetching && filteredList.length > 0 ? filteredList.map((item: any, index: number) => <OrganizationListItem
                id={item.id}
                isPathLab
                onClick={handleItemClick(item)}
                profile={item}
                organizationStatus={item}
                activeClassName={index == 0 ?? false}
                key={index}
            />) : <div><p className="text-royalBlue text-center py-3">No such organization found!</p></div>}
        </div>
        </div>
    }
    return <div>
        <div className='overflow-y-auto flex overflow-x-hidden flex-col ' style={{ height: listHeight }}>
            {!error && !fetching && data.map((item: any, index: number) => <OrganizationListItem
                id={item.id}
                onClick={handleItemClick(item)}
                profile={item}
                organizationStatus={item}
                isPathLab
                activeClassName={index == 0 ?? false}
                key={index}
            />
            )}
        </div>
    </div>
}

function PathLabList() {
    const { data, fetching, error } = useSelector((state: any) => state.PathLab)
    const { profile } = useSelector((state: any) => state.Profile)
    const [search, setSearch] = useState<string>('')
    const [filteredList, setFilteredList] = useState<any[]>([])

    function capitalize(word: any) {
        return word
            .split('')
            .map((letter: any, index: any) =>
                index ? letter.toLowerCase() : letter.toUpperCase(),
            )
            .join('');
    }
    function handleSearch(event: React.FormEvent<HTMLInputElement>) {
        const { value } = event.currentTarget
        // console.log(value)

        setSearch(value)
        if (value) {
            const list = data.filter((organzation: any) => organzation?.name?.toLowerCase().includes(value.toLowerCase()))
            setFilteredList(list)
        }
    }

    if (fetching && !error) { return <OrganizationsListLoadingIndicator /> }

    if (!fetching && error) {
        return <div className="col-span-1 border-r px-6 overflow-y-auto flex justify-center items-center flex-col h-full">
            <p className="text-royalBlue">Something went wrong...</p>
        </div>
    }

    return <div className="col-span-1 border-r px-6 flex flex-col overflow-auto">
        <CustomInput
            id="search"
            label="Search"
            type="text"
            name="search"
            placeholder="Search pathlab"
            withoutForm
            isInternal
            required={false}
            onChange={handleSearch}
            className="sticky top-0 mb-0"
            errors={{}}
            touched={{}}
        />
        <RenderList
            search={search}
            data={data}
            filteredList={filteredList}
            fetching={fetching}
            error={error}
            profile={profile}
        />
    </div>
}

const mapDispatchToProps = {
    setButtonState: ButtonStateActions.setButtonState
}

export default connect(null, mapDispatchToProps)(PathLabList)
