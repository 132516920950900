import * as Yup from "yup";

const InviteUserValidation = Yup.object().shape({
 email: Yup.string()
  .email("Email ID is invalid!")
  .required("Email ID is required!"),
 firstName: Yup.string().required("First name is required!").test(
  "no-empty-blank-space",
  "First Name cannot be an empty blank space(s)",
  (value: any) => {
    return value && value.trim() !== "";
  }
),
 lastName: Yup.string().required("Last name is required!").test(
  "no-empty-blank-space",
  "Last Name cannot be an empty blank space(s)",
  (value: any) => {
    return value && value.trim() !== "";
  }
),
 role: Yup.string().required("Role is required!").test(
  "no-empty-blank-space",
  "Role cannot be an empty blank space(s)",
  (value: any) => {
    return value && value.trim() !== "";
  }
),
npi: Yup.string().when("role", {
  is: "Physician",
  then: Yup.string()
    .required("NPI is required!")
    .matches(/^[0-9]{10}$/, "NPI must be a 10-digit number")
    .test(
      "no-empty-blank-space",
      "NPI cannot be an empty blank space(s)",
      (value: any) => {
        return value && value.trim() !== "";
      }
    ),
}),
specialty: Yup.string().when("role", {
  is: "Physician",
  then: Yup.string()
    .required("Speciality is required!")
    .test(
      "no-empty-blank-space",
      "Speciality cannot be an empty blank space(s)",
      (value: any) => {
        return value && value.trim() !== "";
      }
    ),
}),
suffix: Yup.string().when("role", {
  is: "Physician",
  then: Yup.string()
    .required("Suffix is required!")
    .test(
      "no-empty-blank-space",
      "Suffix cannot be an empty blank space(s)",
      (value: any) => {
        return value && value.trim() !== "";
      }
    ),
}),
 physicianIds: Yup.array().when("role", {
  is: (role: string) => {
   return role === "Office Manager";
  },
  then: Yup.array().min(1).required("Physician Id is required!"),
 }),
});

export default InviteUserValidation;
