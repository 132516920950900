import { connect, useDispatch, useSelector } from "react-redux";
import NotificationSettingsLayout from "./NotificationsSettingsLayout";
import ButtonStateActions from "../../Store/Actions/ButtonStateActions";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import OrganizationsSettingsTable from "../../components/NotificationsScreen/OrganizationsSettingsTable";
import HeaderColumnData from "../../models/notificationsSettingsTableModels/headerColumnData.model";
import BodyColumnData from "../../components/NotificationsScreen/BodyColumnData.model";
import Button from "../../components/atomic/Button";
import UpdateOrganizationSettingsPayloadItem from "../../models/notificationsModels/updateOrganizationSettingsPayloadItem";
import http from "../../Store/API/HttpMethods";
import { useOktaAuth } from "@okta/okta-react";
import UserNotificationActions from "../../Store/Actions/UserNotificationActions";
import { ActionArgs } from "../../Store/Actions/types";
import toastMessages from "../../utils/helpers/toastMessage";
import NotificationSettingsTableLoadingIndicator from "../../components/global/LoadingIndicators/NotificationsSettingsTableLoadingIndicator";
import { useHistory } from "react-router-dom";
import RouteLeavingGuard from "./RouteLeaving";

interface OrganizationSettingsProps {
    fetching: boolean
    error: boolean
    fetchOrganizationNotificationMedium: (options: ActionArgs) => void
}

function OrganisationSettings({
    fetching,
    error,
    fetchOrganizationNotificationMedium
}: OrganizationSettingsProps) {
    const { authState } = useOktaAuth()
    const { screen } = useSelector((state: any) => state.ButtonState)
    const dispatch = useDispatch()
    const [tableColumns, setTableColumns] = useState<HeaderColumnData[]>([]);
    const [tableBody, setTableBody] = useState<Array<BodyColumnData[]>>([]);
    const [initialOrganizationNotificationMediumTableBody, setInitialOrganizationNotificationMediumTableBody] = useState<Array<BodyColumnData[]>>([]);
    const [saving, setSaving] = useState<boolean>(false)

    if (initialOrganizationNotificationMediumTableBody?.length === 0 && tableBody?.length > 0) {
        // Set the initial value when it's first populated
        setInitialOrganizationNotificationMediumTableBody(tableBody);
    }

    // Use JSON.stringify for a deep comparison of arrays
    const isOrganizationNotificationSettingsChanged = JSON.stringify(tableBody) !== JSON.stringify(initialOrganizationNotificationMediumTableBody)
    const history = useHistory()
    useEffect((): any => {
        dispatch(ButtonStateActions.setButtonState('notification settings'))
    }, [fetching])

    async function handleSave() {
        let apiData: UpdateOrganizationSettingsPayloadItem[] = []
        tableBody.forEach((data) => {
            data.forEach((value) => {
                if (Array.isArray(value.data)) {
                    let organizationId = !Array.isArray(data[0].data) ? Number(data[0].data.id) : 0

                    value.data.forEach((setting) => {
                        let settingItem: UpdateOrganizationSettingsPayloadItem = {
                            organizationId,
                            notificationMediumId: Number(setting.id),
                            value: typeof (setting.value) == 'boolean' ? setting.value : false
                        }

                        apiData.push(settingItem)
                    })
                }
            })
        })
        const body = {
            data: apiData
        }

        setSaving(true)
        try {
            const response = await http.saveOrganizationNotificationsSettings('v1/organization-notification-settings', body, {
                headers: {
                    Authorization: `Bearer ${authState?.accessToken?.accessToken}`
                }
            })

            const options = {
                config: {
                    headers: {
                        Authorization: `Bearer ${authState?.accessToken?.accessToken}`
                    }
                }
            }

            if (!!response.message) {
                fetchOrganizationNotificationMedium(options)
                setInitialOrganizationNotificationMediumTableBody(tableBody);
            }

            setSaving(false)

        } catch (error: any) {
            toastMessages.error(error?.message)
            setSaving(false)
        }
    }

    return <NotificationSettingsLayout CustomButtons={() => {
        return <div>
            <Button
                title={saving ? 'Saving' : 'Save'}
                onClick={handleSave}
                btnType='primary'
                disabled={saving}
                submitting={saving}
            />
        </div>
    }}
        isSettingsChanged={isOrganizationNotificationSettingsChanged}>
        <RouteLeavingGuard
            when={isOrganizationNotificationSettingsChanged}
            navigate={(path) => {
                history.push(path);
            }}
            shouldBlockNavigation={() => {
                if (isOrganizationNotificationSettingsChanged) {
                    return true;
                }
                return false;
            }}
        />
        <AnimatePresence exitBeforeEnter>
            <motion.div
                key={screen}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
            >

                <div className="px-6">
                    <p className="text-royalBlue font-bold text-xl pb-6 py-8">Notification Medium</p>
                    {
                        fetching ?
                            <NotificationSettingsTableLoadingIndicator /> :
                            <OrganizationsSettingsTable
                                tableBody={tableBody}
                                tableColumns={tableColumns}
                                setTableBody={setTableBody}
                                setTableColumns={setTableColumns}
                            />
                    }
                </div>
            </motion.div>
        </AnimatePresence>
    </NotificationSettingsLayout>
}

const mapStateToProps = (state: any) => ({
    fetching: state.UserNotifications.fetching,
    error: state.UserNotifications.error,
})

const mapDispatchToProps = {
    fetchOrganizationNotificationMedium: UserNotificationActions.fetchOrganizationNotificationMedium
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationSettings);