import { useSelector } from "react-redux";

function Tab({
  tab,
  handleTab,
  selectedTab
}: {
  tab: { id: string, label: string };
  handleTab: any;
  selectedTab: string;
}): JSX.Element {
  return (
    <button
      onClick={handleTab(tab.id)}
      className={`flex items-center text-royalBlue text-sm lg:text-base text-left block capitalize p-1 mb-1 hover:text-activeLink hover:font-bold hover:lg:text-base hover:text-sm visited:text-royalBlue ${selectedTab === tab.id ? "font-bold text-activeLink hover:font-bold" : ""}`}
    >
      <div className={`break-normal`}>
        {tab.label}
      </div>
    </button>
  );
}

function HelpScreenTabs({
  selectedTab,
  setSelectedTab
}: any): JSX.Element {
  const videos = useSelector((state: any) => state.Help?.data?.videos);
  
  const tabs = [
    {
      id: 'documents',
      label: 'Documents',
    },
    {
      id: 'videos',
      label: 'Videos'
    },
    {
      id: 'faq',
      label: 'FAQ'
    },
  ]

  const filteredTabs = videos?.length===0
  ? tabs.filter(tab => tab.id !== 'videos')
  : tabs;

  const scroll = (tab: string): any => {
    const section: any = document.getElementById(tab);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  function handleTab(val: string) {
    return () => {
      localStorage.setItem('forceTab', val)
      setSelectedTab(val);
      scroll(val);
    };
  }

  return (
    <div className="bg-[#F9F9FA] border-r col-span-1 p-6 break-all">
      {filteredTabs.map((tab, index) => (
        <Tab
          key={tab.id}
          tab={tab}
          selectedTab={selectedTab}
          handleTab={handleTab}
        />
      ))}
    </div>
  );
}
export default HelpScreenTabs;
