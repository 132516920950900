import * as React from 'react'
import { SVGProps } from 'react'

const DropDownIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={14.367} height={8} {...props}>
        <path
            d="M7.184 5.586 12.615.293a1.045 1.045 0 0 1 1.451 0 .982.982 0 0 1 0 1.414l-6.157 6a1.045 1.045 0 0 1-1.451 0l-6.158-6A.982.982 0 0 1 .3.293a1.045 1.045 0 0 1 1.451 0Z"
            fill={props.fill ?? '#647188'}
        />
    </svg>
)

export default DropDownIcon
