import { Fragment, createRef, useEffect, useRef, useState } from "react";
import CreateSOF from "../CreateSOF";
import CustomPopup from "../global/CustomPopup";
import DownloadHelper from '../../utils/helpers/fileDownloader'
import toastMessages from "../../utils/helpers/toastMessage";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import http from "../../Store/API/HttpMethods";
import { useOktaAuth } from "@okta/okta-react";
import LoadingSvg from "../../assets/svg/LoadingSVG";
import { connect } from "react-redux";
import ProfileActions from "../../Store/Actions/ProfileActions";

interface SOFFlowProps {
  isVisible: boolean
  type: 'create' | 'update' | 'view' | 'delete' | 'update-req-pending'
  item: any
  closeSOF: () => any
  isProfileCompletion?: boolean
  setFilePath?: (url: string) => any
  profile?: any
}

function SOFFlow({ isVisible, type, item, isProfileCompletion, closeSOF, setFilePath, profile }: SOFFlowProps): JSX.Element {
  const [popupVisible, setPopupVisible] = useState<boolean>(false)
  const [downloadingSOF, setDownloadingSOF] = useState<boolean>(false)
  const { authState } = useOktaAuth()
  // const isCOA = profile?.roles?.includes('Collaborative Order Agreement')
  const isCOA = item?.data?.OA
  const [documentLink, setDocumentLink] = useState<any>(null)
  const [fetchingDocument, setFetchingDocument] = useState(true)
  const printRef: any = createRef()

  async function handleViewURL() {

    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`
      }
    }
    setFetchingDocument(true)
    try {
      let apiEndPoint;
      if (isCOA) {
        apiEndPoint = `v1/order-agreement/${item?.data?.OA?.userId}`
      } else {
        apiEndPoint = `v1/sof/view-sof/${item?.id}`;
      }
      const { statusCode, data } = await http.viewSOF(apiEndPoint, config)
      if (statusCode === 200 && data) {
        let foundOA = data?.[item?.parentIndex] ? data?.[item.parentIndex] : null
        // setDocumentLink(isCOA ? data?.filter((item: any, index: number) => index == item.parentIndex).map((e: any) => (e?.viewableURL)) : data)
        setDocumentLink(isCOA ? foundOA?.viewableURL : data)
      }
      setFetchingDocument(false)
    } catch (error: any) {
      toastMessages.error(error?.message ? error?.message : isCOA ? 'Failed to open Order Agreement' : 'Failed to open SOF')
      closeSOF()
      setFetchingDocument(false)
    }
  }

  const hidePopup = () => {
    setPopupVisible(false)
    closeSOF()
  }

  function sendEmail() {
    location.href = 'mailto:clinicalservices@castlebiosciences.com';
  }

  useEffect(() => {

    if (isVisible && type === 'view') {
      handleViewURL()
    }
    setPopupVisible(isVisible)
  }, [isVisible, type, item])

  return <Fragment>
    {popupVisible && type === 'create' && <CreateSOF isProfileCompletion={isProfileCompletion} setProfileFilePath={setFilePath} setIsCreateSofOpen={setPopupVisible} closeSOF={closeSOF} />}
    {popupVisible && type === 'update' && <CreateSOF setIsCreateSofOpen={setPopupVisible} closeSOF={closeSOF} sofID={item?.id} userOrganizationId={item?.userOrganizationId} isUpdate />}
    {type === 'delete' && <CustomPopup
      buttons={[
        { onClick: sendEmail, title: "CONTACT CS TEAM", type: "primary" },
      ]}
      hidePopup={hidePopup}
      visible={popupVisible}
      title={isCOA ? "Delete Order Agreement" : "Delete SOF"}
      deleteAndUpdateSof
      contentContainerClass={'w-2/6 tab:w-4/6'}
    >
      <p className="mt-5 text-royalBlue items-start">
        Please contact CS team to delete {isCOA ? 'Order Agreement' : 'SOF'}
      </p>
    </CustomPopup>
    }
    {type === 'update-req-pending' && <CustomPopup
      hideButtons
      hidePopup={hidePopup}
      visible={popupVisible}
      title={"Update SOF"}
      deleteAndUpdateSof
      contentContainerClass={'w-2/6'}
    >
      <p className="mt-5 text-royalBlue items-start">
        You already have submitted an update request, please wait for the response to that request.
      </p>
    </CustomPopup>}
    {type === 'view' && <CustomPopup
      hidePopup={hidePopup}
      buttons={[
        {
          submitting: downloadingSOF,
          disabled: downloadingSOF || fetchingDocument,
          onClick: () => {
            setDownloadingSOF(true)
            DownloadHelper.fileDownloader(documentLink ? (isCOA ? documentLink : documentLink?.sofBlobUrl) : '', (isCOA ? `OrderAgreement.${item?.data?.OA?.blobUrl.split('.').pop()}` : `SOFfile.${documentLink?.extension}`), () => {
              setDownloadingSOF(false)
            }, (error: any) => {
              setDownloadingSOF(false)
              toastMessages.error(error)
            },)
          },
          title: 'Download',
          type: "primary"
        },

      ]}
      visible={popupVisible}
      title={isCOA ? "View Order Agreement" : "View SOF"}
      deleteAndUpdateSof
      contentContainerClass={'!w-2/6 tab:!w-4/6'}
    >
      {fetchingDocument && <div className="flex h-[30vh] w-full justify-center items-center">
        <div className='animate-spin'>
          <img src={require('../../assets/images/loadingPNG.png')} className={'h-[50px] w-[50px]'} />
        </div>
      </div>}
      {!fetchingDocument && <embed
        src={documentLink ? (isCOA ? documentLink : documentLink?.sofBlobUrl) : ''}
        ref={printRef}
        width="250" height={"250"} className="h-[80vh] w-full object-contain" />}
    </CustomPopup>}
  </Fragment>
}

const mapStateToProps = (state: any) => ({
  profile: state.Profile.profile
})

const mapDispatchToProps = {
  fetchProfile: ProfileActions.fetchprofile,
}

export default connect(mapStateToProps, mapDispatchToProps)(SOFFlow)