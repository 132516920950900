import { AnimatePresence, motion } from 'framer-motion'
import Header from '../../../components/global/Header'
import TestOrdersTable from '../../../components/TestOrders/TestOrdersTable'
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import ButtonStateActions from '../../../Store/Actions/ButtonStateActions';


function ReferralOrdersScreen() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(ButtonStateActions.setButtonState("create order"));
  }, []);
  return <AnimatePresence exitBeforeEnter>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}

    >
      <Header
        isInternal
        title={'Referral orders'}
      />
      <div className="p-4 py-5">
        <TestOrdersTable
          screen='referral-orders'
          hideButtons={false}
        />
      </div>
    </motion.div>
  </AnimatePresence>
}

export default ReferralOrdersScreen
