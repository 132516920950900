import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import envVars from "../../env-configs";
import { io } from "socket.io-client";

function useSocket(token: any) {
  // const { authState } = useOktaAuth()
  const [socket, setSocket] = useState<any>(null)

  useEffect(() => {
    if (!!token && socket == null) {
      const socket = io(envVars.REACT_APP_SERVER_URL/* 'http://www.localhost:3001/' */, {
        auth: {
          token,
        },
        transports: ['websocket']
      })
      socket.emit('join', {})
      setSocket(socket)
    }

  }, [token])

  return socket
}

export default useSocket