import { motion } from 'framer-motion'
import { useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import CheckMarkSVG from '../../../assets/svg/CheckMarkSVG'
import Button from '../../../components/atomic/Button'
import useQuery from '../../../utils/helpers/QueryHelper'

function OrderCreated() {
  const history = useHistory()
  const query = useQuery()


  function handleDashboard() {
    history.push('/dashboard')
  }
  function handleNewOrder() {
    history.push('/test-orders/online-order')
  }

  return <motion.section
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="flex flex-col h-full justify-center items-center">
    <CheckMarkSVG />
    <div className="w-4/6 md:w-2/6 text-center p-5">
      <p className="text-royalBlue font-medium">
        {query?.get('status')?.includes('submitted') ? 'You order has been successfully submitted.' : `The clinician has been notified about your test order. Your order will be successfully submitted after the clinician’s approval.`}
      </p>
    </div>
    <div className="flex items-center">

      <Button
        btnType="secondary"
        title={'Go to dashboard'}
        onClick={handleDashboard}
        className={'mr-6'}
      />
      <Button
        btnType="primary"
        title={'order new online order'}
        onClick={handleNewOrder}
      />
    </div>
  </motion.section>
}

export default OrderCreated
