/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import React from 'react'
import { useSelector } from 'react-redux'
import Header from '../components/global/Header'
import SummaryTabs from '../components/TestOrders/SummaryTabs'

function OrderSummaryLayout({ children, headerTitle, selectedTab, setSelectedTab, showTitleLoading }: any): JSX.Element {
  const { screen } = useSelector((state: any) => state.ButtonState)

  return <div className="flex flex-col justify-between h-full lg:overflow-hidden">
    <Header
      isInternal
      showBack
      showTitleLoading={showTitleLoading}
      onBackScreen={screen === 'invite screen' ? 'portal-users' : 'UserProfile'}
      title={headerTitle ?? 'manage'}
    />
    <div className="lg:grid h-full lg:grid-cols-5 overflow-scroll lg:overflow-hidden">
      <SummaryTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <div className="col-span-4 lg:grid lg:grid-cols-4 overflow-auto">
        {children}
      </div>
    </div>
  </div>
}

export default OrderSummaryLayout
