import reduxActionType from '../../models/reduxActionType'
import { SET_BUTTON_STATE, RESET_BUTTON_STATE, TOGGLE_SUBMIT, TOGGLE_FORM_SUBMIT, TOGGLE_BUTTON_VISIBILITY, TOGGLE_SAVE_AND_EXIT, TOGGLE_SAVE_ADDITIONAL_CLINICIAN, LOGOUT } from '../Actions/types'

const initialState = {
  screen: '',
  formSubmit: false,
  submit: false,
  hideButton: false,
  saveAndExit: false,
  disableSave: false
}
function ButtonStateReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case SET_BUTTON_STATE: {
      return {
        ...state,
        screen: action.payload
      }
    }
    case TOGGLE_SUBMIT: {
      return {
        ...state,
        submit: action.payload
      }
    }
    case TOGGLE_SAVE_AND_EXIT: {
      return {
        ...state,
        saveAndExit: action.payload
      }
    }
    case TOGGLE_FORM_SUBMIT: {
      return {
        ...state,
        formSubmit: action.payload
      }
    }
    case TOGGLE_SAVE_ADDITIONAL_CLINICIAN: {
      return {
        ...state,
        disableSave: action.payload
      }
    }
    case RESET_BUTTON_STATE:
      return initialState
    case TOGGLE_BUTTON_VISIBILITY:
      return {
        ...state,
        hideButton: action.payload
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
export default ButtonStateReducer
