import * as actions from "./types";
import http from "../API/HttpMethods";
import ApiNotificationData from "../../models/notificationsModels/apiNotificationsData.model";
import NotificationsListService from "../../services/NotificationsListService";

const fetchNotificationMedium =
 (options: actions.ActionArgs) => async (dispatch: any) => {
  const { onSuccess, onError, config } = options;
  dispatch({ type: actions.FETCHING_NOTIFICATION_MEDIUM });

  try {
   const response = await http.getNotificationMedium(
    "v1/notification-mediums",
    config
   );
   const { statusCode, error, data } = response;
   if (error && onError != null) {
    dispatch({ type: actions.FETCHING_NOTIFICATION_MEDIUM_ERROR });
    return onError();
   }

   if (!!data) {
    dispatch({
     type: actions.FETCHING_NOTIFICATION_MEDIUM_SUCCESS,
     payload: data,
    });
   }

   return data;
  } catch (error) {
   dispatch({ type: actions.FETCHING_NOTIFICATION_MEDIUM_ERROR });
  }
 };

const fetchOrganizationNotificationMedium =
 (options: actions.ActionArgs) => async (dispatch: any) => {
  const { onSuccess, onError, config } = options;
  dispatch({ type: actions.ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM });

  try {
   const response = await http.getOrganizationNotificationMedium(
    "/v1/organization-notification-settings",
    config
   );
   const { statusCode, error, data } = response;
   if (error && onError != null) {
    dispatch({
     type: actions.ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM_ERROR,
    });
    return onError();
   }

   if (!!data) {
    dispatch({
     type: actions.ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM_SUCCESS,
     payload: data,
    });
   }

   return data;
  } catch (error) {
   dispatch({
    type: actions.ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM_ERROR,
   });
  }
 };

const fetchUserNotificationMedium =
 (options: actions.ActionArgs) => async (dispatch: any) => {
  const { onSuccess, onError, config } = options;
  dispatch({ type: actions.USER_FETCHING_NOTIFICATION_MEDIUM });

  try {
   const response = await http.getUserNotificationMedium(
    "v1/user-notification-medium-settings",
    config
   );
   const { statusCode, error, data } = response;
   if (error && onError != null) {
    dispatch({ type: actions.USER_FETCHING_NOTIFICATION_MEDIUM_ERROR });
    return onError();
   }

   if (!!data) {
    dispatch({
     type: actions.USER_FETCHING_NOTIFICATION_MEDIUM_SUCCESS,
     payload: data,
    });
   }

   return data;
  } catch (error) {
   dispatch({ type: actions.USER_FETCHING_NOTIFICATION_MEDIUM_ERROR });
  }
 };

const fetchUserNotificationType =
 (options: actions.ActionArgs) => async (dispatch: any) => {
  const { onSuccess, onError, config } = options;
  dispatch({ type: actions.USER_FETCHING_NOTIFICATION_TYPE });

  try {
   const response = await http.getUserNotificationType(
    "v1/user-notification-type-settings",
    config
   );
   const { statusCode, error, data } = response;
   if (error && onError != null) {
    dispatch({ type: actions.USER_FETCHING_NOTIFICATION_TYPE_ERROR });
    return onError();
   }

   if (!!data) {
    dispatch({
     type: actions.USER_FETCHING_NOTIFICATION_TYPE_SUCCESS,
     payload: data,
    });
   }

   return data;
  } catch (error) {
   dispatch({ type: actions.USER_FETCHING_NOTIFICATION_TYPE_ERROR });
  }
 };
const fetchNotificationType =
 (options: actions.ActionArgs) => async (dispatch: any) => {
  const { onSuccess, onError, config } = options;
  dispatch({ type: actions.FETCHING_NOTIFICATION_TYPE });

  try {
   const response = await http.getUserNotificationType(
    "v1/notification-types",
    config
   );
   const { statusCode, error, data } = response;
   if (error && onError != null) {
    dispatch({ type: actions.FETCHING_NOTIFICATION_TYPE_ERROR });
    return onError();
   }

   if (!!data) {
    dispatch({
     type: actions.FETCHING_NOTIFICATION_TYPE_SUCCESS,
     payload: data,
    });
   }

   return data;
  } catch (error) {
   dispatch({ type: actions.FETCHING_NOTIFICATION_TYPE_ERROR });
  }
 };

const fetchAllNotifications =
 (options: actions.ActionArgs) => async (dispatch: any) => {
  dispatch({ type: actions.FETCH_NOTIFICATIONS });
  const { onError, onSuccess, config } = options;

  const data: ApiNotificationData[] = [
   {
    id: 1,
    contentId: 1,
    contentType: "order",
    notificationType: {
     id: 1,
     key: "ORDER_RECEIVED",
     name: "Order Received - Non-WebApp Portal Orders",
     frontEndURL: "/test-orders/summary/:id",
     iconURL:
      "https://cbslocal01.blob.core.windows.net/logo-images/tissuecypher-barrett's%20Esophagus.png",
    },
    notificationTypeId: 1,
    userId: 1,
    organizationId: 1,
    isRead: false,
    description:
     "Tissue cipher test of John Doe against Shaukat khanum org has been received",
    createdAt: "2023-10-09T10:19:14.269Z",
    updatedAt: "2023-10-09T10:21:46.011Z",
   },
   {
    id: 2,
    contentId: 34,
    contentType: "order",
    notificationType: {
     id: 2,
     key: "ORDER_PROCESSED",
     name: "Order Processed - WebApp Portal",
     frontEndURL: "/test-orders/summary/:id",
     iconURL:
      "https://cbslocal01.blob.core.windows.net/logo-images/tissuecypher-barrett's%20Esophagus.png",
    },
    notificationTypeId: 2,
    userId: 1,
    organizationId: 1,
    isRead: true,
    description:
     "Tissue cipher test of John Doe against Shaukat khanum org has been processed",
    createdAt: "2023-10-09T10:19:14.269Z",
    updatedAt: "2023-10-09T10:21:46.011Z",
   },
   {
    id: 3,
    contentId: 34,
    contentType: "order",
    notificationType: {
     id: 2,
     key: "ORDER_PROCESSED",
     name: "Order Processed - WebApp Portal",
     frontEndURL: "/test-orders/summary/:id",
     iconURL:
      "https://cbslocal01.blob.core.windows.net/logo-images/tissuecypher-barrett's%20Esophagus.png",
    },
    notificationTypeId: 2,
    userId: 1,
    organizationId: 1,
    isRead: true,
    description:
     "Tissue cipher test of John Doe against Shaukat khanum org has been processed",
    createdAt: "2023-10-13T10:19:14.269Z",
    updatedAt: "2023-10-13T10:21:46.011Z",
   },
  ];

  try {
   const response = await http.getAllNotifications(options.data.url, config);
   const NotificationsList = new NotificationsListService(response.data);

   if (response.data) {
    dispatch({
     type: actions.FETCH_NOTIFICATIONS_SUCCESS,
     payload: {
      notificationsList: NotificationsList.getNotificationsList(),
      notificationsData: response.data,
     },
    });

    const unReadCount = response.data.filter(
     (notification: ApiNotificationData) => notification.isRead == false
    ).length;
    dispatch({
     type: actions.UPDATE_NOTIFICATION_UNREAD_COUNT,
     payload: unReadCount ? unReadCount : 0,
    });

    onSuccess?.();
   }
  } catch (error: any) {
   onError?.(error.message);
   dispatch({ type: actions.FETCH_NOTIFICATIONS_ERROR });
  }
 };

const updateNotificationFilters = (filters: any) => ({
 type: actions.UPDATE_FILTERS,
 payload: filters,
});

const getUnreadCount =
 (options: actions.ActionArgs) => async (dispatch: any) => {
  const { config, onError, onSuccess } = options;
  try {
   dispatch({ type: actions.FETCHING_UNREAD_COUNT });
   const response = await http.getUnreadCount(
    "v1/notifications/unread-counts",
    config
   );

   dispatch({
    type: actions.FETCHING_UNREAD_COUNT_SUCCESS,
    payload: response?.data?.unreadCounts,
   });
  } catch (error: any) {
   onError?.(error.message);
   dispatch({ type: actions.FETCHING_UNREAD_COUNT_ERROR });
  }
 };

const UserNotificationActions = {
 fetchNotificationMedium,
 fetchOrganizationNotificationMedium,
 fetchUserNotificationMedium,
 fetchUserNotificationType,
 fetchNotificationType,
 fetchAllNotifications,
 updateNotificationFilters,
 getUnreadCount,
};

export default UserNotificationActions;
