/* eslint-disable @typescript-eslint/space-before-function-paren */
import React from 'react'

function LoginProcessIndicator(): JSX.Element {
  return <div className='flex h-screen w-full justify-center items-center'>
    <div className='animate-spin'>
      <img src={require('../../../assets/images/loadingPNG.png')} className={'h-[100px] w-[100px]'} />
    </div>
  </div>
}

export default LoginProcessIndicator
