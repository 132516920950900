import { Fragment, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import PillBoxInfoIcon from "../../assets/svg/PillBoxInfoIcon";
import SelectedCheckMark from "../../assets/svg/SelectedCheckMark";
import { connect } from "react-redux";
import './styles.css'
import TooltipHelper from "./TooltipHelper";

function OrganizationPillBox({
  organization,
  onClick,
  className,
  disabled,
  profile
}: any) {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={`flex items-center py-1 px-3 mr-2 ${disabled ? 'cursor-not-allowed' : 'hover:border hover:shadow-sm hover:border-zinc-300'} border rounded-full bg-sideMenuHoverBg text-royalBlue mb-2 ${className ?? ""
        }`}
    >
      {organization?.selected && <SelectedCheckMark className="mr-2" />}
      <div className="flex justify-between items-center">
     
        <p className={`${organization?.selected ? "mr-2" : "mx-2"}`}>
         {organization?.name}
        </p>
        {organization && <Fragment>
          <TooltipHelper backgroundColor={'#18B5B7'} className={'max-w-[200px]'} />
          <div
            data-tip={`${organization?.address}${organization?.city ? `, ${organization?.city}` : ''}${organization?.state ? `, ${organization?.state}` : ''} ${organization?.zipcode ? `, ${organization?.zipcode}` : ''} ${organization?.fax ? `, ${organization?.fax}` : ''}  ${organization?.status ? `<br/> ${organization.status}` : ''}`}
            data-iscapture="true"
            data-class="my-tooltip"
            data-multiline={true}
            style={{ height: 15, width: 15 }}
            className={`ml-2 cursor-pointer rounded-full bg-primary hover:bg-primaryHover text-center text-white flex flex-col justify-center items-center font-medium text-xs ${disabled ? 'cursor-not-allowed' : ''}`}
          >
            i
          </div>
        </Fragment>}
      </div>
    </button>
  );
}

const mapStateToProps=(state:any)=>({
  profile:state?.Profile?.profile
})
export default connect(mapStateToProps)(OrganizationPillBox);
