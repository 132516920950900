/* eslint-disable @typescript-eslint/space-before-function-paren */
import React from 'react'
import { InView } from 'react-intersection-observer'

function Indicator({ isDual }: { isDual: boolean }): JSX.Element {
  return <div className='flex justify-between w-full'>
    <div className="w-2/5 py-5 px-2 border-r border-b flex flex-col justify-center items-center">
      <div className='h-16 w-4/6 bg-dropDownBorder rounded-lg animate-pulse' />
    </div>
    <div className="w-3/5 px-6 py-2  border-b flex flex-col justify-center hover:bg-[#F9F9FA]">
      <div className='flex items-center animate-pulse my-2'>
        <div className='h-[20px] w-[20px] rounded-sm bg-dropDownBorder' />
        <div className='h-[10px] w-2/6 ml-3 rounded-full bg-dropDownBorder' />
      </div>
      {isDual && <div className='flex items-center animate-pulse'>
        <div className='h-[20px] w-[20px] rounded-sm bg-dropDownBorder' />
        <div className='h-[10px] w-2/6 ml-3 rounded-full bg-dropDownBorder' />
      </div>}
    </div>
  </div>
}

function TestSelectorLoadingIndicator({ setSelectedTab }: any): JSX.Element {
  function handleInView(inView: boolean): void {
    if (!!setSelectedTab === false) return
    if (inView) {
      setSelectedTab('select test')
    } else {
      setSelectedTab('patient info')
    }
  }
  return <InView id='select test' as={'div'} className={'scroll-mt-64'} threshold={0.5} onChange={handleInView}>
    <div className='flex flex-col border rounded-sm'>
      <Indicator isDual={false} />
      <Indicator isDual={false} />
      <Indicator isDual={true} />
      <Indicator isDual={true} />
      <Indicator isDual={false} />
    </div>
  </InView>
}

export default TestSelectorLoadingIndicator
