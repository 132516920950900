import moment from "moment";
import ApiNotificationData from "../models/notificationsModels/apiNotificationsData.model";
import NotificationCardProps from "../models/notificationsModels/notificationCard.model";
import NotificationsDataModel from "../models/notificationsModels/notificationsData.model";

class NotificationsListService {
 notificationsData: NotificationsDataModel[] = [];
 todayData: NotificationCardProps[] = [];
 earlierData: NotificationCardProps[] = [];
 data: ApiNotificationData[] = [];

 constructor(data: ApiNotificationData[]) {
  this.data = data;
  this.createNotificationsList();
 }

 private createNotificationsList() {
  this?.data?.forEach((notification) => {
   let item: NotificationCardProps = {
    ...notification,
    message: notification.description,
    time: moment(notification.createdAt).toDate(),
    contentType: notification.contentType,
    iconURL: notification.notificationType.iconURL,
    isNew: notification.isRead === true ? false : true,
    onClick: (history: any) => {
     const link = notification?.notificationType?.frontEndURL?.replace(
      ":id",
      notification?.contentId?.toString()
     );
     if (notification.notificationType.frontEndURL !== "") history?.push(link);
    },
   };

   if (moment().isSame(notification.createdAt, "day")) {
    this.todayData.push(item);
   } else {
    this.earlierData.push(item);
   }
  });

  this.notificationsData.push({
   date: moment().toDate(),
   list: this.todayData,
  });
  this.notificationsData.push({
   date: moment().subtract(1, "day").toDate(),
   list: this.earlierData,
  });
 }

 getNotificationsList(): NotificationsDataModel[] {
  return this.notificationsData;
 }
}

export default NotificationsListService;
