import * as React from "react"
import { SVGProps } from "react"

const SOFArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.121}
    height={7.811}
    {...props}
  >
    <g data-name="Component 6355 \u2013 76">
      <path
        data-name="Path 5132"
        d="m1.061 1.061 6 6 6-6"
        fill="none"
        stroke={props?.fill ? props.fill:"#18b5b7"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
)

export default SOFArrowDown
