import ArrowUp from "../../assets/svg/ArrowUp";
import DropDownIcon from "../../assets/svg/DropdownIcon";
import React, { useState } from "react";
import FAQLoadingIndicator from "../global/LoadingIndicators/FAQSectionLoadingIndicator";
import { InView } from "react-intersection-observer";
import scrollHelper from "../../utils/helpers/scrollHelper";
import { useSelector } from "react-redux";

function FAQ({ setSelectedTab, firstScroll }: any) {
  const [activeIndex, setActiveIndex] = useState<any>(0);
  const handleQuestionClick = (index: any) => {
    if (activeIndex === index) {
      setActiveIndex(null); 
    } else {
      setActiveIndex(index);
    }
  };
  const questions = useSelector((state: any) => state.Help);
  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, firstScroll, "faq");
  }

  return (
    <InView
      id={"faq"}
      as={"div"}
      className={`${questions?.data?.videos?.length === 0 && 'scroll-my-[180px]' } scroll-my-[180px]`}
      threshold={window.innerWidth <= 1280 ? 0.5 : 0.7}
      onChange={handleMouseScroll}
    >
      <div>
        <p className="font-bold text-royalBlue pt-5 pb-3 text-[24px]">FAQ</p>
        {questions?.fetching ? (
          <FAQLoadingIndicator />
        ) : questions?.data?.faqs?.length === 0 ? (
          <p className="font-medium text-royalBlue py-2">No faqs found</p>
        ) : (
          questions?.data?.faqs?.sort((a:any, b:any) => a.title.localeCompare(b.title)).map((q: any, index: any) => (
            <div key={index} className="mt-2 mb-2">
              <div
                className={`border rounded-md ${
                  activeIndex === index ? "border-[#18B5B7]" : "shadow-sm"
                }`}
              >
                <button
                  className={`w-full flex text-left justify-between font-medium text-royalBlue px-4 py-2 rounded focus:outline-none`}
                  onClick={() => handleQuestionClick(index)}
                >
                  {q.title}
                  <span className={`py-2`}>
                    {activeIndex === index ? <ArrowUp /> : <DropDownIcon />}
                  </span>
                </button>
                <div
                  className={`px-4 text-royalBlue text-left ${
                    activeIndex === index
                      ? "py-4 h-auto opacity-100 transition-all duration-200 ease-in-out"
                      : "py-0 h-0 opacity-0 transition-all duration-200 ease-in-out"
                  }`}
                >
                  {activeIndex === index && (
                    <React.Suspense fallback={<p>Loading...</p>}>
                      <div dangerouslySetInnerHTML={{__html:q.description}} />
                    </React.Suspense>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </InView>
  );
}

export default FAQ;