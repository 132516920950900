import axios, { AxiosResponse } from "axios";
import envVars from "../env-configs";

const instance = axios.create({
  baseURL: envVars.REACT_APP_SERVER_URL,
});
const selectedEndpoints = [
  "/v1/auth/new-flow/register",
  "/v1/orders/new-flow/create",
  "/v1/orders/new-flow",
  "v1/organizations/new-flow/create",
  "v1/sof/create-sof",
  "v1/sof/update-sof",
  "v1/users/invite",
  "/v1/additional-clinicians",
];
instance.interceptors.request.use(async (config: any) => {
  try {
    // Fetch the IP address of the user using a free IP lookup service (e.g., ipify.org)
    if (selectedEndpoints.find((link) => config.url.includes(link))) {
      if (
        config.url.startsWith("/v1/orders/new-flow/pdf-orders") ||
        config.url.startsWith("/v1/orders/new-flow/multiple-download") ||
        config.url.startsWith("/v1/orders/new-flow/add-notes") ||
        config.url.startsWith("/v1/orders/new-flow/add-document")
      ) {
        return config; // Skip this endpoint and do not attach IP address
      }
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();

      config.data = {
        ...config.data,
        SourceIpAddress: data.ip,
      };
    }
  } catch (error) {
    // Handle any errors that occurred while fetching the IP address
    console.error("Error fetching IP address:", error);
  }
  return config;
});

const responseBody = (response: AxiosResponse) => {
  let data = response.data;
  data.status = response.status;
  
  return data;
};
// const responseBody = (response: AxiosResponse) => response.data;


const requests = {
  get: async (url: string, config?: {}) =>
    await instance.get(url, config).then(responseBody),
  post: async (url: string, body: {}, config?: {}) =>
    await instance.post(url, body, config).then(responseBody),
  put: async (url: string, body: {}, config?: {}) =>
    await instance.put(url, body, config).then(responseBody),
  delete: async (url: string, config?: {}) =>
    await instance.delete(url, config).then(responseBody),
};

export default requests;
