import * as React from 'react'
import { SVGProps } from 'react'

const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8.121}
    height={8.121}
    {...props}
  >
    <g
      fill="none"
      stroke={props.stroke ? props.stroke : "#97a0af"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path data-name="Line 847" d="m7.061 1.061-6 6" />
      <path data-name="Line 846" d="m1.061 1.061 6 6" />
    </g>
  </svg>
  // <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width={props.width ?? 14.121}
  //     height={props.height ?? 14.121}
  //     {...props}
  // >
  //     <g
  //         data-name="Group 13475"
  //         fill="none"
  //         stroke={props.stroke ?? '#647188'}
  //         strokeLinecap="round"
  //         strokeWidth={1.5}
  //     >
  //         <path data-name="Path 5334" d="M13.061 1.061 5.936 8.186l-4.875 4.875" />
  //         <path
  //             data-name="Line 1251"
  //             strokeLinejoin="round"
  //             d="m1.061 1.061 12 12"
  //         />
  //     </g>
  // </svg>
)

export default CloseIcon
