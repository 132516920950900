import * as actions from '../Actions/types'
import http from '../API/HttpMethods'

const fetchStateAndCity = (options: actions.ActionArgs) => async (dispatch: any) => {
  const { onSuccess, onError } = options
  dispatch({ type: actions.FETCHING_STATE_DATA })

  try {
    const response = await http.getStateAndCity('v1/states')
    const { statusCode, error, data } = response
    // console.log(error)
    if (error && (onError != null)) {
      dispatch({ type: actions.FETCHING_STATE_ERROR })
      return onError()
    }
    if (statusCode == 200) { dispatch({ type: actions.FETCHING_STATE_SUCCESS, payload: data }) }
  } catch (error) {
    // console.log(error)
    dispatch({ type: actions.FETCHING_STATE_ERROR })
  }
}

const StateAndCityActions = {
  fetchStateAndCity
}

export default StateAndCityActions
