import { useEffect } from "react"

function DownloadStorage() {
    const download = () => (
        Object.assign(document.createElement("a"), {
            href: `data:application/JSON, ${encodeURIComponent(
                JSON.stringify(
                    Object.keys(localStorage).reduce(
                        (obj, k) => {
                            const data: any = localStorage.getItem(k)?.includes('{') ? localStorage.getItem(k):'{}'
                            console.log({data,k})
                            return { ...obj, [k]: JSON.parse(data) }
                        },
                        {}
                    ),
                    null,
                    2
                )
            )}`,
            download: "localStorageData",
        }).click()
    )
    useEffect(() => {
        download()
    }, [])

    return null
}

export default DownloadStorage;