import * as React from 'react'
import { SVGProps } from 'react'

const NoImageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name="Component 6406"
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 26}
        height={props.height ?? 16}
        {...props}
    >
        <rect
            data-name="Rectangle 4262"
            width={26}
            height={16}
            rx={2}
            fill="#edeff1"
        />
        <g
            data-name="Group 14088"
            transform="translate(4.451 -.549)"
            fill="none"
            stroke="#b1b8c3"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path data-name="Line 1297" d="M9.549 6.549h0" />
            <rect
                data-name="Rectangle 4263"
                width={10}
                height={10}
                rx={3}
                transform="translate(3.549 3.549)"
            />
            <path
                data-name="Path 5220"
                d="m4.035 10.242 2.257-2.257a1.137 1.137 0 0 1 1.693 0l2.821 2.821"
            />
            <path
                data-name="Path 5221"
                d="m9.678 9.677.564-.564a1.137 1.137 0 0 1 1.693 0l1.129 1.129"
            />
        </g>
    </svg>
)

export default NoImageIcon
