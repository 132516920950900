/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import React, { useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Provider, useDispatch } from 'react-redux'
import './App.css'
import AppRoutes from './Router/AppRoutes'
import store, { persistor } from './Store'
import { PersistGate } from 'redux-persist/integration/react'

function App(): JSX.Element {

  return (
    <AnimatePresence>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRoutes />
        </PersistGate>
      </Provider>
    </AnimatePresence>
  )
}

export default App
