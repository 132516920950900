import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import NotificationActions from "../../Store/Actions/NotificationsActions"
import { useHistory } from "react-router-dom"

function useOrderFlowPopups(updateOrder: boolean): any {
  const { selectedOrganization, selectedPhysician, selectedPathLab } = useSelector((state: any) => state.OnlineOrderForm)
  const { sessionDestroyed } = useSelector((state: any) => state.Session)
  const profile = useSelector((state: any) => state.Profile)
  const isPathLab = (profile?.roles?.includes('Collaborative Order Agreement') || profile?.profile?.roles?.includes('Collaborative Order Agreement')) && !((!profile?.roles?.includes('Physician') || !profile?.profile?.roles?.includes('Physician')) && selectedPhysician?.id == profile?.profile?.id)
  const history = useHistory()
  const dispatch = useDispatch()
  const [previousPathLab, setPreviousPathLab] = useState<any>(0); // Initialize previousPathLab

  // THIS LINE OF CODE IS COMMENTED UNTIL CONFIRMED THAT THERE CAN BE ANOTHER COA,PHYSICIAN USER in DROPDOWN
  // const selectedPhysicianOrganizationRoles = !!selectedPhysician?.organizationRoles ? JSON.parse(selectedPhysician.organizationRoles)?.[selectedOrganization?.id]?.roles : null
  // const isPathLab = !!selectedPhysicianOrganizationRoles ? selectedPhysicianOrganizationRoles?.includes('Collaborative Order Agreement') && selectedPhysicianOrganizationRoles?.includes('Physician') == false : false

  useEffect((): any => {
    const props: any = { selectedOrganization, selectedPhysician }
    if (isPathLab) {
      props.selectedPathLab = selectedPathLab
    }
    let message: string = ''
    let buttonTitle: string = 'OK'
    let hasPhysicians: boolean = selectedOrganization?.users?.filter((e: any) => { return e?.roles?.includes("Physician"); }).map((e: any) => { return { id: e.id, label: e?.firstName }; }).length > 0

    if (!!selectedOrganization == false) {
      message = `Please specify the organization ${isPathLab ? ', path lab and the clinician' : `${!!selectedPhysician?.id == false ? 'and clinician' : ''}`} in the context for whom you will be starting the test order for.`
      buttonTitle = 'OK'
    }
    if(selectedPathLab === null && profile?.roles?.includes('Collaborative Order Agreement')){
      message= `Please specify the pathlab first`,
      buttonTitle = 'OK'
    }

    if (!!selectedOrganization?.id) {
      if (!!selectedPhysician?.id == false) {
        if (isPathLab && !!selectedPathLab?.id === false) {
          message = 'Please specify the clinician and path lab before proceeding with creating a test order.'
          buttonTitle = 'Ok'
        } else {
          message = hasPhysicians ? 'Please select a clinician before proceeding with creating a test order.' : 'Please add a clinician before proceeding with creating a test order'
          buttonTitle = hasPhysicians ? 'OK' : 'Add Clinicians'
        }
      }
    }
    if (Object.keys(props).some(key => !!props[key] == false ? true : false) && !sessionDestroyed) {
      dispatch(NotificationActions.showNotification({
        type: 'error',
        title: updateOrder ? 'Update Online Order' : 'Create Online Order',
        message,
        hideClose: true,
        customFunction() {
          hasPhysicians ? history.replace("/test-orders") : !!selectedOrganization == false ? history.replace('/test-orders') : history.replace("/manage/portal-users");
        },
        buttons: [
          {
            onClick: () => {
              hasPhysicians ? history.replace("/test-orders") : !!selectedOrganization == false ? history.replace('/test-orders') : history.replace("/manage/portal-users")
              dispatch(NotificationActions.hideNotifications())
            },
            title: buttonTitle,
            type: "primary",
          }
        ]
      }))
    }

    setPreviousPathLab(selectedPathLab);

  },
    [selectedOrganization, selectedPhysician, selectedPathLab, sessionDestroyed])
}

export default useOrderFlowPopups