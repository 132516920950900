import * as React from "react"
import { SVGProps } from "react"
const NotificationsSettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17.5} height={17.5} {...props}>
    <g
      fill="none"
      stroke="#647188"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      data-name="Component 6422 \u2013 6"
      {...props}
    >
      <path
        d="M10.75 2.75h-2a2 2 0 1 0 2-2 2 2 0 0 0-2 2"
        data-name="Path 5417"
      />
      <path d="M.75 2.75h8" data-name="Path 5418" />
      <path d="M12.75 2.75h4" data-name="Path 5419" />
      <path
        d="M4.75 8.75h-2a2 2 0 1 0 2-2 2 2 0 0 0-2 2"
        data-name="Path 5420"
      />
      <path d="M.75 8.75h2" data-name="Path 5421" />
      <path d="M6.75 8.75h10" data-name="Path 5422" />
      <path
        d="M11.75 14.75a2 2 0 1 0 2-2 2 2 0 0 0-2 2"
        data-name="Path 5423"
      />
      <path d="M.75 14.75h11" data-name="Path 5424" />
      <path d="M15.75 14.75h1" data-name="Path 5425" />
    </g>
  </svg>
)
export default NotificationsSettingsIcon
