import { combineReducers } from 'redux'
import AuthReducer from './AuthReducer'
import ButtonStateReducer from './ButtonsStateReducer'
import DashboardReducer from './DashboardReducer'
import InviteUserReducer from './InviteUserReducer'
import NotificationsReducer from './NotificationsReducer'
import OnlineOrderFormReducer from './OnlineOrderFormReducer'
import OrganizationsReducer from './OrganizationsReducer'
import PDFOrderReducer from './PDFOrderReducer'
import PortalUsersReducer from './PortalUsersReducer'
import ProfileReducer from './ProfileReducer'
import SessionReducer from './SessionReducer'
import SignUpFormReducer from './SignUpFormReducer'
import SpecialityReducer from './Speciality'
import StateAndCityReducer from './StateAndCity'
import SuffixReducer from './Suffix'
import PathLabReducer from './PathLabReducer'
import SyncReducer from './SyncReducer'
import SideMenuReducer from './SideMenuReducer'
import ActiveTestsReducer from './ActiveTestsReducer'
import HelpReducer from './HelpReducer'
import SettingsReducer from './SettingsReducer'
import NotificationReducer from './UserNotificationReducer'

const rootReducer = combineReducers({
  AuthReducer,
  ActiveTests: ActiveTestsReducer,
  SignUpFormValues: SignUpFormReducer,
  ButtonState: ButtonStateReducer,
  InviteUser: InviteUserReducer,
  Organizations: OrganizationsReducer,
  PortalUsers: PortalUsersReducer,
  OnlineOrderForm: OnlineOrderFormReducer,
  StateAndCity: StateAndCityReducer,
  Dashboard: DashboardReducer,
  Session: SessionReducer,
  PathLab: PathLabReducer,
  Suffix: SuffixReducer,
  Speciality: SpecialityReducer,
  Profile: ProfileReducer,
  Notifications: NotificationsReducer,
  PDFOrder: PDFOrderReducer,
  Sync: SyncReducer,
  SideMenuReducer: SideMenuReducer,
  Settings: SettingsReducer,
  Help: HelpReducer,
  UserNotifications: NotificationReducer
})

export default rootReducer
