import * as React from 'react'
import { SVGProps } from 'react'

const ApprovalsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={15.222} height={17} {...props}>
        <g
            data-name="Component 6411 \u2013 4"
            transform="translate(.5 .5)"
            fill={props.fill ?? 'none'}
            stroke={props.stroke ?? '#172b4d'}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path data-name="Path 5318" d="M9.222 0v4a1 1 0 0 0 1 1h4" />
            <path
                data-name="Path 5319"
                d="M2 4.444V1.778A1.762 1.762 0 0 1 3.746 0h6.111l4.365 4.444v9.778A1.762 1.762 0 0 1 12.476 16H8.111"
            />
            <circle
                data-name="Ellipse 685"
                cx={4.5}
                cy={4.5}
                r={4.5}
                transform="translate(0 6)"
            />
            <path data-name="Path 5324" d="m2.777 10.648 1.143 1.143 2.287-2.286" />
        </g>
    </svg>
)

export default ApprovalsIcon
