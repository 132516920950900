import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import ButtonStateActions from "../../Store/Actions/ButtonStateActions";

function Tabs({ activeClassName, isPathLab }: any) {
  const profile = useSelector((state: any) => state.Profile);
  const location = useLocation()
  let showPathLabs =
    profile?.roles?.includes("Collaborative Order Agreement") ||
    profile?.roles?.includes("PathLab User") ||
    profile?.profile?.roles?.includes("Collaborative Order Agreement") ||
    profile?.profile?.roles?.includes("PathLab User");
  let tabs = [
    { label: "Organizations", value: "/manage/organizations" },
    { label: "PathLab", value: "/manage/pathlabs" },
    {
      label: "Portal Users",
      value: "/manage/portal-users",
      buttonStateValue: "portal-users",
    },
  ];
  let notificationTabs = [
    { label: "Organization Settings", value: "/notifications/settings/organization-settings" },
    {
      label: "Personal Settings",
      value: "/notifications/settings/personal-settings",
      // buttonStateValue: "personal-settings",
    },
  ];

  let shipmentTabs = [
    { label: "Unshipped Orders", value: "/shipments/unshipped-orders" },
    {
      label: "Active Labels",
      value: "/shipments/active-labels",
    },
    {
      label: "Pickups",
      value: location?.pathname === '/shipments/schedule-pickup' ? '/shipments/schedule-pickup' : "/shipments/pick-ups",
    },
  ];

  const { screen } = useSelector((state: any) => state.ButtonState);
  const dispatch = useDispatch();
  function handleTab(val: string) {
    return () => {
      // setHeaderTitle(val == 'organizations' ? 'manage' : val);
      if (screen !== val) {
        dispatch(ButtonStateActions.setButtonState(val));
      }
      // setSelectedTab(val);
    };
  }

  const filteredTabs = notificationTabs.filter((tab) => {
    if (tab.value === "/notifications/settings/organization-settings") {
      return profile?.profile?.roles?.includes('Office Manager');
    }
    return true;
  });
  return (
    <div className="bg-[#F9F9FA] border-r col-span-1 p-6 break-all h-full">
      {location?.pathname?.includes("notification")
        ? filteredTabs?.map((tab) => (
          <NavLink
            to={tab.value}
            key={tab.value}
            replace={true}
            onClick={() =>
              handleTab(
                tab.value
              )
            }
            activeClassName={"font-bold !text-activeLink hover:font-bold"}
            className="text-royalBlue text-sm lg:text-base text-left block capitalize p-1 mb-1 hover:text-activeLink hover:font-bold hover:lg:text-base hover:text-sm visited:text-royalBlue break-normal"
          >
            {tab.label}
          </NavLink>
        ))
        : location?.pathname?.includes('shipments') ? shipmentTabs?.map((tab) => (
          <NavLink
            to={tab.value}
            key={tab.value}
            replace={true}
            onClick={() =>
              handleTab(
                tab.value
              )
            }
            activeClassName={"font-bold !text-activeLink hover:font-bold"}
            className={`text-royalBlue text-sm lg:text-base text-left block p-1 mb-1 hover:text-activeLink hover:font-bold hover:lg:text-base hover:text-sm visited:text-royalBlue break-normal`}
          >
            {tab.label}
          </NavLink>
        )) : tabs
          .filter((tab) =>
            !showPathLabs ? tab.value !== "/manage/pathlabs" : true
          )
          .map((tab) => (
            <NavLink
              to={tab.value}
              key={tab.value}
              replace={true}
              onClick={() =>
                handleTab(
                  tab?.buttonStateValue ? tab.buttonStateValue : tab.value
                )
              }
              activeClassName={"font-bold !text-activeLink hover:font-bold"}
              className="text-royalBlue text-sm lg:text-base text-left block capitalize p-1 mb-1 hover:text-activeLink hover:font-bold hover:lg:text-base hover:text-sm visited:text-royalBlue break-normal"
            >
              {tab.label}
            </NavLink>
          ))}
    </div>
  );
}

export default Tabs;
