/* eslint-disable semi */
/* eslint-disable quotes */
interface envConfigType {
 REACT_APP_SERVER_URL: string;
 REACT_APP_OKTA_ISSUER: string;
 REACT_APP_OKTA_CLIENT_ID: string;
 REACT_APP_OPEN_REPLAY_PROJECT_KEY: string;
 REACT_APP_OPEN_REPLAY_INGEST_POINT: string;
}

const envVars = (env: any): envConfigType => {
 return {
  REACT_APP_SERVER_URL: env.REACT_APP_SERVER_URL,
  REACT_APP_OKTA_ISSUER: env?.REACT_APP_OKTA_ISSUER,
  REACT_APP_OKTA_CLIENT_ID: env?.REACT_APP_OKTA_CLIENT_ID,
  REACT_APP_OPEN_REPLAY_INGEST_POINT: env?.REACT_APP_OPEN_REPLAY_INGEST_POINT,
  REACT_APP_OPEN_REPLAY_PROJECT_KEY: env?.REACT_APP_OPEN_REPLAY_PROJECT_KEY,
 };
};

export default { ...envVars(process.env) };
