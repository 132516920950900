import { removeExtraSpace } from "../../utils/helpers/removeExtraSpace";
import http from "../API/HttpMethods";
import * as actions from "./types";
import { ActionArgs } from "./types";

const fetchprofile = (options: any) => async (dispatch: any) => {
 const { accessToken, onError, onSuccess } = options;

 dispatch({ type: actions.FETCH_PROFILE });

 try {
  const response = await http.getProfile("/v1/auth/new-flow/me", {
   headers: { Authorization: `Bearer ${accessToken}` },
  });

  const { error, data } = response;

  if (error && onError) {
   dispatch({ type: actions.FETCH_PROFILE_ERROR });
   onError();
   return;
  }

  const payload = { ...data };

  dispatch({ type: actions.FETCH_PROFILE_SUCCESS, payload });

  if (onSuccess) onSuccess(data);

  return data;
 } catch (error) {
  dispatch({ type: actions.FETCH_PROFILE_ERROR, payload: error });
  onError();
 }
};

const updateUser = (options: ActionArgs) => async (dispatch: any) => {
 const { data: requestData, onSuccess, onError } = options || {};
 const apiData: any = {
  firstName: removeExtraSpace(requestData.firstName),
  email_id: requestData.email_id,
  lastName: removeExtraSpace(requestData.lastName),
  acceptTerms: requestData.acceptTerms,
  acceptTermsDate: requestData.acceptTermsDate,
  profileImage: requestData.profileImage ? requestData.profileImage : "",
  //   primaryPhone: requestData.primaryPhone,
  //   fax: requestData.fax,
  //   streetAddress: requestData.streetAddress,
  //   city: requestData.city,
  //   state: requestData.state,
  //   zipCode: requestData.zipCode,
  //   // role: requestData.role,
  //   pinCode: requestData.pinCode,
 };
 //  if (requestData?.role?.toLowerCase() === "office manager") {
 //     apiData.firstName=requestData.firstName;
 //     apiData.lastName=requestData.lastName;
 //     apiData.email_id=requestData.email_id;
 //    }
 if (requestData?.role?.toLowerCase() === "physician") {
  apiData.signPermission = !!requestData.signPermission
   ? requestData.signPermission
   : false;
  apiData.specialty = removeExtraSpace(requestData.specialty);
  apiData.suffix = removeExtraSpace(requestData.suffix);
  apiData.npi = requestData.npi;
 }

 const config = {
  headers: { Authorization: `Bearer ${requestData.accessToken}` },
 };

 dispatch({ type: actions.TOGGLE_FORM_SUBMIT, payload: true });
 dispatch({ type: actions.UPDATE_LOGGEDIN_USER });

 try {
  const result = await http.updateOrganization(
   "/v1/users/new-flow/update-profile",
   apiData,
   config
  );

  const { success, error, data, message, statusCode } = result;

  if (error && onError != null) return onError(error);
  dispatch({ type: actions.UPDATE_LOGGEDIN_USER_SUCCESS });
  dispatch({ type: actions.TOGGLE_FORM_SUBMIT, payload: false });
  onSuccess != null && onSuccess(message);
 } catch (error: any) {
  const { data: errorData } = error?.response;
  const message = errorData ? errorData.message : !!error;

  onError != null && onError(message);
  dispatch({ type: actions.UPDATE_LOGGEDIN_USER_ERROR });
  dispatch({ type: actions.TOGGLE_FORM_SUBMIT, payload: false });
 }
};

const fetchOrderAgreementOfUser =
 (id: string, config: any, onError?: (message: string) => void) =>
 async (dispatch: any) => {
  dispatch({ type: actions.FETCH_PROFILE_OA });
  try {
   const result = await http.getOrderAgreement(
    `/v1/order-agreement/${id}`,
    config
   );
   const { statusCode, data, error } = result;

   if (error && onError) {
    onError(error?.message ? error.message : "Error retrieving ");
    dispatch({ type: actions.FETCH_PROFILE_OA_ERROR });
   }

   dispatch({ type: actions.FETCH_PROFILE_OA_SUCCESS, payload: data });
  } catch (error: any) {
   onError &&
    onError(
     error?.message
      ? error?.response?.data
        ? error?.response.data
        : error
      : "Failed to retrieve user data"
    );
   dispatch({ type: actions.FETCH_PROFILE_OA_ERROR });
  }
 };
const ProfileActions: any = {
 fetchprofile,
 updateUser,
 fetchOrderAgreementOfUser,
};

export default ProfileActions;
