import * as React from "react"
import { SVGProps } from "react"
const EmptyDataSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={44}
    fill="none"
    {...props}
  >
    <g
      stroke="#B2B8C2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
    >
      <path d="M25.666 5.5v7.333a1.833 1.833 0 0 0 1.833 1.834h7.334" />
      <path d="M28 38.5H12.832a3.667 3.667 0 0 1-3.667-3.667V9.167A3.667 3.667 0 0 1 12.833 5.5h12.833l9.167 9.167v15.25" />
      <path d="M14 26a8 8 0 1 0 16.001 0A8 8 0 0 0 14 26ZM29 32l4.583 4.583M20 24l4.583 4.583M20 28.583 24.583 24" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h44v44H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default EmptyDataSVG
