import WarningIcon from "../../assets/svg/WarningIcon"

function WarningBanner({ warning }: { warning: string }): JSX.Element {
  return <div className="py-3 mt-2 w-[100%] bg-pink border-requiredRed rounded-md border-[1px] justify-between px-3">

    <div className="flex items-center ">
      <WarningIcon className=' 2xl:w-[3%] lg:w-[10%] xl:w-[8%]' />
      <div className="text-requiredRed text-sm ml-2">{warning}</div>
    </div>
  </div>
}
export default WarningBanner