import * as React from 'react'
import { SVGProps } from 'react'

const SelectedCheckMark = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 14.828}
        height={props.height ?? 10.414}
        {...props}
    >
        <g data-name="Group 14125">
            <path
                data-name="Path 5128"
                d="m1.414 5.414 4 4 8-8"
                fill="none"
                stroke="#18b5b7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
            />
        </g>
    </svg>
)

export default SelectedCheckMark
