interface LandingPageActionProps {
  title: string
  subtitle: string
  buttonTitle: string
  onClick: (props?: any) => any
  buttonClassName?: string
}

function LandingPageAction({ title, subtitle, buttonTitle, buttonClassName, onClick }: LandingPageActionProps): JSX.Element {
  return <div className={`bg-primary rounded-[15px] flex flex-col  items-end overflow-hidden ${buttonClassName}`}>
    <div className="w-[98%] bg-[#1e4b8a] h-full p-5 flex flex-col">
      <div className="mx-5 flex flex-col h-full justify-between">
        <div>
          <h2 className="font-semibold text-white  uppercase text-lg">{title}</h2>
          <h4 className="text-[rgba(255,255,255,0.8)]">{subtitle}</h4>
        </div>
        <button
          type="button"
          className="w-full bg-[#d7d9dd] rounded-[15px] p-2 uppercase text-[#1e4b8a] font-semibold mb-2 my-5 "
          onClick={onClick}
        >
          {buttonTitle}
        </button>
      </div>
    </div>
  </div>
}

export default LandingPageAction