import * as React from 'react'
import { SVGProps } from 'react'
import { useLocation } from 'react-router-dom'

const SubmittedTestOrdersIcon = (props: SVGProps<SVGSVGElement>) => {
    const location = useLocation()

    return <svg xmlns="http://www.w3.org/2000/svg" width={17} height={14.714} {...props}>
        <g
            data-name="Group 14058"
            transform="rotate(90 13.5 8)"
            fill={location.pathname.includes('/test-orders/online-order') ? '#fff' : 'none'}
            stroke={props.stroke ?? '#172b4d'}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect
                data-name="Rectangle 4234"
                width={4.571}
                height={16}
                rx={1}
                transform="translate(6 5)"
            />
            <rect
                data-name="Rectangle 4235"
                width={4.571}
                height={16}
                rx={1}
                transform="translate(15.143 5)"
            />
        </g>
    </svg>
}

export default SubmittedTestOrdersIcon
