import * as React from "react"
import { SVGProps } from "react"

const ArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={8} {...props}>
    <path
      d="M7 2.414 1.707 7.707A1 1 0 0 1 .293 6.293l6-6a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1-1.414 1.414Z"
      fill={props.fill ?? '#647188'}
    />
  </svg>
)

export default ArrowUp
