import * as actions from "./types";
import http from "../API/HttpMethods";

const fetchHelp = (options: any) => async (dispatch: any) => {
 const { accessToken, onError, onSuccess,searchQuery } = options;
 dispatch({ type: actions.FETCHING_HELP_DATA });

 try {
  const response = await http.getHelp(!!searchQuery ? `/v1/help-section/search?name=${searchQuery}`: '/v1/help-section', {
   headers: { Authorization: `Bearer ${accessToken}` },
  });
  const { error, data } = response;

  if (error && onError) {
   dispatch({ type: actions.FETCHING_HELP_DATA_ERROR });
   onError();
   return;
  }

  const payload = { ...data };

  dispatch({ type: actions.FETCHING_HELP_DATA_SUCCESS, payload });

  if (onSuccess) onSuccess();
 } catch (error) {
  dispatch({ type: actions.FETCHING_HELP_DATA_ERROR });
  onError();
 }
};

const HelpActions: any = {
 fetchHelp,
};

export default HelpActions;
