/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import { Field } from 'formik'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import DropDownIcon from '../../assets/svg/DropdownIcon'
import PasswordVisibility from '../../assets/svg/PasswordVisibilityIcon'
import inputProps from '../../models/inputprops.type'
import SuggestionsDropDown from '../organism/SuggstionsDropdown'
import MaskedInput from 'react-text-mask'
import './styles.css'
import LoadingSvg from '../../assets/svg/LoadingSVG'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { useSelector } from 'react-redux'
import RequiredFieldTooltip from './RequiredFieldTooltip'
import dateFormatter from '../../utils/helpers/dateFormatter'
import TooltipHelper from './TooltipHelper'
import { addDays, getDay } from 'date-fns';

const phoneNumberMask = [
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/
]

function CustomInput({ id, isSummary, isPage, showToolTip, generateStyling, label, name, placeholder, required, value, className, signPermissionCheck, errors, touched, type, suggestions, onChange, isInternal, checkBoxTextStyling, checkboxContainerStyle, inputMask, hideDropdownIcon, checked, disabled, withoutForm, disableSuggestiveFiltering, min, max, isTextArea, isSearching, tabIndex, signupFieldsStyle, labelClassName, inputClassName, showSuggestions, customError, customTouched, addLabelSpan, bulletPoint, styleCheckbox, suggestionInputDisabled, tooltipText, customLabelHTML, dropDownContainerStyle, onBlurCapture, disableSort, obscureInput }: inputProps): JSX.Element {
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [suggestionVisible, setSuggestionVisible] = useState(showSuggestions ?? false)
    const [suggestionValue, setSuggestionValue] = useState((suggestions != null) ? value ?? '' : '')
    const dropDownRef = useRef<any>()
    const radioRef = useRef<any>()
    const { screen } = useSelector((state: any) => state.ButtonState)

    const isWeekday = (date: Date) => {
        const day = getDay(date);
        const today = new Date();
        let nextTwoWeekdays;
        if (today.getDay() === 5) {
            // If today is Friday
            nextTwoWeekdays = [addDays(today, 0), addDays(today, 3)];
        } else if (today.getDay() === 6) {
            // If today is Saturday
            nextTwoWeekdays = [addDays(today, 2), addDays(today, 3)];
        } else if (today.getDay() === 0) {
            // If today is Sunday
            nextTwoWeekdays = [addDays(today, 1), addDays(today, 2)];
        } else {
            // For other weekdays
            nextTwoWeekdays = [addDays(today, 0), addDays(today, 1)];
        }
        const isTodayOrNextTwoWeekdays = nextTwoWeekdays.some(
            (d) => d.toDateString() === date.toDateString()
        );
        if (day === 0 || day === 6) {
            return false;
        }

        return isTodayOrNextTwoWeekdays;
    };
    // Conditionally apply the filter only when screen === 'shipments'
    const filterDateFunction = screen === 'shipment' ? isWeekday : undefined;
    const settingMaxDate = screen !== 'shipment' ? max : undefined
    const attributes = obscureInput ? { 'data-openreplay-obscured': true } : {}

    useEffect(() => {
        if (value === '') {
            setSuggestionValue('')
        }
    }, [value])


    useEffect(() => {
        if (showSuggestions)
            setSuggestionVisible(showSuggestions)
    }, [showSuggestions])

    function togglePasswordVisibility(): void {
        setPasswordVisible(state => !state)
    }

    function toggleDropdownVisibility(): void {
        setSuggestionVisible(state => !state)
    }

    function handleSuggestionChange(e: React.FormEvent<HTMLInputElement>): void {
        const { value } = e.currentTarget
        if (!suggestionVisible || value === '') { setSuggestionVisible(true) }

        onChange(value)
        setSuggestionValue(value)
    }

    if (disabled && (type !== 'checkbox' && type !== 'date')) {

        return <div className={`my-3 w-full h-16 ${className ?? ''} cursor-not-allowed`}>

            <RequiredFieldTooltip required={required} tooltipText={'Required Field'}>
                <label htmlFor={id} className={`cursor-not-allowed text-xs text-royalBlue font-normal ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''} ${errors[name] && touched[name] ? 'text-requiredRed' : ''}`}>
                    {label}
                </label>
            </RequiredFieldTooltip>

            <input
                {...attributes}
                id={id}
                name={name}
                autoComplete={'off'}
                value={value}
                disabled
                placeholder={placeholder}
                type={type ?? 'text'}
                className={`disabled:cursor-not-allowed py-2 w-full focus:ring-transparent focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder:text-borderGray  ${signupFieldsStyle ?? ''} ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''} ${inputClassName ?? ''}`}
            />
            {errors && errors[name] && touched && touched[name] && (
                <p className="text-xs text-requiredRed mt-1">{errors[name]}</p>
            )}
        </div>
    }


    if (type && type === 'checkbox') {
        return <div
            className={`mr-4 flex items-center justify-center ${signPermissionCheck ? '' : ''} float-left ${checkboxContainerStyle ?? ''} ${disabled ? 'cursor-not-allowed' : ''} `}>
            {withoutForm ? <input
                {...attributes}
                id={id}
                autoComplete={'off'}
                name={name}
                onChange={onChange}
                onBlurCapture={onBlurCapture}
                checked={checked}
                disabled={disabled ?? false}
                type="checkbox"
                className={`w-5 h-5 ${checked ? 'focus:ring-primary' : 'focus:ring-transparent'} focus:border-[#647188] border border-dropDownBorder hover:border-[#647188] border-borderGray border-2 rounded-sm text-primary ${className ?? ''} ${disabled ? 'cursor-not-allowed' : ''} `}
            /> : <div
                data-tip={showToolTip ? `${!!tooltipText ? tooltipText : `You must provide the clinician's signature on file ( SOF ) in order to submit this order. Please upload the required SOF for the selected clinician.`}` : (generateStyling && showToolTip) ? `${!!tooltipText ? tooltipText : `You must provide the clinician's signature on file ( SOF ) in order to submit this order. Please upload the required SOF for the selected clinician.`}` : null}
                data-iscapture="true"
                data-for={id}
                data-multiline={true}>
                <Field
                    {...attributes}
                    id={id}
                    autoComplete={'off'}
                    onBlurCapture={onBlurCapture}
                    name={name}
                    onChange={onChange}
                    checked={checked}
                    disabled={disabled ?? false}
                    type="checkbox"
                    className={`w-5  h-5 ${checked ? 'focus:ring-primary' : 'focus:ring-transparent'} focus:border-[#647188] border border-dropDownBorder hover:border-[#647188] border-borderGray border-2 rounded-sm text-primary ${className ?? ''} ${disabled ? 'cursor-not-allowed' : ''} `}
                />
                {/* {(checkBoxTextStyling && disabled) || (generateStyling && disabled) && <ReactTooltip />} */}
                {/* {(checkBoxTextStyling && showToolTip) && <ReactTooltip />} */}
                {showToolTip && (
                    <TooltipHelper
                        backgroundColor={'#18B5B7'}
                        className={'!text-xs !p-2 max-w-[300px]'}
                        effect={'float'}
                        id={id}
                        scrollHide={true}
                        multiline={true}
                    />
                )}
            </div>
            }
            {addLabelSpan ? <label
                htmlFor={id} className={`ml-2 self-center text-sm  text-royalBlue ${labelClassName ?? ''}`}>
                <span className='font-semibold text-royalBlue '>{label.split(':')[0]}:</span>{label.split(':')[1]}
                {bulletPoint && <div><p className='font-semibold text-royalBlue mb-2'>{bulletPoint.split(':')[0]}:<span className='font-normal'>{bulletPoint.split(':')[1]}</span></p></div>}
            </label> : <label tabIndex={tabIndex} htmlFor={label} className={`ml-2 ${checkBoxTextStyling ? 'text-[16px] -mt-1 font-semibold' : ''} ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''} break-normal self-center text-sm text-royalBlue ${isPage || styleCheckbox ? 'lg:whitespace-normal xl:whitespace-nowrap 2xl:whitespace-nowrap' : 'whitespace-normal'} ${labelClassName ?? ''}`}>

                {!!customLabelHTML ? <span dangerouslySetInnerHTML={{ __html: customLabelHTML }} /> : <Fragment>
                    {label.includes('<sup>') ? label.split('<sup>')[0] : label}
                    {label.includes('<sup>') && <sup>{label.split('<sup>')[1].replace('</sup>', '')}</sup>}
                </Fragment>}
            </label>}
        </div>
    }
    if (type && type === 'radio') {
        return <div className={`mr-4 mb-2 flex items-center justify-center ${checkboxContainerStyle ?? ''} `}>
            <button
                type="button"
                onBlurCapture={onBlurCapture}
                className={`w-5 h-5 border-2 hover:border-[#647188] outline-[#647188] border-dropDownBorder flex flex-col justify-center items-center rounded-full ${checked ? '!border-primary' : ''}`} onClick={() => { radioRef.current.click() }}>
                <div className={`w-2.5 h-2.5 ${checked ? 'bg-primary' : ''}`} style={{ borderRadius: '100%' }} />
            </button>
            <input
                {...attributes}
                id={id}
                ref={radioRef}
                name={name}
                onChange={onChange}
                value={value}
                onBlurCapture={onBlurCapture}
                checked={checked}
                type="radio"
                hidden
                className={`!apperance-none text-primary focus:ring-transparent border border-darkGray p-2 hidden ${inputClassName ?? ''}`}
            />
            <label htmlFor={label} className={'ml-2 self-center text-sm text-royalBlue'}>
                {label}
            </label>
        </div>
    }

    if (type && type === 'password') {
        return <div className={`my-3 h-16  w-full ${className ?? ''}`}>

            <RequiredFieldTooltip required={required} tooltipText={'Required Field'}>
                <label htmlFor={id} className={`text-xs text-royalBlue font-normal 
                ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''} 
                ${errors[name] && touched[name] ? 'text-requiredRed' : ''}`}>{label}</label>
            </RequiredFieldTooltip>

            <div className={`flex justify-between items-center border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray focus:outline-none hover:border-b hover:border-royalBlue focus:border-primary ${errors[name] && touched[name] ? 'border-requiredRed foucs:border-requiredRed' : ''}`}>
                <Field
                    {...attributes}
                    id={id}
                    autoComplete={'off'}
                    onBlurCapture={onBlurCapture}
                    name={name}
                    placeholder={placeholder}
                    type={passwordVisible ? 'text' : 'password'}
                    className={`py-2 w-full focus:ring-transparent placeholder:text-borderGray border-0 px-0 ${inputClassName ?? ''}`}
                />

                <button type="button" onClick={togglePasswordVisibility}>
                    <PasswordVisibility isVisible={passwordVisible} height={20} width={25} />
                </button>
            </div>
            {errors[name] && touched[name] && (
                <p className="text-xs text-requiredRed mt-1">{errors[name]}</p>
            )}
        </div>
    }
    if (type && type === 'masked') {
        return <div className={`my-3 h-16  w-full ${className ?? ''}`}>
            <RequiredFieldTooltip required={required} tooltipText={'Required Field'}>
                <label htmlFor={id}
                    className={`text-xs text-royalBlue font-normal 
                ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''} 
                ${errors[name] && touched[name] ? 'text-requiredRed' : ''}`}>{label}</label>
            </RequiredFieldTooltip>


            <Field
                {...attributes}
                id={id}
                name={name}
                autoComplete={'off'}
                onBlurCapture={onBlurCapture}
                placeholder={placeholder}
                render={({ field }: any) => (
                    <MaskedInput
                        {...field}
                        mask={inputMask}
                        onChange={onChange}
                        id={id}
                        placeholder={placeholder}
                        type="text"
                        className={`py-2 w-full focus:ring-transparent text-royalBlue focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder:text-borderGray ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''}`}

                    />)}
                type={type ?? 'text'}
                className={`py-2 w-full focus:ring-transparent focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder:text-borderGray ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''}`}
            />
            {errors[name] && touched[name] && (
                <p className="text-xs text-requiredRed mt-1">{errors[name]}</p>
            )}
        </div>
    }
    if (type && type === 'tel') {
        return <div className={`my-3 h-16 w-full ${className ?? ''}`}>

            <RequiredFieldTooltip required={required} tooltipText={'Required Field'}>
                <label htmlFor={id}
                    className={`text-xs text-royalBlue font-normal 
                       ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''} 
                       ${errors[name] && touched[name] ? 'text-requiredRed' : ''}`}>
                    {label}</label>
            </RequiredFieldTooltip>
            <Field
                {...attributes}
                id={id}
                name={name}
                autoComplete={'off'}
                placeholder={placeholder}
                onBlurCapture={onBlurCapture}
                render={({ field }: any) => {
                    if (onChange) {
                        return <MaskedInput
                            {...field}
                            {...attributes}
                            mask={phoneNumberMask}
                            id="phone"
                            onChange={onChange}
                            placeholder={placeholder}
                            type="text"
                            // signupFieldsStyle={signupFieldsStyle}
                            className={`py-2 w-full text-royalBlue ${isSummary && 'bg-[#F9F9FA]'} focus:ring-transparent focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder:text-borderGray ${signupFieldsStyle ?? ''} ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''}`}
                        />
                    }
                    return <MaskedInput
                        {...field}
                        {...attributes}
                        mask={phoneNumberMask}
                        id="phone"
                        placeholder={placeholder}
                        type="text"
                        className={`py-2 w-full text-royalBlue focus:ring-transparent focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder:text-borderGray ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''}`}

                    />
                }}
                type={type ?? 'text'}
                className={`py-2 w-full focus:ring-transparent focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder:text-borderGray ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''}`}
            />
            {!!customError && !!customTouched ?
                <p className="text-xs text-requiredRed mt-1">{customError}</p>
                : <Fragment>
                    {errors[name] && touched[name] && (
                        <p className="text-xs text-requiredRed mt-1">{errors[name]}</p>
                    )}
                </Fragment>}
        </div>
    }
    if ((suggestions != null) && suggestions.length > 0) {
        return <div ref={dropDownRef} className={`my-3 h-16 relative  w-full ${className ?? ''}`}>


            <RequiredFieldTooltip required={required} tooltipText={'Required Field'}>
                <label
                    htmlFor={id}
                    className={`text-xs text-royalBlue font-normal ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''} ${errors[name] && touched[name] ? 'text-requiredRed' : ''}`}>
                    {label}
                </label>
            </RequiredFieldTooltip>

            <div onClick={(e) => {
                e.stopPropagation()
                setSuggestionVisible(prevState => !prevState)
            }} className={`flex w-full justify-between relative items-center border-t-0 border-r-0 border-l-0 border-b border-borderGray focus:outline-none hover:border-b hover:border-royalBlue ${signupFieldsStyle ?? ''} ${suggestionVisible ? 'border-primary hover:border-primary' : ''} ${errors[name] && touched[name] ? 'border-requiredRed foucs:border-requiredRed' : ''}`}>
                {!!suggestionInputDisabled ? <div
                    className='py-2 w-full focus:ring-transparent placeholder:text-borderGray border-0 px-0'
                ><p className={`${!!suggestionValue ? 'text-black' : 'text-borderGray'}`}>{!!suggestionValue ? suggestionValue : placeholder}</p></div> : <Field
                    {...attributes}
                    id={id}
                    autoComplete={'off'}
                    key={name}
                    name={name}
                    onBlurCapture={onBlurCapture}
                    disabled={!!suggestionInputDisabled}
                    value={value}
                    placeholder={placeholder}
                    // initialValue={suggestionValue ?? value}
                    onChange={handleSuggestionChange}
                    type={type ?? 'text'}
                    className='py-2 w-full focus:ring-transparent placeholder:text-borderGray border-0 px-0'
                />}
                <SuggestionsDropDown
                    dropDownRef={dropDownRef}
                    // suggestionValue={suggestionValue}
                    suggestionValue={suggestionValue}
                    disableSuggestiveFiltering={disableSuggestiveFiltering}
                    disableSort={disableSort}
                    suggestionVisible={suggestionVisible}
                    suggestions={suggestions}
                    containerStyle={!!disableSuggestiveFiltering ? 'mt-1' : `${dropDownContainerStyle ? dropDownContainerStyle : ''}`}
                    itemStyle={'hover:bg-[#EDEFF1]'}
                    onChange={onChange}
                    setSuggestionValue={(val: any) => setSuggestionValue(val)}
                    setSuggestionVisible={(val: any) => setSuggestionVisible(val)}
                />
                {hideDropdownIcon && hideDropdownIcon
                    ? null
                    : <button type="button" onClick={toggleDropdownVisibility} className={`${suggestionVisible ? 'rotate-180 -mt-2 mr-3' : '-mb-5'}`}>
                        <DropDownIcon onClick={toggleDropdownVisibility} height={20} width={25} />
                    </button>}
                {isSearching && <div className={'absolute right-5 top-2'}>
                    <LoadingSvg isSecondary className={'animate-spin'} />
                </div>}
            </div>
            {errors[name] && touched[name] && (
                <p className="text-xs text-requiredRed mt-1">{errors[name]}</p>
            )}
        </div>
    }
    if (type && type === 'date') {
        return <div className={`my-3 h-16 w-full relative ${className ?? ''} ${disabled && 'cursor-not-allowed'}`}>
            <RequiredFieldTooltip required={required} tooltipText={'Required Field'}>
                <label
                    htmlFor={id}
                    className={`text-xs ${disabled && 'cursor-not-allowed'} text-royalBlue font-normal ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''} ${errors[name] && touched[name] ? 'text-requiredRed' : ''}`}>
                    {label}
                </label>
            </RequiredFieldTooltip>
            <DatePicker
                selected={!!value ? dateFormatter(value) : null}
                // selected={!!value ? new Date(value) : null}
                placeholderText={'MM/DD/YYYY'}
                maxDate={settingMaxDate}
                scrollableYearDropdown
                showYearDropdown
                filterDate={filterDateFunction}
                customInput={
                    <MaskedInput
                        {...attributes}
                        type="text"
                        mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                    />
                }
                disabled={disabled}
                yearDropdownItemNumber={100}
                onBlur={onBlurCapture}
                dateFormat={'MM/dd/yyyy'}
                className={`py-2 w-full focus:ring-transparent ${inputClassName} ${disabled && 'cursor-not-allowed'} ${value ? 'text-royalBlue' : 'text-borderGray'} focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder-borderGray ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''}`}
                onChange={(date: Date) => {
                    if (date === null) {
                        onChange('')
                    } else {
                        onChange(moment(date).format('MM-DD-YYYY'))
                    }
                }}
            />
            {errors && errors[name] && touched && touched[name] && (
                <p className="text-xs text-requiredRed mt-1">{errors[name]}</p>
            )}
        </div>
    }

    if (type && type === 'time') {
        return <div className={`my-3 h-16 w-full relative ${className ?? ''} ${disabled && 'cursor-not-allowed'}`}>
            <RequiredFieldTooltip required={required} tooltipText={'Required Field'}>
                <label
                    htmlFor={id}
                    className={`text-xs ${disabled && 'cursor-not-allowed'} text-royalBlue font-normal ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''} ${errors[name] && touched[name] ? 'text-requiredRed' : ''}`}>
                    {label}
                </label>
            </RequiredFieldTooltip>
            <DatePicker
                {...attributes}
                // selected={!!value ? value : null}
                selected={!!value ? dateFormatter(value) : null}
                placeholderText={placeholder ? placeholder : 'Time'}
                showTimeSelect
                disabled={disabled}
                dateFormat={'Pp'}
                className={`py-2 w-full focus:ring-transparent ${disabled && 'cursor-not-allowed'} ${value ? 'text-royalBlue' : 'text-borderGray'} focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder-borderGray ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''}`}
                onChange={(time: any) => {
                    if (time === null) {
                        onChange('')
                    } else {
                        onChange(moment(time).format('MM-DD-YYYY'))
                    }
                }}
            />
            {errors && errors[name] && touched && touched[name] && (
                <p className="text-xs text-requiredRed mt-1">{errors[name]}</p>
            )}
        </div>
    }


    if (isInternal) {
        return <div className={`my-3 h-16 w-full relative ${className ?? ''}`}>

            <RequiredFieldTooltip required={required} tooltipText={'Required Field'}>
                <label
                    htmlFor={id}
                    className={`text-xs text-royalBlue font-normal ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''} ${errors[name] && touched[name] ? 'text-requiredRed' : ''}`}>
                    {label}
                </label>
            </RequiredFieldTooltip>
            {withoutForm
                ? <input
                    {...attributes}
                    id={id}
                    name={name}
                    autoComplete={'off'}
                    onChange={onChange}
                    onBlurCapture={onBlurCapture}
                    value={value}
                    placeholder={placeholder}
                    type={type ?? 'text'}
                    className={`py-2 w-full focus:ring-transparent focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder-borderGray ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''}`}
                />
                : <Field
                    {...attributes}
                    id={id}
                    name={name}
                    autoComplete={'off'}
                    onChange={onChange}
                    value={value}
                    onBlurCapture={onBlurCapture}
                    placeholder={placeholder}
                    type={type ?? 'text'}
                    className={`py-2 w-full ${isSummary && 'bg-[#F9F9FA]'} focus:ring-transparent focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder-borderGray ${signupFieldsStyle ?? ''} ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''}`}
                />}
            {isSearching && <div className='absolute right-0 top-6'>
                <LoadingSvg isSecondary className={'animate-spin'} />
            </div>}
            {!!customError && !!customTouched ?
                <p className="text-xs text-requiredRed mt-1">{customError}</p>
                : <Fragment>
                    {errors[name] && touched[name] && (
                        <p className="text-xs text-requiredRed mt-1">{errors[name]}</p>
                    )}
                </Fragment>}
        </div>
    }
    if (withoutForm) {
        return <div className={`my-3 h-16 w-full ${className ?? ''}`}>
            <RequiredFieldTooltip required={required} tooltipText={'Required Field'}>
                <label
                    htmlFor={id}
                    className={`text-xs text-royalBlue font-normal ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''} ${errors[name] && touched[name] ? 'text-requiredRed' : ''}`}>
                    {label}
                </label>
            </RequiredFieldTooltip>

            <input
                {...attributes}
                id={id}
                name={name}
                autoComplete={'off'}
                value={value}
                placeholder={placeholder}
                onBlurCapture={onBlurCapture}
                type={type ?? 'text'}
                className={`py-2 w-full bg-transparent focus:ring-transparent focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder:text-borderGray ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''}`}
            />
        </div>
    }
    return <div className={`my-3 ${isTextArea ? 'max-h-[100px] overflow-y-auto' : 'h-16'} w-full ${className ?? ''}`}>

        <RequiredFieldTooltip required={required} tooltipText={'Required Field'}>
            <label htmlFor={id}
                className={`text-xs text-royalBlue font-normal 
                ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''} 
                ${errors[name] && touched[name] ? 'text-requiredRed' : ''}`}>{label}</label>
        </RequiredFieldTooltip>

        {isTextArea ? <div
            {...attributes}
            id={id}
            role="textarea"
            contentEditable={true}
            data-text="Enter text"
            style={{ resize: 'block' }}
            placeholder={placeholder}
            // onInput={onChange}
            onBlur={onChange}
            className={`py-2 block w-full min-h-10 outline-none focus:ring-transparent focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder:text-borderGray ${signupFieldsStyle ?? ''} ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''}`}
        >{value}</div> : <Field
            id={id}
            name={name}
            autoComplete={'off'}
            value={value}
            placeholder={placeholder}
            type={type ?? 'text'}
            className={` py-2 w-full focus:ring-transparent focus:border-primary foucs:!outline-none border-t-0 border-r-0 border-l-0 px-0 border-b border-borderGray hover:border-b hover:border-royalBlue placeholder:text-borderGray ${signupFieldsStyle ?? ''} ${errors[name] && touched[name] ? 'border-requiredRed focus:border-requiredRed' : ''} ${inputClassName ?? ''}`}
        />}
        {errors && errors[name] && touched && touched[name] && (
            <p className="text-xs text-requiredRed mt-1">{errors[name]}</p>
        )}
    </div>
}

export default CustomInput
