import { motion } from 'framer-motion'
import CheckMarkSVG from '../../assets/svg/CheckMarkSVG'

function VerificationSuccess () {
  return <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex flex-col h-full justify-center items-center">
        <CheckMarkSVG />
        <div className="w-4/6 md:w-2/6 text-center p-5">
            <p className="text-royalBlue font-medium">Thankyou for confirming your email address. You will be notified
                when your account is ready in the next 24 to 48 hours</p>
        </div>
    </motion.section>
}

export default VerificationSuccess
