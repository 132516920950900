/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import LogoSVG from '../../assets/svg/Logo'
import ManagerSignUpForm from '../../components/organism/ManagerSignUpForm'
import { connect, useSelector } from 'react-redux'
import { useOktaAuth } from '@okta/okta-react'
import SuffixAndSpecialityActions from '../../Store/Actions/SuffixAndSpecialtyActions'
import NotificationActions from '../../Store/Actions/NotificationsActions'
import PhysicianSignUpForm from '../../components/organism/PhysicianSignUpForm'

function RenderForm({ role }: { role: string }): any {

    if (role === 'Physician') {
        return <PhysicianSignUpForm
            isCompletion
            signUp={undefined}
            updateUser={undefined}
        />
    }
    return <ManagerSignUpForm
        isCompletion
        signUp={undefined}
        updateUser={undefined}
    />
}

function ProfileCompletion({ getSuffix, getSpeciality }: any): JSX.Element {
    const { profile } = useSelector((state: any) => state.Profile)
    const { authState } = useOktaAuth()

    function userRole() {
        let role = ''

        if (profile?.roles && profile?.roles?.length === 1) {
            role = profile?.roles[0]
        } else if (profile?.roles?.length > 1) {
            role = profile?.roles?.includes('Physician') ? 'Clinician' : 'Office Manager'
        }

        return role
    }

    useEffect(() => {
        // console.log({ profile })
        getSuffix({
            data: {
                token: authState?.accessToken?.accessToken
            },
            onError: () => {
                alert('error occured')
            }
        })
        getSpeciality({
            data: {
                token: authState?.accessToken?.accessToken
            },
            onError: () => {
                alert('error occured')
            }
        })
    }, [])

    return <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={'flex h-screen justify-between border border-gray'}
    >
        <section className='flex h-full w-3/6 bg-gradient-to-b from-[#18B5B7] to-[#084E8B] border border-darkGray' />
        <section className='flex flex-col h-full w-3/6 justify-center items-center'>
            <div className='w-4/6 h-full flex flex-col items-stretch overflow-auto'>

                <div className='mt-10 mb-6'>
                    <LogoSVG height={38} width={112} className={'my-4'} />
                    <h3 className='text-2xl font-bold text-royalBlue text-left mt-1'>
                        Profile Completion
                    </h3>

                    <h5 className='text-base font-normal text-royalBlue text-left'>Please complete your profile</h5>

                </div>
                {profile && <RenderForm
                    role={userRole()}
                // role={profile.roles[0]}
                // role={profile.roles.includes('Physician') ? 'Physician' : 'Office Manager'}
                />}
            </div>
        </section>
    </motion.section>
}
const mapDispatchToProps = {
    getSpeciality: SuffixAndSpecialityActions.fetchSpeciality,
    getSuffix: SuffixAndSpecialityActions.fetchSuffix,
}
export default connect(null, mapDispatchToProps)(ProfileCompletion)
