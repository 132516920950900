import Tracker from '@openreplay/tracker';
import envVars from '../../env-configs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const tracker = new Tracker({
  projectKey: envVars.REACT_APP_OPEN_REPLAY_PROJECT_KEY,
  // TO record localhost session please set DISABLE SECURE MODE to true otherwise false
  __DISABLE_SECURE_MODE: true,
  ingestPoint: envVars.REACT_APP_OPEN_REPLAY_INGEST_POINT,
  network: {
    capturePayload: true,
    failuresOnly: true,
    ignoreHeaders: false,
    sessionTokenHeader: '',
    captureInIframes: false
  },
  defaultInputMode: 0
});

function useReplay() {
  const [replayTracker, setReplayTracker] = useState(tracker)
  const { profile } = useSelector((state: any) => state.Profile);

  useEffect(() => {
    replayTracker.start()

    if (profile && profile?.id) {
      replayTracker.setUserID(profile?.email);
      replayTracker.setMetadata('recordId', profile?.id);
    }

    // return () => {
    //   replayTracker.stop()
    // }

  }, [profile?.id])

  return replayTracker
}

export default useReplay;