import * as React from 'react'
import { SVGProps } from 'react'

const SideMenuIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={21.5} height={16.5} {...props}>
        <g
            data-name="Component 5928 \u2013 5"
            fill="none"
            stroke="#172b4d"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        >
            <path data-name="Line 827" d="M.75.75h20" />
            <path data-name="Line 828" d="M.75 8.25h20" />
            <path data-name="Line 829" d="M.75 15.75h20" />
        </g>
    </svg>
)

export default SideMenuIcon
