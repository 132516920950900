import React from "react";
import { connect } from "react-redux";
function StatusItem({ label, valid, hideRightBorder,data }: any): JSX.Element {
  return <div className="h-[90px] block w-full">
    <div className="flex items-center">
      <div className={`w-[20px] flex flex-col items-center justify-center h-[20px] border border-dropDownBorder bg-dropDownBorder animate-pulse rounded-full`}>
        {/* <TickIcon
          stroke={valid ? 'white' : undefined} /> */}
      </div>
      {hideRightBorder ? null :
        <div className={`h-[1px] w-[calc(100%_-_20px)] bg-[#DCDFE4] animate-pulse`} />
      }
    </div>
    <div className="w-full -ml-7">
      <p className="!text-sm text-dropDownBorder animate-pulse rounded-lg max-w-[80px] mt-2 text-center bg-dropDownBorder">{label}</p>
    </div>
  </div>
}
function SummaryHeaderLoadingIndicator({ data }: any): JSX.Element {
  const statuses = [
    {
      label: 'In Progress',
      valid: true
    },
    {
      label: 'Pending Submission',
      valid: false
    },
    {
      label: 'Pending Approval',
      valid: false
    },
    {
      label: 'Submitted',
      valid: false
    },
    {
      label: 'Pending Tissue Receipt',
      valid: false
    },
    {
      label: 'Pending Results',
      valid: false
    },
    {
      label: 'Completed',
      valid: false
    },
  ]
  return (
    <>
     {data?.orderStatus.includes("Pending Approval") && (
      <div className="bg-dropDownBorder ml-28 2xl:w-[80%] xl:w-[78%] lg:w-[75%] py-3 rounded-2xl animate-pulse mt-9">
        </div>
     )}
    <div className="sticky top-0 bg-white pt-7 pb-5 z-20 border border-white ml-32">
      <div className="flex items-center justify-center">
        {statuses.map((status: any) => <StatusItem
          label={status.label}
          hideRightBorder={status.label === 'Completed'}
          valid={status.valid}
          key={status.label} />)}
      </div>
    </div>
    </>
  );
}
const mapStateToProps = (state: any) => ({
  data: state.OnlineOrderForm.orderData,
});
export default connect(mapStateToProps)(SummaryHeaderLoadingIndicator);