import * as React from "react"
import { SVGProps } from 'react'

const OrganizationNoImage = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={316} height={68} {...props}>
    <g data-name="Group 14089" strokeLinecap="round">
      <g
        data-name="Rectangle 4262"
        fill="#edeff1"
        stroke="#d0d4db"
        strokeDasharray={2}
      >
        <rect width={316} height={68} rx={6} stroke="none" />
        <rect x={0.5} y={0.5} width={315} height={67} rx={5.5} fill="none" />
      </g>
      <g
        data-name="Group 14088"
        transform="translate(140.063 16.063)"
        fill="none"
        stroke="#b1b8c3"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path data-name="Line 1297" d="M22.938 10.938h0" />
        <rect
          data-name="Rectangle 4263"
          width={28}
          height={28}
          rx={3}
          transform="translate(3.938 3.938)"
        />
        <path
          data-name="Path 5220"
          d="m4.392 23.017 6.773-6.773a3.411 3.411 0 0 1 5.079 0l8.466 8.466"
        />
        <path
          data-name="Path 5221"
          d="m21.324 21.323 1.693-1.693a3.411 3.411 0 0 1 5.079 0l3.386 3.386"
        />
      </g>
    </g>
  </svg>
)

export default OrganizationNoImage;
