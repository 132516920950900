import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { resetSelectedPathLab, updateSelectedPathLab } from "../../Store/Actions/OnlineOrderFormActions";
import OrganizationActions from "../../Store/Actions/OrganizationsActions";
import Dropdown from "./Dropdown";
import toastMessages from "../../utils/helpers/toastMessage";
import { useOktaAuth } from "@okta/okta-react";
import ProfileActions from "../../Store/Actions/ProfileActions";
import PathLabActions from "../../Store/Actions/PathLabActions";

function PathLabDropdown({ fetching, pathLabs: data }: any): JSX.Element | any {
  const [pathLabs, setPathLabs] = useState<any>([])
  const { authState } = useOktaAuth()
  const { selectedPathLab } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const dispatch: any = useDispatch()


  function PathLabSelect(val: any): void {
    const selectedOrg = data.filter(
      (e: any) => e?.id === val?.id
    )[0];

    if (val.id === 0) {
      return dispatch(resetSelectedPathLab())
    }

    dispatch(updateSelectedPathLab(selectedOrg));
  }




  function fetchPathLabs() {
    const options = {
      onError: (message: string) => toastMessages.error(message ?? 'Failed to fetch path labs, Please try again'),
      onEmpty: () => {
        // toastMessages.error('You are not associated to any Pathology Lab')
      },
      config: {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`
        }
      }
    }
    dispatch(PathLabActions.fetchPathlabs(options))
  }

  useEffect(() => {
    let foundPathLabs = data?.map((e: any) => {
      return {
        id: e.id,
        label: e?.name,
        image: e?.logoUrl,
        city: e.city,
        state: e.state,
        address: e.address,
      };
    });
    if (foundPathLabs) {
      setPathLabs([
        { id: 0, label: "All Pathlab" },
        ...foundPathLabs,
      ]);
    }

  }, [fetching])

  if (fetching)
    return <div className="p-2.5 h-[25px] bg-dropDownBorder rounded-md mx-3 w-[120px] animate-pulse" />

  return <Fragment>
    <Dropdown
      handleChange={(val: any) => PathLabSelect(val)}
      text="All Path Labs"
      selectedValue={selectedPathLab?.name}
      selectedImage={selectedPathLab?.logoUrl}
      isOrg={true}
      data={pathLabs}
      loadMoreOrgs={fetchPathLabs}
      style={{
        minWidth: pathLabs == null ? "110%" : "309px",
        maxHeight: "200px",
      }}
    />

  </Fragment>
}

const mapStateToProps = (state: any) => ({
  fetching: state.PathLab.fetching,
  error: state.PathLab.error,
  pathLabs: state.PathLab.data
})

const mapDispatchToProps = {
  fetchOrganizationUsers: OrganizationActions.fetchOrganizationUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(PathLabDropdown)