import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import CheckMarkSVG from '../../../assets/svg/CheckMarkSVG'
import Button from '../../../components/atomic/Button'
import useQuery from '../../../utils/helpers/QueryHelper'

function OrdersAuthorized(): JSX.Element {
  const history = useHistory()
  const query = useQuery()
  const [ids, setIds] = useState<any>([])


  useEffect(() => {
    setIds(query.get('ids')?.split(','))
  }, [])

  function handleDashboard() {
    history.push('/dashboard')
  }
  function handleNewOrder() {
    history.push('/test-orders/online-order')
  }
  return <motion.section
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="flex flex-col h-full justify-center items-center">
    <CheckMarkSVG />
    <div className="w-4/6 md:w-2/6 text-center p-5">
    The order(s) has been approved and submitted successfully.
    </div>
    <div className="flex items-center">

      <Button
        btnType="secondary"
        title={'Go to dashboard'}
        onClick={handleDashboard}
        className={'mr-6'}
      />
      <Button
        btnType="primary"
        title={'order new online order'}
        onClick={handleNewOrder}
      />
    </div>
  </motion.section>
}

export default OrdersAuthorized
