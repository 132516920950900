import reduxActionType from '../../models/reduxActionType'
import { FETCH_PATHLAB, FETCH_PATHLAB_ERROR, FETCH_PATHLAB_SUCCESS, LOGOUT } from '../Actions/types'

const initialState = {
  fetching: true,
  error: false,
  data: []
}

function PathLabReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case FETCH_PATHLAB: {
      return {
        ...state,
        fetching: true,
        error: false
      }
    }
    case FETCH_PATHLAB_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        // data: [...action.payload]
        data: action.payload.map((item: any) => ({ ...item, ...item?.profile }))
      }
    }
    case FETCH_PATHLAB_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true
      }
    }

    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default PathLabReducer
