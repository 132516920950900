import moment from "moment";

function dateFormatter(date: string | null | undefined) {
  let convertedDate: any = null;


  // Check default MM-DD-YYYY format
  if (!!date) {
    let parsingDate = date.split('-')
    if (parsingDate.length === 3) {
      // YYYY-DD-MM
      let dateString = `${parsingDate[2]}-${parsingDate[0]}-${parsingDate[1]}`
      convertedDate = new Date(dateString.replace(/-/g, '\/').replace(/T.+/, ''))
    }
  }

  // if fails then check YYYY-MM-DD format
  if (isNaN(convertedDate?.getFullYear())) {
    const dateString = !!date ? date : ''
    const newDate = new Date(dateString)

    convertedDate = isNaN(newDate?.getFullYear()) ? null : newDate
  }

  return convertedDate
}

export default dateFormatter