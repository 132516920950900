import * as React from 'react'
import { SVGProps } from 'react'

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 15.142}
        height={props.height ?? 17.5}
        {...props}
    >
        <g
            data-name="Component 6356 \u2013 529"
            fill="none"
            stroke={props.stroke ?? '#647188'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        >
            <path
                data-name="Path 5130"
                d="M.75 14.506h3.51l9.212-9.428a2.579 2.579 0 0 0 0-3.59 2.443 2.443 0 0 0-3.51 0L.75 10.916v3.59"
            />
            <path data-name="Line 1262" d="m9.085 2.391 3.51 3.59" />
            <path data-name="Line 1263" d="M.75 16.75h13.6" />
        </g>
    </svg>
)

export default EditIcon
