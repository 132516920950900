/* eslint-disable @typescript-eslint/space-before-function-paren */
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import FormTabCheck from '../../assets/svg/FormTabCheck'
import CustomInput from '../global/CustomInput'
import SidebarLoadingIndicator from '../global/LoadingIndicators/CreateOnlineOrderIndicators/SidebarLoadingIndicator'

function Tab({ tab, handleTab, selectedTab, isValid, testId }: { testId: any, tab: { id: string, label: string }, handleTab: any, selectedTab: string, isValid: boolean }): JSX.Element {

  return <button
    onClick={handleTab(tab.id)}
    className={`flex items-center text-royalBlue text-sm lg:text-sm text-left block capitalize p-1 mb-1 hover:text-activeLink hover:font-bold hover:lg:text-sm hover:text-sm visited:text-royalBlue ${selectedTab === tab.id ? 'font-bold text-activeLink hover:font-bold' : ''} ${isValid ? 'text-primary font-medium' : ''}`}
  >
    <div className={`text-left`}><FormTabCheck
      className="mr-1 !text-xl"
      fill={isValid ? "#18B5B7" : "none"}
      isvalid={isValid ? "white" : "#d0d4db"}
      stroke={isValid ? "#18B5B7" : "#d0d4db"}
    />
    </div>
    <div className='break-normal'>
      {testId === 8 && tab.label === 'Laboratory Information' ? 'Specimen Information'
        : (tab.id === 'clinical info' && testId === 8) ? 'Patient Healthcare Information'
          : tab.label}
    </div>

  </button>
}

function ApprovalTabs({ selectedTab, setSelectedTab, validSections, fetching, testId, label, handleCheckboxChange, checked, index }: any): JSX.Element {
  // const tabs = ['select test', 'patient info', 'billing info & medicare', 'clinical info', 'additional info', 'laboratory info', 'supporting documents']
  const tabs = [{
    id: 'select test',
    label: 'Select Test',
  },
  {
    id: 'patient info',
    label: 'Patient Information'
  },
  {
    id: 'billing info & medicare',
    label: 'billing information'
  },
  {
    id: 'clinical info',
    label: 'Clinical Information'
  },
  {
    id: "additional info",
    label: "Additional Information",
  },
  {
    id: "laboratory info",
    label: "Laboratory Information"
  },
  {
    id: "supporting documents",
    label: "supporting documents"
  }]


  const scroll = (tab: string): any => {
    const section: any = document.getElementById(`${label}${tab}`)
    section.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  function handleTab(val: string) {
    return () => {
      scroll(val)
      setSelectedTab(val)
    }
  }

  if (fetching) {
    return <SidebarLoadingIndicator />
  } else {
    return <div className={`px-5 py-2 ${index === 0 ? 'pt-5' : ''}`}>
      <div className='w-full flex flex-col items-start'>
        <CustomInput
          name='name'
          type='checkbox'
          placeholder=''
          id=''
          required={false}
          checked={checked}
          errors={{}}
          touched={{}}
          withoutForm
          className='mb-0 w-4 h-4'
          label={label ?? ''}
          labelClassName={`!text-sm font-semibold text-[#084E8B] `}
          isInternal
          onChange={handleCheckboxChange}
        />
      </div>
      <div className="bg-[#F9F9FA]  px-6 break-all py-1">
        {testId === 1
          ? tabs.filter(item => item.id !== 'clinical info').map((tab, index) => <Tab
            key={tab.id}
            tab={tab}
            selectedTab={selectedTab}
            isValid
            handleTab={handleTab}
            testId={testId}
          />)
          : tabs.map((tab, index) => <Tab
            key={tab.id}
            tab={tab}
            selectedTab={selectedTab}
            isValid
            handleTab={handleTab}
            testId={testId}
          />)}
      </div>
    </div>
  }
}

export default ApprovalTabs
