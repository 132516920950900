import moment from "moment";
import NotificationsDataModel from "../models/notificationsModels/notificationsData.model";

const insuranceList = ["Self pay", "Client Bill"];

const ICD10CodeList = [
 {
  testId: 1,
  testName: "TC",
  codes: [
   { label: "BE without dysplasia", value: "K22.70" },
   { label: "BE with low grade dysplasia", value: "K22.710" },
   { label: "BE with dysplasia, unspecified", value: "K22.719" },
  ],
 },
 {
  testId: 2,
  testName: "SCC",
  codes: [
   { label: "Squamous cell carcinoma of skin of lip", value: "C44.02" },
   {
    label:
     "Squamous cell carcinoma of skin of unspecified eyelid, including canthus",
    value: "C44.121",
   },
   {
    label: "Squamous cell carcinoma of skin of upper eyelid, including canthus",
    value: "C44.1221",
   },
   {
    label:
     "Squamous cell carcinoma of skin of right lower eyelid, including canthus",
    value: "C44.1222",
   },
   {
    label:
     "Squamous cell carcinoma of skin of left upper eyelid, including canthus",
    value: "C44.1291",
   },
   {
    label:
     "Squamous cell carcinoma of skin of left lower eyelid, including canthus",
    value: "C44.1292",
   },
   {
    label:
     "Squamous cell carcinoma of skin of unspecified ear and external auricular canal",
    value: "C44.221",
   },
   {
    label:
     "Squamous cell carcinoma of skin or right ear and external auricular canal",
    value: "C44.222",
   },
   {
    label:
     "Squamous cell carcinoma of skin or left ear and external auricular canal",
    value: "C44.229",
   },
   {
    label: "Squamous cell carcinoma of skin of unspecified parts of face",
    value: "C44.320",
   },
   { label: "Squamous cell carcinoma of skin of nose", value: "C44.321" },
   {
    label: "Squamous cell carcinoma of skin of other parts of face",
    value: "C44.329",
   },
   {
    label: "Squamous cell carcinoma of skin of scalp and neck",
    value: "C44.42",
   },
   { label: "Squamous cell carcinoma of anal skin", value: "C44.520" },
   { label: "Squamous cell carcinoma of skin of breast", value: "C44.521" },
   {
    label: "Squamous cell carcinoma of skin of other part of trunk",
    value: "C44.529",
   },
   {
    label:
     "Squamous cell carcinoma of skin of unspecified upper limb, including shoulder",
    value: "C44.621",
   },
   {
    label:
     "Squamous cell carcinoma of skin of right upper limb, including shoulder",
    value: "C44.622",
   },
   {
    label:
     "Squamous cell carcinoma of skin of left upper limb, including shoulder",
    value: "C44.629",
   },
   {
    label:
     "Squamous cell carcinoma of skin of unspecified lower limb, including hip",
    value: "C44.721",
   },
   {
    label: "Squamous cell carcinoma of skin of right lower limb, including hip",
    value: "C44.722",
   },
   {
    label: "Squamous cell carcinoma of skin of left lower limb, including hip",
    value: "C44.729",
   },
   {
    label: "Squamous cell carcinoma of overlapping sites of skin",
    value: "C44.82",
   },
   {
    label: "Squamous cell carcinoma of skin, unspecified",
    value: "C44.92",
   },
  ],
 },
 {
  testId: 3,
  testName: "CM",
  codes: [
   { label: "Malignant melanoma of lip", value: "C43.0" },
   {
    label: "Malignant melanoma of unspecified eyelid, including canthus",
    value: "C43.10",
   },
   {
    label: "Malignant melanoma of right upper eyelid, including canthus",
    value: "C43.111",
   },
   {
    label: "Malignant melanoma of right lower eyelid, including canthus",
    value: "C43.112",
   },
   {
    label: "Malignant melanoma of left upper eyelid, including canthus",
    value: "C43.121",
   },
   {
    label: "Malignant melanoma of left lower eyelid, including canthus",
    value: "C43.122",
   },
   {
    label: "Malignant melanoma of unspecified ear and external auricular canal",
    value: "C43.20",
   },
   {
    label: "Malignant melanoma of right ear and external auricular canal",
    value: "C43.21",
   },
   {
    label: "Malignant melanoma of left ear and external auricular canal",
    value: "C43.22",
   },
   {
    label: "Malignant melanoma of unspecified part of face",
    value: "C43.30",
   },
   { label: "Malignant melanoma of nose", value: "C43.31" },
   { label: " Malignant melanoma of other parts of face", value: "C43.39" },
   { label: "Malignant melanoma of scalp and neck", value: "C43.4" },
   { label: "Malignant melanoma of anal skin", value: "C43.51" },
   { label: "Malignant melanoma of skin of breast", value: "C43.52" },
   { label: "Malignant melanoma of other part of trunk", value: "C43.59" },
   {
    label: "Malignant melanoma of unspecified upper limb, including shoulder",
    value: "C43.60",
   },
   {
    label: "Malignant melanoma of right upper limb, including shoulder",
    value: "C43.61",
   },
   {
    label: "Malignant melanoma of left upper limb, including shoulder",
    value: "C43.62",
   },
   {
    label: "Malignant melanoma of unspecified lower limb, including hip",
    value: "C43.70",
   },
   {
    label: "Malignant melanoma of right lower limb, including hip",
    value: "C43.71",
   },
   {
    label: "Malignant melanoma of left lower limb, including hip",
    value: "C43.72",
   },
   {
    label: "Malignant melanoma of overlapping sites of skin",
    value: "C43.8",
   },
   { label: "Malignant melanoma of skin, unspecified", value: "C43.9" },
   { label: "Malignant melanoma of skin, unspecified", value: "C43.9" },
   {
    label:
     "Secondary and unspecified malignant neoplasm of inguinal and lower limb lymph nodes",
    value: "C77.4",
   },
  ],
 },
 {
  testId: 4,
  testName: "UM",
  codes: [
   { label: "Malignant neoplasm of right choroid", value: "C69.31" },
   { label: "Malignant neoplasm of left choroid", value: "C69.32" },
   { label: "Malignant neoplasm of right ciliary body", value: "C69.41" },
   { label: "Malignant neoplasm of left ciliary body", value: "C69.42" },
   {
    label: "Malignant neoplasm of unspecified site of right eye",
    value: "C69.91",
   },
   {
    label: "Malignant neoplasm of unspecified site of left eye",
    value: "C69.92",
   },
   {
    label: "Malignant neoplasm of unspecified site of unspecified eye",
    value: "C69.90",
   },
   { label: "Secondary Malignant neoplasm of liver", value: "C78.7" },
  ],
 },
 {
  testId: 5,
  testName: "DDM",
  codes: [
   {
    label: "Neoplasm of unspecified behavior of bone, soft tissue, and skin",
    value: "D49.2",
   },
   { label: "Neoplasm of uncertain behavior of skin", value: "D48.5" },
   { label: "Melanocytic nevi, unspecified", value: "D22.9" },
   {
    label: "Melanocytic nevi of left lower limb, including hip",
    value: "D22.72",
   },
   {
    label: "Melanocytic nevi of right lower limb, including hip",
    value: "D22.71",
   },
   {
    label: "Melanocytic nevi of unspecified lower limb, including hip",
    value: "D22.70",
   },
   {
    label: "Melanocytic nevi of left upper limb, including shoulder",
    value: "D22.62",
   },
   {
    label: "Melanocytic nevi of right upper limb, including shoulder",
    value: "D22.61",
   },
   {
    label: "Melanocytic nevi of unspecified upper limb, including shoulder",
    value: "D22.60",
   },
   { label: "Melanocytic nevi of trunk", value: "D22.5" },
   { label: "Melanocytic nevi of scalp and neck", value: "D22.4" },
   { label: "Melanocytic nevi of other parts of face", value: "D22.39" },
   {
    label: "Melanocytic nevi of unspecified part of face",
    value: "D22.30",
   },
   {
    label: "Melanocytic nevi of left ear and external auricular canal",
    value: "D22.22",
   },
   {
    label: "Melanocytic nevi of right ear and external auricular canal",
    value: "D22.21",
   },
   {
    label: "Melanocytic nevi of unspecified ear and external auricular canal",
    value: "D22.20",
   },
   {
    label: "Melanocytic nevi of unspecified eyelid, including canthus",
    value: "D22.10",
   },
   { label: "Melanocytic nevi of lip", value: "D22.0" },
   {
    label: "Melanocytic nevi of right upper eyelid, including canthus",
    value: "D22.111",
   },
   {
    label: "Melanocytic nevi of right lower eyelid, including canthus",
    value: "D22.112",
   },
   {
    label: "Melanocytic nevi of left upper eyelid, including canthus",
    value: "D22.121 ",
   },
   {
    label: "Melanocytic nevi of left lower eyelid, including canthus",
    value: "D22.122",
   },
  ],
 },
];
// <sup style={{ verticalAlign: 'super', marginTop:'10px' }}>®</sup>
const isAltheaDxNeuropsychotropicMedicationsValues = [
 {
  id: 1,
  label: "amitriptyline (Elavil® - TCA)",
  value: "amitriptyline",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 2,
  label: "amphetamine (Adderall® - Stimulant)",
  value: "amphetamine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 3,
  label: "aripiprazole (Abilify® - SGA)",
  value: "aripiprazole",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 4,
  label: "atomoxetine (Strattera® - NRI)",
  value: "atomoxetine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 5,
  label: "brexpiprazole (Rexulti® - SGA)",
  value: "brexpiprazole",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 6,
  label: "celecoxib (Celebrex® - NSAID)",
  value: "celecoxib",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 7,
  label: "citalopram (Celexa® - SSRI)",
  value: "citalopram",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 8,
  label: "clobazam (Onfi® - Benzodiazepine)",
  value: "clobazam",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 9,
  label: "clomipramine (Anafranil® - TCA)",
  value: "clomipramine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 10,
  label: "clozapine (Clozaril®, Fazaclo®, Versacloz® - SGA)",
  value: "clozapine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 11,
  label: "codeine  (Opioid)",
  value: "codeine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 12,
  label: "desipramine (Norpramin® - TCA)",
  value: "desipramine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 13,
  label: "doxepin (Silenor® - TCA)",
  value: "doxepin",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 14,
  label: "escitalopram (Lexapro® - SSRI)",
  value: "escitalopram",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 15,
  label: "flurbiprofen (Ansaid® - NSAID)",
  value: "flurbiprofen",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 16,
  label: "fluvoxamine (Luvox® - SSRI)",
  value: "fluvoxamine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 17,
  label: "fosphenytoin (Cerebyx® - Anticonvulsant)",
  value: "fosphenytoin",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 18,
  label: "hydrocodone (Hysingla® - Opioid)",
  value: "hydrocodone",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 19,
  label: "ibuprofen (Advil®, Motrin® - NSAID)",
  value: "ibuprofen",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 20,
  label: "iloperidone (Fanapt® - SGA)",
  value: "iloperidone",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 21,
  label: "imipramine (Tofranil™ - TCA)",
  value: "imipramine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 22,
  label: "meloxicam (Mobic® - NSAID)",
  value: "meloxicam",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 23,
  label: "nortriptyline (Pamelor™ - TCA)",
  value: "nortriptyline",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 24,
  label: "paroxetine (Paxil®, Brisdelle®, Pexeva® - SSRI)",
  value: "paroxetine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 25,
  label: "perphenazine (Trilafon® - First Generation Antipsychotic)",
  value: "perphenazine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 26,
  label: "phenytoin (Dilantin® - Anticonvulsant)",
  value: "phenytoin",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 27,
  label: "piroxicam (Feldene® - NSAID)",
  value: "piroxicam",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 28,
  label: "risperidone (Risperdal® - SGA)",
  value: "risperidone",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 29,
  label: "sertraline (Zoloft® - SSRI)",
  value: "sertraline",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 30,
  label: "thioridazine (Mellaril® - First Generation Antipsychotic)",
  value: "thioridazine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 31,
  label: "tramadol (Ultram® - Opioid)",
  value: "tramadol",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 32,
  label: "venlafaxine (Effexor® - SNRI)",
  value: "venlafaxine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
 {
  id: 33,
  label: "vortioxetine (Trintellix® - Serotonin Modulator and Stimulator)",
  value: "vortioxetine",
  name: "isAltheaDxNeuropsychotropicMedications",
 },
];
const isIDgNeuropsychotropicMedicationsValues = [
 {
  id: 0,
  label: "-none-",
  value: "-none-",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 1,
  label: "abatacept (Orencia®)",
  value: "abatacept",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 2,
  label: "adalimumab (Humira®)",
  value: "adalimumab",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 3,
  label: "almotriptan (Axert®)",
  value: "almotriptan",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 4,
  label: "alprazolam (Xanax®)",
  value: "alprazolam",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 5,
  label: "amitriptyline (Elavil)",
  value: "amitriptyline",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 6,
  label: "amphetamine (Adderall®)",
  value: "amphetamine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 7,
  label: "anakinra (Kineret®)",
  value: "anakinra",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 8,
  label: "aripiprazole (Abilify®)",
  value: "aripiprazole",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 9,
  label: "asenapine (Saphris®)",
  value: "asenapine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 10,
  label: "atomoxetine (Strattera®)",
  value: "atomoxetine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 11,
  label: "azathioprine (Imuran®, Azasan®)",
  value: "azathioprine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 12,
  label: "brexpiprazole (Rexulti®)",
  value: "brexpiprazole",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 13,
  label: "buprenorphine (Butrans®, Belbuca®)",
  value: "buprenorphine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 14,
  label: "bupropion (Wellbutrin®, Aplenzin®, Forfivo®)",
  value: "bupropion",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 15,
  label: "buspirone (Buspar®)",
  value: "buspirone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 16,
  label: "caffeine & ergotamine (Cafergot®)",
  value: "caffeine & ergotamine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 17,
  label: "carbamazepine (Tegretol®, Carbatrol®, Epitol®, Equetro®)",
  value: "carbamazepine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 18,
  label: "cariprazine (Vraylar™)",
  value: "cariprazine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 19,
  label: "carisoprodol (Soma®)",
  value: "carisoprodol",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 20,
  label: "celecoxib (Celebrex®)",
  value: "celecoxib",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 21,
  label: "certolizumab (Cimzia®)",
  value: "certolizumab",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 22,
  label: "chlordiazepoxide (Librium®)",
  value: "chlordiazepoxide",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 23,
  label: "chlorpromazine (Thorazine®)",
  value: "chlorpromazine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 24,
  label: "citalopram (Celexa®)",
  value: "citalopram",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 25,
  label: "clobazam (Onfi®)",
  value: "clobazam",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 26,
  label: "clomipramine (Anafranil®)",
  value: "clomipramine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 27,
  label: "clonazepam (Klonopin®, Sympazan®)",
  value: "clonazepam",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 28,
  label: "clonidine (Kapvay®)",
  value: "clonidine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 29,
  label: "clozapine (Clozaril®, Fazaclo®, Versacloz®)",
  value: "clozapine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 30,
  label: "codeine",
  value: "codeine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 31,
  label: "cyclophosphamide (Cytoxan®)",
  value: "cyclophosphamide",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 32,
  label: "desipramine (Norpramin®)",
  value: "desipramine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 33,
  label: "desvenlafaxine (Pristiq®)",
  value: "desvenlafaxine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 34,
  label: "dexmethylphenidate (Focalin®)",
  value: "dexmethylphenidate",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 35,
  label: "dextroamphetamine (Dexedrine®)",
  value: "dextroamphetamine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 36,
  label: "diazepam (Valium®)",
  value: "diazepam",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 37,
  label: "diclofenac (Cambia®, Zipsor®, Cataflam®)",
  value: "diclofenac",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 38,
  label: "doxepin (Silenor®)",
  value: "doxepin",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 39,
  label: "duloxetine (Cymbalta®)",
  value: "duloxetine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 40,
  label: "eletriptan (Relpax®)",
  value: "eletriptan",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 41,
  label: "escitalopram (Lexapro®)",
  value: "escitalopram",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 42,
  label: "eslicarbazepine (Aptiom®)",
  value: "eslicarbazepine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 43,
  label: "etanercept (Enbrel®)",
  value: "etanercept",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 44,
  label: "ethosuximide (Zarontin®)",
  value: "ethosuximide",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 45,
  label: "ethotoin (Peganone®)",
  value: "ethotoin",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 46,
  label: "ezogabine (Potiga®)",
  value: "ezogabine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 47,
  label: "felbamate (Felbatol®)",
  value: "felbamate",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 48,
  label: "fentanyl (Actiq®, Fentora®)",
  value: "fentanyl",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 49,
  label: "fluoxetine (Prozac®)",
  value: "fluoxetine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 50,
  label: "flurbiprofen (Ansaid®)",
  value: "flurbiprofen",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 51,
  label: "fluvoxamine (Luvox®)",
  value: "fluvoxamine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 52,
  label: "fosphenytoin (Cerebyx®)",
  value: "fosphenytoin",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 53,
  label: "frovatriptan (Frova®)",
  value: "frovatriptan",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 54,
  label: "gabapentin (Neurontin®, Gralise®, Horizant®)",
  value: "gabapentin",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 55,
  label: "golimumab (Simponi®)",
  value: "golimumab",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 56,
  label: "guanfacine (Intuniv®)",
  value: "guanfacine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 57,
  label: "haloperidol (Haldol®)",
  value: "haloperidol",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 58,
  label: "hydrocodone (Hysingla®)",
  value: "hydrocodone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 59,
  label: "hydromorphone (Dilaudid®)",
  value: "hydromorphone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 60,
  label: "hydroxychloroquine (Plaquenil®)",
  value: "hydroxychloroquine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 61,
  label: "hydroxyzine (Vistaril®)",
  value: "hydroxyzine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 62,
  label: "ibuprofen (Advil®, Motrin®)",
  value: "ibuprofen",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 63,
  label: "iloperidone (Fanapt®)",
  value: "iloperidone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 64,
  label: "imipramine (Tofranil™)",
  value: "imipramine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 65,
  label: "indomethacin (Indocin®)",
  value: "indomethacin",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 66,
  label: "infliximab (Remicade®)",
  value: "infliximab",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 67,
  label: "lacosamide (Vimpat®)",
  value: "lacosamide",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 68,
  label: "lamotrigine (Lamictal®)",
  value: "lamotrigine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 69,
  label: "leflunomide (Arava®)",
  value: "leflunomide",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 70,
  label: "levetiracetam (Elepsia®)",
  value: "levetiracetam",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 71,
  label: "levomilnacipran (Fetzima®)",
  value: "levomilnacipran",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 72,
  label: "lisdexamfetamine (Vyvanse®)",
  value: "lisdexamfetamine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 73,
  label: "lithium (Lithobid®)",
  value: "lithium",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 74,
  label: "lorazepam (Ativan®)",
  value: "lorazepam",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 75,
  label: "loxapine (Loxitane®)",
  value: "loxapine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 76,
  label: "lurasidone (Latuda®)",
  value: "lurasidone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 77,
  label: "mefenamic acid (Ponstel®)",
  value: "mefenamic acid",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 78,
  label: "meloxicam (Mobic®)",
  value: "meloxicam",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 79,
  label: "methadone (Methadose™)",
  value: "methadone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 80,
  label: "methotrexate (Trexall™)",
  value: "methotrexate",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 81,
  label: "methsuximide (Celontin®)",
  value: "methsuximide",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 82,
  label:
   "methylphenidate (Ritalin®, Concerta®, Metadate®, Quillivant®, Daytrana®)",
  value: "methylphenidate",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 83,
  label: "milnacipran (Savella®)",
  value: "milnacipran",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 84,
  label: "mirtazapine (Remeron®)",
  value: "mirtazapine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 85,
  label: "morphine (MS Contin®)",
  value: "morphine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 86,
  label: "naproxen (Naprosyn®, Naprelan®, Aleve®)",
  value: "naproxen",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 87,
  label: "naratriptan (Amerge®)",
  value: "naratriptan",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 88,
  label: "nortriptyline (Pamelor™)",
  value: "nortriptyline",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 89,
  label: "olanzapine (Zyprexa®)",
  value: "olanzapine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 90,
  label: "oxazepam (Serax®)",
  value: "oxazepam",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 91,
  label: "oxcarbazepine (Trileptal®, Oxtellar®)",
  value: "oxcarbazepine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 92,
  label: "oxycodone (Oxycontin®, Roxicodone®)",
  value: "oxycodone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 93,
  label: "paliperidone (Invega®)",
  value: "paliperidone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 94,
  label: "paroxetine (Paxil®, Brisdelle®, Pexeva®)",
  value: "paroxetine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 95,
  label: "pentobarbital (Nembutal®)",
  value: "pentobarbital",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 96,
  label: "perampanel (Fycompa®)",
  value: "perampanel",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 97,
  label: "perphenazine (Trilafon®)",
  value: "perphenazine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 98,
  label: "phenobarbital",
  value: "phenobarbital",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 99,
  label: "phenytoin (Dilantin®)",
  value: "phenytoin",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 100,
  label: "piroxicam (Feldene®)",
  value: "piroxicam",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 101,
  label: "pregabalin (Lyrica®)",
  value: "pregabalin",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 102,
  label: "primidone (Mysoline®)",
  value: "primidone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 103,
  label: "propranolol (Inderal®, Inoprann®)",
  value: "propranolol",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 104,
  label: "quetiapine (Seroquel®)",
  value: "quetiapine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 105,
  label: "risperidone (Risperdal®)",
  value: "risperidone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 106,
  label: "rituximab (Rituxan®)",
  value: "rituximab",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 107,
  label: "rizatriptan (Maxalt®)",
  value: "rizatriptan",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 108,
  label: "rufinamide  (Banzel®)",
  value: "rufinamide ",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 109,
  label: "sertraline (Zoloft®)",
  value: "sertraline",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 110,
  label: "sulfasalazine (Azulfidine®)",
  value: "sulfasalazine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 111,
  label: "sumatriptan (Imitrex®)",
  value: "sumatriptan",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 112,
  label: "tapentadol (Nucynta®)",
  value: "tapentadol",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 113,
  label: "thioridazine (Mellaril®)",
  value: "thioridazine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 114,
  label: "tiagabine (Gabitril®)",
  value: "tiagabine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 115,
  label: "tocilizumab (Actemra®)",
  value: "tocilizumab",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 116,
  label: "tofacitinib (Xeljanz®)",
  value: "tofacitinib",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 117,
  label: "topiramate (Topamax®, Trokendi®, Qudexy®)",
  value: "topiramate",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 118,
  label: "tramadol (Ultram®)",
  value: "tramadol",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 119,
  label: "trazodone (Desyrel®)",
  value: "trazodone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 120,
  label: "valproate (Depakote®)",
  value: "valproate",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 121,
  label: "venlafaxine (Effexor®)",
  value: "venlafaxine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 122,
  label: "vigabatrin (Sabril®)",
  value: "vigabatrin",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 123,
  label: "vilazodone (Viibryd®)",
  value: "vilazodone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 124,
  label: "vortioxetine (Trintellix®)",
  value: "vortioxetine",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 125,
  label: "ziprasidone (Geodon®)",
  value: "ziprasidone",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 126,
  label: "zolmitriptan (Zomig®)",
  value: "zolmitriptan",
  name: "isIDgNeuropsychotropicMedications",
 },
 {
  id: 127,
  label: "zonisamide (Zonegran®)",
  value: "zonisamide",
  name: "isIDgNeuropsychotropicMedications",
 },
];
const isIDgNeuropsychotropicMedicationsCurrentValues = [
 {
  label: "5-FU",
  value: "5-FU",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "8-MOP",
  value: "8-MOP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "A/T/S",
  value: "A/T/S",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "A+D ZINC OXIDE--CREAM",
  value: "A+D ZINC OXIDE--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "abacavir",
  value: "abacavir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "abatacept",
  value: "abatacept",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ABILIFY",
  value: "ABILIFY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ABILIFY MAINTENA KIT",
  value: "ABILIFY MAINTENA KIT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Abiraterone",
  value: "Abiraterone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ABRAXANE",
  value: "ABRAXANE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ABSTRAL",
  value: "ABSTRAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACCOLATE",
  value: "ACCOLATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AccuNeb",
  value: "AccuNeb",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Accupril",
  value: "Accupril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACCURBRON",
  value: "ACCURBRON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Aceon",
  value: "Aceon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACEPHEN",
  value: "ACEPHEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACETADOTE",
  value: "ACETADOTE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACETAMINOPHEN",
  value: "ACETAMINOPHEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACETAMINOPHEN and CODEINE",
  value: "ACETAMINOPHEN and CODEINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "acetaminophen NAPQI",
  value: "acetaminophen NAPQI",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACETASOL HC",
  value: "ACETASOL HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACETAZOLAMIDE",
  value: "ACETAZOLAMIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACETIC ACID W/ HYDROCORTISONE",
  value: "ACETIC ACID W/ HYDROCORTISONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Acetolol",
  value: "Acetolol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACETYLCYSTEINE",
  value: "ACETYLCYSTEINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACHES-N-PAIN",
  value: "ACHES-N-PAIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACHROMYCIN",
  value: "ACHROMYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACIDOPHILUS",
  value: "ACIDOPHILUS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACILAC",
  value: "ACILAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACIPHEX",
  value: "ACIPHEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACTEMRA",
  value: "ACTEMRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACTICORT",
  value: "ACTICORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACTIMMUNE",
  value: "ACTIMMUNE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACTIQ",
  value: "ACTIQ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACTIVELLA",
  value: "ACTIVELLA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACTONEL",
  value: "ACTONEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACTOPLUS MET",
  value: "ACTOPLUS MET",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACTOS",
  value: "ACTOS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACTRON",
  value: "ACTRON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Acyclovir",
  value: "Acyclovir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "acyclovir--cream",
  value: "acyclovir--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ACZONE",
  value: "ACZONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADALAT",
  value: "ADALAT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADALAT CC",
  value: "ADALAT CC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "adalimumab",
  value: "adalimumab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Adapalene",
  value: "Adapalene",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADASUVE",
  value: "ADASUVE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADCIRCA",
  value: "ADCIRCA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Adderall",
  value: "Adderall",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Adderall XR",
  value: "Adderall XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Addyi",
  value: "Addyi",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Adefovir",
  value: "Adefovir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Adipex-P",
  value: "Adipex-P",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADRIAMYCIN",
  value: "ADRIAMYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADRIAMYCIN PFS",
  value: "ADRIAMYCIN PFS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADRIAMYCIN RDF",
  value: "ADRIAMYCIN RDF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADRONAT",
  value: "ADRONAT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADRUCIL",
  value: "ADRUCIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADVAIR",
  value: "ADVAIR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADVAIR DISKUS",
  value: "ADVAIR DISKUS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Advicor",
  value: "Advicor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADVIL",
  value: "ADVIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADVIL ALLERGY AND CONGESTION RELIEF",
  value: "ADVIL ALLERGY AND CONGESTION RELIEF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADVIL ALLERGY SINUS",
  value: "ADVIL ALLERGY SINUS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADVIL COLD AND SINUS",
  value: "ADVIL COLD AND SINUS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADVIL CONGESTION RELIEF",
  value: "ADVIL CONGESTION RELIEF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADVIL LIQUI-GELS",
  value: "ADVIL LIQUI-GELS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADVIL MIGRAINE LIQUI-GELS",
  value: "ADVIL MIGRAINE LIQUI-GELS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ADVIL PM",
  value: "ADVIL PM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AEROLATE",
  value: "AEROLATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AEROLATE III",
  value: "AEROLATE III",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AEROLATE JR",
  value: "AEROLATE JR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AEROLATE SR",
  value: "AEROLATE SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AEROLIN",
  value: "AEROLIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AEROSEB-DEX",
  value: "AEROSEB-DEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AEROSEB-HC",
  value: "AEROSEB-HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AFEDITAB CR",
  value: "AFEDITAB CR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AFINITOR",
  value: "AFINITOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AFINITOR DISPERZ",
  value: "AFINITOR DISPERZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AGENERASE",
  value: "AGENERASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AGGRENOX",
  value: "AGGRENOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "A-HYDROCORT",
  value: "A-HYDROCORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AIROMIR",
  value: "AIROMIR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AKNE-MYCIN",
  value: "AKNE-MYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AKTEN",
  value: "AKTEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AKTOB",
  value: "AKTOB",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Akwa tears--eye drops",
  value: "Akwa tears--eye drops",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALA-CORT",
  value: "ALA-CORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALA-SCALP",
  value: "ALA-SCALP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALAVERT",
  value: "ALAVERT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "albuterol",
  value: "albuterol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALDACTAZIDE",
  value: "ALDACTAZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALDACTONE",
  value: "ALDACTONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Aldosterone",
  value: "Aldosterone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "alendronate",
  value: "alendronate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "alendronate and cholecalciferol",
  value: "alendronate and cholecalciferol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Alendronic acid",
  value: "Alendronic acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALENDROS",
  value: "ALENDROS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALESSE",
  value: "ALESSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALEVE",
  value: "ALEVE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALEVE-D SINUS & COLD",
  value: "ALEVE-D SINUS & COLD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Alfenta",
  value: "Alfenta",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "alfentanil",
  value: "alfentanil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "alfeprol",
  value: "alfeprol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALFERON N INJECTION",
  value: "ALFERON N INJECTION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Alfuzosin",
  value: "Alfuzosin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "alirocumab",
  value: "alirocumab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Aliskiren",
  value: "Aliskiren",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Allopurinol",
  value: "Allopurinol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Almokalant",
  value: "Almokalant",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Almotriptan",
  value: "Almotriptan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALORA",
  value: "ALORA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Alosetron",
  value: "Alosetron",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALOXI",
  value: "ALOXI",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALPHACAINE",
  value: "ALPHACAINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALPHACAINE HYDROCHLORIDE",
  value: "ALPHACAINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALPHADERM",
  value: "ALPHADERM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "alphagan--eye drops",
  value: "alphagan--eye drops",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "alprazolam",
  value: "alprazolam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "alprenolol",
  value: "alprenolol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALTACE",
  value: "ALTACE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALTAVERA",
  value: "ALTAVERA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Altocor",
  value: "Altocor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALTOPREV",
  value: "ALTOPREV",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Aluminum hydroxide",
  value: "Aluminum hydroxide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALYACEN",
  value: "ALYACEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALYACEN 1/35",
  value: "ALYACEN 1/35",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALYACEN 7/7/7",
  value: "ALYACEN 7/7/7",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ALYACEN 777",
  value: "ALYACEN 777",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Amantadine",
  value: "Amantadine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMARYL",
  value: "AMARYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMBENYL",
  value: "AMBENYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMBIEN",
  value: "AMBIEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMBIEN CR",
  value: "AMBIEN CR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMBODRYL",
  value: "AMBODRYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Amentoflavone",
  value: "Amentoflavone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Amerge",
  value: "Amerge",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Amiloride",
  value: "Amiloride",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Aminoglutethimide",
  value: "Aminoglutethimide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "amiodarone",
  value: "amiodarone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Amiodarone Hydrochloride",
  value: "Amiodarone Hydrochloride",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMITID",
  value: "AMITID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMITIZA",
  value: "AMITIZA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMITRIL",
  value: "AMITRIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "amitriptyline",
  value: "amitriptyline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMITRIPTYLINE HYDROCHLORIDE",
  value: "AMITRIPTYLINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "amlodipine",
  value: "amlodipine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMLODIPINE and BENAZEPRIL",
  value: "AMLODIPINE and BENAZEPRIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMMONIUM LACTATE--lotion",
  value: "AMMONIUM LACTATE--lotion",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMOX TR-K CLV",
  value: "AMOX TR-K CLV",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Amoxicillin",
  value: "Amoxicillin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "amoxicillin and clavulanate",
  value: "amoxicillin and clavulanate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "amoxicillin and clavulanic acid",
  value: "amoxicillin and clavulanic acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMOXICILLIN PEDIATRIC",
  value: "AMOXICILLIN PEDIATRIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMOXIL",
  value: "AMOXIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "amphetamine",
  value: "amphetamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMPHICOL",
  value: "AMPHICOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ampicillin",
  value: "Ampicillin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Amprenavir",
  value: "Amprenavir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AMRIX",
  value: "AMRIX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Amturnide",
  value: "Amturnide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Amvaz",
  value: "Amvaz",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANAFRANIL",
  value: "ANAFRANIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Anagrelide",
  value: "Anagrelide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "anakinra",
  value: "anakinra",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANAPROX",
  value: "ANAPROX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANAPROX DS",
  value: "ANAPROX DS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANASTROZOLE",
  value: "ANASTROZOLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANDRODERM",
  value: "ANDRODERM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANDROGEL",
  value: "ANDROGEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANDROID 10, 25, 5",
  value: '"ANDROID 10, 25, 5"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Androsterone",
  value: "Androsterone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANESTACON",
  value: "ANESTACON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANGELIQ",
  value: "ANGELIQ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "ANGELIQ, VAGIFEM, MENOSTAR, MINEVELLE, INNOFEM, GYNODIOL, FEMPATCH, EVAMIST, DIVIGEL, CLIMARA",
  value:
   '"ANGELIQ, VAGIFEM, MENOSTAR, MINEVELLE, INNOFEM, GYNODIOL, FEMPATCH, EVAMIST, DIVIGEL, CLIMARA"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Anoro Ellipta",
  value: "Anoro Ellipta",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANSAID",
  value: "ANSAID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANSPOR",
  value: "ANSPOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANTABUSE",
  value: "ANTABUSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "antacid",
  value: "antacid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANTAGONATE",
  value: "ANTAGONATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Antara",
  value: "Antara",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANTARA (MICRONIZED)",
  value: "ANTARA (MICRONIZED)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "anti-diarrheal",
  value: "anti-diarrheal",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANTITUSSIVE",
  value: "ANTITUSSIVE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANTIVERT",
  value: "ANTIVERT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANTURANE",
  value: "ANTURANE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ANUSOL HC",
  value: "ANUSOL HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "APIDRA",
  value: "APIDRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Apixaban",
  value: "Apixaban",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "APLENZIN",
  value: "APLENZIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "A-POXIDE",
  value: "A-POXIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Aprepitant",
  value: "Aprepitant",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Apresoline",
  value: "Apresoline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "APRISO",
  value: "APRISO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Aptensio",
  value: "Aptensio",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "aptine",
  value: "aptine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Aptiom",
  value: "Aptiom",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "APTIVUS",
  value: "APTIVUS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AQUAPHYLLIN",
  value: "AQUAPHYLLIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ARANELLE",
  value: "ARANELLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ARANELLE, NORLUTIN, NORLUTATE, NOR-QD, ORTHO MICRONOR",
  value: '"ARANELLE, NORLUTIN, NORLUTATE, NOR-QD, ORTHO MICRONOR"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Aranesp",
  value: "Aranesp",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ARAVA",
  value: "ARAVA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ARENDAL",
  value: "ARENDAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ARIMIDEX",
  value: "ARIMIDEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "aripiprazole",
  value: "aripiprazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "armodafinil",
  value: "armodafinil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Armour thyroid",
  value: "Armour thyroid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ARNUITY ELLIPTA",
  value: "ARNUITY ELLIPTA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AROMASIN",
  value: "AROMASIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ARTANE",
  value: "ARTANE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Artemisinin",
  value: "Artemisinin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Arthrotec",
  value: "Arthrotec",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ARTIFICIAL TEARS--EYE DROPS",
  value: "ARTIFICIAL TEARS--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ASACOL",
  value: "ASACOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ASACOL HD",
  value: "ASACOL HD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ascorbic acid",
  value: "Ascorbic acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Asenapine",
  value: "Asenapine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ASMAVEN",
  value: "ASMAVEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ASMOL",
  value: "ASMOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Aspirin",
  value: "Aspirin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ASPIRIN AND DIPYRIDAMOLE",
  value: "ASPIRIN AND DIPYRIDAMOLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ASTAGRAF XL",
  value: "ASTAGRAF XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "astemizole",
  value: "astemizole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ASTHALIN",
  value: "ASTHALIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ASTHAVENT",
  value: "ASTHAVENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Astromorph",
  value: "Astromorph",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Atacand",
  value: "Atacand",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ATARAX",
  value: "ATARAX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Atazanavir",
  value: "Atazanavir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Atenolol",
  value: "Atenolol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "atenolol and chlorthalidone",
  value: "atenolol and chlorthalidone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ATIVAN",
  value: "ATIVAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "atomoxetine",
  value: "atomoxetine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "atorvastatin",
  value: "atorvastatin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ATRIPLA",
  value: "ATRIPLA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Atropine",
  value: "Atropine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ATROPINE AND DEMEROL",
  value: "ATROPINE AND DEMEROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ATROVENT",
  value: "ATROVENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ATROVENT HFA",
  value: "ATROVENT HFA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Augmentin",
  value: "Augmentin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AURSTAT ANTI-ITCH HYDROGEL",
  value: "AURSTAT ANTI-ITCH HYDROGEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AURSTAT-- HYDROGEL",
  value: "AURSTAT-- HYDROGEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Avalide",
  value: "Avalide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AVANDAMET",
  value: "AVANDAMET",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AVANDARYL",
  value: "AVANDARYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AVANDIA",
  value: "AVANDIA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AVAPRO",
  value: "AVAPRO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Avastin",
  value: "Avastin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AVENTYL",
  value: "AVENTYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AVENTYL HYDROCHLORIDE",
  value: "AVENTYL HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AVIANE-21",
  value: "AVIANE-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AVIANE-28",
  value: "AVIANE-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "avibactum",
  value: "avibactum",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Avinza",
  value: "Avinza",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AVODART",
  value: "AVODART",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AVONEX",
  value: "AVONEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Avycaz",
  value: "Avycaz",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Axert",
  value: "Axert",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Axid",
  value: "Axid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AXIRON",
  value: "AXIRON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AYGESTIN",
  value: "AYGESTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Azapropazone",
  value: "Azapropazone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AZASAN",
  value: "AZASAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AZASITE",
  value: "AZASITE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "azathioprine",
  value: "azathioprine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AZELASTINE--DROPS",
  value: "AZELASTINE--DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AZELASTINE--NASAL SPRAY",
  value: "AZELASTINE--NASAL SPRAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AZILECT",
  value: "AZILECT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "azilsartan",
  value: "azilsartan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Azithromycin",
  value: "Azithromycin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Azor",
  value: "Azor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AZT",
  value: "AZT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AZULFIDINE",
  value: "AZULFIDINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "AZULFIDINE EN-TABS",
  value: "AZULFIDINE EN-TABS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BACITRACIN-NEOMYCIN-POLYMYXIN W/ HYDROCORTISONE ACETATE",
  value: "BACITRACIN-NEOMYCIN-POLYMYXIN W/ HYDROCORTISONE ACETATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BACITRACIN--OINTMENT",
  value: "BACITRACIN--OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BACLOFEN",
  value: "BACLOFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BACTERIOSTATIC SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value: "BACTERIOSTATIC SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BACTRIM",
  value: "BACTRIM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BACTROBAN--OINTMENT",
  value: "BACTROBAN--OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BALNEOL-HC",
  value: "BALNEOL-HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BALZIVA-21",
  value: "BALZIVA-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BALZIVA-28",
  value: "BALZIVA-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Banzel",
  value: "Banzel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Barbiturates",
  value: "Barbiturates",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BAYCOL",
  value: "BAYCOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BAZA PROTECT CREAM",
  value: "BAZA PROTECT CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BAZA PROTECT--CREAM",
  value: "BAZA PROTECT--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Beclomethasone",
  value: "Beclomethasone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BECLOMETHASONE DIPROPIONATE",
  value: "BECLOMETHASONE DIPROPIONATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BECLOVENT",
  value: "BECLOVENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BECONASE",
  value: "BECONASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BELDIN",
  value: "BELDIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BELIX",
  value: "BELIX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BENADRYL",
  value: "BENADRYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BENADRYL PRESERVATIVE FREE",
  value: "BENADRYL PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Benazepril",
  value: "Benazepril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Bendamustine",
  value: "Bendamustine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BENEMID",
  value: "BENEMID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Benicar",
  value: "Benicar",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BENTYL",
  value: "BENTYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BENTYL PRESERVATIVE FREE",
  value: "BENTYL PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BENYLIN",
  value: "BENYLIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BENZAMYCIN",
  value: "BENZAMYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BENZAMYCIN PAK",
  value: "BENZAMYCIN PAK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Benzocaine",
  value: "Benzocaine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BENZONATATE",
  value: "BENZONATATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Benzoyl peroxide",
  value: "Benzoyl peroxide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BENZTROPINE",
  value: "BENZTROPINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BENZTROPINE MESYLATE",
  value: "BENZTROPINE MESYLATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Benzylpenicillin",
  value: "Benzylpenicillin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Bepridil",
  value: "Bepridil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BERUBIGEN",
  value: "BERUBIGEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Beta Carotene",
  value: "Beta Carotene",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BETA-HC",
  value: "BETA-HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BETALIN 12",
  value: "BETALIN 12",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BETAMETHASONE--cream",
  value: "BETAMETHASONE--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "beta-naphthoflavone",
  value: "beta-naphthoflavone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BETAPACE",
  value: "BETAPACE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BETAPACE AF",
  value: "BETAPACE AF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BETASERON",
  value: "BETASERON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BETHANECHOL",
  value: "BETHANECHOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BETHKIS",
  value: "BETHKIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "bevacizumab",
  value: "bevacizumab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Bexarotene",
  value: "Bexarotene",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BEXTRA",
  value: "BEXTRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BEYAZ",
  value: "BEYAZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BIAXIN",
  value: "BIAXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BIAXIN XL",
  value: "BIAXIN XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BICALUTAMIDE",
  value: "BICALUTAMIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Bidil",
  value: "Bidil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BIFITERAL",
  value: "BIFITERAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Bimatoprost",
  value: "Bimatoprost",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "bisacodyl",
  value: "bisacodyl",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "biscolax",
  value: "biscolax",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "bisocolax--suppository",
  value: "bisocolax--suppository",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Bisoprolol",
  value: "Bisoprolol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Blocadren",
  value: "Blocadren",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Boceprevir",
  value: "Boceprevir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Boniva",
  value: "Boniva",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Breo Ellipta",
  value: "Breo Ellipta",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Brevibloc",
  value: "Brevibloc",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BREVICON",
  value: "BREVICON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BREVICON 21-DAY",
  value: "BREVICON 21-DAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BREVICON 28-DAY",
  value: "BREVICON 28-DAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "brexpiprazole",
  value: "brexpiprazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BRIELLYN",
  value: "BRIELLYN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BRILINTA",
  value: "BRILINTA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BRIMONIDINE-EYE DROPs",
  value: "BRIMONIDINE-EYE DROPs",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Brisdelle",
  value: "Brisdelle",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BRISTAMYCIN",
  value: "BRISTAMYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BROMANYL",
  value: "BROMANYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "bromday--eye drops",
  value: "bromday--eye drops",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BROMFED-DM",
  value: "BROMFED-DM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "bromfenac--eye drops",
  value: "bromfenac--eye drops",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Bromocriptine",
  value: "Bromocriptine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BROMODIPHENHYDRAMINE HYDROCHLORIDE AND CODEINE PHOSPHATE",
  value: "BROMODIPHENHYDRAMINE HYDROCHLORIDE AND CODEINE PHOSPHATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BRONCHO SALINE",
  value: "BRONCHO SALINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BRONCOVALEAS",
  value: "BRONCOVALEAS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BRONKODYL",
  value: "BRONKODYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BUDEPRION",
  value: "BUDEPRION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Budeprion XL",
  value: "Budeprion XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Budesonide",
  value: "Budesonide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "budesonide and formoterol",
  value: "budesonide and formoterol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "bufuralol",
  value: "bufuralol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Bumetanide",
  value: "Bumetanide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BUMEX",
  value: "BUMEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Buprenorphine",
  value: "Buprenorphine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "bupropion",
  value: "bupropion",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BUPROPION HYDROBROMIDE",
  value: "BUPROPION HYDROBROMIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BUPROPION HYDROCHLORIDE",
  value: "BUPROPION HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BUSPAR",
  value: "BUSPAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "buspirone",
  value: "buspirone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "butalbital",
  value: "butalbital",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BUTRANS",
  value: "BUTRANS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "BUVENTOL",
  value: "BUVENTOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Bydureon",
  value: "Bydureon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Byetta",
  value: "Byetta",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Bystolic",
  value: "Bystolic",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cabergoline",
  value: "Cabergoline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Caduet",
  value: "Caduet",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cafergot",
  value: "Cafergot",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "caffeine",
  value: "caffeine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "caffeine & ergotamine",
  value: "caffeine & ergotamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Calan",
  value: "Calan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Calan SR",
  value: "Calan SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CALCIJEX",
  value: "CALCIJEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "calcitonin",
  value: "calcitonin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CALCITONIN SALMON--injection",
  value: "CALCITONIN SALMON--injection",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Calcitonin-Salmon",
  value: "Calcitonin-Salmon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "calcitriol",
  value: "calcitriol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "calcium",
  value: "calcium",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CALCIUM ACETATE",
  value: "CALCIUM ACETATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Calcium Carbonate",
  value: "Calcium Carbonate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Calcium Carbonate and Magnesium Hydroxide",
  value: "Calcium Carbonate and Magnesium Hydroxide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CALDOLOR",
  value: "CALDOLOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Calmoseptine",
  value: "Calmoseptine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CALMURID HC",
  value: "CALMURID HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CALOMIST",
  value: "CALOMIST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CAM-AP-ES",
  value: "CAM-AP-ES",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cambia",
  value: "Cambia",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CAMILA",
  value: "CAMILA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CAMPTOSAR",
  value: "CAMPTOSAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CANASA",
  value: "CANASA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "candesartan",
  value: "candesartan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cangrelor",
  value: "cangrelor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cannabinoids",
  value: "Cannabinoids",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cannabis",
  value: "cannabis",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cannabis sativa",
  value: "cannabis sativa",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "capecitabine",
  value: "capecitabine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CAPOTEN",
  value: "CAPOTEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CAP-PROFEN",
  value: "CAP-PROFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Capsaicin",
  value: "Capsaicin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Captopril",
  value: "Captopril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARAC",
  value: "CARAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARAFATE",
  value: "CARAFATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARAMBOLA",
  value: "CARAMBOLA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Carbamazepine",
  value: "Carbamazepine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARBATROL",
  value: "CARBATROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "carbidopa",
  value: "carbidopa",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARBIDOPA and LEVODOPA",
  value: "CARBIDOPA and LEVODOPA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cardioquin",
  value: "Cardioquin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cardizem",
  value: "Cardizem",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARDIZEM CD",
  value: "CARDIZEM CD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARDIZEM LA",
  value: "CARDIZEM LA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARDIZEM SR",
  value: "CARDIZEM SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARDURA",
  value: "CARDURA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARDURA XL",
  value: "CARDURA XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cariprazine",
  value: "cariprazine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "carisoprodol",
  value: "carisoprodol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARMOL HC",
  value: "CARMOL HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARNITOR",
  value: "CARNITOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARNITOR SF",
  value: "CARNITOR SF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cartia",
  value: "Cartia",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CARTIA XT",
  value: "CARTIA XT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "carvedilol",
  value: "carvedilol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CASODEX",
  value: "CASODEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cataflam",
  value: "Cataflam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CATAPRES",
  value: "CATAPRES",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CATAPRES-TTS-1",
  value: "CATAPRES-TTS-1",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CATAPRES-TTS-2",
  value: "CATAPRES-TTS-2",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CATAPRES-TTS-3",
  value: "CATAPRES-TTS-3",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cefadroxil",
  value: "Cefadroxil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cefazolin",
  value: "Cefazolin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cefdinir",
  value: "cefdinir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cefepime",
  value: "cefepime",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cefoperazone",
  value: "Cefoperazone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ceftazidime",
  value: "ceftazidime",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ceftibuten",
  value: "Ceftibuten",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CEFTIN",
  value: "CEFTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ceftriaxone",
  value: "ceftriaxone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CEFUROXIME",
  value: "CEFUROXIME",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CEFUROXIME AXETIL",
  value: "CEFUROXIME AXETIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CELEBREX",
  value: "CELEBREX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "celecoxib",
  value: "celecoxib",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Celexa",
  value: "Celexa",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Celiprolol",
  value: "Celiprolol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Celontin",
  value: "Celontin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cephalexin",
  value: "Cephalexin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cephaloridine",
  value: "Cephaloridine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cephradine",
  value: "Cephradine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CEPHULAC",
  value: "CEPHULAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cerebyx",
  value: "Cerebyx",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cerivastatin",
  value: "cerivastatin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "certolizumab",
  value: "certolizumab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CETACORT",
  value: "CETACORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CETIRIZINE",
  value: "CETIRIZINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cetirizine and pseudoephedrine",
  value: "cetirizine and pseudoephedrine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CETIRIZINE HCL",
  value: "CETIRIZINE HCL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cetirizine hydrochloride",
  value: "Cetirizine hydrochloride",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CETRAXAL",
  value: "CETRAXAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CETSIM",
  value: "CETSIM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cevimeline",
  value: "Cevimeline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "chantix",
  value: "chantix",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHIBROXIN",
  value: "CHIBROXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHILDREN'S ADVIL",
  value: "CHILDREN'S ADVIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHILDREN'S ADVIL ALLERGY SINUS",
  value: "CHILDREN'S ADVIL ALLERGY SINUS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHILDREN'S ADVIL COLD",
  value: "CHILDREN'S ADVIL COLD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHILDREN'S ADVIL-FLAVORED",
  value: "CHILDREN'S ADVIL-FLAVORED",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHILDREN'S CLARITIN",
  value: "CHILDREN'S CLARITIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHILDREN'S ELIXSURE",
  value: "CHILDREN'S ELIXSURE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHILDREN'S IBUPROFEN",
  value: "CHILDREN'S IBUPROFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHILDREN'S MOTRIN",
  value: "CHILDREN'S MOTRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHILDREN'S MOTRIN COLD",
  value: "CHILDREN'S MOTRIN COLD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "chloramphenicol",
  value: "chloramphenicol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLORAMPHENICOL SODIUM SUCCINATE",
  value: "CHLORAMPHENICOL SODIUM SUCCINATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLORASEPTIC--SPRAY",
  value: "CHLORASEPTIC--SPRAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLORDIAZACHEL",
  value: "CHLORDIAZACHEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLORDIAZEPOXIDE",
  value: "CHLORDIAZEPOXIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLORDIAZEPOXIDE AND AMITRIPTYLINE HYDROCHLORIDE",
  value: "CHLORDIAZEPOXIDE AND AMITRIPTYLINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLORDIAZEPOXIDE HYDROCHLORIDE",
  value: "CHLORDIAZEPOXIDE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLORHEXIDINE--rinse",
  value: "CHLORHEXIDINE--rinse",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOROFAIR",
  value: "CHLOROFAIR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOROHENIRAMINE MALEATE AND PHENYLPROPANOLAMINE HYDROCHLORIDE",
  value: "CHLOROHENIRAMINE MALEATE AND PHENYLPROPANOLAMINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOROMYCETIN",
  value: "CHLOROMYCETIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOROMYCETIN HYDROCORTISONE",
  value: "CHLOROMYCETIN HYDROCORTISONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOROMYCETIN PALMITATE",
  value: "CHLOROMYCETIN PALMITATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOROMYXIN",
  value: "CHLOROMYXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOROPTIC",
  value: "CHLOROPTIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOROPTIC S.O.P.",
  value: "CHLOROPTIC S.O.P.",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOROPTIC-P S.O.P.",
  value: "CHLOROPTIC-P S.O.P.",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Chloroquine",
  value: "Chloroquine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOROTHIAZIDE AND RESERPINE",
  value: "CHLOROTHIAZIDE AND RESERPINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOROTHIAZIDE W/ RESERPINE",
  value: "CHLOROTHIAZIDE W/ RESERPINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOROTHIAZIDE-RESERPINE",
  value: "CHLOROTHIAZIDE-RESERPINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Chlorpheniramine",
  value: "Chlorpheniramine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLORPHENIRAMINE MALEATE",
  value: "CHLORPHENIRAMINE MALEATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "chlorpromazine",
  value: "chlorpromazine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLORPROMAZINE HYDROCHLORIDE",
  value: "CHLORPROMAZINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLORPROMAZINE HYDROCHLORIDE INTENSOL",
  value: "CHLORPROMAZINE HYDROCHLORIDE INTENSOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Chlorpropamide",
  value: "Chlorpropamide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Chlorthalidone",
  value: "Chlorthalidone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHLOR-TRIMETON",
  value: "CHLOR-TRIMETON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Chlorzoxazone",
  value: "Chlorzoxazone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHOLAC",
  value: "CHOLAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cholbam",
  value: "Cholbam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cholestyramine",
  value: "Cholestyramine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHOLESTYRAMINE LIGHT",
  value: "CHOLESTYRAMINE LIGHT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cholic acid",
  value: "cholic acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHOLYBAR",
  value: "CHOLYBAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "chondroitin",
  value: "chondroitin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CHRONULAC",
  value: "CHRONULAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIALIS",
  value: "CIALIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CICLOPIROX--SOLUTION",
  value: "CICLOPIROX--SOLUTION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cidofovir",
  value: "Cidofovir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cilostazol",
  value: "cilostazol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CILOXAN",
  value: "CILOXAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIMETIDINE",
  value: "CIMETIDINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIMETIDINE HYDROCHLORIDE",
  value: "CIMETIDINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "CIMETIDINE HYDROCHLORIDE IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value:
   "CIMETIDINE HYDROCHLORIDE IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cimzia",
  value: "Cimzia",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cinacalcet",
  value: "Cinacalcet",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIN-Quin",
  value: "CIN-Quin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIPRO",
  value: "CIPRO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIPRO HC",
  value: "CIPRO HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIPRO I.V.",
  value: "CIPRO I.V.",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIPRO IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "CIPRO IN DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIPRO IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value: "CIPRO IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIPRO XR",
  value: "CIPRO XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIPRODEX",
  value: "CIPRODEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ciprofloxacin",
  value: "ciprofloxacin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIPROFLOXACIN IN DEXTROSE 5%",
  value: "CIPROFLOXACIN IN DEXTROSE 5%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIPROFLOXACIN IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "CIPROFLOXACIN IN DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CIPROFLOXACIN--eye drop",
  value: "CIPROFLOXACIN--eye drop",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cisapride",
  value: "cisapride",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cisplatin",
  value: "Cisplatin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Citalopram",
  value: "Citalopram",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CITALOPRAM HYDROBROMIDE",
  value: "CITALOPRAM HYDROBROMIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CITOPAN",
  value: "CITOPAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLARINEX",
  value: "CLARINEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLARINEX D 24 HOUR",
  value: "CLARINEX D 24 HOUR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLARINEX-D 12 HOUR",
  value: "CLARINEX-D 12 HOUR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "clarithromycin",
  value: "clarithromycin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLARITIN",
  value: "CLARITIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLARITIN HIVES RELIEF",
  value: "CLARITIN HIVES RELIEF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLARITIN HIVES RELIEF REDITAB",
  value: "CLARITIN HIVES RELIEF REDITAB",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLARITIN REDITABS",
  value: "CLARITIN REDITABS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLARITIN-D",
  value: "CLARITIN-D",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLARITIN-D 24 HOUR",
  value: "CLARITIN-D 24 HOUR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "clemastine",
  value: "clemastine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLEMASTINE FUMARATE",
  value: "CLEMASTINE FUMARATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLIMARA",
  value: "CLIMARA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLIMARA PRO",
  value: "CLIMARA PRO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Clindamycin",
  value: "Clindamycin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Clinoril",
  value: "Clinoril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "clobazam",
  value: "clobazam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLOBETASOL--cream",
  value: "CLOBETASOL--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLOBETASOL--ointment",
  value: "CLOBETASOL--ointment",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Clofibrate",
  value: "Clofibrate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Clofibric acid",
  value: "Clofibric acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Clomid",
  value: "Clomid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Clomiphene",
  value: "Clomiphene",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "clomipramine",
  value: "clomipramine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLOMIPRAMINE HYDROCHLORIDE",
  value: "CLOMIPRAMINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Clonazepam",
  value: "Clonazepam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "clonidine",
  value: "clonidine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLONIDINE HYDROCHLORIDE",
  value: "CLONIDINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLONIDINE HYDROCHLORIDE AND CHLORTHALIDONE",
  value: "CLONIDINE HYDROCHLORIDE AND CHLORTHALIDONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLONIDINE--PATCH",
  value: "CLONIDINE--PATCH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Clopidogrel",
  value: "Clopidogrel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLOPRA",
  value: "CLOPRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: 'CLOPRA-""YELLOW""',
  value: 'CLOPRA-""YELLOW""',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLOPRA-YELLOW",
  value: "CLOPRA-YELLOW",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLORPRES",
  value: "CLORPRES",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Clotrimazole",
  value: "Clotrimazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLOTRIMAZOLE-BETAMETHASONE--cream",
  value: "CLOTRIMAZOLE-BETAMETHASONE--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "clozapine",
  value: "clozapine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CLOZARIL",
  value: "CLOZARIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COBAVITE",
  value: "COBAVITE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cobicistat",
  value: "cobicistat",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COBUTOLIN",
  value: "COBUTOLIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Codeine",
  value: "Codeine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CODEPREX",
  value: "CODEPREX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CODIMAL-L.A. 12",
  value: "CODIMAL-L.A. 12",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CODOXY",
  value: "CODOXY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Codrix",
  value: "Codrix",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COGENTIN",
  value: "COGENTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COGNEX",
  value: "COGNEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Colchicine",
  value: "Colchicine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COLCRYS",
  value: "COLCRYS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COLD CAPSULE IV",
  value: "COLD CAPSULE IV",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COLD CAPSULE V",
  value: "COLD CAPSULE V",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Colesevelam",
  value: "Colesevelam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Colestid",
  value: "Colestid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "colestipol",
  value: "colestipol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COLOCORT",
  value: "COLOCORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COLY-MYCIN S",
  value: "COLY-MYCIN S",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COMBIGAN--EYE DROPS",
  value: "COMBIGAN--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COMBIPATCH",
  value: "COMBIPATCH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COMBIPRES",
  value: "COMBIPRES",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Combivent",
  value: "Combivent",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Combivent Respimat",
  value: "Combivent Respimat",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Combivir",
  value: "Combivir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Combunox",
  value: "Combunox",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COMPAZINE",
  value: "COMPAZINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Complera",
  value: "Complera",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COMPRO",
  value: "COMPRO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COMTAN",
  value: "COMTAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CONCERTA",
  value: "CONCERTA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CONSTILAC",
  value: "CONSTILAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CONSTULOSE",
  value: "CONSTULOSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CONTAC",
  value: "CONTAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CONTAC 12 HOUR",
  value: "CONTAC 12 HOUR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Conzip",
  value: "Conzip",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COPAXONE",
  value: "COPAXONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Copper",
  value: "Copper",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cordarone",
  value: "Cordarone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COREG",
  value: "COREG",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COREG CR",
  value: "COREG CR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CORGARD",
  value: "CORGARD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Corlanor",
  value: "Corlanor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COR-OTICIN",
  value: "COR-OTICIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CORSYM",
  value: "CORSYM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CORTALONE",
  value: "CORTALONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CORTAN",
  value: "CORTAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CORT-DOME",
  value: "CORT-DOME",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CORTEF",
  value: "CORTEF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CORTEF ACETATE",
  value: "CORTEF ACETATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CORTENEMA",
  value: "CORTENEMA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CORTIFOAM",
  value: "CORTIFOAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cortisol",
  value: "Cortisol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CORTISPORIN",
  value: "CORTISPORIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CORTRIL",
  value: "CORTRIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CORVERT",
  value: "CORVERT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cosentyx",
  value: "Cosentyx",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cotazym",
  value: "Cotazym",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cotrimoxazole",
  value: "Cotrimoxazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Coumadin",
  value: "Coumadin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Covera-HS",
  value: "Covera-HS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "COZAAR",
  value: "COZAAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CPT-11",
  value: "CPT-11",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CRANBERRY POWDER",
  value: "CRANBERRY POWDER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Creon",
  value: "Creon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cresemba",
  value: "Cresemba",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Crestor",
  value: "Crestor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CRINONE",
  value: "CRINONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CRIXIVAN",
  value: "CRIXIVAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CROMOLYN--EYE DROPS",
  value: "CROMOLYN--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CRYSELLE",
  value: "CRYSELLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "C-SOLVE-2",
  value: "C-SOLVE-2",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CUTIVATE",
  value: "CUTIVATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CUVPOSA",
  value: "CUVPOSA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CYANOCOBALAMIN",
  value: "CYANOCOBALAMIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CYCLAFEM 1/35",
  value: "CYCLAFEM 1/35",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CYCLAFEM 7/7/7",
  value: "CYCLAFEM 7/7/7",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CYCLESSA",
  value: "CYCLESSA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cyclobenzaprine",
  value: "cyclobenzaprine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CYCLOBENZAPRINE HYDROCHLORIDE",
  value: "CYCLOBENZAPRINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "cyclophosphamide",
  value: "cyclophosphamide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cyclosporine",
  value: "Cyclosporine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cymbalta",
  value: "Cymbalta",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cyproheptadine",
  value: "Cyproheptadine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CYPROHEPTADINE HYDROCHLORIDE",
  value: "CYPROHEPTADINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CYTADREN",
  value: "CYTADREN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cytomel",
  value: "Cytomel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Cytotec",
  value: "Cytotec",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CYTOVENE",
  value: "CYTOVENE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CYTOXAN",
  value: "CYTOXAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "CYTOXAN (LYOPHILIZED)",
  value: "CYTOXAN (LYOPHILIZED)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dabigatran",
  value: "Dabigatran",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DABIGATRAN ETEXILATE",
  value: "DABIGATRAN ETEXILATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "daclatasvir",
  value: "daclatasvir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Daklinza",
  value: "Daklinza",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DALIRESP",
  value: "DALIRESP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Danazol",
  value: "Danazol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DANOCRINE",
  value: "DANOCRINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dapagliflozin",
  value: "Dapagliflozin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "dapsone",
  value: "dapsone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "darbepoetin alfa",
  value: "darbepoetin alfa",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Darifenacin",
  value: "Darifenacin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DARIFENACIN HYDROBROMIDE",
  value: "DARIFENACIN HYDROBROMIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Darunavir",
  value: "Darunavir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DASETTA 1/35",
  value: "DASETTA 1/35",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DASETTA 7/7/7",
  value: "DASETTA 7/7/7",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Daunorubicin",
  value: "Daunorubicin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Daypro",
  value: "Daypro",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DAYSEE",
  value: "DAYSEE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DAYTRANA",
  value: "DAYTRANA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "debrisoquine",
  value: "debrisoquine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Debroquine",
  value: "Debroquine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DECADERM",
  value: "DECADERM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DECADRON",
  value: "DECADRON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DECADRON W/ XYLOCAINE",
  value: "DECADRON W/ XYLOCAINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DECADRON-LA",
  value: "DECADRON-LA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DECASPRAY",
  value: "DECASPRAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DECAXEN",
  value: "DECAXEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Delavirdine",
  value: "Delavirdine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "delaviridine",
  value: "delaviridine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DELAXIN",
  value: "DELAXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DELESTROGEN",
  value: "DELESTROGEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DELSYM",
  value: "DELSYM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DELTA-CORTEF",
  value: "DELTA-CORTEF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DELTA-DOME",
  value: "DELTA-DOME",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DELTASONE",
  value: "DELTASONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DELZICOL",
  value: "DELZICOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEMADEX",
  value: "DEMADEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEMAZIN",
  value: "DEMAZIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEMECLOCYCLINE",
  value: "DEMECLOCYCLINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEMEROL",
  value: "DEMEROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEMI-REGROTON",
  value: "DEMI-REGROTON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEMULEN 1/35-21",
  value: "DEMULEN 1/35-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEMULEN 1/35-28",
  value: "DEMULEN 1/35-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEMULEN 1/50-21",
  value: "DEMULEN 1/50-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEMULEN 1/50-28",
  value: "DEMULEN 1/50-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Denosumab",
  value: "Denosumab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DENTIPATCH",
  value: "DENTIPATCH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "deoxycholic acid",
  value: "deoxycholic acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Depacon",
  value: "Depacon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEPAKENE",
  value: "DEPAKENE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEPAKOTE",
  value: "DEPAKOTE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEPAKOTE ER",
  value: "DEPAKOTE ER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Depakote Sprinkles",
  value: "Depakote Sprinkles",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEPO-ESTRADIOL",
  value: "DEPO-ESTRADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEPO-TESTADIOL",
  value: "DEPO-TESTADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DERMACORT",
  value: "DERMACORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DERMA-SMOOTHE--SCALP OIL",
  value: "DERMA-SMOOTHE--SCALP OIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Desipramine",
  value: "Desipramine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DESIPRAMINE HYDROCHLORIDE",
  value: "DESIPRAMINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DESLORATADINE",
  value: "DESLORATADINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DESLORATADINE AND PSEUDOEPHEDRINE SULFATE 24 HOUR",
  value: "DESLORATADINE AND PSEUDOEPHEDRINE SULFATE 24 HOUR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DESOGEN",
  value: "DESOGEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Desogestrel",
  value: "Desogestrel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DESOGESTREL AND ETHINYL ESTRADIOL",
  value: "DESOGESTREL AND ETHINYL ESTRADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DESOGESTREL; ETHINYL",
  value: "DESOGESTREL; ETHINYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DESOGESTREL; ETHINYL ESTRADIOL",
  value: "DESOGESTREL; ETHINYL ESTRADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DESONIDE--LOTION",
  value: "DESONIDE--LOTION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DESOXIMETASONE--OINTMENT",
  value: "DESOXIMETASONE--OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "desvenlafaxine",
  value: "desvenlafaxine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Desyrel",
  value: "Desyrel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DETROL",
  value: "DETROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DETROL LA",
  value: "DETROL LA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXACEN-4",
  value: "DEXACEN-4",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXACIDIN",
  value: "DEXACIDIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXACORT",
  value: "DEXACORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXAIR",
  value: "DEXAIR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXAMETHASONE",
  value: "DEXAMETHASONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXAMETHASONE ACETATE",
  value: "DEXAMETHASONE ACETATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXAMETHASONE INTENSOL",
  value: "DEXAMETHASONE INTENSOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXASPORIN",
  value: "DEXASPORIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXCHLORPHENIRAMINE MALEATE",
  value: "DEXCHLORPHENIRAMINE MALEATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dexedrine",
  value: "Dexedrine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "dexfenfluramine",
  value: "dexfenfluramine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXILANT",
  value: "DEXILANT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dexmethylphenidate",
  value: "Dexmethylphenidate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXMETHYLPHENIDATE HYDROCHLORIDE",
  value: "DEXMETHYLPHENIDATE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXONE",
  value: "DEXONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXONE 0.5",
  value: "DEXONE 0.5",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXONE 0.75",
  value: "DEXONE 0.75",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXONE 1.5",
  value: "DEXONE 1.5",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXONE 4",
  value: "DEXONE 4",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "dextroamphetamine",
  value: "dextroamphetamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "dextromethorphan",
  value: "dextromethorphan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "dextromethorphan and guaifenesin",
  value: "dextromethorphan and guaifenesin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "dextrose",
  value: "dextrose",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 10% IN PLASTIC CONTAINER",
  value: "DEXTROSE 10% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 2.5% IN PLASTIC CONTAINER",
  value: "DEXTROSE 2.5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 20% IN PLASTIC CONTAINER",
  value: "DEXTROSE 20% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 25%",
  value: "DEXTROSE 25%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 30% IN PLASTIC CONTAINER",
  value: "DEXTROSE 30% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 38.5% IN PLASTIC CONTAINER",
  value: "DEXTROSE 38.5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 40% IN PLASTIC CONTAINER",
  value: "DEXTROSE 40% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 5%--IV SOLUTION",
  value: "DEXTROSE 5%--IV SOLUTION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 50% IN PLASTIC CONTAINER",
  value: "DEXTROSE 50% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 60%",
  value: "DEXTROSE 60%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 60% IN PLASTIC CONTAINER",
  value: "DEXTROSE 60% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 7.7% IN PLASTIC CONTAINER",
  value: "DEXTROSE 7.7% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DEXTROSE 70% IN PLASTIC CONTAINER",
  value: "DEXTROSE 70% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dextrostat",
  value: "Dextrostat",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dexverapamil",
  value: "Dexverapamil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DHEA",
  value: "DHEA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIABETA",
  value: "DIABETA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIABINESE",
  value: "DIABINESE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIAMOX",
  value: "DIAMOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIASTAT",
  value: "DIASTAT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIASTAT ACUDIAL",
  value: "DIASTAT ACUDIAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Diazepam",
  value: "Diazepam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIAZEPAM INTENSOL",
  value: "DIAZEPAM INTENSOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIAZEPAM RECTAL--GEL",
  value: "DIAZEPAM RECTAL--GEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIBENIL",
  value: "DIBENIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIBENIL, DIPHEN, HYDRAMINE, SILPHEN, BELIX",
  value: '"DIBENIL, DIPHEN, HYDRAMINE, SILPHEN, BELIX"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "diclofenac",
  value: "diclofenac",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DICYCLOMINE",
  value: "DICYCLOMINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DICYCLOMINE HYDROCHLORIDE",
  value: "DICYCLOMINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DICYCLOMINE HYDROCHLORIDE (PRESERVATIVE-FREE)",
  value: "DICYCLOMINE HYDROCHLORIDE (PRESERVATIVE-FREE)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dienestrol",
  value: "Dienestrol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Differin",
  value: "Differin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIFICID",
  value: "DIFICID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIFLUCAN",
  value: "DIFLUCAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIFLUCAN IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "DIFLUCAN IN DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIFLUCAN IN SODIUM CHLORIDE 0.9%",
  value: "DIFLUCAN IN SODIUM CHLORIDE 0.9%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIFLUCAN IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value: "DIFLUCAN IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIGOX",
  value: "DIGOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Digoxin",
  value: "Digoxin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIGOXIN PEDIATRIC",
  value: "DIGOXIN PEDIATRIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dihydroergotamine",
  value: "Dihydroergotamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dilacor",
  value: "Dilacor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DILACOR XR",
  value: "DILACOR XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DILANTIN",
  value: "DILANTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DILANTIN-125",
  value: "DILANTIN-125",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DILANTIN-30",
  value: "DILANTIN-30",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DILATRATE-SR",
  value: "DILATRATE-SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dilaudid",
  value: "Dilaudid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dilt-CD",
  value: "Dilt-CD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "diltiazem",
  value: "diltiazem",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DILTIAZEM HYDROCHLORIDE",
  value: "DILTIAZEM HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DILTZAC",
  value: "DILTZAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "dinutuximab",
  value: "dinutuximab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIOVAN",
  value: "DIOVAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIPHEN",
  value: "DIPHEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "diphenhydramine",
  value: "diphenhydramine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIPHENHYDRAMINE HYDROCHLORIDE",
  value: "DIPHENHYDRAMINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIPHENHYDRAMINE HYDROCHLORIDE PRESERVATIVE FREE",
  value: "DIPHENHYDRAMINE HYDROCHLORIDE PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIPHENOXYLATE and ATROPINE",
  value: "DIPHENOXYLATE and ATROPINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIPRIVAN",
  value: "DIPRIVAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dipyridamole",
  value: "Dipyridamole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Disopyramide",
  value: "Disopyramide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DISOPYRAMIDE PHOSPHATE",
  value: "DISOPYRAMIDE PHOSPHATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DISPERMOX",
  value: "DISPERMOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Disulfiram",
  value: "Disulfiram",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DITATE-DS",
  value: "DITATE-DS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DITROPAN",
  value: "DITROPAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DITROPAN XL",
  value: "DITROPAN XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIULO",
  value: "DIULO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIUPRES-250",
  value: "DIUPRES-250",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIUPRES-500",
  value: "DIUPRES-500",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIUTENSEN-R",
  value: "DIUTENSEN-R",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Divalproex",
  value: "Divalproex",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIVALPROEX SODIUM",
  value: "DIVALPROEX SODIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIVIGEL",
  value: "DIVIGEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DIZAC",
  value: "DIZAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "D-LACTULOSE",
  value: "D-LACTULOSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOCEFREZ",
  value: "DOCEFREZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "docetaxel",
  value: "docetaxel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOC-Q-LACE",
  value: "DOC-Q-LACE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOCUSATE",
  value: "DOCUSATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dofetilide",
  value: "Dofetilide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOK",
  value: "DOK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOK PLUS",
  value: "DOK PLUS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dolasetron",
  value: "Dolasetron",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dolophine",
  value: "Dolophine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOLOPHINE HYDROCHLORIDE",
  value: "DOLOPHINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "dolutegravir",
  value: "dolutegravir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "domperidone",
  value: "domperidone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Donepezil",
  value: "Donepezil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DORZOLAMIDE TIMOLOL--EYE DROPS",
  value: "DORZOLAMIDE TIMOLOL--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DORZOLAMIDE--EYE DROPS",
  value: "DORZOLAMIDE--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dostinex",
  value: "Dostinex",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOW-ISONIAZID",
  value: "DOW-ISONIAZID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "doxazosin",
  value: "doxazosin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOXAZOSIN MESYLATE",
  value: "DOXAZOSIN MESYLATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Doxepin",
  value: "Doxepin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOXEPIN HYDROCHLORIDE",
  value: "DOXEPIN HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOXIL (LIPOSOMAL)",
  value: "DOXIL (LIPOSOMAL)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "doxorubicin",
  value: "doxorubicin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOXORUBICIN HYDROCHLORIDE",
  value: "DOXORUBICIN HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOXORUBICIN HYDROCHLORIDE (LIPOSOMAL)",
  value: "DOXORUBICIN HYDROCHLORIDE (LIPOSOMAL)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DOXYCHEL",
  value: "DOXYCHEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Doxycycline",
  value: "Doxycycline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Doxylamine",
  value: "Doxylamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DRALSERP",
  value: "DRALSERP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DRICORT",
  value: "DRICORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DRIZE",
  value: "DRIZE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dronabinol",
  value: "Dronabinol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dronedarone",
  value: "Dronedarone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DROSPIRENONE AND ETHINYL ESTRADIOL",
  value: "DROSPIRENONE AND ETHINYL ESTRADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DUETACT",
  value: "DUETACT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DUEXIS",
  value: "DUEXIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "duloxetine",
  value: "duloxetine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DUOCAINE",
  value: "DUOCAINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Duoneb",
  value: "Duoneb",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Duopa",
  value: "Duopa",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DUPHALAC",
  value: "DUPHALAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DURACLON",
  value: "DURACLON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Duragesic",
  value: "Duragesic",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DURAGESIC-100",
  value: "DURAGESIC-100",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DURAGESIC-12",
  value: "DURAGESIC-12",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DURAGESIC-25",
  value: "DURAGESIC-25",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DURAGESIC-50",
  value: "DURAGESIC-50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DURAGESIC-75",
  value: "DURAGESIC-75",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Duramorph",
  value: "Duramorph",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DURAPHYL",
  value: "DURAPHYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Duraquin",
  value: "Duraquin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dutasteride",
  value: "Dutasteride",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dutoprol",
  value: "Dutoprol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DV",
  value: "DV",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dyanavel",
  value: "Dyanavel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dyanavel XR",
  value: "Dyanavel XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dyazide",
  value: "Dyazide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DYNACIRC",
  value: "DYNACIRC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "DYNACIRC CR",
  value: "DYNACIRC CR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Dyrenium",
  value: "Dyrenium",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "E.E.S.",
  value: "E.E.S.",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "E.E.S. 200",
  value: "E.E.S. 200",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "E.E.S. 400",
  value: "E.E.S. 400",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EARWAX--DROPS",
  value: "EARWAX--DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "E-BASE",
  value: "E-BASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EC-NAPROSYN",
  value: "EC-NAPROSYN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ECONAZOLE--CREAM",
  value: "ECONAZOLE--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ECONOCHLOR",
  value: "ECONOCHLOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ECONOCHLOR, MYCHEL, OPHTHOCHLOR, OPTOMYCIN",
  value: '"ECONOCHLOR, MYCHEL, OPHTHOCHLOR, OPTOMYCIN"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ECOVENT",
  value: "ECOVENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Edarbi",
  value: "Edarbi",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Edarbyclor",
  value: "Edarbyclor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EDECRIN",
  value: "EDECRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EDLUAR",
  value: "EDLUAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "edoxaban",
  value: "edoxaban",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Edurant",
  value: "Edurant",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Efavirenz",
  value: "Efavirenz",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EFAVIRENZ 200MG SCORED TABLETS",
  value: "EFAVIRENZ 200MG SCORED TABLETS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EFAVIRENZ; EMTRICITABINE; TENOFOVIR DISOPROXIL FUMARATE",
  value: "EFAVIRENZ; EMTRICITABINE; TENOFOVIR DISOPROXIL FUMARATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Effexor",
  value: "Effexor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Effexor XR",
  value: "Effexor XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EFFIENT",
  value: "EFFIENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EFIDAC 24",
  value: "EFIDAC 24",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EFIDAC 24 CHLORPHENIRAMINE MALEATE",
  value: "EFIDAC 24 CHLORPHENIRAMINE MALEATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EFUDEX",
  value: "EFUDEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "E-GLADES",
  value: "E-GLADES",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Elacridar",
  value: "Elacridar",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ELASE-CHLOROMYCETIN",
  value: "ELASE-CHLOROMYCETIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ELAVIL",
  value: "ELAVIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ELDECORT",
  value: "ELDECORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ELESTRIN",
  value: "ELESTRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "eletriptan",
  value: "eletriptan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "elicarbazepine",
  value: "elicarbazepine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ELINEST",
  value: "ELINEST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ELIPHOS",
  value: "ELIPHOS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ELIQUIS",
  value: "ELIQUIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ELIXICON",
  value: "ELIXICON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ELIXOMIN",
  value: "ELIXOMIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ELIXOPHYLLIN",
  value: "ELIXOPHYLLIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ELIXOPHYLLIN SR",
  value: "ELIXOPHYLLIN SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Elocon",
  value: "Elocon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "eluxadoline",
  value: "eluxadoline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "elvitegravir",
  value: "elvitegravir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Embeda",
  value: "Embeda",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EMBOLEX",
  value: "EMBOLEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EMEND",
  value: "EMEND",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EMGEL",
  value: "EMGEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EMOQUETTE",
  value: "EMOQUETTE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Empagliflozin",
  value: "Empagliflozin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Emtricitabine",
  value: "Emtricitabine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Emtriva",
  value: "Emtriva",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "E-MYCIN",
  value: "E-MYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "E-MYCIN E",
  value: "E-MYCIN E",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ENABLEX",
  value: "ENABLEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Enalapril",
  value: "Enalapril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ENBREL",
  value: "ENBREL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "encainide",
  value: "encainide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ENDEP",
  value: "ENDEP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Endocet",
  value: "Endocet",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ENDOMETRIN",
  value: "ENDOMETRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "enfuvirtide",
  value: "enfuvirtide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ENKAID",
  value: "ENKAID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Enoxacin",
  value: "Enoxacin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ENOXAPARIN",
  value: "ENOXAPARIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ENPRESSE-21",
  value: "ENPRESSE-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ENPRESSE-28",
  value: "ENPRESSE-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ENSKYCE",
  value: "ENSKYCE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Entacapone",
  value: "Entacapone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ENTOCORT EC",
  value: "ENTOCORT EC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Entresto",
  value: "Entresto",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ENULOSE",
  value: "ENULOSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Envarsus XR",
  value: "Envarsus XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EPICORT",
  value: "EPICORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EPIFOAM",
  value: "EPIFOAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Epinephrine",
  value: "Epinephrine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Epipen",
  value: "Epipen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Epirubicin",
  value: "Epirubicin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EPITOL",
  value: "EPITOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EPIVIR",
  value: "EPIVIR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EPIVIR-HBV",
  value: "EPIVIR-HBV",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "eplerenone",
  value: "eplerenone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Epoetin alfa",
  value: "Epoetin alfa",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EPOGEN",
  value: "EPOGEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EPOGEN/PROCRIT",
  value: "EPOGEN/PROCRIT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EPREX",
  value: "EPREX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "eprosartan",
  value: "eprosartan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Epzicom",
  value: "Epzicom",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EQUETRO",
  value: "EQUETRO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ergomar",
  value: "Ergomar",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ergotamine",
  value: "Ergotamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Erlotinib",
  value: "Erlotinib",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERRIN",
  value: "ERRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYC",
  value: "ERYC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYC 125",
  value: "ERYC 125",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYC SPRINKLES",
  value: "ERYC SPRINKLES",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYCETTE",
  value: "ERYCETTE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYDERM",
  value: "ERYDERM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYGEL",
  value: "ERYGEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYMAX",
  value: "ERYMAX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYPAR",
  value: "ERYPAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYPED",
  value: "ERYPED",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERY-TAB",
  value: "ERY-TAB",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYTHRA-DERM",
  value: "ERYTHRA-DERM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYTHROCIN STEARATE",
  value: "ERYTHROCIN STEARATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Erythromycin",
  value: "Erythromycin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYTHROMYCIN AND BENZOYL PEROXIDE",
  value: "ERYTHROMYCIN AND BENZOYL PEROXIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYTHROMYCIN ESTOLATE",
  value: "ERYTHROMYCIN ESTOLATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYTHROMYCIN ETHYLSUCCINATE",
  value: "ERYTHROMYCIN ETHYLSUCCINATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYTHROMYCIN ETHYLSUCCINATE AND SULFISOXAZOLE ACETYL",
  value: "ERYTHROMYCIN ETHYLSUCCINATE AND SULFISOXAZOLE ACETYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYTHROMYCIN LACTOBIONATE",
  value: "ERYTHROMYCIN LACTOBIONATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYTHROMYCIN STEARATE",
  value: "ERYTHROMYCIN STEARATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYTHROMYCIN--EYE OINTMENT",
  value: "ERYTHROMYCIN--EYE OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYTHRO-STATIN",
  value: "ERYTHRO-STATIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ERYZOLE",
  value: "ERYZOLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Escitalopram",
  value: "Escitalopram",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESCITALOPRAM OXALATE",
  value: "ESCITALOPRAM OXALATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESCLIM",
  value: "ESCLIM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESIDRIX",
  value: "ESIDRIX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESKALITH",
  value: "ESKALITH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESKALITH CR",
  value: "ESKALITH CR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "eslicarbazepine",
  value: "eslicarbazepine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Esmolol",
  value: "Esmolol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "E-SOLVE 2",
  value: "E-SOLVE 2",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Esomeprazole",
  value: "Esomeprazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESOMEPRAZOLE MAGNESIUM",
  value: "ESOMEPRAZOLE MAGNESIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESOMEPRAZOLE SODIUM",
  value: "ESOMEPRAZOLE SODIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESOMEPRAZOLE STRONTIUM",
  value: "ESOMEPRAZOLE STRONTIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTARYLLA",
  value: "ESTARYLLA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTINYL",
  value: "ESTINYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTRACE",
  value: "ESTRACE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTRACE--CREAM",
  value: "ESTRACE--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTRADERM",
  value: "ESTRADERM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "estradiol",
  value: "estradiol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTRADIOL AND NORETHINDRONE ACETATE",
  value: "ESTRADIOL AND NORETHINDRONE ACETATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTRADIOL AND NORGESTIMATE",
  value: "ESTRADIOL AND NORGESTIMATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTRADIOL CYPIONATE",
  value: "ESTRADIOL CYPIONATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTRADIOL VALERATE",
  value: "ESTRADIOL VALERATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTRADURIN",
  value: "ESTRADURIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTRAGUARD",
  value: "ESTRAGUARD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTRASORB",
  value: "ESTRASORB",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTRING",
  value: "ESTRING",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTROGEL",
  value: "ESTROGEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTROGENIC SUBSTANCE",
  value: "ESTROGENIC SUBSTANCE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Estrogens",
  value: "Estrogens",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Estrone",
  value: "Estrone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Estrone sulfate",
  value: "Estrone sulfate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTROSTEP 21",
  value: "ESTROSTEP 21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ESTROSTEP FE",
  value: "ESTROSTEP FE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Eszopiclone",
  value: "Eszopiclone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "etanercept",
  value: "etanercept",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ethacrynic acid",
  value: "Ethacrynic acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ethinyl Estradiol",
  value: "Ethinyl Estradiol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ethosuximide",
  value: "ethosuximide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ethotoin",
  value: "ethotoin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ETHRIL 250",
  value: "ETHRIL 250",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ETHRIL 500",
  value: "ETHRIL 500",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Etonogestrel",
  value: "Etonogestrel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Etoposide",
  value: "Etoposide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Etrafon",
  value: "Etrafon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ETRAFON 2-10",
  value: "ETRAFON 2-10",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ETRAFON 2-25",
  value: "ETRAFON 2-25",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ETRAFON-A",
  value: "ETRAFON-A",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ETRAFON-FORTE",
  value: "ETRAFON-FORTE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "etravirine",
  value: "etravirine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EUCERIN--CREAM",
  value: "EUCERIN--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EUCERIN--LOTION",
  value: "EUCERIN--LOTION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EULEXIN",
  value: "EULEXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EURAX--CREAM",
  value: "EURAX--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EVALOSE",
  value: "EVALOSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EVAMIST",
  value: "EVAMIST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Everolimus",
  value: "Everolimus",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EVIPAN",
  value: "EVIPAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Evista",
  value: "Evista",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "evolocumab",
  value: "evolocumab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Evotaz",
  value: "Evotaz",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Evzio",
  value: "Evzio",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Exalgo",
  value: "Exalgo",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Exelon",
  value: "Exelon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EXELON--PATCH",
  value: "EXELON--PATCH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Exemestane",
  value: "Exemestane",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Exenatide",
  value: "Exenatide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Exforge",
  value: "Exforge",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Exforge HCT",
  value: "Exforge HCT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Exforge, Lotrel, Tekamlo, Tribenzor, Twynsta",
  value: '"Exforge, Lotrel, Tekamlo, Tribenzor, Twynsta"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EXTAVIA",
  value: "EXTAVIA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EXTINA",
  value: "EXTINA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "EXUBERA",
  value: "EXUBERA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ezetemibe",
  value: "ezetemibe",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ezetimibe",
  value: "Ezetimibe",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ezetimibe & Simvastatin",
  value: "Ezetimibe & Simvastatin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ezogabine",
  value: "ezogabine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FALMINA",
  value: "FALMINA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Famotidine",
  value: "Famotidine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FAMOTIDINE PRESERVATIVE FREE",
  value: "FAMOTIDINE PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FAMOTIDINE PRESERVATIVE FREE (PHARMACY BULK)",
  value: "FAMOTIDINE PRESERVATIVE FREE (PHARMACY BULK)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FAMOTIDINE PRESERVATIVE FREE IN PLASTIC CONTAINER",
  value: "FAMOTIDINE PRESERVATIVE FREE IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FANAPT",
  value: "FANAPT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Farxiga",
  value: "Farxiga",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Farydak",
  value: "Farydak",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fazaclo",
  value: "Fazaclo",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FAZACLO ODT",
  value: "FAZACLO ODT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Febuxostat",
  value: "Febuxostat",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "felbamate",
  value: "felbamate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FELBATOL",
  value: "FELBATOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FELDENE",
  value: "FELDENE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "felodipine",
  value: "felodipine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FEMARA",
  value: "FEMARA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FEMCON FE",
  value: "FEMCON FE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FEMHRT",
  value: "FEMHRT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FEMINONE",
  value: "FEMINONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FEMPATCH",
  value: "FEMPATCH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FEMRING",
  value: "FEMRING",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FEMTRACE",
  value: "FEMTRACE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fenfluramine",
  value: "Fenfluramine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "fenofibrate",
  value: "fenofibrate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENOFIBRATE (MICRONIZED)",
  value: "FENOFIBRATE (MICRONIZED)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fenofibric acid",
  value: "Fenofibric acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENOGLIDE",
  value: "FENOGLIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fenoprofen",
  value: "Fenoprofen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENOPROFEN CALCIUM",
  value: "FENOPROFEN CALCIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "fentanyl",
  value: "fentanyl",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENTANYL CITRATE",
  value: "FENTANYL CITRATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENTANYL CITRATE AND DROPERIDOL",
  value: "FENTANYL CITRATE AND DROPERIDOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENTANYL CITRATE PRESERVATIVE FREE",
  value: "FENTANYL CITRATE PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENTANYL-100",
  value: "FENTANYL-100",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENTANYL-12",
  value: "FENTANYL-12",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENTANYL-25",
  value: "FENTANYL-25",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENTANYL-50",
  value: "FENTANYL-50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENTANYL-75",
  value: "FENTANYL-75",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENTANYL--PATCH",
  value: "FENTANYL--PATCH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FENTORA",
  value: "FENTORA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ferndex",
  value: "Ferndex",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FERNISOLONE-P",
  value: "FERNISOLONE-P",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FERNISONE",
  value: "FERNISONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FERROUS SULFATE",
  value: "FERROUS SULFATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FESOTERODINE",
  value: "FESOTERODINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FESOTERODINE FUMARATE",
  value: "FESOTERODINE FUMARATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fetzima",
  value: "Fetzima",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fexofenadine",
  value: "Fexofenadine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FIBER-LAX",
  value: "FIBER-LAX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FIBRICOR",
  value: "FIBRICOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fidaxomicin",
  value: "Fidaxomicin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "finasteride",
  value: "finasteride",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FIORINAL",
  value: "FIORINAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FIORINAL W/CODEINE",
  value: "FIORINAL W/CODEINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "fish oil",
  value: "fish oil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLAGYL",
  value: "FLAGYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLAGYL ER",
  value: "FLAGYL ER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLAGYL I.V. RTU IN PLASTIC CONTAINER",
  value: "FLAGYL I.V. RTU IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Flavopiridol",
  value: "Flavopiridol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLAVOXATE",
  value: "FLAVOXATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLAVOXATE HYDROCHLORIDE",
  value: "FLAVOXATE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "flecainide",
  value: "flecainide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLECAINIDE ACETATE",
  value: "FLECAINIDE ACETATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLECTOR--PATCH",
  value: "FLECTOR--PATCH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fleet enema",
  value: "Fleet enema",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLEXERIL",
  value: "FLEXERIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLEXICORT",
  value: "FLEXICORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "flibanserin",
  value: "flibanserin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Flomax",
  value: "Flomax",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLONASE",
  value: "FLONASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLONASE ALLERGY RELIEF",
  value: "FLONASE ALLERGY RELIEF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLORASTOR",
  value: "FLORASTOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLOVENT",
  value: "FLOVENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLOVENT DISKUS",
  value: "FLOVENT DISKUS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLOVENT DISKUS 100",
  value: "FLOVENT DISKUS 100",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLOVENT DISKUS 250",
  value: "FLOVENT DISKUS 250",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLOVENT DISKUS 50",
  value: "FLOVENT DISKUS 50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLOVENT HFA",
  value: "FLOVENT HFA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLOXIN",
  value: "FLOXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLOXIN IN DEXTROSE 5%",
  value: "FLOXIN IN DEXTROSE 5%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLOXIN IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "FLOXIN IN DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLOXIN OTIC",
  value: "FLOXIN OTIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUCANAZOLE IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value: "FLUCANAZOLE IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fluconazole",
  value: "Fluconazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUCONAZOLE IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "FLUCONAZOLE IN DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUCONAZOLE IN SODIUM CHLORIDE 0.9%",
  value: "FLUCONAZOLE IN SODIUM CHLORIDE 0.9%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUCONAZOLE IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value: "FLUCONAZOLE IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUDROCORTISONE",
  value: "FLUDROCORTISONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUOCINONIDE--CREAM",
  value: "FLUOCINONIDE--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fluocinonide--ointment",
  value: "Fluocinonide--ointment",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUOROPLEX",
  value: "FLUOROPLEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "fluoroquinolones",
  value: "fluoroquinolones",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fluorouracil",
  value: "Fluorouracil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUOROURACIL--CREAM",
  value: "FLUOROURACIL--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "fluoxetine",
  value: "fluoxetine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUOXETINE HYDROCHLORIDE",
  value: "FLUOXETINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fluphenazine",
  value: "Fluphenazine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUPHENAZINE DECANOATE",
  value: "FLUPHENAZINE DECANOATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUPHENAZINE HCL",
  value: "FLUPHENAZINE HCL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUPHENAZINE HYDROCHLORIDE",
  value: "FLUPHENAZINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Flurbiprofen",
  value: "Flurbiprofen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Flutamide",
  value: "Flutamide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fluticasone",
  value: "Fluticasone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUTICASONE FUROATE",
  value: "FLUTICASONE FUROATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUTICASONE PROPIONATE",
  value: "FLUTICASONE PROPIONATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "fluvastatin",
  value: "fluvastatin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUVASTATIN SODIUM",
  value: "FLUVASTATIN SODIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "fluvoxamine",
  value: "fluvoxamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUVOXAMINE MALEATE",
  value: "FLUVOXAMINE MALEATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUXID",
  value: "FLUXID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FLUZONE",
  value: "FLUZONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FOCALIN",
  value: "FOCALIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FOCALIN XR",
  value: "FOCALIN XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "folic acid",
  value: "folic acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FOLICET",
  value: "FOLICET",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FOLVITE",
  value: "FOLVITE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FORBAXIN",
  value: "FORBAXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Forfivo",
  value: "Forfivo",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FORFIVO XL",
  value: "FORFIVO XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Formoterol",
  value: "Formoterol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "forteo",
  value: "forteo",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FORTESTA",
  value: "FORTESTA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fortical",
  value: "Fortical",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FORTOVASE",
  value: "FORTOVASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fosamax",
  value: "Fosamax",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fosamax Plus D",
  value: "Fosamax Plus D",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "fosamprenavir",
  value: "fosamprenavir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fosinopril",
  value: "Fosinopril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "fosphenytoin",
  value: "fosphenytoin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Frova",
  value: "Frova",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "frovatriptan",
  value: "frovatriptan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FRUCTOFURANOSE",
  value: "FRUCTOFURANOSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FURADANTIN",
  value: "FURADANTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "furafylline",
  value: "furafylline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "FURALAN",
  value: "FURALAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Furosemide",
  value: "Furosemide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fuzeon",
  value: "Fuzeon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Fycompa",
  value: "Fycompa",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "gabapentin",
  value: "gabapentin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "gabapentin enacarbil",
  value: "gabapentin enacarbil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Gabitril",
  value: "Gabitril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GABLOFEN",
  value: "GABLOFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Galantamine",
  value: "Galantamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ganciclovir",
  value: "Ganciclovir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GANTANOL",
  value: "GANTANOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GANTANOL-DS",
  value: "GANTANOL-DS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GAVILYTE-C SOLUTION",
  value: "GAVILYTE-C SOLUTION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Gefitinib",
  value: "Gefitinib",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GELNIQUE",
  value: "GELNIQUE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GELNIQUE 3%",
  value: "GELNIQUE 3%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Gemfibrozil",
  value: "Gemfibrozil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GENCEPT 10/11-21",
  value: "GENCEPT 10/11-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GENCEPT 10/11-28",
  value: "GENCEPT 10/11-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GENERLAC",
  value: "GENERLAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GENGRAF",
  value: "GENGRAF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Gentamicin",
  value: "Gentamicin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GENTAMICIN--CREAM",
  value: "GENTAMICIN--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GENTAMICIN--EYE DROPS",
  value: "GENTAMICIN--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GENTAMICIN--OINTMENT",
  value: "GENTAMICIN--OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Geodon",
  value: "Geodon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "gestodene",
  value: "gestodene",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GILDAGIA",
  value: "GILDAGIA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GILDESS FE 1.5/30",
  value: "GILDESS FE 1.5/30",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GILDESS FE 1/20",
  value: "GILDESS FE 1/20",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLATIRAMER ACETATE",
  value: "GLATIRAMER ACETATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLEEVEC",
  value: "GLEEVEC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "glibenclamide",
  value: "glibenclamide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "glimepiride",
  value: "glimepiride",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "glipizide",
  value: "glipizide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLIPIZIDE AND METFORMIN HYDROCHLORIDE",
  value: "GLIPIZIDE AND METFORMIN HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLUCAGEN",
  value: "GLUCAGEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLUCAGON",
  value: "GLUCAGON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLUCAMIDE",
  value: "GLUCAMIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "glucocorticoids",
  value: "glucocorticoids",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "glucosamine",
  value: "glucosamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLUCOTROL",
  value: "GLUCOTROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLUCOTROL XL",
  value: "GLUCOTROL XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLUCOVANCE",
  value: "GLUCOVANCE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "glyburide",
  value: "glyburide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLYBURIDE (MICRONIZED)",
  value: "GLYBURIDE (MICRONIZED)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "glyburide and metformin",
  value: "glyburide and metformin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "glycate",
  value: "glycate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLYCOPYRROLATE",
  value: "GLYCOPYRROLATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLYCORT",
  value: "GLYCORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLYDO",
  value: "GLYDO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GLYNASE",
  value: "GLYNASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "golimumab",
  value: "golimumab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GRALISE",
  value: "GRALISE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GRANULEX--SPRAY",
  value: "GRANULEX--SPRAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Griseofulvin",
  value: "Griseofulvin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "guaifenesin",
  value: "guaifenesin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "guanfacine",
  value: "guanfacine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GUANFACINE HYDROCHLORIDE",
  value: "GUANFACINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Guanoxan",
  value: "Guanoxan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "gubernal",
  value: "gubernal",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GYNE-LOTRIMIN",
  value: "GYNE-LOTRIMIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GYNE-LOTRIMIN 3",
  value: "GYNE-LOTRIMIN 3",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GYNE-LOTRIMIN 3 COMBINATION PACK",
  value: "GYNE-LOTRIMIN 3 COMBINATION PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GYNE-LOTRIMIN COMBINATION PACK",
  value: "GYNE-LOTRIMIN COMBINATION PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GYNIX",
  value: "GYNIX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "GYNODIOL",
  value: "GYNODIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "H.R.-50",
  value: "H.R.-50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HABITROL",
  value: "HABITROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HALCION",
  value: "HALCION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HALDOL",
  value: "HALDOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HALDOL SOLUTAB",
  value: "HALDOL SOLUTAB",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HALFAN",
  value: "HALFAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "halofantrine",
  value: "halofantrine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Haloperidol",
  value: "Haloperidol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HALOPERIDOL DECANOATE",
  value: "HALOPERIDOL DECANOATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HALOPERIDOL INTENSOL",
  value: "HALOPERIDOL INTENSOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HALOPERIDOL LACTATE",
  value: "HALOPERIDOL LACTATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HC #1",
  value: "HC #1",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HC #4",
  value: "HC #4",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HC (HYDROCORTISONE)",
  value: "HC (HYDROCORTISONE)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "H-CORT",
  value: "H-CORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEATHER",
  value: "HEATHER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEMSOL-HC",
  value: "HEMSOL-HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "heparin",
  value: "heparin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN LOCK FLUSH",
  value: "HEPARIN LOCK FLUSH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "heparin sodium",
  value: "heparin sodium",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "HEPARIN SODIUM 1,000 UNITS AND SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value:
   '"HEPARIN SODIUM 1,000 UNITS AND SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 1,000 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: '"HEPARIN SODIUM 1,000 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "HEPARIN SODIUM 1,000 UNITS IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value:
   '"HEPARIN SODIUM 1,000 UNITS IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 10,000 UNITS AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: '"HEPARIN SODIUM 10,000 UNITS AND DEXTROSE 5% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 10,000 UNITS IN DEXTROSE 5%",
  value: '"HEPARIN SODIUM 10,000 UNITS IN DEXTROSE 5%"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 10,000 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: '"HEPARIN SODIUM 10,000 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 10,000 UNITS IN SODIUM CHLORIDE 0.45%",
  value: '"HEPARIN SODIUM 10,000 UNITS IN SODIUM CHLORIDE 0.45%"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 10,000 UNITS IN SODIUM CHLORIDE 0.9%",
  value: '"HEPARIN SODIUM 10,000 UNITS IN SODIUM CHLORIDE 0.9%"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 12,500 UNITS IN DEXTROSE 5%",
  value: '"HEPARIN SODIUM 12,500 UNITS IN DEXTROSE 5%"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 12,500 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: '"HEPARIN SODIUM 12,500 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "HEPARIN SODIUM 12,500 UNITS IN SODIUM CHLORIDE 0.45% IN PLASTIC CONTAINER",
  value:
   '"HEPARIN SODIUM 12,500 UNITS IN SODIUM CHLORIDE 0.45% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 12,500 UNITS IN SODIUM CHLORIDE 0.9%",
  value: '"HEPARIN SODIUM 12,500 UNITS IN SODIUM CHLORIDE 0.9%"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "HEPARIN SODIUM 2,000 UNITS AND SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value:
   '"HEPARIN SODIUM 2,000 UNITS AND SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 2,000 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: '"HEPARIN SODIUM 2,000 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "HEPARIN SODIUM 2,000 UNITS IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value:
   '"HEPARIN SODIUM 2,000 UNITS IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 20,000 UNITS AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: '"HEPARIN SODIUM 20,000 UNITS AND DEXTROSE 5% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 20,000 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: '"HEPARIN SODIUM 20,000 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 25,000 UNITS AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: '"HEPARIN SODIUM 25,000 UNITS AND DEXTROSE 5% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 25,000 UNITS IN DEXTROSE 5%",
  value: '"HEPARIN SODIUM 25,000 UNITS IN DEXTROSE 5%"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 25,000 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: '"HEPARIN SODIUM 25,000 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "HEPARIN SODIUM 25,000 UNITS IN SODIUM CHLORIDE 0.45% IN PLASTIC CONTAINER",
  value:
   '"HEPARIN SODIUM 25,000 UNITS IN SODIUM CHLORIDE 0.45% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 25,000 UNITS IN SODIUM CHLORIDE 0.9%",
  value: '"HEPARIN SODIUM 25,000 UNITS IN SODIUM CHLORIDE 0.9%"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "HEPARIN SODIUM 25,000 UNITS IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value:
   '"HEPARIN SODIUM 25,000 UNITS IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "HEPARIN SODIUM 5,000 UNITS AND SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value:
   '"HEPARIN SODIUM 5,000 UNITS AND SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 5,000 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: '"HEPARIN SODIUM 5,000 UNITS IN DEXTROSE 5% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 5,000 UNITS IN SODIUM CHLORIDE 0.45%",
  value: '"HEPARIN SODIUM 5,000 UNITS IN SODIUM CHLORIDE 0.45%"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM 5,000 UNITS IN SODIUM CHLORIDE 0.9%",
  value: '"HEPARIN SODIUM 5,000 UNITS IN SODIUM CHLORIDE 0.9%"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "HEPARIN SODIUM 5,000 UNITS IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value:
   '"HEPARIN SODIUM 5,000 UNITS IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM IN PLASTIC CONTAINER",
  value: "HEPARIN SODIUM IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPARIN SODIUM PRESERVATIVE FREE",
  value: "HEPARIN SODIUM PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEPTALAC",
  value: "HEPTALAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Herceptin",
  value: "Herceptin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HEXADROL",
  value: "HEXADROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "hexobarbital",
  value: "hexobarbital",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HIBICLENS--LIQUID",
  value: "HIBICLENS--LIQUID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HI-COR",
  value: "HI-COR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HIPREX",
  value: "HIPREX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HISERPIA",
  value: "HISERPIA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HISMANAL",
  value: "HISMANAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Homatropine",
  value: "Homatropine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Horizant",
  value: "Horizant",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Humalog",
  value: "Humalog",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HUMALOG 75-25",
  value: "HUMALOG 75-25",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HUMIRA",
  value: "HUMIRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Humulin 70/30",
  value: "Humulin 70/30",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HUMULIN 70-30",
  value: "HUMULIN 70-30",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HUMULIN MIX 50-50",
  value: "HUMULIN MIX 50-50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Humulin N Kwikpen",
  value: "Humulin N Kwikpen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Humulin R U-500",
  value: "Humulin R U-500",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "hydralazine",
  value: "hydralazine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDRALAZINE HYDROCHLORIDE, HYDROCHLOROTHIAZIDE AND RESERPINE",
  value: '"HYDRALAZINE HYDROCHLORIDE, HYDROCHLOROTHIAZIDE AND RESERPINE"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDRALAZINE HYDROCHLORIDE-HYDROCHLOROTHIAZIDE-RESERPINE",
  value: "HYDRALAZINE HYDROCHLORIDE-HYDROCHLOROTHIAZIDE-RESERPINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDRALAZINE, HYDROCHLOROTHIAZIDE W/ RESERPINE",
  value: '"HYDRALAZINE, HYDROCHLOROTHIAZIDE W/ RESERPINE"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDRAMINE",
  value: "HYDRAMINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDRAP-ES",
  value: "HYDRAP-ES",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "hydrochlorothiazide",
  value: "hydrochlorothiazide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCHLOROTHIAZIDE INTENSOL",
  value: "HYDROCHLOROTHIAZIDE INTENSOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCHLOROTHIAZIDE W/ RESERPINE",
  value: "HYDROCHLOROTHIAZIDE W/ RESERPINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCHLOROTHIAZIDE W/ RESERPINE AND HYDRALAZINE",
  value: "HYDROCHLOROTHIAZIDE W/ RESERPINE AND HYDRALAZINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCHLOROTHIAZIDE; IRBESARTAN",
  value: "HYDROCHLOROTHIAZIDE; IRBESARTAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Hydrocodone",
  value: "Hydrocodone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Hydrocodone and acetaminophen",
  value: "Hydrocodone and acetaminophen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCODONE BITARTRATE AND IBUPROFEN",
  value: "HYDROCODONE BITARTRATE AND IBUPROFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCODONE POLISTIREX AND CHLORPHENIRAMNE POLISTIREX",
  value: "HYDROCODONE POLISTIREX AND CHLORPHENIRAMNE POLISTIREX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Hydrocortisone",
  value: "Hydrocortisone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCORTISONE ACETATE",
  value: "HYDROCORTISONE ACETATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCORTISONE ACETATE 1% AND PRAMOXINE HYDROCHLORIDE 1%",
  value: "HYDROCORTISONE ACETATE 1% AND PRAMOXINE HYDROCHLORIDE 1%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCORTISONE AND ACETIC ACID",
  value: "HYDROCORTISONE AND ACETIC ACID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCORTISONE BUTYRATE",
  value: "HYDROCORTISONE BUTYRATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCORTISONE IN ABSORBASE",
  value: "HYDROCORTISONE IN ABSORBASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCORTISONE SODIUM PHOSPHATE",
  value: "HYDROCORTISONE SODIUM PHOSPHATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCORTISONE SODIUM SUCCINATE",
  value: "HYDROCORTISONE SODIUM SUCCINATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCORTISONE VALERATE",
  value: "HYDROCORTISONE VALERATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCORTISONE VALERATE--CREAM",
  value: "HYDROCORTISONE VALERATE--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "hydrocortisone--cream",
  value: "hydrocortisone--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCORTISONE--OINTMENT",
  value: "HYDROCORTISONE--OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROCORTONE",
  value: "HYDROCORTONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDRO-D",
  value: "HYDRO-D",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDRODIURIL",
  value: "HYDRODIURIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROFLUMETHIAZIDE AND RESERPINE",
  value: "HYDROFLUMETHIAZIDE AND RESERPINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "hydromorphone",
  value: "hydromorphone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROMOX R",
  value: "HYDROMOX R",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROPRES 25",
  value: "HYDROPRES 25",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROPRES 50",
  value: "HYDROPRES 50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDRO-RESERP",
  value: "HYDRO-RESERP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDRO-RX",
  value: "HYDRO-RX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: 'HYDRO-SERP ""25""',
  value: 'HYDRO-SERP ""25""',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: 'HYDRO-SERP ""50""',
  value: 'HYDRO-SERP ""50""',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROSERPINE PLUS (R-H-H)",
  value: "HYDROSERPINE PLUS (R-H-H)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Hydroxychloroquine",
  value: "Hydroxychloroquine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Hydroxyzine",
  value: "Hydroxyzine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROXYZINE HYDROCHLORIDE",
  value: "HYDROXYZINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYDROXYZINE PAMOATE",
  value: "HYDROXYZINE PAMOATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYGROTON",
  value: "HYGROTON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYOSCYAMINE--drop",
  value: "HYOSCYAMINE--drop",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: 'HY-PAM ""25""',
  value: 'HY-PAM ""25""',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: 'HY-PAM "25"',
  value: 'HY-PAM "25"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Hy-Pam 25",
  value: "Hy-Pam 25",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYTONE",
  value: "HYTONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYTRIN",
  value: "HYTRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Hyzaar",
  value: "Hyzaar",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "HYZYD",
  value: "HYZYD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ibandronate",
  value: "Ibandronate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ibrance",
  value: "Ibrance",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IBU",
  value: "IBU",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IBUPRIN",
  value: "IBUPRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ibuprofen",
  value: "ibuprofen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IBUPROFEN AND DIPHENHYDRAMINE CITRATE",
  value: "IBUPROFEN AND DIPHENHYDRAMINE CITRATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IBUPROFEN AND DIPHENHYDRAMINE HYDROCHLORIDE",
  value: "IBUPROFEN AND DIPHENHYDRAMINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IBUPROFEN AND PSEUDOEPHEDRINE HYDROCHLORIDE",
  value: "IBUPROFEN AND PSEUDOEPHEDRINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IBUPROHM",
  value: "IBUPROHM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IBUPROHM COLD AND SINUS",
  value: "IBUPROHM COLD AND SINUS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IBU-TAB",
  value: "IBU-TAB",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IBU-TAB 200",
  value: "IBU-TAB 200",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ibutilide",
  value: "ibutilide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IBUTILIDE FUMARATE",
  value: "IBUTILIDE FUMARATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Idarubicin",
  value: "Idarubicin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Iloperidone",
  value: "Iloperidone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ILOSONE",
  value: "ILOSONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ILOSONE SULFA",
  value: "ILOSONE SULFA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ILOTYCIN",
  value: "ILOTYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ILOTYCIN GLUCEPTATE",
  value: "ILOTYCIN GLUCEPTATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Imatinib",
  value: "Imatinib",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IMATINIB MESYLATE",
  value: "IMATINIB MESYLATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IMDUR",
  value: "IMDUR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Imipramine",
  value: "Imipramine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IMIPRAMINE HYDROCHLORIDE",
  value: "IMIPRAMINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IMIPRAMINE PAMOATE",
  value: "IMIPRAMINE PAMOATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IMITREX",
  value: "IMITREX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IMODIUM",
  value: "IMODIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IMODIUM A-D",
  value: "IMODIUM A-D",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IMODIUM A-D EZ CHEWS",
  value: "IMODIUM A-D EZ CHEWS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IMODIUM MULTI-SYMPTOM RELIEF",
  value: "IMODIUM MULTI-SYMPTOM RELIEF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IMPLANON",
  value: "IMPLANON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IMURAN",
  value: "IMURAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INCIVEK",
  value: "INCIVEK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Inderal",
  value: "Inderal",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Inderal LA",
  value: "Inderal LA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Inderide",
  value: "Inderide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "indinavir",
  value: "indinavir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INDOCIN",
  value: "INDOCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INDOCIN SR",
  value: "INDOCIN SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INDO-LEMMON",
  value: "INDO-LEMMON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Indomethacin",
  value: "Indomethacin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INDOMETHACIN SODIUM",
  value: "INDOMETHACIN SODIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Indoramin",
  value: "Indoramin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INFANTS' FEVERALL",
  value: "INFANTS' FEVERALL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INFERGEN",
  value: "INFERGEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "infliximab",
  value: "infliximab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Influenza Virus Vaccine",
  value: "Influenza Virus Vaccine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Infumorph",
  value: "Infumorph",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INH",
  value: "INH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INJECTAPAP",
  value: "INJECTAPAP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INNOFEM",
  value: "INNOFEM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "InnoPran XL",
  value: "InnoPran XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INNOVAR",
  value: "INNOVAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Inositol",
  value: "Inositol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INSPRA",
  value: "INSPRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "insulin",
  value: "insulin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "insulin aspart",
  value: "insulin aspart",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "insulin degludec",
  value: "insulin degludec",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "insulin detemir",
  value: "insulin detemir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "insulin glargine",
  value: "insulin glargine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "insulin glulisine",
  value: "insulin glulisine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "insulin lispro",
  value: "insulin lispro",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Insulin NPH",
  value: "Insulin NPH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Insulin Regular",
  value: "Insulin Regular",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Intelence",
  value: "Intelence",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "interferon",
  value: "interferon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INTERMEZZO",
  value: "INTERMEZZO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INTRON A",
  value: "INTRON A",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INTROVALE",
  value: "INTROVALE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INTUNIV",
  value: "INTUNIV",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INVEGA",
  value: "INVEGA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INVEGA SUSTENNA",
  value: "INVEGA SUSTENNA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "INVIRASE",
  value: "INVIRASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Iodine",
  value: "Iodine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IONSYS",
  value: "IONSYS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IONTOCAINE",
  value: "IONTOCAINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IPRATROPIUM",
  value: "IPRATROPIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ipratropium and albuterol",
  value: "ipratropium and albuterol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IPRATROPIUM BROMIDE",
  value: "IPRATROPIUM BROMIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ipratropium bromide and albuterol sulfate",
  value: "ipratropium bromide and albuterol sulfate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IQUIX",
  value: "IQUIX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "irbesartan",
  value: "irbesartan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IRBESARTAN AND HYDROCHLOROTHIAZIDE",
  value: "IRBESARTAN AND HYDROCHLOROTHIAZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IRBESARTAN HYDROCHLOROTHIAZIDE",
  value: "IRBESARTAN HYDROCHLOROTHIAZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IRBESARTAN; HYDROCHLOROTHIAZIDE",
  value: "IRBESARTAN; HYDROCHLOROTHIAZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IRESSA",
  value: "IRESSA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Irinotecan",
  value: "Irinotecan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IRINOTECAN HYDROCHLORIDE",
  value: "IRINOTECAN HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "isavuconazonium",
  value: "isavuconazonium",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Isentress",
  value: "Isentress",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ISMO",
  value: "ISMO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ISMOTIC",
  value: "ISMOTIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ISOCLOR",
  value: "ISOCLOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Isoniazid",
  value: "Isoniazid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Isoptin",
  value: "Isoptin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Isoptin SR",
  value: "Isoptin SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Isopto Atropine",
  value: "Isopto Atropine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ISORDIL",
  value: "ISORDIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Isosorbide",
  value: "Isosorbide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "isosorbide dinitrate",
  value: "isosorbide dinitrate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "isosorbide DN",
  value: "isosorbide DN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "isosorbide MN",
  value: "isosorbide MN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "isosorbide mononitrate",
  value: "isosorbide mononitrate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Isradipine",
  value: "Isradipine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ITCH RELIEF--CREAM",
  value: "ITCH RELIEF--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Itraconazole",
  value: "Itraconazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IV PERSANTINE",
  value: "IV PERSANTINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ivabradine",
  value: "ivabradine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "IVADANTIN",
  value: "IVADANTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ivermectin",
  value: "ivermectin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Jantoven",
  value: "Jantoven",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Janumet",
  value: "Janumet",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "januvia",
  value: "januvia",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Jardiance",
  value: "Jardiance",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "JENCYCLA",
  value: "JENCYCLA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "JENLOGA",
  value: "JENLOGA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "JUNEL 1.5/30",
  value: "JUNEL 1.5/30",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "JUNEL 1/20",
  value: "JUNEL 1/20",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "JUNEL FE 1.5/30",
  value: "JUNEL FE 1.5/30",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "JUNEL FE 1/20",
  value: "JUNEL FE 1/20",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "JUNIOR STRENGTH ADVIL",
  value: "JUNIOR STRENGTH ADVIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "JUNIOR STRENGTH IBUPROFEN",
  value: "JUNIOR STRENGTH IBUPROFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "JUNIOR STRENGTH MOTRIN",
  value: "JUNIOR STRENGTH MOTRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Juvisync",
  value: "Juvisync",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "K+10",
  value: "K+10",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "K+8",
  value: "K+8",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Kadian",
  value: "Kadian",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KALETRA",
  value: "KALETRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KAON CL",
  value: "KAON CL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KAON CL-10",
  value: "KAON CL-10",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KAPVAY",
  value: "KAPVAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KARIVA",
  value: "KARIVA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KEFLET",
  value: "KEFLET",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KEFLEX",
  value: "KEFLEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KELNOR",
  value: "KELNOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KEMSTRO",
  value: "KEMSTRO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Kengreal",
  value: "Kengreal",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KEPPRA",
  value: "KEPPRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KEPPRA XR",
  value: "KEPPRA XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ketamine",
  value: "Ketamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KETEK",
  value: "KETEK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ketoconazole",
  value: "Ketoconazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KETOCONAZOLE--CREAM",
  value: "KETOCONAZOLE--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KETOCONAZOLE--SHAMPOO",
  value: "KETOCONAZOLE--SHAMPOO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ketoprofen",
  value: "Ketoprofen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KETOZOLE",
  value: "KETOZOLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KHEDEZLA",
  value: "KHEDEZLA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KINERET",
  value: "KINERET",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "K-LEASE",
  value: "K-LEASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KLONOPIN",
  value: "KLONOPIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KLONOPIN RAPIDLY DISINTEGRATING",
  value: "KLONOPIN RAPIDLY DISINTEGRATING",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KLOR-CON",
  value: "KLOR-CON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KLOR-CON M10",
  value: "KLOR-CON M10",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KLOR-CON M15",
  value: "KLOR-CON M15",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KLOR-CON M20",
  value: "KLOR-CON M20",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KLOROMIN",
  value: "KLOROMIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KLOTRIX",
  value: "KLOTRIX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KORLYM",
  value: "KORLYM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "K-PHOS NEUTRAL",
  value: "K-PHOS NEUTRAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KRISTALOSE",
  value: "KRISTALOSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "K-TAB",
  value: "K-TAB",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KURIC",
  value: "KURIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "KURVELO",
  value: "KURVELO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Kybella",
  value: "Kybella",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LAAM",
  value: "LAAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Labetalol",
  value: "Labetalol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LABID",
  value: "LABID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LACOSAMIDE",
  value: "LACOSAMIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lactulose",
  value: "Lactulose",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LAMICTAL",
  value: "LAMICTAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LAMICTAL CD",
  value: "LAMICTAL CD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LAMICTAL ODT",
  value: "LAMICTAL ODT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LAMICTAL XR",
  value: "LAMICTAL XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LAMISIL",
  value: "LAMISIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LAMISIL AT",
  value: "LAMISIL AT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lamivudine",
  value: "Lamivudine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lamotrigine",
  value: "Lamotrigine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LANABIOTIC",
  value: "LANABIOTIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LANIAZID",
  value: "LANIAZID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LANOPHYLLIN",
  value: "LANOPHYLLIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LANOXICAPS",
  value: "LANOXICAPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LANOXIN",
  value: "LANOXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LANOXIN PEDIATRIC",
  value: "LANOXIN PEDIATRIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LANSOPRAZOLE",
  value: "LANSOPRAZOLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LANSOPRAZOLE, AMOXICILLIN AND CLARITHROMYCIN",
  value: '"LANSOPRAZOLE, AMOXICILLIN AND CLARITHROMYCIN"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lantus",
  value: "Lantus",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Largactil",
  value: "Largactil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LARIN FE 1.5/30",
  value: "LARIN FE 1.5/30",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LARIN FE 1/20",
  value: "LARIN FE 1/20",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LAROTID",
  value: "LAROTID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LARYNG-O-JET KIT",
  value: "LARYNG-O-JET KIT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LARYNGOTRACHEAL ANESTHESIA KIT",
  value: "LARYNGOTRACHEAL ANESTHESIA KIT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LASIX",
  value: "LASIX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LATANOPROST--EYE DROPS",
  value: "LATANOPROST--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LATUDA",
  value: "LATUDA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LAXILOSE",
  value: "LAXILOSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LAZANDA",
  value: "LAZANDA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Leflunomide",
  value: "Leflunomide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lenalidomide",
  value: "Lenalidomide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "lenvatinib",
  value: "lenvatinib",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lenvima",
  value: "Lenvima",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "lercanidipine",
  value: "lercanidipine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LESCOL",
  value: "LESCOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LESCOL XL",
  value: "LESCOL XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LESSINA-21",
  value: "LESSINA-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LESSINA-28",
  value: "LESSINA-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Letrozole",
  value: "Letrozole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Leucovorin",
  value: "Leucovorin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVALBUTEROL",
  value: "LEVALBUTEROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVAQUIN",
  value: "LEVAQUIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVAQUIN IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "LEVAQUIN IN DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Levemir",
  value: "Levemir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVETIRACETAM",
  value: "LEVETIRACETAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVETIRACETAM IN SODIUM CHLORIDE",
  value: "LEVETIRACETAM IN SODIUM CHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVLITE",
  value: "LEVLITE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVOCARNITINE",
  value: "LEVOCARNITINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Levocetirizine",
  value: "Levocetirizine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "levodopa",
  value: "levodopa",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "levofloxacin",
  value: "levofloxacin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVOFLOXACIN IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "LEVOFLOXACIN IN DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "levomepromazine",
  value: "levomepromazine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "levomilnacipran",
  value: "levomilnacipran",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVONEST",
  value: "LEVONEST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVONORGESTREL AND ETHINYL ESTRADIOL",
  value: "LEVONORGESTREL AND ETHINYL ESTRADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVONORGESTREL AND ETHINYL ESTRADIOL AND ETHINYL ESTRADIOL",
  value: "LEVONORGESTREL AND ETHINYL ESTRADIOL AND ETHINYL ESTRADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVORA 0.15/30-21",
  value: "LEVORA 0.15/30-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LEVORA 0.15/30-28",
  value: "LEVORA 0.15/30-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Levothyroxine",
  value: "Levothyroxine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lexapro",
  value: "Lexapro",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lexiva",
  value: "Lexiva",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lexxel",
  value: "Lexxel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIALDA",
  value: "LIALDA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIBRELEASE",
  value: "LIBRELEASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIBRITABS",
  value: "LIBRITABS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIBRIUM",
  value: "LIBRIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LidaMantle",
  value: "LidaMantle",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LidaMantle--cream",
  value: "LidaMantle--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "lidocaine",
  value: "lidocaine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "lidocaine and hydrocortisone",
  value: "lidocaine and hydrocortisone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE AND PRILOCAINE",
  value: "LIDOCAINE AND PRILOCAINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "lidocaine and tetracaine--patch",
  value: "lidocaine and tetracaine--patch",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE",
  value: "LIDOCAINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE 0.1% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "LIDOCAINE HYDROCHLORIDE 0.1% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE 0.2% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "LIDOCAINE HYDROCHLORIDE 0.2% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE 0.2% IN DEXTROSE 5%",
  value: "LIDOCAINE HYDROCHLORIDE 0.2% IN DEXTROSE 5%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE 0.2% IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "LIDOCAINE HYDROCHLORIDE 0.2% IN DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE 0.4% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "LIDOCAINE HYDROCHLORIDE 0.4% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE 0.4% IN DEXTROSE 5%",
  value: "LIDOCAINE HYDROCHLORIDE 0.4% IN DEXTROSE 5%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE 0.4% IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "LIDOCAINE HYDROCHLORIDE 0.4% IN DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE 0.8% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "LIDOCAINE HYDROCHLORIDE 0.8% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE 0.8% IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "LIDOCAINE HYDROCHLORIDE 0.8% IN DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE 5% AND DEXTROSE 7.5%",
  value: "LIDOCAINE HYDROCHLORIDE 5% AND DEXTROSE 7.5%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE IN PLASTIC CONTAINER",
  value: "LIDOCAINE HYDROCHLORIDE IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE PRESERVATIVE FREE",
  value: "LIDOCAINE HYDROCHLORIDE PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE PRESERVATIVE FREE IN PLASTIC CONTAINER",
  value: "LIDOCAINE HYDROCHLORIDE PRESERVATIVE FREE IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE VISCOUS",
  value: "LIDOCAINE HYDROCHLORIDE VISCOUS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE HYDROCHLORIDE W/ EPINEPHRINE",
  value: "LIDOCAINE HYDROCHLORIDE W/ EPINEPHRINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE VISCOUS",
  value: "LIDOCAINE VISCOUS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE--JELLY",
  value: "LIDOCAINE--JELLY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE--OINTMENT",
  value: "LIDOCAINE--OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOCAINE--PATCH",
  value: "LIDOCAINE--PATCH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDODERM",
  value: "LIDODERM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDODERM--PATCH",
  value: "LIDODERM--PATCH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOPEN",
  value: "LIDOPEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIDOSITE TOPICAL SYSTEM KIT",
  value: "LIDOSITE TOPICAL SYSTEM KIT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIGNOSPAN FORTE",
  value: "LIGNOSPAN FORTE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIGNOSPAN STANDARD",
  value: "LIGNOSPAN STANDARD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Limbitrol",
  value: "Limbitrol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIMBITROL DS",
  value: "LIMBITROL DS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Linagliptin",
  value: "Linagliptin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "linezolid",
  value: "linezolid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIORESAL",
  value: "LIORESAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Liothyronine",
  value: "Liothyronine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIPIDIL",
  value: "LIPIDIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lipidil, Trilipix",
  value: '"Lipidil, Trilipix"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lipitor",
  value: "Lipitor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIPOFEN",
  value: "LIPOFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIPO-HEPIN",
  value: "LIPO-HEPIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Liptruzet",
  value: "Liptruzet",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIQUAEMIN LOCK FLUSH",
  value: "LIQUAEMIN LOCK FLUSH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIQUAEMIN SODIUM",
  value: "LIQUAEMIN SODIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIQUAEMIN SODIUM PRESERVATIVE FREE",
  value: "LIQUAEMIN SODIUM PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LIQUID PRED",
  value: "LIQUID PRED",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lisdexamfetamine",
  value: "Lisdexamfetamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lisinopril",
  value: "Lisinopril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LISINOPRIL and HYDROCHLOROTHIAZIDE",
  value: "LISINOPRIL and HYDROCHLOROTHIAZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LISINOPRIL-HCTZ",
  value: "LISINOPRIL-HCTZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LITHANE",
  value: "LITHANE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lithium",
  value: "Lithium",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lithium Carbonate",
  value: "Lithium Carbonate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lithium ER",
  value: "Lithium ER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LITHOBID",
  value: "LITHOBID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LITHONATE",
  value: "LITHONATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LITHOTABS",
  value: "LITHOTABS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Livalo",
  value: "Livalo",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LO LOESTRIN FE",
  value: "LO LOESTRIN FE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LO MINASTRIN FE",
  value: "LO MINASTRIN FE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LO/OVRAL",
  value: "LO/OVRAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LO/OVRAL-28",
  value: "LO/OVRAL-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOCHOLEST",
  value: "LOCHOLEST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOCHOLEST LIGHT",
  value: "LOCHOLEST LIGHT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOCOID",
  value: "LOCOID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOCOID LIPOCREAM",
  value: "LOCOID LIPOCREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOESTRIN 21 1.5/30",
  value: "LOESTRIN 21 1.5/30",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOESTRIN 21 1/20",
  value: "LOESTRIN 21 1/20",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOESTRIN 24 FE",
  value: "LOESTRIN 24 FE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOESTRIN FE 1.5/30",
  value: "LOESTRIN FE 1.5/30",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOESTRIN FE 1/20",
  value: "LOESTRIN FE 1/20",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOFTAN",
  value: "LOFTAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lomotil",
  value: "Lomotil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lomoxicam",
  value: "Lomoxicam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LONITEN",
  value: "LONITEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lonsurf",
  value: "Lonsurf",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lonsys",
  value: "Lonsys",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Loperamide",
  value: "Loperamide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOPERAMIDE HYDROCHLORIDE",
  value: "LOPERAMIDE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOPERAMIDE HYDROCHLORIDE AND SIMETHICONE",
  value: "LOPERAMIDE HYDROCHLORIDE AND SIMETHICONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOPID",
  value: "LOPID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lopinavir",
  value: "Lopinavir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "lopinavir and ritonavir",
  value: "lopinavir and ritonavir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "lopressor",
  value: "lopressor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOPURIN",
  value: "LOPURIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Loratadine",
  value: "Loratadine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LORATADINE AND PSEUDOEPHEDRINE SULFATE",
  value: "LORATADINE AND PSEUDOEPHEDRINE SULFATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LORATADINE REDIDOSE",
  value: "LORATADINE REDIDOSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LORAZ",
  value: "LORAZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lorazepam",
  value: "Lorazepam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LORAZEPAM INTENSOL",
  value: "LORAZEPAM INTENSOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LORAZEPAM PRESERVATIVE FREE",
  value: "LORAZEPAM PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "lornoxicam",
  value: "lornoxicam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lortab",
  value: "Lortab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LORYNA",
  value: "LORYNA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "losartan",
  value: "losartan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOSARTAN POTASSIUM",
  value: "LOSARTAN POTASSIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOSARTAN POTASSIUM AND HYDROCHLOROTHIAZIDE",
  value: "LOSARTAN POTASSIUM AND HYDROCHLOROTHIAZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOSEASONIQUE",
  value: "LOSEASONIQUE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lotensin",
  value: "Lotensin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lotrel",
  value: "Lotrel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOTRIMIN",
  value: "LOTRIMIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOTRIMIN AF",
  value: "LOTRIMIN AF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "lovastatin",
  value: "lovastatin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOVAZA",
  value: "LOVAZA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "lovenox",
  value: "lovenox",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOW-OGESTREL-21",
  value: "LOW-OGESTREL-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOW-OGESTREL-28",
  value: "LOW-OGESTREL-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Loxapine",
  value: "Loxapine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOXAPINE SUCCINATE",
  value: "LOXAPINE SUCCINATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOXITANE",
  value: "LOXITANE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOXITANE C",
  value: "LOXITANE C",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LOXITANE IM",
  value: "LOXITANE IM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LTA II KIT",
  value: "LTA II KIT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "lubiprostone",
  value: "lubiprostone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LUDIOMIL",
  value: "LUDIOMIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lumigan",
  value: "Lumigan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LUMIGAN--EYE DROPS",
  value: "LUMIGAN--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Luminal",
  value: "Luminal",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LUNELLE",
  value: "LUNELLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LUNESTA",
  value: "LUNESTA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LUPANETA PACK",
  value: "LUPANETA PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "lurasidone",
  value: "lurasidone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lutein",
  value: "Lutein",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LUVOX",
  value: "LUVOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LUVOX CR",
  value: "LUVOX CR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Luvox Tablets",
  value: "Luvox Tablets",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LYBREL",
  value: "LYBREL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LYGEN",
  value: "LYGEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LYNORAL",
  value: "LYNORAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LYOPHILIZED CYTOXAN",
  value: "LYOPHILIZED CYTOXAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "LYRICA",
  value: "LYRICA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Lysine",
  value: "Lysine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MACROBID",
  value: "MACROBID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MACRODANTIN",
  value: "MACRODANTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Magnesium Hydroxide",
  value: "Magnesium Hydroxide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MAGNESIUM HYDROXIDE AND OMEPRAZOLE AND SODIUM BICARBONATE",
  value: "MAGNESIUM HYDROXIDE AND OMEPRAZOLE AND SODIUM BICARBONATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MAGNESIUM-OXIDE",
  value: "MAGNESIUM-OXIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MALARONE",
  value: "MALARONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mapap Arthritis Pain",
  value: "Mapap Arthritis Pain",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mapap Multi-Symptom Cold",
  value: "Mapap Multi-Symptom Cold",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mapap PM",
  value: "Mapap PM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mapap Sinus PE",
  value: "Mapap Sinus PE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Maprotiline",
  value: "Maprotiline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MAPROTILINE HYDROCHLORIDE",
  value: "MAPROTILINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "maraviroc",
  value: "maraviroc",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "marijuana",
  value: "marijuana",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MARINOL",
  value: "MARINOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MARLISSA",
  value: "MARLISSA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MARQIBO KIT",
  value: "MARQIBO KIT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mavik",
  value: "Mavik",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Maxalt",
  value: "Maxalt",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MAXIDEX",
  value: "MAXIDEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MAXITROL",
  value: "MAXITROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MAXOLON",
  value: "MAXOLON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Maxzide",
  value: "Maxzide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MAXZIDE-25",
  value: "MAXZIDE-25",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MECLIZINE",
  value: "MECLIZINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MECLIZINE HYDROCHLORIDE",
  value: "MECLIZINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MEDIPREN",
  value: "MEDIPREN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MEDROL",
  value: "MEDROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Medroxyprogesterone",
  value: "Medroxyprogesterone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mefenamic acid",
  value: "Mefenamic acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mefloquine",
  value: "Mefloquine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MEGACE",
  value: "MEGACE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MEGACE ES",
  value: "MEGACE ES",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Megaphen",
  value: "Megaphen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MEGESTROL",
  value: "MEGESTROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MEGESTROL ACETATE",
  value: "MEGESTROL ACETATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Melatonin",
  value: "Melatonin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mellaril",
  value: "Mellaril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MELLARIL-S",
  value: "MELLARIL-S",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "meloxicam",
  value: "meloxicam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Memantine",
  value: "Memantine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Memantine HCL",
  value: "Memantine HCL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MENOSTAR",
  value: "MENOSTAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MENRIUM 10-4",
  value: "MENRIUM 10-4",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MENRIUM 5-2",
  value: "MENRIUM 5-2",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MENRIUM 5-4",
  value: "MENRIUM 5-4",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MEN'S ROGAINE",
  value: "MEN'S ROGAINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MEPERGAN",
  value: "MEPERGAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Meperidine",
  value: "Meperidine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MEPERIDINE AND ATROPINE SULFATE",
  value: "MEPERIDINE AND ATROPINE SULFATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MEPERIDINE HYDROCHLORIDE",
  value: "MEPERIDINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MEPERIDINE HYDROCHLORIDE PRESERVATIVE FREE",
  value: "MEPERIDINE HYDROCHLORIDE PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mephenytoin",
  value: "Mephenytoin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mephobarbital",
  value: "Mephobarbital",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mercaptopurine",
  value: "Mercaptopurine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "mesalamine",
  value: "mesalamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METADATE CD",
  value: "METADATE CD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METADATE ER",
  value: "METADATE ER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METAGLIP",
  value: "METAGLIP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METANX",
  value: "METANX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METATENSIN #2",
  value: "METATENSIN #2",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METATENSIN #4",
  value: "METATENSIN #4",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METAXALONE",
  value: "METAXALONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Metformin",
  value: "Metformin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "methadone",
  value: "methadone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METHADONE HYDROCHLORIDE",
  value: "METHADONE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METHADONE HYDROCHLORIDE INTENSOL",
  value: "METHADONE HYDROCHLORIDE INTENSOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METHADOSE",
  value: "METHADOSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Methamphetamine",
  value: "Methamphetamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METHENAMINE",
  value: "METHENAMINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METHENAMINE Hippurate",
  value: "METHENAMINE Hippurate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METHENAMINE MANDELATE",
  value: "METHENAMINE MANDELATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METHIMAZOLE",
  value: "METHIMAZOLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METHOCARBAMOL",
  value: "METHOCARBAMOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METHOCARBAMOL AND ASPIRIN",
  value: "METHOCARBAMOL AND ASPIRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Methotrexate",
  value: "Methotrexate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "methoxsalen",
  value: "methoxsalen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "methoxyamphetamine",
  value: "methoxyamphetamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Methscopolamine",
  value: "Methscopolamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "methsuximide",
  value: "methsuximide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METHYLIN",
  value: "METHYLIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METHYLIN ER",
  value: "METHYLIN ER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "methylphenidate",
  value: "methylphenidate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METHYLPHENIDATE HYDROCHLORIDE",
  value: "METHYLPHENIDATE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Methylprednisolone",
  value: "Methylprednisolone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "methylsulfonylmethane",
  value: "methylsulfonylmethane",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Methyltestosterone",
  value: "Methyltestosterone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METICORTEN",
  value: "METICORTEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METI-DERM",
  value: "METI-DERM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METIPRANOLOL--EYE DROPS",
  value: "METIPRANOLOL--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "metoclopramide",
  value: "metoclopramide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METOCLOPRAMIDE HCL",
  value: "METOCLOPRAMIDE HCL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METOCLOPRAMIDE HYDROCHLORIDE",
  value: "METOCLOPRAMIDE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METOCLOPRAMIDE INTENSOL",
  value: "METOCLOPRAMIDE INTENSOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METOLAZONE",
  value: "METOLAZONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Metoprolol",
  value: "Metoprolol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METOZOLV ODT",
  value: "METOZOLV ODT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METRO I.V.",
  value: "METRO I.V.",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METRO I.V. IN PLASTIC CONTAINER",
  value: "METRO I.V. IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METROCREAM",
  value: "METROCREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METROGEL",
  value: "METROGEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METROGEL-VAGINAL",
  value: "METROGEL-VAGINAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METROLOTION",
  value: "METROLOTION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METROMIDOL",
  value: "METROMIDOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Metronidazole",
  value: "Metronidazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "METRONIDAZOLE IN PLASTIC CONTAINER",
  value: "METRONIDAZOLE IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MEVACOR",
  value: "MEVACOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mexiletine",
  value: "Mexiletine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mexitil",
  value: "Mexitil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MI acid suspension",
  value: "MI acid suspension",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "mibefradil",
  value: "mibefradil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICARDIS",
  value: "MICARDIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICONAZOLE",
  value: "MICONAZOLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICONAZOLE 3",
  value: "MICONAZOLE 3",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICONAZOLE 3 COMBINATION PACK",
  value: "MICONAZOLE 3 COMBINATION PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICONAZOLE 7",
  value: "MICONAZOLE 7",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICONAZOLE 7 COMBINATION PACK",
  value: "MICONAZOLE 7 COMBINATION PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICONAZOLE-- CREAM",
  value: "MICONAZOLE-- CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICONAZOLE NITRATE",
  value: "MICONAZOLE NITRATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICONAZOLE NITRATE COMBINATION PACK",
  value: "MICONAZOLE NITRATE COMBINATION PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICONAZOLE --SPRAY POWDER",
  value: "MICONAZOLE --SPRAY POWDER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICORT-HC",
  value: "MICORT-HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICROGESTIN 1.5/30",
  value: "MICROGESTIN 1.5/30",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICROGESTIN 1/20",
  value: "MICROGESTIN 1/20",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICROGESTIN FE 1.5/30",
  value: "MICROGESTIN FE 1.5/30",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICROGESTIN FE 1/20",
  value: "MICROGESTIN FE 1/20",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICRO-K",
  value: "MICRO-K",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICRO-K 10",
  value: "MICRO-K 10",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICRO-K LS",
  value: "MICRO-K LS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICRONASE",
  value: "MICRONASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICRONOR",
  value: "MICRONOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MICROZIDE",
  value: "MICROZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Midamor",
  value: "Midamor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "midazolam",
  value: "midazolam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MIDAZOLAM HYDROCHLORIDE",
  value: "MIDAZOLAM HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MIDAZOLAM HYDROCHLORIDE PRESERVATIVE FREE",
  value: "MIDAZOLAM HYDROCHLORIDE PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "midodrine",
  value: "midodrine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MIDODRINE HYDROCHLORIDE",
  value: "MIDODRINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MIDOL",
  value: "MIDOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MIDOL LIQUID GELS",
  value: "MIDOL LIQUID GELS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MIDOZALAM",
  value: "MIDOZALAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MIDOZALAM HYDROCHLORIDE",
  value: "MIDOZALAM HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MIFEPREX",
  value: "MIFEPREX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "mifepristone",
  value: "mifepristone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Migergot",
  value: "Migergot",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MILK OF MAGNESIA",
  value: "MILK OF MAGNESIA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "milnacipran",
  value: "milnacipran",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "minaprine",
  value: "minaprine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MINERIN--CREAM",
  value: "MINERIN--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MINIPRESS",
  value: "MINIPRESS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MINIPRESS XL",
  value: "MINIPRESS XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MINITRAN",
  value: "MINITRAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MINIVELLE",
  value: "MINIVELLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MINIZIDE",
  value: "MINIZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Minocycline",
  value: "Minocycline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MINODYL",
  value: "MINODYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MINOXIDIL",
  value: "MINOXIDIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MINOXIDIL (FOR MEN)",
  value: "MINOXIDIL (FOR MEN)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MINOXIDIL (FOR WOMEN)",
  value: "MINOXIDIL (FOR WOMEN)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MINOXIDIL EXTRA STRENGTH (FOR MEN)",
  value: "MINOXIDIL EXTRA STRENGTH (FOR MEN)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mirabegron",
  value: "Mirabegron",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MIRCETTE",
  value: "MIRCETTE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mirtazapine",
  value: "Mirtazapine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MISOPROSTOL",
  value: "MISOPROSTOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MITIGARE",
  value: "MITIGARE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mitoxantrone",
  value: "Mitoxantrone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MOBIC",
  value: "MOBIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "moclobemide",
  value: "moclobemide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "modafinil",
  value: "modafinil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MODICON 21",
  value: "MODICON 21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MODICON 28",
  value: "MODICON 28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Moexipril",
  value: "Moexipril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mometasone",
  value: "Mometasone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONISTAT",
  value: "MONISTAT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONISTAT 1 COMBINATION PACK",
  value: "MONISTAT 1 COMBINATION PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONISTAT 3",
  value: "MONISTAT 3",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONISTAT 3 COMBINATION PACK",
  value: "MONISTAT 3 COMBINATION PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONISTAT 3 COMBINATION PACK (PREFILLED)",
  value: "MONISTAT 3 COMBINATION PACK (PREFILLED)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONISTAT 5",
  value: "MONISTAT 5",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONISTAT 7",
  value: "MONISTAT 7",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONISTAT 7 COMBINATION PACK",
  value: "MONISTAT 7 COMBINATION PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONISTAT DUAL- PAK",
  value: "MONISTAT DUAL- PAK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONISTAT-3 COMBINATION PACK",
  value: "MONISTAT-3 COMBINATION PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONISTAT-DERM",
  value: "MONISTAT-DERM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONODOX",
  value: "MONODOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONOKET",
  value: "MONOKET",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONO-LINYAH",
  value: "MONO-LINYAH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Monopril",
  value: "Monopril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Montelukast",
  value: "Montelukast",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONTELUKAST SOD",
  value: "MONTELUKAST SOD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MONTELUKAST SODIUM",
  value: "MONTELUKAST SODIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "morphine",
  value: "morphine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MOTRIN",
  value: "MOTRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MOTRIN IB",
  value: "MOTRIN IB",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MOTRIN MIGRAINE PAIN",
  value: "MOTRIN MIGRAINE PAIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MOXATAG",
  value: "MOXATAG",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MS Contin",
  value: "MS Contin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MUCINEX",
  value: "MUCINEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MUCOMYST",
  value: "MUCOMYST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MUCOSIL-10",
  value: "MUCOSIL-10",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MUCOSIL-20",
  value: "MUCOSIL-20",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MULTAQ",
  value: "MULTAQ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "multivitamins",
  value: "multivitamins",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MUPIROCIN--CREAM",
  value: "MUPIROCIN--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MUPIROCIN--OINTMENT",
  value: "MUPIROCIN--OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MYCELEX",
  value: "MYCELEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MYCELEX-7",
  value: "MYCELEX-7",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MYCELEX-7 COMBINATION PACK",
  value: "MYCELEX-7 COMBINATION PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MYCELEX-G",
  value: "MYCELEX-G",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MYCHEL",
  value: "MYCHEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MYCHEL, OPHTHOCHLOR, OPTOMYCIN",
  value: '"MYCHEL, OPHTHOCHLOR, OPTOMYCIN"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MYCHEL-S",
  value: "MYCHEL-S",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MYCOBUTIN",
  value: "MYCOBUTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mycophenolate",
  value: "Mycophenolate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MYKROX",
  value: "MYKROX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Mylanta",
  value: "Mylanta",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MYMETHAZINE FORTIS",
  value: "MYMETHAZINE FORTIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Myrbetriq",
  value: "Myrbetriq",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MYSOLINE",
  value: "MYSOLINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "MYZILRA",
  value: "MYZILRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "M-ZOLE 3 COMBINATION PACK",
  value: "M-ZOLE 3 COMBINATION PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "M-ZOLE 7 DUAL PACK",
  value: "M-ZOLE 7 DUAL PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "N.E.E. 1/35 21",
  value: "N.E.E. 1/35 21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "N.E.E. 1/35 28",
  value: "N.E.E. 1/35 28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nabumetone",
  value: "Nabumetone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nadolol",
  value: "Nadolol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "nafcillin",
  value: "nafcillin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NAFCILLIN INJECTION",
  value: "NAFCILLIN INJECTION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NAFCILLIN SODIUM",
  value: "NAFCILLIN SODIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NALFON",
  value: "NALFON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nalidixic acid",
  value: "Nalidixic acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NALLPEN",
  value: "NALLPEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NALLPEN IN PLASTIC CONTAINER",
  value: "NALLPEN IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nalorphine",
  value: "Nalorphine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Naloxone",
  value: "Naloxone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NALOXONE HYDROCHLORIDE AND PENTAZOCINE HYDROCHLORIDE",
  value: "NALOXONE HYDROCHLORIDE AND PENTAZOCINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Naltrexone",
  value: "Naltrexone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NAMENDA",
  value: "NAMENDA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Naphthol",
  value: "Naphthol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NAPRELAN",
  value: "NAPRELAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NAPROSYN",
  value: "NAPROSYN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Naproxen",
  value: "Naproxen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NAPROXEN AND ESOMEPRAZOLE MAGNESIUM",
  value: "NAPROXEN AND ESOMEPRAZOLE MAGNESIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NAPROXEN SODIUM",
  value: "NAPROXEN SODIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NAPROXEN SODIUM AND PSEUDOEPHEDRINE HYDROCHLORIDE",
  value: "NAPROXEN SODIUM AND PSEUDOEPHEDRINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NAQUIVAL",
  value: "NAQUIVAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "naratriptan",
  value: "naratriptan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NAROPIN",
  value: "NAROPIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NASCOBAL",
  value: "NASCOBAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NATAZIA",
  value: "NATAZIA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "nateglinide",
  value: "nateglinide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NATESTO",
  value: "NATESTO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Natpara",
  value: "Natpara",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NATURAL ESTROGENIC SUBSTANCE-ESTRONE",
  value: "NATURAL ESTROGENIC SUBSTANCE-ESTRONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "nebivolol",
  value: "nebivolol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "nefazodone",
  value: "nefazodone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEFAZODONE HYDROCHLORIDE",
  value: "NEFAZODONE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "nelfinavir",
  value: "nelfinavir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEMBUTAL",
  value: "NEMBUTAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEO-CORT-DOME",
  value: "NEO-CORT-DOME",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEO-CORTEF",
  value: "NEO-CORTEF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEODECADRON",
  value: "NEODECADRON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEOMYCIN & POLYMYXIN B SULFATES & BACITRACIN ZINC & HYDROCORTISONE",
  value: "NEOMYCIN & POLYMYXIN B SULFATES & BACITRACIN ZINC & HYDROCORTISONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEOMYCIN AND POLYMYXIN B SULFATES AND DEXAMETHASONE",
  value: "NEOMYCIN AND POLYMYXIN B SULFATES AND DEXAMETHASONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEOMYCIN AND POLYMYXIN B SULFATES AND HYDROCORTISONE",
  value: "NEOMYCIN AND POLYMYXIN B SULFATES AND HYDROCORTISONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "NEOMYCIN AND POLYMYXIN B SULFATES, BACITRACIN ZINC AND HYDROCORTISONE",
  value:
   '"NEOMYCIN AND POLYMYXIN B SULFATES, BACITRACIN ZINC AND HYDROCORTISONE"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEOMYCIN SULFATE AND DEXAMETHASONE SODIUM PHOSPHATE",
  value: "NEOMYCIN SULFATE AND DEXAMETHASONE SODIUM PHOSPHATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEOMYCIN SULFATE, POLYMYXIN B SULFATE & HYDROCORTISONE",
  value: '"NEOMYCIN SULFATE, POLYMYXIN B SULFATE & HYDROCORTISONE"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEOMYCIN SULFATE-DEXAMETHASONE SODIUM PHOSPHATE",
  value: "NEOMYCIN SULFATE-DEXAMETHASONE SODIUM PHOSPHATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEOMYCIN SULFATE-POLYMYXIN B SULFATE-HYDROCORTISONE",
  value: "NEOMYCIN SULFATE-POLYMYXIN B SULFATE-HYDROCORTISONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEOMYCIN-POLYMYXIN-DEXAMETHASONE--OINTMENT",
  value: "NEOMYCIN-POLYMYXIN-DEXAMETHASONE--OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEOPAP",
  value: "NEOPAP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEOPROFEN",
  value: "NEOPROFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEORAL",
  value: "NEORAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEOSAR",
  value: "NEOSAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEUPRO",
  value: "NEUPRO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEUPRO--PATCH",
  value: "NEUPRO--PATCH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEURONTIN",
  value: "NEURONTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nevanac--eye drops",
  value: "Nevanac--eye drops",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "nevirapine",
  value: "nevirapine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEVIRAPINE TABLETS FOR ORAL SUSPENSION",
  value: "NEVIRAPINE TABLETS FOR ORAL SUSPENSION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEXCEDE",
  value: "NEXCEDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nexium",
  value: "Nexium",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEXIUM 24HR",
  value: "NEXIUM 24HR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEXIUM IV",
  value: "NEXIUM IV",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NEXPLANON",
  value: "NEXPLANON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nexterone",
  value: "Nexterone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Niacin",
  value: "Niacin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NIACOR",
  value: "NIACOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NIASPAN",
  value: "NIASPAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NIASPAN TITRATION STARTER PACK",
  value: "NIASPAN TITRATION STARTER PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nicardipine",
  value: "Nicardipine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NICODERM CQ",
  value: "NICODERM CQ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NICOLAR",
  value: "NICOLAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nicotine",
  value: "Nicotine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NICOTINE--PATCH",
  value: "NICOTINE--PATCH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NICOTROL",
  value: "NICOTROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nifedipine",
  value: "Nifedipine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NILANDRON",
  value: "NILANDRON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "nilutamide",
  value: "nilutamide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nimodipine",
  value: "Nimodipine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NIMOTOP",
  value: "NIMOTOP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NIRAVAM",
  value: "NIRAVAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "nisoldipine",
  value: "nisoldipine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "nitrendipine",
  value: "nitrendipine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITRO IV",
  value: "NITRO IV",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITRO-BID",
  value: "NITRO-BID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITRO-DUR",
  value: "NITRO-DUR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITROFURANTOIN",
  value: "NITROFURANTOIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITROFURANTOIN (MONOHYDRATE/MACROCRYSTALS)",
  value: "NITROFURANTOIN (MONOHYDRATE/MACROCRYSTALS)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITROFURANTOIN MACROCRYSTALLINE",
  value: "NITROFURANTOIN MACROCRYSTALLINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "nitroglycerin",
  value: "nitroglycerin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITROGLYCERIN IN DEXTROSE 5%",
  value: "NITROGLYCERIN IN DEXTROSE 5%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITROGLYCERIN--PATCH",
  value: "NITROGLYCERIN--PATCH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITROL",
  value: "NITROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITROLINGUAL",
  value: "NITROLINGUAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITROLINGUAL PUMPSPRAY",
  value: "NITROLINGUAL PUMPSPRAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITROMIST",
  value: "NITROMIST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITRONAL",
  value: "NITRONAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NITROSTAT",
  value: "NITROSTAT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "nivolumab",
  value: "nivolumab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nizatidine",
  value: "Nizatidine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NIZORAL",
  value: "NIZORAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NIZORAL A-D",
  value: "NIZORAL A-D",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NOGENIC HC",
  value: "NOGENIC HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NOLVADEX",
  value: "NOLVADEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORCEPT-E 1/35 21",
  value: "NORCEPT-E 1/35 21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORCEPT-E 1/35 28",
  value: "NORCEPT-E 1/35 28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Norco",
  value: "Norco",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORDETTE-21",
  value: "NORDETTE-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORDETTE-28",
  value: "NORDETTE-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NOR-DQ",
  value: "NOR-DQ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHIDRONE",
  value: "NORETHIDRONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHIDRONE ACETATE",
  value: "NORETHIDRONE ACETATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHIN 1/35E-21",
  value: "NORETHIN 1/35E-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHIN 1/35E-28",
  value: "NORETHIN 1/35E-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHIN 1/50M-21",
  value: "NORETHIN 1/50M-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHIN 1/50M-28",
  value: "NORETHIN 1/50M-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "norethindrone",
  value: "norethindrone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHINDRONE ACETATE",
  value: "NORETHINDRONE ACETATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHINDRONE ACETATE AND ETHINYL ESTRADIOL",
  value: "NORETHINDRONE ACETATE AND ETHINYL ESTRADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHINDRONE ACETATE AND ETHINYL ESTRADIOL AND FERROUS FUMARATE",
  value: "NORETHINDRONE ACETATE AND ETHINYL ESTRADIOL AND FERROUS FUMARATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHINDRONE ACETATE; ETHINYL ESTRADIOL",
  value: "NORETHINDRONE ACETATE; ETHINYL ESTRADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHINDRONE AND ETHINYL ESTRADIOL",
  value: "NORETHINDRONE AND ETHINYL ESTRADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHINDRONE AND ETHINYL ESTRADIOL (10/11)",
  value: "NORETHINDRONE AND ETHINYL ESTRADIOL (10/11)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHINDRONE AND ETHINYL ESTRADIOL (7/14)",
  value: "NORETHINDRONE AND ETHINYL ESTRADIOL (7/14)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHINDRONE AND ETHINYL ESTRADIOL AND FERROUS FUMARATE",
  value: "NORETHINDRONE AND ETHINYL ESTRADIOL AND FERROUS FUMARATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHINDRONE AND MESTRANOL",
  value: "NORETHINDRONE AND MESTRANOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORETHINDRONE; ETHINYL ESTRADIOL",
  value: "NORETHINDRONE; ETHINYL ESTRADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Norflex",
  value: "Norflex",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Norfloxacin",
  value: "Norfloxacin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORGESTIMATE AND ETHINYL ESTRADIOL",
  value: "NORGESTIMATE AND ETHINYL ESTRADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORGESTIMATE; ETHINYL ESTRADIOL",
  value: "NORGESTIMATE; ETHINYL ESTRADIOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORINYL",
  value: "NORINYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORINYL 1+35 21-DAY",
  value: "NORINYL 1+35 21-DAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORINYL 1+35 28-DAY",
  value: "NORINYL 1+35 28-DAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORINYL 1+50 21-DAY",
  value: "NORINYL 1+50 21-DAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORINYL 1+50 28-DAY",
  value: "NORINYL 1+50 28-DAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORINYL 1+80 21-DAY",
  value: "NORINYL 1+80 21-DAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORINYL 1+80 28-DAY",
  value: "NORINYL 1+80 28-DAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORITATE",
  value: "NORITATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORLESTRIN 21 1/50",
  value: "NORLESTRIN 21 1/50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORLESTRIN 21 2.5/50",
  value: "NORLESTRIN 21 2.5/50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORLESTRIN 28 1/50",
  value: "NORLESTRIN 28 1/50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORLESTRIN FE 1/50",
  value: "NORLESTRIN FE 1/50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORLESTRIN FE 2.5/50",
  value: "NORLESTRIN FE 2.5/50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORLUTATE",
  value: "NORLUTATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORLUTIN",
  value: "NORLUTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Normodyne",
  value: "Normodyne",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NOROXIN",
  value: "NOROXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORPACE",
  value: "NORPACE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORPACE CR",
  value: "NORPACE CR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORPRAMIN",
  value: "NORPRAMIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NOR-QD",
  value: "NOR-QD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORQUEST FE",
  value: "NORQUEST FE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORTREL 0.5/35-21",
  value: "NORTREL 0.5/35-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORTREL 0.5/35-28",
  value: "NORTREL 0.5/35-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORTREL 1/35-21",
  value: "NORTREL 1/35-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORTREL 1/35-28",
  value: "NORTREL 1/35-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORTREL 7/7/7",
  value: "NORTREL 7/7/7",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "nortriptyline",
  value: "nortriptyline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORTRIPTYLINE HYDROCHLORIDE",
  value: "NORTRIPTYLINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Norvasc",
  value: "Norvasc",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NORVIR",
  value: "NORVIR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NOVOLIN",
  value: "NOVOLIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NOVOLIN 70/30",
  value: "NOVOLIN 70/30",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NOVOLIN N ReliOn",
  value: "NOVOLIN N ReliOn",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NOVOLIN R ReliOn",
  value: "NOVOLIN R ReliOn",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Novolog",
  value: "Novolog",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NOXAFIL",
  value: "NOXAFIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nucynta",
  value: "Nucynta",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Nucynta ER",
  value: "Nucynta ER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NUEDEXTA",
  value: "NUEDEXTA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NUMORPHAN",
  value: "NUMORPHAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NUPRIN",
  value: "NUPRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NUTRACORT",
  value: "NUTRACORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NUVARING",
  value: "NUVARING",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NUVIGIL",
  value: "NUVIGIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NYAMYC--POWDER",
  value: "NYAMYC--POWDER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NYDRAZID",
  value: "NYDRAZID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NYMALIZE",
  value: "NYMALIZE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NYSTATIN-- OINTMENT",
  value: "NYSTATIN-- OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NYSTATIN--CREAM",
  value: "NYSTATIN--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NYSTATIN--POWDER",
  value: "NYSTATIN--POWDER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NYSTATIN-TRIAMCINOLONE--CREAM",
  value: "NYSTATIN-TRIAMCINOLONE--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NYSTATIN-TRIAMCINOLONE--OINTMENT",
  value: "NYSTATIN-TRIAMCINOLONE--OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "NYSTOP--POWDER",
  value: "NYSTOP--POWDER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OCTOCAINE",
  value: "OCTOCAINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Octreotide",
  value: "Octreotide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OCUFLOX",
  value: "OCUFLOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OCUSERT PILO-20",
  value: "OCUSERT PILO-20",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OCUSERT PILO-40",
  value: "OCUSERT PILO-40",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OCUVITE LUTEIN AND ZEAXANTHIN",
  value: "OCUVITE LUTEIN AND ZEAXANTHIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Odomzo",
  value: "Odomzo",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ofloxacin",
  value: "Ofloxacin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OFLOXACIN--EYE DROPS",
  value: "OFLOXACIN--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OGESTREL 0.5/50-21",
  value: "OGESTREL 0.5/50-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OGESTREL 0.5/50-28",
  value: "OGESTREL 0.5/50-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "olanzapine",
  value: "olanzapine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OLANZAPINE AND FLUOXETINE HYDROCHLORIDE",
  value: "OLANZAPINE AND FLUOXETINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Oleptro",
  value: "Oleptro",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "olmesartan",
  value: "olmesartan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "olodaterol",
  value: "olodaterol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Olopatadine",
  value: "Olopatadine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ombitasvir",
  value: "ombitasvir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "omega-3-acid ethyl esters",
  value: "omega-3-acid ethyl esters",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OMEPRAZOLE",
  value: "OMEPRAZOLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OMEPRAZOLE AND CLARITHROMYCIN AND AMOXICILLIN",
  value: "OMEPRAZOLE AND CLARITHROMYCIN AND AMOXICILLIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OMEPRAZOLE AND SODIUM BICARBONATE",
  value: "OMEPRAZOLE AND SODIUM BICARBONATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OMEPRAZOLE MAGNESIUM",
  value: "OMEPRAZOLE MAGNESIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OMEPRAZOLE SODIUM BICARBONATE",
  value: "OMEPRAZOLE SODIUM BICARBONATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OMNICEF",
  value: "OMNICEF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OMTRYG",
  value: "OMTRYG",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ONCLAST",
  value: "ONCLAST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ONCOVIN",
  value: "ONCOVIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ondansetron",
  value: "ondansetron",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ONDANSETRON HYDROCHLORIDE",
  value: "ONDANSETRON HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ONDANSETRON HYDROCHLORIDE AND DEXTROSE IN PLASTIC CONTAINER",
  value: "ONDANSETRON HYDROCHLORIDE AND DEXTROSE IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ONDANSETRON HYDROCHLORIDE AND SODIUM CHLORIDE IN PLASTIC CONTAINER",
  value: "ONDANSETRON HYDROCHLORIDE AND SODIUM CHLORIDE IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ONDANSETRON HYDROCHLORIDE PRESERVATIVE FREE",
  value: "ONDANSETRON HYDROCHLORIDE PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Onfi",
  value: "Onfi",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ONMEL",
  value: "ONMEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ONSOLIS",
  value: "ONSOLIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OPANA",
  value: "OPANA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OPANA ER",
  value: "OPANA ER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Opdivo",
  value: "Opdivo",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OPHTHOCHLOR",
  value: "OPHTHOCHLOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OPHTHOCORT",
  value: "OPHTHOCORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OPTI-VITAMINS",
  value: "OPTI-VITAMINS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OPTOMYCIN",
  value: "OPTOMYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORABASE HCA",
  value: "ORABASE HCA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORABASE--PASTE",
  value: "ORABASE--PASTE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORACEA",
  value: "ORACEA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORAP",
  value: "ORAP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORAQIX",
  value: "ORAQIX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORASONE",
  value: "ORASONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORAVIG",
  value: "ORAVIG",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORENCIA",
  value: "ORENCIA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORETIC",
  value: "ORETIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORGANIDIN",
  value: "ORGANIDIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORGATRAX",
  value: "ORGATRAX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORINASE",
  value: "ORINASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORLEX HC",
  value: "ORLEX HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORNADE",
  value: "ORNADE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "orphenadrine",
  value: "orphenadrine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORSYTHIA",
  value: "ORSYTHIA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO CYCLEN-21",
  value: "ORTHO CYCLEN-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO CYCLEN-28",
  value: "ORTHO CYCLEN-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO EVRA",
  value: "ORTHO EVRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO TRI-CYCLEN",
  value: "ORTHO TRI-CYCLEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO TRI-CYCLEN 21",
  value: "ORTHO TRI-CYCLEN 21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO TRI-CYCLEN 28",
  value: "ORTHO TRI-CYCLEN 28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO TRI-CYCLEN LO",
  value: "ORTHO TRI-CYCLEN LO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-CEPT",
  value: "ORTHO-CEPT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 1/35-21",
  value: "ORTHO-NOVUM 1/35-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 1/35-28",
  value: "ORTHO-NOVUM 1/35-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 1/50 21",
  value: "ORTHO-NOVUM 1/50 21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 1/50 28",
  value: "ORTHO-NOVUM 1/50 28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 1/80 21",
  value: "ORTHO-NOVUM 1/80 21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 1/80 28",
  value: "ORTHO-NOVUM 1/80 28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 10/11-21",
  value: "ORTHO-NOVUM 10/11-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 10/11-28",
  value: "ORTHO-NOVUM 10/11-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 10-21",
  value: "ORTHO-NOVUM 10-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 2-21",
  value: "ORTHO-NOVUM 2-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 7/14-21",
  value: "ORTHO-NOVUM 7/14-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 7/14-28",
  value: "ORTHO-NOVUM 7/14-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 7/7/7-21",
  value: "ORTHO-NOVUM 7/7/7-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORTHO-NOVUM 7/7/7-28",
  value: "ORTHO-NOVUM 7/7/7-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORUDIS",
  value: "ORUDIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORUDIS KT",
  value: "ORUDIS KT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORUVAIL",
  value: "ORUVAIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ORVATEN",
  value: "ORVATEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "oseltamivir",
  value: "oseltamivir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OTICAIR",
  value: "OTICAIR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OTOBIONE",
  value: "OTOBIONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OTOBIOTIC",
  value: "OTOBIOTIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OTOCORT",
  value: "OTOCORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OTREXUP",
  value: "OTREXUP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OVCON-35",
  value: "OVCON-35",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OVCON-50",
  value: "OVCON-50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OVRAL",
  value: "OVRAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OVRAL-28",
  value: "OVRAL-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "oxaprozin",
  value: "oxaprozin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Oxazepam",
  value: "Oxazepam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "oxcarbazepine",
  value: "oxcarbazepine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXECTA",
  value: "OXECTA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Oxiconazole",
  value: "Oxiconazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXSERALEN-ULTRA",
  value: "OXSERALEN-ULTRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXSORALEN",
  value: "OXSORALEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXSORALEN ULTRA",
  value: "OXSORALEN ULTRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXSORALEN-ULTRA",
  value: "OXSORALEN-ULTRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXTELLAR",
  value: "OXTELLAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXTELLAR XR",
  value: "OXTELLAR XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Oxybutynin",
  value: "Oxybutynin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYBUTYNIN CHLORIDE",
  value: "OXYBUTYNIN CHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYCET",
  value: "OXYCET",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "oxycodone",
  value: "oxycodone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYCODONE 2.5/APAP 500",
  value: "OXYCODONE 2.5/APAP 500",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYCODONE 5/APAP 500",
  value: "OXYCODONE 5/APAP 500",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYCODONE AND ACETAMINOPHEN",
  value: "OXYCODONE AND ACETAMINOPHEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYCODONE AND ASPIRIN",
  value: "OXYCODONE AND ASPIRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYCODONE AND ASPIRIN (HALF-STRENGTH)",
  value: "OXYCODONE AND ASPIRIN (HALF-STRENGTH)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYCODONE HYDROCHLORIDE",
  value: "OXYCODONE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYCODONE HYDROCHLORIDE AND IBUPROFEN",
  value: "OXYCODONE HYDROCHLORIDE AND IBUPROFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYCONTIN",
  value: "OXYCONTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Oxymetazoline",
  value: "Oxymetazoline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "oxymorphone",
  value: "oxymorphone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYMORPHONE HYDROCHLORIDE",
  value: "OXYMORPHONE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYTROL",
  value: "OXYTROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OXYTROL FOR WOMEN",
  value: "OXYTROL FOR WOMEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OYSTER SHELL CALCIUM",
  value: "OYSTER SHELL CALCIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "OZURDEX",
  value: "OZURDEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pacerone",
  value: "Pacerone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Paclitaxel",
  value: "Paclitaxel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "palbociclib",
  value: "palbociclib",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Paliperidone",
  value: "Paliperidone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Palonosetron",
  value: "Palonosetron",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PALUDRINE",
  value: "PALUDRINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PAMELOR",
  value: "PAMELOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pancreaze",
  value: "Pancreaze",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PANCRELIPASE",
  value: "PANCRELIPASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PANDEL",
  value: "PANDEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PANHEPRIN",
  value: "PANHEPRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PANIXINE DISPERDOSE",
  value: "PANIXINE DISPERDOSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "panobinostat",
  value: "panobinostat",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "pantoprazole",
  value: "pantoprazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PANTOPRAZOLE SODIUM",
  value: "PANTOPRAZOLE SODIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Papa-deine",
  value: "Papa-deine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Paracetamol",
  value: "Paracetamol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PARACORT",
  value: "PARACORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PARACORT, LIQUID PRED, PREDNICEN, SERVISONE",
  value: '"PARACORT, LIQUID PRED, PREDNICEN, SERVISONE"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Parafon Forte",
  value: "Parafon Forte",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "parathyroid hormone",
  value: "parathyroid hormone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "paritaprevir",
  value: "paritaprevir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "paroxetine",
  value: "paroxetine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pataday",
  value: "Pataday",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PATANOL--EYE DROPS",
  value: "PATANOL--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Paxil",
  value: "Paxil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Paxil CR",
  value: "Paxil CR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PBZ",
  value: "PBZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PBZ-SR",
  value: "PBZ-SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PCE",
  value: "PCE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEDIAMYCIN",
  value: "PEDIAMYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEDIAMYCIN 400",
  value: "PEDIAMYCIN 400",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEDIATRIC ADVIL",
  value: "PEDIATRIC ADVIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEDIATRIC LTA KIT",
  value: "PEDIATRIC LTA KIT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEDIAZOLE",
  value: "PEDIAZOLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEDIOTIC",
  value: "PEDIOTIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEGASYS",
  value: "PEGASYS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEGINTERFERON",
  value: "PEGINTERFERON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEGINTRON",
  value: "PEGINTRON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEGINTRON/REBETOL COMBO PACK",
  value: "PEGINTRON/REBETOL COMBO PACK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PENECORT",
  value: "PENECORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "PENECORT, NUTRACORT, NOGENIC HC, HYTONE, H-CORT, HC #1, HC #4, CORTEF, CORTENEMA",
  value:
   '"PENECORT, NUTRACORT, NOGENIC HC, HYTONE, H-CORT, HC #1, HC #4, CORTEF, CORTENEMA"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PENETREX",
  value: "PENETREX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Penicillin G",
  value: "Penicillin G",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Penicillin VK",
  value: "Penicillin VK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pennsaid",
  value: "Pennsaid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PENNTUSS",
  value: "PENNTUSS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pentamidine",
  value: "Pentamidine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PENTASA",
  value: "PENTASA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pentazocine",
  value: "Pentazocine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "pentobarbital",
  value: "pentobarbital",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "pentoxifylline",
  value: "pentoxifylline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PENTOXIL",
  value: "PENTOXIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pentuss",
  value: "Pentuss",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pepcid",
  value: "Pepcid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEPCID AC",
  value: "PEPCID AC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEPCID PRESERVATIVE FREE",
  value: "PEPCID PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEPCID PRESERVATIVE FREE IN PLASTIC CONTAINER",
  value: "PEPCID PRESERVATIVE FREE IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PEPCID RPD",
  value: "PEPCID RPD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "perampanel",
  value: "perampanel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PERCOCET",
  value: "PERCOCET",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PERCODAN",
  value: "PERCODAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PERCODAN-DEMI",
  value: "PERCODAN-DEMI",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "perhexiline",
  value: "perhexiline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PERIACTIN",
  value: "PERIACTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Perindopril",
  value: "Perindopril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PERMETHRIN--CREAM",
  value: "PERMETHRIN--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PERMITIL",
  value: "PERMITIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "perphenazine",
  value: "perphenazine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PERPHENAZINE AND AMITRIPTYLINE HYDROCHLORIDE",
  value: "PERPHENAZINE AND AMITRIPTYLINE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PERSANTINE",
  value: "PERSANTINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PERTOFRANE",
  value: "PERTOFRANE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pertzye",
  value: "Pertzye",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pexeva",
  value: "Pexeva",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PFIZER-E",
  value: "PFIZER-E",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "phenacetin",
  value: "phenacetin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PHENAZOPYRIDINE",
  value: "PHENAZOPYRIDINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PHENERGAN",
  value: "PHENERGAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PHENETRON",
  value: "PHENETRON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PHENETRON, PYRIDAMAL, TELDRIN, ANA-KIT, TUSSIONEX, VITUZ, ZUTRIPO",
  value: '"PHENETRON, PYRIDAMAL, TELDRIN, ANA-KIT, TUSSIONEX, VITUZ, ZUTRIPO"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "phenformin",
  value: "phenformin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Phenobarbital",
  value: "Phenobarbital",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "phenobarbitone",
  value: "phenobarbitone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Phentermine",
  value: "Phentermine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "phenylbutazone",
  value: "phenylbutazone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "phenylephrine",
  value: "phenylephrine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PHENYLPROPANOLAMINE HYDROCHLORIDE W/ CHLORPHENIRAMINE MALEATE",
  value: "PHENYLPROPANOLAMINE HYDROCHLORIDE W/ CHLORPHENIRAMINE MALEATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Phenytoin",
  value: "Phenytoin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PHERAZINE",
  value: "PHERAZINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PHILITH",
  value: "PHILITH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PHOSLO",
  value: "PHOSLO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PHOSLO GELCAPS",
  value: "PHOSLO GELCAPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PHOSLYRA",
  value: "PHOSLYRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PHOSPHA",
  value: "PHOSPHA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PILOCARPINE",
  value: "PILOCARPINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PILOCARPINE--EYE DROPS",
  value: "PILOCARPINE--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "pimozide",
  value: "pimozide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PIMTREA",
  value: "PIMTREA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pindolol",
  value: "Pindolol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pioglitazone",
  value: "Pioglitazone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PIOGLITAZONE HYDROCHLORIDE AND GLIMEPIRIDE",
  value: "PIOGLITAZONE HYDROCHLORIDE AND GLIMEPIRIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "piperacillin and tazobactam",
  value: "piperacillin and tazobactam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PIRMELLA 1/35",
  value: "PIRMELLA 1/35",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PIRMELLA 7/7/7",
  value: "PIRMELLA 7/7/7",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "piroxicam",
  value: "piroxicam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "pitavastatin",
  value: "pitavastatin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Plaquenil",
  value: "Plaquenil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PLATINOL",
  value: "PLATINOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PLATINOL-AQ",
  value: "PLATINOL-AQ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Plavix",
  value: "Plavix",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PLENDIL",
  value: "PLENDIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PLETAL",
  value: "PLETAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PLIAGLIS",
  value: "PLIAGLIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PLIAGLIS--cream",
  value: "PLIAGLIS--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PLIAGLIS--patch",
  value: "PLIAGLIS--patch",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "POLARAMINE",
  value: "POLARAMINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "polyethylene glycol",
  value: "polyethylene glycol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "POLYMOX",
  value: "POLYMOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "POLYMYXIN-- EYE DROPS",
  value: "POLYMYXIN-- EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PONSTEL",
  value: "PONSTEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PORTALAC",
  value: "PORTALAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PORTIA-21",
  value: "PORTIA-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PORTIA-28",
  value: "PORTIA-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Posaconazole",
  value: "Posaconazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "potassium chloride",
  value: "potassium chloride",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "POTASSIUM CHLORIDE 10MEQ IN PLASTIC CONTAINER",
  value: "POTASSIUM CHLORIDE 10MEQ IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "POTASSIUM CHLORIDE 20MEQ IN PLASTIC CONTAINER",
  value: "POTASSIUM CHLORIDE 20MEQ IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "POTASSIUM CHLORIDE 30MEQ IN PLASTIC CONTAINER",
  value: "POTASSIUM CHLORIDE 30MEQ IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "POTASSIUM CHLORIDE 40MEQ IN PLASTIC CONTAINER",
  value: "POTASSIUM CHLORIDE 40MEQ IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "POTASSIUM CHLORIDE IN PLASTIC CONTAINER",
  value: "POTASSIUM CHLORIDE IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Potassium Phosphate",
  value: "Potassium Phosphate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Potiga",
  value: "Potiga",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRADAXA",
  value: "PRADAXA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Praluent",
  value: "Praluent",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "pramipexole",
  value: "pramipexole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRAMOSONE",
  value: "PRAMOSONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRANDIMET",
  value: "PRANDIMET",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRANDIN",
  value: "PRANDIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Prasugrel",
  value: "Prasugrel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pravachol",
  value: "Pravachol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pravastatin",
  value: "Pravastatin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Prazosin",
  value: "Prazosin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRAZOSIN HYDROCHLORIDE",
  value: "PRAZOSIN HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREDNICEN-M",
  value: "PREDNICEN-M",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "prednisolone",
  value: "prednisolone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREDNISOLONE--EYE DROPs",
  value: "PREDNISOLONE--EYE DROPs",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "prednisone",
  value: "prednisone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREDNISONE INTENSOL",
  value: "PREDNISONE INTENSOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREFEST",
  value: "PREFEST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "pregabalin",
  value: "pregabalin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRELAY",
  value: "PRELAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRELONE",
  value: "PRELONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREMARIN--CREAM",
  value: "PREMARIN--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Prestalia",
  value: "Prestalia",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREVACID",
  value: "PREVACID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREVACID 24 HR",
  value: "PREVACID 24 HR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREVACID IV",
  value: "PREVACID IV",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREVACID NAPRAPAC 250 (COPACKAGED)",
  value: "PREVACID NAPRAPAC 250 (COPACKAGED)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREVACID NAPRAPAC 375 (COPACKAGED)",
  value: "PREVACID NAPRAPAC 375 (COPACKAGED)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREVACID NAPRAPAC 500 (COPACKAGED)",
  value: "PREVACID NAPRAPAC 500 (COPACKAGED)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREVALITE",
  value: "PREVALITE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREVEN EMERGENCY CONTRACEPTIVE KIT",
  value: "PREVEN EMERGENCY CONTRACEPTIVE KIT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREVIFEM",
  value: "PREVIFEM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PREVPAC",
  value: "PREVPAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Prezcobix",
  value: "Prezcobix",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Prezista",
  value: "Prezista",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRIFTIN",
  value: "PRIFTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "prilocaine",
  value: "prilocaine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRILOSEC",
  value: "PRILOSEC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRILOSEC OTC",
  value: "PRILOSEC OTC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Primidone",
  value: "Primidone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Primsol",
  value: "Primsol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRINIVIL",
  value: "PRINIVIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Prinizide",
  value: "Prinizide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pristiq",
  value: "Pristiq",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROAIR",
  value: "PROAIR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROAIR HFA",
  value: "PROAIR HFA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROAMATINE",
  value: "PROAMATINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROBALAN",
  value: "PROBALAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "probenecid",
  value: "probenecid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROBIOTIC",
  value: "PROBIOTIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "procainamide",
  value: "procainamide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCAINAMIDE HCL",
  value: "PROCAINAMIDE HCL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCAINAMIDE HYDROCHLORIDE",
  value: "PROCAINAMIDE HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCAN",
  value: "PROCAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCAN SR",
  value: "PROCAN SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCANBID",
  value: "PROCANBID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCAPAN",
  value: "PROCAPAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCARDIA",
  value: "PROCARDIA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCARDIA XL",
  value: "PROCARDIA XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ProCentra",
  value: "ProCentra",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Prochlorperazine",
  value: "Prochlorperazine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCHLORPERAZINE EDISYLATE",
  value: "PROCHLORPERAZINE EDISYLATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCHLORPERAZINE MALEATE",
  value: "PROCHLORPERAZINE MALEATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCOMP",
  value: "PROCOMP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCRIT",
  value: "PROCRIT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCTOCORT",
  value: "PROCTOCORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "proctocort--cream",
  value: "proctocort--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROCTOFOAM HC",
  value: "PROCTOFOAM HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "proctofoam--cream",
  value: "proctofoam--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "proctosol--cream",
  value: "proctosol--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "proctosol-HC--cream",
  value: "proctosol-HC--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "proctozone--cream",
  value: "proctozone--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "proctozone-HC--cream",
  value: "proctozone-HC--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROFEN",
  value: "PROFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROFENAL",
  value: "PROFENAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROGESTASERT",
  value: "PROGESTASERT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "progesterone",
  value: "progesterone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Progestins",
  value: "Progestins",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROGRAF",
  value: "PROGRAF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "proguanil",
  value: "proguanil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Prolia",
  value: "Prolia",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROLIXIN",
  value: "PROLIXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROLIXIN DECANOATE",
  value: "PROLIXIN DECANOATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROLIXIN ENANTHATE",
  value: "PROLIXIN ENANTHATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROMAPAR",
  value: "PROMAPAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROMETH FORTIS",
  value: "PROMETH FORTIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROMETH PLAIN",
  value: "PROMETH PLAIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROMETHACON",
  value: "PROMETHACON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "promethazine",
  value: "promethazine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Promethegan",
  value: "Promethegan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROMETRIUM",
  value: "PROMETRIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRONESTYL",
  value: "PRONESTYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PRONESTYL-SR",
  value: "PRONESTYL-SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "propafenone",
  value: "propafenone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROPECIA",
  value: "PROPECIA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Propofol",
  value: "Propofol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Propoxyphene",
  value: "Propoxyphene",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "propranolol",
  value: "propranolol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Propranolol Hydrochloride Injection",
  value: "Propranolol Hydrochloride Injection",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROPULSID",
  value: "PROPULSID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROQUIN XR",
  value: "PROQUIN XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROSCAR",
  value: "PROSCAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROSTEP",
  value: "PROSTEP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROTONIX",
  value: "PROTONIX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROTONIX IV",
  value: "PROTONIX IV",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROTOPIC",
  value: "PROTOPIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROTOSTAT",
  value: "PROTOSTAT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Proval #3",
  value: "Proval #3",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROVENTIL",
  value: "PROVENTIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROVENTIL HFA",
  value: "PROVENTIL HFA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Provera",
  value: "Provera",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROVIGIL",
  value: "PROVIGIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROZAC",
  value: "PROZAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PROZAC WEEKLY",
  value: "PROZAC WEEKLY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pseudoephedrine",
  value: "Pseudoephedrine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PSEUDOEPHEDRINE HYDROCHLORIDE AND CHLORPHENIRAMINE MALEATE",
  value: "PSEUDOEPHEDRINE HYDROCHLORIDE AND CHLORPHENIRAMINE MALEATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Psoralen",
  value: "Psoralen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "psyllium",
  value: "psyllium",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PULMICORT",
  value: "PULMICORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PULMICORT FLEXHALER",
  value: "PULMICORT FLEXHALER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PULMICORT RESPULES",
  value: "PULMICORT RESPULES",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PURINETHOL",
  value: "PURINETHOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PURIXAN",
  value: "PURIXAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PYOCIDIN",
  value: "PYOCIDIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PYRIDAMAL 100",
  value: "PYRIDAMAL 100",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "PYRIDIUM",
  value: "PYRIDIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Pyrilamine",
  value: "Pyrilamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QNASL",
  value: "QNASL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Q-PAM",
  value: "Q-PAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Q-PAP",
  value: "Q-PAP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Qsymia",
  value: "Qsymia",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Q-TUSSIN",
  value: "Q-TUSSIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Q-TUSSIN DM",
  value: "Q-TUSSIN DM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QUALAQUIN",
  value: "QUALAQUIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QUARTETTE",
  value: "QUARTETTE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QUASENSE",
  value: "QUASENSE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QUDEXY XR",
  value: "QUDEXY XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QUESTRAN",
  value: "QUESTRAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QUESTRAN LIGHT",
  value: "QUESTRAN LIGHT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "quetiapine",
  value: "quetiapine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QUIBRON-T",
  value: "QUIBRON-T",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QUIBRON-T/SR",
  value: "QUIBRON-T/SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QUILLIVANT XR",
  value: "QUILLIVANT XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Quinacrine",
  value: "Quinacrine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Quinact",
  value: "Quinact",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Quinaglute",
  value: "Quinaglute",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Quinalan",
  value: "Quinalan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Quinapril",
  value: "Quinapril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Quinatime",
  value: "Quinatime",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Quinidex",
  value: "Quinidex",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "quinidine",
  value: "quinidine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "quinidine/dextromethorphan",
  value: "quinidine/dextromethorphan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Quinine",
  value: "Quinine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Quinora",
  value: "Quinora",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QUIXIN",
  value: "QUIXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QVAR 40",
  value: "QVAR 40",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QVAR 80",
  value: "QVAR 80",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "QVAR INHALER",
  value: "QVAR INHALER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rabeprazole",
  value: "rabeprazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Raloxifene",
  value: "Raloxifene",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Raltegravir",
  value: "Raltegravir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ramelteon",
  value: "Ramelteon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ramipril",
  value: "Ramipril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RANEXA",
  value: "RANEXA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RANITIDINE",
  value: "RANITIDINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ranolazine",
  value: "Ranolazine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Rapaflo",
  value: "Rapaflo",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RAPAMUNE",
  value: "RAPAMUNE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Rapifen",
  value: "Rapifen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rasagiline",
  value: "rasagiline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RASUVO",
  value: "RASUVO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RAU-SED",
  value: "RAU-SED",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RAYOS",
  value: "RAYOS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REBIF",
  value: "REBIF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RECTIV",
  value: "RECTIV",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "red-haloperidol",
  value: "red-haloperidol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REDISOL",
  value: "REDISOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REDUX",
  value: "REDUX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REFRESH--EYE DROPS",
  value: "REFRESH--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REGLAN",
  value: "REGLAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REGLAN ODT",
  value: "REGLAN ODT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "regletin",
  value: "regletin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REGROTON",
  value: "REGROTON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REGULOID",
  value: "REGULOID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RELA",
  value: "RELA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RELAFEN",
  value: "RELAFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Relpax",
  value: "Relpax",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REMERON",
  value: "REMERON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REMERON SOLTAB",
  value: "REMERON SOLTAB",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REMICADE",
  value: "REMICADE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RENESE-R",
  value: "RENESE-R",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "renvela",
  value: "renvela",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Repaglinide",
  value: "Repaglinide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Repatha",
  value: "Repatha",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REPREXAIN",
  value: "REPREXAIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "requip",
  value: "requip",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RESCRIPTOR",
  value: "RESCRIPTOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Reserpine",
  value: "Reserpine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RESERPINE AND HYDROCHLOROTHIAZIDE",
  value: "RESERPINE AND HYDROCHLOROTHIAZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RESERPINE AND HYDROCHLOROTHIAZIDE-50",
  value: "RESERPINE AND HYDROCHLOROTHIAZIDE-50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RESERPINE AND HYDROFLUMETHIAZIDE",
  value: "RESERPINE AND HYDROFLUMETHIAZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RESERPINE, HYDRALAZINE HYDROCHLORIDE AND HYDROCHLOROTHIAZIDE",
  value: '"RESERPINE, HYDRALAZINE HYDROCHLORIDE AND HYDROCHLOROTHIAZIDE"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RESERPINE, HYDROCHLOROTHIAZIDE, AND HYDRALAZINE HYDROCHLORIDE",
  value: '"RESERPINE, HYDROCHLOROTHIAZIDE, AND HYDRALAZINE HYDROCHLORIDE"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RESTASIS",
  value: "RESTASIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RESTASIS--EYE EMULSION",
  value: "RESTASIS--EYE EMULSION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RESTORIL",
  value: "RESTORIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Retigabine",
  value: "Retigabine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Retinoic acid",
  value: "Retinoic acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RETROVIR",
  value: "RETROVIR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REVATIO",
  value: "REVATIO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Revia",
  value: "Revia",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REVLIMID",
  value: "REVLIMID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Rexulti",
  value: "Rexulti",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Reyataz",
  value: "Reyataz",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "REZULIN",
  value: "REZULIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Rheumatrex",
  value: "Rheumatrex",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RHINOCORT",
  value: "RHINOCORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Rhodamine",
  value: "Rhodamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rifabutin",
  value: "rifabutin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RIFADIN",
  value: "RIFADIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RIFAMATE",
  value: "RIFAMATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rifampin",
  value: "rifampin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RIFAMPIN AND ISONIAZID",
  value: "RIFAMPIN AND ISONIAZID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Rifapentine",
  value: "Rifapentine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RIFATER",
  value: "RIFATER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rifaximin",
  value: "rifaximin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rilpivirine",
  value: "rilpivirine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RILUTEK",
  value: "RILUTEK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "riluzole",
  value: "riluzole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RIMACTANE",
  value: "RIMACTANE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RIMIFON",
  value: "RIMIFON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "risedronate",
  value: "risedronate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "risendronate",
  value: "risendronate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RISPERDAL",
  value: "RISPERDAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RISPERDAL CONSTA",
  value: "RISPERDAL CONSTA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Risperidone",
  value: "Risperidone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RITALIN",
  value: "RITALIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RITALIN LA",
  value: "RITALIN LA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RITALIN SR",
  value: "RITALIN SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RITALIN-SR",
  value: "RITALIN-SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ritonavir",
  value: "Ritonavir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RITUXAN",
  value: "RITUXAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rituximab",
  value: "rituximab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Rivaroxaban",
  value: "Rivaroxaban",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rivastigmine",
  value: "rivastigmine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rizatriptan",
  value: "rizatriptan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "R-mephobarbital",
  value: "R-mephobarbital",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROBAXIN",
  value: "ROBAXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROBAXIN-750",
  value: "ROBAXIN-750",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROBIMYCIN",
  value: "ROBIMYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROBINUL",
  value: "ROBINUL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROBINUL FORTE",
  value: "ROBINUL FORTE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROCALTROL",
  value: "ROCALTROL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROCEPHIN KIT",
  value: "ROCEPHIN KIT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROFERON",
  value: "ROFERON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROFERON A",
  value: "ROFERON A",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Roflumilast",
  value: "Roflumilast",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROGAINE (FOR MEN)",
  value: "ROGAINE (FOR MEN)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROGAINE (FOR WOMEN)",
  value: "ROGAINE (FOR WOMEN)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROGAINE EXTRA STRENGTH (FOR MEN)",
  value: "ROGAINE EXTRA STRENGTH (FOR MEN)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROLAIDS",
  value: "ROLAIDS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rolapitant",
  value: "rolapitant",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ropinirole",
  value: "ropinirole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ropivacaine",
  value: "ropivacaine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rosiglitazone",
  value: "rosiglitazone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROSIGLITAZONE MALEATE; GLIMEPIRIDE",
  value: "ROSIGLITAZONE MALEATE; GLIMEPIRIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Rosuvastatin",
  value: "Rosuvastatin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROTAHALER",
  value: "ROTAHALER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rotigotine",
  value: "rotigotine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROWASA",
  value: "ROWASA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Roxicet",
  value: "Roxicet",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROXICET 5/500",
  value: "ROXICET 5/500",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROXICODONE",
  value: "ROXICODONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROXILOX",
  value: "ROXILOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Roxithromycin",
  value: "Roxithromycin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ROZEREM",
  value: "ROZEREM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "R-P MYCIN",
  value: "R-P MYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RUBEX",
  value: "RUBEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RUBIVITE",
  value: "RUBIVITE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RUBRAMIN PC",
  value: "RUBRAMIN PC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RUFEN",
  value: "RUFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "rufinamide",
  value: "rufinamide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "RUVITE",
  value: "RUVITE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Rybix ODT",
  value: "Rybix ODT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Rythmol",
  value: "Rythmol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Rythmol SR",
  value: "Rythmol SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ryzolt",
  value: "Ryzolt",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "S.A.S.-500",
  value: "S.A.S.-500",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SABRIL",
  value: "SABRIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Saccharomyces boulardii",
  value: "Saccharomyces boulardii",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sacubitril",
  value: "sacubitril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SAFYRAL",
  value: "SAFYRAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Salagen",
  value: "Salagen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SALAMOL",
  value: "SALAMOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SALBULIN",
  value: "SALBULIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "salbutamol",
  value: "salbutamol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SALBUTARD",
  value: "SALBUTARD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SALBUTINE",
  value: "SALBUTINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SALBUVENT",
  value: "SALBUVENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Salicylate",
  value: "Salicylate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "salmeterol",
  value: "salmeterol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SALUTENSIN",
  value: "SALUTENSIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SALUTENSIN-DEMI",
  value: "SALUTENSIN-DEMI",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SANDIMMUNE",
  value: "SANDIMMUNE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sandostatin",
  value: "Sandostatin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SANDRIL",
  value: "SANDRIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SANSAC",
  value: "SANSAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Santyl--ointment",
  value: "Santyl--ointment",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Saphris",
  value: "Saphris",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "saquinavir",
  value: "saquinavir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SARAFEM",
  value: "SARAFEM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SATRIC",
  value: "SATRIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Savaysa",
  value: "Savaysa",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Savella",
  value: "Savella",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Saxagliptin",
  value: "Saxagliptin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Scopolamine",
  value: "Scopolamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SEASONALE",
  value: "SEASONALE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SEASONIQUE",
  value: "SEASONIQUE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "secobarbital",
  value: "secobarbital",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SECONAL SODIUM",
  value: "SECONAL SODIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "secukinumab",
  value: "secukinumab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SELDANE",
  value: "SELDANE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SELENIUM SULFIDE--LOTION",
  value: "SELENIUM SULFIDE--LOTION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SELENIUM--SHAMPOO",
  value: "SELENIUM--SHAMPOO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SELFEMRA",
  value: "SELFEMRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Selzentry",
  value: "Selzentry",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SENEXON",
  value: "SENEXON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Senna",
  value: "Senna",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SENNA LAXATIVE",
  value: "SENNA LAXATIVE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Senna-Gen",
  value: "Senna-Gen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SENNA-GEN NF",
  value: "SENNA-GEN NF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sensipar",
  value: "sensipar",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SEPTRA",
  value: "SEPTRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SER-A-GEN",
  value: "SER-A-GEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SER-AP-ES",
  value: "SER-AP-ES",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SERAX",
  value: "SERAX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SEREVENT",
  value: "SEREVENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Seroquel",
  value: "Seroquel",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Seroquel XR",
  value: "Seroquel XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SERPALAN",
  value: "SERPALAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SERPANRAY",
  value: "SERPANRAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SERPASIL",
  value: "SERPASIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SERPASIL-APRESOLINE",
  value: "SERPASIL-APRESOLINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SERPASIL-ESIDRIX #1",
  value: "SERPASIL-ESIDRIX #1",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SERPASIL-ESIDRIX #2",
  value: "SERPASIL-ESIDRIX #2",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SERPATE",
  value: "SERPATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SERPIVITE",
  value: "SERPIVITE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sertraline",
  value: "Sertraline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SERVISONE",
  value: "SERVISONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SERZONE",
  value: "SERZONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sevelamer",
  value: "sevelamer",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SFROWASA",
  value: "SFROWASA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sibutramine",
  value: "Sibutramine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sildenafil",
  value: "sildenafil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SILENOR",
  value: "SILENOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Silodosin",
  value: "Silodosin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SILPHEN",
  value: "SILPHEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "silvadene--cream",
  value: "silvadene--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SILVASORB--GEL",
  value: "SILVASORB--GEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "silver sulfadiazine topical",
  value: "silver sulfadiazine topical",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SILVER SULFADIAZINE--CREAM",
  value: "SILVER SULFADIAZINE--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "silver sulfadiazine--topical",
  value: "silver sulfadiazine--topical",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SIMBRINZA--EYE DROPS",
  value: "SIMBRINZA--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Simcor",
  value: "Simcor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "simethicone",
  value: "simethicone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SIMPONI",
  value: "SIMPONI",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SIMPONI ARIA",
  value: "SIMPONI ARIA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "simvastatin",
  value: "simvastatin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SINE-AID IB",
  value: "SINE-AID IB",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SINEQUAN",
  value: "SINEQUAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SINGULAIR",
  value: "SINGULAIR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sinsemilla",
  value: "sinsemilla",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sirolimus",
  value: "sirolimus",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sitagliptin",
  value: "Sitagliptin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sitagliptin and metformin",
  value: "sitagliptin and metformin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sitagliptin and metformin hydrochloride",
  value: "Sitagliptin and metformin hydrochloride",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SITAVIG",
  value: "SITAVIG",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SKELAXIN",
  value: "SKELAXIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SKLICE",
  value: "SKLICE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SLO-BID",
  value: "SLO-BID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SLO-PHYLLIN",
  value: "SLO-PHYLLIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SLOW-K",
  value: "SLOW-K",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SM TUSSIN DM",
  value: "SM TUSSIN DM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SN-38",
  value: "SN-38",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SODIUM BICARB",
  value: "SODIUM BICARB",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sodium bicarbonate",
  value: "sodium bicarbonate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SODIUM BICARBONATE IN PLASTIC CONTAINER",
  value: "SODIUM BICARBONATE IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SODIUM CHLORIDE",
  value: "SODIUM CHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SODIUM CHLORIDE 0.45% IN PLASTIC CONTAINER",
  value: "SODIUM CHLORIDE 0.45% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SODIUM CHLORIDE 0.9%",
  value: "SODIUM CHLORIDE 0.9%",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value: "SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SODIUM CHLORIDE 0.9% IN STERILE PLASTIC CONTAINER",
  value: "SODIUM CHLORIDE 0.9% IN STERILE PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SODIUM CHLORIDE 23.4% IN PLASTIC CONTAINER",
  value: "SODIUM CHLORIDE 23.4% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SODIUM CHLORIDE 3% IN PLASTIC CONTAINER",
  value: "SODIUM CHLORIDE 3% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SODIUM CHLORIDE 5% IN PLASTIC CONTAINER",
  value: "SODIUM CHLORIDE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SODIUM CHLORIDE IN PLASTIC CONTAINER",
  value: "SODIUM CHLORIDE IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SODIUM HEPARIN",
  value: "SODIUM HEPARIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sodium oxybate",
  value: "Sodium oxybate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sodium Phosphate",
  value: "Sodium Phosphate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sodium polystyrene sulfonate",
  value: "sodium polystyrene sulfonate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Solaraze",
  value: "Solaraze",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Solifenacin",
  value: "Solifenacin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SOLIFENACIN SUCCINATE",
  value: "SOLIFENACIN SUCCINATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SOLTAMOX",
  value: "SOLTAMOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SOLU-CORTEF",
  value: "SOLU-CORTEF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Solu-Medrol",
  value: "Solu-Medrol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SOMA",
  value: "SOMA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SOMOPHYLLIN-CRT",
  value: "SOMOPHYLLIN-CRT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SOMOPHYLLIN-T",
  value: "SOMOPHYLLIN-T",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SONATA",
  value: "SONATA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SONAZINE",
  value: "SONAZINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sonidegib",
  value: "sonidegib",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sorafenib",
  value: "Sorafenib",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sorbitol",
  value: "Sorbitol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SORBITRATE",
  value: "SORBITRATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SORINE",
  value: "SORINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sotalol",
  value: "Sotalol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SOTALOL HYDROCHLORIDE",
  value: "SOTALOL HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sparfloxacin",
  value: "Sparfloxacin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sparteine",
  value: "sparteine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SPIRIVA HANDIHALER",
  value: "SPIRIVA HANDIHALER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "spironolactone",
  value: "spironolactone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SPIRONOLACTONE AND HYDROCHLOROTHIAZIDE",
  value: "SPIRONOLACTONE AND HYDROCHLOROTHIAZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SPIRONOLACTONE W/ HYDROCHLOROTHIAZIDE",
  value: "SPIRONOLACTONE W/ HYDROCHLOROTHIAZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SPORANOX",
  value: "SPORANOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SPRINTEC",
  value: "SPRINTEC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SPS",
  value: "SPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SSD",
  value: "SSD",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "STANOZIDE",
  value: "STANOZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "STARLIX",
  value: "STARLIX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "STATICIN",
  value: "STATICIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "stavudine",
  value: "stavudine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "STAVZOR",
  value: "STAVZOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "STERANE",
  value: "STERANE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Steroids",
  value: "Steroids",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "STIE-CORT",
  value: "STIE-CORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Stiolto Respimat",
  value: "Stiolto Respimat",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "stjohnswort",
  value: "stjohnswort",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Strattera",
  value: "Strattera",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "STRIANT",
  value: "STRIANT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "STRIANT, TESTIM, TESTODERM, TESTOPEL, VOGELXO",
  value: '"STRIANT, TESTIM, TESTODERM, TESTOPEL, VOGELXO"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Stribild",
  value: "Stribild",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Stromectol",
  value: "Stromectol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "stromectol-B",
  value: "stromectol-B",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SUBLIMAZE PRESERVATIVE FREE",
  value: "SUBLIMAZE PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Suboxone",
  value: "Suboxone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SUBSYS",
  value: "SUBSYS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Subutex",
  value: "Subutex",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sucralfate",
  value: "sucralfate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sufentanil",
  value: "Sufentanil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SULAR",
  value: "SULAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SULFABID",
  value: "SULFABID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SULFACETAMIDE--EYE DROPS",
  value: "SULFACETAMIDE--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sulfamethoxazole",
  value: "sulfamethoxazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sulfaphenazole",
  value: "sulfaphenazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sulfasalazine",
  value: "sulfasalazine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SULFAZINE",
  value: "SULFAZINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sulfinpyrazone",
  value: "Sulfinpyrazone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sulfonamides",
  value: "Sulfonamides",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sulindac",
  value: "Sulindac",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SULTANOL",
  value: "SULTANOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "sumatriptan",
  value: "sumatriptan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SUMATRIPTAN; NAPROXEN SODIUM",
  value: "SUMATRIPTAN; NAPROXEN SODIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Sunitinib",
  value: "Sunitinib",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Suprenza",
  value: "Suprenza",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "suprofen",
  value: "suprofen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SURMONTIL",
  value: "SURMONTIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SUSTAIRE",
  value: "SUSTAIRE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SUSTIVA",
  value: "SUSTIVA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SYEDA",
  value: "SYEDA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SYLATRON",
  value: "SYLATRON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Symbicort",
  value: "Symbicort",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Symbyax",
  value: "Symbyax",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SYNACORT",
  value: "SYNACORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "SYNERA",
  value: "SYNERA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Synjardy",
  value: "Synjardy",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAB-A-VITE",
  value: "TAB-A-VITE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAB-PROFEN",
  value: "TAB-PROFEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tacrine",
  value: "tacrine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tacrolimus",
  value: "Tacrolimus",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tadalafil",
  value: "Tadalafil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAGAMET",
  value: "TAGAMET",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAGAMET HB",
  value: "TAGAMET HB",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAGAMET HB 200",
  value: "TAGAMET HB 200",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAGAMET HYDROCHLORIDE IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  value: "TAGAMET HYDROCHLORIDE IN SODIUM CHLORIDE 0.9% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TALACEN",
  value: "TALACEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TALWIN",
  value: "TALWIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TALWIN 50",
  value: "TALWIN 50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TALWIN NX",
  value: "TALWIN NX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAMBOCOR",
  value: "TAMBOCOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tamiflu",
  value: "tamiflu",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tamoxifen",
  value: "tamoxifen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tamsulosin",
  value: "tamsulosin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAO",
  value: "TAO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAPAZOLE",
  value: "TAPAZOLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tapentadol",
  value: "tapentadol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TARGRETIN",
  value: "TARGRETIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tarka",
  value: "Tarka",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TASMAR",
  value: "TASMAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAVIST",
  value: "TAVIST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAVIST ALLERGY/SINUS/HEADACHE",
  value: "TAVIST ALLERGY/SINUS/HEADACHE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAVIST-1",
  value: "TAVIST-1",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAVIST-D",
  value: "TAVIST-D",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAXOL",
  value: "TAXOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAXOTERE",
  value: "TAXOTERE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TAZTIA XT",
  value: "TAZTIA XT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Technivie",
  value: "Technivie",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TECZEM",
  value: "TECZEM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tegaserod",
  value: "Tegaserod",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TEGRETOL",
  value: "TEGRETOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TEGRETOL-XR",
  value: "TEGRETOL-XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tekamlo",
  value: "Tekamlo",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tekturna",
  value: "tekturna",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Telaprevir",
  value: "Telaprevir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TELDRIN",
  value: "TELDRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "telithromycin",
  value: "telithromycin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Telmisartan",
  value: "Telmisartan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TEMAZ",
  value: "TEMAZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Temazepam",
  value: "Temazepam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Temocapril",
  value: "Temocapril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Temsirolimus",
  value: "Temsirolimus",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TENEX",
  value: "TENEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "teniposide",
  value: "teniposide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TEN-K",
  value: "TEN-K",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tenofovir",
  value: "Tenofovir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tenoretic",
  value: "Tenoretic",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TENORMIN",
  value: "TENORMIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "terazosin",
  value: "terazosin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TERAZOSIN HYDROCHLORIDE",
  value: "TERAZOSIN HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "terbinafine",
  value: "terbinafine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TERBINAFINE--CREAM",
  value: "TERBINAFINE--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "terfenadine",
  value: "terfenadine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TERIL",
  value: "TERIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TERIPARATIDE",
  value: "TERIPARATIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TERRA-CORTRIL",
  value: "TERRA-CORTRIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TERRAMYCIN",
  value: "TERRAMYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TESSALON",
  value: "TESSALON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TESTIM",
  value: "TESTIM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TESTODERM",
  value: "TESTODERM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TESTODERM TTS",
  value: "TESTODERM TTS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TESTOPEL",
  value: "TESTOPEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Testosterone",
  value: "Testosterone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TESTOSTERONE CYPIONATE-ESTRADIOL CYPIONATE",
  value: "TESTOSTERONE CYPIONATE-ESTRADIOL CYPIONATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TESTOSTERONE ENANTHATE AND ESTRADIOL VALERATE",
  value: "TESTOSTERONE ENANTHATE AND ESTRADIOL VALERATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tetracycline",
  value: "Tetracycline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tetrahydrocannabinol",
  value: "Tetrahydrocannabinol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Teveten",
  value: "Teveten",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TEXACORT",
  value: "TEXACORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THALITONE",
  value: "THALITONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THC",
  value: "THC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEELIN",
  value: "THEELIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEO-24",
  value: "THEO-24",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOBID",
  value: "THEOBID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOBID JR.",
  value: "THEOBID JR.",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOCHRON",
  value: "THEOCHRON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOCLEAR L.A.-130",
  value: "THEOCLEAR L.A.-130",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOCLEAR L.A.-260",
  value: "THEOCLEAR L.A.-260",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOCLEAR-100",
  value: "THEOCLEAR-100",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOCLEAR-200",
  value: "THEOCLEAR-200",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOCLEAR-80",
  value: "THEOCLEAR-80",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEO-DUR",
  value: "THEO-DUR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOLAIR",
  value: "THEOLAIR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOLAIR-SR",
  value: "THEOLAIR-SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOLIXIR",
  value: "THEOLIXIR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYL",
  value: "THEOPHYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYL-225",
  value: "THEOPHYL-225",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "theophylline",
  value: "theophylline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYLLINE 0.04% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "THEOPHYLLINE 0.04% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYLLINE 0.08% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "THEOPHYLLINE 0.08% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYLLINE 0.16% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "THEOPHYLLINE 0.16% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYLLINE 0.2% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "THEOPHYLLINE 0.2% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYLLINE 0.32% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "THEOPHYLLINE 0.32% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYLLINE 0.4% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "THEOPHYLLINE 0.4% AND DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYLLINE 5% DEXTOSE INJECTION VIAFLEX",
  value: "THEOPHYLLINE 5% DEXTOSE INJECTION VIAFLEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYLLINE 5% DEXTROSE INJECTION EXCEL",
  value: "THEOPHYLLINE 5% DEXTROSE INJECTION EXCEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYLLINE 5% DEXTROSE INJECTION FLEXIBLE",
  value: "THEOPHYLLINE 5% DEXTROSE INJECTION FLEXIBLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYLLINE AND DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "THEOPHYLLINE AND DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYLLINE IN DEXTROSE 5% IN PLASTIC CONTAINER",
  value: "THEOPHYLLINE IN DEXTROSE 5% IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYLLINE-SR",
  value: "THEOPHYLLINE-SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOPHYL-SR",
  value: "THEOPHYL-SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEOVENT",
  value: "THEOVENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THERA-TABS",
  value: "THERA-TABS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEREMS-M",
  value: "THEREMS-M",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THEROXIDIL",
  value: "THEROXIDIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Thioridazine",
  value: "Thioridazine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "THORAZINE",
  value: "THORAZINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "thyroid, desiccated",
  value: '"thyroid, desiccated"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Thyroxine",
  value: "Thyroxine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tiagabine",
  value: "Tiagabine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TIAMATE",
  value: "TIAMATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TIAZAC",
  value: "TIAZAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ticagrelor",
  value: "Ticagrelor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ticlid",
  value: "Ticlid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ticlopidine",
  value: "ticlopidine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tienilic acid",
  value: "Tienilic acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TIKOSYN",
  value: "TIKOSYN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "timolol",
  value: "timolol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Timoptic",
  value: "Timoptic",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TINDAMAX",
  value: "TINDAMAX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tinidazole",
  value: "Tinidazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tiotropium",
  value: "Tiotropium",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tipiracil",
  value: "tipiracil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tipranavir",
  value: "Tipranavir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tivicay",
  value: "Tivicay",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TIVORBEX",
  value: "TIVORBEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tizanidine",
  value: "tizanidine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOBI",
  value: "TOBI",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOBI PODHALER",
  value: "TOBI PODHALER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOBINAL",
  value: "TOBINAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOBRADEX",
  value: "TOBRADEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOBRADEX ST",
  value: "TOBRADEX ST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tobramycin",
  value: "tobramycin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOBRAMYCIN AND DEXAMETHASONE",
  value: "TOBRAMYCIN AND DEXAMETHASONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tobramycin and dexamethasone--opthalmic",
  value: "tobramycin and dexamethasone--opthalmic",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tobramycin--eye drops",
  value: "tobramycin--eye drops",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOBREX",
  value: "TOBREX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tocilizumab",
  value: "tocilizumab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tofacitinib",
  value: "tofacitinib",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOFRANIL",
  value: "TOFRANIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOFRANIL-PM",
  value: "TOFRANIL-PM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tolbutamide",
  value: "Tolbutamide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tolcapone",
  value: "Tolcapone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tolterodine",
  value: "Tolterodine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOLTERODINE TARTRATE",
  value: "TOLTERODINE TARTRATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOPAMAX",
  value: "TOPAMAX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOPAMAX SPRINKLE",
  value: "TOPAMAX SPRINKLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Topiramate",
  value: "Topiramate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOPOSAR",
  value: "TOPOSAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Topotecan",
  value: "Topotecan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Toprol-XL",
  value: "Toprol-XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Toremifene",
  value: "Toremifene",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TORISEL",
  value: "TORISEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "torsemide",
  value: "torsemide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOVALT ODT",
  value: "TOVALT ODT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TOVIAZ",
  value: "TOVIAZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "T-PHYL",
  value: "T-PHYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tradjenta",
  value: "Tradjenta",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tramadol",
  value: "tramadol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Trandate",
  value: "Trandate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Trandolapril",
  value: "Trandolapril",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRANSDERM-NITRO",
  value: "TRANSDERM-NITRO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Transderm-Scop",
  value: "Transderm-Scop",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Trastuzumab",
  value: "Trastuzumab",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "travasol",
  value: "travasol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRAVATAN--EYE DROPS",
  value: "TRAVATAN--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "trazodone",
  value: "trazodone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TREMIN",
  value: "TREMIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRENTAL",
  value: "TRENTAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tresiba",
  value: "Tresiba",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Trexall",
  value: "Trexall",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Treximet",
  value: "Treximet",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRI LO SPRINTEC",
  value: "TRI LO SPRINTEC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Trialodine",
  value: "Trialodine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIAMCINOLONE--CREAM",
  value: "TRIAMCINOLONE--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIAMCINOLONE--OINTMENT",
  value: "TRIAMCINOLONE--OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIAMINIC-12",
  value: "TRIAMINIC-12",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "triamterene",
  value: "triamterene",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIAMTERENE AND HYDROCHLOROTHIAZIDE",
  value: "TRIAMTERENE AND HYDROCHLOROTHIAZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Triavil",
  value: "Triavil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIAVIL 2-10",
  value: "TRIAVIL 2-10",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIAVIL 2-25",
  value: "TRIAVIL 2-25",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIAVIL 4-10",
  value: "TRIAVIL 4-10",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIAVIL 4-25",
  value: "TRIAVIL 4-25",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIAVIL 4-50",
  value: "TRIAVIL 4-50",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "triazolam",
  value: "triazolam",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tribenzor",
  value: "Tribenzor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRICHLORMETHIAZIDE W/ RESERPINE",
  value: "TRICHLORMETHIAZIDE W/ RESERPINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRICOR",
  value: "TRICOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRICOR (MICRONIZED)",
  value: "TRICOR (MICRONIZED)",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIDIL",
  value: "TRIDIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRI-ESTARYLLA",
  value: "TRI-ESTARYLLA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "trifluridine",
  value: "trifluridine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIGLIDE",
  value: "TRIGLIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "trihexyphenidyl",
  value: "trihexyphenidyl",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIHEXYPHENIDYL HYDROCHLORIDE",
  value: "TRIHEXYPHENIDYL HYDROCHLORIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRILAFON",
  value: "TRILAFON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRI-LEGEST 21",
  value: "TRI-LEGEST 21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRI-LEGEST FE",
  value: "TRI-LEGEST FE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRILEPTAL",
  value: "TRILEPTAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRI-LINYAH",
  value: "TRI-LINYAH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRILIPIX",
  value: "TRILIPIX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRI-LO-LINYAH",
  value: "TRI-LO-LINYAH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "trimethoprim",
  value: "trimethoprim",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Trimetrexate",
  value: "Trimetrexate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Trimipramine",
  value: "Trimipramine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIMIPRAMINE MALEATE",
  value: "TRIMIPRAMINE MALEATE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIMOX",
  value: "TRIMOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRI-NORINYL 21-DAY",
  value: "TRI-NORINYL 21-DAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRI-NORINYL 28-DAY",
  value: "TRI-NORINYL 28-DAY",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Trintellix",
  value: "Trintellix",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "tripelennamine",
  value: "tripelennamine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIPHASIL-21",
  value: "TRIPHASIL-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIPHASIL-28",
  value: "TRIPHASIL-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIPLE ANTIBIOTIC OINTMENT",
  value: "TRIPLE ANTIBIOTIC OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRI-PREVIFEM",
  value: "TRI-PREVIFEM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRI-SPRINTEC",
  value: "TRI-SPRINTEC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRITEC",
  value: "TRITEC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Triumeq",
  value: "Triumeq",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIVAGIZOLE 3",
  value: "TRIVAGIZOLE 3",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIVORA-21",
  value: "TRIVORA-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TRIVORA-28",
  value: "TRIVORA-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Trizivir",
  value: "Trizivir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "troglitazone",
  value: "troglitazone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TROKENDI XR",
  value: "TROKENDI XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Troleandomycin",
  value: "Troleandomycin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tropisetron",
  value: "Tropisetron",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "T-STAT",
  value: "T-STAT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TUSSICAPS",
  value: "TUSSICAPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TUSSIN",
  value: "TUSSIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TUSSIN DM",
  value: "TUSSIN DM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TUSSIONEX PENNKINETIC",
  value: "TUSSIONEX PENNKINETIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Twynsta",
  value: "Twynsta",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Tybost",
  value: "Tybost",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TYLENOL",
  value: "TYLENOL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TYLENOL WITH CODEINE",
  value: "TYLENOL WITH CODEINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TYLENOL WITH CODEINE No. 2",
  value: "TYLENOL WITH CODEINE No. 2",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TYLENOL WITH CODEINE No. 3",
  value: "TYLENOL WITH CODEINE No. 3",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TYLENOL WITH CODEINE No. 4",
  value: "TYLENOL WITH CODEINE No. 4",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TYLOX",
  value: "TYLOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "TYLOX-325",
  value: "TYLOX-325",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "UCERIS",
  value: "UCERIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "U-CORT",
  value: "U-CORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ULORIC",
  value: "ULORIC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ultracet",
  value: "Ultracet",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ultram",
  value: "Ultram",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ultram ER",
  value: "Ultram ER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ultresa",
  value: "Ultresa",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "UNI-DUR",
  value: "UNI-DUR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "UNIPEN",
  value: "UNIPEN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "UNIPEN IN PLASTIC CONTAINER",
  value: "UNIPEN IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "UNIPHYL",
  value: "UNIPHYL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "UNIPRES",
  value: "UNIPRES",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Unituxin",
  value: "Unituxin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "univasc",
  value: "univasc",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "UREX",
  value: "UREX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "URISPAS",
  value: "URISPAS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "UROBAK",
  value: "UROBAK",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "UTIMOX",
  value: "UTIMOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "UVADEX",
  value: "UVADEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VAGIFEM",
  value: "VAGIFEM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "valaciclovir",
  value: "valaciclovir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Valacyclovir",
  value: "Valacyclovir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VALACYCLOVIR HCL",
  value: "VALACYCLOVIR HCL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Valdecoxib",
  value: "Valdecoxib",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VALIUM",
  value: "VALIUM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Valproate",
  value: "Valproate",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Valproate sodium",
  value: "Valproate sodium",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Valproic acid",
  value: "Valproic acid",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VALRELEASE",
  value: "VALRELEASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "valsartan",
  value: "valsartan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VALSARTAN and HCTZ",
  value: "VALSARTAN and HCTZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "valsartan and hydrochlorothiazide",
  value: "valsartan and hydrochlorothiazide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VALSARTAN-HCTZ",
  value: "VALSARTAN-HCTZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Valspodar",
  value: "Valspodar",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VANCENASE",
  value: "VANCENASE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VANCERIL",
  value: "VANCERIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VANCERIL DOUBLE STRENGTH",
  value: "VANCERIL DOUBLE STRENGTH",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "vancomycin",
  value: "vancomycin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VANDAZOLE",
  value: "VANDAZOLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VANICREAM-- CREAM",
  value: "VANICREAM-- CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vardenafil",
  value: "Vardenafil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "varenicline",
  value: "varenicline",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Varubi",
  value: "Varubi",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vasotec",
  value: "Vasotec",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VECTICAL",
  value: "VECTICAL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vecuronium",
  value: "Vecuronium",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VELIVET",
  value: "VELIVET",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VELOSEF",
  value: "VELOSEF",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VELOSEF '125'",
  value: "VELOSEF '125'",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VELOSEF '250'",
  value: "VELOSEF '250'",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VELOSEF '500'",
  value: "VELOSEF '500'",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VENETLIN",
  value: "VENETLIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "venlafaxine",
  value: "venlafaxine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VENTOLIN",
  value: "VENTOLIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VENTOLIN HFA",
  value: "VENTOLIN HFA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VENTOLIN INHALER",
  value: "VENTOLIN INHALER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ventolin rotacaps",
  value: "ventolin rotacaps",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VEPESID",
  value: "VEPESID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VERAMYST",
  value: "VERAMYST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "verapamil",
  value: "verapamil",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Verelan",
  value: "Verelan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Verelan PM",
  value: "Verelan PM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VERSACLOZ",
  value: "VERSACLOZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VERSED",
  value: "VERSED",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VESICARE",
  value: "VESICARE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VFEND",
  value: "VFEND",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VIAGRA",
  value: "VIAGRA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Viberzi",
  value: "Viberzi",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VIBISONE",
  value: "VIBISONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VIBRAMYCIN",
  value: "VIBRAMYCIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VICKS FORMULA 44",
  value: "VICKS FORMULA 44",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vicodin",
  value: "Vicodin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vicoprofen",
  value: "Vicoprofen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VICTRELIS",
  value: "VICTRELIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vigabatrin",
  value: "Vigabatrin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VIGAMOX--EYE DROPS",
  value: "VIGAMOX--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Viibryd",
  value: "Viibryd",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vilanterol and fluticasone",
  value: "Vilanterol and fluticasone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "vilanterol and umeclidinium",
  value: "vilanterol and umeclidinium",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "vilazodone",
  value: "vilazodone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vimovo",
  value: "Vimovo",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VIMPAT",
  value: "VIMPAT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vinblastine",
  value: "Vinblastine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VINCASAR",
  value: "VINCASAR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VINCREX",
  value: "VINCREX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "vincristine",
  value: "vincristine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vindesine",
  value: "Vindesine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Viokace",
  value: "Viokace",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VIORELE",
  value: "VIORELE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VIRACEPT",
  value: "VIRACEPT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VIRAMUNE",
  value: "VIRAMUNE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VIRAMUNE XR",
  value: "VIRAMUNE XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Viread",
  value: "Viread",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Visken",
  value: "Visken",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VISTARIL",
  value: "VISTARIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VISTIDE",
  value: "VISTIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VITAMIN B",
  value: "VITAMIN B",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VITAMIN C",
  value: "VITAMIN C",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VITAMIN D",
  value: "VITAMIN D",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VITAMIN D2",
  value: "VITAMIN D2",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VITAMIN D3",
  value: "VITAMIN D3",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VITAMIN E",
  value: "VITAMIN E",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VITAMIN K",
  value: "VITAMIN K",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vitekta",
  value: "Vitekta",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VITRASERT",
  value: "VITRASERT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VITUZ",
  value: "VITUZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VI-TWEL",
  value: "VI-TWEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VIVELLE",
  value: "VIVELLE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VIVELLE-DOT",
  value: "VIVELLE-DOT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vivitrol",
  value: "Vivitrol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VOGELXO",
  value: "VOGELXO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VOLMA",
  value: "VOLMA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Voltaren",
  value: "Voltaren",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VOLTAREN--GEL",
  value: "VOLTAREN--GEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "vorapaxar",
  value: "vorapaxar",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Voriconazole",
  value: "Voriconazole",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "vortioxetine",
  value: "vortioxetine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VOSOL HC",
  value: "VOSOL HC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vraylar",
  value: "Vraylar",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VUMON",
  value: "VUMON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VUSION",
  value: "VUSION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "VYFEMLA",
  value: "VYFEMLA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vytorin",
  value: "Vytorin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Vyvanse",
  value: "Vyvanse",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WAL-ZYR",
  value: "WAL-ZYR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WAL-ZYR D",
  value: "WAL-ZYR D",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WAMPOCAP",
  value: "WAMPOCAP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Warfarin",
  value: "Warfarin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Welchol",
  value: "Welchol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WELLBUTRIN",
  value: "WELLBUTRIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WELLBUTRIN SR",
  value: "WELLBUTRIN SR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Wellbutrin SR, Wellbutrin XL",
  value: '"Wellbutrin SR, Wellbutrin XL"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WELLBUTRIN XL",
  value: "WELLBUTRIN XL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WERA",
  value: "WERA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WESTADONE",
  value: "WESTADONE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WESTCORT",
  value: "WESTCORT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WIGRAINE",
  value: "WIGRAINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WOMEN'S ROGAINE",
  value: "WOMEN'S ROGAINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WYAMYCIN E",
  value: "WYAMYCIN E",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WYAMYCIN S",
  value: "WYAMYCIN S",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "WYMOX",
  value: "WYMOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "XANAX",
  value: "XANAX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "XANAX XR",
  value: "XANAX XR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "XARELTO",
  value: "XARELTO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Xeljanz",
  value: "Xeljanz",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "XELODA",
  value: "XELODA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "XERESE",
  value: "XERESE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "XIFAXAN",
  value: "XIFAXAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "XOLEGEL",
  value: "XOLEGEL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Xopenex",
  value: "Xopenex",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Xopenex HFA",
  value: "Xopenex HFA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "X-VIATE--CREAM",
  value: "X-VIATE--CREAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "XYLOCAINE",
  value: "XYLOCAINE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "XYLOCAINE VISCOUS",
  value: "XYLOCAINE VISCOUS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Xyrem",
  value: "Xyrem",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Xyzal",
  value: "Xyzal",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "YASMIN",
  value: "YASMIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "YAZ",
  value: "YAZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "yobir",
  value: "yobir",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "zafirlukast",
  value: "zafirlukast",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZAGAM",
  value: "ZAGAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "zaleplon",
  value: "zaleplon",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZANAFLEX",
  value: "ZANAFLEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZANIDIP",
  value: "ZANIDIP",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZANTAC",
  value: "ZANTAC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZARONTIN",
  value: "ZARONTIN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZAROXOLYN",
  value: "ZAROXOLYN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZAXOPAM",
  value: "ZAXOPAM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zeaxanthin",
  value: "Zeaxanthin",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zebeta",
  value: "Zebeta",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZEGERID",
  value: "ZEGERID",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZEGERID OTC",
  value: "ZEGERID OTC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "zenpep",
  value: "zenpep",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zenzedi",
  value: "Zenzedi",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zerit",
  value: "Zerit",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zestoretic",
  value: "Zestoretic",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZESTRIL",
  value: "ZESTRIL",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZETIA",
  value: "ZETIA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Ziagen",
  value: "Ziagen",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZIDE",
  value: "ZIDE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zidovudine",
  value: "Zidovudine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "zileuton",
  value: "zileuton",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zinc",
  value: "Zinc",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label:
   "ZINC BACITRACIN,NEOMYCIN SULFATE,POLYMYXIN B SULFATE & HYDROCORTISONE",
  value:
   '"ZINC BACITRACIN,NEOMYCIN SULFATE,POLYMYXIN B SULFATE & HYDROCORTISONE"',
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZINC OXIDE--OINTMENT",
  value: "ZINC OXIDE--OINTMENT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZINGO",
  value: "ZINGO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZIOPTAN--EYE DROPS",
  value: "ZIOPTAN--EYE DROPS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ziprasidone",
  value: "ziprasidone",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zipsor",
  value: "Zipsor",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZIRGAN",
  value: "ZIRGAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZITHROMAX",
  value: "ZITHROMAX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZMAX",
  value: "ZMAX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOCOR",
  value: "ZOCOR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOFRAN",
  value: "ZOFRAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOFRAN AND DEXTROSE IN PLASTIC CONTAINER",
  value: "ZOFRAN AND DEXTROSE IN PLASTIC CONTAINER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOFRAN INJECTION",
  value: "ZOFRAN INJECTION",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOFRAN ODT",
  value: "ZOFRAN ODT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOFRAN PRESERVATIVE FREE",
  value: "ZOFRAN PRESERVATIVE FREE",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "zolmitriptan",
  value: "zolmitriptan",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zoloft",
  value: "Zoloft",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zolpidem",
  value: "Zolpidem",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOLPIMIST",
  value: "ZOLPIMIST",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOMIG",
  value: "ZOMIG",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zomig Nasal Spray",
  value: "Zomig Nasal Spray",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOMIG-ZMT",
  value: "ZOMIG-ZMT",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZONALON",
  value: "ZONALON",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZONEGRAN",
  value: "ZONEGRAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zonisamide",
  value: "Zonisamide",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zontivity",
  value: "Zontivity",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZORTRESS",
  value: "ZORTRESS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZORVOLEX",
  value: "ZORVOLEX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOSTAVAX",
  value: "ZOSTAVAX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zoster Vaccine",
  value: "Zoster Vaccine",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOTRIM",
  value: "ZOTRIM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOVIA 1/35E-21",
  value: "ZOVIA 1/35E-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOVIA 1/35E-28",
  value: "ZOVIA 1/35E-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOVIA 1/50E-21",
  value: "ZOVIA 1/50E-21",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOVIA 1/50E-28",
  value: "ZOVIA 1/50E-28",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZOVIRAX",
  value: "ZOVIRAX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "zovirax--cream",
  value: "zovirax--cream",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "zuclopenthixol",
  value: "zuclopenthixol",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZUPLENZ",
  value: "ZUPLENZ",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZUTRIPRO",
  value: "ZUTRIPRO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZYBAN",
  value: "ZYBAN",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZYFLO",
  value: "ZYFLO",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZYFLO CR",
  value: "ZYFLO CR",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "Zyhydro ER",
  value: "Zyhydro ER",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZYLOPRIM",
  value: "ZYLOPRIM",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZYPREXA",
  value: "ZYPREXA",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZYPREXA RELPREVV",
  value: "ZYPREXA RELPREVV",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "zyprexa relprew",
  value: "zyprexa relprew",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZYPREXA ZYDIS",
  value: "ZYPREXA ZYDIS",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZYRTEC",
  value: "ZYRTEC",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZYRTEC-D",
  value: "ZYRTEC-D",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
 {
  label: "ZYVOX",
  value: "ZYVOX",
  name: "isIDgNeuropsychotropicMedicationsCurrent",
 },
];
const isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplementValues = [
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Acetaminophen",
  value: "Acetaminophen",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Alcoholic Beverages",
  value: "Alcoholic Beverages",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Aspirin",
  value: "Aspirin",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Beta-naphthoflavone",
  value: "Beta-naphthoflavone",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Broccoli",
  value: "Broccoli",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Brussel Sprouts",
  value: "Brussel Sprouts",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Cannabinoids",
  value: "Cannabinoids",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Cannabis",
  value: "Cannabis",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Cannabis sativa",
  value: "Cannabis sativa",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Char-grilled meat",
  value: "Char-grilled meat",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Cimetidine",
  value: "Cimetidine",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Cocaine",
  value: "Cocaine",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Diethyldithiocarbamate",
  value: "Diethyldithiocarbamate",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Diphenhydramine",
  value: "Diphenhydramine",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Ginseng",
  value: "Ginseng",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Goldenseal",
  value: "Goldenseal",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Grapefruit juice",
  value: "Grapefruit juice",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Ibuprofen",
  value: "Ibuprofen",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Marijuana",
  value: "Marijuana",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Methylcholanthrene",
  value: "Methylcholanthrene",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Naproxen",
  value: "Naproxen",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Omeprazole",
  value: "Omeprazole",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Quercetin",
  value: "Quercetin",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "St. John's wort",
  value: "St. John's wort",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Starfruit",
  value: "Starfruit",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "THC",
  value: "THC",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Tobacco",
  value: "Tobacco",
 },
 {
  name: "isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement",
  label: "Vitamins",
  value: "Vitamins",
 },
];

const OrganizationSignUpFormTestTypeCheckboxes = {
 checkboxes: [
  {
   label: "DecisionDx-Melanoma",
   name: "cm",
   tooltip: `DecisionDx-Melanoma: Predicts Risk Of Recurrence Or Metastasis In Patients With Cutaneous Melanoma <br/>DecisionDx-CMSeq: Next-Generation Sequencing (NGS) To Identify Hotspot Mutations In The Genes BRAF, NRAS and KIT`,
  },
  {
   label: "DecisionDx-SCC",
   name: "scc",
   tooltip: `Predicts Risk Of Metastasis In Patients With One Or More Risk Factors In Squamous Cell Carcinoma`,
  },
  {
   label: "DecisionDx-UM",
   name: "um",
   tooltip: `DecisionDx-UM: Predicts Risk Of Metastasis In Uveal Melanoma Patients <br/>DecisionDx-UMSeq: Next-Generation Sequencing (NGS) To Identify Hotspot Mutations In The Genes GNAQ, GNA11, CYSLTR2, PLCB4 and SF3B1, Mutations In Exons 1-2 Of EIF1AX and All Coding Exons Of The BAP1 Gene`,
  },
  {
   label: "TissueCypher",
   name: "tc",
   tooltip: `Predicts risk of progression to esophageal cancer in Barrett’s esophagus patients`,
  },
 ],
 checkbox2: {
  label: "MyPath Melanoma",
  name: "ddm",
  tooltip: `Diagnostic Test For Ambiguous Melanocytic Lesions`,
 },
 checkbox3: {
  label: "IDgenetix",
  name: "idg",
  tooltip: `Comprehensive genomic analysis to help match patients with the right medications and identifies drug-drug interactions to improve medication response and remission rates`,
 },
};
const SideLinks = [
 {
  to: "/dashboard",
  title: "Dashboard",
  iconName: "dashboard",
 },
 {
  to: "/test-orders",
  title: "test orders",
  iconName: "testorders",
 },
 {
  to: "/referral-orders",
  title: "Referral orders",
  iconName: "testorders",
 },
 {
  to: "/approvals",
  title: "approvals",
  iconName: "approvals",
 },
 {
  to: "/documents",
  title: "documents",
  iconName: "PDF",
 },
 {
  to: "/shipments",
  title: "shipments",
  iconName: "shipment",
 },
 {
  to: "/manage",
  title: "manage",
  iconName: "manage",
 },
 {
  to: "/profile",
  title: "profile",
  iconName: "profile",
 },
];
const testOrdersGroupColumnsList = [
 {
  label: "Patient First Name",
  selected: false,
  key: "patientFirstName",
 },
 {
  label: "Patient Last Name",
  selected: false,
  key: "patientLastName",
 },
 {
  label: "Patient DOB",
  selected: false,
  key: "patientDOB",
 },
 {
  label: "Order Type",
  selected: false,
  key: "orderType",
 },
 {
  label: "Test Type",
  selected: false,
  key: "testTypeCode",
 },
 {
  label: "Castle ID",
  selected: false,
  key: "castle_id",
 },
 {
  label: "Specimen ID",
  selected: false,
  key: "specimen_id",
 },
 {
  label: "Org",
  selected: false,
  key: "organizationCode",
 },
 {
  label: "Clinician",
  selected: false,
  key: "physicianCode",
 },
 {
  label: "PathLab",
  selected: false,
  key: "pathlabId",
 },
 {
  label: "Placed By",
  selected: false,
  key: "placedBy",
 },
 {
  label: "Ordered Date",
  selected: false,
  key: "orderDate",
 },
 //  {
 //   label: "Ordered Date",
 //   selected: false,
 //   key: "receivedDate",
 //  },
 {
  label: "Report Date",
  selected: false,
  key: "reportDate",
 },
 {
  label: "Castle Result",
  selected: false,
  key: "castleResult",
 },
 {
  label: "Status",
  selected: false,
  key: "status",
 },
];

let genderData = [
 { label: "Male", value: "male" },
 { label: "Female", value: "female" },
];

const SCCQuestions = [
 {
  questionId: 1,
  label: "History and Physical Exam",
  tooltip:
   "Select at least one high risk feature from History and Physician Exam or Pathology and Surgical Findings list",
  name: "history_physical_exam",
  options: [
   {
    id: 1,
    label: "Tumor size≥2cm anywhere on the body",
    value: "≥2cm size (largest diameter)",
    name: "history_physical_exam",
    customLabelHTML: "",
   },
   {
    id: 2,
    label:
     "Tumor location on the head, neck, hands, genitals, feet or pretibial surface <br/> (areas H or M)",
    value: "Located on Areas H or M",
    name: "history_physical_exam",
    customLabelHTML: `Tumor location on the head, neck, hands, genitals, feet or pretibial surface <br/> <em class="text-xs">(areas H or M)</em>`,
   },
   {
    id: 3,
    label: "Immunosuppression",
    value: "Immuno-suppressed patient",
    name: "history_physical_exam",
   },
   {
    id: 4,
    label: "Rapidly growing tumor",
    value: "Rapidly growing tumor",
    name: "history_physical_exam",
   },
   {
    id: 5,
    label: "Tumor with poorly defined borders",
    value: "Borders poorly defined",
    name: "history_physical_exam",
   },
   {
    id: 6,
    label: "Tumor at site of prior radiation therapy or chronic inflammation",
    value:
     "Tumor at site of prior radiation therapy or chronic inflammatory process",
    name: "history_physical_exam",
   },
   {
    id: 7,
    label: "Neurologic symptoms in region of tumor",
    value: "Neurological symptoms in tumor region",
    name: "history_physical_exam",
   },

   // {
   //   id: 9,
   //   label: 'Other',
   //   name: 'history_physical_exam'
   // }
  ],
 },
 {
  questionId: 2,
  label: "Surgical and Pathology Findings",
  name: "pathology_surgical_findings",
  tooltip:
   "Select at least one high risk feature from History and Physician Exam or Pathology and Surgical Findings list.",

  options: [
   {
    id: 1,
    label:
     "Perineural involvement (Large (≥0.1 mm) or named nerve involvement; Small (<0.1 mm) in caliber)",
    customLabelHTML: `Perineural involvement <br/> <em class="text-xs">(Large (≥0.1 mm) or named nerve involvement; Small (<0.1 mm) in caliber)</em>`,
    value: "Perineural invasion",
    name: "pathology_surgical_findings",
    checked: false,
   },
   {
    id: 2,
    label: "Poorly differentiated tumor histology",
    value: "Poorly differentiated",
    name: "pathology_surgical_findings",
    checked: false,
   },
   {
    id: 3,
    label:
     "Depth (Invasive beyond subcutaneous fat or Invasion beyond 2mm or Clark Level IV)",
    customLabelHTML: `Depth <br/> <em class="text-xs">(Invasive beyond subcutaneous fat or Invasion beyond 2mm or Clark Level IV)</em>`,
    value: "Depth: Invasive beyond subcutaneous fat or ≥Clark Level IV or >2mm",
    name: "pathology_surgical_findings",
    checked: false,
   },
   {
    id: 4,
    label: "Aggressive histologic subtype <sup>A</sup>",
    value: "Specific high-risk sub-types",
    name: "pathology_surgical_findings",
    checked: false,
   },
   {
    id: 5,
    label: "Lymphovascular invasion",
    value: "Lymphatic or vascular involvement",
    name: "pathology_surgical_findings",
    checked: false,
   },
   {
    id: 6,
    label: "Desmoplastic SCC",
    value: "Desmoplastic SCC",
    name: "pathology_surgical_findings",
    checked: false,
   },
  ],
 },
];

const TimeData = [
 { label: "12:00 AM", value: "00:00:00" },
 { label: "12:30 AM", value: "00:30:00" },
 { label: "1:00 AM", value: "01:00:00" },
 { label: "1:30 AM", value: "01:30:00" },
 { label: "2:00 AM", value: "02:00:00" },
 { label: "2:30 AM", value: "02:30:00" },
 { label: "3:00 AM", value: "03:00:00" },
 { label: "3:30 AM", value: "03:30:00" },
 { label: "4:00 AM", value: "04:00:00" },
 { label: "4:30 AM", value: "04:30:00" },
 { label: "5:00 AM", value: "05:00:00" },
 { label: "5:30 AM", value: "05:30:00" },
 { label: "6:00 AM", value: "06:00:00" },
 { label: "6:30 AM", value: "06:30:00" },
 { label: "7:00 AM", value: "07:00:00" },
 { label: "7:30 AM", value: "07:30:00" },
 { label: "8:00 AM", value: "08:00:00" },
 { label: "8:30 AM", value: "08:30:00" },
 { label: "9:00 AM", value: "09:00:00" },
 { label: "9:30 AM", value: "09:30:00" },
 { label: "10:00 AM", value: "10:00:00" },
 { label: "10:30 AM", value: "10:30:00" },
 { label: "11:00 AM", value: "11:00:00" },
 { label: "11:30 AM", value: "11:30:00" },
 { label: "12:00 PM", value: "12:00:00" },
 { label: "12:30 PM", value: "12:30:00" },
 { label: "1:00 PM", value: "13:00:00" },
 { label: "1:30 PM", value: "13:30:00" },
 { label: "2:00 PM", value: "14:00:00" },
 { label: "2:30 PM", value: "14:30:00" },
 { label: "3:00 PM", value: "15:00:00" },
 { label: "3:30 PM", value: "15:30:00" },
 { label: "4:00 PM", value: "16:00:00" },
 { label: "4:30 PM", value: "16:30:00" },
 { label: "5:00 PM", value: "17:00:00" },
 { label: "5:30 PM", value: "17:30:00" },
 { label: "6:00 PM", value: "18:00:00" },
 { label: "6:30 PM", value: "18:30:00" },
 { label: "7:00 PM", value: "19:00:00" },
 { label: "7:30 PM", value: "19:30:00" },
 { label: "8:00 PM", value: "20:00:00" },
 { label: "8:30 PM", value: "20:30:00" },
 { label: "9:00 PM", value: "21:00:00" },
 { label: "9:30 PM", value: "21:30:00" },
 { label: "10:00 PM", value: "22:00:00" },
 { label: "10:30 PM", value: "22:30:00" },
 { label: "11:00 PM", value: "23:00:00" },
 { label: "11:30 PM", value: "23:30:00" },
];

const TimeKey: any = {
 "00:00:00": "12:00 AM",
 "00:30:00": "12:30 AM",
 "01:00:00": "1:00 AM",
 "01:30:00": "1:30 AM",
 "02:00:00": "2:00 AM",
 "02:30:00": "2:30 AM",
 "03:00:00": "3:00 AM",
 "03:30:00": "3:30 AM",
 "04:00:00": "4:00 AM",
 "04:30:00": "4:30 AM",
 "05:00:00": "5:00 AM",
 "05:30:00": "5:30 AM",
 "06:00:00": "6:00 AM",
 "06:30:00": "6:30 AM",
 "07:00:00": "7:00 AM",
 "07:30:00": "7:30 AM",
 "08:00:00": "8:00 AM",
 "08:30:00": "8:30 AM",
 "09:00:00": "9:00 AM",
 "09:30:00": "9:30 AM",
 "10:00:00": "10:00 AM",
 "10:30:00": "10:30 AM",
 "11:00:00": "11:00 AM",
 "11:30:00": "11:30 AM",
 "12:00:00": "12:00 PM",
 "12:30:00": "12:30 PM",
 "13:00:00": "1:00 PM",
 "13:30:00": "1:30 PM",
 "14:00:00": "2:00 PM",
 "14:30:00": "2:30 PM",
 "15:00:00": "3:00 PM",
 "15:30:00": "3:30 PM",
 "16:00:00": "4:00 PM",
 "16:30:00": "4:30 PM",
 "17:00:00": "5:00 PM",
 "17:30:00": "5:30 PM",
 "18:00:00": "6:00 PM",
 "18:30:00": "6:30 PM",
 "19:00:00": "7:00 PM",
 "19:30:00": "7:30 PM",
 "20:00:00": "8:00 PM",
 "20:30:00": "8:30 PM",
 "21:00:00": "9:00 PM",
 "21:30:00": "9:30 PM",
 "22:00:00": "10:00 PM",
 "22:30:00": "10:30 PM",
 "23:00:00": "11:00 PM",
 "23:30:00": "11:30 PM",
};

const facilityTypeOptions = [
 {
  id: 1,
  label: "Medical Office",
  value: "Medical Office",
  selected: false,
 },
 {
  id: 2,
  label: "Long Term Care Unit",
  value: "Long Term Care Unit",
  selected: false,
 },
 {
  id: 3,
  label: "Skilled Nursing Unit",
  value: "Skilled Nursing Unit",
  selected: false,
 },
 {
  id: 4,
  label: "Patient's Home",
  value: "Patient's Home",
  selected: false,
 },
 {
  id: 5,
  label: "Inpatient Hospital",
  value: "Inpatient Hospital",
  selected: false,
 },
];

const TestsData = [
 {
  type: "PROGNOSTIC TESTS",
  tests: OrganizationSignUpFormTestTypeCheckboxes.checkboxes,
 },
 {
  type: "DIAGNOSTIC TESTS",
  tests: [OrganizationSignUpFormTestTypeCheckboxes.checkbox2],
 },
 {
  type: "THERAPY SELECTION TESTS",
  tests: [OrganizationSignUpFormTestTypeCheckboxes.checkbox3],
 },
];

function updateLabelsWithSuperscript(arr: any) {
 return arr.map((item: any) => {
  const updatedLabel = item.label.replace(
   /®/g,
   `<sup style={{ verticalAlign: 'super', marginTop:'10px' }}>®</sup>`
  );
  return { ...item, label: updatedLabel };
 });
}

const testAndIcdCodesData = [
 {
  disease: "BARRETT’S ESOPHAGUS",
  tests: [
   {
    id: 1,
    checked: false,
    label:
     "TissueCypher: Predicts risk of progression to esophageal cancer in Barrett’s esophagus patients",
    name:
     "TissueCypher: Predicts risk of progression to esophageal cancer in Barrett’s esophagus patients",
    code: "TC",
    imageUrl:
     "https://cbslocal01.blob.core.windows.net/logo-images/tissuecypher-barrett's%20Esophagus.png",
    groupId: null,
    testTypes: [],
    icdCodes: [
     {
      id: 1,
      name: "BE without dysplasia",
      value: "K22.70",
      testId: 1,
     },
     {
      id: 2,
      name: "BE with low grade dysplasia",
      value: "K22.710",
      testId: 1,
     },
     {
      id: 3,
      name: "BE with dysplasia, unspecified",
      value: "K22.719",
      testId: 1,
     },
    ],
    data: [
     {
      id: 1,
      checked: false,
      label:
       "TissueCypher: Predicts risk of progression to esophageal cancer in Barrett’s esophagus patients",
      name:
       "TissueCypher: Predicts risk of progression to esophageal cancer in Barrett’s esophagus patients",
      code: "TC",
      imageUrl:
       "https://cbslocal01.blob.core.windows.net/logo-images/tissuecypher-barrett's%20Esophagus.png",
      groupId: null,
      testTypes: [],
      icdCodes: [
       {
        id: 1,
        name: "BE without dysplasia",
        value: "K22.70",
        testId: 1,
       },
       {
        id: 2,
        name: "BE with low grade dysplasia",
        value: "K22.710",
        testId: 1,
       },
       {
        id: 3,
        name: "BE with dysplasia, unspecified",
        value: "K22.719",
        testId: 1,
       },
      ],
     },
    ],
   },
  ],
 },
 {
  disease: "SKIN",
  tests: [
   {
    id: 2,
    name:
     "DecisionDx-SCC: Predicts risk of metastasis in patients with one or more risk factors in Squamous Cell Carcinoma",
    code: "SCC",
    imageUrl:
     "https://cbslocal01.blob.core.windows.net/logo-images/DecisionDx-SCC-Screen-Logo.png",
    groupId: null,
    testTypes: [],
    icdCodes: [
     {
      id: 4,
      name: "Squamous cell carcinoma of skin of lip",
      value: "C44.02",
      testId: 2,
     },
    ],
    data: [
     {
      id: 2,
      checked: false,
      label:
       "DecisionDx-SCC: Predicts risk of metastasis in patients with one or more risk factors in Squamous Cell Carcinoma",
      name:
       "DecisionDx-SCC: Predicts risk of metastasis in patients with one or more risk factors in Squamous Cell Carcinoma",
      code: "SCC",
      imageUrl:
       "https://cbslocal01.blob.core.windows.net/logo-images/DecisionDx-SCC-Screen-Logo.png",
      groupId: null,
      testTypes: [],
      icdCodes: [
       {
        id: 4,
        name: "Squamous cell carcinoma of skin of lip",
        value: "C44.02",
        testId: 2,
       },
      ],
     },
    ],
   },
  ],
 },
];

const NotificationsData: NotificationsDataModel[] = [
 {
  date: moment().toDate(),
  list: [
   {
    contentType: "order screen",
    message:
     "Tissue Cypher test of Imtanan Aziz against Shaukat khanam org  has been received",
    time: moment().toDate(),
    isNew: true,
    iconURL:
     "https://cbslocal01.blob.core.windows.net/logo-images/tissuecypher-barrett's%20Esophagus.png",
   },
   {
    contentType: "order screen",
    message:
     "Tissue Cypher test of Imtanan Aziz against Shaukat khanam org  has been received",
    time: moment().subtract(5, "hours").toDate(),
    isNew: false,
    iconURL:
     "https://cbslocal01.blob.core.windows.net/logo-images/tissuecypher-barrett's%20Esophagus.png",
   },
  ],
 },
 {
  date: moment().subtract(1, "day").toDate(),
  list: [
   {
    contentType: "approvals",
    message:
     "Tissue Cypher test of Imtanan Aziz against Shaukat khanam org  has been received",
    time: moment().subtract(1, "day").toDate(),
    isNew: false,
    iconURL:
     "https://cbslocal01.blob.core.windows.net/logo-images/tissuecypher-barrett's%20Esophagus.png",
   },
   {
    contentType: "approvals",
    message:
     "Tissue Cypher test of Imtanan Aziz against Shaukat khanam org  has been received",
    time: moment().subtract(1, "day").toDate(),
    isNew: false,
    iconURL:
     "https://cbslocal01.blob.core.windows.net/logo-images/tissuecypher-barrett's%20Esophagus.png",
   },
  ],
 },
];

const daysSuggestions = [
 { label: "Today", value: moment().format("YYYY-MM-DD"), selected: false },
 {
  label: "Last 7 Days",
  value: moment().subtract(7, "days").format("YYYY-MM-DD"),
  selected: false,
 },
 {
  label: "Last 14 Days",
  value: moment().subtract(14, "days").format("YYYY-MM-DD"),
  selected: false,
 },
 {
  label: "Last 28 Days",
  value: moment().subtract(28, "days").format("YYYY-MM-DD"),
  selected: false,
 },
 {
  label: "Last 90 Days",
  value: moment().subtract(90, "days").format("YYYY-MM-DD"),
  selected: false,
 },
 {
  label: "Last Year",
  value: moment().subtract(1, "year").format("YYYY-MM-DD"),
  selected: false,
 },
];

const dataConstants = {
 insuranceList,
 ICD10CodeList,
 TimeKey,
 isAltheaDxNeuropsychotropicMedicationsValues: updateLabelsWithSuperscript(
  isAltheaDxNeuropsychotropicMedicationsValues
 ),
 isIDgNeuropsychotropicMedicationsValues: updateLabelsWithSuperscript(
  isIDgNeuropsychotropicMedicationsValues
 ),
 isIDgNeuropsychotropicMedicationsCurrentValues: updateLabelsWithSuperscript(
  isIDgNeuropsychotropicMedicationsCurrentValues
 ),
 isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplementValues,
 OrganizationSignUpFormTestTypeCheckboxes,
 SideLinks,
 testOrdersGroupColumnsList,
 genderData,
 SCCQuestions,
 facilityTypeOptions,
 TestsData,
 testAndIcdCodesData,
 NotificationsData,
 TimeData,
 daysSuggestions,
};

export default dataConstants;
