/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable comma-dangle */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/semi */
import moment from "moment";
import OrderFormValues from "../../models/forms/onlineorderformvalues";
import { OnlineOrderOptions } from "../../models/forms/online_order_options.interface";
import orderResponseFormatter from "../../utils/helpers/orderResponseFormatter";
import http from "../API/HttpMethods";
import {
 ADD_SECTION,
 FETCH_PATIENTS,
 FETCH_PATIENTS_ERROR,
 FETCH_PATIENTS_SUCCESS,
 FETCH_TESTS_ICD_CODES,
 FETCH_TESTS_ICD_CODES_ERROR,
 FETCH_TESTS_ICD_CODES_SUCCESS,
 REMOVE_SECTION,
 RESET_SELECTED_TEST,
 RESET_SAVE_ORDER,
 TOGGLE_MODE,
 UPDATE_SELECTED_TEST,
 UPDATE_SELECTED_ORGANIZATION,
 UPDATE_SELECTED_PHYSICANS,
 FETCHING_INSURANCE_DATA,
 FETCHING_INSURANCE_ERROR,
 FETCHING_INSURANCE_SUCCESS,
 CREATE_ONLINE_ORDER,
 CREATE_ONLINE_ORDER_ERROR,
 CREATE_ONLINE_ORDER_SUCCESS,
 TOGGLE_FORM_SUBMIT,
 TOGGLE_CLINICAL_INFO_VALID,
 RESET_SELECTED_PHYSICIAN,
 FETCH_LABS,
 FETCH_LABS_ERROR,
 FETCH_LABS_SUCCESS,
 SAVE_ONLINE_ORDER,
 SAVE_ONLINE_ORDER_ERROR,
 SAVE_ONLINE_ORDER_SUCCESS,
 DISCARD_FORM,
 RESET_MODE,
 FETCH_ORDER_BY_ID_SUCCESS,
 FETCH_ORDER_BY_ID,
 FETCH_ORDER_BY_ID_ERROR,
 SET_MODE_EDIT,
 FETCH_LAB_BY_ID,
 FETCH_LAB_BY_ID_SUCCESS,
 FETCH_LAB_BY_ID_ERROR,
 ADD_NOTES,
 ADD_NOTES_SUCCESS,
 ADD_NOTES_ERROR,
 UPDATE_IS_PHYSICIAN_SIGN_PERMISSION,
 UPDATE_SELECTED_PATHLAB,
 RESET_SELECTED_PATHLAB,
 RETRY_ONLINE_ORDER,
 RETRY_ONLINE_ORDER_ERROR,
 RETRY_ONLINE_ORDER_SUCCESS,
} from "./types";
import { ActionArgs } from "./types";
import orderFormPayloadCreator from "../../utils/helpers/orderFormPayloadCreator";
import toastMessages from "../../utils/helpers/toastMessage";
export const updateSelectedTest = (payload: any): any => ({
 type: UPDATE_SELECTED_TEST,
 payload,
});
export const resetSelectedTest = (): any => ({ type: RESET_SELECTED_TEST });
export const resetSaveOrder = (): any => ({ type: RESET_SAVE_ORDER });
export const addSection = (payload: string): any => ({
 type: ADD_SECTION,
 payload,
});
export const removeSection = (payload: string): any => ({
 type: REMOVE_SECTION,
 payload,
});
export const toggleMode = (): any => ({ type: TOGGLE_MODE, payload: "" });
export const editMode = (): any => ({ type: SET_MODE_EDIT });
export const resetMode = (): any => ({ type: RESET_MODE });
export const fetchTestsAndIcdCodes =
 (options: any) => async (dispatch: any) => {
  dispatch({ type: FETCH_TESTS_ICD_CODES });

  const { orgId, config, onError, onSingleTest }: any = options;
  try {
   const result = await http.getTestsAndICDCode(
    // `/v1/test-type/new-flow?organizationId=${orgId}`,
    `/v1/test-type/get-active-tests-for-organization?organizationId=${orgId}`,
    config
   );

   const { data, statusCode, error } = result;

   if (error && onError) return onError(error);

   if (statusCode === 200) {
    dispatch({
     type: FETCH_TESTS_ICD_CODES_SUCCESS,
     payload: data,
    });

    if (data.length == 1 && data[0].testTypes?.length == 1 && !!onSingleTest) {
     const payload = {
      id: data[0]?.testTypes[0]?.id,
      checkedIndexes: [data[0]?.testTypes[0]?.id],
     };
     onSingleTest(payload);
    }
   }
  } catch (error) {
   dispatch({ type: FETCH_TESTS_ICD_CODES_ERROR });
   if (onError) {
    onError(error);
   }
  }
 };
export const fetchPatients = (options: any) => async (dispatch: any) => {
 const { config, onError, orgId, name, onEmpty, onSuccess } = options;
 dispatch({ type: FETCH_PATIENTS });
 try {
  const { data, statusCode, error } = await http.getPatients(
   `v1/patients/new-flow?organizationId=${orgId}&name=${name}`,
   config
  );

  if (error && onError) return onError(error);

  if (statusCode === 200) {
   if (data.length === 0) {
    onEmpty();
   }
   dispatch({ type: FETCH_PATIENTS_SUCCESS, payload: data });
   onSuccess && onSuccess();
  }
 } catch (error: any) {
  dispatch({ type: FETCH_PATIENTS_ERROR });
  const { response } = error;
  if (response) {
   if (response.data.message) {
    onError(response.data.message);
   } else {
    onError(error.message ?? "Failed to create online order");
   }
  } else {
   onError(error.message ?? "Failed to create online order");
  }
 }
};
export const updateSelectedPhysician = (payload: string) => ({
 type: UPDATE_SELECTED_PHYSICANS,
 payload,
});
export const updateSelectedOrganization = (payload: string) => ({
 type: UPDATE_SELECTED_ORGANIZATION,
 payload,
});

export const updateSelectedPathLab = (payload: string) => ({
 type: UPDATE_SELECTED_PATHLAB,
 payload,
});
export const resetSelectedPathLab = (): any => ({
 type: RESET_SELECTED_PATHLAB,
});
export const resetSelectedPhysician = (): any => ({
 type: RESET_SELECTED_PHYSICIAN,
});

// .forEach((clinician:any)=>{
//   clinician.firstName=removeExtraSpace(clinician.firstName);
//   clinician.lastName=removeExtraSpace(clinician.lastName);
//  })
export const createOnlineOrder =
 (options: OnlineOrderOptions) => async (dispatch: any) => {
  const {
   data,
   selectedOrganization,
   selectedPhysician,
   config,
   onError,
   onSuccess,
  } = options;

  if (
   orderFormPayloadCreator(data, selectedOrganization, selectedPhysician)
    .isExposedToRadiation === null &&
   !!data.isSave === false &&
   data?.selectedTest?.id == 4
  ) {
   toastMessages.error(
    "Please select Yes or No for Exposed to Radiation field in order to Submit or Send the order"
   );
   return;
  }

  if (data?.isSave) {
   dispatch({ type: SAVE_ONLINE_ORDER });
  } else {
   dispatch({ type: TOGGLE_FORM_SUBMIT, payload: true });
   dispatch({ type: CREATE_ONLINE_ORDER });
  }

  try {
   const {
    message,
    error,
    data: responseData,
   } = await http.createOnlineOrder(
    "/v1/orders/new-flow/create",
    orderFormPayloadCreator(data, selectedOrganization, selectedPhysician),
    config
   );

   if (!!error) {
    if (data?.isSave) {
     dispatch({ type: SAVE_ONLINE_ORDER_ERROR });
    } else {
     dispatch({ type: CREATE_ONLINE_ORDER_ERROR });
     dispatch({ type: TOGGLE_FORM_SUBMIT, payload: false });
    }
    return onError(error ?? "Failed to create online order");
   }

   if (data?.isSave) {
    dispatch({ type: SAVE_ONLINE_ORDER_SUCCESS });
    onSuccess(message ?? "Online order saved successfully");
   } else {
    dispatch({ type: CREATE_ONLINE_ORDER_SUCCESS });
    dispatch({ type: TOGGLE_FORM_SUBMIT, payload: false });
    onSuccess(responseData?.status === 40 ? "submitted" : "send for approval");
    // onSuccess(message ?? "Online order created successfully");
   }
  } catch (error: any) {
   const { response } = error;
   if (response) {
    if (response.data.message) {
     onError(response.data.message);
    } else {
     onError(error.message ?? "Failed to create online order");
    }
   } else {
    onError(error.message ?? "Failed to create online order");
   }
   dispatch({ type: CREATE_ONLINE_ORDER_ERROR });
   dispatch({ type: SAVE_ONLINE_ORDER_ERROR });
   dispatch({ type: TOGGLE_FORM_SUBMIT, payload: false });
  }
 };
export const updateOnlineOrder =
 (options: OnlineOrderOptions) => async (dispatch: any) => {
  const {
   data,
   selectedOrganization,
   selectedPhysician,
   config,
   onError,
   onSuccess,
  } = options;

  if (
   orderFormPayloadCreator(data, selectedOrganization, selectedPhysician)
    .isExposedToRadiation === null &&
   !!data.isSave === false &&
   data?.selectedTest?.id == 4
  ) {
   toastMessages.error(
    "Please select Yes or No for Exposed to Radiation field in order to Submit or Send the order"
   );
   return;
  }

  if (data?.isSave) {
   dispatch({ type: SAVE_ONLINE_ORDER });
  } else {
   dispatch({ type: TOGGLE_FORM_SUBMIT, payload: true });
   dispatch({ type: CREATE_ONLINE_ORDER });
  }

  try {
   const {
    message,
    error,
    data: responseData,
   } = await http.updateOnlineOrder(
    `/v1/orders/new-flow/${data.orderId}`,
    orderFormPayloadCreator(data, selectedOrganization, selectedPhysician),
    config
   );

   if (!!error) {
    if (data?.isSave) {
     dispatch({ type: SAVE_ONLINE_ORDER_ERROR });
    } else {
     dispatch({ type: CREATE_ONLINE_ORDER_ERROR });
     dispatch({ type: TOGGLE_FORM_SUBMIT, payload: false });
    }
    return onError(error ?? "Failed to create online order");
   }

   if (data?.isSave) {
    dispatch({ type: SAVE_ONLINE_ORDER_SUCCESS });
    onSuccess(message ?? "Online order saved successfully");
   } else {
    dispatch({ type: CREATE_ONLINE_ORDER_SUCCESS });
    dispatch({ type: TOGGLE_FORM_SUBMIT, payload: false });
    // onSuccess(message ?? "Online order created successfully");
    onSuccess(responseData?.status === 40 ? "submitted" : "send for approval");
   }
  } catch (error: any) {
   const { response } = error;
   if (response) {
    if (response.data.message) {
     onError(response.data.message);
    } else {
     onError(error.message ?? "Failed to create online order");
    }
   } else {
    onError(error.message ?? "Failed to create online order");
   }
   dispatch({ type: CREATE_ONLINE_ORDER_ERROR });
   dispatch({ type: SAVE_ONLINE_ORDER_ERROR });
   dispatch({ type: TOGGLE_FORM_SUBMIT, payload: false });
  }
 };

export const retryOrder =
 (options: {
  data: any;
  config: any;
  onError: (props?: any) => void;
  onSuccess: (props?: any) => void;
 }) =>
 async (dispatch: any) => {
  const { data, config, onError, onSuccess } = options;

  dispatch({ type: RETRY_ONLINE_ORDER });

  try {
   const {
    message,
    error,
    data: responseData,
    statusCode,
   } = await http.retryOnlineOrder(
    `/v1/orders/new-flow/${data.orderId}`,
    orderFormPayloadCreator(data, data.organization_id, data.clinician_id),
    config
   );
   if (!!error) {
    dispatch({ type: RETRY_ONLINE_ORDER_ERROR });
    onError && onError();
    return;
   }
   if (statusCode === 200) {
    dispatch({ type: RETRY_ONLINE_ORDER_SUCCESS });
    onSuccess();
   }
  } catch (error: any) {
   dispatch({ type: RETRY_ONLINE_ORDER_ERROR });
   onError && onError(error?.message ? error?.message : "Retry Order failed");
  }
 };

export const fetchInsuranceCompany =
 (options: any) => async (dispatch: any) => {
  dispatch({ type: FETCHING_INSURANCE_DATA });

  const { config, onError }: any = options;
  try {
   const result = await http.getInsuranceCompany(
    "/v1/primary-insurance",
    config
   );
   const { data, statusCode, error } = result;
   if (error && onError) return onError(error);

   if (statusCode === 200) {
    dispatch({
     type: FETCHING_INSURANCE_SUCCESS,
     payload: data,
    });
   }
  } catch (error) {
   dispatch({ type: FETCHING_INSURANCE_ERROR });
   if (onError) {
    onError(error);
   }
  }
 };

export const toggleClinicalInfoValid = (payload: boolean): any => ({
 type: TOGGLE_CLINICAL_INFO_VALID,
 payload,
});

export const fetchLabs = (options: any) => async (dispatch: any) => {
 dispatch({ type: FETCH_LABS });

 const { search, config, onError, onSuccess }: any = options;
 try {
  const result = await http.getAllLabs(
   `/v1/pathology/new-flow/?search=${search}`,
   config
  );
  const { data, statusCode, error } = result;
  if (error && onError) return onError(error);

  if (statusCode === 200) {
   dispatch({ type: FETCH_LABS_SUCCESS, payload: data });
   onSuccess && onSuccess();
  }
 } catch (error) {
  dispatch({ type: FETCH_LABS_ERROR });
  if (onError) {
   onError(error);
  }
 }
};
export const fetchLabById = (options: any) => async (dispatch: any) => {
 const { id, onError, config, onSuccess } = options;
 dispatch({ type: FETCH_LAB_BY_ID });
 try {
  const result = await http.getLabById(`/v1/pathology/new-flow/${id}`, config);
  const { statusCode, message, data } = result;
  if (statusCode == 200) {
   dispatch({ type: FETCH_LAB_BY_ID_SUCCESS, payload: data });
   onSuccess(data);
  }
 } catch (error: any) {
  dispatch({ type: FETCH_LAB_BY_ID_ERROR });
  const { response } = error;
  if (response) {
   onError(response?.data?.message ?? "Failed to retrieve lab data");
  } else {
   onError(error.message ?? "Failed to retrieve lab data");
  }
 }
};
export const fetchOrderById = (options: any) => async (dispatch: any) => {
 const { id, isReferralOrder, onError, config, onSuccess } = options;
 dispatch({ type: FETCH_ORDER_BY_ID });
 try {
  const result = await http.getOrderById(
   `/v1/orders/new-flow/${id}${isReferralOrder ? "?isReferralOrder=true" : ""}`,
   config
  );
  const { data, statusCode, error } = result;
  if (error && onError) return onError(error);

  if (statusCode === 200) {
   dispatch({
    type: FETCH_ORDER_BY_ID_SUCCESS,
    // payload: data,
    payload: orderResponseFormatter(data),
   });
   if (onSuccess) {
    onSuccess(data?.organization?.id);
   }
  }
 } catch (error: any) {
  dispatch({ type: FETCH_ORDER_BY_ID_ERROR });
  const { response } = error;
  if (response) {
   if (response?.data?.message) {
    onError(response.data.message);
   } else {
    onError(error.message ?? "Failed to get order details");
   }
  } else {
   onError(error.message ?? "Failed to get order details");
  }
 }
};
export const addNotes = (options: any) => async (dispatch: any) => {
 const { data, onSuccess, onError, config } = options;
 dispatch({ type: ADD_NOTES });
 try {
  const { message, statusCode } = await http.addNotes(
   "/v1/orders/new-flow/add-notes",
   data,
   config
  );
  if (statusCode === 200) {
   dispatch({ type: ADD_NOTES_SUCCESS });
   onSuccess && onSuccess(message);
  }
 } catch (error: any) {
  const { response } = error;
  if (response) {
   if (response.data.message) {
    onError(response.data.message);
   } else {
    onError(error.message ?? "Failed to create online order");
   }
  } else {
   onError(error.message ?? "Failed to create online order");
  }
  dispatch({ type: ADD_NOTES_ERROR });
 }
};
export const handleDiscard = (): any => ({ type: DISCARD_FORM });
export const updatePhysicianSignCheck = (payload: boolean) => ({
 type: UPDATE_IS_PHYSICIAN_SIGN_PERMISSION,
 payload,
});
