import { AnimatePresence, motion, MotionConfig } from 'framer-motion'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import ProfileScreenLayout from '../../../Containers/ProfileLayout'
import ButtonStateActions from '../../../Store/Actions/ButtonStateActions'
import ProfileActions from '../../../Store/Actions/ProfileActions'
import { useOktaAuth } from '@okta/okta-react'
import Button from '../../../components/atomic/Button'
import { useHistory } from 'react-router-dom'
import CustomImage from '../../../components/global/CustomImage'
import UserDetailLoadingIndicator from '../../../components/global/LoadingIndicators/UserDetailLoadingIndicator'
import { ITableHeaderProps } from '../../../components/DataTableHeader/DataTableHeader'
import DataTable, { DataTableState } from '../../../components/DataTable/DataTable'
import OrganizationPillBox from '../../../components/global/OrganizationPillBox'
import OrganizationWithRoleBox from '../../../components/global/OrganizationWithRoleBox'
import AssociatedOrganisationsAndRolesTable from '../../../components/global/Tables/AssociatedOrganisationsAndRolesTable'
import SOFFlow from '../../../components/organism/SofFlow'
import DropdownSOF from '../../../components/DataTable/DropdownSOF'
import RequiredFieldTooltip from '../../../components/global/RequiredFieldTooltip'
import physicianToClinician from '../../../utils/helpers/physicianToClinician'
import UserSOFTable from '../../../components/global/Tables/UserSOFTable'
import AssociatedPathLabAndRolesTable from '../../../components/global/Tables/AssociatedPathLabAndRolesTable'
import toastMessages from '../../../utils/helpers/toastMessage'


function ProfileHomeScreen({ screen, headerTitle, setButtonState, fetchProfile, fetching, error, profile, tableData, sofTable, orderAgreementTable, fetchOrderAgreementOfUser }: any) {
    const { authState } = useOktaAuth()
    const history = useHistory()
    const { data: states, error: statesError } = useSelector((state: any) => state.StateAndCity)
    const [sofState, setSofState] = useState<DataTableState>({
        sofType: 'create',
        sofVisible: false,
        item: null
    })
    function SOFClick({ type, item }: { type: 'create' | 'update' | 'view' | 'delete' | 'update-req-pending', item: any }) {
        setSofState({ sofVisible: true, sofType: type, item })
    }
    function changePassword() {
        history.push('/forget-password', { replace: true })
    }

    let keys: any

    if (profile && Array.isArray(profile.roles) && profile.roles.includes('Physician')) {
        keys = {
            // firstName: 'First Name',
            // lastName: 'Last Name',
            firstName: 'Full Name',
            email: 'Email ID',
            roles: 'Role',
            // primaryPhone: 'Phone',
            // fax: 'Fax',
            // city: 'City',
            // zipCode: 'ZIP Code',
            // state: 'State/Province',
            // streetAddress: 'Address',
            suffix: 'Suffix',
            npi: 'NPI',
            specialty: 'Speciality',
            signPermission: 'Sign Permission'
        }
    } else {
        keys = {
            // firstName: 'First Name',
            // lastName: 'Last Name',
            firstName: 'Full Name',
            email: 'Email ID',
            roles: 'Role',
            // primaryPhone: 'Phone',
            // fax: 'Fax',
            // city: 'City',
            // zipCode: 'ZIP Code',
            // state: 'State/Province',
            // streetAddress: 'Address'
        }
    }

    useEffect(() => {
        setButtonState('my profile')
        let options = {
            accessToken: authState?.accessToken?.accessToken,
            onSuccess: () => { console.log('success') },
            onError: (error: any) => { console.log(error) }
        }
        fetchProfile(options);
    }, [])

    useEffect(() => {
        if (profile?.roles?.includes('Collaborative Order Agreement') && !fetching) {
            fetchOrderAgreementOfUser(profile?.id,
                {
                    headers: {
                        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                    },
                }, (message: string) => {
                    // history.replace('/manage/portal-users')
                    toastMessages.error(message)
                });
        }
    }, [profile?.user?.roles, fetching])

    if (fetching && !error) { return <UserDetailLoadingIndicator showTable={false} /> }

    if (!fetching && error) { return <div><p>Something went wrong...</p></div> }

    return <ProfileScreenLayout
        headerTitle={headerTitle}
        screen={screen}>

        <AnimatePresence exitBeforeEnter >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className={'col-span-5'}
            >
                <p className="text-royalBlue p-5 font-semibold !font-xs">
                    User Detail
                </p>

                <div className="mx-auto mx-5">
                    <div className="flex">
                        {/* Left */}
                        <CustomImage src={profile.imageUrl}
                            className="object-fit w-[7rem] h-[7rem] rounded-full"
                            alt="User image" />
                        {/* Right */}
                        <div className="ml-5">
                            <div className="text-gray-700">
                                <div className="flex flex-wrap">
                                    {
                                        Object.entries(keys).map(([field, value]: any) => {
                                            if (field == 'firstName') {
                                                return (
                                                    <div key={field} className="mr-6">
                                                        <p className="text-royalBlue text-xs font-semibold my-2">{value}</p>
                                                        <p className="text-royalBlue text-base">{profile?.firstName + " " + profile?.lastName}</p>
                                                    </div>
                                                )
                                            }
                                            if (field === 'signPermission') {
                                                return <div key={field} className="mr-6">
                                                    <p className="text-royalBlue text-xs font-semibold my-2">{value}</p>
                                                    <p className="text-royalBlue text-base">{profile?.sof?.id ? 'Yes' : 'No'}</p>
                                                </div>
                                            }
                                            if (field === 'state') {
                                                return <div key={field} className="mr-6">
                                                    <p className="text-royalBlue text-xs font-semibold my-2">{value}</p>
                                                    <p className="text-royalBlue text-base">
                                                        {states && states.length > 0 && states.find((state: any) => state?.state_code === profile[field])?.name ? states.find((state: any) => state?.state_code === profile[field])?.name : '---'}
                                                    </p>
                                                </div>
                                            }
                                            if (field === 'roles' && profile?.roles?.length > 1) {
                                                return <div key={field} className="mr-6">
                                                    <p className="text-royalBlue text-xs font-semibold my-2">{value}</p>
                                                    {/* <p className="text-royalBlue text-base">{profile?.roles[0] + ', ' + (profile?.roles[1]?.includes('Physician') && 'Clinician')}</p> */}
                                                    <p className="text-royalBlue text-base">
                                                        {profile?.roles && physicianToClinician(profile?.roles)?.map((role: string, index: number) => `${role}${index + 1 !== profile?.roles?.length ? ', ' : ''}`)}
                                                    </p>
                                                </div>
                                            }
                                            return (
                                                <div key={field} className="mr-6">
                                                    <p className="text-royalBlue text-xs font-semibold my-2">{value}</p>
                                                    <p className="text-royalBlue text-base">{profile[field] ? profile[field]?.includes('Physician') ? 'Clinician' : profile[field] : '---'}</p>
                                                </div>
                                            )
                                        }

                                        )
                                    }
                                </div>
                                <div className="flex items-center">
                                    <Button
                                        btnType='transparent'
                                        onClick={changePassword}
                                        title={'Change Password'}
                                        className={'my-3 hover:text-[#78dbdc]'}
                                    />
                                    {profile?.roles?.includes('Physician') && <div className='mx-3 flex items-center'>
                                        {
                                            profile?.sof?.id ?
                                                <DropdownSOF options={[
                                                    { label: 'Update', labelValue: 'update' },
                                                    { label: 'View', labelValue: 'view' },
                                                    { label: 'Delete', labelValue: 'delete' },
                                                ]}
                                                    getValueOfSof={(value: any) => {
                                                        if (value === 'update' && profile.sof?.isUpdated) {
                                                            return SOFClick({ type: 'update-req-pending', item: profile.sof })
                                                        }
                                                        SOFClick({ type: value, item: profile.sof })
                                                    }}
                                                    text='Signature on File (SOF) Actions'
                                                    expiryDate={profile.sof.expiryDate}
                                                // disabled={item?.data?.SOF?.status.toLowerCase() == 'pending'}
                                                /> :
                                                // <RequiredFieldTooltip required={true} tooltipText='An active signature on file will allow authorized office staff to order specific Castle tests on behalf of the clinician.'>
                                                <RequiredFieldTooltip required={true} tooltipText='New SOF (signature on file) creation has been disabled. Please use Clinician Approval workflow (option B in Ordering Instructions Guide) and reach out if you have any questions.'>
                                                    <Button
                                                        title='Create Signature on File (SOF)'
                                                        btnType='transparent'
                                                        className='ml-2.5'
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            SOFClick({ type: 'create', item: profile.sof })
                                                        }}
                                                        disabled />
                                                </RequiredFieldTooltip>
                                        }
                                    </div>}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {profile?.roles?.includes("Collaborative Order Agreement") && <Fragment>
                            <div className="text-royalBlue font-semibold mt-5 mb-5">
                                Associated Order Agreement
                            </div>
                            <div className="flex flex-wrap min-w-full 2xl:mb-0 xl:mb-0 lg:mb-12">
                                {profile && (
                                    <AssociatedPathLabAndRolesTable
                                        tableData={orderAgreementTable}
                                        roles={profile?.roles}
                                        className={""}
                                    />
                                )}
                            </div>
                        </Fragment>}
                        <div className="text-royalBlue font-semibold mt-5 mb-5">
                            Associated Organizations & Roles
                        </div>
                        <AssociatedOrganisationsAndRolesTable tableData={tableData} roles={profile.roles} />
                    </div>
                    {
                        profile?.roles?.includes('Physician') && <div>
                            <div className="text-royalBlue font-semibold mt-5 mb-5">
                                User Signature on Files
                            </div>
                            <UserSOFTable
                                tableData={sofTable}
                                className=" border-t pb-16"
                            />
                        </div>
                    }

                </div>
                <SOFFlow
                    isVisible={sofState.sofVisible}
                    type={sofState.sofType}
                    item={sofState.item}
                    closeSOF={() => setSofState({ sofType: 'create', item: null, sofVisible: false })}
                />
            </motion.div>

        </AnimatePresence>

    </ProfileScreenLayout>
}
const mapStateToProps = (state: any) => ({
    profile: state.Profile.profile,
    tableData: state.Profile.tableData,
    orderAgreementTable: state.Profile.orderAgreementTable,
    sofTable: state.Profile.sofTable,
    error: state.Profile.error,
    fetching: state.Profile.fetching
})

const mapDispatchToProps = {
    fetchProfile: ProfileActions.fetchprofile,
    fetchOrderAgreementOfUser: ProfileActions.fetchOrderAgreementOfUser,
    setButtonState: ButtonStateActions.setButtonState,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHomeScreen)
