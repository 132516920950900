/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useCallback, useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { ITableHeaderProps } from '../../DataTableHeader/DataTableHeader'
import { useDispatch, useSelector } from 'react-redux'
import http from '../../../Store/API/HttpMethods'
import { toggleSumbit } from '../../../Store/Actions/ButtonStateActions'
// import { formRef } from '../../../Pages/Admin/ManageScreen'
import RenderTable from './UsersTable'
import { formRef } from '../../../Pages/Admin/ManageScreen'
import InviteUserActions from '../../../Store/Actions/InviteUserActions'
import RequiredFieldTooltip from '../RequiredFieldTooltip'
import physicianToClinician from '../../../utils/helpers/physicianToClinician'

interface tooltip {
  key: string
  value: string
}

interface AssociationsPropType {
  role: string,
  selectedOrganizations?: any,
  organizationsList: any,
  initialMembers?: any[],
  formValid?: boolean,
  isUser?: boolean,
  setButtonDisable: any
}

function Associations({ role, selectedOrganizations, organizationsList, initialMembers, formValid, isUser, setButtonDisable }: AssociationsPropType): any {
  const { authState } = useOktaAuth()
  const initialHeader: ITableHeaderProps = {
    hasVerticalMenu: false,
    onSelectAll: (val) => {
      handleAll(val)
    },
    NavLink: '',
    nnProps: '',
    check: false,
    hideCheckBox: false,
    data: [
      {
        name: 'Name',
        preventAutoSearch: false,
        filter: false,
        sticky: false
        // suggestions: ['name', 'ali'],
      },
      {
        name: 'Email ID',
        preventAutoSearch: false,
        filter: false,
        sticky: false

      },
      {
        name: 'Role',
        preventAutoSearch: false,
        filter: false,
        sticky: false

      },
      // {
      //   name: 'organizations',
      //   preventAutoSearch: false,
      //   filter: false,
      //   sticky: false

      // }

    ],
    list: [
      // { name: 'name' },
      // { email: 'email' },
    ]
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialData, setInitialData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [fetching, setFetching] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [tableBody, setTableBody] = useState<any[]>(initialMembers ?? [])
  const [header, setHeader] = useState<ITableHeaderProps>(initialHeader)
  const [hasNoAssociatedMembers, setHasNoAssociatedMembers] = useState<boolean>(false)
  const { data: orgData, fetching: loadingOrg, error: orgError } = useSelector((state: any) => state.Organizations)
  const dispatch = useDispatch()

  function handleMembers(members: any) {
    const keys: any = {}
    let tableBody: any = []

    if (members && members.length > 0) {
      organizationsList.filter((org: any) => selectedOrganizations.find((item: any) => org.id == item.id)).map((item: any) => {

        return (keys[item?.name] = {
          value: false,
          isCheckbox: true,
          disabled: false
        })
      })

      tableBody = members.map((item: any) => {
        let organizations = ''
        let foundUserOrganisations: any[] = []

        organizationsList.forEach((organisation: any) => {
          if (organisation.users.find((user: any) => user.id == item.id)) {
            foundUserOrganisations.push(organisation)
            organizations = organizations + `${organisation.name}`
          }
        })

        const currentKeys = { ...keys }

        Object.keys(currentKeys).map((key: any) => {
          if (
            foundUserOrganisations.find((value: any) => value.name == key)
          ) {
            currentKeys[key].disabled = false
            currentKeys[key].value = true
          } else {
            currentKeys[key].disabled = true
          }
          return key
        })

        return {
          id: item.id,
          data: {
            // imsi: 3641741342,
            name: {
              value: item?.firstName ?? '',
              img: item?.imageUrl ?? ''
            },
            email: item?.email ?? '',
            role: item?.roles ?? '',
            organizations: organizations ?? '',
            ...currentKeys
          },
          showCheckbox: true,
          check: true
        }
      })

      let membersState = members?.map((member: any) => {
        let foundUserOrganisations: any[] = []
        organizationsList.forEach((organisation: any) => {
          if (organisation.users.find((user: any) => user.id == member.id)) {
            foundUserOrganisations.push(organisation)
          }
        })
        return { id: member?.id, organizationIds: foundUserOrganisations?.map((org: any) => org?.id) }
      })

      if (members.length > 0) {
        if (formRef?.current?.isValid) {
          dispatch(toggleSumbit(true))
        } else {
          dispatch(toggleSumbit(false))
        }
      }
      dispatch(InviteUserActions.setMembers(membersState))
    }
    return tableBody
  }

  async function getUsers(selectedOrganizations: any): Promise<void> {
    if (fetching) return
    setLoading(true)
    dispatch(toggleSumbit(false))
    setFetching(true)
    setButtonDisable(true)

    try {
      const config = { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } }
      let ids = ''
      let organizations = ''

      selectedOrganizations.map((selectedOrg: any, index: number) => {
        organizations = organizations + `${selectedOrg?.name}`
        // ids = ids + `&ids=${selectedOrg.id}`
        ids = ids + `${selectedOrg.id}${index + 1 == selectedOrganizations.length ? '' : ','}`
        return selectedOrg
      })
      // const response = await http.getUsersListRole(`v1/organizations/get-users-by-role?role=${role === 'Office Staff' ? 'Physician' : 'Staff'}${ids}`, config)
      const response = await http.getUsersListRole(`/v1/users/new-flow/list?organizationId=${ids}`, config)
      const { data, error } = response

      if (error) {
        alert(error)
        setError(true)
        setLoading(false)
        setFetching(false)
        setButtonDisable(false)
        return
      }

      if (data) {
        const keys: any = {}
        const { isValid } = formRef.current

        selectedOrganizations.map((item: any): any => {
          keys[item?.name] = {
            value: false, isCheckbox: true, disabled: true
          }
          return item
        })

        const tableBody = data.filter((user: any) => user.roles.includes('Physician')).map((item: any) => {
          // eslint-disable-next-line prefer-const
          let currentKeys: any = {}
          let OrganizationsData = ''
          let count = 0;
          let foundInitialData: any = null

          foundInitialData = handleMembers(initialMembers)?.find((member: any) => member.id === item.id)
          // foundInitialData = initialMembers?.find((member: any) => member.id === item.id)
          let filteredData = orgData.filter((org: any) => {
            if (selectedOrganizations?.find((selectedOrg: any) => selectedOrg.id == org.id))
              return org
            return null
          })

          filteredData.map((organisation: any, index: number) => {
            let found = organisation?.users?.find((user: any) => user.id == item.id)
            if (found) {
              OrganizationsData = OrganizationsData + `${count > 0 ? ', ' : ''}${organisation?.name}`
              count = count + 1
              currentKeys[organisation?.name] = { value: false, isCheckbox: true, disabled: false }
            } else {
              currentKeys[organisation?.name] = { value: false, isCheckbox: true, disabled: true }
            }
          })

          // if (foundInitialData)
          //   Object.keys((foundInitialData?.data)).forEach((key: any) => {
          //     if (key === 'name' || key === 'role' || key === 'organizations') {
          //       finalMemberData.data[key] = foundInitialData?.data[key]
          //     }
          //     if (selectedOrganizations.find((org: any) => org.profile.name == key)) {
          //       finalMemberData.data[key] = foundInitialData?.data[key]
          //     }
          //   })

          if (foundInitialData) return { ...foundInitialData, data: { ...foundInitialData.data, organizations: OrganizationsData } }

          // eslint-disable-next-line prefer-const
          let finalKeys: any = { ...keys, ...currentKeys }

          return {

            id: item.id,
            data: {
              name: {
                value: item?.firstName ?? '',
                img: item?.imageUrl ?? ''
              },
              email: item?.email ?? '',
              // role: item?.roles ?? '',
              role: physicianToClinician(item.roles),
              // organizations: OrganizationsData ?? '',
              // ...finalKeys
            },
            showCheckbox: true,
            check: false
          }
        })

        setLoading(false)
        setError(false)
        setFetching(false)
        setTableBody(tableBody)
        setButtonDisable(false)

        if (tableBody.length === 0) {
          setHasNoAssociatedMembers(true)
        } else {
          setHasNoAssociatedMembers(false)
        }

        if (!!formValid == false) {
          if (isValid && tableBody.length === 0) {
            dispatch(toggleSumbit(true))
          } else {
            dispatch(toggleSumbit(false))
          }
        }

        setInitialData(tableBody)
      }


    } catch (error) {
      console.log({ error })
      setError(true)
      setLoading(false)
      setFetching(false)
      setButtonDisable(false)
    }
  }

  useEffect(() => {
    let data = initialHeader.data

    // const organizations = selectedOrganizations.map((item: any) => {
    //   let tooltipValue = `${item?.address}${item?.city ? `,${" "}${item?.city}` : ''}${item?.state ? `,${" "}${item?.state}` : ''}`
    //   let tooltip: tooltip = { key: item.name, value: tooltipValue }

    //   return { name: item.name, filter: false, sticky: false, tooltip }
    // })
    // data = [...data, ...organizations]

    setHeader((prevState: any) => { return { ...prevState, data, check: false } })
  }, [selectedOrganizations?.length])

  useEffect(() => {
    if (loadingOrg) return
    if (selectedOrganizations && selectedOrganizations.length > 0 && !!role && role !== 'Physician' && role !== 'PathLab User' && role !== 'Collaborative Order Agreement') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getUsers(selectedOrganizations)
    }
  }, [selectedOrganizations?.length])

  function handleSelectAll(val: boolean): void {

    setHeader((prevState: any) => ({ ...prevState, check: val }))
    setTableBody((prevState: any) => {
      let members: any = []
      let updatedBody = prevState.map((item: any) => {
        let updatedOrganisations = { ...item.data }
        Object.keys(item.data).forEach((key: any) => {
          if (key !== 'email' && key !== 'name' && key !== 'organizations' && key !== 'role') {
            updatedOrganisations[key].value = val
          }
        })
        return { ...item, check: val, data: { ...item.data, } }
      })
      /* Old FLow where organisation checbox was needed to be selected as well */
      // members = updatedBody.map((member: any, childIndex: number) => {
      //   if (member.check) {
      //     const ids: any = []
      //     organizationsList.map((item: any) => {
      //       if (Object.keys(member.data).includes(item.name)) {
      //         if (member.data[item.name].value === true && member.data[item.name].disabled === false && ids.includes(item.id) === false) { ids.push(item.id) }
      //       }
      //       return item
      //     })

      //     const obj = { id: member.id, organizationIds: ids }
      //     return obj
      //   } else {
      //     dispatch(toggleSumbit(false))
      //   }
      //   return null
      // })
      // members = members.filter((member: any) => member?.organizationIds?.length > 0)
      // if (members.length > 0) {
      //   if (formRef?.current?.isValid) {
      //     dispatch(toggleSumbit(true))
      //   } else {
      //     dispatch(toggleSumbit(false))
      //   }
      // }

      members = updatedBody.filter((member: any) => member.check).map((member: any) => ({ id: member.id }))
      if (members.length > 0) {
        if (formRef?.current?.validateForm) {
          const updatedValues = { ...formRef.current.values }
          updatedValues.physicianIds = members.map((member: any) => member.id)
          formRef.current.setFieldValue('physicianIds', members.map((member: any) => member.id))
          formRef.current.validateForm(updatedValues).then((res: any) => {
            if (Object.keys(res).length == 0) {
              dispatch(toggleSumbit(true))
            } else {
              dispatch(toggleSumbit(false))
            }
          })
        } else {
          dispatch(toggleSumbit(false))
        }

      } else {
        if (formRef?.current?.validateForm) {
          const updatedValues = { ...formRef.current.values }
          updatedValues.physicianIds = []
          formRef.current.setFieldValue('physicianIds', [])
          formRef.current.validateForm(updatedValues).then((res: any) => {
            if (Object.keys(res).length == 0) {
              dispatch(toggleSumbit(true))
            } else {
              dispatch(toggleSumbit(false))
            }
          })
        }
      }
      dispatch(InviteUserActions.setMembers(members))
      return updatedBody
    });
  }
  const handleAll = useCallback(handleSelectAll, [tableBody, header])

  if (!role) {
    return <div className="py-4">
      <p className='text-royalBlue font-semibold py-4'>Associations</p>
      <p className="text-royalBlue">Please select a role</p>
    </div>
  }
  if (role === 'Physician' || role === 'Collaborative Order Agreement' || role === 'PathLab User') { return null }

  if (selectedOrganizations && selectedOrganizations.length === 0) {
    return <div className="py-4">
      <p className={`text-royalBlue font-semibold py-4 ${isUser || hasNoAssociatedMembers == false ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''}`}>{role.toLowerCase() === 'office manager' ? 'Associate with clinicians' : 'Associations'}</p>
      <p className="text-royalBlue">Please select an organization</p>
    </div>
  }

  if (loading && !error) {
    return <div className="py-4">
      <p className={`text-royalBlue font-semibold py-4 ${isUser || hasNoAssociatedMembers == false ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''}`}>{role.toLowerCase() === 'office manager' ? 'Associate with clinicians' : 'Associations'}</p>
      <p className="text-royalBlue">Loading...</p>
    </div>
  }

  if (!loading && error) { return <div className="py-4"><p className="text-royalBlue">Something went wrong..</p></div> }

  if (!loading && !error && !hasNoAssociatedMembers) {
    if (role.toLowerCase() === 'office manager') {
      return <div>
        {/* <RequiredFieldTooltip required={hasNoAssociatedMembers == false || isUser} tooltipText={'Required Field'}> */}
        <p className={`text-royalBlue font-semibold py-4 ${isUser || hasNoAssociatedMembers == false ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''}`}>
          {isUser ? 'Associated Users' : 'Associate with clinicians'}
          {/* Associated Physicians */}
        </p>
        {/* </RequiredFieldTooltip> */}
        {
          loading
            ? <p>Loading ...</p>
            : error
              ? <div>some thing went wrong</div>
              : <RenderTable
                tableBody={tableBody}
                tableHead={header}
                organizationsList={selectedOrganizations}
              />
        }

      </div>
    }

    return null
  }

  if (!loading && role.toLowerCase() === 'office manager') {
    return <div>
      <p className="text-royalBlue font-semibold py-4">
        {isUser ? 'Associate with clinicians' : 'Associated Users'}
      </p>
      <p>It seems at the moment there are no clinicians added in these organizations. You have to add clinicians in these organizations in order assign permission. You can still create the user and associate that user by going to the user details.</p>
    </div>
  }
  // if (!loading && role.toLowerCase() === 'office staff') {
  //   return <div>
  //     <p className="text-royalBlue font-semibold py-4">
  //       {isUser ? 'Associate with physicians' : 'Associated Users'}
  //     </p>
  //     <p>It seems at the moment there are no physicians added in these organizations. You have to add physicians in these organizations in order assign permission. You can still assign these permissions later in user details after you have added the physicians.</p>
  //   </div>
  // }
  return null
}

export default Associations
