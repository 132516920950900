import http from "../API/HttpMethods";
import * as actions from "../Actions/types";
import {
 FETCH_PORTAL_USERS,
 FETCH_PORTAL_USERS_BY_ORG,
 FETCH_PORTAL_USERS_BY_ORG_ERROR,
 FETCH_PORTAL_USERS_BY_ORG_SUCCESS,
 FETCH_PORTAL_USERS_ERROR,
 FETCH_PORTAL_USERS_SUCCESS,
 FETCH_PORTAL_USER_BY_ID,
 FETCH_PORTAL_USER_BY_ID_ERROR,
 FETCH_PORTAL_USER_BY_ID_SUCCESS,
 ActionArgs,
} from "./types";
import store from "..";
import toastMessages from "../../utils/helpers/toastMessage";

const fetchPortalUsers =
 (config: any, fetchMyProfile?: boolean) => async (dispatch: any) => {
  dispatch({ type: FETCH_PORTAL_USERS });

  try {
   const result = await http.getPortalUsers(
    // "v1/organizations/get-users",
    `/v1/users/new-flow/list${fetchMyProfile ? "?fetchMyProfile=true" : ""}`,
    config
   );
   const { statusCode, data, error } = result;

   if (error) return dispatch({ type: FETCH_PORTAL_USERS_ERROR });

   dispatch({ type: FETCH_PORTAL_USERS_SUCCESS, payload: data });
  } catch (error) {
   dispatch({ type: FETCH_PORTAL_USERS_ERROR });
  }
 };
const fetchPortalUsersByOrgId = (options: any) => async (dispatch: any) => {
 const { config, organizationId, pathologyLabId, fetchMyProfile, onError } =
  options;

 const filters = { pathologyLabId, organizationId, fetchMyProfile };

 const buildParams = (filters: any) => {
  const params = new URLSearchParams();

  Object.keys(filters).forEach(
   (key: string) => filters[key] && params.append(key, filters[key])
  );

  return params.toString();
 };
 dispatch({ type: FETCH_PORTAL_USERS_BY_ORG });

 try {
  const result = await http.getPortalUsers(
   `/v1/users/new-flow/list?${buildParams(filters)}`,
   config
  );

  const { statusCode, data, error } = result;

  if (error) return dispatch({ type: FETCH_PORTAL_USERS_BY_ORG_ERROR });

  dispatch({ type: FETCH_PORTAL_USERS_BY_ORG_SUCCESS, payload: data });
 } catch (error: any) {
  toastMessages.error(error?.message ? error.message : "Something went wrong");
  dispatch({ type: FETCH_PORTAL_USERS_BY_ORG_ERROR });
 }
};
const fetchPortalUserById =
 (id: string, config: any, onError?: (message: string) => void) =>
 async (dispatch: any) => {
  dispatch({ type: FETCH_PORTAL_USER_BY_ID });
  try {
   //  Old link
   // const result = await http.getPortalUserById(`v1/users/${id}`, config);

   //  New link  and flow
   const result = await http.getPortalUserById(
    `v1/users/new-flow/${id}`,
    config
   );

   const { statusCode, data, error } = result;
   if (error && onError) {
    onError(error?.message ? error.message : "Error retrieving ");
   }

   dispatch({ type: FETCH_PORTAL_USER_BY_ID_SUCCESS, payload: data });
   //    dispatch({ type: actions.SET_INVITE_MEMBERS, payload: data.members });
  } catch (error: any) {
   onError &&
    onError(
     error?.message
      ? error?.response?.data
        ? error?.response.data
        : error
      : "Failed to retrieve user data"
    );
   dispatch({ type: FETCH_PORTAL_USER_BY_ID_ERROR });
  }
 };
const updatePortalUserById = (options: ActionArgs) => async (dispatch: any) => {
 const { data: requestData, onSuccess, onError } = options || {};
 //  const { members }: any = store.getState().InviteUser;

 let apiData: any = {
  // firstName: requestData.firstName ?? "",
  // lastName: requestData.lastName ?? "",
  // primaryPhone: requestData.primaryPhone ?? "",
  // fax: requestData.fax ?? "",
  // streetAddress: requestData.streetAddress ?? "",
  // city: requestData.city ?? "",
  // zipCode: requestData.zipCode ?? "",
  // state: requestData.state ?? "",
  // // role: requestData.role ?? "",
  profileImage: requestData.profileImage ?? "",
  // specialty: requestData.specialty ?? "",
  // suffix: requestData.suffix ?? "",
  // npi: requestData.npi ?? "",
  // pinCode: requestData.pinCode ?? "",
  // organizationIds: requestData.organizationIds,
  // members: members,
 };

 if (requestData?.role === "Physician") {
  apiData.signPermission = requestData.signPermission;
 }

 const config = {
  headers: { Authorization: `Bearer ${requestData.accessToken}` },
 };

 dispatch({ type: actions.TOGGLE_FORM_SUBMIT, payload: true });
 dispatch({ type: actions.UPDATE_PORTAL_USER });

 try {
  const result = await http.updateUserProfile(
   //  `/v1/users/new-flow/${requestData.id}`,
   `/v1/users/new-flow/${requestData.id}`,
   apiData,
   config
  );

  const { success, error, data, statusCode } = result;

  if (error && onError != null) return onError(error);
  dispatch({ type: actions.UPDATE_PORTAL_USER_SUCCESS });
  dispatch({ type: actions.TOGGLE_FORM_SUBMIT, payload: false });
  onSuccess != null && onSuccess();
 } catch (error: any) {
  const { data: errorData } = error?.response;
  const message = errorData ? errorData.message : !!error;
  onError != null && onError(message);
  dispatch({ type: actions.UPDATE_PORTAL_USER_ERROR });
  dispatch({ type: actions.TOGGLE_FORM_SUBMIT, payload: false });
 }
};
const fetchOrderAgreement =
 (id: string, config: any, onError?: (message: string) => void) =>
 async (dispatch: any) => {
  dispatch({ type: actions.FETCH_PORTAL_USER_OA });
  try {
   const result = await http.getOrderAgreement(
    `/v1/order-agreement/${id}`,
    config
   );
   const { statusCode, data, error } = result;

   if (error && onError) {
    onError(error?.message ? error.message : "Error retrieving ");
    dispatch({ type: actions.FETCH_PORTAL_USER_OA_ERROR });
   }

   dispatch({ type: actions.FETCH_PORTAL_USER_OA_SUCCESS, payload: data });
  } catch (error: any) {
   onError &&
    onError(
     error?.message
      ? error?.response?.data
        ? error?.response.data
        : error
      : "Failed to retrieve user data"
    );
   dispatch({ type: actions.FETCH_PORTAL_USER_OA_ERROR });
  }
 };

const updateSelectedUserStatus = (status: string) => ({
 type: actions.UPDATE_SELECTED_USER_STAUTS,
 payload: status,
});

const PortalUsersActions = {
 fetchPortalUsers,
 fetchPortalUserById,
 fetchPortalUsersByOrgId,
 updateSelectedUserStatus,
 fetchOrderAgreement,
 updatePortalUserById,
};

export default PortalUsersActions;
