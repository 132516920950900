import { useEffect } from "react";
import NotificationsSettingsTable from "./NotificationsSettingsTable";
import HeaderColumnData from "../../models/notificationsSettingsTableModels/headerColumnData.model";
import BodyColumnData from "./BodyColumnData.model";
import { connect, useDispatch } from "react-redux";
import NotificationActions from "../../Store/Actions/UserNotificationActions";
import NotificationSettingsServices, { ApiMediumValue, ApiOrganizationMediumSettingsValue, ApiUserNotificationMediumValue } from "../../services/NotificationSettingsServices";
import { getFilteredValues } from "../../utils/helpers/enableDisableNotificationHelper";

interface UserNotificationMediumTableProps {
  tableColumns: HeaderColumnData[]
  organizations: any
  fetching: boolean
  error: boolean
  mediumValues: ApiMediumValue[]
  userMediumValues: ApiUserNotificationMediumValue[]
  organizationValues: ApiOrganizationMediumSettingsValue[]
  tableBody: Array<BodyColumnData[]>
  setTableBody: (data: Array<BodyColumnData[]>) => void
  setTableColumns: (data: HeaderColumnData[]) => void
}

function UserNotificationMediumTable({
  tableColumns,
  tableBody,
  setTableBody,
  setTableColumns,
  organizations,
  mediumValues,
  userMediumValues,
  organizationValues,
}: UserNotificationMediumTableProps): JSX.Element {
  const NotificationsSettingsData = new NotificationSettingsServices(organizations, mediumValues, organizationValues, userMediumValues)
  const dispatch = useDispatch()
  function handleSelectAll(id: string | number, value?: boolean) {
    setTableBody(NotificationsSettingsData.handleSelectAll(id, tableColumns, tableBody,dispatch, value).tableBody)
    setTableColumns(NotificationsSettingsData.handleSelectAll(id, tableColumns, tableBody,dispatch, value).tableColumn)
  }

  useEffect(() => {
    NotificationsSettingsData.createPersonalOrganizationsSettingsData()
    setTableColumns(NotificationsSettingsData.getPersonalOrganizationsSettingsData().tableColumn)
    setTableBody(NotificationsSettingsData.getPersonalOrganizationsSettingsData().tableBody)
  }, [])

  const filteredValues = getFilteredValues(tableBody);
  return <NotificationsSettingsTable
    tableColumns={tableColumns}
    onSelectAll={handleSelectAll}
    tableBody={tableBody}
    setTableBody={setTableBody}
    isEnabled={filteredValues}
  />
}
const mapStateToProps = (state: any) => ({
  fetching: state.UserNotifications.fetching,
  error: state.UserNotifications.error,
  organizations: state.Organizations.data,
  mediumValues: state.UserNotifications.notificationMediums,
  organizationValues: state.UserNotifications.organizationNotificationMediums,
  userMediumValues: state.UserNotifications.userNotificationMedium
})

const mapDispatchToProps = {
  fetchNotificationMedium: NotificationActions.fetchNotificationMedium,
  fetchOrganizationNotificationMedium: NotificationActions.fetchOrganizationNotificationMedium
}

export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationMediumTable)