function FAQLoadingIndicator() {
    return (
      <div className="justify-between items-center py-5">
        <div className="p-3 my-2 bg-dropDownBorder rounded-lg mx-3 w-[80%] animate-pulse" />
        <div className="p-3 my-2 bg-dropDownBorder rounded-lg mx-3 w-[80%] animate-pulse" />
        <div className="p-3 my-2 bg-dropDownBorder rounded-lg mx-3 w-[80%] animate-pulse" />
      </div>
      
    );
  }
  
  export default FAQLoadingIndicator;
  