import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../../atomic/Button";
import ProgressBar from "../../molecular/ProgressBar";
import RequiredFieldTooltip from "../../global/RequiredFieldTooltip";

function FormHeader({
  selectedTab,
  patientType,
  setPaitentType,
  validSections,
}: any): any {
  const { mode, selectedTest } = useSelector((state: any) => state.OnlineOrderForm);
  const { state }: any = useLocation()
  if (mode === "edit") {
    return null;
  }
  switch (selectedTab) {
    case "patient info":
      return (
        <div className="sticky top-0 bg-white py-5 z-20 border border-white px-10 lg:px-[109px]">
          <ProgressBar validSections={validSections} />
          <div className="flex justify-between items-center mt-5">
            <p className="text-royalBlue uppercase text-base font-medium tracking-[0.18em]">
              Patient Information
            </p>
            <div className="flex justify-between items-center border border-borderGray rounded-md">
              <Button
                onClick={() => setPaitentType("new")}
                title={"new"}
                className={`!border-none rounded-sm w-[88px] py-[5px] ${patientType === "new" ? "bg-[#EDEFF1]" : ""
                  }`}
                btnType="secondary"
              />
              <Button
                onClick={() => setPaitentType("existing")}
                title={"existing"}
                className={`border-t-0 border-b-0 border-r-0 px-0 rounded-sm w-[88px] py-[5px] ${patientType === "existing" ? "bg-[#EDEFF1]" : ""
                  }`}
                btnType="secondary"
              />
            </div>
          </div>
        </div>
      );
    case "billing info & medicare":
      return (
        <div className="sticky top-0 bg-white py-5 z-20 border border-white px-10 lg:px-[109px]">
          <ProgressBar validSections={validSections} />
          <p className="text-royalBlue font-medium text-base uppercase mt-5 tracking-[0.18em]">
            Billing Information
          </p>
        </div>
      );
    case "clinical info":
      return (
        <div className="sticky top-0 bg-white py-5 z-20 border border-white px-10 lg:px-[109px]">
          <ProgressBar validSections={validSections} />
          <p className="text-royalBlue font-medium text-base uppercase mt-5 tracking-[0.18em]">
            {selectedTest?.id === 8 ? 'Patient Healthcare Information' : selectedTest?.id == 2 ? <span>Clinical Information <span className="after:content-['*'] after:ml-1 after:text-requiredRed">(REQUIRED)</span> <span className="!text-xs normalize"><em className="normal-case">(This test is validated for patients with one or more high-risk features. Please check all that apply from the table below)</em></span></span> : 'Clinical Information'}
          </p>
        </div>
      );
    case "additional info":
      return (
        <div className="sticky top-0 bg-white py-5 z-20 border border-white px-10 lg:px-[109px]">
          <ProgressBar validSections={validSections} />
          <p className="text-royalBlue font-medium text-base uppercase mt-5 tracking-[0.18em]">
            Additional Information
          </p>
        </div>
      );
    case "laboratory info":
      return (
        <div className="sticky top-0 bg-white py-5 z-20 border border-white px-10 lg:px-[109px]">
          <ProgressBar validSections={validSections} />
          <p className="text-royalBlue font-medium text-base uppercase mt-5 tracking-[0.18em]">
            {selectedTest?.id === 8 ? 'Specimen Information' : 'Laboratory Information'}
          </p>
        </div>
      );
    case "supporting documents":
      return (
        <div className="sticky top-0 bg-white py-5 z-20 border border-white px-10 lg:px-[109px]">
          <ProgressBar validSections={validSections} />
          <p className="text-royalBlue font-medium text-base uppercase mt-5 tracking-[0.18em]">
            Supporting Documents
          </p>
        </div>
      );

    default:
      return (
        <div className="sticky top-0 bg-white py-5 z-20 border border-white px-10 lg:px-[109px]">
          <ProgressBar validSections={validSections} />
          <div className="bg-lightBlue p-2 px-3 rounded my-8 text-base text-royalBlue">
            {!!state?.orderValue ? 
          <div className="flex">
          <div style={{ height: 15, width: 15 }} className={'self-end mb-2 cursor-pointer rounded-full bg-primary hover:bg-primaryHover text-center text-white font-medium text-xs'}>
            i
          </div>
          <p className="p-1">
            Previous order documents have been attached with this order. Please verify the docs before submitting the order.
          </p>
        </div> :
              <p>
                By pressing{" "}
                <span className="text-primary font-semibold">Tab</span> button on
                the keyboard you can continue to the next field or section
              </p>}
          </div>
          <div>
            <p className="text-royalBlue uppercase text-base font-medium tracking-[0.14em]">
              Select Test
            </p>
            <RequiredFieldTooltip required={true} showLeft={true} tooltipText={'Required Field'}>
              <p className="text-royalBlue text-base pt-5">
                Please select test to begin a new Online Order{" "}
                <span className="text-requiredRed">*</span>
              </p>
            </RequiredFieldTooltip>
          </div>
        </div>
      );
  }
}

export default FormHeader