import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export function SortableItem(props: any) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const updatedAttributes: any = { ...attributes }

  if (props['data-openreplay-obscured']) {
    updatedAttributes['data-openreplay-obscured'] = true
  }

  return (
    <td ref={setNodeRef} style={style} {...updatedAttributes} {...listeners} className={props?.className ?? ''}>
      {/* ... */}
      {props.children}
    </td>
  );
}