import { motion } from 'framer-motion'

function NotFound () {
  return <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex flex-col h-full justify-center items-center">
        {/* <CheckMarkSVG /> */}
        <div className="w-2/6 text-center p-5">
            <p className="text-royalBlue font-medium text-2xl">
                404 | Page Not Found
            </p>
        </div>
    </motion.section>
}

export default NotFound
