/* eslint-disable @typescript-eslint/space-before-function-paren */
import React from 'react'
import TableLoadingIndicator from './TableLoadingIndicator'

function LoadingPill(): JSX.Element {
  return <div className='mx-3 mb-3'>
    <div className='py-1.5 w-3/6 bg-dropDownBorder rounded-lg animate-pulse' />
    <div className='py-1.5 px-20 bg-dropDownBorder rounded-lg mt-2 animate-pulse' />
  </div>
}

function UserDetailLoadingIndicator({ showTable }: { showTable: boolean }): JSX.Element {
  return <div className="py-5 px-12 flex flex-col">
    <div className="p-2 bg-dropDownBorder rounded-lg mx-3 w-[100px] animate-pulse" />
    <div className='my-5 py-5 border-b border-dropDownBorder'>
      <div className='flex justify-between flex-nowrap'>
        <div className="bg-dropDownBorder animate-pulse" style={{ height: 80, width: 80, borderRadius: 40 }} />
        <div>
          <div className='flex flex-wrap'>
            <LoadingPill />
            <LoadingPill />
            <LoadingPill />
            <LoadingPill />
            <LoadingPill />
            <LoadingPill />
          </div>
        </div>
      </div>
    </div>
    <div className='pb-5 border-b border-dropDownBorder my-5'>
      <div className='w-3/6'>
        <div className='h-[14px] w-3/6 bg-dropDownBorder rounded-lg animate-pulse' />
        <div className='flex items-center'>
          <div className='h-[25px] px-10 bg-dropDownBorder rounded-full mt-2 animate-pulse mr-6' />
          <div className='h-[25px] px-10 bg-dropDownBorder rounded-full mt-2 animate-pulse' />
        </div>
      </div>
    </div>
    <div className='pb-5 border-b border-dropDownBorder my-5'>
      <div className='w-3/6 border-b border-dropDownBorder'>
        <div className='h-[14px] w-3/6 bg-dropDownBorder rounded-lg animate-pulse mb-3' />
        <div className='h-[14px] w-2/6 bg-dropDownBorder rounded-lg animate-pulse mb-3' />
        <div className='h-[14px] w-3/6 bg-dropDownBorder rounded-lg animate-pulse mb-3' />
      </div>
      {
        showTable && <div className='mt-10'>
          <TableLoadingIndicator />
        </div>
      }

    </div>
  </div>
}

export default UserDetailLoadingIndicator
