import http from "../API/HttpMethods";
import * as actions from "./types";

const fetchSync = (options: any) => async (dispatch: any) => {
 const { onError, onResync, config, userId } = options;
 dispatch({ type: actions.SYNCHRONIZING });

 try {
  const response = await http.fetchSyncStatus(
   "/v1/users/sync-associations",
   { userId },
   config
  );
  const { error, reFetch } = response;

  if (error && onError) {
   dispatch({ type: actions.SYNCHRONIZING_ERROR });
   onError();
   return;
  }

  if (reFetch) {
   dispatch({ type: actions.SYNCHRONIZING_SUCCESS, payload: false });
   return onResync();
  }

  dispatch({ type: actions.SYNCHRONIZING_SUCCESS });
 } catch (error) {
  dispatch({ type: actions.SYNCHRONIZING_ERROR });

  if (onError) {
   onError();
  }
 }
};

const SyncActions = {
 fetchSync,
};

export default SyncActions;
