import * as React from "react"
import { SVGProps } from "react"

const LandingPageRightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 240 240"
    // style={{
    //   enableBackground: "new 0 0 240 240",
    // }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        fill: "#028eaa",
      }}
      d="M57.04 0h57.33l74.67 111.56L99.7 240H44.8l95.08-128.39z"
    />
  </svg>
)

export default LandingPageRightArrow
