import { Component } from 'react'
import TableLink from '../Link/TableLink'
import './style.css'

export interface ICheckboxProps {
  label?: string
  checked?: boolean
  disabled?: boolean
  link?: string
  NavLink: any
  nnProps?: any
  hideCheckBox?: boolean
  onChange?: (event: any) => any
}

export interface ICheckboxState { }

class Checkbox extends Component<ICheckboxProps, ICheckboxState> {
  public static defaultProps = {
    checked: false,
    disabled: false
  }

  render () {
    const { nnProps, NavLink, label, link, hideCheckBox, disabled, ...props } =
      this.props
    return (
      <div className="checkbox-container">
        <label className={hideCheckBox ? '' : 'checkbox-row'}>
          <span className={link ? 'checkbox-link' : ''}>
            {link
              ? (
              <TableLink
                nnProps={nnProps}
                NavLink={NavLink}
                text={label}
                to={link}
              />
                )
              : (
                  label
                )}
          </span>
          {hideCheckBox ? null : <input type="checkbox" disabled={disabled} {...props} />}
          {disabled ? <span className="disabled-line"/> : null}
          {hideCheckBox ? null : <span className="checkmark" />}
        </label>
      </div>
    )
  }
}

export default Checkbox
