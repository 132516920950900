import * as actions from './types'

const login = (options: any) => async (dispatch: any) => {
  const { user, onSuccess } = options
  return setTimeout(() => {
    dispatch({
      type: actions.LOGIN,
      payload: user
    })
    onSuccess()
  })
}

export default {
  login
}
