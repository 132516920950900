import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './style.css'
export interface LinkProps {
  text?: string
  to?: string
  size?: 'small'
  nnProps?: any
  externalLink?: boolean
  NavLink: any
}

class TableLink extends Component<LinkProps> {
  onKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>): void => {
    const { to = '', externalLink, nnProps } = this.props
    const { navigate } = nnProps
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      if (externalLink) {
        window.location.href = to
      }
      navigate(to)
    }
  }

  render () {
    const { text, to, size, externalLink, NavLink } = this.props
    if (NavLink) {
      if (externalLink) {
        return (
          <a
            href={to}
            target="_blank"
            rel="noreferrer"
            className={`link ${size}`}
          >
            {text}
          </a>
        )
      }
      return (
        <NavLink
          // className={(data: any) => `link ${size} ${data.activeClassName && "active"}`}
          activeClassName={'font-medium'}
          className={`text-royalBlue text-sm lg:text-base text-left block capitalize p-1 mb-1`}
          // className={({ activeClassName }: any) => `text-royalBlue text-sm lg:text-base text-left block capitalize p-1 mb-1 ${activeClassName ? 'font-medium' : ''}`}
          to={to ?? ''}
          onKeyDown={this.onKeyDown}
        >
          {text}
        </NavLink>
      )
    }
    return null
  }
}
export default TableLink
