/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ButtonStateActions from '../../Store/Actions/ButtonStateActions'

function ProgressBar({ validSections }: any): JSX.Element {
  const [percentage, setPercentage] = useState<string>('0%')
  const { selectedTest } = useSelector((state: any) => state.OnlineOrderForm)
  const dispatch = useDispatch();
  ButtonStateActions
  function enableSubmit(): void {
    dispatch(ButtonStateActions.toggleSumbit(true))
  }
  function disableSubmit(): void {
    dispatch(ButtonStateActions.toggleSumbit(false))
  }

  useEffect(() => {
    let totalSections = 0
    let unique = []
    let currentPercentage = ''
    let percentage = 0


    if (selectedTest.id === 1) {
      totalSections = 6
    } else {
      totalSections = 7
    }
    unique = Array.from(new Set(validSections))
    percentage = Number(((unique.length / totalSections) * 100).toFixed(0))
    currentPercentage = `${percentage}%`

    if (percentage < 100) {
      disableSubmit()
    } else {
      enableSubmit()
    }

    setPercentage(currentPercentage)
  }, [selectedTest?.id, validSections?.length])

  return <div>
    <p className="transition-all" style={{ marginLeft: percentage }}>{percentage}</p>
    <div className="w-full bg-[#DCDFE4] rounded-full overflow-hidden">
      <div className="bg-gradient-to-r from-[#18B5B7] to-[#084E8B] rounded-full p-1 transition-all" style={{ width: percentage }} />
    </div>
  </div>
}

export default ProgressBar
