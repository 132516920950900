import { useOktaAuth } from "@okta/okta-react";
import { Form, Formik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import TickIcon from "../../../assets/svg/TickIcon";
import HeaderOnlineFormLoadingIndicator from "../../../components/global/LoadingIndicators/CreateOnlineOrderIndicators/HeaderOnlineFormLoadingIndicator";
import SummaryHeaderLoadingIndicator from "../../../components/global/LoadingIndicators/CreateOnlineOrderIndicators/SummaryHeaderLoadingIndicator";
import SummarySectionIndicator from "../../../components/global/LoadingIndicators/SummaryScreenIndicators/SummarySectionIndicator";
import OnlineOrderPDF from "../../../components/organism/OnlineOrderForm/OnlineOrderPDF";
import ReviewSection from "../../../components/organism/OnlineOrderForm/ReviewSection";
import OrderSummaryLayout from "../../../Containers/OrderSummaryLayout";
import OrderFormValues from "../../../models/forms/onlineorderformvalues";
import ButtonStateActions from "../../../Store/Actions/ButtonStateActions";
import { fetchOrderById, fetchTestsAndIcdCodes } from "../../../Store/Actions/OnlineOrderFormActions";
import useQuery from "../../../utils/helpers/QueryHelper";
import toastMessages from "../../../utils/helpers/toastMessage";
import OrderFormValidation from "../../../validations/orderformvalidation";
import { formRef } from "../ManageScreen";
import CloseIcon from "../../../assets/svg/CloseIcon";
import testNameSplitter from "../../../utils/helpers/testNameSplitter";
import WarningIcon from "../../../assets/svg/WarningIcon";
import Button from "../../../components/atomic/Button";
function StatusItem({ label, valid, hideRightBorder, isCancel }: any): JSX.Element {
  return <div className="h-[90px] block w-full">
    <div className="flex items-center">
      <div className={`w-[20px] flex flex-col items-center justify-center h-[20px] border ${valid ? 'border-primary bg-primary' : ' border-dropDownBorder'} rounded-full`}>
        <TickIcon
          stroke={valid ? 'white' : undefined} />
      </div>
      {hideRightBorder ? null :
        <div className={`h-[1px] w-[calc(100%_-_20px)] ${valid ? 'bg-primary' : 'bg-[#DCDFE4]'}`} />
      }
    </div>
    <div className="w-full -ml-7">
      <p className={`!text-sm max-w-[80px] mt-2 text-center ${isCancel ? 'text-[#DCDFE4]' : 'text-royalBlue'}`}>
        {label}</p>
    </div>
  </div>
}
function StatusHeader({ status, selectedTab }: { status: string, selectedTab: string }): JSX.Element {
  const [statuses, setStatuses] = useState<any[]>([
    {
      label: 'In Progress',
      value: 10,
      valid: true
    },
    {
      label: 'Pending Submission',
      value: 20,
      valid: false
    },
    {
      label: 'Pending Approval',
      value: 30,
      valid: false
    },
    {
      label: 'Submitted',
      value: 40,
      valid: false
    },
    {
      label: 'Pending Tissue Receipt',
      value: 60,
      valid: false
    },
    {
      label: 'Pending Results',
      value: 70,
      valid: false
    },
    {
      label: 'Completed',
      value: 100,
      valid: false
    },
  ])
  useEffect(() => {
    const foundIndex = statuses.findIndex((item: any) => item.value === status)
    let updatedStatuses = statuses.map((item: any, index: number) => {
      if (index <= foundIndex) {
        return { ...item, valid: true }
      }
      return { ...item, valid: false }
    })
    setStatuses(updatedStatuses)
  }, [])
  return <div className={`${selectedTab !== 'additional info' && 'lg:sticky lg:top-0 bg-white pt-7 pb-5 lg:z-20 border border-white 2xl:w-[99%] xl:w-[93%] lg:w-[90%]'}`}>
    <div className="flex items-center justify-center ml-[4.3rem] lg:ml-32">
      {statuses.map((status: any) => <StatusItem
        label={status.label}
        hideRightBorder={status.label === 'Completed'}
        valid={status.valid}
        key={status.label} />)}
    </div>
  </div>
}

function Banner({ onClick, text, type, subHeading, hideButton = false, onClose }: { onClick: any, onClose?: any, text: string, subHeading?: string, type: 'cancel' | 'exception', hideButton: boolean }): JSX.Element | null {
  if (type === 'cancel') {
    return (
      <div className="mx-28 py-3 flex bg-pink border-requiredRed rounded-md border-[1px] mt-9 items-center justify-between px-3">
        <div className="flex items-center">
          <CloseIcon className="my-auto cursor-pointer mr-3" stroke={"#FD4F82"} onClick={onClose} />
          <div className="text-requiredRed">{text}</div>
        </div>
        <Button
          title="Reorder"
          btnType='transparent'
          className="text-requiredRed hover:text-[#f41e1e]"
          onClick={onClick}
        />
      </div>
    );
  } else if (type === 'exception') {
    return (
      <div className="mx-28 py-3 flex bg-pink border-requiredRed rounded-md border-[1px] mt-9 items-center justify-between px-3">
        <div>
          <div className="flex items-center pb-3">
            <WarningIcon className="mr-3" />
            <p className="text-requiredRed font-semibold mr-2">Missing Information: </p>
            <p className="text-requiredRed mr-2">{subHeading}</p>
          </div>
          <div className="text-requiredRed">{text}</div>
        </div>
        {/* {!hideButton && (
          <Button
            title={'Update Now'}
            btnType='transparent'
            className="text-requiredRed hover:text-[#f41e1e]"
            onClick={onClick}
          />
        )} */}
      </div>
    );
  } else {
    return null;
  }
}

function StatusHeaderDisabled(): JSX.Element {
  const [statuses, setStatuses] = useState<any[]>([
    {
      label: "In Progress",
      valid: true,
    },
    {
      label: "Pending Submission",
      valid: false,
    },
    {
      label: "Pending Approval",
      valid: false,
    },
    {
      label: "Submitted",
      valid: false,
    },
    {
      label: "Pending Document Review",
      valid: false,
    },
    {
      label: "Pending Tissue Receipt",
      valid: false,
    },
    {
      label: "Pending Results",
      valid: false,
    },
    {
      label: "Completed",
      valid: false,
    },
  ]);
  useEffect(() => {
    const foundIndex = statuses.findIndex((item: any) => item.label === status);
    let updatedStatuses = statuses.map((item: any, index: number) => {
      if (index <= foundIndex) {
        return { ...item, valid: true };
      }
      return { ...item, valid: false };
    });
    setStatuses(updatedStatuses);
  }, []);
  return (
    <div className="lg:sticky lg:top-0 bg-white pt-7 pb-5 z-20 border border-white 2xl:w-[97%] xl:w-[93%] lg:w-[90%]">
      <div className="flex items-center justify-center ml-[4.3rem] lg:ml-32">
        {statuses.map((status: any) => (
          <StatusItem
            label={status.label}
            hideRightBorder={status.label === "Completed"}
            valid={status.valid}
            key={status.label}
            isCancel
          />
        ))}
      </div>
    </div>
  );
}
function SummaryScreen({ loading, data, error, fetchOrderById, fetchTestsAndIcdCodes, resetButtonState }: any): JSX.Element {
  const [selectedTab, setSelectedTab] = useState<string>('Patient and Order Information')
  const { id } = useParams<{ id: string }>();
  const history = useHistory()
  const { authState } = useOktaAuth()
  const query = useQuery()
  const [show, setShow] = useState<boolean>(true)

  useEffect(() => {
    if (id) {
      resetButtonState()
      const options = {
        id,
        isReferralOrder: query.get('isReferralOrder') ? true : false,
        onError: (message: string) => {
          toastMessages.error(message)
          history.replace('/test-orders')
        },
        onSuccess: (id: string) => {
          const options = {
            orgId: id ?? '',
            config: {
              headers: {
                Authorization: `Bearer ${authState?.accessToken?.accessToken}`
              }
            },
            onError: (message: string) => toastMessages.error(message)
          }
          fetchTestsAndIcdCodes(options)
        },
        config: {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`
          }
        }
      }
      fetchOrderById(options)
    }
  }, [])

  function handleTab(tab: string): void {
    setSelectedTab(tab)
  }
  function hideBanner() {
    setShow(!show)
  }
  function handleUpdateNow(values: OrderFormValues) {
    return () => history.replace(
      `/test-orders/online-order/${values?.orderId}`
    );
  }
  function handleReorder(values: OrderFormValues) {
    return () => history.push(`/test-orders/online-order`, { orderValue: { ...values, orderId: '' } })
  }
  function Render({
    errors,
    touched,
    setFieldValue,
    isValid,
    values,
    initialValues,
    setFieldError,
    validateField,
    ...rest
  }: any): JSX.Element {

    return (
      <Fragment>
        <OnlineOrderPDF values={values} isSummary />
        <Form className="col-span-4 flex flex-col  w-full overflow-auto">
          <div className="w-[98.5%]">
            {((data?.status === 100 && data?.canReOrder) || (data?.status === 90 && data?.canReOrder)) && show && (
              <Banner
                text={'Test order has failed/Cancelled by Clinicial Services'}
                type='cancel'
                onClose={hideBanner}
                onClick={handleReorder(values)}
                hideButton={false}
              />
            )}
          </div>
          {data?.status === 80 && (
            <div className="mx-28 py-3 flex bg-[#ebebec] border-[#535353] rounded-md border-[1px] mt-9">
              {/* <CloseIcon className="my-auto mr-3 cursor-pointer ml-3" stroke={"#FD4F82"} onClick={() => setShow(!show)} /> */}
              <div className="text-[#535353] mx-3">This order has been put on hold by the CS Team.
              </div>
            </div>
          )}
          <div className="flex flex-col overflow-auto">
            {data?.status === 90 ? <StatusHeaderDisabled /> :
              <StatusHeader status={data?.status ?? 10} selectedTab={selectedTab} />
            }
            {
              !!data?.orderExceptions && <Banner
                text={data.orderComments}
                subHeading={data.orderExceptions}
                onClick={handleUpdateNow(values)}
                type='exception'
                hideButton={data.status === 80 || data.status === 90}
              />
            }
            <div className="px-10 lg:px-[109px]">
              <ReviewSection
                isSummary
                errors={errors}
                openOrder={() => history.replace(`/test-orders/order-review/${id}`)}
                touched={touched}
                isValid={isValid}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                selectedTab={selectedTab}
                setSelectedTab={handleTab}
                values={{ ...data, ...values }}
              />
            </div>
          </div>
        </Form>
      </Fragment >
    )
  }
  return <OrderSummaryLayout
    headerTitle={data?.selectedTest ? `${data?.patient_firstname ? `${data?.patient_firstname} - ` : '[Pending Entry] - '}${testNameSplitter(data.selectedTest.name)}` : 'N/A'}
    showTitleLoading={loading}
    selectedTab={selectedTab}
    setSelectedTab={handleTab}
  >
    {
      loading ? <div className="col-span-4 flex flex-col overflow-auto">
        <div className="flex flex-col overflow-auto">
          <div className='2xl:w-[99%] xl:w-[93%] lg:w-[90%]'>
            <SummaryHeaderLoadingIndicator />
          </div>
          <div className="px-10 lg:px-[109px]">
            <SummarySectionIndicator />
          </div>
        </div>
      </div>
        : data && <><Formik
          innerRef={formRef}
          validationSchema={OrderFormValidation}
          initialValues={data}
          initialStatus={false}
          onSubmit={() => { }}
          isInitialValid={false}
          render={Render}
        />
        </>
    }
  </OrderSummaryLayout>
}
const mapStateToProps = (state: any) => ({
  loading: state.OnlineOrderForm.fetchingOrderByID,
  error: state.OnlineOrderForm.fetchingOrderByIDError,
  data: state.OnlineOrderForm.orderData
})
const mapDispatchToProps = {
  fetchOrderById: fetchOrderById,
  fetchTestsAndIcdCodes: fetchTestsAndIcdCodes,
  resetButtonState: ButtonStateActions.resetButtonState
}
export default connect(mapStateToProps, mapDispatchToProps)(SummaryScreen)