import { Form, Formik, FormikProps } from "formik";
import CustomInput from "../../global/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { ApiNotificationTypeValue } from "../../../services/NotificationSettingsServices";
import dataConstants from "../../../constants/dataConstants";
import { ActionArgs, UPDATE_FILTERS } from "../../../Store/Actions/types";
import { useOktaAuth } from "@okta/okta-react";
import toastMessages from "../../../utils/helpers/toastMessage";
import MultiSelectDropdown from "../../global/MultiselectDropdown";
import UserNotificationActions from "../../../Store/Actions/UserNotificationActions";
import { NotificationFilters } from "../../../Store/Reducers/UserNotificationReducer";

type initialValues = {
  organizationId: string,
  createdAt: string,
  notificationTypeId: string
  // organizationLabel: string
  // dayLabel: string
  // typeLabel: string
}

function Render({ errors, touched, values, setFieldValue, submitForm }: FormikProps<any>) {
  const { notificationTypes, organizations } = useSelector((state: any) => ({
    notificationTypes: state.UserNotifications.notificationTypes,
    organizations: state.Organizations.data
  }))
  const organizationsList = organizations.map((organization: any) => ({ label: organization.name, value: organization.id, selected: false }))
  const typesSuggestions = notificationTypes.map((type: ApiNotificationTypeValue) => ({ label: type.name, value: type.id, selected: false }))

  function handleChange(name: string, value: string) {
    if (value === values[name]) {
      setFieldValue(name, '')
    } else {
      setFieldValue(name, value)
    }
    submitForm()
  }

  return <Form className="">
    <div className="flex w-full justify-between items-center">
      <MultiSelectDropdown
        label='Filter By Type Of Notifications'
        required={false}
        errors={errors}
        placeholder="Select Type"
        touched={{ ...touched }}
        disabled={typesSuggestions.length === 0}
        name='notificationTypeId'
        id='notificationTypeId'
        onOptionClick={(item) => {
          handleChange('notificationTypeId', item.value)
        }}
        options={typesSuggestions.map((option: any) => {
          if (!!values.notificationTypeId && (option.value == values.notificationTypeId)) {
            return {
              ...option,
              selected: true
            }
          }
          return {
            ...option
          }
        })}
      />
      <MultiSelectDropdown
        label='Filter By Organization'
        required={false}
        errors={errors}
        touched={{ ...touched }}
        placeholder="Select Organization"
        disabled={organizationsList.length === 0}
        name='organizationId'
        id='organizationId'
        className="mx-5"
        onOptionClick={(item) => {
          handleChange('organizationId', item.value)
        }}
        options={organizationsList.map((option: any) => {
          if (!!values.organizationId && (option.value == values.organizationId)) {
            return {
              ...option,
              selected: true
            }
          }
          return {
            ...option
          }
        })}
        notificationsDropdown
      />
      <MultiSelectDropdown
        label='Filter By Days'
        required={false}
        errors={errors}
        placeholder="Select"
        touched={{ ...touched }}
        disabled={organizationsList.length === 0}
        name='createdAt'
        id='createdAt'
        onOptionClick={(item) => {
          handleChange('createdAt', item.value)
        }}
        options={dataConstants.daysSuggestions.map((option: any) => {
          if (!!values.createdAt && (option.value == values.createdAt)) {
            return {
              ...option,
              selected: true
            }
          }
          return {
            ...option
          }
        })}
        className=""
        notificationsDropdown
      />
    </div>
  </Form>
}

function NotificationsForm({ fetchAllNotifications }: { fetchAllNotifications: (options: ActionArgs) => void }): JSX.Element {
  const { authState } = useOktaAuth()
  const dispatch = useDispatch()
  const { filters } = useSelector((state: any) => state.UserNotifications)
  const config = {
    headers: {
      Authorization: `Bearer ${authState?.accessToken?.accessToken}`
    }
  }
  const initialValues: initialValues = {
    organizationId: '',
    createdAt: '',
    notificationTypeId: '',
  }
  function createNotificationsURL(props: any) {
    let url = "/v1/notifications";
    let urlParams: any = {
      notificationTypeId: "notificationTypeId",
      organizationId: "organizationId",
      createdAt: "createdAt",
    };


    const buildParams = (filters: any) => {
      const params = new URLSearchParams();

      Object.keys(filters).forEach(
        (key: string) => props[key] && params.append(filters[key], props[key])
      );

      return params.toString();
    };

    url = `${url}${buildParams(urlParams) ? `?${buildParams(urlParams)}` : ""}`;
    return url;
  }

  return <Formik
    onSubmit={(values) => {
      dispatch(UserNotificationActions.updateNotificationFilters(values))
      if ((filters?.createdAt !== values?.createdAt))
        fetchAllNotifications({
          onError: (error: string) => toastMessages.error(error),
          data: {
            url: createNotificationsURL(values)
          },
          config
        })
    }}
    initialValues={initialValues}
    render={(props) => <Render {...props} />}
  />
}

export default NotificationsForm