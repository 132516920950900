import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import TooltipHelper from "./TooltipHelper";

const RequiredFieldTooltip = ({ children, required, showLeft, tooltipText }: any) => {
  const tooltipId = showLeft === undefined ? "labelTooltip" : "labelLeftTip";
  const tooltipPlace = showLeft === undefined ? "top" : "left";

  return (
    <>
      <span
        data-tip={required ? tooltipText : undefined} data-for={required ? tooltipId : undefined}>
        {children}
      </span>
      {required && (
        <TooltipHelper
          id={tooltipId}
          backgroundColor="#18B5B7"
          className="text-xs p-2 max-w-[350px]"
        />
      )}
    </>
  );
};

export default RequiredFieldTooltip;
