import reduxActionType from '../../models/reduxActionType'
import * as actions from '../Actions/types'

const initialState: { menuOpen: boolean } = {
  menuOpen: true
}

function SideMenuReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case actions.TOGGLE_SIDE_MENU:
      {
        return {
          ...state,
          menuOpen: !state.menuOpen
        }
      }
    case actions.LOGOUT:
      return initialState
    default:
      return state
  }
}

export default SideMenuReducer
