import reduxActionType from '../../models/reduxActionType'
import { CREATE_ORGANIZATION, CREATE_ORGANIZATION_ERROR, CREATE_ORGANIZATION_SUCCESS, FETCH_ORGANIZATIONS, FETCH_ORGANIZATIONS_ERROR, FETCH_ORGANIZATIONS_SUCCESS, FETCH_ORG_DETAIL, FETCH_ORG_DETAIL_ERROR, FETCH_ORG_DETAIL_SUCCESS, FETCH_ORG_USERS, FETCH_ORG_USERS_ERROR, FETCH_ORG_USERS_SUCCESS, LOGOUT, SET_FETCH_USERS_FALSE, UPDATE_ORGANIZATION, UPDATE_ORGANIZATION_ERROR, UPDATE_ORGANIZATION_SUCCESS, UPDATE_REDUX_ORG_LIST } from '../Actions/types'

const initialState = {
  fetching: false,
  creating: false,
  createError: false,
  updating: false,
  updateError: false,
  error: false,
  fetchingOrgUsers: false,
  fetchingOrgUsersError: false,
  data: []
}

function OrganizationsReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case FETCH_ORGANIZATIONS: {
      return {
        ...state,
        fetching: true,
        error: false
      }
    }
    case FETCH_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        // data: [...action.payload]
        data: action.payload.map((item: any) => ({ ...item, users: item?.users?.filter((user: any) => user.isApproved), ...item?.profile }))
      }
    }
    case FETCH_ORGANIZATIONS_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true
      }
    }
    case CREATE_ORGANIZATION: {
      return {
        ...state,
        creating: true,
        createError: false
      }
    }
    case CREATE_ORGANIZATION_ERROR: {
      return {
        ...state,
        creating: false,
        createError: true
      }
    }
    case CREATE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        creating: false,
        createError: false
      }
    }
    case UPDATE_ORGANIZATION: {
      return {
        ...state,
        updating: true,
        updateError: false
      }
    }
    case UPDATE_ORGANIZATION_ERROR: {
      return {
        ...state,
        updating: false,
        updateError: true
      }
    }
    case UPDATE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        updating: false,
        updateError: false
      }
    }
    case FETCH_ORG_USERS: {
      return {
        ...state,
        fetchingOrgUsers: true,
        fetchingOrgUsersError: false,
      }
    }
    case FETCH_ORG_USERS_SUCCESS: {
      let allData = [...state.data]
      let updatedData = allData.map((organisation: any) => {
        if (organisation.id === action.payload.id) {
          return {
            ...organisation,
            // users: action.payload.data
            users: action?.payload?.data?.map((item: any) => ({ ...item, ...item?.profile }))
          }
        }
        return {
          ...organisation
        }
      })

      return {
        ...state,
        fetchingOrgUsers: false,
        fetchingOrgUsersError: false,
        data: updatedData
        // data: action.payload?.data?.map((organisation: any) => {
        //   if (organisation.id === action.payload.id) {
        //     return {
        //       ...organisation,
        //       users: action.payload.data
        //     }
        //   }
        //   return {
        //     ...organisation
        //   }
        // })
      }
    }
    case FETCH_ORG_USERS_ERROR: {
      return {
        ...state,
        fetchingOrgUsers: false,
        fetchingOrgUsersError: true,
      }
    }
    case SET_FETCH_USERS_FALSE: {
      return {
        ...state,
        fetchingOrgUsers: false,
        fetchingOrgUsersError: true,
      }
    }
    case FETCH_ORG_DETAIL: {
      return {
        ...state,
        fetching: true,
        error: false
      }
    }
    case FETCH_ORG_DETAIL_SUCCESS: {
      let orgsList: any[] = [...state.data]
      let found = orgsList.find((org) => org.id == action.payload.id)
      if (!!found == false)
        orgsList.push(action.payload)

      return {
        ...state,
        data: orgsList,
        fetching: false
      }
    }
    case FETCH_ORG_DETAIL_ERROR: {
      return {
        ...state,
        error: true,
        fetching: false
      }
    }
    case UPDATE_REDUX_ORG_LIST: {
      return {
        ...state,
        data: state.data.map((org: any) => {
          if (org.id === action.payload.id) {
            return {
              ...org,
              ...action.payload
            }
          }
          return {
            ...org
          }
        })
      }
    }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default OrganizationsReducer
