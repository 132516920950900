import reduxActionType from '../../models/reduxActionType'
import * as actions from '../Actions/types'

const initialState = {
  user: { email: 'hello' }
}

function AuthReducer (state = initialState, action: reduxActionType) {
  switch (action.type) {
    case actions.LOGIN:
    {
      const { payload } = action
      return {
        ...state,
        user: payload
      }
    }
    case actions.LOGOUT:
      return initialState
    default:
      return state
  }
}

export default AuthReducer
