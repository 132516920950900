import { AnimatePresence } from 'framer-motion'
import { Component, Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import Checkbox from '../Checkbox/Checkbox'
import CreateSOF from '../CreateSOF'
import DataTableHeader, {
  ITableHeaderProps
} from '../DataTableHeader/DataTableHeader'
import CustomPopup from '../global/CustomPopup'
import SOFFlow from '../organism/SofFlow'
import ThreeDotVerticalMenu, { MenuItem } from '../ThreeDotVerticalMenu/ThreeDotVerticalMenu'
import DataTableItem from './DataTableItem'
// import './style.css'

interface tooltip {
  key: string,
  value: string
}
interface stickyClass {
  key: string
  value: string
}
interface tooltips {
  tooltips: tooltip[]
}
interface bodyContent {
  id: number
  imsi?: any
  content?: any
  data?: any
  onRowClick?: (id: number) => any
  actionClick?: (item: any) => any
  check: boolean
  showCheckbox?: boolean
  threeDotsActions?: MenuItem[]
  tooltips?: tooltips
  stickyClass?: stickyClass[]
  sensitiveFields?: string[]
  selectOnIndex?: (check: boolean, id: number) => any
}
interface DataTableProps {
  NavLink: any
  usersListDebounce?: boolean
  nnProps?: any
  externalCheckbox?: boolean
  handleCheckbox?: (key: string, check: boolean, index: number) => any
  selectOnIndex?: (check: boolean, id: number) => any
  visibleFields?: any[]
  showNumbering?: boolean
  header: ITableHeaderProps
  onMouseEnter?: any
  onMouseLeave?: any
  className?: string
  initialFilters?: any
  customEmptyText?: string,
  showLoader?: boolean,
  body: bodyContent[]
  groupedData?: Map<string, bodyContent[]>
  portaluserHeader?: any
  handlegetSofvalue?: any
  isCreateSof?: boolean,
  sofValue?: any,
  isSofFlow?: any
}


export interface DataTableState {
  sofVisible: boolean
  sofType: 'create' | 'update' | 'view' | 'delete' | 'update-req-pending'
  item: any
}

class DataTable extends Component<DataTableProps, DataTableState> {
  constructor(props: DataTableProps) {
    super(props)
    this.state = {
      sofType: 'create',
      sofVisible: false,
      item: null
    }
    this.handleSOF = this.handleSOF.bind(this)
  }
  handleSOF({ item, type }: { type: 'create' | 'update' | 'view' | 'delete', item: any }) {
    this.setState({ sofType: type, sofVisible: true, item })
  }

  render() {
    let { sofVisible, sofType, item } = this.state;
    const { portaluserHeader, NavLink, header, body, nnProps, visibleFields, isCreateSof, className, groupedData, customEmptyText, selectOnIndex, handleCheckbox, showLoader, showNumbering, initialFilters, onMouseEnter, onMouseLeave, externalCheckbox, usersListDebounce } = this.props

    return (
      <Fragment>
        <div className='relative'>
          <div className={`data-table z-1 overflow-auto ${className ?? '2xl:!h-[73vh] xl:!h-[65vh] lg:!h-[57vh] tab:!h-[55vh] pb-7 tab:pb-16'}`}>
            <table >
              {header && <DataTableHeader
                externalCheckBox={externalCheckbox}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                initialFilters={initialFilters}
                showNumbering={showNumbering}
                NavLink={NavLink}
                {...header}
                visibleFields={visibleFields}
                portaluserHeader={portaluserHeader}
                usersListDebounce={usersListDebounce}
              />}
              <tbody className="data-table-body relative">
                {
                  (groupedData != null) && Array.from(groupedData.keys())?.length > 0 && Array
                    .from(groupedData.keys())
                    .map((key: any) => <Fragment>
                      <td colSpan={visibleFields?.length ? visibleFields?.length + 3 : 5} className="font-semibold bg-sideMenuHoverBg  !border-b !border-primary !p-2">
                        {key}
                      </td>
                      {groupedData.get(key)?.map((item, parentIndex) => (<DataTableItem
                        key={item.id}
                        externalCheckbox={externalCheckbox}
                        item={item}
                        showNumbering={showNumbering}
                        body={item}
                        parentIndex={parentIndex}
                        onClick={(item.onRowClick != null) ? item.onRowClick : undefined}
                        visibleFields={visibleFields}
                        showCheckbox={item.showCheckbox}
                        handleCheckbox={handleCheckbox}
                        actionClick={item.actionClick ? item.actionClick : undefined}
                        selectOnIndex={(item.selectOnIndex != null) ? item.selectOnIndex : selectOnIndex}
                        handleSOF={this.handleSOF}
                      />))}
                    </Fragment>
                    )
                }
                {(groupedData != null) && Array.from(groupedData.keys())?.length > 0
                  ? null
                  : (body || []).map((item, parentIndex) => <DataTableItem
                    key={item.id}
                    item={item}
                    externalCheckbox={externalCheckbox}
                    hasVerticalMenu={header?.hasVerticalMenu ?? false}
                    showNumbering={showNumbering}
                    body={body}
                    parentIndex={parentIndex}
                    actionClick={item.actionClick ? item.actionClick : undefined}
                    onClick={item.onRowClick}
                    handleCheckbox={handleCheckbox}
                    visibleFields={visibleFields}
                    showCheckbox={item.showCheckbox}
                    selectOnIndex={(item.selectOnIndex != null) ? item.selectOnIndex : selectOnIndex}
                    handleSOF={this.handleSOF}
                  />)}
              </tbody>

            </table>
            {body?.length === 0 && <div className="p-5">
              <p className="text-royalBlue text-center normal-case px-2 text-xl">
                {customEmptyText ??
                  'Sorry, there are no matching results.'
                }
              </p>
            </div>}

          </div>
          {showLoader && <div className='top-0 bottom-0 left-0 right-0 w-full h-full absolute' style={{ zIndex: 2000,/*  backgroundColor: 'rgba(0,0,0,0.2)' */ }}>
            <div className={`flex w-full h-full justify-center items-center`} >
              <div className='animate-spin'>
                <img src={require('../../assets/images/loadingPNG.png')} className={'h-[50px] w-[50px]'} />
              </div>
            </div>
          </div>}
        </div>
        <SOFFlow
          isVisible={sofVisible}
          type={sofType}
          item={item}
          closeSOF={() => this.setState({ sofType: 'create', item: null, sofVisible: false })}
        />
      </Fragment>
    )
  }
}
export default DataTable
