import * as Yup from "yup";

const EditMyProfileValidation = Yup.object().shape({
  firstName:Yup.string().required("First name is required!").test('no-empty-blank-space', 'First name cannot be an empty blank space(s)', (value:any) => {
    return value && value.trim() !== '';
  }),
 lastName:Yup.string().required("Last name is required!").test('no-empty-blank-space', 'Last name cannot be an empty blank space(s)', (value:any) => {
    return value && value.trim() !== '';
  }),
 email_id: Yup.string().email("Email ID is invalid!"),
 // password: Yup.string()
 //     .required('Password is required'),
//  primaryPhone: Yup.string()
//   .matches(
//    /\d{3}-\d{3}-\d{4}/,
//    // /\d?(\s?|-?|\+?|\.?)((\(\d{1,4}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)\d{3}(-|\.|\s)\d{4}/,
//    "Phone must contain 10 digits with following format 000-000-0000."
//   )
//   .required("Phone is required!"),
//  fax: Yup.string()
//   .matches(
//    /\d{3}-\d{3}-\d{4}/,
//    //  /\d?(\s?|-?|\+?|\.?)((\(\d{1,4}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)\d{3}(-|\.|\s)\d{4}/,
//    "Fax must contain 10 digits with following format 000-000-0000."
//   )
//   .required("Fax is required!"),
//  city: Yup.string().required("City is required!"),
//  zipCode: Yup.string()
//   .matches(/^[0-9]+$/, "Zip code must be numbers")
//   .required("Zip code is required!")
//   .min(5, "Length must equal to 5")
//   .max(5, "Length must equal to 5"),
//  state: Yup.string().required("State/Province is required!"),
//  streetAddress: Yup.string().required("Street address is required!")
});

export default EditMyProfileValidation;
