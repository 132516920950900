import * as React from 'react'
import { SVGProps } from 'react'

const ManageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name="Component 6400 \u2013 19"
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={15}
        {...props}
    >
        <path
            data-name="Ellipse 607"
            d="M8 0a3.423 3.423 0 0 1 3.428 3.409 3.429 3.429 0 0 1-6.857 0A3.423 3.423 0 0 1 8 0Zm0 5.455a2.045 2.045 0 1 0-2.058-2.046A2.054 2.054 0 0 0 8 5.45Z"
            fill={props.fill ?? '#fff'}
        />
        <path
            data-name="Ellipse 608"
            d="M12.572 8.182A3.423 3.423 0 0 1 16 11.591a3.429 3.429 0 0 1-6.857 0 3.423 3.423 0 0 1 3.429-3.409Zm0 5.455a2.045 2.045 0 1 0-2.058-2.046 2.054 2.054 0 0 0 2.058 2.041Z"
            fill={props.fill ?? '#fff'}
        />
        <path
            data-name="Ellipse 609"
            d="M3.429 8.182a3.423 3.423 0 0 1 3.428 3.409 3.429 3.429 0 0 1-6.857 0 3.423 3.423 0 0 1 3.429-3.409Zm0 5.455a2.045 2.045 0 1 0-2.058-2.046 2.054 2.054 0 0 0 2.058 2.041Z"
            fill={props.fill ?? '#fff'}
        />
    </svg>
)

export default ManageIcon
