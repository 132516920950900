import { Fragment, createRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import OrganizationsList from '../../../components/organism/OrganizationsList'
import ManageScreenLayout from '../../../Containers/ManageScreenLayout'
import ButtonStateActions from '../../../Store/Actions/ButtonStateActions'
import PathLabList from '../../../components/organism/PathLabList'

export const formRef = createRef<any>()

function ManageHomeScreen({ screen, headerTitle, isPathLab }: any) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { organizationData, pathLabsData } = useSelector((state: any) => {
    return {
      organizationData: state.Organizations.data,
      pathLabsData: state.PathLab.data
    };
  });
  useEffect(() => {
    // Navigating to default company will be dynamic once api data is received.
    if (isPathLab) {
      pathLabsData?.length > 0 ?
        history.push(`/manage/pathlabs/${pathLabsData[0]?.id}`, { replace: true }) :
        ''
    } else {
      organizationData?.length > 0 ?
        history.push(`/manage/organizations/${organizationData[0]?.id}`, { replace: true }) :
        ''
    }
    dispatch(ButtonStateActions.resetButtonState())

    // dispatch(ButtonStateActions.setButtonState('organizations'))
  }, [organizationData, pathLabsData])

  return <ManageScreenLayout
    headerTitle={headerTitle}
    isPathLab={isPathLab}
    screen={screen}>
    {isPathLab ? <PathLabList /> :
      <OrganizationsList />
    }
    <Fragment>
      {(pathLabsData.length === 0 || organizationData.length === 0) && <div className='col-span-3 flex flex-col justify-center items-center'>
        <p className='text-royalBlue text-2xl'>{isPathLab ? 'No PathLab has been added' : 'No Organization has been added'}</p>
      </div>}
    </Fragment>
  </ManageScreenLayout>
}

export default ManageHomeScreen
