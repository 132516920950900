import * as React from 'react'
import { SVGProps } from 'react'

const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 19.027}
        height={props.height ?? 17.057}
        {...props}
    >
        <g
            data-name="Group 15542"
            fill="none"
            stroke={props.stroke ?? '#fe4f4f'}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path data-name="Path 5332" d="M9.523 6.532v2m0 4v.01" />
            <path
                data-name="Path 5333"
                d="M2.523 16.532h14a2 2 0 0 0 1.84-2.75l-7.1-12.25a2 2 0 0 0-3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
            />
        </g>
    </svg>
)

export default WarningIcon
