function testNameSplitter(name: string) {
  let finalName: string = ''
  let splittedText: any = name.split(':')

  if (splittedText.length > 1) {
    finalName = splittedText[0]
  } else {
    finalName = name
  }

  return finalName
}
export default testNameSplitter