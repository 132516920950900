/* eslint-disable @typescript-eslint/space-before-function-paren */
import { Form, Formik } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../../components/atomic/Button'
import ProgressBar from '../../../components/molecular/ProgressBar'
import ReviewSection from '../../../components/organism/OnlineOrderForm/ReviewSection'
import PatientInfoForm from '../../../components/organism/TestOrderForms/PatientInfoForm'
import AdditionalInfoSection from '../../../components/organism/TestOrderForms/Sections/AdditionalInfoSection'
import BillingInfoSection from '../../../components/organism/TestOrderForms/Sections/BillingInfoSection'
import ClinicalInfoSection from '../../../components/organism/TestOrderForms/Sections/ClinicalInfoSection'
import LaboratoryInfoSection from '../../../components/organism/TestOrderForms/Sections/LaboratoryInfoSection'
import SupportingDocsSection from '../../../components/organism/TestOrderForms/Sections/SupportingDocsSection'
import SelectTestForm from '../../../components/organism/TestOrderForms/SelectTestFrom'
import TestOrdersLayout from '../../../Containers/TestOrdersLayout'
import OrderFormValues from '../../../models/forms/onlineorderformvalues'
import ButtonStateActions from '../../../Store/Actions/ButtonStateActions'
import OrderFormValidation from '../../../validations/orderformvalidation'
import { formRef } from '../ManageScreen'
import TestSelectorLoadingIndicator from '../../../components/global/LoadingIndicators/CreateOnlineOrderIndicators/TestSelectorLoadingIndicator'
import HeaderOnlineFormLoadingIndicator from '../../../components/global/LoadingIndicators/CreateOnlineOrderIndicators/HeaderOnlineFormLoadingIndicator'
import CustomPopup from '../../../components/global/CustomPopup'
import { createOnlineOrder, editMode, fetchOrderById, fetchTestsAndIcdCodes, handleDiscard, resetMode, resetSaveOrder, resetSelectedTest, toggleMode, updateOnlineOrder, updatePhysicianSignCheck, updateSelectedOrganization, updateSelectedPathLab, updateSelectedPhysician } from '../../../Store/Actions/OnlineOrderFormActions'
import OnlineOrderPDF from '../../../components/organism/OnlineOrderForm/OnlineOrderPDF'
import { OnlineOrderOptions } from '../../../models/forms/online_order_options.interface'
import toastMessages from '../../../utils/helpers/toastMessage'
import { useOktaAuth } from '@okta/okta-react'
import MedicareSection from '../../../components/organism/TestOrderForms/Sections/MedicareSection'
import orderResponseFormatter from '../../../utils/helpers/orderResponseFormatter'
import useQuery from '../../../utils/helpers/QueryHelper'
import testNameSplitter from '../../../utils/helpers/testNameSplitter'
import useOrderFlowPopups from '../../../utils/customHooks/orderFlowPopups'
import RequiredFieldTooltip from '../../../components/global/RequiredFieldTooltip'
import FormHeader from '../../../components/organism/OnlineOrderForm/FormHeader'
import NotificationActions from '../../../Store/Actions/NotificationsActions'
import http from '../../../Store/API/HttpMethods'
import DashboardCallModel from '../../../models/DashboardCallModel'
import DashboardActions from '../../../Store/Actions/DashboardActions'


function RenderFields({
  initialValues,
  setFieldValue,
  errors,
  touched,
  setImage,
  isValid,
  values,
  selectedTab,
  patientType,
  setFieldError,
  setSelectedTab,
  setValidSections,
  validateField,
  ...rest
}: any): JSX.Element {
  const { mode, selectedTest } = useSelector((state: any) => state.OnlineOrderForm)

  return <Fragment>
    <div className={`${mode === 'edit' ? '' : 'hidden'}`}>
      <ReviewSection isUpdate values={{ ...initialValues, ...values }} setFieldValue={setFieldValue} setSelectedTab={setSelectedTab} isValid={isValid} />
    </div>
    <div className={`${mode === 'edit' ? 'hidden' : ''}`}>
      <SelectTestForm
        initialValues={initialValues}
        isEdit
        selectedTab={selectedTab}
        values={values}
        setValidSections={setValidSections}
        setFieldValue={setFieldValue}
        setFieldError={setFieldError}
        setSelectedTab={setSelectedTab}
        errors={errors}
        touched={touched}
        {...rest}
      />
      <PatientInfoForm
        initialValues={initialValues}
        selectedTab={selectedTab}
        setFieldError={setFieldError}
        values={values}
        patientType={patientType}
        setFieldValue={setFieldValue}
        errors={errors}
        isUpdate
        touched={touched}
        setSelectedTab={setSelectedTab}
        setValidSections={setValidSections}
        {...rest}
      />
      <BillingInfoSection
        initialValues={initialValues}
        values={values}
        isUpdate
        setFieldError={setFieldError}
        patientType={patientType}
        setFieldValue={setFieldValue}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        errors={errors}
        touched={touched}
        setValidSections={setValidSections}
        {...rest}
      />
      {/* {(values?.insurance.includes("Medicare") || values?.insurance.includes("Medicaid")) && <MedicareSection
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        setValidSections={setValidSections}
        setSelectedTab={setSelectedTab}
        {...rest}
      />} */}
      <ClinicalInfoSection
        initialValues={initialValues}
        setFieldError={setFieldError}
        values={values}
        patientType={patientType}
        isValid={isValid}
        selectedTab={selectedTab}
        isUpdate
        setFieldValue={setFieldValue}
        errors={errors}
        setValidSections={setValidSections}
        setSelectedTab={setSelectedTab}
        touched={touched}
        {...rest}
      />
      <AdditionalInfoSection
        initialValues={initialValues}
        values={values}
        setFieldError={setFieldError}
        isUpdate
        patientType={patientType}
        selectedTab={selectedTab}
        isValid={isValid}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        setSelectedTab={setSelectedTab}
        setValidSections={setValidSections}
        {...rest}
      />
      {/* {
        selectedTest.id !== 8 && */}
      <LaboratoryInfoSection
        initialValues={initialValues}
        values={values}
        patientType={patientType}
        setFieldValue={setFieldValue}
        isUpdate
        selectedTab={selectedTab}
        setFieldError={setFieldError}
        setSelectedTab={setSelectedTab}
        errors={errors}
        touched={touched}
        setValidSections={setValidSections}
        {...rest}
      />
      {/* } */}
      <SupportingDocsSection
        initialValues={initialValues}
        values={values}
        patientType={patientType}
        setFieldValue={setFieldValue}
        setFieldError={setFieldError}
        isEdit
        isUpdate
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        errors={errors}
        touched={touched}
        setValidSections={setValidSections}
        {...rest}
      />
    </div>
  </Fragment>
}

function UpdateOrderScreen({ createError, updateOnlineOrder, creatingOrder, orderData: data, loading, error, fetchOrderById, fetchTestsAndIcdCodes, fetchDashboard }: any): JSX.Element {
  const dispatch = useDispatch()
  const query = useQuery()
  const history = useHistory()
  const { authState } = useOktaAuth()
  const [selectedTab, setSelectedTab] = useState<string>('select test')
  const [firstScroll, setFirstScroll] = useState<boolean>(true)
  const [patientType, setPatientType] = useState<string>('new')
  const [validSections, setValidSections] = useState<any[]>([])
  const [touchedSections, setTouchedSections] = useState<string[]>([]);
  const { data: orgsList } = useSelector((state: any) => state.Organizations)
  const { data: pathLabList } = useSelector((state: any) => state.PathLab)
  const orderFlowPopup: any = useOrderFlowPopups(false)
  const { selectedPhysician, selectedOrganization, selectedPathLab } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [previousOrganizationAndPhysician, setPreviousOrganizationAndPhysician] = useState<any>(null)
  const [previousOrgId, setPreviousOrgId] = useState<any>(null)
  const [previousPhysId, setPreviousPhysId] = useState<any>(null)
  const [previousPathLabId, setPreviousPathLabId] = useState(selectedPathLab?.id);
  const [gettingTests, setGettingTests] = useState<boolean>(false);
  const [allowedTestsCOA, setAllowedTestsCOA] = useState([]);
  const [allowedTestsCalled, setAllowedTestsCalled] = useState<boolean>(false)
  const { id } = useParams<{ id: string }>();
  const profile = useSelector((state: any) => state.Profile)
  const isCOA = profile?.profile?.roles?.includes('Collaborative Order Agreement')
  const { sessionDestroyed } = useSelector((state: any) => state.Session)
  const listener = (e: any): any => {
    e.preventDefault()
    e.returnValue = 'Are you sure you want to exit? All data will be lost.'
  }
  async function getAllowedTestsCOA() {

    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`
      }
    }

    setGettingTests(true)
    setAllowedTestsCalled(true)

    try {
      const { statusCode, data } = await http.getAllowedTests(`/v1/order-agreement/get-allowed-tests?userId=${selectedPhysician?.id}&pathLabId=${selectedPathLab?.id}&organizationId=${selectedOrganization?.id}`, config)

      if (statusCode === 200 && data) {
        setGettingTests(false)
        setAllowedTestsCOA(data)
        if (data.length == 0) {
          dispatch(NotificationActions.showNotification({
            type: 'success',
            title: '',
            hideClose: true,
            message: 'Not authorized to place any order.',
            customFunction() {
            },
            buttons: [
              {
                onClick: () => {
                  location.href = 'mailto:clinicalservices@castlebiosciences.com';
                  history.replace('/dashboard')

                  dispatch(NotificationActions.hideNotifications())
                },
                title: 'Contact CS Team',
                type: "secondary",
              },
              {
                onClick: () => {
                  history.replace('/dashboard')
                  dispatch(NotificationActions.hideNotifications())
                },
                title: 'OK',
                type: "primary",
              }
            ]
          }))
        }
      }
    } catch (error: any) {
      setGettingTests(false)
      setAllowedTestsCalled(false)
    }
  }

  // Window refresh/reload listener added to prevent user from refreshing to prevent data loss.
  useEffect(() => {
    dispatch(resetMode())
    // window.addEventListener('beforeunload', listener)
    // dispatch(editMode())

    // dispatch(ButtonStateActions.setButtonState('online order'))
    dispatch(ButtonStateActions.setButtonState('edit online order'))


    if (id && !loading) {
      const options = {
        id,
        onError: (message: string) => {
          toastMessages.error(message)
          history.replace('/test-orders')
        },
        onSuccess: (id: string) => {
          const options = {
            orgId: id ?? '',
            config: {
              headers: {
                Authorization: `Bearer ${authState?.accessToken?.accessToken}`
              }
            },
            onError: (message: string) => toastMessages.error(message)
          }
          fetchTestsAndIcdCodes(options)
        },
        config: {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`
          }
        }
      }
      fetchOrderById(options)
    }

    return () => {
      // window.removeEventListener('beforeunload', listener)
      dispatch(updatePhysicianSignCheck(false))
    }
  }, [id])

  useEffect(() => {
    if (isCOA && allowedTestsCalled === false && (selectedPhysician?.id !== profile?.profile?.id)) {
      getAllowedTestsCOA()
    }
    dispatch(resetSaveOrder())
    dispatch(ButtonStateActions.setButtonState('edit online order'))
  }, [])

  useEffect(() => {
    // setPreviousOrganizationAndPhysician((prevState: any) => ({ ...prevState, organizationId: selectedOrganization?.id, physicianId: selectedPhysician?.id }))

    if (!!previousOrgId && ((selectedOrganization?.id !== previousOrgId)) && !sessionDestroyed) {
      setShowPopup(true)
    }
    if (!!previousPhysId && ((selectedPhysician?.id !== previousPhysId)) && !sessionDestroyed) {
      setShowPopup(true)
    }
    if (!!previousPathLabId && ((selectedPathLab?.id !== previousPathLabId)) && (selectedPhysician?.id !== profile?.profile?.id) && !sessionDestroyed) {
      setShowPopup(true)
    }
    // if (!!previousOrganizationAndPhysician && ((selectedOrganization?.id !== previousOrganizationAndPhysician?.organizationId) || (selectedPhysician?.id !== previousOrganizationAndPhysician?.physicianId))) {
    //   setShowPopup(true)
    // }
  }, [selectedOrganization?.id, selectedPhysician?.id, selectedPathLab?.id])

  useEffect(() => {
    if (
      selectedOrganization === null ||
      selectedOrganization == undefined ||
      selectedPhysician === null ||
      selectedOrganization?.users
        ?.filter((e: any) => {
          return e?.roles?.includes("Physician");
        })
        .map((e: any) => {
          return { id: e.id, label: e?.firstName };
        }).length < 1
    ) {
      // setShowPopup(true);
      dispatch(resetSelectedTest());
      setValidSections([]);
    }
    if (previousOrgId === null) {
      (selectedOrganization?.id)
    }
    if (previousPhysId === null)
      setPreviousPhysId(selectedPhysician?.id)
    if (previousPathLabId === null)
      setPreviousPathLabId(selectedPathLab?.id)
  }, [selectedPhysician?.id, selectedOrganization?.id, selectedPathLab?.id]);

  useEffect(() => {
    let scrollSection = query.get('section')?.toLowerCase() ?? ''

    if ((profile?.roles?.includes('PathLab User') || profile?.profile?.roles?.includes('PathLab User')) && (profile?.roles?.length == 1 || profile?.profile?.roles?.length == 1)) {
      return history.replace('/unauthorized')
    }


    if (!!scrollSection) {
      const section: any = document.getElementById(scrollSection.includes('billing info') ? 'billing info & medicare' : scrollSection)
      section?.scrollIntoView({ behavior: "auto", block: "nearest" })

      if (!loading && !!data) {
        setSelectedTab(scrollSection.includes('billing info') ? 'billing info & medicare' : scrollSection)
        setTimeout(() => {
          setFirstScroll(false)
          section?.scrollIntoView({ behavior: "auto", block: "nearest" })
        }, 1500)
      }
    }

    if (data && data?.patientId) {
      setPatientType('existing')
    }

    if (!loading && !!data) {
      setFirstScroll(false)
      if (data.id == id && (data?.orderStatus?.toLowerCase() === 'submitted' || data?.orderStatus?.toLowerCase() === 'completed')) {
        history.replace('/test-orders')
        toastMessages.error(`Cannot edit order after it is ${data?.orderStatus?.toLowerCase()}`)
      }
    }

    if (data) {
      setPreviousOrgId(data?.organization_id)
      setPreviousPhysId(data?.clinician_id)
      dispatch(updatePhysicianSignCheck(data?.isPhysicianSignPermission))
    }
  }, [loading])

  function handleTab(tab: string): void {
    setSelectedTab(tab)
  }

  function RefetchDashboard() {
    let isPLU = profile?.profile?.roles?.includes('PathLab User')

    const options: DashboardCallModel = {
      onError: (message: string) => toastMessages.error(message ? message : 'Something Went wrong'),
      config: { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken} ` } },
      pathLabId: selectedPathLab?.id ? selectedPathLab.id : null,
      organizationId: selectedOrganization?.id ? selectedOrganization.id : null,
      physicianId: selectedPhysician?.id ? selectedPhysician.id : null,
      isPLU
    }

    fetchDashboard(options)
  }

  function handleSubmit(data: OrderFormValues, { resetForm, setFieldError }: any): void {
    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`
      }
    }

    const options: OnlineOrderOptions = {
      data,
      config,
      selectedOrganization: data.organization_id ?? selectedOrganization?.id ?? '',
      selectedPhysician: data?.clinician_id ?? selectedPhysician.id,
      onError: (message: string) => toastMessages.error(message),
      onSuccess: (status: string) => {
        history.replace(`/test-orders/order-created?status=${status}`)
        dispatch(resetMode())
        RefetchDashboard()
      }
    }
    updateOnlineOrder(options)
    // window.removeEventListener('beforeunload', listener)
    // history.push('/test-orders/order-created', { replace: true })
  }

  function Render({
    errors,
    touched,
    setFieldValue,
    isValid,
    values,
    initialValues,
    setFieldError,
    validateField,
    ...rest
  }: any): JSX.Element {
    return (
      <Fragment>
        <OnlineOrderPDF values={{ ...data, ...values }} />
        <Form className="col-span-4 flex flex-col  w-full overflow-auto">
          <div id='updateOnlineOrderForm' className="flex flex-col overflow-auto">
            <FormHeader
              selectedTab={selectedTab}
              validSections={validSections}
              patientType={patientType}
              setPaitentType={setPatientType}
            />
            <div className="px-10 lg:px-[109px]">
              <RenderFields
                initalValues={initialValues}
                values={{ ...data, ...values }}
                isPage={false}
                patientType={patientType}
                firstScroll={firstScroll}
                errors={errors}
                touched={touched}
                isValid={isValid}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                selectedTab={selectedTab}
                setSelectedTab={handleTab}
                setValidSections={setValidSections}
                setPatientType={setPatientType}
                validateField={validateField}
                touchedSections={touchedSections}
                setTouchedSections={setTouchedSections}
                allowedTestsCOA={allowedTestsCOA}
                gettingTests={gettingTests}
                {...rest}
              />
            </div>
          </div>
        </Form>
      </Fragment >

    )
  }

  function hidePopup(): void {
    let previousPhysician: any = null
    orgsList.forEach((organization: any) => {
      let found = organization?.users?.find((user: any) => user?.id === previousPhysId)

      if (found) {
        previousPhysician = found
        return
      }
    })

    dispatch(updateSelectedOrganization(orgsList?.find((organization: any) => organization?.id === previousOrgId)))

    if (previousPhysician)
      dispatch(updateSelectedPhysician(previousPhysician))
    setShowPopup(false)
  }
  function hidePathLabPopup(): void {
    let previousPathLab: any = null;

    pathLabList.forEach((pathlab: any) => {
      if (pathlab.id === previousPathLabId) {
        previousPathLab = pathlab;
        return;
      }
    });

    if (previousPathLab) {
      dispatch(updateSelectedPathLab(previousPathLab));
    }

    setShowPopup(false);
  }
  function handlePathLabChange() {
    setShowPopup(false);
    setPreviousPathLabId(selectedPathLab?.id)
    if (selectedPhysician?.id !== profile?.profile?.id && isCOA) {
      getAllowedTestsCOA()
    }
  }
  function hidePopupAndNavigate(): void {
    setShowPopup(false)
    history.replace('/test-orders')
  }


  function handleReset(setFieldValue: any): void {
    setValidSections([])
    dispatch(handleDiscard())
    dispatch(resetMode())
    dispatch(resetSelectedTest())
  }


  function RenderMessage(): JSX.Element {
    if (!selectedOrganization && selectedPhysician)
      return (
        <p className="mt-5 text-royalBlue">
          Please select an organization before proceeding with creating a test order
        </p>
      );
    if ((selectedPathLab?.id !== previousPathLabId)) {
      return (
        <p className="mt-5 text-royalBlue"> You are about to change the path lab of the online order.<br />Are you sure you want to proceed ?</p>
      );
    }
    if (selectedOrganization == null)
      return (
        <p className="mt-5 text-royalBlue">
          Please specify the organization and the clinician in
          <br /> the context for whom you will be starting the test order for.
        </p>
      );
    if (selectedOrganization !== null && selectedPhysician == null) {
      if (selectedOrganization?.users
        ?.filter((e: any) => {
          return e?.roles?.includes("Physician");
        })
        .map((e: any) => {
          return { id: e.id, label: e?.firstName };
        }).length < 1) {
        return (
          <p className="mt-5 text-royalBlue">
            Please add a clinician before proceeding with creating a test order
          </p>
        )
      } else if ((selectedOrganization?.users
        ?.filter((e: any) => {
          return e?.roles?.includes("Physician");
        })
        .map((e: any) => {
          return { id: e.id, label: e?.firstName };
        }).length > 1)) {
        return (
          <p className="mt-5 text-royalBlue">
            Please select a clinician before proceeding with creating a test order.
          </p>
        );
      }
      else return <p className="mt-5 text-royalBlue">Please select a clinician before proceeding with creating a test order.</p>
    }
    return (
      <p className="mt-5 text-royalBlue">
        All changes will be discarded.
      </p>
    );
  }
  return (
    <Fragment>
      <CustomPopup
        buttons={[
          { onClick: selectedPathLab?.id !== previousPathLabId ? hidePathLabPopup : hidePopup, title: 'Cancel', type: 'secondary' },
          { onClick: selectedPathLab?.id !== previousPathLabId ? handlePathLabChange : hidePopupAndNavigate, title: "OK", type: "primary" },
        ]}
        visible={showPopup}
        hidePopup={selectedPathLab?.id !== previousPathLabId ? hidePathLabPopup : hidePopup}
      >
        <RenderMessage />
      </CustomPopup>
      <TestOrdersLayout
        touchedSections={touchedSections}
        selectedTab={selectedTab}
        headerTitle={data && `${data?.patient_firstname ? `${data?.patient_firstname} -` : ''} ${data?.selectedTest?.name ? testNameSplitter(data?.selectedTest?.name) : ''}`}
        showTitleLoading={loading}
        setSelectedTab={handleTab}
        isEdit
        validSections={validSections}
        setValidSections={setValidSections}
      >
        {loading
          ? (
            <div className="col-span-4 flex flex-col w-full overflow-auto">
              <div className="my-5 flex flex-col overflow-auto">
                <div className='px-10 lg:px-[108px] border-none mb-2 border-8'>
                  <HeaderOnlineFormLoadingIndicator />
                </div>
                <div className="px-10 lg:px-[109px]">
                  <TestSelectorLoadingIndicator />
                </div>
              </div>
            </div>
          )
          : (
            data && <Formik
              innerRef={formRef}
              validationSchema={OrderFormValidation}
              initialValues={data}
              onReset={handleReset}
              onSubmit={handleSubmit}
              initialStatus={false}
              isInitialValid={false}
              render={Render}
            />
          )}
      </TestOrdersLayout>
    </Fragment>
  )
}

const mapStateToProps = (state: any): any => ({
  loading: state.OnlineOrderForm.fetchingOrderByID,
  error: state.OnlineOrderForm.fetchingOrderByIDError,
  orderData: state.OnlineOrderForm.orderData,
  creatingOrder: state.OnlineOrderForm,
  createError: state.OnlineOrderForm
})

const mapDispatchToProps = {
  updateOnlineOrder: updateOnlineOrder,
  fetchOrderById: fetchOrderById,
  fetchTestsAndIcdCodes: fetchTestsAndIcdCodes,
  fetchDashboard: DashboardActions.fetchDashboard
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrderScreen)
