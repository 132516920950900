/* eslint-disable @typescript-eslint/space-before-function-paren */
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../components/atomic/Button";
import ReviewSection from "../../../components/organism/OnlineOrderForm/ReviewSection";
import PatientInfoForm from "../../../components/organism/TestOrderForms/PatientInfoForm";
import AdditionalInfoSection from "../../../components/organism/TestOrderForms/Sections/AdditionalInfoSection";
import BillingInfoSection from "../../../components/organism/TestOrderForms/Sections/BillingInfoSection";
import ClinicalInfoSection from "../../../components/organism/TestOrderForms/Sections/ClinicalInfoSection";
import LaboratoryInfoSection from "../../../components/organism/TestOrderForms/Sections/LaboratoryInfoSection";
import SupportingDocsSection from "../../../components/organism/TestOrderForms/Sections/SupportingDocsSection";
import SelectTestForm from "../../../components/organism/TestOrderForms/SelectTestFrom";
import TestOrdersLayout from "../../../Containers/TestOrdersLayout";
import OrderFormValues from "../../../models/forms/onlineorderformvalues";
import OrderFormValidation from "../../../validations/orderformvalidation";
import { formRef } from "../ManageScreen";
import TestSelectorLoadingIndicator from "../../../components/global/LoadingIndicators/CreateOnlineOrderIndicators/TestSelectorLoadingIndicator";
import HeaderOnlineFormLoadingIndicator from "../../../components/global/LoadingIndicators/CreateOnlineOrderIndicators/HeaderOnlineFormLoadingIndicator";
import CustomPopup from "../../../components/global/CustomPopup";
import {
  editMode,
  fetchOrderById,
  fetchTestsAndIcdCodes,
  handleDiscard,
  resetMode,
  resetSelectedTest,
  updateOnlineOrder,
} from "../../../Store/Actions/OnlineOrderFormActions";
import OnlineOrderPDF, {
  printRef,
} from "../../../components/organism/OnlineOrderForm/OnlineOrderPDF";
import { OnlineOrderOptions } from "../../../models/forms/online_order_options.interface";
import toastMessages from "../../../utils/helpers/toastMessage";
import { useOktaAuth } from "@okta/okta-react";
import MedicareSection from "../../../components/organism/TestOrderForms/Sections/MedicareSection";
import pdfHelper from "../../../utils/helpers/pdfHelper";
import { useReactToPrint } from "react-to-print";
import DownloadHelper from "../../../utils/helpers/fileDownloader";
import useOrderButtonTitle from "../../../utils/customHooks/buttonTitle";
import pageStyle from "../../../constants/pageStyle";
import testNameSplitter from "../../../utils/helpers/testNameSplitter";
import http from "../../../Store/API/HttpMethods";
import NotificationActions from "../../../Store/Actions/NotificationsActions";

function RenderFields({
  initialValues,
  setFieldValue,
  errors,
  touched,
  setImage,
  isValid,
  values,
  selectedTab,
  patientType,
  setFieldError,
  setSelectedTab,
  setValidSections,
  validateField,
  ...rest
}: any): JSX.Element {
  const { mode } = useSelector((state: any) => state.OnlineOrderForm);

  return (
    <Fragment>
      <div>
        <ReviewSection
          isApproval
          values={values}
          setFieldValue={setFieldValue}
          setSelectedTab={setSelectedTab}
          isValid={isValid}
        />
      </div>
      <div className={"hidden"}>
        <SelectTestForm
          initialValues={initialValues}
          isEdit
          selectedTab={selectedTab}
          values={values}
          setValidSections={setValidSections}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          setSelectedTab={setSelectedTab}
          errors={errors}
          touched={touched}
          {...rest}
        />
        <PatientInfoForm
          initialValues={initialValues}
          selectedTab={selectedTab}
          setFieldError={setFieldError}
          values={values}
          patientType={patientType}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          setSelectedTab={setSelectedTab}
          setValidSections={setValidSections}
          {...rest}
        />
        <BillingInfoSection
          initialValues={initialValues}
          values={values}
          isUpdate
          setFieldError={setFieldError}
          patientType={patientType}
          setFieldValue={setFieldValue}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          errors={errors}
          touched={touched}
          setValidSections={setValidSections}
          {...rest}
        />
        {(values?.insurance.includes("Medicare") ||
          values?.insurance.includes("Medicaid")) && (
          <MedicareSection
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            setValidSections={setValidSections}
            setSelectedTab={setSelectedTab}
            {...rest}
          />
        )}
        <ClinicalInfoSection
          initialValues={initialValues}
          setFieldError={setFieldError}
          values={values}
          patientType={patientType}
          isValid={isValid}
          selectedTab={selectedTab}
          setFieldValue={setFieldValue}
          errors={errors}
          setValidSections={setValidSections}
          setSelectedTab={setSelectedTab}
          touched={touched}
          {...rest}
        />
        <AdditionalInfoSection
          initialValues={initialValues}
          values={values}
          setFieldError={setFieldError}
          isUpdate
          patientType={patientType}
          selectedTab={selectedTab}
          isValid={isValid}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          setSelectedTab={setSelectedTab}
          setValidSections={setValidSections}
          {...rest}
        />
        <LaboratoryInfoSection
          initialValues={initialValues}
          values={values}
          patientType={patientType}
          setFieldValue={setFieldValue}
          isUpdate
          selectedTab={selectedTab}
          setFieldError={setFieldError}
          setSelectedTab={setSelectedTab}
          errors={errors}
          touched={touched}
          setValidSections={setValidSections}
          {...rest}
        />
        <SupportingDocsSection
          initialValues={initialValues}
          values={values}
          patientType={patientType}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          isEdit
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          errors={errors}
          touched={touched}
          setValidSections={setValidSections}
          {...rest}
        />
      </div>
    </Fragment>
  );
}

function OrderReviewScreen({
  createError,
  updateOnlineOrder,
  creatingOrder,
  orderData: data,
  loading,
  error,
  fetchOrderById,
  fetchTestsAndIcdCodes,
  savingOrder,
}: any): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authState } = useOktaAuth();
  const [selectedTab, setSelectedTab] = useState<string>("select test");
  const [patientType, setPatientType] = useState<string>("new");
  const [validSections, setValidSections] = useState<any[]>([]);
  const { profile } = useSelector((state: any) => state.Profile);
  const { submit } = useSelector((state: any) => state.ButtonState);
  const { clinicalInfoValid } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const { formSubmit } = useSelector((state: any) => state.ButtonState);
  const { selectedTest } = useSelector((state: any) => state.OnlineOrderForm);
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);
  const [downloadingPDF, setDownloadingPDF] = useState<boolean>(false);
  const [deletingOrder, setDeletingOrder] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  const listener = (e: any): any => {
    e.preventDefault();
    e.returnValue = "Are you sure you want to exit? All data will be lost.";
  };

  // Window refresh/reload listener added to prevent user from refreshing to prevent data loss.
  useEffect(() => {
    dispatch(editMode());
    if (id && !loading) {
      const options = {
        id,
        onError: (message: string) => {
          toastMessages.error(message);
          history.replace("/test-orders");
        },
        onSuccess: (id: string) => {
          const options = {
            orgId: id ?? "",
            config: {
              headers: {
                Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
              },
            },
            onError: (message: string) => toastMessages.error(message),
          };
          fetchTestsAndIcdCodes(options);
        },
        config: {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        },
      };
      fetchOrderById(options);
    }
    return () => {
      dispatch(resetMode());
    };
  }, []);

  function handleTab(tab: string): void {
    setSelectedTab(tab);
  }

  function handleSubmit(
    data: OrderFormValues,
    { resetForm, setFieldError }: any
  ): void {
    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    };
    const options: OnlineOrderOptions = {
      data,
      config,
      selectedOrganization:
        data.organization_id ?? selectedOrganization?.id ?? "",
      selectedPhysician: data?.clinician_id ?? selectedPhysician.id,
      onError: (message: string) => toastMessages.error(message),
      onSuccess: (message: string) => {
        history.replace("/test-orders/order-created");
        dispatch(resetMode());
      },
    };
    updateOnlineOrder(options);
    window.removeEventListener("beforeunload", listener);
    // history.push('/test-orders/order-created', { replace: true })
  }

  function Render({
    errors,
    touched,
    setFieldValue,
    isValid,
    values,
    initialValues,
    setFieldError,
    validateField,
    ...rest
  }: any): JSX.Element {
    return (
      <Fragment>
        <OnlineOrderPDF
          values={values}
          isSummary
          // isApproval
          formId={`${data?.patient_firstname} ${data?.patient_lastname} - ${data?.selectedTest?.name}`}
        />
        <Form className="col-span-4 flex flex-col  w-full overflow-auto">
          <div
            id="updateOnlineOrderForm"
            className="flex flex-col overflow-auto"
          >
            <div className="px-10 lg:px-[109px]">
              <RenderFields
                initalValues={initialValues}
                values={values}
                isPage={false}
                patientType={patientType}
                errors={errors}
                touched={touched}
                isValid={isValid}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                selectedTab={selectedTab}
                setSelectedTab={handleTab}
                setValidSections={setValidSections}
                setPatientType={setPatientType}
                validateField={validateField}
                {...rest}
              />
            </div>
          </div>
        </Form>
      </Fragment>
    );
  }

  function hidePopup(): void {
    setShowPopup(false);
    history.replace("/test-orders");
  }
  const { selectedPhysician, selectedOrganization } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const buttonTitle = useOrderButtonTitle(
    data?.isPhysicianSignPermission,
    data?.clinician_id
  );

  function handleReset(setFieldValue: any): void {
    setValidSections([]);
    dispatch(handleDiscard());
    dispatch(resetMode());
    dispatch(resetSelectedTest());
  }
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: pageStyle,
    copyStyles: false,
  });

  async function handleSaveAndExit() {
    if (savingOrder === true) return;
    if (!!formRef.current === false) return;

    const data = { ...formRef.current.values, isSave: "true" };
    if (
      data?.selectedTest?.id === null ||
      data?.selectedTest?.id === undefined
    ) {
      return toastMessages.error("Please select test type in order to save");
    }

    if (
      !!data?.patient_firstname === false ||
      !!data?.patient_lastname === false ||
      !data?.patient_firstname.trim() ||
      !data?.patient_lastname.trim() ||
      !!data?.patient_dob === false
    ) {
      return toastMessages.error(
        "Please enter patient first name, last name and date of birth in order to save"
      );
    }

    const validate = await formRef?.current.validateForm(data);
    const errorsOtherThanRequired = Object.keys(validate).filter((key) => {
      if (
        validate[key].includes("required") === false &&
        validate[key] !==
          "additionalClinician field must have at least 1 items" &&
        validate[key].includes("skippedDocs must be a `boolean") === false
      )
        return true;
      return false;
    });

    let errors: any[] = [];
    Object.entries(validate).forEach(([key, value]) => {
      if (errorsOtherThanRequired.includes(key)) {
        if (Array.isArray(value)) {
          value.map((error, index) => {
            errors.push(`\n${key}${index + 1} ${JSON.stringify(error)}`);
          });
        } else errors.push(value);
      }
    });

    if (errorsOtherThanRequired.length > 0)
      return toastMessages.error(`${errors.join(",")}`);

    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    };
    const options: OnlineOrderOptions = {
      data,
      config,
      selectedOrganization:
        data.organization_id ?? selectedOrganization?.id ?? "",
      selectedPhysician: data?.clinician_id ?? selectedPhysician.id,
      onError: (message: string) => toastMessages.error(message),
      onSuccess: (message: string) => {
        history.replace("/test-orders");
      },
    };
    updateOnlineOrder(options);
  }
  function PDFDownload() {
    if (downloadingPDF) return;

    setDownloadingPDF(true);
    const token = authState?.accessToken?.accessToken ?? "";
    let pdfName = `${id}_${
      !!data?.patientName
        ? data?.patientName == "N/A"
          ? "Patient"
          : data?.patientName
        : "Patient"
    }_${data?.selectedTest?.name}`;
    // pdfHelper.download(pdfName, false)
    DownloadHelper.downloadMultiplePDF(
      [{ id: Number(id), fileName: pdfName }],
      token,
      false,
      (message: string) => {
        toastMessages.error(message);
        setDownloadingPDF(false);
      },
      () => {
        setDownloadingPDF(false);
      }
    );
  }
  function PrintOrder() {
    // let pdfName = `${data?.id}_${!!data?.data.patientName ? data?.data?.patientName == 'N/A' ? 'Patient' : data?.data?.patientName : 'Patient'}_${data?.selectedTest?.name}`
    let pdfName = `${data?.patient_firstname} ${data?.patient_lastname} - ${data?.selectedTest?.name}`;

    pdfHelper.printOrder(pdfName, handlePrint);
  }
  function RenderMessage(): JSX.Element {
    if (selectedOrganization == null)
      return (
        <p className="mt-5 text-royalBlue">
          Please specify the organization and the clinician in
          <br /> the context for whom you will be starting the test order for.
        </p>
      );
    if (selectedPhysician === null)
      return (
        <p className="mt-5 text-royalBlue">
          Please add a clinician before proceeding with creating a test order.
        </p>
      );

    return (
      <p className="mt-5 text-royalBlue">
        Please specify the organization and the clinician in
        <br /> the context for whom you will be starting the test order for.
      </p>
    );
  }
  function ReportDownload() {
    if (downloadingReport) return;

    if (data?.orderStatus?.toLowerCase() === "completed") {
      setDownloadingReport(true);
      let name = `${
        data?.patient_firstname ? `${data?.patient_firstname} - ` : ""
      }${data.selectedTest.name} Report`;
      let token = authState?.accessToken?.accessToken ?? "";
      const onError = (error: any) => {
        toastMessages.error(error.message);
        setDownloadingReport(false);
      };
      const onSuccess = () => {
        setDownloadingReport(false);
      };

      DownloadHelper.downloadReport(
        data.orderId,
        name,
        token,
        onError,
        onSuccess
      );
    }
  }
  const isDownloadButtonDisabled = (downloadingReport: boolean, data: any) => {
    if (downloadingReport) {
      return true;
    }

    if (
      data?.status === 100 &&
      Array.isArray(data.orderReport) &&
      data.orderReport.length > 0
    ) {
      return false;
    }

    return true;
  };

  async function handleDeleteOrder() {
    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    };
    try {
      setDeletingOrder(true);
      const response = await http.discardOrder(
        `v1/orders/new-flow/${formRef.current.values.orderId}`,
        config
      );
      console.log({ deleteOrderResponse: response });
      history.replace("/test-orders");
      setDeletingOrder(false);
    } catch (error: any) {
      toastMessages.error(error?.message ?? "Cannot delete order");
      setDeletingOrder(false);
    }
  }
  function CustomButtons(): JSX.Element {
    return (
      <div className={"flex flex-wrap items-center justify-end"}>
        <Button
          btnType="secondary"
          onClick={PrintOrder}
          title={"Print"}
          className={"lg:mr-2 xl:mr-5 2xl:mr-5 py-1.5 tab:mr-1"}
        />

        <Button
          btnType="secondary"
          onClick={PDFDownload}
          submitting={downloadingPDF}
          disabled={
            downloadingPDF ? true : !!data?.eRequestId == true ? false : true
          }
          title={data?.status < 40 ? "Download Order" : "Download Receipt"}
          className={"lg:mr-2 xl:mr-5 2xl:mr-5 py-1.5 tab:mr-1"}
        />

        <Button
          btnType="secondary"
          submitting={downloadingReport}
          onClick={ReportDownload}
          disabled={isDownloadButtonDisabled(downloadingReport, data)}
          title={"Download Report"}
          className={"lg:mr-2 xl:mr-5 2xl:mr-5 py-1.5 tab:mr-1"}
        />
        <Button
          btnType="secondary"
          onClick={() => {
            formRef?.current?.resetForm();
          }}
          // disabled={data?.status >= 40}
          disabled
          title={"Discard"}
          className={"lg:mr-2 xl:mr-5 2xl:mr-5 py-1.5 tab:mr-1"}
        />
        {formRef &&
          formRef.current &&
          formRef?.current?.values?.status < 40 && (
            <Button
              btnType="secondary"
              onClick={() => {
                dispatch(
                  NotificationActions.showNotification({
                    type: "error",
                    hideClose: true,
                    message: "Are you sure you want to delete order?",
                    title: "Online Order",
                    customFunction: handleDeleteOrder,
                  })
                );
              }}
              title={"Delete Order"}
              disabled={deletingOrder}
              submitting={deletingOrder}
              className={"lg:mr-2 xl:mr-5 2xl:mr-5 py-1.5 tab:mr-1"}
            />
          )}
        <Button
          btnType="secondary"
          onClick={handleSaveAndExit}
          submitting={savingOrder}
          // disabled={data?.status < 40 ? false : true}
          disabled
          title={"Save and Exit"}
          className={"lg:mr-2 xl:mr-5 2xl:mr-5 py-1.5 tab:mr-1 tab:my-1"}
        />
        <Button
          btnType="secondary"
          disabled={data?.status < 40 ? false : true}
          onClick={() => {
            history.replace(`/test-orders/online-order/${id}`);
          }}
          title={"Edit"}
          className={"lg:mr-2 xl:mr-5 2xl:mr-5 py-1.5 tab:mr-1 tab:my-1"}
        />
        <Button
          btnType="primary"
          onClick={formSubmit ? () => {} : formRef?.current?.submitForm}
          // disabled={data?.status >= 40 || submit === false || (clinicalInfoValid === false && (selectedTest.id !== 2 || selectedTest.id !== 3 || selectedTest.id !== 5 || selectedTest.id !== 4))}
          disabled={
            data?.status >= 40 ||
            submit === false ||
            formSubmit ||
            (clinicalInfoValid === false &&
              (selectedTest.id == 2 ||
                selectedTest.id == 3 ||
                selectedTest.id == 5))
          }
          submitting={formSubmit}
          className={
            "py-1.5 lg:-mr-5 2xl:mr-0 xl:mr-0  my-0 'border border-primary tab:my-1"
          }
          title={buttonTitle}
          // title={profile && Array.isArray(profile.roles) && profile.roles.includes('Physician') ? 'Submit' : 'Send for approval'}
          // title={profile && Array.isArray(profile?.roles) && profile?.roles?.includes('Physician') ? 'Submit' : (selectedPhysician?.profile?.signPermission && data?.isPhysicianSignPermission) ? 'Submit' : 'Send for approval'}
        />
      </div>
    );
  }
  return (
    <Fragment>
      <CustomPopup
        buttons={[]}
        hidePopup={hidePopup}
        handleOk={hidePopup}
        visible={showPopup}
      >
        <RenderMessage />
      </CustomPopup>
      <TestOrdersLayout
        selectedTab={selectedTab}
        CustomButtons={CustomButtons}
        headerTitle={
          data &&
          `${data?.patient_firstname ? `${data?.patient_firstname} -` : ""} ${
            data?.selectedTest?.name
              ? testNameSplitter(data?.selectedTest?.name)
              : ""
          }`
        }
        showTitleLoading={loading}
        setSelectedTab={handleTab}
        isEdit
        validSections={validSections}
        setValidSections={setValidSections}
      >
        {loading ? (
          <div className="col-span-4 flex flex-col w-full overflow-auto">
            <div className="my-5 flex flex-col overflow-auto">
              <div className="px-10 lg:px-[109px] border-none mb-2 border-8">
                <HeaderOnlineFormLoadingIndicator />
              </div>
              <div className="px-10 lg:px-[109px]">
                <TestSelectorLoadingIndicator />
              </div>
            </div>
          </div>
        ) : (
          data && (
            <Formik
              innerRef={formRef}
              validationSchema={OrderFormValidation}
              initialValues={data}
              onReset={handleReset}
              onSubmit={handleSubmit}
              initialStatus={false}
              isInitialValid={false}
              render={Render}
            />
          )
        )}
      </TestOrdersLayout>
    </Fragment>
  );
}

const mapStateToProps = (state: any): any => ({
  loading: state.OnlineOrderForm.fetchingOrderByID,
  error: state.OnlineOrderForm.fetchingOrderByIDError,
  orderData: state.OnlineOrderForm.orderData,
  creatingOrder: state.OnlineOrderForm,
  createError: state.OnlineOrderForm,
  screen: state.ButtonState.screen,
  submit: state.ButtonState.submit,
  profile: state.Profile.profile,
  formSubmit: state.ButtonState.formSubmit,
  clinicalInfoValid: state.OnlineOrderForm.clinicalInfoValid,
  submitting: state.InviteUser.submitting,
  selectedUser: state.PortalUsers.selectedUser,
  mode: state.OnlineOrderForm.mode,
  selectedTest: state.OnlineOrderForm,
  savingOrder: state.OnlineOrderForm.savingOrder,
});

const mapDispatchToProps = {
  updateOnlineOrder: updateOnlineOrder,
  fetchOrderById: fetchOrderById,
  fetchTestsAndIcdCodes: fetchTestsAndIcdCodes,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderReviewScreen);
