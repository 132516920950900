import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useSignCheckbox(): boolean {
  const [showSignCheckbox, setShowSignCheckbox] = useState(true)
  const { selectedOrganization, selectedPhysician, selectedTest } = useSelector((state: any) => state.OnlineOrderForm)
  const { profile } = useSelector((state: any) => state.Profile)
  const isDualRoleCOA = profile?.roles?.includes('Collaborative Order Agreement') && profile?.roles?.includes('Physician')
  const physicianSameAsUser = selectedPhysician?.id == profile?.id

  function determineDualRole(selectedPhysician: any) {
    let foundOrganization = null
    let physicianOrganizationRoles = !!selectedPhysician?.profile?.organizationRoles ? selectedPhysician?.profile?.organizationRoles : !!selectedPhysician?.organizationRoles ? selectedPhysician?.organizationRoles : null


    if (physicianOrganizationRoles) {
      if (typeof (physicianOrganizationRoles) === 'string')
        foundOrganization = JSON?.parse(physicianOrganizationRoles)[selectedOrganization?.id]
      else
        foundOrganization = physicianOrganizationRoles[selectedOrganization?.id]
    }

    if (foundOrganization && foundOrganization?.roles?.includes("Physician") && selectedPhysician?.id === profile?.id && selectedTest?.id != 8) {
      setShowSignCheckbox(false)
    } else {
      setShowSignCheckbox(true)
    }
  }

  useEffect(() => {
    if (selectedOrganization && selectedPhysician) {
      if (isDualRoleCOA && physicianSameAsUser)
        setShowSignCheckbox(false)
      else
        determineDualRole(selectedPhysician)
    }
  }, [selectedOrganization, selectedPhysician, selectedTest?.id])

  return showSignCheckbox
}

export default useSignCheckbox;