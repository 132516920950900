import store from "..";
import http from "../API/HttpMethods";
import * as actions from "../Actions/types";
import {
 ActionArgs,
 FETCH_ORGANIZATIONS,
 FETCH_ORGANIZATIONS_ERROR,
 FETCH_ORGANIZATIONS_SUCCESS,
} from "./types";
import { removeExtraSpace } from "../../utils/helpers/removeExtraSpace";

function handleDefaultOrganizationAndPhysician(data: any, dispatch: any) {
 // Fetch Organization which has physician
 let organizationsWithPhysicians = data?.filter(
  (org: any) =>
   org?.users?.length > 0 &&
   org?.users?.some(
    (user: any) =>
     user?.roles?.includes("Physician") && user?.isApproved === true
   )
 );

 if (organizationsWithPhysicians?.length > 0) {
  dispatch({
   type: actions.UPDATE_SELECTED_ORGANIZATION,
   payload: organizationsWithPhysicians[0],
  });
  // Select first physician from the first organization
  let selectedPhysician = organizationsWithPhysicians[0]?.users.find(
    (user: any) =>
   
    user?.roles?.includes("Physician") && user?.isApproved === true
  );

  if (selectedPhysician)
   dispatch({
    type: actions.UPDATE_SELECTED_PHYSICANS,
    payload: selectedPhysician,
   });
 } else {
  dispatch({
   type: actions.UPDATE_SELECTED_ORGANIZATION,
   payload: data[0],
  });
 }
}

const fetchOrganizations = (options: any) => async (dispatch: any) => {
 const { config, onError } = options;
 dispatch({ type: FETCH_ORGANIZATIONS });

 try {
  const result = await http.getOrganizations(
    `v1/organizations/new-flow/list?fetchUsers=true`,
   config
  );
  const { statusCode, data, error } = result;

  if (error) {
   onError(error);
   return dispatch({ type: FETCH_ORGANIZATIONS_ERROR });
  }
  if (data.length > 0) handleDefaultOrganizationAndPhysician(data, dispatch);

  dispatch({ type: FETCH_ORGANIZATIONS_SUCCESS, payload: data });
 } catch (error: any) {
  dispatch({ type: FETCH_ORGANIZATIONS_ERROR });
  const { response } = error;
  if (response?.data) {
   onError(response?.data?.message);
  } else {
   onError(error?.message);
  }
 }
};
const fetchOrganizationUsers = (options: any) => async (dispatch: any) => {
 const { config, onError, id } = options;
 const filters = { organizationId: id };
 const { OnlineOrderForm } = store.getState();
 const selectedPhysician = OnlineOrderForm?.selectedPhysician;
 const selectedOrganization = OnlineOrderForm?.selectedOrganization;

 const buildParams = (filters: any) => {
  const params = new URLSearchParams();

  Object.keys(filters).forEach(
   (key: string) => filters[key] && params.append(key, filters[key])
  );

  return params.toString();
 };

 dispatch({ type: actions.FETCH_ORG_USERS, payload: id });

 try {
  const result = await http.getOrganizationUsers(
   //  `v1/organizations/get-users?id=${id}`,
   `/v1/users/new-flow/list?${buildParams(filters)}`,
   config
  );
  if (result.data) {
   if (selectedPhysician?.id && selectedOrganization?.id == id) {
    let found = result.data.some(
     (user: any) => user.id != selectedPhysician.id
    );
    if (found == false) {
     dispatch({ type: actions.RESET_SELECTED_PHYSICIAN });
    }
   }
   dispatch({
    type: actions.FETCH_ORG_USERS_SUCCESS,
    payload: {
     id: id,
     data: result.data,
    },
   });
  }
 } catch (error) {
  console.log({ error });
  dispatch({ type: actions.FETCH_ORG_USERS_ERROR });
 }
};
const fetchOrganizationsWithoutUsers =
 (options: any) => async (dispatch: any) => {
  const { config, onError, onEmpty } = options;
  dispatch({ type: FETCH_ORGANIZATIONS });

  try {
   const result = await http.getOrganizations(
    // "v1/organizations?excludeUsers=true",
    "v1/organizations/new-flow/list",
    config
   );
   const { statusCode, data, error } = result;

   if (error) {
    onError(error);
    return dispatch({ type: FETCH_ORGANIZATIONS_ERROR });
   }

   if (
    (data?.filter((e: any) => e?.isApproved === true)?.length == 0 ||
     data?.length == 0) &&
    onEmpty
   ) {
    onEmpty();
   }
   if (data.length > 0) handleDefaultOrganizationAndPhysician(data, dispatch);

   dispatch({ type: FETCH_ORGANIZATIONS_SUCCESS, payload: data });
   return data;
  } catch (error: any) {
   dispatch({ type: FETCH_ORGANIZATIONS_ERROR, payload: error });
   const { response } = error;

   if (response?.data) {
    onError(response?.data?.message);
   } else {
    onError(error.message);
   }
  }
 };

const createOrganization = (options: ActionArgs) => async (dispatch: any) => {
 const { data, onSuccess, onError } = options || {};
 const tests = {
  scc: "SCC",
  cm: "CM",
  um: "UM",
  tc: "TC",
  ddm: "DDM",
  idg: "IDG",
 };
 const cbsTests: string[] = [];

 Object.keys(tests).map((test: any) => {
  type ObjectKey = keyof typeof tests;
  const myKey = test as ObjectKey;

  if (data[test]) {
   cbsTests.push(tests[myKey]);
  }
 });
 const apiData = {
  name: removeExtraSpace(data.organization_name),
  description: removeExtraSpace(data.description),
  phone: data.phone,
  fax: data.fax,
  address: removeExtraSpace(data.address),
  address2: removeExtraSpace(data.address2),
  city: removeExtraSpace(data.city),
  state: removeExtraSpace(data.state),
  zipcode: data.zip_code,
  cbsTests,
  logo: data.logo ?? "",
 };

 const config = {
  headers: { Authorization: `Bearer ${data.accessToken}` },
 };

 dispatch({ type: actions.TOGGLE_FORM_SUBMIT, payload: true });
 dispatch({ type: actions.CREATE_ORGANIZATION });

 try {
  const result = await http.createOrganization(
   "v1/organizations/new-flow/create",
   apiData,
   config
  );

  const { success, error, data, statusCode } = result;

  if (error && onError != null) return onError(error);
  dispatch({ type: actions.CREATE_ORGANIZATION_SUCCESS });
  dispatch({ type: actions.TOGGLE_FORM_SUBMIT, payload: false });
  onSuccess != null && onSuccess();
 } catch (error: any) {
  const errorData = error?.response;
  const message = errorData?.data ? errorData?.data?.message : !!error;
  onError != null && onError(message);
  dispatch({ type: actions.CREATE_ORGANIZATION_ERROR });
  dispatch({ type: actions.TOGGLE_FORM_SUBMIT, payload: false });
 }
};

const updateOrganization = (options: ActionArgs) => async (dispatch: any) => {
 const { data: requestData, onSuccess, onError } = options || {};
 const tests = {
  scc: "SCC",
  cm: "CM",
  um: "UM",
  tc: "TC",
  ddm: "DDM",
  idg: "IDG",
 };
 const cbsTests: string[] = [];

 Object.keys(tests).map((test: any) => {
  type ObjectKey = keyof typeof tests;
  const myKey = test as ObjectKey;

  if (requestData[test]) {
   cbsTests.push(tests[myKey]);
  }
 });

 const apiData = {
  // description: requestData.description,
  // email: requestData.email_id,
  // phone: requestData.phone,
  // fax: requestData.fax,
  // address: requestData.address,
  // city: requestData.city,
  // state: requestData.state,
  // zipcode: requestData.zip_code,
  cbsTests,
  logo: requestData.logo ?? "",
 };

 const config = {
  headers: { Authorization: `Bearer ${requestData.accessToken}` },
 };

 dispatch({ type: actions.TOGGLE_FORM_SUBMIT, payload: true });
 dispatch({ type: actions.UPDATE_ORGANIZATION });

 try {
  const result = await http.updateOrganization(
   "v1/organizations/new-flow/" + requestData.orgId,
   apiData,
   config
  );

  const { success, error, data, message, statusCode } = result;

  if (error && onError != null) return onError(error);
  dispatch({ type: actions.UPDATE_ORGANIZATION_SUCCESS });
  dispatch({ type: actions.TOGGLE_FORM_SUBMIT, payload: false });
  onSuccess != null && onSuccess(message);
 } catch (error: any) {
  const { data: errorData } = error?.response;
  const message = errorData ? errorData.message : !!error;
  onError != null && onError(message);
  dispatch({ type: actions.UPDATE_ORGANIZATION_ERROR });
  dispatch({ type: actions.TOGGLE_FORM_SUBMIT, payload: false });
 }
};

const updateFetchingUsersToFalse = () => ({
 type: actions.SET_FETCH_USERS_FALSE,
});

const getOrganizationDetail =
 (options: ActionArgs) => async (dispatch: any) => {
  const { onSuccess, onError, data, config } = options;
  try {
   dispatch({ type: actions.FETCH_ORG_DETAIL });
   const { statusCode, data: responseData } = await http.getOrganizationDetail(
    `v1/organizations/${data.id}`,
    config
   );
   if (statusCode === 200) {
    dispatch({
     type: actions.FETCH_ORG_DETAIL_SUCCESS,
     payload: responseData,
    });
    dispatch({
     type: actions.UPDATE_REDUX_ORG_LIST,
     payload: responseData,
    });
    onSuccess?.(responseData);
   }
  } catch (error: any) {
   let message: string = error?.response?.data?.message || error?.message;
   onError?.(message);
   dispatch({ type: actions.FETCH_ORG_DETAIL_ERROR });
  }
 };

const OrganizationActions = {
 fetchOrganizations,
 fetchOrganizationsWithoutUsers,
 fetchOrganizationUsers,
 createOrganization,
 updateOrganization,
 updateFetchingUsersToFalse,
 getOrganizationDetail,
};

export default OrganizationActions;
