import React, { useCallback, useEffect, useRef, useState } from 'react'
import FilterItem from '../global/FilterItem'
import { useLocation } from 'react-router-dom'


function TableFilterDropdown({ title, Icon, containerStyle, list, onItemClick, hideHeader, suggestionVisible, hideSuggestion, itemContainerStyle, itemStyle, propDropdownRef }: any) {
  const dropDownRef = useRef<any>()
  const [visibility, setVisiblity] = useState<boolean>(false)
  const location = useLocation()

  const handleVisibility = useCallback((e: any) => {
    e.stopPropagation()
    setVisiblity((state: boolean) => !state)
  }, [])

  function handleClick(value: any) {
    onItemClick(value)
    setVisiblity(false)
  }

  useEffect(() => {
    const unsubscribe = window.addEventListener('mousedown', (event) => {
      if (propDropdownRef?.current && !propDropdownRef?.current?.contains(event.target)) {
        hideSuggestion && hideSuggestion()
        return setVisiblity(false)
      }
      else if (dropDownRef?.current && !dropDownRef?.current?.contains(event.target)) {
        hideSuggestion && hideSuggestion()
        return setVisiblity(false)
      }
    })

    return () => unsubscribe
  }, [])

  return <div ref={propDropdownRef ? null : dropDownRef} className={`relative ${containerStyle ?? ''}`}>
    {hideHeader ? null : <button type="button" onClick={handleVisibility} className="flex items-center py-2 hover:text-royalBlue text-[#97A0AF]">
      <Icon stroke="currentColor" />
      <p className="text-royalBlue text-sm ml-1 text-left">{title}</p>
    </button>}
    {(visibility || suggestionVisible) && <div
      className={`drop-shadow-md bg-white rounded absolute z-50 overflow-auto ${location?.pathname === '/shipments/unshipped-orders' ? 'w-[88%] px-0 justify-center flex flex-col py-1 items-center mx-auto':'h-64  py-2'} ${itemContainerStyle}`} >
      {list.map((item: any, index: number) => (<FilterItem
        key={index}
        label={item.label}
        selected={item.selected}
        itemStyle={itemStyle}
        onClick={() => handleClick(index)} />))}
    </div>}
  </div>
}
export default TableFilterDropdown