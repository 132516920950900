/* eslint-disable @typescript-eslint/indent */
/* eslint-disable comma-dangle */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/semi */
import { toast } from "react-toastify";

const showSuccess = (message: string, duration?: number): any =>
 toast.success(message, {
  position: "top-right",
  autoClose: duration ?? 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
 });

const showError = (message: string, duration?: number): any =>
 toast.error(message, {
  position: "top-right",
  autoClose: duration ?? 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
 });

const showInfo = (message: string, duration?: number): any =>
 toast.info(message, {
  position: "top-right",
  autoClose: duration ?? 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
 });

const showWarning = (message: string, duration?: number): any =>
 toast.warning(message, {
  position: "top-right",
  autoClose: duration ?? 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
 });

const toastMessages = {
 success: showSuccess,
 error: showError,
 info: showInfo,
 warning: showWarning,
};

export default toastMessages;
