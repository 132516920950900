function NewOrganizationLoadingIndicator () {
    return <div className="flex flex-col h-full self-center justify-center items-center w-full col-span-3">
        <div className="flex justify-between items-center align-center">
          <div className="bg-dropDownBorder w-[102px] h-[102px] align-center rounded-full animate-pulse self-center" />
      </div>
      <div className="bg-dropDownBorder w-[398px] h-2.5 mt-5 mb-0.5 align-center rounded-2xl animate-pulse self-center" />
      <div className="bg-dropDownBorder w-[314px] h-2.5 mt-2.5 align-center rounded-2xl animate-pulse self-center" />
      <div className="bg-dropDownBorder w-[398px] h-2.5 mt-[26px] align-center rounded-2xl animate-pulse self-center" />
      <div className="bg-dropDownBorder w-[314px] h-2.5 mt-2.5 align-center rounded-2xl animate-pulse self-center" />
      <div className="bg-dropDownBorder w-[272px] h-2.5 mt-2.5 align-center rounded-2xl animate-pulse self-center" />
      <div className="bg-dropDownBorder w-[96px] h-2.5 mt-2.5 align-center rounded-2xl animate-pulse self-center" />
      <div className="bg-dropDownBorder w-[192px] h-10 mt-[28px] align-center rounded-md animate-pulse self-center" />
      </div>
  }
  
  export default NewOrganizationLoadingIndicator
  