import React from "react";

function HeaderOnlineFormLoadingIndicator(): JSX.Element {
  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-col mt-4 items-start">
        <div className="py-1.5 w-6 my-3 bg-dropDownBorder rounded-lg animate-pulse" />
        <div className="py-1 w-full my-3 bg-dropDownBorder rounded-lg animate-pulse" />
        <div className="py-2 w-[190px] my-3 bg-dropDownBorder rounded-lg animate-pulse" />
        <div className="h-2.5 w-[520px] my-3 bg-dropDownBorder rounded-lg animate-pulse" />
      </div>
    </div>
  );
}

export default HeaderOnlineFormLoadingIndicator;
