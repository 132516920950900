/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import FormTabCheck from "../../assets/svg/FormTabCheck";
import SidebarLoadingIndicator from "../global/LoadingIndicators/CreateOnlineOrderIndicators/SidebarLoadingIndicator";
import ErrorCross from "../../assets/svg/ErrorCross";
import RequiredFieldTooltip from "../global/RequiredFieldTooltip";

function Tab({
  tab,
  handleTab,
  selectedTab,
  isValid,
  touched
}: {
  tab: { id: string, label: string };
  touched: boolean
  handleTab: any;
  selectedTab: string;
  isValid: boolean;
}): JSX.Element {
  const { selectedTest, clinicalInfoValid } = useSelector((state: any) => state.OnlineOrderForm)
  const isCurrentTab = selectedTab === tab.id
  const showClinicalInfoError = !isCurrentTab && (clinicalInfoValid === false && (selectedTest.id == 2 || selectedTest.id == 3 || selectedTest.id == 5 || selectedTest?.id == 4 || selectedTest?.id == 8)) && touched
  const showError = tab.id === 'clinical info' ? showClinicalInfoError : !isCurrentTab && !isValid && touched

  return (
    <RequiredFieldTooltip
      required={showError}
      tooltipText={'Missing information for order submission'}
    >
      <button
        onClick={handleTab(tab.id)}
        className={`flex items-center ${showError ? 'text-[#f00]' : 'text-royalBlue'} text-sm lg:text-base text-left block capitalize p-1 mb-1 hover:text-activeLink hover:font-bold hover:lg:text-base hover:text-sm visited:text-royalBlue ${selectedTab === tab.id ? "font-bold text-activeLink hover:font-bold" : ""
          } ${isValid ? "text-primary font-medium" : ""}`}
      >
        {" "}
        <div className={`text-left`}>
          {showError ? <ErrorCross height={15.5} width={15.5} className="mr-1" fill="red" /> : <FormTabCheck
            className="mr-1 !text-xl"
            fill={isValid ? "#18B5B7" : "none"}
            isvalid={isValid ? "white" : "#d0d4db"}
            stroke={isValid ? "#18B5B7" : "#d0d4db"}
          />}

        </div>
        <div className={`break-normal`}>
          {/* {tab.label} */}
          {(tab.id === 'laboratory info' && selectedTest?.id == 8) ? 'Specimen Information'
            : (tab.id === 'clinical info' && selectedTest?.id === 8) ? 'Patient Healthcare Information'
              : tab.label}
        </div>
      </button>
    </RequiredFieldTooltip>
  );
}

function OrderFormTabs({
  selectedTab,
  setSelectedTab,
  validSections,
  isEdit,
  touchedSections
}: any): JSX.Element {
  const { selectedTest, clinicalInfoValid } = useSelector((state: any) => state.OnlineOrderForm);
  const { selectedPhysician, selectedOrganization, fetchingOrderByID, mode } =
    useSelector((state: any) => state.OnlineOrderForm);

  const tabs = [
    {
      id: 'select test',
      label: 'Select Test',
    },
    {
      id: 'patient info',
      label: 'Patient Information'
    },
    {
      id: 'billing info & medicare',
      label: 'billing information'
    },
    {
      id: 'clinical info',
      label: 'Clinical Information'
    },
    {
      id: "additional info",
      label: "Additional Information",
    },
    {
      id: "laboratory info",
      label: "Laboratory Information"
    },
    {
      id: "supporting documents",
      label: "supporting documents"
    }
  ]

  const scroll = (tab: string): any => {
    const section: any = document.getElementById((mode === "edit") ? `review ${tab}` : tab);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  function handleTab(val: string) {
    return () => {
      localStorage.setItem('forceTab', val)
      setSelectedTab(val);
      scroll(val);
      // setTimeout(() => {
      // }, 500)
    };
  }

  if (isEdit && fetchingOrderByID) {
    return <SidebarLoadingIndicator />;
  } else if (
    !isEdit &&
    (selectedOrganization === null ||
      selectedPhysician === null ||
      selectedOrganization == undefined)
  ) {
    return <SidebarLoadingIndicator />;
  } else {
    return (
      <div className="bg-[#F9F9FA] border-r col-span-1 p-6 break-all">
        {selectedTest?.id === 1
          ? tabs
            .filter((item) => item.id !== "clinical info")
            .map((tab, index) => (
              <Tab
                key={tab.id}
                tab={tab}
                selectedTab={selectedTab}
                isValid={
                  validSections &&
                  validSections.length > 0 &&
                  validSections.includes(tab.id)
                }
                handleTab={handleTab}
                touched={touchedSections?.includes(tab?.id)}

              />
            ))
          : tabs.map((tab, index) => (
            <Tab
              key={tab.id}
              tab={tab}
              selectedTab={selectedTab}
              isValid={
                validSections &&
                validSections.length > 0 &&
                validSections.includes(tab.id)
              }
              handleTab={handleTab}
              touched={touchedSections?.includes(tab.id)}
            />
          ))}
      </div>
    );
  }
}

export default OrderFormTabs;
