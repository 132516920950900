/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import Button from '../atomic/Button'
import CustomInput from '../global/CustomInput'
// import { useNavigate } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import SignUpFormActions from '../../Store/Actions/SignUpFormActions'
import ProfileActions from '../../Store/Actions/ProfileActions'
import { useOktaAuth } from '@okta/okta-react'
import PhysicianProfileValidation from '../../validations/physicianprofilevalidation'
import toastMessages from '../../utils/helpers/toastMessage'
import { useHistory } from 'react-router-dom'
import PhysicianProfileCompletionValidation from '../../validations/physiciancompletionvalidation'
import { cityStateHelper } from '../../utils/helpers/cityStateHelper'
import SOFFlow from './SofFlow'
import SuffixAndSpecialityActions from '../../Store/Actions/SuffixAndSpecialtyActions'

interface PhysicianSignUpProps {
  signUp: any
  updateUser: any
  isCompletion?: any
  fetchprofile?: any
  getSuffix?: any
  getSpeciality?: any
  handleNext?: any
  loading?: any
}

function PhysicianSignUpForm({ handleNext, signUp, updateUser, isCompletion, fetchprofile, getSuffix, getSpeciality, loading }: PhysicianSignUpProps): JSX.Element {
  // const navigate = useNavigate()
  const history = useHistory()
  const { suffixData, specialityData } = useSelector((state: any) => {
    return {
      suffixData: state.Suffix.suffix,
      specialityData: state.Speciality.speciality
    }
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { profile } = useSelector((state: any) => state.Profile)
  const { authState } = useOktaAuth()
  const { data: states, error } = useSelector((state: any) => state.StateAndCity)
  const [cities, setCities] = useState([])
  const [sofVisible, setSofVisible] = useState<boolean>(false)
  const [sofFilePath, setSofFilePath] = useState<string>('')
  const formRef = useRef<any>()
  const formValues = useSelector((state: any) => state.SignUpFormValues)

  const physicianFields = [
    {
      id: 'first_name',
      disabled: false,
      name: 'firstName',
      label: 'First Name',
      placeholder: 'Enter your first name',
      required: true,
      type: 'event'
    },
    {
      id: 'last_name',
      disabled: false,
      name: 'lastName',
      label: 'Last Name',
      placeholder: 'Enter your last name',
      required: true,
      type: 'event'
    },
    {
      id: 'email_id',
      disabled: false,
      name: 'email_id',
      label: 'Clinician Email ID',
      placeholder: 'Enter email ID',
      required: true,
      type: 'event'
    },
    // {
    //   id: 'primaryPhone',
    //   disabled: false,
    //   name: 'primaryPhone',
    //   label: 'Phone',
    //   isTel: true,
    //   placeholder: '000-000-0000',
    //   required: true,
    //   type: 'event'
    // },
    // {
    //   id: 'fax',
    //   disabled: false,
    //   name: 'fax',
    //   label: 'Fax',
    //   placeholder: '000-000-0000',
    //   isTel: true,
    //   required: true,
    //   type: 'event'
    // },
    // {
    //   id: 'manger_address',
    //   disabled: false,
    //   name: 'streetAddress',
    //   label: 'Address',
    //   placeholder: 'Enter street address',
    //   required: isCompletion ?? false,
    //   type: 'event',
    //   isTel: false
    // },
    // {
    //   id: 'manager_state',
    //   disabled: false,
    //   name: isCompletion ? 'state' : 'manager_state',
    //   label: 'State/Province',
    //   suggestions: ['California', 'Texas'],
    //   placeholder: 'Enter state or province name',
    //   required: isCompletion ?? false,
    //   type: 'val',
    //   isTel: false
    // },
    // {
    //   id: 'city',
    //   disabled: false,
    //   name: 'city',
    //   label: 'City',
    //   suggestions: ['New York', 'Las Vegas'],
    //   placeholder: 'Enter city name',
    //   required: true,
    //   type: 'val',
    //   isTel: false

    // },
    {
      id: 'suffix',
      name: 'suffix',
      label: 'Suffix',
      placeholder: 'Enter Suffix',
      suggestions: ['New York', 'Las Vegas'],
      required: true,
      type: 'val',
      isTel: false
    },
    {
      id: 'specialty',
      name: 'specialty',
      label: 'Speciality',
      placeholder: 'Enter Speciality',
      suggestions: ['New York', 'Las Vegas'],
      required: true,
      type: 'val',
      isTel: false
    },
    {
      id: 'npi',
      name: 'npi',
      label: 'NPI',
      placeholder: '0000000000',
      required: true,
      type: 'event',
      isTel: false
    },
    // {
    //   id: 'zipCode',
    //   disabled: false,
    //   name: 'zipCode',
    //   label: 'Zip Code',
    //   placeholder: 'Enter zip code',
    //   required: isCompletion ?? false,
    //   type: 'event',
    //   isTel: false
    // },
    // {
    //   id: 'signPermisison',
    //   disabled: false,
    //   name: 'signPermission',
    //   label: 'I allow the Office Manager to use my e-signature to submit an order.',
    //   placeholder: 'Enter zip code',
    //   required: isCompletion ?? false,
    //   type: 'event',
    //   isTel: false
    // },

  ]

  let initialValues = {}
  if (!isCompletion) {
    initialValues = {
      firstName: formValues ? formValues.firstName ? formValues.firstName : '' : '',
      lastName: formValues ? formValues.lastName ? formValues.lastName : '' : '',
      email_id: formValues ? formValues.email_id ? formValues.email_id : '' : '',
      specialty: formValues ? formValues.specialty ? formValues.specialty : '' : '',
      suffix: formValues ? formValues.suffix ? formValues.suffix : '' : '',
      npi: formValues ? formValues.npi ? formValues.npi : '' : '',
    }
  } else {
    initialValues = {
      firstName: profile ? profile.firstName ? profile.firstName : '' : '',
      email_id: profile ? profile.email ? profile.email : '' : '',
      lastName: profile ? profile.lastName ? profile.lastName : '' : '',
      primaryPhone: profile ? profile.primaryPhone ? profile.primaryPhone : '' : '',
      fax: profile ? profile.fax ? profile.fax : '' : '',
      streetAddress: profile ? profile.streetAddress ? profile.streetAddress : '' : '',
      city: profile ? profile.city ? profile.city : '' : '',
      state: profile ? profile.state ? profile.state : '' : '',
      zipCode: profile ? profile.zipCode ? profile.zipCode : '' : '',
      profileImage: profile ? profile.profileImage ? profile.profileImage : '' : '',
      imageUrl: profile ? profile.imageUrl ? profile.imageUrl : '' : '',
      // role: profile ? Array.isArray(profile.roles) ? profile.roles[0] : profile.roles : '',
      role: 'Physician',
      specialty: profile ? profile.specialty ? profile.specialty : '' : '',
      suffix: profile ? profile.suffix ? profile.suffix : '' : '',
      npi: profile ? profile.npi ? profile.npi : '' : '',
      pinCode: profile ? profile.pinCode ? profile.pinCode : '' : '',
      signPermission: profile ? profile.signPermission ? profile.signPermission : false : false
    }
  }
  function hideSOF() {
    setSofVisible(false)
  }
  function handleChange(setFieldValue: any, val: any, field: any, values: any, validateForm: any) {
    setFieldValue(field.name, val)
    setFieldValue('stateValue', states.find((state: any) => state.state_code === val)?.name ?? val)
    cityStateHelper(states, setCities, val, values, setFieldValue, 'city')
    const updatedValues = { ...values, [field.name]: val }
    validateForm(updatedValues)
  }

  useEffect(() => {
    if (profile && states.length > 0) {
      const foundState = states.find((state: any) => `${state.name} ${state.state_code}` === profile.state)
      if (foundState) { setCities(foundState.cities) }
    }
    getSuffix({
      data: {
        token: authState?.accessToken?.accessToken
      },
      onError: () => {
        // alert('error occured')
      }
    })
    getSpeciality({
      data: {
        token: authState?.accessToken?.accessToken
      },
      onError: () => {
        // alert('error occured')
      }
    })
  }, [])


  function handleSubmit(data: any): void {
    if (submitting) return

    if (!isCompletion) {
      setSubmitting(true)
      signUp({
        data: { ...formValues, ...data },
        onSuccess: (message: string) => {
          toastMessages.success(message ?? 'Organization and user has been created')
          history.replace('/verify-email')
          setSubmitting(false)
        },
        onError: (message: any) => {
          toastMessages.error(message ?? 'Organization Creation Failed', 3000)
          setSubmitting(false)
        }
      })
    } else {
      setSubmitting(true)
      const apiData = {
        firstName: data.firstName,
        lastName: data.lastName,
        primaryPhone: data.primaryPhone,
        fax: data.fax,
        streetAddress: data.streetAddress,
        city: data.city,
        state: data.state,
        zipCode: data.zipCode,
        profileImage: data.profileImage ? data.profileImage : '',
        // role: Array.isArray(data.role) ? data.role[0] : data.role,
        role: 'Physician',
        specialty: data.specialty,
        suffix: data.suffix,
        npi: data.npi,
        accessToken: authState?.accessToken?.accessToken,
        signPermission: data.signPermission
      }
      updateUser({
        data: { ...apiData },
        onSuccess: (message: string) => {
          toastMessages.success(message ?? 'Profile Completed', 3000)
          const options = {
            accessToken: authState?.accessToken?.accessToken,
            onSuccess: () => {
              // console.log("success");
            },
            onError: () => {
              // alert("Something went wrong");
            },
          };
          if (fetchprofile)
            fetchprofile(options);
          history.replace('/dashboard')
          // navigate('/dashboard', { replace: true })
        },
        onError: (message: any) => {
          toastMessages.error(message ?? 'Profile Completion Failed', 3000)
          setSubmitting(false)
        }
      })
    }
  }

  return <Formik
    initialValues={initialValues}
    validationSchema={isCompletion ? PhysicianProfileCompletionValidation : PhysicianProfileValidation}
    // onSubmit={isCompletion ? handleSubmit : (data) => handleNext(data, formRef)}
    onSubmit={handleSubmit}
    innerRef={formRef}
    render={({ errors, touched, setFieldValue, values, validateForm }: any) => (<Form
      className={"flex flex-col justify-around overflow-auto h-full"}
    >
      <SOFFlow
        isVisible={sofVisible}
        type={'create'}
        isProfileCompletion
        setFilePath={setSofFilePath}
        item={{}}
        closeSOF={hideSOF}
      />
      <div className='flex flex-col overflow-auto px-1'>
        {physicianFields.map((field, index) => {
          // type ObjectKey = keyof typeof initialValues
          // const myKey = field.name as ObjectKey
          if (field.name === 'signPermission')
            return <div className='w-full flex items-start flex-col pb-3'>
              {/*   <Button
                btnType={'secondary'}
                type="button"
                title={'Upload SOF'}
                onClick={() => setSofVisible(true)}
                className="py-1 w-[30%] mb-4 mt-2"
                submitting={false} /> */}
              <CustomInput
                id={field.id}
                key={field.name}
                name={field.name}
                label={field.label}
                checked={values[field.name]}
                required={field.required}
                placeholder={field.placeholder}
                errors={errors}
                onChange={(e: any) => {
                  setFieldValue(field.name, e.target.checked)
                }}
                type="checkbox"
                touched={touched}
                className="self-start"
              // className="w-[83%]"
              // signupFieldsStyle={'-mt-1'}
              />
            </div>
          if (field.name === 'state') {
            return <CustomInput
              id={'state'}
              key={'state'}
              name={field.name}
              label={'State/Province'}
              required={isCompletion ?? false}
              value={values?.stateValue ? values.stateValue : values[field.name] ? states && states.length > 0 && states.find((state: any) => `${state?.name} ${state?.state_code}`.includes(values[field.name]))?.name : values[field.name]}
              placeholder={'Enter state or province name'}
              // suggestions={!error && states.length > 0 ? states.map((state: any) => { return `${state.name} ${state.state_code}` }) : []}
              suggestions={!error && states.length > 0 ? states.map((state: any) => ({ label: state.name, value: state.state_code })) : []}
              // onChange={(val: any) => {
              //   setFieldValue(field.name, val)
              //   cityStateHelper(states, setCities, val, values, setFieldValue, 'city')
              // }}
              onChange={(val: any) => {
                handleChange(setFieldValue, val, field, values, validateForm)
              }}
              className={`${isCompletion ? 'w-[83%]' : ''}`}
              errors={errors}
              touched={touched}
              signupFieldsStyle={'-mt-1'}
            />
          }
          if (field.name === 'specialty') {
            return <CustomInput
              id={'specialty'}
              key={'specialty'}
              name={'specialty'}
              label={'Speciality'}
              isInternal
              required={field.required}
              placeholder={'Choose speciality'}
              suggestions={
                !error && specialityData.length > 0
                  ? specialityData.map((state: any) => ({ label: state.name, value: state.name }))
                  : []
              }
              onChange={(val: any) => {
                setFieldValue(field?.name, val)
              }}
              value={values[field.name]}
              className={`${isCompletion ? 'w-[83%]' : ''}`}
              errors={errors}
              touched={touched}
              signupFieldsStyle={'-mt-1'}
            />
          }
          if (field.name === 'suffix') {
            return <CustomInput
              id={'suffix'}
              key={'suffix'}
              name={'suffix'}
              label={'Suffix'}
              required={field.required}
              placeholder={'Enter suffix'}
              suggestions={
                !error && suffixData.length > 0
                  ? suffixData.map((state: any) => ({ label: state.name, value: state.name }))
                  : []
              }
              isInternal
              onChange={(val: any) => {
                setFieldValue(field.name, val)
              }}
              className={`${isCompletion ? 'w-[83%]' : ''}`}
              value={values[field.name]}
              errors={errors}
              touched={touched}
              signupFieldsStyle={'-mt-1'}
            />
          }
          if (field.name === 'city') {
            return <CustomInput
              id={'city'}
              key={'city'}
              name={field.name}
              label={'City'}
              value={values[field.name]}
              required={isCompletion ?? false}
              placeholder={'Enter city name'}
              suggestions={cities && cities.length > 0 ? cities.map((city: any) => ({ label: city.name, value: city.name })) : []}
              errors={errors}
              onChange={(val: any) => {
                setFieldValue(field.name, val)
              }}
              className={`${isCompletion ? 'w-[83%]' : ''}`}
              touched={touched}
              signupFieldsStyle={'-mt-1'}
            />
          }
          if (field.name === 'npi') {
            return <CustomInput
              id={field.id}
              key={field.id}
              name={field.name}
              disabled={field.disabled}
              label={field.label}
              // type={'number'}
              required={field.required}
              value={values[field.name]}
              // suggestions={field.suggestions}
              onChange={field.type === 'event' ? (e: any) => setFieldValue(field.name, e.target.value, true) : (val: any) => setFieldValue(field.name, val, true)}
              placeholder={field.placeholder}
              errors={errors}
              touched={touched}
              className={index === 0 ? `mt-0 ${isCompletion ? 'w-[83%]' : ''}` : `${isCompletion ? 'w-[83%]' : ''}`}
              signupFieldsStyle={'-mt-1'}
            />
          }
          return <CustomInput
            id={field.id}
            key={field.id}
            name={field.name}
            disabled={field.disabled}
            label={field.label}
            type={field.isTel ? 'tel' : 'text'}
            required={field.required}
            value={values[field.name]}
            // suggestions={field.suggestions}
            onChange={field.type === 'event' ? (e: any) => setFieldValue(field.name, e.target.value, true) : (val: any) => setFieldValue(field.name, val, true)}
            placeholder={field.placeholder}
            errors={errors}
            touched={touched}
            className={index === 0 ? `mt-0 ${isCompletion ? 'w-[83%]' : ''}` : `${isCompletion ? 'w-[83%]' : ''}`}
            signupFieldsStyle={'-mt-1'}
          />
        }
        )}

      </div>
      <div className="flex flex-col justify-around flex-1">
        <Button
          type={'submit'}
          btnType='primary'
          submitting={loading || submitting}
          disabled={loading || submitting}
          title={isCompletion ? 'Done' : 'Sign Up'}
          className={`w-full   ${loading || submitting ? 'justify-center' : ''} ${isCompletion ? 'w-[83%] mt-1 mb-[34px]' : ''}`}
          // onClick={() => { formRef?.current?.submit() }}
          onClick={() => { }}
        />
        {!isCompletion && <div className="flex items-center justify-center mt-4">
          <div className={'h-3 w-3 rounded-full bg-primaryDisabled mr-1'} />
          <div className={'h-3 w-3 rounded-full bg-primaryDisabled mr-1'} />
          <div className={'h-3 w-3 rounded-full bg-primary'} />
        </div>}
      </div>
    </Form>
    )} />
}
const mapStateToProps = (state: any): any => ({ formValues: state.SignUpFormValues })
const mapDispatchToProps = {
  signUp: SignUpFormActions.signUpUser,
  updateUser: ProfileActions.updateUser,
  fetchprofile: ProfileActions.fetchprofile,
  getSpeciality: SuffixAndSpecialityActions.fetchSpeciality,
  getSuffix: SuffixAndSpecialityActions.fetchSuffix,
}
export default connect(mapStateToProps, mapDispatchToProps)(PhysicianSignUpForm)
