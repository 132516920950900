/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/indent */
function validationHelper(
 validateform: any,
 setValidSections: any,
 values: any,
 object: any,
 sectionName: string,
 enableSumbit: any,
 disableSubmit: any
): any {
 validateform?.(values)?.then((res: any) => {
  // if (Object.keys(res).length === 0) {
  //  if (values.has_additional_clinician === null) {
  //   disableSubmit();
  //  } else if (values.skippedDocs === null || values?.isTeleHealth === false) {
  //   console.log("in first if");

  //   setValidSections((prevState: any) =>
  //    prevState.filter((item: string) => item !== sectionName)
  //   );
  //   disableSubmit();
  //  } else {
  //   enableSumbit();
  //  }
  // } else {
  //  disableSubmit();
  // }
  const hasError = Object.keys(object).some((key) =>
   Object.keys(res).includes(key)
  );

  if (hasError) {
   return setValidSections?.((prevState: any) =>
    prevState.filter((item: string) => item !== sectionName)
   );
  }

  return setValidSections?.((prevState: any) => [...prevState, sectionName]);
 });
}
export default validationHelper;
