import http from "../API/HttpMethods";
import {
 CREATE_PDF_ORDER,
 CREATE_PDF_ORDER_FAIL,
 CREATE_PDF_ORDER_SUCCESS,
} from "./types";
import { removeExtraSpace } from "../../utils/helpers/removeExtraSpace";

export const createPDFOrder = (options: any) => async (dispatch: any) => {
 const { data, config, onError, onSuccess } = options;

 let payload = {
  firstName: removeExtraSpace(data.firstName),
  lastName: removeExtraSpace(data.lastName),
  paperTRFDocuments: data.paperTRFDocuments.map((item: any) => item.filePath),
 };

 dispatch({ type: CREATE_PDF_ORDER });

 try {
  const { message, error } = await http.createPDFOrder(
   "/v1/orders/new-flow/pdf-orders",
   payload,
   config
  );

  if (!!error) {
   dispatch({ type: CREATE_PDF_ORDER_FAIL });

   return onError(error ?? "Failed to create pdf order");
  }

  dispatch({ type: CREATE_PDF_ORDER_SUCCESS });
  onSuccess(message ?? "PDF order created successfully");
 } catch (error: any) {
  dispatch({ type: CREATE_PDF_ORDER_FAIL });
  const { response } = error;
  if (response) {
   if (response.data.message) {
    onError(response.data.message);
   } else {
    onError(error.message ?? "Failed to create pdf order");
   }
  } else {
   onError(error.message ?? "Failed to create pdf order");
  }
 }
};
