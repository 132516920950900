import * as React from 'react'
import { SVGProps } from 'react'

const UploaderImageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g
            data-name="Group 14088"
            transform="translate(-2.938 -2.938)"
            fill="none"
            stroke="#b1b8c3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        >
            <path data-name="Line 1297" d="M22.938 10.938h0" />
            <rect
                data-name="Rectangle 4263"
                width={28}
                height={28}
                rx={3}
                transform="translate(3.938 3.938)"
            />
            <path
                data-name="Path 5220"
                d="m4.392 23.017 6.773-6.773a3.411 3.411 0 0 1 5.079 0l8.466 8.466"
            />
            <path
                data-name="Path 5221"
                d="m21.324 21.323 1.693-1.693a3.411 3.411 0 0 1 5.079 0l3.386 3.386"
            />
        </g>
    </svg>
)

export default UploaderImageIcon
