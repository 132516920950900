import { Fragment, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import Button from '../atomic/Button'
import './transitions.css'

interface SignUpFlowButtonProps {
  handleCreate: () => any
  handleExisting: () => any
  isPage: boolean
}

function SignUpFlowButtons ({ handleCreate, handleExisting, isPage }: SignUpFlowButtonProps) {
  const [showSignUp, setShowSignUp] = useState(!isPage)

  function viewSignUp () {
    setShowSignUp(true)
  }

  function closeSignUp () {
    setShowSignUp(false)
  }
  return <Fragment>
        <CSSTransition
            in={showSignUp}
            timeout={400}
            unmountOnExit
            classNames={'fade'}
            onEnter={viewSignUp}
            onExit={closeSignUp}

        >
            <div className="flex z-10">
                <Button
                    btnType="primary"
                    onClick={handleCreate}
                    title={'Create an organization'}
                    className={!isPage ? 'md:text-xs lg:text-sm' : ''}
                />
                <Button
                    btnType="secondary"
                    onClick={handleExisting}
                    title={'Join an existing organization'}
                    className={!isPage ? 'ml-6 md:text-xs lg:text-sm' : 'ml-6'}
                />
            </div>
        </CSSTransition>

        {!showSignUp && <Button
            btnType="primary"
            onClick={viewSignUp}
            title={'Signup'}
            className={'py-2 z-10'}
        />
        }
    </Fragment>
}
export default SignUpFlowButtons
