import * as React from "react"
import { SVGProps } from "react"
const TermsAndConditionsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} {...props}>
    <g
      fill="none"
      stroke={props?.stroke ? props.stroke : "#fff"}
      strokeLinecap="round"
      strokeLinejoin="round"
      data-name="Component 6416"
    >
      <path
        d="M9.389 12.945a3.556 3.556 0 1 0 3.556-3.556 3.556 3.556 0 0 0-3.556 3.556"
        data-name="Path 5367"
      />
      <path
        d="M8.5.5v3.556a.889.889 0 0 0 .889.889h3.556"
        data-name="Path 5369"
      />
      <path
        d="M8.056 16.5H2.278A1.778 1.778 0 0 1 .5 14.722V2.278A1.778 1.778 0 0 1 2.278.5H8.5l4.444 4.444v1.778m0 2.667"
        data-name="Path 5370"
      />
      <path d="M3.167 4.056h.889" data-name="Path 5379" />
      <path d="M3.167 7.611H8.5" data-name="Path 5380" />
      <path d="M3.167 11.167h3.245" data-name="Path 5381" />
      <path d="m11.393 12.944 1.034 1.034 2.066-2.068" data-name="Path 5382" />
    </g>
  </svg>
)
export default TermsAndConditionsIcon
