import { InView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSumbit } from '../../../../Store/Actions/ButtonStateActions'
import validationHelper from '../../../../utils/helpers/orderFormValidationHelper'
import scrollHelper from '../../../../utils/helpers/scrollHelper'
import CustomInput from '../../../global/CustomInput'

function SecuritySection({ values, setFieldValue, errors, touched, validateForm, setValidSections, InputFields, handleDataValidation, setSelectedTab, ...rest }: any) {
  const dispatch = useDispatch()

  const { selectedTest } = useSelector((state: any) => state.OnlineOrderForm)
  function enableSubmit() {
    dispatch(toggleSumbit(true))
  }
  function disableSubmit() {
    dispatch(toggleSumbit(false))
  }
  function handleChange(input: any, name: string) {
    const updatedValues = { ...values }
    updatedValues[name] = input

    setFieldValue(name, input)
    validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'patient info', enableSubmit, disableSubmit)
    rest.setTouchedSections((prevState: any) => [...prevState, 'patient info'])
  }
  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, undefined, 'patient info')
  }
  return <InView threshold={0.8} onChange={handleMouseScroll}>
    <div tabIndex={2} className="focus:outline-primary p-5 my-5 shadow-sm border border-[0.5px] rounded-md flex flex-col hover:border-primary hover:border">
      <p className="text-royalBlue font-medium text-base">Additional Patient Identifier (Optional)</p>
      <div>
        <p className="text-royalBlue mt-5">For test submission, either the SSN or MRN is required.
          {selectedTest?.checkedIndexes?.includes(7) && <p>Enter both identities as need fit for accuracy.</p>}</p>
      </div>
      <div className="flex justify-between items-center">
        <CustomInput
          id={'patient_ssn'}
          obscureInput
          key={'patient_ssn'}
          name={'patient_ssn'}
          label={'SSN'}
          isInternal
          inputMask={[
            /[1-9]/,
            /[1-9]/,
            /[1-9]/,
            '-',
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/
          ]}
          type={'masked'}
          value={values.ssn}
          onChange={(e: any) => handleChange(e.target.value, 'patient_ssn')}
          required={selectedTest.id === 4 ? true : false}
          placeholder={'000-00-0000'}
          errors={errors}
          className={'mr-5'}
          touched={touched}
        />
        <CustomInput
          id={'patient_mrn'}
          key={'patient_mrn'}
          obscureInput
          name={'patient_mrn'}
          label={'MRN'}
          isInternal
          value={values.patient_mrn}
          // onChange={(val: any) => setFieldValue('mrn', val)}
          onChange={(e: any) => handleChange(e.target.value, 'patient_mrn')}
          required={selectedTest.id === 4 ? true : false}
          placeholder={'0000000'}
          errors={errors}
          touched={touched}
        />
      </div>
    </div>
  </InView>
}
export default SecuritySection
