import { useEffect } from "react";
import NotificationsSettingsTable from "./NotificationsSettingsTable";
import HeaderColumnData from "../../models/notificationsSettingsTableModels/headerColumnData.model";
import BodyColumnData from "./BodyColumnData.model";
import { connect, useDispatch } from "react-redux";
import NotificationActions from "../../Store/Actions/UserNotificationActions";
import NotificationSettingsServices, { ApiMediumValue, ApiNotificationTypeValue, ApiOrganizationMediumSettingsValue, ApiUserNotificationTypeValue } from "../../services/NotificationSettingsServices";

interface UserNotificationTypeTableProps {
  tableColumns: HeaderColumnData[]
  organizations: any
  fetching: boolean
  error: boolean
  mediumValues: ApiMediumValue[]
  notificationTypes: ApiNotificationTypeValue[]
  organizationValues: ApiOrganizationMediumSettingsValue[]
  userNotificationTypesValues: ApiUserNotificationTypeValue[]
  tableBody: Array<BodyColumnData[]>
  setTableBody: (data: Array<BodyColumnData[]>) => void
  setTableColumns: (data: HeaderColumnData[]) => void
}

function UserNotificationTypeTable({
  tableColumns,
  tableBody,
  setTableBody,
  setTableColumns,
  organizations,
  mediumValues,
  notificationTypes,
  organizationValues,
  userNotificationTypesValues,
}: UserNotificationTypeTableProps): JSX.Element {

  const NotificationsSettingsData = new NotificationSettingsServices(
    organizations,
    mediumValues,
    organizationValues,
    undefined,
    notificationTypes,
    userNotificationTypesValues
  )
  const dispatch = useDispatch()
  function handleSelectAll(id: string | number, value?: boolean) {
    setTableBody(NotificationsSettingsData.handleSelectAll(id, tableColumns, tableBody,dispatch, value).tableBody)
    setTableColumns(NotificationsSettingsData.handleSelectAll(id, tableColumns, tableBody,dispatch, value).tableColumn)
  }
  const keys = [
    'ORDER_RECEIVED',
    'ORDER_PROCESSED',
    'TISSUE_RECEIVED',
    'REPORT_ISSUED',
    'PENDING_MISSING_INFORMATION',
    'SOF',
    'SOF_EXPIRY',
    'CUSTOM_BROADCAST',
    'CLINICIAN_SOF_ORDER',
    'CLINICIAN_ORDER_AGREEMENT_ORDER',
    'REPORT_CORRECTION',
    'CASTLE_NEWS',
    'CUSTOMER_NEWSLETTER',
  ];
  const getPersonalSettingsValue = (organization: any) => {
    let finalObject: any = {}

    notificationTypes?.map((e:any)=>e?.key).forEach((currKey: string) => {
      finalObject[currKey] = organization[1]?.data?.find(({ key }: any) => key === currKey)?.value
    })

    return finalObject
  }

  const personalSettingsValue = tableBody.map(getPersonalSettingsValue)

  useEffect(() => {
    NotificationsSettingsData.createPersonalNotificationTypeData()
    setTableColumns(NotificationsSettingsData.getPersonalNotificationTypeData().tableColumn)
    setTableBody(NotificationsSettingsData.getPersonalNotificationTypeData().tableBody)
  }, [])

  return <NotificationsSettingsTable
    tableColumns={tableColumns}
    onSelectAll={handleSelectAll}
    tableBody={tableBody}
    setTableBody={setTableBody}
    isEnabled={personalSettingsValue}
  />
}
const mapStateToProps = (state: any) => ({
  fetching: state.UserNotifications.fetching,
  error: state.UserNotifications.error,
  organizations: state.Organizations.data,
  mediumValues: state.UserNotifications.notificationMediums,
  organizationValues: state.UserNotifications.organizationNotificationMediums,
  notificationTypes: state.UserNotifications.notificationTypes,
  userNotificationTypesValues: state.UserNotifications.userNotificationTypes
})

const mapDispatchToProps = {
  fetchNotificationMedium: NotificationActions.fetchNotificationMedium,
  fetchOrganizationNotificationMedium: NotificationActions.fetchOrganizationNotificationMedium
}

export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationTypeTable)