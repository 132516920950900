/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import React from 'react'
import { useSelector } from 'react-redux'
import Header from '../components/global/Header'
import Tabs from '../components/ManageScreen/Tabs'
import '../assets/css/custom.css';

function ManageScreenLayout({ children, headerTitle, isPathLab }: any): JSX.Element {
    const { screen } = useSelector((state: any) => state.ButtonState)
    return <div className="flex flex-col h-full ">
        <Header
            isInternal
            showBack={screen === 'invite screen' || screen === 'UpdatePortalUser'}
            onBackScreen={screen === 'invite screen' ? 'portal-users' : 'UserProfile'}
            title={headerTitle ?? 'manage'}
        />
        <div className="grid h-full lg:grid-cols-7 tab:w-full tab:flex tab:overflow-hidden mobile:flex-col mobile:flex">
            <div className="sidebar lg:col-span-1 mobile:w-full">
                <Tabs isPathLab />
            </div>
            <div className="rightContainer lg:col-span-6  lg:grid lg:grid-cols-4 tab:flex tab:overflow-auto">
                <div className="lg:col-span-6  lg:grid lg:grid-cols-4 tab:flex">
                    {children}
                </div>
            </div>
        </div>
    </div>
}

export default ManageScreenLayout
