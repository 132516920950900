import './styles.css'
interface ToggleSwitchProps {
  name: string
  id: string | number
  handleChange: React.ChangeEventHandler<HTMLInputElement>
  checked: boolean
  disabled: boolean
  containerClassName?: string
}
function ToggleSwitch({ name, id, handleChange, checked, disabled, containerClassName }: ToggleSwitchProps): JSX.Element {
  return <label className="switch ">
    <input
      type="checkbox"
      disabled={disabled}
      onChange={handleChange}
      checked={checked}
    />
    <span className="slider round"></span>
  </label>
}

export default ToggleSwitch