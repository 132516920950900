import { useOktaAuth } from '@okta/okta-react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LandingPageBottomDesign from '../../assets/svg/LandingPageBottomDesign'
import LandingPageRightArrow from '../../assets/svg/LandingPageRightArrow'
import LandingPageAction from '../../components/global/LandingPageAction'
import LandingPageBG from '../../assets/svg/LandingPageBG.svg';
import { LOGOUT } from '../../Store/Actions/types'
import Button from '../../components/atomic/Button'

function LandingPage() {
    const history = useHistory()
    const dispatch = useDispatch()
    const { oktaAuth, authState } = useOktaAuth();

    function navigateTo(screen: string) {
        return () => history.push(screen)
    }
    const handleLogin = async (): Promise<any> => {
        await oktaAuth.signInWithRedirect({ originalUri: "/login-process" });
    };

    useEffect(() => {
        if (authState?.accessToken?.accessToken) {
        // if (oktaAuth.getAccessToken()) {
            history.push('/dashboard')
        } 
        // else {
        //     oktaAuth.closeSession()
        //     dispatch({ type: LOGOUT })
        // }

    }, [])


    return <div className='h-full flex flex-col bg-cover xl:bg-auto ' style={{ backgroundImage: `url(${LandingPageBG})` }}>
        <section className="container m-auto flex flex-col h-full " >
            <div className='flex flex-col m-auto w-full xl:w-[60%]'>
                <div className='flex mb-10'>
                    <LandingPageRightArrow height={50} className="mt-3" />
                    <div className='mx-5 w-full'>
                        <h2 className='text-[#1e4b8a] text-left font-semibold text-4xl md:text-5xl lg:text-[80px] mb-2 '>Clinician</h2>
                        <h2 className='text-[#1e4b8a] text-left font-semibold text-4xl md:text-5xl lg:text-[80px] mb-2 '>Ordering Portal</h2>
                        <h3 className="text-royalBlue text-base md:text-xl my-8 text-left font-semibold">
                            Improving health through innovative tests that guide patient care
                        </h3>
                        <div className='flex justify-between mt-12'>
                            <LandingPageAction
                                title='Existing Customer'
                                subtitle='Already have an account?'
                                buttonTitle='login'
                                buttonClassName='mr-4 w-3/6'
                                onClick={handleLogin}
                            />
                            <LandingPageAction
                                title='New Customer'
                                subtitle='Register for an account to begin'
                                buttonTitle='sign up'
                                buttonClassName="ml-4 w-3/6"
                                onClick={navigateTo('signup')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default LandingPage
