/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { /* useNavigate, */ useHistory, useParams } from "react-router-dom";
import ManageScreenLayout from "../../../../Containers/ManageScreenLayout";
import ButtonStateActions from "../../../../Store/Actions/ButtonStateActions";
import NotificationActions from "../../../../Store/Actions/NotificationsActions";
import { useOktaAuth } from "@okta/okta-react";
import OrganizationPillBox from "../../../../components/global/OrganizationPillBox";
import { Form, Formik } from "formik";
import { formRef } from "..";
import CustomImageUploader from "../../../../components/global/CustomImageUploader";
import CustomInput from "../../../../components/global/CustomInput";
// import EditPortalUserFormValidation from "../../../../validations/editportaluserprofilevalidation";
import EditPortalUserForm from "../../../../models/forms/editportaluserformvalues.interface";
import Associations from "../../../../components/global/InviteUser/Associations";
import SuffixAndSpecialityActions from "../../../../Store/Actions/SuffixAndSpecialtyActions";
import PortalUsersActions from "../../../../Store/Actions/PortalUsersActions";
import toastMessages from "../../../../utils/helpers/toastMessage";
import InviteUserActions from "../../../../Store/Actions/InviteUserActions";
import { cityStateHelper } from "../../../../utils/helpers/cityStateHelper";
import OrganizationActions from "../../../../Store/Actions/OrganizationsActions";
import { ITableHeaderProps, tooltip } from "../../../../components/DataTableHeader/DataTableHeader";
import DataTable from "../../../../components/DataTable/DataTable";
import ReactTooltip from "react-tooltip";
import RequiredFieldTooltip from "../../../../components/global/RequiredFieldTooltip";
import { updatePhysicianSignCheck, updateSelectedPhysician } from "../../../../Store/Actions/OnlineOrderFormActions";
import TooltipHelper from "../../../../components/global/TooltipHelper";

function RenderAssociatedMembers({
  selectedUser,
  members,
  role,
  profile,
}: any) {
  const dispatch = useDispatch();
  const { data: organizationsList } = useSelector(
    (state: any) => state.Organizations
  );
  const initialHeader: ITableHeaderProps = {
    hasVerticalMenu: false,
    onSelectAll: (val) => {
      // console.log(val)
    },
    NavLink: "",
    nnProps: "",
    check: false,
    hideCheckBox: false,
    data: [
      {
        name: "Name",
        filter: false,
        preventAutoSearch: false,
        sticky: false,
        // suggestions: ['name', 'ali'],
      },
      {
        name: "Email ID",
        filter: false,
        preventAutoSearch: false,
        sticky: false,
      },
      {
        name: "Role",
        filter: false,
        preventAutoSearch: false,
        sticky: false,
      },
      {
        name: "organizations",
        filter: false,
        preventAutoSearch: false,
        sticky: false,
      },
    ],
    list: [
      // { name: 'name' },
      // { email: 'email' },
    ],
  };
  const [header, setHeader] = useState<ITableHeaderProps>({
    hasVerticalMenu: false,
    onSelectAll: (val) => {
      // console.log(val)
    },
    NavLink: "",
    nnProps: "",
    check: false,
    hideCheckBox: true,
    data: [
      {
        name: "Name",
        filter: false,
        preventAutoSearch: false,
        sticky: false,
        // suggestions: ['name', 'ali'],
      },
      {
        name: "Email ID",
        filter: false,
        preventAutoSearch: false,
        sticky: false,
      },
      {
        name: "organizations",
        filter: false,
        preventAutoSearch: false,
        sticky: false,
      },
      {
        name: "ACS",
        filter: false,
        preventAutoSearch: false,
        sticky: false,
      },
      {
        name: "AFP",
        filter: false,
        preventAutoSearch: false,
        sticky: false,
      },
    ],
    list: [{ name: "name" }, { email: "email" }],
  });
  const [tableBody, setTableBody] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  function handleSearch(e: React.FormEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;
    setSearch(value);

    let filteredList: any = [...tableBody];

    filteredList = filteredList.filter((item: any) => {
      if (
        item.data.email.toLowerCase().includes(value.toLowerCase()) ||
        item.data.name.value.toLowerCase().includes(value.toLowerCase())
      ) {
        return true;
      }
      return false;
    });

    setFilteredList(filteredList);
  }
  useEffect(() => {
    let data = initialHeader.data;
    const organizations = organizationsList
      .filter((organisation: any) => {
        if (
          selectedUser?.profile?.organizations.find(
            (org: any) => org.id == organisation.id
          )
        ) {
          return true;
        }
        return false;
      })
      .map((item: any) => {
        let tooltipValue = `${item?.profile?.address}${item?.profile?.city ? `,${" "}${item?.profile?.city}` : ''}${item?.profile?.state ? `,${" "}${item?.profile?.state}` : ''}`
        let tooltip: tooltip = { key: item.profile.name, value: tooltipValue }

        return { name: item.profile.name, filter: false, sticky: false, tooltip };
      });

    data = [...data, ...organizations];

    setHeader((prevState: any) => {
      return { ...prevState, data };
    });

    const keys: any = {};

    if (members && members.length > 0) {
      organizationsList
        .filter((organisation: any) =>
          selectedUser?.profile?.organizations.find(
            (org: any) => org.id == organisation.id
          )
        )
        .map((item: any) => {
          return (keys[item?.profile?.name] = {
            value: false,
            isCheckbox: true,
            disabled: false,
          });
        });

      const tableBody = members?.map((item: any) => {
        let organizations = "";

        organizationsList.forEach((organisation: any) => {
          if (organisation.users?.find((user: any) => user.id == item.id)) {
            organizations = organizations + `${organisation?.name}`;
          }
        });
        const currentKeys = { ...keys };

        Object.keys(currentKeys).map((key: any) => {
          if (
            selectedUser?.profile?.organizations.find(
              (value: any) => value.name == key
            )
          ) {
            currentKeys[key].disabled = false;
            currentKeys[key].value = true;
          } else {
            currentKeys[key].disabled = true;
          }
        });

        return {
          id: item.id,
          data: {
            // imsi: 3641741342,
            name: {
              value: item?.profile?.firstName ?? "Raza",
              img: item?.profile?.imageUrl ?? "",
            },
            email: item?.profile?.email ?? "",
            role: item?.profile?.roles ?? "",
            organizations: organizations ?? "",
            ...currentKeys,
          },
          showCheckbox: false,
          check: false,
        };
      });
      setTableBody(tableBody);
    }

    let membersState = members?.map((member: any) => {
      let foundUserOrganisations: any[] = [];
      organizationsList.forEach((organisation: any) => {
        if (organisation.users?.find((user: any) => user.id == member.id)) {
          foundUserOrganisations.push(organisation);
        }
      });
      return {
        id: member?.id,
        organizationIds: foundUserOrganisations?.map((org: any) => org?.id),
      };
    });

    dispatch(InviteUserActions.setMembers(membersState));
  }, [members, selectedUser]);

  if (role === "Office Manager") return null;

  return (
    <div>
      <div className="text-royalBlue font-semibold mt-6">
        Associated {role && role == "Physician" ? "Staff" : "Physicians"}
      </div>

      <div className="my-3 overflow-visible">
        <CustomInput
          id={"search"}
          key={"search"}
          name={"search"}
          label={"Search"}
          required={false}
          placeholder={"Search by name or email id"}
          className="w-2/6"
          onChange={handleSearch}
          errors={{}}
          withoutForm
          isInternal
          touched={{}}
        />
        <DataTable
          NavLink={""}
          body={search ? filteredList : tableBody}
          header={header}
        />
      </div>
    </div>
  );
}
function RenderFields({
  isPage,
  setFieldValue,
  errors,
  touched,
  isValid,
  values,
  role,
  orgId,
  validateForm,
  selectedUser,
}: any): JSX.Element {
  const dispatch = useDispatch();
  const { data: states, error } = useSelector(
    (state: any) => state.StateAndCity
  );
  const { suffixData, specialityData } = useSelector((state: any) => {
    return {
      suffixData: state.Suffix.suffix,
      specialityData: state.Speciality.speciality,
    };
  });
  const [cities, setCities] = useState([]);

  function handleChange(name: string, type: "event" | "val", data: any): void {
    const updatedValues = { ...values };

    function handleValue(val: any) {
      updatedValues[name] = val;

      if (val == "") {
        dispatch(ButtonStateActions.toggleSumbit(false));
      } else {
        validateForm(updatedValues)
          .then((res: any) => {
            if (Object.keys(res).length === 0) {
              dispatch(ButtonStateActions.toggleSumbit(true));
            } else {
              dispatch(ButtonStateActions.toggleSumbit(false));
            }
          })
          .catch((err: any) => {
            dispatch(ButtonStateActions.toggleSumbit(isValid));
          });
      }
    }

    if (type === "event") {
      setFieldValue(name, data.target.value);
      handleValue(data.target.value);
    } else {
      if (name == 'state') {
        cityStateHelper(states, setCities, data, values, setFieldValue, 'city')
        setFieldValue(name, data)
        setFieldValue('stateValue', states.find((state: any) => state.state_code === data)?.name ?? data)
        handleValue(data)
      } else {
        setFieldValue(name, data)
        handleValue(data)
      }
    }
  }

  function onUploadStart(): void {
    dispatch(ButtonStateActions.toggleSumbit(false));
  }
  function onUploadEnd(): void {
    dispatch(ButtonStateActions.toggleSumbit(isValid));
  }
  function handleSignPermission(e: any) {
    const updatedValues = { ...values }
    setFieldValue("signPermission", e.target.checked);
    updatedValues.signPermission = e.target.checked;
    validateForm(updatedValues)
      .then((res: any) => {
        if (Object.keys(res).length === 0) {
          dispatch(ButtonStateActions.toggleSumbit(true));
        } else {
          dispatch(ButtonStateActions.toggleSumbit(false));
        }
      })
      .catch((err: any) => {
        dispatch(ButtonStateActions.toggleSumbit(isValid));
      });
  }
  return (
    <div className="overflow-visible">
      {/* {(selectedUser?.roles?.includes("Physician")) &&
      <div className="bg-lightBlue p-2 px-4 rounded my-4 font-semibold flex text-base text-royalBlue">
      <p
        style={{ height: 15, width: 15 }} className={'mt-1 mr-2 cursor-pointer rounded-full bg-primary text-center text-white font-medium text-xs'}>
        i
      </p>
      <p>
        Only permission checkbox is editable. For any other changes , <a href="mailto:clinicalservices@castlebiosciences.com" className='hover:underline hover:text-base hover:font-semibold text-blue-600'>
          contact support</a>
      </p>
    </div>
      } */}

      <p className="text-royalBlue text-base font-medium">User Profile</p>

      <CustomImageUploader
        fieldName={"profileImage"}
        label="Image"
        title="Change Image"
        onUploadStart={onUploadStart}
        onUploadEnd={onUploadEnd}
        initialImage={values.imageUrl ?? ""}
        setFieldValue={setFieldValue}
        handleImage={(val: any) => {
          // console.log(val)
        }}
      // disabled
      />
      {/* <button className="text-[#31BDBF] font-medium tracking-widest">CHANGE IMAGE</button> */}
      <div className="w-3/5">
        <CustomInput
          id={"firstName"}
          key={"firstName"}
          name={"firstName"}
          // disabled={orgId}
          disabled
          label={"First Name"}
          isInternal
          onChange={(val: any) => handleChange("firstName", "event", val)}
          required
          placeholder={"Enter first name"}
          value={values.firstName}
          errors={errors}
          touched={touched}
          className={"mt-[24px]"}
        />
        <CustomInput
          id={"lastName"}
          key={"lastName"}
          name={"lastName"}
          label={"Last Name"}
          disabled
          isInternal
          onChange={(val: any) => handleChange("lastName", "event", val)}
          required
          placeholder={"Enter last name"}
          value={values.lastName}
          errors={errors}
          touched={touched}
          className={""}
        />
        <CustomInput
          id={"email"}
          key={"email"}
          name={"email"}
          label={"Email ID"}
          required={false}
          placeholder={"Enter email ID"}
          disabled
          isInternal
          onChange={(val: any) => handleChange("email", "event", val)}
          value={values.email}
          errors={errors}
          touched={touched}
          className={""}
        />
        {/* {role && role==='Collaborative Order Agreement' && <>
        <div className="flex flex-row">
          <CustomInput
            id={"primaryPhone"}
            key={"primaryPhone"}
            name={"primaryPhone"}
            label={"Phone"}
            required
            placeholder={"000-000-0000"}
            type={"tel"}
            value={values.primaryPhone}
            errors={errors}
            // onChange={(val: any) => setFieldValue('fax', val, true)}
            isInternal
            onChange={(val: any) => handleChange("primaryPhone", "event", val)}
            touched={touched}
            className={""}
            disabled
          />
          <CustomInput
            id={"fax"}
            key={"fax"}
            name={"fax"}
            label={"Fax"}
            required
            disabled
            placeholder={"000-000-0000"}
            type={"tel"}
            value={values.fax}
            // onChange={(val: any) => setFieldValue('phone', val, true)}
            isInternal
            onChange={(val: any) => handleChange("fax", "event", val)}
            errors={errors}
            touched={touched}
            className={"ml-3"}
          />
        </div>
        </>
        } */}
        <div
          className={`${role && role === "Physician" ? "flex flex-row" : ""}`}
        >
          {role && role === "Physician" && (
            <CustomInput
              id={"specialty"}
              key={"specialty"}
              name={"specialty"}
              label={"Speciality"}
              required
              disabled
              placeholder={"Select Speciality"}
              // suggestions={
              //   !error && specialityData.length > 0
              //     ? specialityData.map((state: any) => {
              //       return `${state.name}`;
              //     })
              //     : []
              // }
              suggestions={
                !error && specialityData.length > 0
                  ? specialityData.map((state: any) => ({ label: state.name, value: state.name }))
                  : []
              }
              isInternal
              onChange={
                specialityData && specialityData.length > 0
                  ? (val: any) => handleChange("specialty", "val", val)
                  : (val: any) => handleChange("specialty", "event", val)
              }
              value={values.specialty}
              errors={errors}
              touched={touched}
              className={"mt-2"}
            />
          )}
        </div>
        {role && role === "Physician" && (
          <div className="flex flex-row">
            <CustomInput
              id={"npi"}
              key={"npi"}
              name={"npi"}
              label={"NPI"}
              disabled
              placeholder={"Enter NPI"}
              type={"number"}
              required
              value={values.npi}
              isInternal
              onChange={(val: any) => handleChange("npi", "event", val)}
              errors={errors}
              touched={touched}
              className={"mr-2"}
            />
            <CustomInput
              id={"suffix"}
              key={"suffix"}
              name={"suffix"}
              label={"Suffix"}
              disabled
              required
              placeholder={"Select Suffix"}
              // suggestions={
              //   !error && suffixData.length > 0
              //     ? suffixData.map((state: any) => {
              //       return `${state.name}`;
              //     })
              //     : []
              // }
              suggestions={
                !error && suffixData.length > 0
                  ? suffixData.map((state: any) => ({ label: state.name, value: state.name }))
                  : []
              }
              isInternal
              onChange={
                suffixData && suffixData.length > 0
                  ? (val: any) => handleChange("suffix", "val", val)
                  : (val: any) => handleChange("suffix", "event", val)
              }
              value={values.suffix}
              errors={errors}
              touched={touched}
              className={"mt-4"}
            />
          </div>
        )}
        {/* {role && role =='Collaborative Order Agreement' && <>
        <CustomInput
          id={"streetAddress"}
          key={"streetAddress"}
          name={"streetAddress"}
          label={"Address"}
          isInternal={!isPage}
          onChange={(val: any) => handleChange("streetAddress", "event", val)}
          required={true}
          disabled
          placeholder={"Enter street address"}
          value={values.streetAddress}
          errors={errors}
          touched={touched}
          className={"mt-2 "}
        />
        <CustomInput
          id={"state"}
          key={"state"}
          name={"state"}
          label={"State/Province"}
          required
          placeholder={"Enter state"}
          // suggestions={
          //   !error && states.length > 0
          //     ? states.map((state: any) => {
          //       return `${state.name} ${state.state_code}`;
          //     })
          //     : []
          // }
          suggestions={
            !error && states.length > 0
              ? states.map((state: any) => ({ label: state.name, value: state.state_code }))
              : []
          }
          isInternal={!isPage}
          onChange={
            states && states.length > 0
              ? (val: any) => handleChange("state", "val", val)
              : (val: any) => handleChange("state", "event", val)
          }
          // value={values.state}
          value={values?.stateValue ? values.stateValue : values.state ? states && states.length > 0 && states.find((state: any) => `${state?.name} ${state?.state_code}`.includes(values?.state))?.name : values.state}
          errors={errors}
          disabled
          touched={touched}
        />
        <div className="flex flex-row">
          <CustomInput
            id={"city"}
            key={"city"}
            name={"city"}
            label={"City"}
            disabled
            required
            placeholder={"Enter city name"}
            // suggestions={
            //   cities && cities.length > 0
            //     ? cities.map((city: any) => {
            //       return city.name;
            //     })
            //     : []
            // }
            suggestions={
              cities && cities.length > 0
                ? cities.map((city: any) => ({ label: city.name, value: city.name }))
                : []
            }
            value={values.city}
            errors={errors}
            isInternal={!isPage}
            onChange={
              cities && cities.length > 0
                ? (val: any) => handleChange("city", "val", val)
                : (val: any) => handleChange("city", "event", val)
            }
            touched={touched}
            className={"mr-2"}
          />
          <CustomInput
            id={"zipCode"}
            key={"zipCode"}
            name={"zipCode"}
            label={"ZIP Code"}
            required
            placeholder={"Enter zip code"}
            value={values.zipCode}
            disabled
            errors={errors}
            isInternal
            onChange={(val: any) => handleChange("zipCode", "event", val)}
            className={"ml-2"}
            touched={touched}
          />
        </div>
        </>
      } */}
        {/* {role && role === "Physician" && (
          <div className="flex">
            <CustomInput
              id={"signPermission"}
              key={"signPermission"}
              name={"signPermission"}
              label={
                "I allow the Office Manager to use my e-signature to submit an order."
              }
              checked={values?.sof?.id ? true :false }
              required={false}
              showToolTip={true}
              tooltipText="You are unable to edit this checkbox because it is dependent upon Signature on File (SOF)."
              placeholder={""}
              errors={errors}
              onChange={(e: any) => {
                handleSignPermission(e)
              }}
              disabled={true}
              type="checkbox"
              touched={touched}
              className="self-start"
            // className="w-[83%]"
            // signupFieldsStyle={'-mt-1'}
            />
          </div>
        )} */}
      </div>
    </div>
  );
}

function UpdatePortalUserDetail({
  screen,
  loggedInUser,
  toggleSumbit,
  organizationsList,
  loadingOrganizations,
  loadingOrganizationsError,
  selectedUser,
  profile,
  getSuffix,
  getSpeciality,
  getOrganizations,
  fetchPortalUserById,
  updatePortalUserById,
  members,
}: any): JSX.Element {
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();
  // const navigate = useNavigate()
  const history = useHistory();
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [initialMembers, setInitialMembers] = useState<any[]>([]);
  const { selectedPhysician } = useSelector((state: any) => state.OnlineOrderForm)
  const { id } = useParams<any>();

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });

  const [listHeight, setListHeight] = useState(window.innerHeight - (window.innerHeight * 0.22))

  const setDimension = () => {
    if (window.innerHeight <= 370) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.32))
    } else if (window.innerHeight <= 400) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.3))
    } else if (window.innerHeight <= 450) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.26))
    } else if (window.innerHeight <= 500) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.24))
    } else if (window.innerHeight <= 530) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.23))
    } else if (window.innerHeight <= 570) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.22))
    } else if (window.innerHeight <= 600) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.2))
    } else if (window.innerHeight <= 630) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.19))
    } else if (window.innerHeight <= 655) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.18))
    } else if (window.innerHeight <= 680) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.18))
    } else if (window.innerHeight <= 700) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.17))
    } else if (window.innerHeight <= 715) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.17))
    } else if (window.innerHeight <= 750) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.16))
    } else if (window.innerHeight <= 780) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.15))
    } else if (window.innerHeight <= 800) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.16))
    } else if (window.innerHeight <= 850) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.3))
    } else if (window.innerHeight <= 880) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.14))
    } else if (window.innerHeight <= 900) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.27))
    } else if (window.innerHeight <= 980) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.13))
    } else if (window.innerHeight <= 1050) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.119))
    } else {
      setListHeight(window.innerHeight - (window.innerHeight * 0.1119))
    }
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }
  useEffect(() => {
    // calling to initially set list height on basis of screen height
    setDimension()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', setDimension);
    return (() => {
      window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])
  const fetchUser = (): void => {
    if (authState?.accessToken?.accessToken) {
      fetchPortalUserById(id as string, {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      });
    }
  };
  useEffect(() => {
    // changed from true to false due to temporary functionality of editing portal user removal 
    toggleSumbit(false);
    if (screen !== "UpdatePortalUser") {
      dispatch(ButtonStateActions.setButtonState("UpdatePortalUser"));
    }
    fetchUser();
    getSuffix({
      data: {
        token: authState?.accessToken?.accessToken,
      },
      onError: () => {
        alert("error occured");
      },
    });
    getSpeciality({
      data: {
        token: authState?.accessToken?.accessToken,
      },
      onError: () => {
        alert("error occured");
      },
    });
  }, []);
  useEffect(() => {
    if (organizationsList) {
      setOrganizations(
        organizationsList.map((organization: any) => {

          let found = profile?.organizationsRolesAndSOF?.find(
            (selectedOrg: any) => selectedOrg.id === organization.id
          );
          if (found) {
            return {
              name: organization?.name,
              id: organization.id,
              selected: true,
              ...organization
            };
          }

          return {
            name: organization?.name,
            id: organization.id,
            selected: false,
            ...organization
          };
        })
      );
    }
  }, []);
  function handleOrganizationClick(index: number, isValid: boolean): any {
    return () => {
      const temp = [...organizations];
      temp[index].selected = !temp[index].selected;
      setOrganizations(temp);
      const nonSelected =
        temp.filter((data) => data.selected === false).length ===
        organizationsList.length;

      if (nonSelected) {
        toggleSumbit(false);
      } else {
        toggleSumbit(isValid);
      }
    };
  }
  let initialValues: EditPortalUserForm;

  initialValues = {
    firstName: profile ? profile.firstName : "...",
    lastName: profile ? profile.lastName : "...",
    email: profile ? profile.email : "",
    profileImage: profile ? profile.profileImage : "...",
    imageUrl: profile ? profile.imageUrl : "",
    primaryPhone: profile ? profile.primaryPhone : "...",
    fax: profile ? profile.fax : "...",
    streetAddress: profile ? profile.streetAddress : "..",
    zipCode: profile ? profile.zipCode : "..",
    state: profile ? profile.state : "..",
    city: profile ? profile.city : "..",
    role: profile
      ? Array.isArray(profile.roles)
        ? profile.roles[0]
        : profile.roles
      : "..",
    npi: profile ? profile.npi : "..",
    suffix: profile ? profile.suffix : "..",
    specialty: profile ? profile.specialty : "..",
    pinCode: profile ? profile.pinCode : "..",
    signPermission: profile ? profile.signPermission : false,
    members: [],
    organizationIds: [],
    sof: profile ? profile?.sof : '---',
    // email: profile ? profile.email : "..",
    // organizations: profile ? profile.organizations : "..",
  };

  function onSubmit(data: EditPortalUserForm): void {
    // let role = profile?.roles.length > 1 && profile?.roles?.includes('Physician') ? 'Physician' : profile?.roles.includes('Office Staff') ? 'Staff' : profile.roles[0]
    let role = profile?.roles.length > 1 && profile?.roles?.includes('Physician') ? 'Physician'
      : profile.roles[0]
    const requestData = {
      id,
      firstName: data.firstName,
      lastName: data.lastName,
      primaryPhone: data.primaryPhone,
      fax: data.fax,
      streetAddress: data.streetAddress,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
      profileImage: data.profileImage ?? "",
      role: role,
      specialty: data.specialty,
      suffix: data.suffix,
      npi: data.npi,
      sof: data.sof,
      members: [],
      signPermission: data.signPermission,
      organizationIds: organizations
        .filter((org: any) => org.selected === true)
        ?.map((org: any) => org.id),
      accessToken: authState?.accessToken?.accessToken,
    };

    dispatch(ButtonStateActions.formSubmit(true));
    const options = {
      accessToken: authState?.accessToken?.accessToken,
      data: requestData,
      onSuccess: (message: any) => {
        toastMessages.success(message ?? "Portal user details updated");
        dispatch(ButtonStateActions.toggleSumbit(false));
        dispatch(ButtonStateActions.formSubmit(false));
        dispatch(ButtonStateActions.setButtonState("my profile"));
        dispatch(InviteUserActions.resetMembers());
        if (role === "Physician") {
          if (selectedUser.id === selectedPhysician.id) {
            dispatch(updateSelectedPhysician({ ...selectedPhysician, signPermission: data.signPermission }))
          }

          const options = {
            onError: (message: string) =>
              toastMessages.error(
                message ??
                "Failed to fetch organizations, Please refresh the app"
              ),
            config: {
              headers: {
                Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
              },
            },
          };
          getOrganizations(options);
        }
        history.replace(`/manage/portal-users/profile/${id}`);

        // navigate('/profile', { replace: true })
      },
      onError: (message: any) => {
        toastMessages.error(message ?? "Error in updating portal user details");
      },
    };

    updatePortalUserById(options);
  }

  return (
    <ManageScreenLayout headerTitle={"User Details"}>
      <div className="col-span-12">
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="col-span-4"
          >
            <div className="py-5 px-12 overflow-auto" style={{ height: listHeight }}>
              <Formik
                initialValues={initialValues}
                isInitialValid={true}
                onSubmit={onSubmit}
                innerRef={formRef}
                // validationSchema={EditPortalUserFormValidation}
                render={({
                  errors,
                  touched,
                  setFieldValue,
                  isValid,
                  values,
                  isSubmitting,
                  validateForm,
                }) => (
                  <Form>
                    <RenderFields
                      isPage
                      // role={
                      //   profile?.roles
                      //     ? profile?.roles[0] === "Staff"
                      //       ? "Office Staff"
                      //       : profile.roles[0]
                      //     : "Office Manager"
                      // }
                      role={
                        profile?.roles?.length > 1 && profile?.roles?.includes('Physician')
                          ? 'Physician'
                          : (profile?.roles?.length > 0 ? profile.roles[0] : '')
                      }
                      validateForm={validateForm}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      isValid={isValid}
                      values={values}
                      selectedUser={selectedUser}
                    />
                    <div className="flex">
                      <RequiredFieldTooltip required={true} tooltipText={'Required Field'}>
                        <p className="text-royalBlue font-semibold py-3 after:content-['*'] after:ml-1 after:text-requiredRed">
                          Associated Organization(s)
                        </p>
                      </RequiredFieldTooltip>
                      <TooltipHelper className="" id="orgInfo" backgroundColor="#18B5B7" />
                      <div
                        data-tip="The organizations that you see here are the ones which are mutual between you and this user.
            <br />The selected ( with ✓ )  organizations are the ones that this user has permission of."
                        data-iscapture="true"
                        data-for="orgInfo"
                        data-multiline={true}
                        style={{ height: 15, width: 15 }}
                        className={
                          " mt-[17px] ml-2 cursor-pointer rounded-full bg-primary hover:bg-primaryHover text-center text-white flex flex-col justify-center items-center font-medium text-xs"
                        }
                      >
                        i
                      </div>
                    </div>

                    {loadingOrganizations && !loadingOrganizationsError ? (
                      <div>
                        <p>Loading...</p>
                      </div>
                    ) : !loadingOrganizations && loadingOrganizationsError ? (
                      <div>Something went wrong</div>
                    ) : (
                      <div className="flex flex-wrap min-w-full tab:pb-12">

                        {organizations.map(
                          (organization: any, index: number) => (
                            <OrganizationPillBox
                              key={organization.id}
                              organization={organization}
                              onClick={handleOrganizationClick(index, isValid)}
                              disabled
                            // disabled={
                            //   loggedInUser?.roles?.includes(
                            //     "Office Manager"
                            //   ) ||
                            //   (loggedInUser?.roles?.includes("Physician") &&
                            //     profile?.roles?.includes("Staff"))
                            //     ? false
                            //     : true
                            // }
                            />
                          )
                        )}
                      </div>
                    )}
                  </Form>
                )}
              />
              <div className="my-3 overflow-visible">
                {/* {profile &&
                formRef.current &&
                loggedInUser?.roles?.includes("Office Manager") ? (
                  <Associations
                    initialMembers={members}
                    isUser={true}
                    formValid={formRef?.current?.isValid ?? false}
                    organizationsList={organizationsList}
                    selectedOrganizations={organizationsList.filter(
                      (org: any) => {
                        const find = organizations.find(
                          (item: any) => item.id === org.id
                        );
                        if (find) {
                          return find.selected;
                        }
                        return null;
                      }
                    )}
                    role={
                      profile?.roles
                        ? profile?.roles[0] === "Staff"
                          ? "Office Staff"
                          : profile.roles[0]
                        : "Office Manager"
                    }
                    
                  />
                ) : (
                  <RenderAssociatedMembers
                    members={selectedUser?.members ?? []}
                    selectedUser={selectedUser}
                    role={
                      selectedUser?.profile?.roles
                        ? selectedUser?.profile?.roles[0]
                        : "Office Manager"
                    }
                  />
                )} */}
                {/* <RenderAssociatedMembers
                  members={selectedUser?.members ?? []}
                  selectedUser={selectedUser}
                  role={
                    selectedUser?.profile?.roles
                      ? selectedUser?.profile?.roles[0]
                      : "Office Manager"
                  }
                /> */}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </ManageScreenLayout>
  );
}

const mapStateToProps = (state: any): any => ({
  profile: { ...state?.PortalUsers?.selectedUser?.user, ...state?.PortalUsers?.selectedUser },
  loggedInUser: state?.Profile?.profile,
  members: state?.PortalUsers?.selectedUser?.members,
  selectedUser: state?.PortalUsers?.selectedUser?.user,
  screen: state.ButtonState.screen,
  loadingOrganizations: state.Organizations.fetching,
  loadingOrganizationsError: state.Organizations.error,
  organizationsList: state.Organizations.data,
});
const mapDispatchToProps = {
  fetchPortalUserById: PortalUsersActions.fetchPortalUserById,
  updatePortalUserById: PortalUsersActions.updatePortalUserById,
  getSpeciality: SuffixAndSpecialityActions.fetchSpeciality,
  getSuffix: SuffixAndSpecialityActions.fetchSuffix,
  toggleSumbit: ButtonStateActions.toggleSumbit,
  getOrganizations: OrganizationActions.fetchOrganizations,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePortalUserDetail);
