/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import OrganizationNoImage from '../../assets/svg/OrganizationNoImage'
import OrganizationDetailLoadingIndicator from '../global/LoadingIndicators/OrganizationDetailLoadingIndicator'
import { updateSelectedOrganization, updateSelectedPathLab } from '../../Store/Actions/OnlineOrderFormActions'

function DetailHolder({ label, value }: any): JSX.Element {
    return <div className="my-3 mr-8">
        <p className={'self-center text-sm font-semibold pb-1'}>{label}</p>
        <p className="text-royalBlue text-base font-normal break-all">{value ?? '---'}</p>
    </div>
}
function PathLabDetail({ id }: any): JSX.Element {
    const dispatch = useDispatch()
    const [detail, setDetail] = useState<any>(null)
    const { data, fetching, error } = useSelector((state: any) => state.PathLab)
    const { data: states, error: statesError } = useSelector((state: any) => state.StateAndCity)

    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const [listHeight, setListHeight] = useState(window.innerHeight - (window.innerHeight * 0.22))

    const setDimension = () => {
        if (window.innerHeight <= 370) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.50))
        } else if (window.innerHeight <= 400) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.48))
        } else if (window.innerHeight <= 450) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.42))
        } else if (window.innerHeight <= 480) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.4))
        } else if (window.innerHeight <= 500) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.38))
        } else if (window.innerHeight <= 530) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.36))
        } else if (window.innerHeight <= 570) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.34))
        } else if (window.innerHeight <= 600) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.32))
        } else if (window.innerHeight <= 630) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.3))
        } else if (window.innerHeight <= 655) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.29))
        } else if (window.innerHeight <= 680) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.28))
        } else if (window.innerHeight <= 700) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.27))
        } else if (window.innerHeight <= 715) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.27))
        } else if (window.innerHeight <= 750) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.26))
        } else if (window.innerHeight <= 800) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.24))
        } else if (window.innerHeight <= 850) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.22))
        } else if (window.innerHeight <= 880) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.22))
        } else if (window.innerHeight <= 900) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.21))
        } else if (window.innerHeight <= 980) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.196))
        } else if (window.innerHeight <= 1030) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.28))
          } else if (window.innerHeight <= 1050) {
            setListHeight(window.innerHeight - (window.innerHeight * 0.1))
        } else {
            setListHeight(window.innerHeight - (window.innerHeight * 0.2))
        }
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        // calling to initially set list height on basis of screen height
        setDimension()
    }, [])

    useEffect(() => {
        window.addEventListener('resize', setDimension);
        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    useEffect(() => {
        // call api here to get organization details
        setDetail(data.find((pathlab: any) => pathlab.id == id))
    }, [id, fetching])

    const history = useHistory()

    function openUsers(): void {
        dispatch(updateSelectedPathLab(data.find((item: any) => item.id == detail.id)))
        history.push(`/manage/portal-users/${detail.id}`)
    }
    if (fetching) { return <OrganizationDetailLoadingIndicator /> }

    if (!fetching && error) {
        return <div className="px-12 h-full pb-[45%] lg:pb-[35%] xl:pb-[25%] 2xl:pb-0">
            <p>Something went wrong...</p>
        </div>
    }

    return <div className=" px-12 overflow-auto" style={{ height: listHeight }}>
        <p className="text-sm font-semibold mt-1 mb-3">
            Logo
        </p>
        {detail && detail?.logoUrl
            ? <img
                className="h-24 w-4/6 lg:w-3/6 object-fit my-4 rounded"
                src={detail?.logoUrl}
            />
            : <div className="h-24 w-4/6 lg:w-3/6 rounded my-4">
                <OrganizationNoImage />
            </div>}
        <DetailHolder label={'PathLab Name'} value={detail && detail?.name} />
        {/* <DetailHolder label={'Description'} value={detail && detail?.profile?.description ? detail?.profile?.description : '---'} /> */}
        <DetailHolder label={'Description'} value={detail && detail?.description ? detail?.description : '---'} />
        <div className="flex items-center">
            <DetailHolder label={'PathLab Email ID'} value={detail && detail?.email} />
            <DetailHolder label={'Phone'} value={detail && detail?.phone} />
            <DetailHolder label={'Fax'} value={detail && detail?.fax} />
        </div>
        <DetailHolder label={'Address'} value={detail && detail?.address} />
        <div className="flex items-center">
            <DetailHolder label={'City'} value={detail && detail?.city} />
            <DetailHolder label={'ZIP Code'} value={detail && detail?.zipcode} />
            {/* <DetailHolder label={'State/Province'} value={detail && detail?.profile?.state} /> */}
            <DetailHolder label={'State/Province'} value={detail && detail?.state ? states && states.length > 0 && states.find((state: any) => `${state.name} ${state.state_code}`.includes(detail?.state))?.name : detail?.state} />
        </div>

        <p className="text-sm font-semibold mt-5 mb-3">
            Total Number of Portal Users in this PathLab {detail && detail.users && <span>({detail.users.length})</span>}
        </p>
        <button type="button" onClick={openUsers} className="text-[#18b5b7] font-semibold text-xs m-0 p-0 lg:text-base uppercase pb-3 tab:mb-16">
            see users
        </button>

    </div>
}

export default PathLabDetail
