import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Button from '../../../../components/atomic/Button'
import { useHistory, useParams } from 'react-router-dom'
import DataTable from '../../../../components/DataTable/DataTable'
import { ITableHeaderProps } from '../../../../components/DataTableHeader/DataTableHeader'
import ManageScreenLayout from '../../../../Containers/ManageScreenLayout'
import ButtonStateActions from '../../../../Store/Actions/ButtonStateActions'
import PortalUsersActions from '../../../../Store/Actions/PortalUsersActions'
import PortalUsersReducer from '../../../../Store/Reducers/PortalUsersReducer'
import { useOktaAuth } from '@okta/okta-react'
import TableLoadingIndicator from '../../../../components/global/LoadingIndicators/TableLoadingIndicator'
import http from '../../../../Store/API/HttpMethods'
import toastMessages from '../../../../utils/helpers/toastMessage'
import OrganizationActions from '../../../../Store/Actions/OrganizationsActions'
import { suggestion } from '../../../../components/organism/SuggstionsDropdown'
import PathLabActions from '../../../../Store/Actions/PathLabActions'

function PortalUsersListScreen({ portalUsers, fetchPortalUsers, fetchPortalUsersByOrgId, fetchPortalUsersByPathLabID, fetching, fetchOrganizations, error }: any) {
  const { screen } = useSelector((state: any) => state.ButtonState)
  const { selectedOrganization } = useSelector((state: any) => state.OnlineOrderForm)
  const { profile } = useSelector((state: any) => state.Profile)
  const { data: organisationsData } = useSelector((state: any) => state.Organizations)
  const { orgId, pathlabid } = useParams<{ orgId: string, pathlabid: string }>()
  const [firstEntry, setFirstEntry] = useState<boolean>(false)
  // const { id } = useParams<{id: string}>();
  const { authState } = useOktaAuth()
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const history = useHistory()
  const [header, setHeader] = useState<ITableHeaderProps>({
    hasVerticalMenu: false,
    onFilter(filters: any) {
      handleFilters(filters, portalUsers)
    },
    NavLink: '',
    nnProps: '',
    check: false,
    hideCheckBox: true,
    data: [
      {
        name: 'Name',
        filter: true,
        preventAutoSearch: false
        // suggestions: ['name', 'ali'],
      },
      {
        name: 'Email ID',
        filter: true,
        preventAutoSearch: false
      },
      {
        name: 'Role',
        filter: true,

        preventAutoSearch: false,
      },
      {
        name: 'PathLab',
        filter: true,
        preventAutoSearch: false,
      },
      {
        name: 'Status',
        filter: true,
        preventAutoSearch: false,
        action: {
          type: 'filter',
          onClick(field: any, filter: any) {
            // console.log(field, filter)
          },
          dropdownList: [
            { label: 'Active', key: 'Active' },
          ],
          currentSelection: { label: '', key: '' }
        }
      },
    ],
    list: [{ name: 'name' },
    { email: 'email' }]
  })

  const [tableBody, setTableBody] = useState<any[]>(portalUsers || [])
  const { selectedPathLab } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  function handleSuggestions(name: string): suggestion[] {
    let suggestions: suggestion[] = []

    if (name.toLowerCase() === 'name' && portalUsers?.length > 0) {
      portalUsers?.forEach((user: any) => {
        let name: string = `${user?.data?.name?.value}`
        suggestions.push({ label: name, value: name })
      })
    }

    if (name.toLowerCase() === 'email id' && portalUsers?.length > 0) {
      portalUsers?.forEach((user: any) => {
        let email: string = `${user?.data?.email}`
        suggestions.push({ label: email, value: email })
      })
    }

    if (name.toLowerCase() === 'role') {
      let isCOA = profile?.roles?.includes('Collaborative Order Agreement')
      let isPLU = profile?.roles?.includes('PathLab User')

      let allRoles = [
        {
          label: 'Office Manager',
          value: 'Office Manager'
        },
        {
          label: 'Clinician',
          value: 'Clinician'
        },
        {
          label: 'Collaborative Order Agreement',
          value: 'Collaborative Order Agreement'
        },
        {
          label: "PathLab User",
          value: "PathLab User"
        }
      ]

      suggestions = allRoles.filter((item: any) => {
        if (isPLU) {
          return item.label == 'PathLab User'
        }
        if (!isCOA) {
          return item.label !== 'Collaborative Order Agreement' && item.label !== 'PathLab User'
        }
        if(isCOA){
          return item.label !== 'Office Manager' && item.label !== 'PathLab User'
        }
        return item
      })
    }

    if (name.toLowerCase() === 'organizations' && organisationsData.length > 0) {
      organisationsData.forEach((org: any) => {
        suggestions.push({ label: org?.profile?.name, value: org?.profile?.name })
      })
    }

    if (name.toLowerCase() === 'status') {
      suggestions = [{ label: 'Active', value: 'Active' }, { label: 'Pending', value: 'Pending' }]
    }

    return suggestions
  }
  // useEffect(() => {
  //   if (organisationsData.some((org: any) => !!org.users === false)) {

  //     const options = {
  //       onError: (message: string) => toastMessages.error(message ?? 'Failed to fetch organizations, Please refresh the app'),
  //       config: {
  //         headers: {
  //           Authorization: `Bearer ${authState?.accessToken?.accessToken}`
  //         }
  //       }
  //     }
  //     fetchOrganizations(options)
  //   }
  // }, [fetchOrganizations])


  useEffect(() => {
    if (authState?.accessToken?.accessToken) {
      if (selectedOrganization || selectedPathLab) {
        const options = {
          config: { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } },
          organizationId: selectedOrganization?.id,
          pathologyLabId: selectedPathLab?.id,
          fetchMyProfile: true
        }
        if (profile.roles.includes('PathLab User')) {
          delete options.organizationId
        }

        fetchPortalUsersByOrgId(options)

      } else {
        fetchPortalUsers({ headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } }, true)
      }
    }

    if (screen !== 'portal-users') { dispatch(ButtonStateActions.setButtonState('portal-users')) }
  }, [selectedOrganization, selectedPathLab])

  async function handleUserStatus(user: any, status: string) {
    let finalStatus = status === 'Pending' ? 'active' : status === 'Blocked' ? 'active' : 'block'

    if (profile?.roles.includes('Staff')) return toastMessages.error('You are not authorized to perform this action')

    if (profile?.roles.includes('Physician') && user?.data?.role?.includes('Office Manager')) return toastMessages.error('You are not authorized to perform this action')

    if (profile?.id === user?.id) return toastMessages.error('You cannot change your own status')

    try {
      const body: any = {
        userId: user.id,
        status: finalStatus
      }
      const config: any = {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`
        }
      }
      const response = await http.updateStatus(
        '/v1/users/change-status-latest',
        body,
        config
      )
      const { statusCode, message, error } = response

      if (error) {
        toastMessages.error(`Something went wrong. Unable to change ${user?.data?.name?.value} status.`)
      }

      if (statusCode === 200) {
        setTableBody((prevState: any) => prevState.map((item: any) => {
          if (item.id === user.id) {
            return {
              ...item,
              data: {
                ...item.data,
                status: finalStatus === 'block' ? 'Blocked' : 'Active'
              },
              // threeDotsActions: user?.data?.status === 'Pending' ? [] : [
              //   {
              //     label: finalStatus === 'block' ? 'Unblock User' : 'Block',
              //     onClick: () => handleUserStatus(user, finalStatus === 'block' ? 'Blocked' : 'Active')
              //   }
              // ]
            }
          }
          return {
            ...item
          }
        }))
      }
    } catch (error: any) {

      if (error?.response?.data?.message) {
        toastMessages.error(error?.response?.data?.message)
      } else
        toastMessages.error(`Something went wrong. Unable to change ${user?.data?.name?.value} status.`)
    }
  }

  function handleThreeDotsActions(user: any) {
    let threeDotsActions: any[] = [
      {
        label: user?.data?.status === 'Blocked' ? 'Unblock User' : user?.data?.status === 'Pending' ? 'Active' : 'Block User',
        onClick: () => handleUserStatus(user, user?.data?.status ?? 'Pending')
      }
    ]

    if (user?.id === profile?.id) {
      return threeDotsActions = []
    }

    // if (profile?.roles?.includes('Staff')) {
    //   return threeDotsActions = []
    // }


    if (profile?.roles?.includes('Physician') && profile?.roles?.length === 1) {
      let userRoles: any = []

      organisationsData?.forEach((organization: any) => {
        let foundUser = organization?.users?.find((orgUser: any) => user?.id === orgUser?.id)
        if (foundUser) {
          userRoles.push(foundUser?.profile?.roles)
        }
      })


      // if (userRoles && userRoles.flat()?.includes('Staff') && user?.data?.status !== 'Pending') {
      //   return threeDotsActions = [
      //     {
      //       label: user?.data?.status === 'Blocked' ? 'Unblock User' : user?.data?.status === 'Pending' ? 'Active' : 'Block',
      //       onClick: () => handleUserStatus(user, user?.data?.status ?? 'Pending')
      //     }
      //   ]

      // } else {
      //   return threeDotsActions = []
      // }

    }

    if (user?.data?.status === 'Pending' || user?.data?.status === 'N/A') {
      return threeDotsActions = []
    }

    return threeDotsActions
  }

  useEffect(() => {
    let updatedPortalUsers: any[] = portalUsers?.map((user: any) => {
      const newData = { ...user.data };
      if (profile?.roles?.includes('Office Manager') || profile?.roles?.includes("Physician")) {
        delete newData.role;
        delete newData.pathLab;
      }
      if(profile?.roles?.includes('Collaborative Order Agreement')){
        delete newData.pathLab;
      }
      return {
        ...user,
        data: newData,
        // threeDotsActions: handleThreeDotsActions(user)
      };
    });
    setHeader((prevState: any) => {
      let data = [...prevState.data];

      if (profile?.roles?.includes('Office Manager') || profile?.roles?.includes("Physician")) {
        data = data.filter((item: any) => item.name !== "PathLab" && item?.name !== 'Role');
      }
      if(profile?.roles?.includes('Collaborative Order Agreement')){
        data= data.filter((item:any)=>item.name!== 'PathLab')
      }
      return {
        ...prevState,
        // hasVerticalMenu: profile.roles?.includes('Staff') ? false : true,
        hasVerticalMenu: false,
        data: data.map((item: any) => item = {
          ...item,
          hideIds: false,
          suggestions: handleSuggestions(item.name),
          action: {
            ...item.action,
            type: 'sort',
            onClick(field: string, filter: string) {
              handleSort(field, filter, updatedPortalUsers)
            },
            dropdownList: [
              { label: 'Ascending', key: 'ascending' },
              { label: 'Descending', key: 'descending' }
            ],
            currentSelection: { label: '', key: '' }
          },
        })
        ,
        onFilter(filters: any) {
          handleFilters(filters, updatedPortalUsers)
        }
      }
    })
    setTableBody(updatedPortalUsers)

  }, [portalUsers?.length])

  function handleFilters(filters: any, portalUsers: any[]) {
    let filteredList: any[] = []; // Initialize filteredList outside the loop
    let foundInputs: any = {}

    // Populating object to match all the values of keys while filtering
    Object.keys(filters)?.forEach((col: any) => {
      const input = filters[col];
      if (input) {
        portalUsers.some((user: any) => {
          const foundKey = Object?.keys(user?.data)[Number(col)];
          if (foundKey) {
            foundInputs[foundKey] = input
          }
          return foundKey
        })

      }
    });

    // Match both the value and count if all keys and their values match
    filteredList = portalUsers.filter(user => {
      let foundInputKeysCount = Object.keys(foundInputs).length
      let matchCount = 0

      Object.keys(user?.data).map(key => {
        const value = Array.isArray(user?.data[key]) ? user?.data[key].join(',') : user?.data[key]?.value ? user?.data[key].value : user?.data[key]?.name ? user?.data[key].name : user?.data[key]

        if ((value)?.toLowerCase().includes(foundInputs[key]?.toLowerCase())) {
          matchCount = matchCount + 1
        }
      })

      if (matchCount === foundInputKeysCount) return true
      return false
    })

    const isFiltersEmpty = !Object.values(filters).some(
      (x) => x !== null && x !== ''
    );
    if (!isFiltersEmpty) {
      setTableBody(filteredList);
    } else {
      setTableBody(portalUsers);
    }
  }

  const handleSort = useCallback(onSort, portalUsers)
  function onSort(sortField: string, sortDirection: string, data: any) {

    let fields: any = {
      'Name': 'name',
      'Email ID': 'email',
      'Organizations': 'organizations',
      'Role': 'role',
      'Status': 'status',
      'PathLab': 'pathlab'
    }
    if (data) {
      const sortedData = [...data]
      sortedData.sort((a, b) => {
        if (sortField === 'Name') {
          if ((a.data[fields[sortField]].value.toLowerCase() < b.data[fields[sortField]].value.toLowerCase()) && sortDirection == 'ascending') {
            return -1
          }
          if ((a.data[fields[sortField]].value.toLowerCase() > b.data[fields[sortField]].value.toLowerCase()) && sortDirection == 'descending') {
            return -1
          }
        } else {
          if ((a.data[fields[sortField]].toLowerCase() < b.data[fields[sortField]].toLowerCase()) && sortDirection == 'ascending') {
            return -1
          }
          if ((a.data[fields[sortField]].toLowerCase() > b.data[fields[sortField]].toLowerCase() && sortDirection == 'descending')) {
            return -1
          }
        }

        return 0
      })

      setTableBody(sortedData)
    }
  };


  return <ManageScreenLayout headerTitle={'Portal Users'}>
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className='col-span-4 '>
        <div className="py-5 px-12 flex-none">
          <p className="text-royalBlue font-semibold">Portal Users List</p>
          <div className="my-5 overflow-visible">
            {fetching
              ? <TableLoadingIndicator />
              : <DataTable
                NavLink={''}
                showNumbering
                body={tableBody}
                className='max-h-[67vh] lg:max-h-[75vh] tab:max-h-[60vh] overflow-auto pb-16'
                header={header}
                portaluserHeader
                usersListDebounce
              />}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  </ManageScreenLayout>
}

const mapStateToProps = (state: any) => ({
  portalUsers: state.PortalUsers.data,
  fetching: state.PortalUsers.fetching,
  error: state.PortalUsers.error
})
const mapDispatchToProps = {
  fetchPortalUsers: PortalUsersActions.fetchPortalUsers,
  fetchPortalUsersByOrgId: PortalUsersActions.fetchPortalUsersByOrgId,
  fetchOrganizations: OrganizationActions.fetchOrganizations,
  fetchPortalUsersByPathLabID: PathLabActions?.fetchPortalUsersByPathLabID
}
export default connect(mapStateToProps, mapDispatchToProps)(PortalUsersListScreen)
