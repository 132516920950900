/* eslint-disable @typescript-eslint/space-before-function-paren */
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../../components/atomic/Button";
import ProgressBar from "../../../components/molecular/ProgressBar";
import ReviewSection from "../../../components/organism/OnlineOrderForm/ReviewSection";
import PatientInfoForm from "../../../components/organism/TestOrderForms/PatientInfoForm";
import AdditionalInfoSection from "../../../components/organism/TestOrderForms/Sections/AdditionalInfoSection";
import BillingInfoSection from "../../../components/organism/TestOrderForms/Sections/BillingInfoSection";
import ClinicalInfoSection from "../../../components/organism/TestOrderForms/Sections/ClinicalInfoSection";
import LaboratoryInfoSection from "../../../components/organism/TestOrderForms/Sections/LaboratoryInfoSection";
import SupportingDocsSection from "../../../components/organism/TestOrderForms/Sections/SupportingDocsSection";
import SelectTestForm from "../../../components/organism/TestOrderForms/SelectTestFrom";
import TestOrdersLayout from "../../../Containers/TestOrdersLayout";
import OrderFormValues from "../../../models/forms/onlineorderformvalues";
import ButtonStateActions from "../../../Store/Actions/ButtonStateActions";
import OrderFormValidation from "../../../validations/orderformvalidation";
import { resetSaveOrder } from "../../../Store/Actions/OnlineOrderFormActions";
import { formRef } from "../ManageScreen";
import http from "../../../Store/API/HttpMethods";
import TestSelectorLoadingIndicator from "../../../components/global/LoadingIndicators/CreateOnlineOrderIndicators/TestSelectorLoadingIndicator";
import HeaderOnlineFormLoadingIndicator from "../../../components/global/LoadingIndicators/CreateOnlineOrderIndicators/HeaderOnlineFormLoadingIndicator";
import CustomPopup from "../../../components/global/CustomPopup";
import {
  createOnlineOrder,
  handleDiscard,
  resetMode,
  resetSelectedTest,
  toggleMode,
  updatePhysicianSignCheck,
  updateSelectedOrganization,
  updateSelectedPathLab,
  updateSelectedPhysician,
} from "../../../Store/Actions/OnlineOrderFormActions";
import OnlineOrderPDF from "../../../components/organism/OnlineOrderForm/OnlineOrderPDF";
import { OnlineOrderOptions } from "../../../models/forms/online_order_options.interface";
import toastMessages from "../../../utils/helpers/toastMessage";
import { useOktaAuth } from "@okta/okta-react";
import MedicareSection from "../../../components/organism/TestOrderForms/Sections/MedicareSection";
import useOrderFlowPopups from "../../../utils/customHooks/orderFlowPopups";
import FormHeader from "../../../components/organism/OnlineOrderForm/FormHeader";
import NotificationActions from "../../../Store/Actions/NotificationsActions";
import DashboardCallModel from "../../../models/DashboardCallModel";
import DashboardActions from "../../../Store/Actions/DashboardActions";

function RenderFields({
  initialValues,
  setFieldValue,
  errors,
  touched,
  setImage,
  isValid,
  values,
  selectedTab,
  patientType,
  setFieldError,
  setSelectedTab,
  setValidSections,
  validateField,
  allowedTestsCOA,
  gettingTests,
  ...rest
}: any): JSX.Element {
  const { mode } = useSelector((state: any) => state.OnlineOrderForm);
  const { selectedTest } = useSelector((state: any) => state.OnlineOrderForm)
  /*  if (mode === 'edit') {
     return <ReviewSection values={values} setFieldValue={setFieldValue} setSelectedTab={setSelectedTab} isValid={isValid} />
   } else { */

  return (
    <Fragment>
      <div className={`${mode === "edit" ? "" : "hidden"}`}>
        <ReviewSection
          values={values}
          setFieldValue={setFieldValue}
          setSelectedTab={setSelectedTab}
          isValid={isValid}
        />
      </div>
      <div className={`${mode === "edit" ? "hidden" : "visible"}`}>
        <SelectTestForm
          initialValues={initialValues}
          selectedTab={selectedTab}
          values={values}
          setValidSections={setValidSections}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          setSelectedTab={setSelectedTab}
          errors={errors}
          touched={touched}
          allowedTestsCOA={allowedTestsCOA}
          gettingTests={gettingTests}
          {...rest}
        />
        <PatientInfoForm
          initialValues={initialValues}
          selectedTab={selectedTab}
          setFieldError={setFieldError}
          values={values}
          patientType={patientType}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          setSelectedTab={setSelectedTab}
          setValidSections={setValidSections}
          {...rest}
        />
        <BillingInfoSection
          initialValues={initialValues}
          values={values}
          setFieldError={setFieldError}
          patientType={patientType}
          setFieldValue={setFieldValue}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          errors={errors}
          touched={touched}
          setValidSections={setValidSections}
          {...rest}
        />
        {/* {(values.insurance.includes("Medicare") ||
          values.insurance.includes("Medicaid")) && (
            <MedicareSection
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              setValidSections={setValidSections}
              setSelectedTab={setSelectedTab}
              {...rest}
            />
          )} */}
        <ClinicalInfoSection
          initialValues={initialValues}
          setFieldError={setFieldError}
          values={values}
          patientType={patientType}
          isValid={isValid}
          selectedTab={selectedTab}
          setFieldValue={setFieldValue}
          errors={errors}
          setValidSections={setValidSections}
          setSelectedTab={setSelectedTab}
          touched={touched}
          {...rest}
        />
        <AdditionalInfoSection
          initialValues={initialValues}
          values={values}
          setFieldError={setFieldError}
          patientType={patientType}
          selectedTab={selectedTab}
          isValid={isValid}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          setSelectedTab={setSelectedTab}
          setValidSections={setValidSections}
          {...rest}
        />
        {/* {
          selectedTest.id !== 8 && */}
        <LaboratoryInfoSection
          initialValues={initialValues}
          values={values}
          patientType={patientType}
          setFieldValue={setFieldValue}
          selectedTab={selectedTab}
          setFieldError={setFieldError}
          setSelectedTab={setSelectedTab}
          errors={errors}
          touched={touched}
          setValidSections={setValidSections}
          {...rest}
        />
        {/* } */}
        <SupportingDocsSection
          initialValues={initialValues}
          values={values}
          patientType={patientType}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          errors={errors}
          touched={touched}
          setValidSections={setValidSections}
          {...rest}
        />
      </div>
    </Fragment>
  );
}
// }

function CreateOrderScreen({
  createError,
  createOrder,
  creatingOrder,
  orderData,
  fetchDashboard
}: any): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state }: any = useLocation();
  const { authState } = useOktaAuth();
  const { data: orgsList } = useSelector((state: any) => state.Organizations)
  const { data: pathLabList } = useSelector((state: any) => state.PathLab)
  const profile = useSelector((state: any) => state.Profile)
  const [showAnotherPopup, setAnotherPopup] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [previousOrgId, setPreviousOrgId] = useState<any>(null)
  const [previousPhysId, setPreviousPhysId] = useState<any>(null)
  const [allowedTestsCOA, setAllowedTestsCOA] = useState([]);
  const [gettingTests, setGettingTests] = useState<boolean>(false);
  const [allowedTestsCalled, setAllowedTestsCalled] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<string>("select test");
  const [patientType, setPatientType] = useState<string>("new");
  const [validSections, setValidSections] = useState<any[]>([]);
  const [touchedSections, setTouchedSections] = useState<string[]>([]);
  const orderFlowPopup: any = useOrderFlowPopups(false)
  const isCOA = profile?.profile?.roles?.includes('Collaborative Order Agreement')
  const { selectedPhysician, selectedOrganization, selectedPathLab } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const { sessionDestroyed } = useSelector((state: any) => state.Session)
  const [previousPathLabId, setPreviousPathLabId] = useState(selectedPathLab?.id);
  const listener = (e: any): any => {
    e.preventDefault();
    e.returnValue = "Are you sure you want to exit? All data will be lost.";
  };

  useEffect(() => {
    if ((profile?.roles?.includes('PathLab User') || profile?.profile?.roles?.includes('PathLab User')) && (profile?.roles?.length == 1 || profile?.profile?.roles?.length == 1)) {
      history.replace('/unauthorized')
    } else if (isCOA && allowedTestsCalled === false && (selectedPhysician?.id !== profile?.profile?.id)) {
      getAllowedTestsCOA()
    }
  }, [])
  // Window refresh/reload listener added to prevent user from refreshing to prevent data loss.
  useEffect(() => {
    dispatch(resetSelectedTest())
    dispatch(resetMode());
    // window.addEventListener("beforeunload", listener);
    dispatch(resetSaveOrder());

    dispatch(ButtonStateActions.setButtonState("online order"));

    if (!!state?.orderValue) {
      setPatientType('existing')
    }

    return () => {
      dispatch(updatePhysicianSignCheck(false))
      // window.removeEventListener("beforeunload", listener)
    };
  }, []);


  function handleTab(tab: string): void {
    setSelectedTab(tab);
  }

  function RefetchDashboard() {
    let isPLU = profile?.profile?.roles?.includes('PathLab User')

    const options: DashboardCallModel = {
      onError: (message: string) => toastMessages.error(message ? message : 'Something Went wrong'),
      config: { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken} ` } },
      pathLabId: selectedPathLab?.id ? selectedPathLab.id : null,
      organizationId: selectedOrganization?.id ? selectedOrganization.id : null,
      physicianId: selectedPhysician?.id ? selectedPhysician.id : null,
      isPLU
    }

    fetchDashboard(options)
  }

  function handleSubmit(
    data: OrderFormValues,
    { resetForm, setFieldError }: any
  ): void {
    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    };

    const options: OnlineOrderOptions = {
      data,
      config,
      selectedOrganization: selectedOrganization.id,
      selectedPhysician: selectedPhysician.id,
      onError: (message: string) => toastMessages.error(message),
      onSuccess: (status: string) => {
        history.replace(`/test-orders/order-created?status=${status}`);
        dispatch(resetMode());
        RefetchDashboard()
      },
    };
    createOrder(options);
    // window.removeEventListener("beforeunload", listener);
    // history.push('/test-orders/order-created', { replace: true })
  }
  let initialValues: OrderFormValues = {
    selectedTest: null,
    patientId: '',
    patient_firstname: '',
    facilityType: '',
    patient_lastname: '',
    patient_middleinitial: '',
    patient_dob: '',
    patient_gender: '',
    patient_ssn: '',
    patient_mrn: '',
    patient_email: '',
    patient_address: '',
    patientNotFound: false,
    patient_city: '',
    patient_state: '',
    patient_phone: '',
    patient_zip_code: '',
    icd_10: '',
    insurance: '',
    icn: '',
    billing_phone: '',
    policy: '',
    medicare: '',
    attestation: false,
    date_of_discharge: '',
    has_additional_clinician: null,
    medicarePullDate: null,
    additionalClinician: [],
    noSOF: false,
    isExpiredSOF: false,
    sofMismatch: false,
    isTeleHealth: false,
    tubeBarcode: '',
    // isCastleStandardTesting: true,
    // isDDMNotReported: false,
    // isMyPathNotReported: false,
    isAltheaDxNeuropsychotropicMedications: {
      options: []
    },
    isIDgNeuropsychotropicMedicationsCurrent: [],
    // isIDgNeuropsychotropicMedications: [],
    isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement: [],
    typeofspecimen: 'Slides from Formalin Fixed Paraffin Embedded Tumor Tissue',
    name_of_facility: '',
    date_of_collection: '',
    facility_contact_person: '',
    lab_phone: '',
    lab_fax: '',
    specimenId: '',
    organization_name: 'Organization Name',
    organization_emai: 'Organization Email ID ',
    organization_phone: 'Phone',
    organization_fax: 'Fax',
    organization_description: 'Description',
    organization_address: 'Address',
    organization_city: 'City',
    organization_state: 'State/Province',
    clinician_ssn: 'SSN ',
    clinician_mrn: 'MRN',
    clinician_full_name: 'Full Name ',
    clinician_email: 'Email ID',
    clinician_phone: 'Phone',
    clinician_suffix: 'M.D',
    clinician_speciality: 'Speciality',
    clinician_npi: 'NPI',
    clinician_account_number: 'Account Number',
    clinician_address: 'Address',
    clinician_city: '',
    clinician_zip_code: '',
    clinician_state: '',
    skippedDocs: null,
    pathology_report: '',
    insurance_cards: '',
    patient_info_demographics: '',
    current_prescription_medications: '',
    current_prescription_medicationsFile: null,
    // signed_letter: '',
    pathology_reportFile: null,
    insurance_cardsFile: null,
    patient_info_demographicsFile: null,
    // signed_letterFile: null,
    // generate_letter: false,
    isPhysicianSignPermission: false,
    additionalCodes: [],
    pathologist: '',
    // workingDiagnosis: '',
    tumorSite: '',
    status: 0,
    icd10CodeMismatch: false,
    medicationMismatch: false
  };

  if (!!state?.orderValue) {
    initialValues = { ...initialValues, ...state?.orderValue }
  }

  function Render({
    errors,
    touched,
    setFieldValue,
    isValid,
    values,
    initialValues,
    setFieldError,
    validateField,
    ...rest
  }: any): JSX.Element {
    return (
      <Fragment>
        <OnlineOrderPDF values={values} />
        <Form className="col-span-4 flex flex-col  w-full overflow-auto">
          <div id="createOrderForm" className="flex flex-col overflow-auto">
            <FormHeader
              selectedTab={selectedTab}
              validSections={validSections}
              patientType={patientType}
              setPaitentType={setPatientType}
            />
            <div className="px-10 lg:px-[109px]">
              <RenderFields
                initalValues={initialValues}
                values={values}
                isPage={false}
                patientType={patientType}
                errors={errors}
                touched={touched}
                isValid={isValid}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                selectedTab={selectedTab}
                setSelectedTab={handleTab}
                setValidSections={setValidSections}
                touchedSections={touchedSections}
                setTouchedSections={setTouchedSections}
                setPatientType={setPatientType}
                validateField={validateField}
                allowedTestsCOA={allowedTestsCOA}
                gettingTests={gettingTests}
                {...rest}
              />
            </div>
          </div>
        </Form>
      </Fragment>
    );
  }

  function hidePhysicianPopup(): void {
    let previousPhysician: any = null

    orgsList.forEach((organization: any) => {
      let found = organization?.users?.find((user: any) => user?.id === previousPhysId)

      if (found) {
        previousPhysician = found
        return
      }
    })

    dispatch(updateSelectedOrganization(orgsList?.find((organization: any) => organization?.id === previousOrgId)))

    if (previousPhysician)
      dispatch(updateSelectedPhysician(previousPhysician))
    setAnotherPopup(false)

    localStorage.setItem('discardLetter', 'false')
  }

  function hidePathLabPopup(): void {
    let previousPathLab: any = null;

    pathLabList.forEach((pathlab: any) => {
      if (pathlab.id === previousPathLabId) {
        previousPathLab = pathlab;
        return;
      }
    });

    if (previousPathLab) {
      dispatch(updateSelectedPathLab(previousPathLab));
    }

    setAnotherPopup(false);
  }

  async function getAllowedTestsCOA() {

    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`
      }
    }

    setGettingTests(true)
    setAllowedTestsCalled(true)

    try {
      const { statusCode, data } = await http.getAllowedTests(`/v1/order-agreement/get-allowed-tests?userId=${selectedPhysician?.id}&pathLabId=${selectedPathLab?.id}&organizationId=${selectedOrganization?.id}`, config)

      if (statusCode === 200 && data) {
        setGettingTests(false)
        setAllowedTestsCOA(data)
        if (data.length == 0 && selectedPathLab !== null) {
          dispatch(NotificationActions.showNotification({
            type: 'success',
            title: '',
            hideClose: true,
            message: 'Not authorized to place any order.',
            customFunction() {
            },
            buttons: [
              {
                onClick: () => {
                  location.href = 'mailto:clinicalservices@castlebiosciences.com';
                  history.replace('/dashboard')

                  dispatch(NotificationActions.hideNotifications())
                },
                title: 'Contact CS Team',
                type: "secondary",
              },
              {
                onClick: () => {
                  history.replace('/dashboard')
                  dispatch(NotificationActions.hideNotifications())
                },
                title: 'OK',
                type: "primary",
              }
            ]
          }))
        }
      }
    } catch (error: any) {
      setGettingTests(false)
      setAllowedTestsCalled(false)
    }
  }
  function handlePhysicianChange() {
    setAnotherPopup(false);
    setPreviousOrgId(selectedOrganization?.id);
    setPreviousPhysId(selectedPhysician?.id);
    if (selectedPhysician?.id !== profile?.profile?.id && isCOA) {
      getAllowedTestsCOA()
    }
    localStorage.setItem('discardLetter', 'true')
    setTimeout(() => {

      localStorage.setItem('discardLetter', 'false')
    }, 500)
  }

  function handlePathLabChange() {
    setAnotherPopup(false);
    setPreviousPathLabId(selectedPathLab?.id)
    if (selectedPhysician?.id !== profile?.profile?.id && isCOA) {
      getAllowedTestsCOA()
    }
  }

  function handleReset(setFieldValue: any): void {
    setValidSections([]);
    dispatch(handleDiscard());
    dispatch(resetMode());
    dispatch(resetSelectedTest());
  }

  useEffect(() => {
    if (!!previousPhysId && ((selectedPhysician?.id !== previousPhysId)) && !sessionDestroyed) {
      setAnotherPopup(true)
    }
    if (selectedPhysician?.id == undefined && !sessionDestroyed) {
      setAnotherPopup(false)
    }
    if (!!previousPathLabId && ((selectedPathLab?.id !== previousPathLabId)) && (selectedPhysician?.id !== profile?.profile?.id) && !sessionDestroyed) {
      setAnotherPopup(true)
    }

  }, [selectedPhysician?.id, sessionDestroyed, selectedPathLab?.id])
  function RenderMessage1(): JSX.Element {
    if ((selectedPathLab?.id !== previousPathLabId && selectedPhysician?.id !== profile?.profile?.id)) {
      return (
        <p className="mt-5 text-royalBlue"> You are about to change the path lab of the online order.<br />Are you sure you want to proceed ?
        </p>
      );
    }

    if ((selectedPhysician?.id !== previousPhysId) || (selectedPhysician !== null))
      return (
        <>
          {formRef?.current?.values?.generate_letter === true ? <p className="mt-5 text-royalBlue"> You are about to change the clinician of the online order, your generated letter will reset as well.<br />Are you sure you want to proceed ?
          </p> : <p className="mt-5 text-royalBlue"> You are about to change the clinician of the online order.<br />Are you sure you want to proceed ?
          </p>}
        </>

      );

    return (<p></p>);
  }

  useEffect(() => {
    if (!!selectedOrganization === false || selectedPhysician === null || selectedOrganization?.users
      ?.filter((e: any) => {
        return e?.roles?.includes("Physician");
      })
      .map((e: any) => {
        return { id: e.id, label: e?.firstName };
      }).length < 1
    ) {
      // setShowPopup(true);
      dispatch(resetSelectedTest());
      setValidSections([]);
    }
    if (previousOrgId === null) {
      setPreviousOrgId(selectedOrganization?.id)
    }
    if (previousPhysId === null)
      setPreviousPhysId(selectedPhysician?.id)
    if (previousPathLabId === null)
      setPreviousPathLabId(selectedPathLab?.id)
  }, [selectedPhysician, selectedOrganization, selectedPathLab]);

  return (
    <Fragment>
      <CustomPopup
        visible={showAnotherPopup}
        buttons={[
          { onClick: selectedPathLab?.id !== previousPathLabId ? hidePathLabPopup : hidePhysicianPopup, title: "Cancel", type: "secondary" },
          {
            onClick: selectedPathLab?.id !== previousPathLabId ? handlePathLabChange : handlePhysicianChange,
            title: 'OK',
            type: "primary",
          },
        ]}
        hidePopup={selectedPathLab?.id !== previousPathLabId ? hidePathLabPopup : hidePhysicianPopup}
      >
        <RenderMessage1 />
      </CustomPopup>

      <TestOrdersLayout
        selectedTab={selectedTab}
        setSelectedTab={handleTab}
        validSections={validSections}
        setValidSections={setValidSections}
        touchedSections={touchedSections}
      >
        {selectedOrganization === null || selectedPhysician === null || selectedOrganization == undefined ? (
          <div className="col-span-4 flex flex-col w-full overflow-auto">
            <div className="my-5 flex flex-col overflow-auto">
              <div className="px-10 lg:px-[109px] border-none mb-2 border-8">
                <HeaderOnlineFormLoadingIndicator />
              </div>
              <div className="px-10 lg:px-[109px]">
                <TestSelectorLoadingIndicator />
              </div>
            </div>
          </div>
        ) : (
          <Formik
            innerRef={formRef}
            validationSchema={OrderFormValidation}
            initialValues={initialValues}
            onReset={handleReset}
            onSubmit={handleSubmit}
            initialStatus={false}
            isInitialValid={false}
            validateOnMount={!!state?.orderValue}
            render={Render}
          />
        )}
      </TestOrdersLayout>
    </Fragment>
  );
}

const mapStateToProps = (state: any): any => ({
  creatingOrder: state.OnlineOrderForm,
  createError: state.OnlineOrderForm,
  orderData: state.OnlineOrderForm.orderData,
});

const mapDispatchToProps = {
  createOrder: createOnlineOrder,
  fetchDashboard: DashboardActions.fetchDashboard
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrderScreen);