import { Fragment, useCallback, useEffect, useState } from "react";
import DataTable from "../../DataTable/DataTable";
import { header } from "../../DataTableHeader/DataTableHeader";
import TestOrdersTable, { Pagination } from "../../TestOrders/TestOrdersTable";
import { useSelector } from "react-redux";
import http from "../../../Store/API/HttpMethods";
import { useOktaAuth } from "@okta/okta-react";
import toastMessages from "../../../utils/helpers/toastMessage";
import TableLoadingIndicator from "../../global/LoadingIndicators/TableLoadingIndicator";
import { debounce } from "lodash";
import CustomPopup from "../../global/CustomPopup";

function AssociatedOrdersPopup({ visible, hidePopup, title, fedexLabelId }: { visible: boolean, hidePopup: () => any, title: string, fedexLabelId: number | null | undefined }) {
  return <CustomPopup
    visible={visible}
    hideButtons
    hidePopup={hidePopup}
    title={title}
    containerClass={"py-2"}
    contentContainerClass={"w-[95vw] overflow-auto"}
  >
    <TestOrdersTable
      screen="test-orders"
      className="w-full"
      fedexLabelId={fedexLabelId}
      hideButtons
    />
  </CustomPopup>
}

function ActiveLabelsTable({ className, showActionsColumn, checkedIds, hideViewOrder, refetchOrders }: { className?: string, showActionsColumn: boolean, checkedIds: number[], hideViewOrder: boolean, refetchOrders: Function | undefined }): JSX.Element {
  const { authState } = useOktaAuth()
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const { selectedOrganization, selectedPathLab } = useSelector((state: any) => state.OnlineOrderForm)
  const { fetching: loading, error, data: organizations, fetchingOrgUsers } = useSelector((state: any) => state.Organizations)
  const [fetching, setFetching] = useState<boolean>(true)
  const [paginationData, setPaginationData] = useState<any>(null)
  const [tableFilters, setTableFilters] = useState<any>({})
  const [searching, setSearching] = useState<boolean>(false)
  const [groupedData, setGroupedData] = useState<any>([])
  const [filters, setFilters] = useState<any>({})
  const [valueinFilter, setInfilter] = useState<boolean>(false)
  const itemsPerPage = [15, 20]
  const [size, setSize] = useState(15)
  const initialColumns: header[] = [
    {
      name: 'Tracking ID',
      preventAutoSearch: false,
      disableSuggestiveFiltering: true,
      filter: true,
      action: {
        type: 'sort',
        onClick(field: string, filter: string) {

        },
        dropdownList: [
          { label: 'Ascending', key: 'ascending' },
          { label: 'Descending', key: 'descending' }
        ],
        currentSelection: { label: '', key: '' }
      }
    },
    {
      name: 'Shipping Address',
      preventAutoSearch: false,
      disableSuggestiveFiltering: true,
      filter: true,
      action: {
        type: 'sort',
        onClick(field: string, filter: string) {

        },
        dropdownList: [
          { label: 'Ascending', key: 'ascending' },
          { label: 'Descending', key: 'descending' }
        ],
        currentSelection: { label: '', key: '' }
      }
    },
    {
      name: 'Recipient Address',
      preventAutoSearch: true,
      disableSuggestiveFiltering: true,
      filter: true
    },
    {
      name: 'Org',
      preventAutoSearch: false,
      disableSuggestiveFiltering: false,
      filter: false
    },
    {
      name: 'Label',
      preventAutoSearch: false,
      disableSuggestiveFiltering: true,
      filter: true,
    },
    {
      name: 'Status',
      preventAutoSearch: false,
      filter: true,
      suggestions: [
        {
          label: 'Active',
          value: 'ACTIVE',
          selected: false,
          key: 'status'
        },
        {
          label: 'Dispatched',
          value: 'DISPATCHED',
          selected: false,
          key: 'status'
        },
      ]
    },
    {
      name: 'View Orders',
      preventAutoSearch: false,
      disableSuggestiveFiltering: false,
      filter: false

    },
    {
      name: 'Actions',
      preventAutoSearch: true
    },

  ]
  const [header, setHeader] = useState<any>({
    hasVerticalMenu: false,
    onFilter(filters: any) {
      handleFilters(filters)
    },
    NavLink: '',
    nnProps: '',
    check: false,
    hideCheckBox: true,
    data: initialColumns,
    list: [{ name: 'name' },
    { email: 'email' }]
  })
  const [tableBody, setTableBody] = useState<any[]>([])
  const [fetchingPage, setFetchingPage] = useState<boolean>(false)
  const [popupVisible, setPopupVisible] = useState<boolean>(false)
  const [fedexLabelId, setfedexLabelId] = useState<number | null>(null)
  const { profile } = useSelector((state: any) => state.Profile)
  let isPLU = profile?.roles?.includes('PathLab User')
  function search(apiFilters: any) {
    getTableData({ size, page: 1, ...apiFilters, })
  }
  const debounceSearch = useCallback(debounce(search, 500), [isPLU ? selectedPathLab?.id : selectedOrganization?.id, isPLU ? selectedPathLab : selectedOrganization])

  function handleFilters(filters: any) {
    let apiFilters: any = {
      organizationId: isPLU ? selectedPathLab?.id ? selectedPathLab?.id : '' : selectedOrganization?.id ? selectedOrganization?.id : '',
    }
    let keys: any = {}

    keys = {
      trackingId: 'trackingId',
      shippingAddress: 'shippingAddress',
      recipientAddress: 'recipientAddress',
      organizationId: 'organizationId',
      labelTitle: 'labelTitle',
      status: 'status'
    }

    Object.keys(filters).forEach((col: any) => {
      const input = filters[col]

      if (input) {
        apiFilters[Object.keys(keys)[Number(col)]] = input
      }
      debounceSearch(apiFilters)
    })

    let tableFilters: any = {}
    Object.keys(keys).forEach((key: string, index: number) => {
      tableFilters[index] = apiFilters[key]
      debounceSearch(apiFilters)
    })
    setTableFilters(tableFilters)
    setFilters(apiFilters)
    setInfilter(true)
  }

  function handleBack() {
    if (fetching || searching || fetchingPage) return
    setFetchingPage(true)
    getTableData({ ...filters, page: paginationData.currentPage - 1, size })
  }

  function handleNext() {
    if (fetching || searching || fetchingPage) return
    setFetchingPage(true)
    getTableData({ ...filters, page: paginationData.currentPage + 1, size })
  }

  function handleItemsPerPage(size: number) {
    if (fetching || searching || fetchingPage) return
    setFetchingPage(true)
    setSize(size)
    getTableData({ ...filters, page: 1, size })
  }

  const buildParams = (filters: any) => {
    const params = new URLSearchParams()
    Object.keys(filters).forEach((key: string) => {
      if (Array.isArray(filters[key])) {
        filters[key].map((item: any) => params.append(`${key}[]`, item))
      } else
        if (filters[key])
          params.append(key, filters[key])
    })

    return params.toString()
  }

  function onSort(sortField: string, sortDirection: string, data: any, filters: any) {
    let fields: any = {
      'Tracking ID': 'trackingId',
      'Recipient Address': 'recipientAddress',
      'Org': 'organizationId',
      'Shipping Address': 'shippingAddress',
      'Label': 'labelTitle',
      'Status': 'status'
    }

    getTableData({
      ...filters,
      sortBy: fields[sortField],
      sortDirection: sortDirection === 'ascending' ? 'ASC' : 'DESC'
    })
  };
  function itemActions(record: any) {
    let actions: any[] = []

    if (record) {
      actions = ['select']
    }

    return actions
  }

  async function handleAssociateLabel(orderIds: number[], fedexLabelId: any, onSuccess: Function, onError: Function) {
    const config = { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } }
    try {
      const response = await http.associateLabel('/v1/orders/associate-label', { orderIds, fedexLabelId }, config)
      onSuccess?.()
    } catch (error: any) {
      toastMessages.error(error?.message ? error.message : error)
      onError?.()
    }
  }


  function handleActionClick(item: any) {
    const { item: data, type: action, index } = item;

    if (action === 'select') {
      const setLoading = (loading: boolean) => setTableBody((prevState: any) => prevState.map((item: any) => {
        if (item?.id == data.id) {
          return { ...item, downloading: loading }
        }
        return { ...item }
      }))
      setLoading(true)
      handleAssociateLabel(checkedIds, data.id, () => {
        setLoading(false)
        toastMessages.success('Order associated successfully')
        refetchOrders?.()
      }, () => setLoading(false))
      return
    }
    if (action === 'viewOrder')
      // return window.open(item?.item?.data?.viewOrder, "_blank")
      setfedexLabelId(item?.item?.id)
    return setPopupVisible(true)
  }

  async function getTableData(filters: any) {
    let tableData: any = []
    let updatedColumns: any = []
    setSearching(true)

    function updateTableHeader() {
      updatedColumns = initialColumns.filter((column: header) => {

        if (hideViewOrder) {
          return column.name !== 'View Orders'
        }

        if (!showActionsColumn) {
          if (column.name !== 'Actions')
            return true
          return false
        }
        return true
      }).map((column: header) => {
        if (column?.name === 'Org') {
          return {
            ...column,
            filter: false,
            // suggestions: organizations?.map((org: any) => ({ label: org?.name, value: org?.id })),
          }
        }
        if (column.name !== 'View Orders' && column.name !== 'Label')
          return {
            ...column,
            action: {
              ...column.action,
              dropdownList: [
                { label: 'Ascending', key: 'ascending' },
                { label: 'Descending', key: 'descending' }
              ],
              onClick(field: string, filter: string) {
                onSort(field, filter, tableData, filters)
              },
              currentSelection: { label: '', key: '' },
            }
          }
        return {
          ...column
        }
      })

      setHeader({
        hasVerticalMenu: false,
        onFilter(filters: any) {
          handleFilters(filters)
        },
        onSelectAll: () => {
          let checkValue = false
          setHeader((prevState: any) => {
            checkValue = !prevState.check
            return {
              ...prevState, check: checkValue
            }
          })
          setTableBody((prevState: any) => {
            let temp = [...prevState]
            let newArray = temp.map((item: any, childIndex: number) => {
              return {
                ...item,
                check: checkValue,
                actionClick: (item: any) => handleActionClick(item),
                data: {
                  ...item.data
                }
              }
            })

            return newArray
          })

        },
        NavLink: '',
        nnProps: '',
        check: false,
        hideCheckBox: true,
        data: updatedColumns,
        list: [{ name: 'name' },
        { email: 'email' }]
      })
    }
    try {
      const response = await http.getActiveLabelsData(`/v1/fedex/active-labels?${buildParams(filters)}`, { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } })

      const { data, message, statusCode } = response


      if (data?.fedexLabels?.length > 0) {
        tableData = data?.fedexLabels?.map((record: any) => {

          let bodyData: any = {
            trackingId: record?.trackingId ? record.trackingId : 'N/A',
            shippingAddress: record?.shippingAddress ? record.shippingAddress : 'N/A',
            recipientAddress: record?.recipientAddress ? record.recipientAddress : 'N/A',
            organizationId: record?.organization?.name ? record?.organization?.name : 'N/A',
            labelTitle: record?.labelTitle ? {
              labelTitle: record.labelTitle,
              viewableUrl: record.viewableUrl
            } : 'N/A',
            status: !!record?.status ? record?.status : 'N/A',
            viewOrder: record?.labelUrl ? record?.labelUrl : 'N/A',
          }
          if (hideViewOrder) {
            delete bodyData.viewOrder
          }

          if (showActionsColumn) {
            bodyData.actions = itemActions(record)
          }

          let obj = {
            id: record.id,
            onRowClick: (id: number) => {

            },
            actionClick: handleActionClick,
            selectOnIndex: (check: boolean, index: number, data: any) => {
            },

            data: bodyData,
            tooltips: [{
              key: 'organizationCode',
              value: `${record?.organization?.address || 'N/A'} 
              ${record?.organization?.state ? `, ${record.organization.state}` : ''} 
              ${record?.organization?.city ? `, ${record.organization.city}` : ''}`.trim() || 'N/A'
            },
            {
              key: 'pathlabId',
              value: `${record?.organization?.address || 'N/A'} 
              ${record?.organization?.state ? `, ${record.organization.state}` : ''} 
              ${record?.organization?.city ? `, ${record.organization.city}` : ''}`.trim() || 'N/A'
            }],
            showCheckbox: false,
            check: false
          }

          return obj
        })

        setTableBody(tableData)
        updateTableHeader()
        setFilters(filters)
        setPaginationData({
          totalItems: data.totalItems,
          totalPages: data.totalPages,
          currentPage: data.currentPage
        })
      } else {
        setTableBody([])
      }

      setFilters(filters)
      setFetching(false)
      setRefreshing(false)
      setSearching(false)
      setFetchingPage(false)

    } catch (error: any) {

      updateTableHeader()
      toastMessages.error(error?.message ? error.message : error.error ? error.error : 'Something went wrong')
      setFetching(false)
      setRefreshing(false)
      setSearching(false)
      setFetchingPage(false)
    }
  }

  useEffect(() => {
    if (isPLU && selectedPathLab?.id !== undefined) {
      getTableData({
        organizationId: selectedPathLab?.id, size, page: 1
      })
      return
    }

    if (selectedOrganization?.id !== undefined) {
      getTableData({
        organizationId: selectedOrganization?.id, size, page: 1
      })
    }
  }, [selectedOrganization?.id, selectedPathLab?.id])

  if (fetching)
    return <div className=''>
      <TableLoadingIndicator />
    </div>

  return <Fragment>
    <DataTable
      NavLink={''}
      groupedData={groupedData}
      externalCheckbox={false}
      initialFilters={tableFilters}
      className={`!max-h-[65vh] border-t ${tableBody.length > 10 ? 'border-b' : ''} !min-h-[50vh] ${className}`}
      customEmptyText={valueinFilter === false ? 'Currently, there are no active labels available.' : 'There are no active labels that match your search criteria.'}
      body={tableBody}
      header={header}
      showLoader={searching}
    />
    {paginationData && <Pagination
      paginationData={paginationData}
      submitting={fetchingPage}
      handleBack={handleBack}
      handleNext={handleNext}
      handleItemsPerPage={handleItemsPerPage}
      size={size} />}
    <AssociatedOrdersPopup
      visible={popupVisible}
      hidePopup={() => {
        setPopupVisible(false)
        setfedexLabelId(null)
      }}
      fedexLabelId={fedexLabelId}
      title="Associated Orders"
    />
  </Fragment>
}

export default ActiveLabelsTable