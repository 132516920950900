/* eslint-disable @typescript-eslint/space-before-function-paren */
import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import Button from '../atomic/Button'
import CustomInput from '../global/CustomInput'
import { useHistory } from 'react-router-dom'
import ForgetPasswordFormValues from '../../models/forms/forgetpasswordformvalues.interface'
import ForgetPasswordValidation from '../../validations/forgetpasswordvalidation'
import { OktaAuth } from '@okta/okta-auth-js'
import OktaConfig from '../../config/oktaConfig'
import toastMessages from '../../utils/helpers/toastMessage'

const oktaAuth = new OktaAuth(OktaConfig.oktaAuthConfig)

function ForgetPasswordForm(): JSX.Element {
  const history = useHistory()
  const [submitting, setSubmitting] = useState<boolean>(false)

  const officeManagerFields = [
    {
      id: 'email',
      name: 'email',
      label: 'Email ID',
      placeholder: 'Enter email ID',
      required: true
    }
  ]
  const initialValues: ForgetPasswordFormValues = {
    email: ''
  }

  function onSubmit(data: ForgetPasswordFormValues): void {
    setSubmitting(true)
    oktaAuth.forgotPassword({
      username: data.email,
      factorType: 'EMAIL'
    }).then(function (transaction) {
      if (transaction.status === 'RECOVERY_CHALLENGE') {
        toastMessages.success('A link was sent to your email. Please click on that link to reset your password.', 3000)
        history.push('/')
      } else {
        toastMessages.error('Something went wrong, please contact your Administrator.', 3000)
      }
      setSubmitting(false)

    }).catch(function (err) {
      toastMessages.error(err?.message ? err.message : 'Error changing password', 3000)
      setSubmitting(false)
    })
  }

  return <Formik
    initialValues={initialValues}
    validationSchema={ForgetPasswordValidation}
    onSubmit={onSubmit}
    render={({ errors, touched, isSubmitting }) => (<Form
      className="flex flex-col justify-around my-2 p-0 m-0"
    >
      <div
      >
        {officeManagerFields.map((field, index) => (<CustomInput
          id={field.id}
          key={field.id}
          name={field.name}
          type={'email'}
          label={field.label}
          required={field.required}
          placeholder={field.placeholder}
          errors={errors}
          touched={touched}
          className={index === 0 ? 'mt-0' : ''}
        />)
        )}
        <Button
          type={'submit'}
          submitting={submitting}
          disabled={submitting}
          btnType='primary'
          title={'verify'}
          className={'w-full my-3 justify-center'}
          onClick={() => { }}
        />
      </div>

    </Form>
    )} />
}

export default ForgetPasswordForm
