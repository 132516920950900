import { AnimatePresence, motion } from "framer-motion";
import ManageScreenLayout from "../../../../Containers/ManageScreenLayout";
import { DataTableState } from "../../../../components/DataTable/DataTable";
import { Fragment, useEffect, useState } from "react";
import ButtonStateActions from "../../../../Store/Actions/ButtonStateActions";
import { useHistory, useParams } from "react-router-dom";
import PortalUsersActions from "../../../../Store/Actions/PortalUsersActions";
import ProfileActions from "../../../../Store/Actions/ProfileActions";
import { useDispatch, useSelector, connect } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import CustomImage from "../../../../components/global/CustomImage";
import UserDetailLoadingIndicator from "../../../../components/global/LoadingIndicators/UserDetailLoadingIndicator";
import AssociatedOrganisationsAndRolesTable from "../../../../components/global/Tables/AssociatedOrganisationsAndRolesTable";
import toastMessages from "../../../../utils/helpers/toastMessage";
import DropdownSOF from "../../../../components/DataTable/DropdownSOF";
import Button from "../../../../components/atomic/Button";
import SOFFlow from "../../../../components/organism/SofFlow";
import RequiredFieldTooltip from "../../../../components/global/RequiredFieldTooltip";
import AssociatedPathLabAndRolesTable from "../../../../components/global/Tables/AssociatedPathLabAndRolesTable";
import { RESET_SELECTED_PORTAL_USER } from "../../../../Store/Actions/types";
import UserSOFTable from "../../../../components/global/Tables/UserSOFTable";
import physicianToClinician from "../../../../utils/helpers/physicianToClinician";

function SOFDropDown({ user, SOFClick }: any) {
  return <div className={`${user?.sof?.id ? 'ml-4' : 'ml-8'}  mb-3  flex items-center`}>
    {
      user?.sof?.id ?
        <DropdownSOF options={[
          { label: 'Update', labelValue: 'update' },
          { label: 'View', labelValue: 'view' },
          { label: 'Delete', labelValue: 'delete' },
        ]}
          getValueOfSof={(value: any) => {
            if (value === 'update' && user.sof?.isUpdated) {
              return SOFClick({ type: 'update-req-pending', item: user.sof })
            }
            SOFClick({ type: value, item: user.sof })
          }}
          text='Signature on File (SOF) Actions'
          expiryDate={user.sof.expiryDate}
        // disabled={item?.data?.SOF?.status.toLowerCase() == 'pending'}
        />
        :

        // <RequiredFieldTooltip required={true} tooltipText='An active signature on file will allow authorized office staff to order specific Castle tests on behalf of the clinician.'>
        <RequiredFieldTooltip required={true} tooltipText='New SOF (signature on file) creation has been disabled. Please use Clinician Approval workflow (option B in Ordering Instructions Guide) and reach out if you have any questions.'>
          <Button
            title='Create Signature on File (SOF)'
            btnType='transparent'
            className='-ml-1'
            onClick={(e) => {
              e.stopPropagation()
              SOFClick({ type: 'create', item: user.sof })
            }} 
            disabled/>
        </RequiredFieldTooltip>
    }
  </div>
}

function RenderSOFAction({ user, SOFClick, loggedInUser }: any): JSX.Element | null {
  let dualRoleCOAorPLU = (loggedInUser?.roles?.includes('Collaborative Order Agreement') || loggedInUser?.roles?.includes('PathLab User')) && loggedInUser?.roles?.includes('Physician')
  let isOfficeManager = loggedInUser.roles?.includes('Office Manager')

  if (dualRoleCOAorPLU && user?.id === loggedInUser?.id)
    return <SOFDropDown user={user} SOFClick={SOFClick} />

  if (isOfficeManager && user?.roles?.includes('Physician'))
    return <SOFDropDown user={user} SOFClick={SOFClick} />

  if (user?.roles?.includes('Physician') && loggedInUser.id == user.id)
    return <SOFDropDown user={user} SOFClick={SOFClick} />

  return null
}

function RenderSOFTable({ user, loggedInUser }: any): JSX.Element | null {

  if ((loggedInUser?.roles?.includes('Office Manager') && user?.user?.roles?.includes('Physician')) || (loggedInUser?.id == user?.user?.id && user?.user?.roles?.includes('Physician')))
    return <Fragment>
      <div className="text-royalBlue font-semibold mt-5 mb-5">
        User Signature on Files
      </div>
      <div className="pb-12">
        <UserSOFTable
          tableData={user.sofTable}
          className="border-t pb-16 "
        />
      </div>
    </Fragment>

  return null
}

function UserProfile({
  fetching,
  fetchPortalUserById,
  selectedUser,
  profile,
  error: fetchUserError,
  fetchOrderAgreement,
  fetchingCOAPortalUser,
  errorFetchingCOA
}: any) {
  const { screen } = useSelector((state: any) => state.ButtonState);
  const [initialData, setInitialData] = useState<any>([]);
  const user = selectedUser?.user
  const dualRole = user?.roles;
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { authState } = useOktaAuth();
  const { data: states, error } = useSelector((state: any) => state.StateAndCity)
  const history = useHistory()
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });

  const [listHeight, setListHeight] = useState(window.innerHeight - (window.innerHeight * 0.22))

  const setDimension = () => {
    if (window.innerHeight <= 370) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.32))
    } else if (window.innerHeight <= 400) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.3))
    } else if (window.innerHeight <= 450) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.26))
    } else if (window.innerHeight <= 500) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.24))
    } else if (window.innerHeight <= 530) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.23))
    } else if (window.innerHeight <= 570) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.22))
    } else if (window.innerHeight <= 600) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.2))
    } else if (window.innerHeight <= 630) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.19))
    } else if (window.innerHeight <= 655) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.18))
    } else if (window.innerHeight <= 680) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.18))
    } else if (window.innerHeight <= 700) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.17))
    } else if (window.innerHeight <= 715) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.17))
    } else if (window.innerHeight <= 750) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.16))
    } else if (window.innerHeight <= 780) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.15))
    } else if (window.innerHeight <= 800) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.16))
    } else if (window.innerHeight <= 850) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.3))
    } else if (window.innerHeight <= 880) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.14))
    } else if (window.innerHeight <= 900) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.27))
    } else if (window.innerHeight <= 980) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.13))
    } else if (window.innerHeight <= 1050) {
      setListHeight(window.innerHeight - (window.innerHeight * 0.119))
    } else {
      setListHeight(window.innerHeight - (window.innerHeight * 0.1119))
    }
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }
  const [sofState, setSofState] = useState<DataTableState>({
    sofType: 'create',
    sofVisible: false,
    item: null
  })
  function SOFClick({ type, item }: { type: 'create' | 'update' | 'view' | 'delete' | 'update-req-pending', item: any }) {
    setSofState({ sofVisible: true, sofType: type, item })
  }
  useEffect(() => {
    // calling to initially set list height on basis of screen height
    setDimension()

  }, [])

  useEffect(() => {
    window.addEventListener('resize', setDimension);
    return (() => {
      window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

  const fetchUser = () => {
    if (authState?.accessToken?.accessToken) {
      fetchPortalUserById(id,
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }, (message: string) => {
          history.replace('/manage/portal-users')
          toastMessages.error(message)
        });
    }
  };
  const fetchUserOfCOA = () => {
    if (authState?.accessToken?.accessToken) {
      fetchOrderAgreement(id,
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }, (message: string) => {
          // history.replace('/manage/portal-users')
          toastMessages.error(message)
        });
    }
  };
  useEffect(() => {

    fetchUser();

    const isUnauthorized = profile?.roles?.includes('PathLab User') && (selectedUser?.user?.id !== undefined ? selectedUser?.user?.id !== profile?.id : '') && fetching === false;

    if (isUnauthorized) {
      history.replace('/manage/portal-users');
      toastMessages.error('Unauthorized to view user data');
      return;
    }

    if (screen !== "UserProfile") {
      dispatch(ButtonStateActions.setButtonState("UserProfile"));
    }

    return () => {
      dispatch({ type: RESET_SELECTED_PORTAL_USER })
    }
  }, [id]);

  useEffect(() => {
    if (profile?.roles?.includes('Collaborative Order Agreement') && !fetching && selectedUser?.user?.roles?.includes('Physician')) {
      fetchUserOfCOA()
    }
  }, [selectedUser?.user?.roles, fetching])

  return (
    <ManageScreenLayout headerTitle={"User Details"}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="col-span-4 "
        >
          {fetching ? (
            <UserDetailLoadingIndicator showTable={true} />
          ) : (
            <div className="py-5 px-12 flex flex-col overflow-y-auto" style={{ height: listHeight }}>
              <p className="text-royalBlue font-semibold -mb-5">User Profile</p>
              <div className="my-5">
                <div className="flex pt-4">
                  {/* Left */}
                  <CustomImage
                    src={user?.imageUrl}
                    alt={user?.firstName ?? "User"}
                    className="object-fit w-[7rem] h-[7rem] rounded-full"
                  />

                  {/* Right */}
                  <div className="pl-1 -mt-3">
                    <div className="text-gray-700">
                      <div className="flex flex-wrap">
                        <div className="flex flex-col m-3">
                          <div className="px-4 font-semibold text-royalBlue text-xs">
                            First Name
                          </div>
                          <div className="px-4 white-space: nowrap text-base text-royalBlue">
                            {user?.firstName || "-"}
                          </div>
                        </div>
                        <div className="flex flex-col m-3">
                          <div className="px-4 font-semibold text-royalBlue text-xs">
                            Last Name
                          </div>
                          <div className="px-4 text-base text-royalBlue">
                            {user?.lastName || "-"}
                          </div>
                        </div>
                        <div className="flex flex-col m-3">
                          <div className=" font-semibold text-royalBlue text-xs">
                            Email ID
                          </div>
                          <div className="text-base text-royalBlue">
                            {user?.email || "-"}
                          </div>
                        </div>
                        <div className="flex flex-col m-3">
                          <div className="px-4 font-semibold text-royalBlue text-xs">
                            Role
                          </div>
                          <div
                            className="px-4 text-base text-royalBlue"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {user?.roles && physicianToClinician(user?.roles)?.map((role: string, index: number) => `${role}${index + 1 !== user?.roles?.length ? ', ' : ''}`)}
                          </div>
                        </div>

                        {user?.roles?.includes("Physician") && (
                          <>
                            <div className="flex flex-col m-3">
                              <div className="px-4 font-semibold text-royalBlue text-xs">
                                Speciality
                              </div>
                              <div className="px-4 text-base text-royalBlue">
                                {user?.specialty || "-"}
                              </div>
                            </div>
                            <div className="flex flex-col m-3">
                              <div className="px-4 font-semibold text-royalBlue text-xs">
                                NPI
                              </div>
                              <div className="px-4 text-base text-royalBlue">
                                {user?.npi || "-"}
                              </div>
                            </div>
                            <div className="flex flex-col m-3">
                              <div className="px-4 font-semibold text-royalBlue text-xs">
                                Suffix
                              </div>
                              <div className="px-4 text-base text-royalBlue">
                                {" "}
                                {user?.suffix || "-"}
                              </div>
                            </div>
                          </>
                        )}

                        {user?.roles?.includes("Physician") && (
                          <div className="flex flex-col m-3">
                            <div className="px-4 font-semibold text-royalBlue text-xs">
                              Sign Permission
                            </div>
                            <div className="px-4 text-base text-royalBlue">
                              {/* {user?.signPermission
                                  ? "Yes"
                                  : "No"} */}
                              {user?.sof?.id ? "Yes" : "No"}
                            </div>
                          </div>
                        )}

                      </div>
                      <RenderSOFAction user={user} loggedInUser={profile} SOFClick={SOFClick} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <hr className=""></hr>
              </div>

              <div>
                {profile?.roles?.includes("Collaborative Order Agreement") && selectedUser?.user?.roles?.includes('Physician') && <Fragment>
                  <div className="text-royalBlue font-semibold mt-5 mb-5">
                    Associated Order Agreement
                  </div>
                  <div className="flex flex-wrap min-w-full 2xl:mb-0 xl:mb-0 lg:mb-12">
                    {selectedUser && (
                      <AssociatedPathLabAndRolesTable
                        tableData={selectedUser.orderAgreementTable}
                        roles={user?.roles}
                        className={""}
                      />
                    )}
                  </div>
                </Fragment>}
                <Fragment>
                  <div className="text-royalBlue font-semibold mt-5 mb-5">
                    Associated Organizations & Roles
                  </div>
                  <div className="flex flex-wrap min-w-full">
                    {selectedUser && (
                      <AssociatedOrganisationsAndRolesTable
                        tableData={selectedUser.tableData}
                        roles={user?.roles}
                        className={""}
                      />
                    )}
                  </div>
                </Fragment>
                {selectedUser && <RenderSOFTable
                  user={selectedUser}
                  loggedInUser={profile}
                />
                }

              </div>
            </div>
          )}
          <SOFFlow
            isVisible={sofState.sofVisible}
            type={sofState.sofType}
            item={sofState.item}
            closeSOF={() => setSofState({ sofType: 'create', item: null, sofVisible: false })}
          />
        </motion.div>
      </AnimatePresence>
    </ManageScreenLayout>
  );
}

const mapStateToProps = (state: any): any => ({
  selectedUser: state.PortalUsers.selectedUser,
  fetching: state.PortalUsers.fetchingUser,
  error: state.PortalUsers.fetchUserError,
  profile: state.Profile.profile,
  fetchingCOAPortalUser: state.PortalUsers.fetchingUserOA,
  errorFetchingCOA: state.PortalUsers.fetchUserOAError,
});

const mapDispatchToProps = {
  fetchProfile: ProfileActions.fetchprofile,
  fetchPortalUserById: (id: string, config: any, onError: (message: string) => void) =>
    PortalUsersActions.fetchPortalUserById(id, config, onError),
  fetchOrderAgreement: (id: string, config: any, onError: (message: string) => void) =>
    PortalUsersActions.fetchOrderAgreement(id, config, onError),
};
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
