import envVars from "../env-configs";

interface oktaAuthConfigType {
    issuer: string;
    clientId: string;
    redirectUri: string;
    scopes: string[];
    pkce: boolean;
    disableHttpsCheck: boolean;
    maxClockSkew?: number;
    useInteractionCode: boolean;
    ignoreLifetime: boolean;
}

// const oktaAuthConfig: oktaAuthConfigType = {
//  issuer: "https://dev-2737508.okta.com/oauth2/default",
//  clientId: "0oa5czbuplx6kEPQg5d7",
//  redirectUri: window.location.origin + "/login/callback",
//  scopes: ["openid", "profile", "email"],
//  pkce: true,
//  disableHttpsCheck: true,
//  useInteractionCode: false,
// };

// const oktaWidgetConfig = {
//  issuer: "https://dev-2737508.okta.com/oauth2/default",
//  clientId: "0oa5czbuplx6kEPQg5d7",
//  redirectUri: `${window.location.origin}/login/callback`,
//  scopes: ["openid", "profile", "email"],
//  useInteractionCodeFlow: true,
// };

const oktaAuthConfig: oktaAuthConfigType = {
    issuer: envVars.REACT_APP_OKTA_ISSUER,
    clientId: envVars.REACT_APP_OKTA_CLIENT_ID,
    //  issuer:
    //   process.env.REACT_APP_OKTA_ISSUER ??
    //   "https://dev-2737508.okta.com/oauth2/default",
    //  clientId: process.env.REACT_APP_OKTA_CLIENT_ID ?? "0oa5czbuplx6kEPQg5d7",
    redirectUri: window.location.origin + "/login/callback",
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: true,
    maxClockSkew: 1200,
    useInteractionCode: false,
    ignoreLifetime: true
};

const oktaWidgetConfig = {
    issuer: envVars.REACT_APP_OKTA_ISSUER,
    clientId: envVars.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ["openid", "profile", "email"],
    useInteractionCodeFlow: true,
};

const OktaConfig = {
    oktaAuthConfig,
    oktaWidgetConfig,
};

export default OktaConfig;
