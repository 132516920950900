import * as React from 'react'
import { SVGProps } from 'react'

const DashboardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} {...props}>
        <g
            transform="translate(.5 .5)"
            fill="none"
            stroke={props.stroke ?? '#172b4d'}
            strokeLinecap="round"
        >
            <rect
                data-name="Rectangle 4045"
                width={6}
                height={6}
                rx={1}
                strokeLinejoin="round"
            />
            <rect
                data-name="Rectangle 4046"
                width={6}
                height={6}
                rx={1}
                transform="translate(0 10)"
                strokeLinejoin="round"
            />
            <path
                data-name="Path 5216"
                d="M11 10h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4s.448-1 1-1Z"
            />
            <rect
                data-name="Rectangle 4048"
                width={6}
                height={6}
                rx={1}
                transform="translate(10)"
                strokeLinejoin="round"
            />
        </g>
    </svg>
)

export default DashboardIcon
