import DropDownIcon from '../../assets/svg/DropdownIcon';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import SOFArrowDown from '../../assets/svg/SOFArrowDown';
import SOFArrowUp from '../../assets/svg/SOFArrowUp';
import CustomPopup from '../global/CustomPopup';
import RequiredFieldTooltip from '../global/RequiredFieldTooltip';

export interface IDropdownOption {
	label: string | number;
	labelValue: string | number;
}

interface IDropdownProps {
	options: IDropdownOption[];
	placeHolder?: string;
	tabIndex?: number;
	text?: string;
	getValueOfSof?: any;
	expiryDate?: string;
	disabled?: boolean
	OAFlow?: any
}

function DropdownSOF({
	options,
	placeHolder,
	tabIndex,
	text,
	getValueOfSof,
	expiryDate,
	disabled,
	OAFlow
}: IDropdownProps) {
	const [isFocused, setIsFocused] = React.useState(false);
	const [selectedItem, setSelectedItem] = React.useState<number | string>();
	const wrapperRef = useRef<any>(null);
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setIsFocused(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [wrapperRef]);

	const onValueChange = (selectedValue: string | number) => {
		setSelectedItem(selectedValue);
		setIsFocused(false);
		getValueOfSof(selectedValue)
	};
	React.useEffect(() => {
		setIsFocused(false);
	}, [selectedItem]);

	const onClear = (e: any) => {
		e.stopPropagation();
		setSelectedItem(placeHolder);
		setIsFocused(false);
	};
	return (
		<Fragment>
			<div ref={wrapperRef} className="border-[#1f1c1c] relative">
				<RequiredFieldTooltip required={true} tooltipId={'dropdownSOF'} tooltipText={OAFlow ? '' : 'An active signature on file will allow authorized office staff to order specific Castle tests on behalf of the clinician.'}>
					<button
						tabIndex={tabIndex}
						className={`w-full flex  h-[30px] tab:h-[100px] rounded-md text-[#18b5b7] font-semibold text-sm 
                uppercase pl-3 transition ${disabled ? 'cursor-not-allowed text-primaryDisabled' : 'hover:border hover:border-[#18b5b7]'}
                ${isFocused && 'border border-[#18b5b7]'}
                 flex items-center`}
						onClick={() => setIsFocused(!isFocused)}
						disabled={disabled}
					>
						<span className={'pr-3'}>{text} </span>

						<div className='pr-2'>
							{isFocused ? <SOFArrowUp /> : <SOFArrowDown fill={disabled ? '#8BDADB' : '#18b5b7'} />}
						</div>
					</button>

				</RequiredFieldTooltip>
				{isFocused && (
					<ul
						className={`${OAFlow ? '2xl:w-[35vh] xl:w-[38vh] lg:w-[50vh] fixed 2xl:mt-1 xl:mt-1 lg:mt-[-160px] z-[999]' : 'absolute bg-white w-full z-[999]'}`}
					// className={`${OAFlow ? '2xl:w-[35vh] xl:w-[38vh] lg:w-[50vh] fixed 2xl:mt-1 xl:mt-1 lg:mt-[-160px]' : 'w-[19.5%] tab:w-[22.5%] fixed mt-[-228px] tab:mt-[-165px]'} bg-white items-center gap-4 block shadow-sm rounded-lg z-[500]`}
					>
						{options.map(({ label, labelValue }) => (
							<li
								onClick={() => onValueChange(labelValue)}
								className="w-full
                            shadow-sm text-sm text-royalBlue font-normal bg-white hover:bg-[#EDEFF1]
                               pl-3 transition flex items-start 2xl:py-1.5 xl:py-1.5 lg:py-1 tab:py-1 text-left">
								{label}
							</li>
						))}
					</ul>
				)}
				{
					!OAFlow &&
					<p className={`pt-1.5 tab:mt-3 text-xs text-[#647188]  pl-3 pr-4`}> {disabled ? '(Pending Approval)' : `(Expiry date of SOF: ${expiryDate?.replaceAll('-','/')})`}</p>
				}
			</div>


		</Fragment>
	);
}

export default DropdownSOF;