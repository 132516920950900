import { useCallback, useEffect, useState } from "react";
import DataTable from "../../DataTable/DataTable";
import { ITableHeaderProps } from "../../DataTableHeader/DataTableHeader";
import CreateSOF from "../../CreateSOF";
import CustomPopup from "../CustomPopup";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function AssociatedOrganisationsAndRolesTable({
  tableData,
  className,
  roles,
}: {
  tableData: any;
  className?: string;
  roles: any
}) {
  const [header, setHeader] = useState<ITableHeaderProps>({
    hasVerticalMenu: false,
    onFilter(filters: any) {
      handleFilters(filters, tableData);
    },
    NavLink: "",
    nnProps: "",
    check: false,
    hideCheckBox: true,
    data: [
      {
        name: "Organization",
        preventAutoSearch: false,
        filter: true,
        // suggestions: ['name', 'ali'],
      },
      {
        name: "Roles",
        preventAutoSearch: false,
        filter: true,
      },
      {
        name: "SOF",
        preventAutoSearch: false,
        filter: false,
      },
    ],
    list: [],
  });
  const location = useLocation();
  const { profile } = useSelector((state: any) => state.Profile)
  const [tableBody, setTableBody] = useState<any>([]);

  function handleFilters(filters: any, tableData: any) {
    let filteredList: any[];
    Object.keys(filters).forEach((col: any) => {
      const input = filters[col];
      if (input) {
        filteredList = tableData.filter((item: any) => {
          for (const key in filters) {
            let itemData = item.data[Object.keys(item.data)[Number(key)]];
            let finalValue = "";

            const { value } = itemData;

            if (Array.isArray(itemData)) {
              itemData.map((stringVal: string) => {
                finalValue += `${stringVal}`;
              });
            } else {
              finalValue = itemData;
            }

            if (
              !(value || finalValue)
                ?.toLowerCase()
                ?.includes(filters[key]?.toLowerCase())
            ) {
              return false;
            }
          }
          return true;
        });
      }
      const isFiltersEmpty = !Object.values(filters).some(
        (x) => x !== null && x !== ""
      );

      if (!isFiltersEmpty) {
        setTableBody(filteredList);
      } else {
        setTableBody(tableData);
      }
    });
  }

  useEffect(() => {
    let loggedInUserRole = profile?.roles
    let showSOF = false

    // if (location.pathname === '/profile' && roles?.includes('Physician')) {
    //   showSOF = true
    // } else if (location.pathname !== '/profile' && (loggedInUserRole?.includes('Office Manager') && roles?.includes('Physician'))) {
    //   showSOF = true
    // } else {
    //   showSOF = false
    // }

    setHeader((prevState: any) => {
      let data = [...prevState.data].filter((column: any) => {
        if (!showSOF)
          return column.name !== "SOF"
        return true
      });

      // Remove SOF column if user has Collaborative Order Agreement ,PathLab User or Physician
      if (!showSOF) {
        data = data.filter((item: any) => item.name !== "SOF");
      }

      return {
        ...prevState,
        data: data.map((item: any) => ({
          ...item,
          action: {
            ...item.action,
            type: "sort",
            onClick(field: string, filter: string) {
              handleSort(field, filter, tableData);
            },
            dropdownList: [
              { label: "Ascending", key: "ascending" },
              { label: "Descending", key: "descending" },
            ],
            currentSelection: { label: "", key: "" },
          },
        })),
        onFilter(filters: any) {
          handleFilters(filters, tableData);
        },
      };
    });

    setTableBody(tableData?.map((item: any) => {
      if (!showSOF)
        delete item?.data?.SOF

      return {
        ...item,
      }
    }));
  }, [tableData?.length]);

  const handleSort = useCallback(onSort, tableData);
  function onSort(sortField: string, sortDirection: string, data: any) {
    let fields: any = {
      Organisation: "organisation",
      Roles: "roles",
      SOF: "sof",
    };
    if (data) {
      const sortedData = [...data];
      sortedData.sort((a, b) => {
        if (
          a.data[fields[sortField]].toLowerCase() <
          b.data[fields[sortField]].toLowerCase()
        ) {
          return sortDirection === "ascending" ? -1 : 1;
        }
        if (
          a.data[fields[sortField]].toLowerCase() >
          b.data[fields[sortField]].toLowerCase()
        ) {
          return sortDirection === "descending" ? -1 : 1;
        }

        return 0;
      });
      setTableBody(sortedData);
    }
  }

  return (
    <DataTable
      NavLink={""}
      showNumbering
      // body={checkProfileRole ? newData : tableData}
      body={tableBody}
      header={header}
      className={`my-5 !max-h-[40vh] w-full !overflow-auto lg:w-[100%] ${className}`}
    />
  );
}

export default AssociatedOrganisationsAndRolesTable;
