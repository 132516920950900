export function cityStateHelper(states: any, setCities: any, data: any, values: any, setFieldValue: any, field: any) {
  const foundState = states.find(
    (state: any) => `${state.state_code}` === data
  )
  if (foundState) {
    setCities(foundState.cities)
  } else {
    setCities([])
  }

  if ((values?.manager_state === undefined && values?.patient_state === undefined && data !== values?.state) ||
    (values?.manager_state !== undefined && values?.patient_state === undefined && data !== values?.manager_state) ||
    (values?.manager_state == undefined && values?.patient_state !== undefined && data !== values?.patient_state)) {
    setFieldValue(field, '');
  }

}
