import * as React from "react"
import { SVGProps } from 'react'

const RefreshIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.073}
    height={10.833}
    {...props}
  >
    <g data-name="Group 17615" fill="none">
      <path data-name="Path 5352" d="M10.083 10.083h-10v-10h10Z" />
      <path
        data-name="Path 5353"
        d="M9.86 6.865c0 2.333-2.11 3.218-4.184 3.218A5.233 5.233 0 0 1 .75 5.935a4.979 4.979 0 0 1 3.63-4.926c2.074-.778 4.667.519 5.7 2.852m0-3.111v3.111H7.491"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        
      />
    </g>
  </svg>
)

export default RefreshIcon
