import { useOktaAuth } from "@okta/okta-react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import toastMessages from "../helpers/toastMessage"
import PortalUsersActions from "../../Store/Actions/PortalUsersActions"
import { useHistory } from "react-router-dom"

function useOrderButtonTitle(physicianSignPermission?: any, initialPhysicianID?: any) {
  const [title, setTitle] = useState('Send for Approval')
  const dispatch: any = useDispatch()
  const { authState } = useOktaAuth()
  const { data: OrgData } = useSelector((state: any) => state.Organizations)
  const { selectedOrganization, selectedPhysician, isPhysicianSignPermission } = useSelector((state: any) => state.OnlineOrderForm)
  const { profile } = useSelector((state: any) => state.Profile)
  const history = useHistory()


  function handleEsignatureChecks(selectedPhysician: any) {
    if(profile?.roles?.includes('Collaborative Order Agreement')){
       setTitle('Submit')
    }else if (selectedPhysician?.sofs?.length > 0 && (isPhysicianSignPermission || !!physicianSignPermission)) {
      setTitle('Submit')
    } else
      setTitle('Send for Approval')
  }

  function determineDualRole(selectedPhysician: any) {
    let foundOrganization = null
    let physicianOrganizationRoles = !!selectedPhysician?.organizationRoles ? selectedPhysician?.organizationRoles : !!selectedPhysician?.organizationRoles ? selectedPhysician?.organizationRoles : null

    if (physicianOrganizationRoles) {
      if (typeof (physicianOrganizationRoles) === 'string')
        foundOrganization = JSON?.parse(physicianOrganizationRoles)[selectedOrganization?.id]
      else
        foundOrganization = physicianOrganizationRoles[selectedOrganization?.id]
    }

    if (foundOrganization && foundOrganization?.roles?.includes("Physician") && selectedPhysician?.id === profile?.id) {
      setTitle('Submit')
    } else {
      handleEsignatureChecks(selectedPhysician)
    }
  }

  function getUserData() {
    if (authState?.accessToken && history.location.pathname.includes('online-order'))
      dispatch(PortalUsersActions.fetchPortalUserById(selectedPhysician?.id, {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      }, (message: string) => {
        toastMessages.error(message)
      }))
  }

  useEffect(() => {
    if (selectedPhysician?.id)
      getUserData()
  }, [selectedPhysician?.id])

  useEffect(() => {
    let physician: any = null
    if (initialPhysicianID) {
      OrgData?.some((organization: any) => organization?.users?.filter((user: any) => {
        if (user.id === initialPhysicianID) {
          physician = user
          return true
        }
        return false
      }))
    } else {
      physician = selectedPhysician
    }
    if (selectedOrganization && physician)
      determineDualRole(physician)
  }, [selectedOrganization, selectedPhysician, isPhysicianSignPermission, physicianSignPermission])

  return title
}

export default useOrderButtonTitle