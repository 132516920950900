import {
 HIDE_NOTIFICATION,
 SHOW_ERROR_NOTIFICATION,
 SHOW_SUCCESS_NOTIFICATION,
} from "./types";

export interface CustomNotificationOptions {
 title: string;
 message: string;
 type: "success" | "error";
 buttons?: any;
 customFunction?: () => any;
 hideClose?: boolean;
 Component?: any;
}

const showNotification = (options: CustomNotificationOptions) => {
 if (options.type === "success") {
  return {
   type: SHOW_SUCCESS_NOTIFICATION,
   payload: {
    title: options.title,
    message: options.message,
    customFunction: options.customFunction ?? null,
    buttons: options.buttons,
    hideClose: options?.hideClose ? options.hideClose : false,
    Component: options?.Component,
   },
  };
 }
 return {
  type: SHOW_ERROR_NOTIFICATION,
  payload: {
   title: options.title,
   message: options.message,
   customFunction: options.customFunction ?? null,
   buttons: options.buttons,
   hideClose: options?.hideClose ? options.hideClose : false,
   Component: options?.Component,
  },
 };
};
const hideNotifications = () => ({ type: HIDE_NOTIFICATION });

const NotificationActions = {
 showNotification,
 hideNotifications,
};

export default NotificationActions;
