import ReactTooltip from "react-tooltip";

const TooltipHelper = ({
  id,
  className,
  backgroundColor,
  wrapper,
  place,
  effect,
  scrollHide,
  multiline,
}: any) => {
  
  const isTabletDevice = navigator.maxTouchPoints > 0 

  function handleTooltipAfterShow() {
    if (isTabletDevice) {
      setTimeout(ReactTooltip.hide, 2000);
    }
  }

  return (
    <ReactTooltip
      id={id}
      afterShow={handleTooltipAfterShow}
      backgroundColor={backgroundColor}
      className={className}
      wrapper={wrapper}
      place={place}
      effect={effect}
      scrollHide={scrollHide}
      multiline={multiline}
    />
  );
};

export default TooltipHelper;
