function OrganizationDetailLoadingIndicator () {
  return <div className="px-12 h-full h-[100vh] pb-[45%] lg:pb-[35%] xl:pb-[25%] 2xl:pb-0  overflow-scroll">
        <div className="h-24 w-4/6 lg:w-3/6 object-fit my-4 rounded bg-dropDownBorder animate-pulse" />

        <div className={'self-center w-2/6 text-sm font-semibold p-2 my-2 rounded-lg bg-dropDownBorder animate-pulse'} />

        <div className="my-3 mr-8">
            <div className={'self-center text-sm font-semibold p-2 my-2 rounded-lg bg-dropDownBorder animate-pulse'} />
            <div className="text-royalBlue text-base bg-dropDownBorder rounded-lg p-2 animate-pulse" />
        </div>

        <div className="my-12 border-b border-dropDownBorder animate-pulse py-2">
            <div className="p-2 bg-dropDownBorder animate-pulse w-2/6 rounded-lg" />
            <div className="p-2 bg-dropDownBorder animate-pulse w-full my-2 rounded-lg" />
            <div className="p-2 bg-dropDownBorder animate-pulse w-4/6 rounded-lg" />
        </div>

        <div className="my-12 border-b border-dropDownBorder animate-pulse py-2">
            <div className="p-2 bg-dropDownBorder animate-pulse w-1/6 rounded-lg" />
            <div className="p-2 bg-dropDownBorder animate-pulse w-2/6 mt-2 rounded-lg" />
        </div>

        <div className="flex justify-between">
            <div className="my-12 border-b border-dropDownBorder animate-pulse py-2 w-full mr-4">
                <div className="p-2 bg-dropDownBorder animate-pulse w-1/6 rounded-lg" />
                <div className="p-2 bg-dropDownBorder animate-pulse w-2/6 mt-2 rounded-lg" />
            </div>

            <div className="my-12 border-b border-dropDownBorder animate-pulse py-2 w-full ml-4">
                <div className="p-2 bg-dropDownBorder animate-pulse w-1/6 rounded-lg" />
                <div className="p-2 bg-dropDownBorder animate-pulse w-2/6 mt-2 rounded-lg" />
            </div>

        </div>

        <div className="my-5 border-b border-dropDownBorder animate-pulse py-2">
            <div className="p-2 bg-dropDownBorder animate-pulse w-1/6 rounded-lg" />
            <div className="p-2 bg-dropDownBorder animate-pulse w-2/6 mt-2 rounded-lg" />
        </div>

    </div>
}
export default OrganizationDetailLoadingIndicator
