
import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import thunk from 'redux-thunk'
import rootReducer from './Reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig: any = {
  key: 'root',
  storage,
  blacklist: ['Session'],
  stateReconciler: autoMergeLevel2
}

const composeEnhancers = composeWithDevTools(applyMiddleware(thunk))
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, compose(composeEnhancers))
export const persistor = persistStore(store)

export default store
