import { useCallback, useEffect, useRef, useState } from 'react'

export interface suggestion {
  label: string
  value: string | number
}
interface suggestionProps {
  suggestionVisible: boolean
  suggestionValue: string
  suggestions: suggestion[]
  onChange: any
  setSuggestionValue: any
  containerStyle?: string
  itemStyle?: string
  setSuggestionVisible: any
  disableSuggestiveFiltering?: boolean
  dropDownRef: any
  hideIds?: any
  portalUserHeader?: any
  disableSort?: boolean
}

function SuggestionsDropDown({ suggestionVisible, suggestionValue, disableSuggestiveFiltering, suggestions, onChange, setSuggestionValue, setSuggestionVisible, containerStyle, itemStyle, hideIds, dropDownRef, portalUserHeader, disableSort }: suggestionProps) {
  const [sortedList, setSortedList] = useState<any[]>([])

  const sortByAscending = (a: any, b: any) => {
    if (a?.label?.toLowerCase() < b?.label?.toLowerCase()) {
      return -1
    }
    if (a?.label?.toLowerCase() > b?.label?.toLowerCase()) {
      return 1
    }
    return 0
  }

  useEffect(() => {

    if (suggestions.length > 0) {
      if (!!disableSort) {
        setSortedList(suggestions)
      } else
        setSortedList(suggestions.sort(sortByAscending))
    }
  }, [suggestions?.length])

  useEffect(() => {

    const unsubscribe = document.addEventListener('mousedown', (event) => {
      if (dropDownRef?.current && !dropDownRef?.current?.contains(event.target)) { return setSuggestionVisible(false) }
    })

    return () => unsubscribe
  }, [])

  function handleSuggestionClick(val: any) {
    onChange(val)
    setSuggestionValue(val)
    setSuggestionVisible(false)
  }

  function arrangeElements() {
    let temp = [...sortedList];
    let arrangedArray: any = [];
    let finalArray: any = []

    temp.forEach((element) => {
      if (element.label.toLowerCase().includes(suggestionValue.toLowerCase()) && !!suggestionValue) {
        arrangedArray.push(element)
      }
    })

    if (arrangedArray.length > 0) {
      if (!!disableSort)
        finalArray = [...arrangedArray, ...temp.filter(element => {
          let found = arrangedArray.find((item: any) => element.label == item.label)

          if (found) {
            return false
          }
          return true
        })]
      else
        finalArray = [...arrangedArray.sort(sortByAscending), ...temp.filter(element => {
          let found = arrangedArray.find((item: any) => element.label == item.label)

          if (found) {
            return false
          }
          return true
        })]
    }
    else
      finalArray = [...temp]

    return finalArray
  }

  const arrangedElements = useCallback(arrangeElements, [suggestionValue, suggestionVisible])

  if (suggestionVisible && !!disableSuggestiveFiltering) {
    return <div className={`shadow-md drop-shadow rounded-md z-10 max-h-32 w-full text-royalBlue text-base absolute bg-white top-10 ${containerStyle ?? ''}  overflow-auto`}>
      {suggestions.map((suggestion, index) => <button
        type="button"
        key={suggestion.value}
        className={`block p-2 w-full text-left break-words ${suggestions.length > 1 ? '' : ''} ${itemStyle ?? ''}`}
        onClick={(e) => {
          e.stopPropagation()
          handleSuggestionClick(suggestion.value)
        }}
      >
        {suggestion.label}
        {/* {hideIds ? suggestion.split('|')[1] ? suggestion.split('|')[1] : suggestion : suggestion} */}
      </button>)}
    </div>
  }

  if (suggestionVisible && !suggestionValue) {
    return <div className={`shadow-md drop-shadow ${portalUserHeader && '2xl:w-[103%] xl:[90%] lg:[80%]'} z-10 rounded-md  text-royalBlue text-base max-h-32 absolute bg-white top-10 ${containerStyle ?? ''}
     overflow-auto`}>
      {sortedList.map((suggestion, index) => <button
        type="button"
        key={suggestion.value}
        className={`block p-2 w-full text-left ${portalUserHeader ? 'break-keep' : 'break-words'}  ${suggestions?.length > 1 ? '' : ''} ${itemStyle ?? ''}`}
        onClick={(e) => {
          e.stopPropagation()
          handleSuggestionClick(suggestion.value)
        }}
      >
        {/* {hideIds ? suggestion.split('|')[1] ? suggestion.split('|')[1] : suggestion : suggestion} */}
        {suggestion.label}

      </button>)}
    </div>
  }

  if (suggestionVisible && !!suggestionValue && typeof (suggestionValue) === 'string') {
    if (suggestions?.filter((suggestion) => suggestion?.label?.toLowerCase()?.includes(suggestionValue?.toLowerCase() ?? '')).length > 0) {
      return <div className={`shadow-md drop-shadow rounded-md z-10 w-full absolute bg-white top-11 max-h-32 text-royalBlue text-base ${containerStyle ?? ''} overflow-auto`}>
        {
          arrangedElements()
            .map((suggestion: any, index: number) => <button
              type="button"
              key={index}
              className={`block p-2 w-full text-left break-words ${suggestions?.filter(suggestion => suggestion?.label?.includes(suggestionValue)).length > 1 ? '' : ''} ${itemStyle ?? ''}`}
              onClick={(e) => {
                e.stopPropagation()
                handleSuggestionClick(suggestion.value)
              }}
            >
              {suggestion.label}
              {/* {hideIds ? suggestion.split('|')[1] ? suggestion.split('|')[1] : suggestion : suggestion} */}
            </button>)
        }
      </div>
    } else {
      // return null
      return <div className={`shadow-md drop-shadow rounded-md z-10 w-full absolute bg-white top-11 max-h-32 text-royalBlue text-base ${containerStyle ?? ''} overflow-auto`}>
        <div
          className={`block p-2 w-full text-left break-words ${itemStyle ?? ''}`}
        >
          <p className='text-royalBlue  break-words text-left'>Item not found</p>
        </div>
      </div>
    }
  }
  return null
}

export default SuggestionsDropDown
