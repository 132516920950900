import QuestionMarkSVG from '../../assets/svg/QuestionMarkSVG'
import TemplateProvider from '../../Containers/TemplateProvider'
import { motion } from 'framer-motion'
// import { useNavigate } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import Button from '../../components/atomic/Button'
import ExistingSignUpContents from '../../components/molecular/ExisitingSignUpContents'

function ExistingSignUpPage () {
  const history = useHistory()

  function handleBack () {
    history.goBack()
  }

  return <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="flex flex-col h-full justify-center items-center">

        <ExistingSignUpContents
            handleBack={handleBack}
            handleSupport={() => { }}
            isPage
        />
    </motion.section>
}

export default ExistingSignUpPage
