import * as Yup from "yup";

const PDForderValidation = Yup.object().shape({
 firstName: Yup.string().required("First name is required!").test('no-empty-blank-space', 'First name cannot be an empty blank space(s)', (value:any) => {
  return value && value.trim() !== '';
}),
 lastName: Yup.string().required("Last name is required!").test('no-empty-blank-space', 'Last name cannot be an empty blank space(s)', (value:any) => {
  return value && value.trim() !== '';
}),
 paperTRFDocuments: Yup.array()
  .min(1, "Document(s) are required")
  .required("Document(s) are required"),
});

export default PDForderValidation;
