import { Form, Formik } from "formik";
import CustomInput from "../../../components/global/CustomInput";
import { formRef } from "../ManageScreen";
import { Fragment, useEffect, useState } from "react";
import PickupAddress from "./Pickup/pickupAddress";
import PickupInformation from "./Pickup/pickUpInformation";
import AdditionalInformation from "./Pickup/additionalInformation";
import { useSelector } from "react-redux";
import dataConstants from "../../../constants/dataConstants";
import getKeyByValue from "../../../utils/helpers/keyFromObjectValue";
import { useOktaAuth } from "@okta/okta-react";
import http from "../../../Store/API/HttpMethods";
import * as Yup from "yup";
import toastMessages from "../../../utils/helpers/toastMessage";
import dateFormatter from "../../../utils/helpers/dateFormatter";
import { useHistory } from "react-router-dom";
import ProgressBarPerField from "../../../components/molecular/ProgressBarPerField";

export const validationSchemas =
  Yup.object().shape({
    contactName: Yup.string()
      .required("Contact name is required!")
      .test(
        "no-empty-blank-space",
        "Contact name cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    company: Yup.string()
      .required("Company is required!")
      .test(
        "no-empty-blank-space",
        "Company cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    address1: Yup.string()
      .required("Address is required!")
      .test(
        "no-empty-blank-space",
        "Address cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    city: Yup.string()
      .required("City is required!")
      .test(
        "no-empty-blank-space",
        "City cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    state: Yup.string()
      .required("State is required!")
      .test(
        "no-empty-blank-space",
        "State cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    zipCode: Yup.string()
      .min(5, "Zip code length must equal to 5")
      .max(5, "Zip code length must equal to 5")
      .matches(/^[0-9]+$/, "Zip code must be numbers").required('Zip Code is required!'),
    phone: Yup.string().test("phone-validation", "Phone must contain 10 digits with the following format 000-000-0000", (value) => {
      if (!value) {
        return true;
      }
      return /\d{3}-\d{3}-\d{4}/.test(value);
    }).required('Phone is required!'),
    pickupDate: Yup.string()
      .test(
        "custom-date-validator",
        (value: string | undefined) => !!dateFormatter(value)
      )
      .required("Pickup Date is required!"),
    closeTime: Yup.string()
      .required("Close Time is required!")
      .test(
        "no-empty-blank-space",
        "Close Time cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    readyDateTimestamp: Yup.string()
      .required("Available Ready Time is required!")
      .test(
        "no-empty-blank-space",
        "Available Ready Time cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
  })

interface schedulePickupValues {
  contactName: string,
  company: string,
  address1: string,
  address2: string,
  state: string,
  stateValue: string,
  city: string,
  phone: string,
  zipCode: string,
  pickupDate: string,
  closeTime: string,
  readyDateTimestamp: string,
  additionalInformation: string,
  orgId: number
}

function RenderFields({
  initialValues,
  setFieldValue,
  errors,
  touched,
  setImage,
  isValid,
  values,
  setFieldError,
  validateField,
  setValidFields,
  setScheduleDisabled,
  requiredFields,
  ...rest
}: any): JSX.Element {
  useEffect(() => {
    rest.validateForm(values).then((res: any) => {
      setValidFields(requiredFields - Object.keys(res).length)
    })
  }, [])
  return (
    <Fragment>
      <div>
        <PickupAddress
          initialValues={initialValues}
          setFieldError={setFieldError}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          requiredFields={requiredFields}
          setScheduleDisabled={setScheduleDisabled}
          setValidFields={setValidFields}
          {...rest}
        />
        <PickupInformation
          initialValues={initialValues}
          setFieldError={setFieldError}
          values={values}
          setFieldValue={setFieldValue}
          setScheduleDisabled={setScheduleDisabled}
          errors={errors}
          touched={touched}
          requiredFields={requiredFields}
          setValidFields={setValidFields}
          {...rest}
        />
        <AdditionalInformation
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          setScheduleDisabled={setScheduleDisabled}
          touched={touched}
          {...rest}
        />
      </div>
    </Fragment>
  );
}

function SchedulePickup({ setScheduleDisabled, setSubmitting }: { setScheduleDisabled: Function, setSubmitting: Function }) {
  const { selectedOrganization, selectedPathLab } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const { data: states, error } = useSelector((state: any) => state.StateAndCity)
  const { profile } = useSelector((state: any) => state.Profile)
  let isPLU = profile?.roles?.includes('PathLab User')
  let OrganisationORPathlab = isPLU ? selectedPathLab : selectedOrganization
  const date = new Date()
  const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`;
  const contactName = `${profile?.firstName} ${profile?.lastName}`;
  const [validFields, setValidFields] = useState<number>(0)
  const requiredFields = 10
  const [previousOrgId, setPreviousOrgId] = useState<any>(null)
  const [previousPathLabId, setPreviousPathLabId] = useState<any>(null)
  const { authState } = useOktaAuth();
  const history = useHistory()
  const maskedPhone = (phone: string) => {
    const phoneString = phone.replace(/-/g, "");
    return `${phoneString?.[0]}${phoneString?.[1]}${phoneString?.[2]}-${phoneString?.[3]}${phoneString?.[4]}${phoneString?.[5]}-${phoneString?.[6]}${phoneString?.[7]}${phoneString?.[8]}${phoneString?.[9]}`
  }

  let initialValues: schedulePickupValues = {
    contactName: contactName ? contactName : '',
    company: OrganisationORPathlab?.name ? OrganisationORPathlab?.name : '',
    address1: OrganisationORPathlab?.address ? OrganisationORPathlab?.address : '',
    address2: OrganisationORPathlab?.address2 ? OrganisationORPathlab?.address2 : '',
    state: OrganisationORPathlab?.state ? states?.find((e: any) => ((e?.state_code === OrganisationORPathlab?.state) || (e?.state === OrganisationORPathlab?.state)))?.state_code : '',
    stateValue: OrganisationORPathlab?.state?.value ? OrganisationORPathlab?.state?.value : '',
    city: OrganisationORPathlab?.city ? OrganisationORPathlab?.city : '',
    phone: OrganisationORPathlab?.phone ? maskedPhone(OrganisationORPathlab?.phone) : '',
    zipCode: OrganisationORPathlab?.zipcode ? OrganisationORPathlab?.zipcode : '',
    pickupDate: formattedDate,
    closeTime: '',
    readyDateTimestamp: '',
    additionalInformation: '',
    orgId: OrganisationORPathlab?.id
  }


  async function schedulePickup(data: schedulePickupValues) {
    setSubmitting(true)
    let formattedData: any = { ...data }

    const readyDateTimeInHHMMSS = getKeyByValue(dataConstants.TimeKey, data.readyDateTimestamp)
    // formattedData.readyDateTimestamp = new Date(`${data.pickupDate} ${readyDateTimeInHHMMSS}`);
    const year = data.pickupDate.split('-')[2]
    const month = data.pickupDate.split('-')[0]
    const day = data.pickupDate.split('-')[1]
    const hour = readyDateTimeInHHMMSS?.split(':')[0]
    const minute = readyDateTimeInHHMMSS?.split(':')[1]


    formattedData.readyDateTimestamp = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute)));
    formattedData.closeTime = getKeyByValue(dataConstants.TimeKey, data.closeTime) ? getKeyByValue(dataConstants.TimeKey, data.closeTime) : data.closeTime

    delete formattedData.stateValue

    try {

      const response = await http.schedulePickup(
        "/v1/fedex/schedule-pickup/",
        {
          ...formattedData,
        },
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }
      );
      const { statusCode, message } = response;
      if (response.message === 'Success') {
        toastMessages.success("Pickup scheduled successfuly!");
        history.push('/shipments/pick-ups')
      }
      setSubmitting(false)
    } catch (error: any) {
      if (error?.response?.data?.errors[0]?.message === 'StateOrProvinceCode is missing.') {
        toastMessages?.error('Please enter valid zip code.')
      } else if (error?.response?.data?.errors[0]?.message) {
        toastMessages.error(error?.response?.data?.errors[0]?.message)
      } else {
        toastMessages.error(error?.message)
      }
      setSubmitting(false)
    }
  }



  function Render({
    errors,
    touched,
    setFieldValue,
    isValid,
    values,
    initialValues,
    setFieldError,
    validateField,
    ...rest
  }: any): JSX.Element {

    useEffect(() => {
      if (!isPLU ? selectedOrganization?.id !== previousOrgId : selectedPathLab?.id !== previousPathLabId) {
        setFieldValue('company', OrganisationORPathlab?.name ? OrganisationORPathlab?.name : '')
        setFieldValue('address1', OrganisationORPathlab?.address ? OrganisationORPathlab?.address : '')
        setFieldValue('address2', OrganisationORPathlab?.address2 ? OrganisationORPathlab?.address2 : '')
        setFieldValue('state', OrganisationORPathlab?.state ? states?.find((e: any) => ((e?.state_code === OrganisationORPathlab?.state) || (e?.state === OrganisationORPathlab?.state)))?.state_code : '')
        setFieldValue('stateValue', OrganisationORPathlab?.state?.value)
        setFieldValue('city', OrganisationORPathlab?.city ? OrganisationORPathlab?.city : '')
        setFieldValue('phone', OrganisationORPathlab?.phone ? maskedPhone(OrganisationORPathlab?.phone) : '')
        setFieldValue('zipCode', OrganisationORPathlab?.zipcode ? OrganisationORPathlab?.zipcode : '')
        setFieldValue('orgId', OrganisationORPathlab?.id)
      }
      if (!isPLU) {
        setPreviousOrgId(selectedOrganization?.id)
      } else {
        setPreviousPathLabId(selectedPathLab?.id)
      }
    }, [selectedOrganization?.id, selectedPathLab?.id]);
    return (
      <Fragment>
        <div className="px-[109px] sticky top-0 bg-white pb-10 pt-5 z-[30]">
          <ProgressBarPerField validFields={validFields} totalFields={requiredFields} />
        </div>
        <Form className="col-span-4 flex flex-col  w-full overflow-auto">
          <div className="flex flex-col overflow-auto">
            <div className="px-10 lg:px-[109px]">
              <RenderFields
                initalValues={initialValues}
                values={values}
                isPage={false}
                setScheduleDisabled={setScheduleDisabled}
                errors={errors}
                touched={touched}
                isValid={isValid}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                requiredFields={requiredFields}
                validateField={validateField}
                setValidFields={setValidFields}
                {...rest}
              />
            </div>
          </div>
        </Form>
      </Fragment>
    );
  }

  return (
    <Formik
      innerRef={formRef}
      validationSchema={validationSchemas}
      initialValues={initialValues}
      onSubmit={schedulePickup}
      initialStatus={false}
      isInitialValid={false}
      render={Render}
    />
  )
}

export default SchedulePickup;