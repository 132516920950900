import * as React from "react"
import { SVGProps } from "react"
const ErrorCross = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.435}
    height={14.435}
    viewBox="122.568 363.593 14.435 14.435"
    {...props}
  >
    <path
      d="M129.785 363.593a7.214 7.214 0 0 0-7.217 7.217 7.216 7.216 0 0 0 7.217 7.218 7.217 7.217 0 0 0 7.218-7.218 7.216 7.216 0 0 0-7.218-7.217Zm3.609 9.804-1.022 1.022-2.587-2.588-2.588 2.588-1.02-1.022 2.587-2.587-2.587-2.588 1.02-1.02 2.588 2.587 2.587-2.587 1.022 1.02-2.588 2.588 2.588 2.587Z" />
  </svg>
)
export default ErrorCross
