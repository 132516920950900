import store from "..";
import DashboardCallModel from "../../models/DashboardCallModel";
import http from "../API/HttpMethods";
import {
 FETCHING_DASHBOARD_DATA,
 FETCHING_DASHBOARD_DATA_ERROR,
 FETCHING_DASHBOARD_DATA_SUCCESS,
} from "./types";

function createDashboardURL(isPLU: boolean, props: any) {
 let url = "/v1/orders/new-flow/dashboard-status";
 let urlParams: any = {
  physicianId: "physicianId",
  pathLabId: "pathlabId",
 };

 if (!isPLU) {
  urlParams["organizationId"] = "organizationId";
 }

 const buildParams = (filters: any) => {
  const params = new URLSearchParams();

  Object.keys(filters).forEach(
   (key: string) => props[key] && params.append(filters[key], props[key])
  );

  return params.toString();
 };

 url = `${url}${buildParams(urlParams) ? `?${buildParams(urlParams)}` : ""}`;
 return url;
}

const fetchDashboard =
 (options: DashboardCallModel) => async (dispatch: any) => {
  const {
   config,
   onSuccess,
   onError,
   physicianId,
   organizationId,
   pathLabId,
   isPLU,
  } = options;

  const {
   physicianId: previousPhysicianId,
   organizationId: previousOrganizationId,
   pathLabId: previousPathLabId,
   error,
   isInitialCall,
  }: any = store.getState().Dashboard;

  if (
   (pathLabId == previousPathLabId &&
    previousPhysicianId == physicianId &&
    previousOrganizationId == organizationId &&
    isInitialCall === false) ||
   error
  )
   return;

  dispatch({
   type: FETCHING_DASHBOARD_DATA,
   payload: { physicianId, organizationId, pathLabId },
  });

  const props: any = {
   pathLabId,
   physicianId,
   organizationId,
  };

  try {
   const response = await http.getDashboardData(
    createDashboardURL(isPLU, props),
    config
   );

   const { statusCode, data, message } = response;

   if (statusCode == 200) {
    dispatch({ type: FETCHING_DASHBOARD_DATA_SUCCESS, payload: data });
    if (onSuccess) onSuccess(message);
   }
  } catch (error: any) {
   const { response } = error;
   if (response) {
    if (response.data.message) {
     onError && onError(response.data.message);
    } else {
     onError && onError(error.message ?? "Failed to get data");
    }
   } else {
    onError && onError(error.message ?? "Failed to get data");
   }
   dispatch({ type: FETCHING_DASHBOARD_DATA_ERROR });
  }
 };

const DashboardActions = {
 fetchDashboard,
};

export default DashboardActions;
