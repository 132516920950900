import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { NavLink, useNavigate } from 'react-router-dom'
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import ApprovalsIcon from '../../assets/svg/SideNav/ApprovalsIcon'
import DashboardIcon from '../../assets/svg/SideNav/DashboardIcon'
import ManageIcon from '../../assets/svg/SideNav/ManageIcon'
import PDFIcon from '../../assets/svg/SideNav/PDFIcon'
import ProfileIcon from '../../assets/svg/SideNav/ProfileIcon'
import Help from '../../help'
import SubmittedTestOrdersIcon from '../../assets/svg/SideNav/SubmittedTestOrdersIcon'
import navLinkProps from '../../models/customnavlinkprops.type'
import ButtonStateActions from '../../Store/Actions/ButtonStateActions'
import Button from './Button'
import TermsAndConditionsIcon from '../../assets/svg/SideNav/TermsAndConditionsIcon'
import NotificationActions from '../../Store/Actions/NotificationsActions'
import TermsAndConditionsPopup from '../global/TermsAndConditionsPopup'
import NotificationsIcon from '../../assets/svg/SideNav/NotificationsIcon'
import ShipmentIcon from '../../assets/svg/SideNav/ShipmentsIcon'

const MenuIcon = {
  approvals: (activeClassName: boolean) => <ApprovalsIcon stroke={activeClassName ? 'white' : '#172B4D'} className={'ml-3 self-center items-center  h-full justify-center'} height={20} width={30} />,
  dashboard: (activeClassName: boolean) => <DashboardIcon stroke={activeClassName ? 'white' : '#172B4D'} className={'ml-3 self-center items-center  h-full justify-center'} height={20} width={30} />,
  testorders: (activeClassName: boolean) => <SubmittedTestOrdersIcon stroke={activeClassName ? 'white' : '#172B4D'} className={'ml-3 self-center items-center  h-full justify-center'} height={20} width={30} />,
  PDF: (activeClassName: boolean) => <PDFIcon stroke={activeClassName ? 'white' : '#172B4D'} className={'ml-3 self-center items-center  h-full justify-center'} height={20} width={30} />,
  shipment: (activeClassName: boolean) => <ShipmentIcon stroke={activeClassName ? 'white' : '#172B4D'} className={'ml-3 self-center items-center  h-full justify-center'} height={20} width={30} />,
  manage: (activeClassName: boolean) => <ManageIcon fill={activeClassName ? 'white' : '#172B4D'} className={'ml-3 self-center items-center  h-full justify-center'} height={20} width={30} />,
  profile: (activeClassName: boolean) => <ProfileIcon stroke={activeClassName ? 'white' : '#172B4D'} className={'ml-3 self-center items-center  h-full justify-center'} height={20} width={30} />,
  help: (activeClassName: boolean) => <Help stroke={activeClassName ? 'white' : '#172B4D'} className={'ml-3 self-center items-center  h-full justify-center'} height={20} width={30} />,
  terms_conditions: (activeClassName: boolean) => <TermsAndConditionsIcon stroke={activeClassName ? 'white' : '#172B4D'} className={'ml-3 self-center items-center  h-full justify-center'} height={20} width={30} />,
  notifications: (activeClassName: boolean) => <NotificationsIcon stroke={activeClassName ? 'white' : '#172B4D'} className={'ml-3 self-center items-center  h-full justify-center'} height={20} width={30} />
}

function CustomNavLink({ to, title, iconName }: navLinkProps) {
  const { screen } = useSelector((state: any) => state.ButtonState)
  const [showModal, setShowModal] = useState(false);
  const activeClassName = false
  const { path } = useRouteMatch()
  // const navigate = useNavigate()
  const history = useHistory()
  const dispatch = useDispatch()

  // To access Object key's
  type ObjectKey = keyof typeof MenuIcon
  const myKey = iconName as ObjectKey

  function handleConfirm(location: string) {
    if (window.confirm('Are you sure you want to exit? all the changes will be discarded')) {
      history.push(location, { replace: true })
      dispatch(ButtonStateActions.resetButtonState())
    }
  }

  if (screen === 'online order' || screen === 'edit online order') {
    return <><button type="button" className="w-full" onClick={() => {
      setShowModal(true)
      dispatch(NotificationActions.showNotification({
        type: 'error',
        title: 'Online Order',
        hideClose: true,
        message: `Are you sure you want to exit? all the changes will be discarded`,
        customFunction() {

        },
        buttons: [
          {
            onClick: () => {
              dispatch(NotificationActions.hideNotifications())
            },
            title: 'Cancel',
            type: 'secondary',
          },
          {
            onClick: () => {
              if (to === '/terms_conditions') {
                history.replace('/test-orders')
                dispatch(NotificationActions.showNotification({
                  type: 'success',
                  title: 'Terms & Conditions',
                  message: '',
                  hideClose: true,
                  Component: () => <TermsAndConditionsPopup onlyDownload={true} />,
                  buttons: []
                }))

              } else {
                history.push(to)
                dispatch(NotificationActions.hideNotifications())
              }
              // history.push(to, { replace: true })
            },
            title: 'OK',
            type: "primary",
          }
        ]
      }))
    }} >
      <div
        className={`${to == '/test-orders' ? 'w-full bg-gradient-to-r from-[#18B5B7] to-[#084E8B] capitalize text-white' : ''} flex flex-col justify-center text-center w-full text-xs capitalize p-3 visited:text-royalBlue text-royalBlue hover:bg-sideMenuHoverBg ${title == 'Dashboard' ? 'lg:pt-5' : ''}`}
      >
        {MenuIcon[myKey](to == '/test-orders')}
        {title}

      </div>
    </button >
    </>
  }
  return <NavLink onClick={(e) => {
    if (title === 'Terms and Conditions') {
      e.preventDefault()
      dispatch(NotificationActions.showNotification({
        type: 'success',
        title: 'Terms & Conditions',
        message: '',
        hideClose: true,
        Component: () => <TermsAndConditionsPopup onlyDownload={true} />,
        buttons: []
      }))
    }
  }} to={to}>
    <div
      className={`${path.split('/')[1]?.includes(to.replace('/', '')) ? 'w-full bg-gradient-to-r from-[#18B5B7] to-[#084E8B] capitalize text-white' : ''} flex flex-col justify-center text-center w-full text-xs capitalize p-3 visited:text-royalBlue text-royalBlue hover:bg-sideMenuHoverBg ${title == 'Dashboard' ? 'lg:pt-5' : ''}`}
    >
      {MenuIcon[myKey](path.split('/')[1]?.includes(to.replace('/', '')) ?? false)}

      {title}
    </div>
  </ NavLink>

}
export default CustomNavLink
