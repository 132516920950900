import { BlockBlobClient } from "@azure/storage-blob";
import { useOktaAuth } from "@okta/okta-react";
import { useRef, useState } from "react";
import http from "../../Store/API/HttpMethods";
import toastMessages from "../../utils/helpers/toastMessage";
import Button from "../atomic/Button";
import FileDetail from "./FileDetail";

function MultipleFileUploader({
  title,
  className,
  documents,
  setDocuments,
  uploaderTitle,
  setButtonDisabled,
  uploadButtonTitle,
  chooseFilesButtonText
}: any): JSX.Element {
  const inputRef = useRef<any>();
  const { authState } = useOktaAuth();
  const [newFiles, setNewFiles] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([])


  const validateFile = (file: any): boolean => {
    let sizeInMB = null;
    sizeInMB = file.size / 1024 / 1024;
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'png', 'jpg', 'jpeg', 'pdf']


    if (files.length > 0) {
      if (files.some((item: any) => item.name === file.name)) {
        alert("File already added");
        return false;
      }
    }
    if (!validTypes.includes(getExt(file.name)?.toLowerCase())) {
      toastMessages.error(`Invalid file format`)
      return false
    }

    if (sizeInMB > 50) {
      alert("File size limit is being exceeded");
      return false;
    }
    return true;
  };

  function handleFiles(event: any): void {
    let inputFiles = event.target.files;
    let temp: any[] = [];

    if (files.length > 0) {
      temp = [...files];
    }

    let temp2 = [...newFiles];
    for (const element of inputFiles) {
      if (validateFile(element)) {
        temp.push(element);
        temp2.push(element);
      }
    }
    setFiles(temp);
    setNewFiles(temp2);
  }

  async function handleDrop(event: any) {
    event.preventDefault()
    event.stopPropagation()
    let inputFiles = event.dataTransfer.files;
    let temp: any[] = [];

    if (files.length > 0) {
      temp = [...files];
    }

    let temp2 = [...newFiles];
    for (const element of inputFiles) {
      if (validateFile(element)) {
        temp.push(element);
        temp2.push(element);
      }
    }
    setFiles(temp);
    setNewFiles(temp2);
  }

  function handleRemove(mainIndex: number) {
    return () => {
      inputRef.current.value = "";
      if (documents.length > 0) {
        let temp = [...documents]
        temp = temp.filter((item, index) => index !== mainIndex)
        setDocuments(temp)
        setButtonDisabled(temp.length == 0)
      }
      setNewFiles((prevState: any) => prevState.filter((item: any) => item.name !== files[mainIndex].name))
      setFiles((prevState: any) =>
        prevState.filter((file: any, index: number) => index !== mainIndex)
      );

    };
  }

  function getExt(filename: string): any {
    const ext = filename.split(".").pop();
    if (ext === filename) return "";
    return ext;
  }

  function uploadToAzure(
    inputFile: any,
    url: string,
    filePath: string,
    resolve: any,
    reject: any
  ): void {
    const blockBlobClient = new BlockBlobClient(url);
    blockBlobClient
      .uploadData(inputFile, {
        blobHTTPHeaders: { blobContentType: inputFile.type },
      })
      .then(() => {
        resolve({ filePath, url });
      })
      .catch((error: any) => reject(error));
  }

  function getPresignedUrl(inputFile: any, resolve: any, reject: any): any {
    if (!inputFile) return reject("File missing");
    const extension = getExt(inputFile.name);
    http
      .getPresignedUrl(`v1/pre-signed-url/documents/${inputFile.name.replace(/ /g, "_")}`, {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      })
      .then(({ url, filepath }: any) => {
        if (url) {
          uploadToAzure(inputFile, url, filepath, resolve, reject);
        } else {
          reject("URL not provided");
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  }

  function uploadFile(inputFile: any): any {
    return new Promise((resolve, reject): void => {
      getPresignedUrl(inputFile, resolve, reject);
    });
  }

  function handleTransmit(): void {
    if (uploading) return;
    setUploading(true);
    setButtonDisabled(true);
    Promise.all([...newFiles.map((file: any) => uploadFile(file))])
      .then((results: any) => {
        if (documents.length > 0) {
          setDocuments((prevState: any[]) => [...prevState, ...results])
        } else {
          setDocuments(results)
        }
        setUploading(false);
        setButtonDisabled(false)
        setNewFiles([]);

      })
      .catch((error: any) => {
        toastMessages.error(error ?? "Something went wrong");
        setUploading(false);
      });
  }

  return (
    <div className={`mt-4 px-2 ${className ?? ""}`}>
      <div className="flex justify-between items-center text-center">
        <p className="text-royalBlue text-base font-medium mr-3">{title}</p>
      </div>
      <div
        onDragOver={e => e.preventDefault()}
        onDragEnter={e => e.preventDefault()}
        onDragLeave={e => e.preventDefault()}
        onDrop={handleDrop}>
        <div className="my-2">
          {uploaderTitle && <p className="text-royalBlue my-2">
            {uploaderTitle}
          </p>}
          {files && files?.length > 0 && (
            <div className="my-3 flex items-center flex-wrap">
              {files.map((file: any, mainIndex: number) => (
                <FileDetail
                  key={file.name}
                  url={file?.url}
                  handleRemove={handleRemove(mainIndex)}
                  file={file}
                  uploading={uploading && !!documents?.[mainIndex]?.url === false}
                  hideRemove={uploading ?? false}
                  disabled={false}
                  isSummary
                />
              ))}
            </div>
          )}
          <div className="flex items-center justify-center">
            <Button
              btnType="secondary"
              title={chooseFilesButtonText ? chooseFilesButtonText : "Choose more files"}
              className="py-2 bg-white mb-3"
              disabled={uploading}
              onClick={() => inputRef.current.click()}
            />
            <Button
              title={`${uploadButtonTitle ? uploadButtonTitle : 'transmit file (s)'} ${newFiles.length > 0 ? `(${newFiles.length})` : ""
                }`}
              btnType={"primary"}
              submitting={uploading}
              disabled={newFiles.length === 0 ?? false}
              className="py-2 mb-3 ml-3"
              onClick={handleTransmit}
            />
          </div>
          <input
            type="file"
            id="file"
            multiple
            accept=".png, .jpeg, .pdf, .doc/*"
            ref={inputRef}
            // onChange={onChange}
            onChange={handleFiles}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </div>
  );
}

export default MultipleFileUploader