/* eslint-disable @typescript-eslint/space-before-function-paren */
import React from 'react'
import CloseIcon from '../../assets/svg/CloseIcon'
import FileIcon from '../../assets/svg/FileIcon'
import LoadingSvg from '../../assets/svg/LoadingSVG'

function FileDetail({ handleRemove, disabled, file, uploading, url, hideRemove, isSummary,sofFileUploader,approvalsChooseFileButtonStyle }: any): JSX.Element {
  function openDocument(e: any): void {
    e.stopPropagation()
    if (disabled) return
    if (url && file.type.includes('doc') === false) {
      window.open(url)
    }
  }
  function handleClose(e: any): void {
    e.stopPropagation()
    handleRemove()
  }
  const fileName = file?.name;
  const updatedFileName = fileName?.replace(/^documents\//, '');
  return <div
    onClick={openDocument}
    className={`flex justify-between items-start border cursor-pointer ${approvalsChooseFileButtonStyle && 'bg-white'} ${sofFileUploader && 'bg-white'} ${hideRemove ? 'bg-white flex justify-center items-center' : ''} ${isSummary ? 'bg-white' : ''} border-borderGray rounded p-2 w-[260px] mt-2 mr-5 ${disabled ? 'cursor-not-allowed opacity-[50%]' : ''}`}>
    <div className="flex items-center w-[80%] overflow-auto">
      <FileIcon className="mr-2 w-1/6" />
      <div className="w-5/6">
        <p className="text-royalBlue font-medium text-sm truncate">{updatedFileName}</p>
        <div className='flex inline justify-between'>
          {file.size && <p className="text-royalBlue text-sm">{Math.round(file?.size / 1024)} KB</p>}
        </div>
      </div>
    </div>

    <div className={hideRemove ? `flex flex-col h-full ${uploading ? '!justify-center' : 'justify-end'} w-[20%]` : ''}>
      {hideRemove ? <div className={uploading ? 'p-0' : 'p-3'} /> : <button type="button" disabled={disabled} onClick={handleClose} className={`w-full flex p-2 flex-col items-end focus:border-[#647188] outline-[#647188] ${disabled ? 'cursor-not-allowed' : ''}`}>
        <CloseIcon />
      </button>}
      {uploading ? <LoadingSvg isSecondary className="animate-spin ml-3" /> : url && <p className={`text-royalBlue text-sm hover:underline flex-col items-end`}>View</p>}
    </div>
  </div>
}

export default FileDetail
