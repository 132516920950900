import * as React from "react"
import { SVGProps } from "react"
const ShipmentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.362}
    height={19.181}
    {...props}
  >
    <g
      fill="none"
      stroke={props?.stroke ? props.stroke : "#172b4d"}
      strokeLinecap="round"
      strokeLinejoin="round"
      data-name="Component 6415 \u2013 2"
    >
      <path
        d="m8.681.681 8 4.5v9l-8 4.5-8-4.5v-9l8-4.5"
        data-name="Path 5355"
      />
      <path d="m8.681 9.681 8-4.5" data-name="Path 5356" />
      <path d="M8.681 9.681v9" data-name="Path 5358" />
      <path d="m8.681 9.681-8-4.5" data-name="Path 5359" />
    </g>
  </svg>
)
export default ShipmentIcon
