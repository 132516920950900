import React, { Component } from 'react'
import './style.css'
import icon from '../../assets/Icon/three-dots.svg'

export interface MenuItem {
  label: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}
interface MenuProps {
  position?:
  | 'position-top-left'
  | 'position-top-right'
  | 'position-bottom-right'
  | 'position-bottom-left'
  actions: MenuItem[]
  portaluserHeader?: any
}

interface IMenuStates {
  isOpen: boolean
  position?: string
  isHovering?: any
}

class ThreeDotVerticalMenu extends Component<MenuProps, IMenuStates> {
  private readonly wrapperRef: React.RefObject<any>

  constructor(props: MenuProps) {
    super(props)

    this.state = {
      isOpen: false,
      position: props.position ?? 'position-bottom-left',
      isHovering: false,
    }

    this.wrapperRef = React.createRef()
  }

  handleClickOutside = (event: Event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isOpen: false })
    }
  }
  handleMouseOver = () => {
    this.setState({ isHovering: true })
  }
  handleMouseOut = () => {
    this.setState({ isHovering: false })
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  render() {
    const { position, isOpen } = this.state
    const { actions, portaluserHeader } = this.props

    return (
      <div
        ref={this.wrapperRef}
        className={'three-Dot-vertical-menu-container'}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}>
        <div
          onClick={(e: any) => {
            e.stopPropagation()
            this.setState({ isOpen: !this.state.isOpen })
          }}
          className={`${isOpen} ? 'button-hover' : ''`}
        >
          <img
            src={icon} alt="not found" />
        </div>

        <ul
          className={`${isOpen ? 'dropdown z-10 hover:bg-[#edeff1]' : 'dropdown-hide'} ${portaluserHeader && 'break-keep w-[120px]'} bg-white ${position}`}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        >
          {/* used for top right arrow in dropdown */}

          {/* <p className={`h-2 w-2 -top-1 right-2 absolute ${this.state.isHovering?'bg-[#edeff1]':'bg-white'} -rotate-45 transform origin-top-right`}> </p> */}
          {actions &&
            actions.map((item: any, index) => (
              <div  className={``} key={`threeDots ${index}`}
              onClick={(e: any) => {
                e.stopPropagation()
                item?.onClick()
              }}
              >
              <li>
                <a>{item.label}</a>
              </li>
              </div>
            ))}

        </ul>
      </div >
    )
  }
}
export default ThreeDotVerticalMenu
