import axios from "axios";
import fileDownload from "js-file-download";
import http from "../../Store/API/HttpMethods";
import { error } from "console";
import { reject } from "lodash";
import toastMessages from "./toastMessage";

type pdfNameAndIds = {
 id: number;
 fileName: string;
};

const fileDownloader = (
 url: string,
 filename: string,
 onSuccess?: any,
 onError?: any,
 headers?: any
) => {
 axios
  .get(
   url,
   headers ?? {
    responseType: "blob",
   }
  )
  .then((res) => {
   onSuccess && onSuccess();
   fileDownload(res.data, filename);
  })
  .catch((error: any) => {
   onError && onError(error.message ?? "File download failed!");
  });
};

async function downloadOrderPDF(
 id: string,
 fileName: string,
 token: string,
 onError: any,
 onSuccess?: any
) {
 try {
  const response = await http.getPDF(`/v1/orders/${id}/download`, {
   responseType: "arraybuffer",
   headers: {
    Authorization: `Bearer ${token}`,
    Accept: "application/pdf",
   },
  });

  if (response) {
   fileDownload(response, `${fileName}.pdf`);
   onSuccess && onSuccess();
  }
 } catch (error: any) {
  // onError(error);
  onError && onError(error.message ?? `File download failed!`);
 }
}
async function downloadMultiplePDF(
 data: Array<pdfNameAndIds>,
 token: string,
 withSignature: boolean,
 onError: any,
 onSuccess: any
) {
 try {
  const config = {
   headers: {
    Authorization: `Bearer ${token}`,
   },
  };
  let orderIds = data.map((item: pdfNameAndIds) => item.id);

  const response = await http.downloadMultiplePDF(
   `/v1/orders/new-flow/multiple-download${
    withSignature ? "?template=signature" : ""
   }`,
   { orderIds },
   config
  );

  if (response.length > 0) {
   //  fileDownload(response, `${data[0].fileName}.pdf`);
   response.map((item: any, index: number) =>
    fileDownload(
     new Blob([new Uint8Array(item.data).buffer], { type: "application/pdf" }),
     `${data[index].fileName}.pdf`
    )
   );
   onSuccess && onSuccess();
  }
 } catch (error: any) {
  onError && onError(error.message ?? `File download failed!`);
 }
}
function getReport({ url, filename }: { url: string; filename: string }) {
 return new Promise((resolve, reject) => {
  axios
   .get(url, {
    responseType: "blob",
   })
   .then((res) => {
    resolve(fileDownload(res.data, filename));
   })
   .catch((error: any) => {
    reject(`${filename} download failed!`);
   });
 });
}
async function downloadReport(
 id: string,
 fileName: string,
 token: string,
 onError: any,
 onSuccess?: any
) {
 try {
  const response = await http.getReport(
   `/v1/orders/${id}/new-flow/get-report`,
   {
    headers: { Authorization: `Bearer ${token}` },
   }
  );
  const { statusCode, data } = response;
  if (statusCode == 200) {
   if (data.reportUrl.length > 0) {
    Promise.allSettled(
     data.reportUrl.map((url: string, index: number) =>
      getReport({ url, filename: `${fileName}${index + 1} Report.pdf` })
     )
    ).then((results) => {
     onSuccess?.();
     results
      .filter((item) => item.status === "rejected")
      .forEach(({ status, reason }: any) => {
       toastMessages.error(reason);
      });
    });
   } else {
    onSuccess?.();
   }
   //  fileDownloader(data.reportUrl, `${fileName} Report.pdf`, onSuccess, onError);
  }
 } catch (error: any) {
  onError(error);
 }
}

const DownloadHelper = {
 fileDownloader,
 downloadReport,
 downloadOrderPDF,
 downloadMultiplePDF,
};

export default DownloadHelper;
