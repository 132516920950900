import { useSelector } from "react-redux";
import CustomInput from "../../../../components/global/CustomInput";
import { useEffect, useState } from "react";
import { cityStateHelper } from "../../../../utils/helpers/cityStateHelper";
import { validationSchemas } from "../SchedulePickup";

function pickupAddress({ initialValues, values, setFieldValue, errors, touched, setFieldError, validateForm, fetchPatients, patients, loading, ...rest }: any): JSX.Element {
        const { data: states, error } = useSelector((state: any) => state.StateAndCity)
        const [cities, setCities] = useState([])

        function handleChange(input: any, name: string): void {
                const updatedValues = { ...values }
                updatedValues[name] = input
                if (name === 'state') {
                        cityStateHelper(states, setCities, input, values, setFieldValue, 'city')
                        setFieldValue(name, input, true)
                        setFieldValue('stateValue', states.find((state: any) => state.state_code === input)?.name ?? input)
                } else {
                        setFieldValue(name, input, true)
                }


                validateForm(updatedValues)
                        .then((res: any) => {
                                if (Object.keys(res).length == 0)
                                        rest?.setScheduleDisabled(false)
                                else
                                        rest?.setScheduleDisabled(true)

                                rest?.setValidFields(rest?.requiredFields - Object.keys(res).length)


                        })
                        .catch((errors: any) => {
                                rest?.setScheduleDisabled(true)
                        });
        }

        useEffect(() => {

                if (values && values.state) {
                        const foundState = states.find((state: any) => `${state.name} ${state.state_code}`?.includes(values.state))
                        if (foundState) {
                                setCities(foundState.cities)
                        }
                        setFieldValue('stateValue', foundState.name)
                }
        }, [])

        return (

                <div className="focus:outline-primary p-5 my-5 shadow-sm border border-[0.5px] rounded-md flex flex-col hover:border-primary hover:border">

                        <p className="text-royalBlue font-medium text-base">Pickup Address</p>
                        <CustomInput
                                id={'contactName'}
                                key={'contactName'}
                                name={'contactName'}
                                label={'Contact Name'}
                                isInternal
                                value={values.contactName}
                                // onChange={(val: any) => setFieldValue('patient_middleinitial', val)}
                                onChange={(e: any) => handleChange(e.target.value, 'contactName')}
                                required
                                placeholder={'Enter contact name'}
                                errors={errors}
                                touched={touched}
                        />
                        <CustomInput
                                id={'company'}
                                key={'company'}
                                name={'company'}
                                label={'Company'}
                                isInternal
                                value={values.company}
                                // onChange={(val: any) => setFieldValue('patient_middleinitial', val)}
                                onChange={(e: any) => handleChange(e.target.value, 'company')}
                                required
                                placeholder={'Enter company'}
                                errors={errors}
                                touched={touched}
                        />
                        <CustomInput
                                id={'address1'}
                                key={'address1'}
                                name={'address1'}
                                label={'Address'}
                                isInternal
                                value={values.address1}
                                // onChange={(val: any) => setFieldValue('patient_middleinitial', val)}
                                onChange={(e: any) => handleChange(e.target.value, 'address1')}
                                required
                                placeholder={'House No, Street, State, Postal Code'}
                                errors={errors}
                                touched={touched}
                        />
                        <CustomInput
                                id={'address2'}
                                key={'address2'}
                                name={'address2'}
                                label={'Address 2'}
                                isInternal
                                value={values.address2}
                                // onChange={(val: any) => setFieldValue('patient_middleinitial', val)}
                                onChange={(e: any) => handleChange(e.target.value, 'address2')}
                                required={false}
                                placeholder={'House No, Street, State, Postal Code'}
                                errors={errors}
                                touched={touched}
                        />
                        <div className="flex justify-between items-center">
                                <CustomInput
                                        id={'state'}
                                        key={'state'}
                                        name={'state'}
                                        label={'State/Province'}
                                        required={true}
                                        placeholder={'Enter state'}
                                        className={'mr-5'}
                                        // suggestions={!error && states.length > 0 ? states.map((state: any) => { return `${state.name} ${state.state_code}` }) : []}
                                        suggestions={!error && states.length > 0 ? states.map((state: any) => ({ label: state.name, value: state.state_code })) : []}
                                        isInternal
                                        onChange={states && states.length > 0 ? (val: any) => handleChange(val, 'state') : (val: any) => handleChange(val.target.value, 'state')}
                                        errors={errors}
                                        value={values?.stateValue ? values.stateValue : values.state ? states && states.length > 0 && states.find((state: any) => `${state.name} ${state?.state_code}`.includes(values?.state))?.name : values.state}
                                        touched={touched}
                                />
                                <CustomInput
                                        id={'city'}
                                        key={'city'}
                                        name={'city'}
                                        label={'City'}
                                        isInternal
                                        value={values.city}
                                        // suggestions={cities && cities.length > 0 ? cities.map((city: any) => { return city.name }) : []}
                                        suggestions={cities && cities.length > 0 ? cities.map((city: any) => ({ label: city.name, value: city.name })) : []}
                                        onChange={cities && cities.length > 0 ? (val: any) => handleChange(val, 'city') : (val: any) => handleChange(val.target.value, 'city')}
                                        required={true}
                                        placeholder={'Enter city name'}
                                        errors={errors}
                                        touched={touched}
                                />
                        </div>
                        <div className="flex justify-between items-center">
                                <CustomInput
                                        id={'phone'}
                                        key={'phone'}
                                        name={'phone'}
                                        label={'Phone'}
                                        required={true}
                                        placeholder={'000-000-0000'}
                                        type={'tel'}
                                        isInternal
                                        // onChange={(val: any) => setFieldValue('phone', val)}
                                        onChange={(e: any) => handleChange(e.target.value, 'phone')}
                                        errors={errors}
                                        touched={touched}
                                        className={'mr-5'}
                                />
                                <CustomInput
                                        id={'zipCode'}
                                        key={'zipCode'}
                                        name={'zipCode'}
                                        label={'Zip Code'}
                                        required
                                        value={values.zipCode}
                                        placeholder={'Enter zip code'}
                                        errors={errors}
                                        isInternal
                                        // onChange={(val: any) => setFieldValue('zip_code', val)}
                                        onChange={(e: any) => handleChange(e.target.value, 'zipCode')}

                                        touched={touched}
                                />
                        </div>
                </div>
        )
}

export default pickupAddress;