import ApiNotificationData from "../../models/notificationsModels/apiNotificationsData.model";
import NotificationsDataModel from "../../models/notificationsModels/notificationsData.model";
import reduxActionType from "../../models/reduxActionType";
import {
  ApiMediumValue,
  ApiNotificationTypeValue,
  ApiOrganizationMediumSettingsValue,
  ApiUserNotificationTypeValue,
} from "../../services/NotificationSettingsServices";
import {
  FETCHING_NOTIFICATION_MEDIUM,
  FETCHING_NOTIFICATION_MEDIUM_SUCCESS,
  FETCHING_NOTIFICATION_MEDIUM_ERROR,
  ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM,
  ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM_SUCCESS,
  ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM_ERROR,
  USER_FETCHING_NOTIFICATION_MEDIUM,
  USER_FETCHING_NOTIFICATION_MEDIUM_SUCCESS,
  USER_FETCHING_NOTIFICATION_MEDIUM_ERROR,
  USER_FETCHING_NOTIFICATION_TYPE,
  USER_FETCHING_NOTIFICATION_TYPE_SUCCESS,
  USER_FETCHING_NOTIFICATION_TYPE_ERROR,
  FETCHING_NOTIFICATION_TYPE_ERROR,
  FETCHING_NOTIFICATION_TYPE_SUCCESS,
  FETCHING_NOTIFICATION_TYPE,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_ERROR,
  FETCH_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATION_UNREAD_COUNT,
  UPDATE_FILTERS,
  FETCHING_UNREAD_COUNT,
  FETCHING_UNREAD_COUNT_SUCCESS,
  FETCHING_UNREAD_COUNT_ERROR,
  LOGOUT,
  RESET_UNREAD_COUNT,
} from "../Actions/types";

export type NotificationFilters = {
  [key: string]: number | string
}
export interface UserNotificationsState {
  fetching: boolean;
  error: boolean;
  fetchingUnReadCount: boolean
  fetchingUnReadCountError: boolean
  fetchingUnReadCountSuccess: boolean
  fetchingNotifications: boolean
  fetchingNotificationsError: boolean
  notificationTypes: ApiNotificationTypeValue[];
  notificationMediums: ApiMediumValue[];
  organizationNotificationMediums: ApiOrganizationMediumSettingsValue[];
  userNotificationMedium: ApiUserNotificationTypeValue[];
  userNotificationTypes: ApiUserNotificationTypeValue[];
  notificationsData: ApiNotificationData[]
  notificationsList: NotificationsDataModel[]
  unReadCount: number
  fetchedUnReadCount: boolean
  filters: NotificationFilters
}

const initialState: UserNotificationsState = {
  fetching: false,
  error: false,
  fetchingNotifications: false,
  fetchingNotificationsError: false,
  notificationTypes: [],
  notificationMediums: [],
  organizationNotificationMediums: [],
  userNotificationMedium: [],
  userNotificationTypes: [],
  notificationsData: [],
  notificationsList: [],
  unReadCount: 20,
  fetchingUnReadCount: false,
  fetchingUnReadCountError: false,
  fetchingUnReadCountSuccess: false,
  fetchedUnReadCount: false,
  filters: {
    createdAt: ''
  }
};

function NotificationReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case FETCHING_NOTIFICATION_MEDIUM: {
      return {
        ...state,
        fetching: true,
        error: false,
      };
    }
    case FETCHING_NOTIFICATION_MEDIUM_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        notificationMediums: action.payload,
      };
    }
    case FETCHING_NOTIFICATION_MEDIUM_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true,
      };
    }
    case ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM: {
      return {
        ...state,
        fetching: true,
        error: false,
      };
    }
    case ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        organizationNotificationMediums: action.payload,
      };
    }
    case ORGANIZATION_FETCHING_NOTIFICATION_MEDIUM_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true,
      };
    }
    case USER_FETCHING_NOTIFICATION_MEDIUM: {
      return {
        ...state,
        fetching: true,
        error: false,
      };
    }
    case USER_FETCHING_NOTIFICATION_MEDIUM_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        userNotificationMedium: action.payload,
      };
    }
    case USER_FETCHING_NOTIFICATION_MEDIUM_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true,
      };
    }
    case FETCHING_NOTIFICATION_TYPE: {
      return {
        ...state,
        fetching: true,
        error: false,
      };
    }
    case FETCHING_NOTIFICATION_TYPE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        notificationTypes: action.payload,
      };
    }
    case FETCHING_NOTIFICATION_TYPE_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true,
      };
    }
    case USER_FETCHING_NOTIFICATION_TYPE: {
      return {
        ...state,
        fetching: true,
        error: false,
      };
    }
    case USER_FETCHING_NOTIFICATION_TYPE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        userNotificationTypes: action.payload,
      };
    }
    case USER_FETCHING_NOTIFICATION_TYPE_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true,
      };
    }
    case FETCH_NOTIFICATIONS: {
      return {
        ...state,
        fetchingNotifications: true,
        fetchingNotificationsError: false,
      }
    }
    case FETCH_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        fetchingNotifications: false,
        fetchingNotificationsError: true,
      }
    }
    case FETCH_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        fetchingNotifications: false,
        fetchingNotificationsError: false,
        notificationsData: action.payload.notificationsData,
        notificationsList: action.payload.notificationsList
      }
    }
    case FETCHING_UNREAD_COUNT: {
      return {
        ...state,
        fetchingUnReadCount: true,
        fetchingUnReadCountError: false,
        fetchingUnReadCountSuccess: false
      }
    }
    case FETCHING_UNREAD_COUNT_SUCCESS: {
      return {
        ...state,
        fetchingUnReadCount: false,
        fetchingUnReadCountError: false,
        fetchingUnReadCountSuccess: true,
        unReadCount: action.payload,
        fetchedUnReadCount: true
      }
    }

    case FETCHING_UNREAD_COUNT_ERROR: {
      return {
        ...state,
        fetchingUnReadCount: false,
        fetchingUnReadCountError: true,
        fetchingUnReadCountSuccess: false
      }
    }

    case UPDATE_NOTIFICATION_UNREAD_COUNT: {

      return {
        ...state,
        unReadCount: action.payload
      }
    }
    case RESET_UNREAD_COUNT: {
      return {
        ...state,
        unReadCount: 0
      }
    }

    case UPDATE_FILTERS: {
      return {
        ...state,
        filters: action.payload
      }
    }


    case LOGOUT: {
      return initialState
    }

    default:
      return state;
  }
}

export default NotificationReducer;
