/* eslint-disable @typescript-eslint/space-before-function-paren */
import React from 'react'
import { motion } from 'framer-motion'

function AccessDenied(): JSX.Element {
  return <motion.section
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="flex flex-col h-full justify-center items-center">
    {/* <CheckMarkSVG /> */}
    <div className="w-2/6 text-center p-5">
      <p className="text-royalBlue font-medium text-2xl">
        401 | Access Denied
      </p>
    </div>
  </motion.section>
}

export default AccessDenied
