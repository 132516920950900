import * as React from 'react';
import { LOGOUT, TOGGLE_SESSION_STATE } from "../../Store/Actions/types";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect ,useState} from 'react';
import { useDispatch } from 'react-redux';
import Button from '../atomic/Button';
import { useHistory } from 'react-router';

function Logout(){
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState<boolean>(false);
  const { oktaAuth, authState } = useOktaAuth();
  const history=useHistory()

  useEffect(() => {
    try {
      oktaAuth.signOut();
      setVisibility(false);
      dispatch({ type: LOGOUT, payload: true });
    } catch (error) {
      console.log(error);
    }
    history.push('/')
  }, [oktaAuth]);

  return (
    <div className='container m-auto flex flex-col justify-center items-center'>
    <h4 className="text-royalBlue text-2xl md:text-3xl lg:text-3xl font-bold text-center">
PAGE UNDER MAINTANENCE. TO PLACE ONLINE ORDERS FOR CASTLE TESTING, PLEASE VISIT THE OLD PORTAL 
 AND UTILIZE PRIOR LOGIN CREDENTIALS.
</h4>
<Button
    btnType="primary"
    className={'py-1.5 my-3 lg:mr-2 xl:mr-5 2xl:mr-5'}
    onClick={() => window.location.href = 'https://clabsportal.com/castle_portal/'}
    title={'Visit Old portal'}
/>

</div>
  );
}

export default Logout;
