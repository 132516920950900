import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  resetSelectedPhysician,
  updateSelectedPhysician,
} from "../../Store/Actions/OnlineOrderFormActions";
import OrganizationActions from "../../Store/Actions/OrganizationsActions";
import toastMessages from "../../utils/helpers/toastMessage";
import Dropdown from "./Dropdown";
import CustomPopup from "./CustomPopup";
import './styles.css'

function PhysiciansDropDown({
  fetchOrganizationUsers,
  fetchingOrgUsers,
  syncing,
  fetchingOrgUsersError,
}: any): JSX.Element | any {
  const [physicians, setPhysicians] = useState<any[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const { authState } = useOktaAuth();
  const { profile } = useSelector((state: any) => state.Profile);
  const [organizationProfileRoleCheck, setOrganizationProfileRoleCheck] =
    useState<boolean>(false);
  const { selectedPhysician, selectedOrganization } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const dualRoleCOA = profile?.roles?.includes('Physician') && profile?.roles?.includes('Collaborative Order Agreement')
  const dispatch = useDispatch();
  const { organizationData } = useSelector((state: any) => {
    return {
      organizationData: state.Organizations.data,
    };
  });

  function hidePopup(): void {
    setShowPopup(false);
  }
  function RenderMessage(): JSX.Element {
    return (
      <p className="mt-5 text-royalBlue">
        Please select an organization first.
      </p>
    );
  }
  function PhysicianSelect(val: any): void {
    if (val.id === 0) {
      return dispatch(resetSelectedPhysician());
    }
    const selectedPhy = selectedOrganization?.users?.filter((e: any) => {
      return e.id === val.id && e;
    })[0];
    const emptyOrgPhys = organizationData?.users?.filter((e: any) => {
      return e.id === val.id && e;
    });
    // const emptyOrg = organizationData.map((e: any) =>
    //   e?.users?.filter((e: any) => {
    //     return e.id === val.id && e;
    //   })
    // );
    var emptyOrg = organizationData
      ?.map((e: any) =>
        e?.users?.filter((e: any) => e?.roles?.includes("Physician"))
      )
      .flat()
      .find((e: any) => e?.id === val?.id && e);
    {
      selectedOrganization == undefined
        ? dispatch(updateSelectedPhysician(emptyOrg))
        : dispatch(updateSelectedPhysician(selectedPhy));
    }

    // dispatch(updateSelectedPhysician(selectedPhy));
  }

  const totalPhysiciansLength = selectedOrganization?.users?.filter(
    (val: any) => val?.roles?.includes("Physician")
  )?.length;

  useEffect(() => {

    if (selectedOrganization?.id) {
      if (Object.keys(profile).length > 0)
        if (profile?.organizationRoles)
          setOrganizationProfileRoleCheck(
            profile?.organizationRoles[selectedOrganization?.id]?.roles
              ?.length == 1 &&
            profile?.organizationRoles[
              selectedOrganization?.id
            ]?.roles?.includes("Physician")
          );
    } else {
      setOrganizationProfileRoleCheck(false);
    }

    if (selectedOrganization?.users && selectedOrganization.users?.length > 0)
      return;

    if (
      (!fetchingOrgUsers || !!selectedOrganization?.users === false) &&
      !!selectedOrganization?.id
    ) {
      const options = {
        id: selectedOrganization?.id,
        onError: (message: string) =>
          toastMessages.error(
            message ?? "Failed to fetch clinicians, Please refresh the app"
          ),
        config: {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        },
      };
      fetchOrganizationUsers(options);
    }
  }, [selectedOrganization?.id, fetchOrganizationUsers, syncing]);

  function loadMore() {
    const options = {
      id: selectedOrganization?.id,
      onError: (message: string) =>
        toastMessages.error(
          message ?? "Failed to fetch clinicians, Please refresh the app"
        ),
      config: {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      },
    };
    fetchOrganizationUsers(options);
  }

  if (profile?.roles?.length === 1 && profile?.roles?.includes("Physician"))
    return null;
  if (fetchingOrgUsers)
    return (
      <div className="p-2.5 h-[25px] bg-dropDownBorder rounded-md mx-3 w-[120px] animate-pulse" />
    );

  return (
    <>
      <Dropdown
        handleChange={(val: any) => PhysicianSelect(val)}
        isOrg={false}
        selectedValue={
          selectedPhysician === null
            ? "All Clinicians"
            : selectedPhysician?.firstName + " " + selectedPhysician?.lastName
        }
        data={
          totalPhysiciansLength >= 1
            ? selectedOrganization?.users && [
              { id: 0, label: "All Clinicians" },
              ...selectedOrganization?.users
                ?.filter((val: any) => val?.roles?.includes("Physician") && val.isApproved)
                ?.map((e: any) => {
                  return {
                    id: e.id,
                    label: e?.firstName + " " + e?.lastName,
                    city: e?.city,
                    state: e?.state,
                    address: e?.streetAddress,
                  };
                }),
            ]
            : [
              {
                id: 0,
                label: <p>You have no clinicians in this organization</p>,
              },
            ]
        }
        text={"All Clinicians"}
        style={{
          minWidth: physicians == null ? "110%" : "200px",
          maxHeight: physicians == null ? "110px" : "200px",
        }}
        loadMorePhysicians={loadMore}
        disabled={
          (selectedOrganization == null || organizationProfileRoleCheck) && selectedPhysician?.id
            ? true
            : false
        }
        showTooltip={organizationProfileRoleCheck && selectedPhysician?.id}
        tooltipText={organizationProfileRoleCheck ? 'You are not allowed to view other clinicians' : ''}
        stylingPhysician={
          (selectedOrganization == null || organizationProfileRoleCheck) && selectedPhysician?.id
            ? `cursor-not-allowed`
            : ""
        }
        checkOrganizationNull={() =>
          selectedOrganization == null ? setShowPopup(true) : ""
        }
      />
      <CustomPopup
        buttons={[{ onClick: hidePopup, title: "OK", type: "primary" }]}
        visible={showPopup}
        hidePopup={hidePopup}
      >
        <RenderMessage />
      </CustomPopup>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  fetchingOrgUsers: state.Organizations.fetchingOrgUsers,
  fetchingOrgUsersError: state.Organizations.fetchingOrgUsersError,
  syncing: state.Sync.syncing
});

const mapDispatchToProps = {
  fetchOrganizationUsers: OrganizationActions.fetchOrganizationUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysiciansDropDown);
