import React, { Fragment } from 'react'
import MainLayoutType from '../models/mainlayout.type'
import TemplateProvider from './TemplateProvider'
import { Helmet } from 'react-helmet'
import PrivateRoute from '../Router/PrivateRoute'
import CustomNotifier from '../components/global/CustomNotifier'
import useReplay from '../utils/customHooks/useReplay'

function MainLayout({ template, Element, templateClassName, pageTitle, auth, childContainerClassName, socket }: MainLayoutType): JSX.Element {
    const replay = useReplay()
    if (auth) {
        return <PrivateRoute socket={socket}>
            <CustomNotifier />
            <Fragment>
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
                <TemplateProvider
                    childContainerClassName={childContainerClassName ?? ''}
                    className={templateClassName ?? ''}
                    type={template}>
                    {Element}
                </TemplateProvider>
            </Fragment>
        </PrivateRoute>
    }

    return <Fragment>
        <CustomNotifier />
        <Helmet>
            <title>{pageTitle}</title>
        </Helmet>
        <TemplateProvider
            className={templateClassName ?? ''}
            childContainerClassName={childContainerClassName ?? ''}
            type={template}>
            {Element}
        </TemplateProvider>
    </Fragment>
}

export default MainLayout
