import * as React from "react"

const PageForward = (props: any) => {
  const [hover, setHover] = React.useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={7.811}
      height={14.121}
      {...props}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <path
        data-name="Path 5085"
        d="m1.061 13.061 6-6-6-6"
        fill="none"
        stroke={hover ? "#008080" : props.stroke ?? "#172b4d"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  )
}

export default PageForward
