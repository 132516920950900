import { SVGProps } from 'react'

const OrganizationExistSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={102} height={102} {...props}>
        <defs>
            <linearGradient
                id="a"
                x1={0.129}
                y1={0.163}
                x2={0.867}
                y2={0.875}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#31bdbf" />
                <stop offset={1} stopColor="#3f76a5" />
            </linearGradient>
        </defs>
        <g data-name="Group 15196">
            <g data-name="Component 6407 \u2013 4">
                <g data-name="Ellipse 667" stroke="#18b5b7" fill="url(#a)">
                    <circle cx={51} cy={51} r={51} stroke="none" />
                    <circle cx={51} cy={51} r={50.5} fill="none" />
                </g>
            </g>
            <g data-name="Group 14237" fill="none" stroke="#fff" strokeWidth={3}>
                <path
                    data-name="Line 1302"
                    strokeLinejoin="round"
                    d="M23.234 70.942h54.889"
                />
                <path
                    data-name="Line 1304"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M44.026 54.047h2.218"
                />
                <path
                    data-name="Line 1305"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M44.026 62.91h2.218"
                />
                <path
                    data-name="Line 1307"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M55.115 54.047h2.218"
                />
                <path
                    data-name="Line 1308"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M55.115 62.91h2.218"
                />
                <path
                    data-name="Path 5233"
                    d="M35.155 70.942V35.49a4.433 4.433 0 0 1 4.435-4.431h22.178a4.433 4.433 0 0 1 4.435 4.431v35.452"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    data-name="Path 5234"
                    d="M23 70.943v-21.05c0-1.453.745-2.631 1.663-2.631h8.315c.918 0 1.663 1.178 1.663 2.631v21.05"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    data-name="Path 5235"
                    d="M66.897 70.942V50.833c0-1.389.745-2.514 1.663-2.514h8.315c.918 0 1.663 1.125 1.663 2.514v20.11"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    data-name="Line 1309"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M71.886 54.601h2.218"
                />
                <path
                    data-name="Line 1310"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M71.886 59.587h2.218"
                />
                <path
                    data-name="Line 1311"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M71.886 64.572h2.218"
                />
                <g data-name="Group 14125" strokeLinecap="round" strokeLinejoin="round">
                    <path data-name="Line 1314" d="M27.712 53.493h2.218" />
                    <path data-name="Line 1313" d="M27.712 58.478h2.218" />
                    <path data-name="Line 1312" d="M27.712 63.464h2.218" />
                </g>
                <g data-name="Ellipse 603" transform="translate(43.194 33.552)">
                    <circle cx={7.485} cy={7.485} r={7.485} stroke="none" />
                    <circle cx={7.485} cy={7.485} r={5.985} />
                </g>
                <path
                    data-name="Line 1315"
                    strokeLinecap="round"
                    d="M50.679 38.537v4.985"
                />
                <path
                    data-name="Line 1316"
                    strokeLinecap="round"
                    d="M53.174 41.03h-4.99"
                />
            </g>
        </g>
    </svg>
)

export default OrganizationExistSVG
