/* eslint-disable @typescript-eslint/space-before-function-paren */
import { AnimatePresence, motion } from 'framer-motion'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Header from '../../components/global/Header'
import { setButtonState } from '../../Store/Actions/ButtonStateActions'
import { useOktaAuth } from '@okta/okta-react'
import DataTable from '../../components/DataTable/DataTable'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import SelectedCheckMark from '../../assets/svg/SelectedCheckMark'
import {
  header,
  ITableHeaderProps
} from '../../components/DataTableHeader/DataTableHeader'
import Button from '../../components/atomic/Button'
import TestOrdersTable from '../../components/TestOrders/TestOrdersTable'
import DashboardActions from '../../Store/Actions/DashboardActions'
import toastMessages from '../../utils/helpers/toastMessage'
import DashboardCallModel from '../../models/DashboardCallModel'

function FilterItem({ selected, label, onClick }: any): JSX.Element {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex px-4 py-2 hover:bg-sideMenuHoverBg items-center w-full"
    >
      <div className="w-1/6 mr-2">{selected && <SelectedCheckMark />}</div>
      <p className="w-5/6 capitalize">{label}</p>
    </button>
  )
}

function OverviewCard({ count, onClick, title, title2, countColor, loading }: any): JSX.Element {
  if (loading)
    return <div className="flex justify-center mt-7 mb-3 min-w-[19%] border rounded-lg shadow bg-white max-w-[207px] text-center">
      <div className="">
        <div className="px-[69px]">
          <h5 className={`text-5xl bg-dropDownBorder animate-pulse rounded-md text-dropDownBorder mt-6 mb-3 font-AvenirNextLTPro-Bold font-semibold`}>
            {count}
          </h5>
          <p className="text-sm capitalize bg-dropDownBorder animate-pulse rounded-md mb-2 text-dropDownBorder mb-1 font-AvenirNext-DemiBold font-semibold">
            {title}
          </p>
          <div className='py-2 mb-4 rounded-lg bg-dropDownBorder animate-pulse' />
        </div>
      </div>
    </div>
  return <div className="flex px-2 justify-center mt-7 mb-3 min-w-[19%] border rounded-lg shadow bg-white max-w-[207px] text-center">
    <div className="">
      <div className="">
        <h5 className={`text-5xl ${countColor ?? 'text-royalBlue'} mt-6 mb-3 font-semibold`}>
          {count}
        </h5>
        <p className="text-sm  text-royalBlue mb-1 font-semibold">
          {title}
        </p>
        {title2 && <p className="text-sm  text-royalBlue mb-1 font-semibold">
          {title2}
        </p>}
        <Button
          btnType='transparent'
          onClick={onClick}
          className={`text-sm hover:text-[#78dbdc] mb-6`}
          title="View orders"
        />
        {/* <button>
          <p className="text-xs text-[#31BDBF] mb-7 font-AvenirNext-DemiBold font-semibold">
            VIEW ORDERS
          </p>
        </button> */}
      </div>
    </div>
  </div>
}

function OverviewLoader(): JSX.Element {
  const profile = useSelector((state: any) => state.Profile)
  let isPLUUser = (profile?.profile?.roles?.includes('PathLab User') || profile?.profile?.roles?.includes('PathLab User')) && (profile?.roles?.length == 1 || profile?.profile?.roles?.length == 1)
  const CardsList = [
    {
      title: 'Unsubmitted Orders',
      count: 1,
      onClick: () => { },
      countColor: 'text-[#4F6CFE]'
    },
    {
      count: 1,
      title: 'Pending Approval',
      onClick: () => { },
      countColor: 'text-[#B718B5]'
    },
    {
      count: 1,
      title: 'Pending Results',
      onClick: () => { },
      countColor: 'text-[#AD4FFE]'
    },
    {
      count: 1,
      title: 'Completed Orders',
      title2: '( in the last 72 hours )',
      onClick: () => { },
      countColor: 'text-[#1BC472]'
    },
    {
      count: 1,
      title: 'Submitted',
      onClick: () => { },
      countColor: 'text-[#4F6CFE]'
    },

  ]
  return <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
    className={`flex flex-wrap ${isPLUUser ? 'gap-10' : 'justify-between'} px-5`}>
    {CardsList.filter((card: any) => {
      let pluCardsOnly = ['Completed Orders', 'Submitted']
      if (isPLUUser) {
        return pluCardsOnly.includes(card.title)
      }
      return card.title !== 'Submitted'
    }).map((card: any) => (<OverviewCard loading key={card.title} {...card} />))}
  </motion.div>
}

function OverViewData({ submitted, inComplete, pendingApproval, newReports, pendingDocumentReview, isPLUUser, pendingResults, completedOrders }: any): JSX.Element {
  const history = useHistory()

  const CardsList = [
    {
      title: 'Unsubmitted Orders',
      count: inComplete,
      onClick: () => handleClick('inComplete'),
      countColor: 'text-[#4F6CFE]'
    },
    {
      count: pendingApproval,
      title: 'Pending Approval',
      onClick: () => handleClick('pendingApproval'),
      countColor: 'text-[#B718B5]'
    },
    {
      count: pendingResults,
      title: 'Pending Results',
      onClick: () => handleClick('pendingResults'),
      countColor: 'text-[#AD4FFE]'
    },
    {
      count: completedOrders,
      title: 'Completed Orders',
      title2: '( in the last 72 hours )',
      onClick: () => handleClick('completedOrders'),
      countColor: 'text-[#1BC472]'
    },
    {
      count: submitted,
      title: 'Submitted',
      onClick: () => handleClick('submitted'),
      countColor: 'text-[#4F6CFE]'
    },

  ]
  const queryParams = {
    // submittedToday: 'Submitted Today',
    // newReports: 'New Reports',
    // pendingResults: 'Pending Results'
    inComplete: 'In Complete',
    pendingDocumentReview: 'p.d.r',
    pendingApproval: 'pending approval',
    pendingResults: 'pending results',
    completedOrders: `completed`,
    PDR: 'p.d.r',
    submitted: 'submitted'
  }

  function handleClick(type: string) {
    type ObjectKey = keyof typeof queryParams
    const myKey = type as ObjectKey
    if (type === 'pendingApproval') return history.replace('/approvals')
    history.replace(`/test-orders/?status=${queryParams[myKey]} `)
  }

  return <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
    className={`flex flex - wrap ${isPLUUser ? 'gap-10' : 'justify-between'} px-5`}>
    {CardsList.filter((card: any) => {
      let pluCardsOnly = ['Completed Orders', 'Submitted']
      if (isPLUUser) {
        return pluCardsOnly.includes(card.title)
      }
      return card.title !== 'Submitted'
    }).map((card: any) => (<OverviewCard key={card.title} {...card} />))}
  </motion.div>
}

function DashboardScreen({ hideButtons, fetching, error, data, fetchDashboard, selectedPhysician, selectedOrganization, selectedPathLab }: { hideButtons: boolean, fetching: boolean, error: boolean, data: any, fetchDashboard: any, selectedPhysician: any, selectedOrganization: any, selectedPathLab: any }) {
  const { authState } = useOktaAuth()
  const profile = useSelector((state: any) => state.Profile)
  let isPLUUser = (profile?.profile?.roles?.includes('PathLab User') || profile?.profile?.roles?.includes('PathLab User')) && (profile?.roles?.length == 1 || profile?.profile?.roles?.length == 1)
  const dispatch = useDispatch()

  useEffect(() => {
    let isPLU = profile?.profile?.roles?.includes('PathLab User')
    dispatch(setButtonState('create order'))


    const options: DashboardCallModel = {
      onError: (message: string) => toastMessages.error(message ? message : 'Something Went wrong'),
      config: { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken} ` } },
      pathLabId: selectedPathLab?.id ? selectedPathLab.id : null,
      organizationId: selectedOrganization?.id ? selectedOrganization.id : null,
      physicianId: selectedPhysician?.id ? selectedPhysician.id : null,
      isPLU
    }

    fetchDashboard(options)

  }, [selectedOrganization?.id, selectedPhysician?.id, selectedPathLab?.id])

  return (
    <AnimatePresence exitBeforeEnter>

      {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      > */}
      <Header isInternal title={'Dashboard'} />
      {/* </motion.div> */}

      {fetching ?
        <OverviewLoader /> :
        <OverViewData
          // submittedToday={data?.submittedToday}
          isPLUUser={isPLUUser}
          pendingDocumentReview={data?.PDR}
          inComplete={data?.inComplete}
          pendingResults={data?.pendingResults}
          pendingApproval={data?.pendingApproval}
          completedOrders={data?.completedOrders}
          submitted={data?.submitted}
        // newReports={data?.newReports}
        />}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="col-span-5">
        <div className='p-5 flex-none m-auto overflow-auto'>
          <p className='py-1.5 text-royalBlue'>{isPLUUser ? 'Orders Table' : 'Orders Requiring Action'}</p>
          <TestOrdersTable screen='dashboard' hideButtons />
        </div>
      </motion.div>

    </AnimatePresence>
  )
}

const mapStateToProps = (state: any) => ({
  fetching: state.Dashboard.fetching,
  error: state.Dashboard.error,
  data: state.Dashboard.data,
  selectedOrganization: state.OnlineOrderForm.selectedOrganization,
  selectedPhysician: state.OnlineOrderForm.selectedPhysician,
  selectedPathLab: state.OnlineOrderForm.selectedPathLab,
})

const mapDispatchToProps = {
  fetchDashboard: DashboardActions.fetchDashboard
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen)
