import { Form, Formik } from "formik";
import Button from "../atomic/Button";
import CustomInput from "../global/CustomInput";
import { useHistory } from "react-router-dom";
import SetPasswordFormValues from "../../models/forms/setpasswordformvalues.interface";
import SetPasswordValidation from "../../validations/setpasswordvalidation";

function SetPasswordForm() {
  const history = useHistory();
  const officeManagerFields = [
    {
      id: "password",
      name: "password",
      label: "Password",
      placeholder: "Enter password",
      required: true,
    },
    {
      id: "confirm_password",
      name: "confirm_password",
      label: "Confirm New Password",
      placeholder: "Enter password",
      required: true,
    },
  ];
  const initialValues: SetPasswordFormValues = {
    password: "",
    confirm_password: "",
  };

  function onSubmit(data: SetPasswordFormValues) {
    if (data.password !== data.confirm_password) {
      return;
      // alert("Password's do not match");
    }
    history.push("/login");
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SetPasswordValidation}
      onSubmit={onSubmit}
      render={({ errors, touched }) => (
        <Form className="flex flex-col justify-around my-2 p-0 m-0">
          <div>
            {officeManagerFields.map((field, index) => (
              <CustomInput
                id={field.id}
                key={field.id}
                name={field.name}
                type={"password"}
                label={field.label}
                required={field.required}
                placeholder={field.placeholder}
                errors={errors}
                touched={touched}
                className={index == 0 ? "mt-0" : ""}
              />
            ))}
            {/* <div className="flex justify-between my-6">
                    <CustomInput
                        id={'rememberPassword'}
                        key={'rememberPassword'}
                        name={'rememberPassword'}
                        label={'Save Password'}
                        required={false}
                        placeholder={''}
                        errors={errors}
                        touched={touched}
                        type='checkbox'
                    />
                    <button className="border-0 text-primary underline">
                        Forgot password
                    </button>
                </div> */}
            <Button
              type={"submit"}
              btnType="primary"
              title={"Reset password"}
              className={"w-full my-3"}
              onClick={() => {}}
            />
          </div>
        </Form>
      )}
    />
  );
}

export default SetPasswordForm;
