import { Fragment, useEffect, useRef, useState } from 'react';
import SvgFile from '../assets/svg/SVGFile';
import LineVertical from '../assets/svg/LineVertical';
import Button from './atomic/Button';
import { AnimatePresence, motion } from 'framer-motion'
import toastMessages from '../utils/helpers/toastMessage';
import http from '../Store/API/HttpMethods';
import { useOktaAuth } from '@okta/okta-react';
import { BlockBlobClient } from '@azure/storage-blob';
import { useDispatch, useSelector } from 'react-redux';
import PortalUsersActions from '../Store/Actions/PortalUsersActions';
import { useHistory, useParams } from 'react-router-dom';
import DownloadHelper from '../utils/helpers/fileDownloader';
import ProfileActions from '../Store/Actions/ProfileActions';
import CloseBigIcon from '../assets/svg/CloseBigIcon';
import CustomFileUploader from './global/CustomFIleUploader';
import DocumentLoadingIndicator from './global/LoadingIndicators/DocumentSectionLoadingIndicator';
import MultipleFileUploader from './global/MultipleFileUploader';


function TestPdfDownloader({ filename, blobUrl, title, subtitle }: { filename: string, blobUrl: string, title: string, subtitle: string }) {
  const [downloading, setDownloading] = useState<boolean>(false)
  function handleClick() {
    setDownloading(true)
    DownloadHelper.fileDownloader(blobUrl, filename, () => {
      setDownloading(false)
    }, (error: any) => {
      setDownloading(false)
      toastMessages.error(error.message ?? `Failed downloading ${title} ${subtitle}`)
    })
  }
  return <div className='px-1 flex flex-col'>
    <p className='text-[#647188] text-sm overflow-hidden overflow-ellipsis max-w-[150px]'>
      {title}
    </p>
    <div className='mt-auto'>
      <Button
        btnType="secondary"
        title={downloading === true ? 'Downloading' : 'Download'}
        submitting={downloading}
        disabled={downloading}
        className={` !text-sm mr-5 focus:border-[#647188] outline-[#647188] bg-white my-3`}
        onClick={handleClick}
      />
    </div>
  </div>
}
function getExt(filename: string): any {
  const ext = filename?.split('.')?.pop()
  if (ext === filename) return ''
  return ext
}
function ViewFileStep({ file, fileUrl }: { file: any, fileUrl: string }): JSX.Element {

  return <embed src={URL.createObjectURL(file)} width="250" height={"250"} className="h-[80vh] w-full object-contain" />

}

function UploadingStep({ file, setFileUrl, setStep, setButtonDisabled, setFilePath }: any): JSX.Element {
  const [uploading, setUploading] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const { authState } = useOktaAuth()
  const [resetFiles, setResetFiles] = useState<number>(1)
  async function uploadToAzure(url: string, filePath: string, inputFile: any): Promise<void> {

    const blockBlobClient = new BlockBlobClient(url)

    try {
      const response = await blockBlobClient.uploadData(inputFile, {
        blobHTTPHeaders: { blobContentType: inputFile.type, }, onProgress(uploadedBytes) {
          setProgress((uploadedBytes.loadedBytes / file.size) * 100)
          setButtonDisabled((uploadedBytes.loadedBytes / file.size) * 100 >= 100 ? false : true)
          setStep((uploadedBytes.loadedBytes / file.size) * 100 >= 100 ? 3 : 2)
          setFileUrl((uploadedBytes.loadedBytes / file.size) * 100 >= 100 ? url : '')
          setFilePath((uploadedBytes.loadedBytes / file.size) * 100 >= 100 ? filePath : '')
        },
      })

    } catch (error: any) {
      setUploading(false)
      toastMessages.error(`${inputFile.name} uploading failed`)
    }
  }

  async function getPresignedUrlAndUpload(inputFile: any): Promise<void> {
    if (!file) return
    if (uploading) return

    setUploading(true)
    try {
      const extension = getExt(inputFile.name)
      const response = await http.getPresignedUrl(`v1/pre-signed-url/documents/${file.name.replace(/ /g, "_")}`, { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } })
      const { url, filepath } = response

      if (url) {
        return await uploadToAzure(url, filepath, inputFile)
      }


    } catch (error: any) {
      setUploading(false)
      if (error.response) {
        toastMessages.error(error.response.data.message)
      } else {
        toastMessages.error(error.message)
      }
    }
    setUploading(false)
  }

  useEffect(() => {
    getPresignedUrlAndUpload(file)
  }, [])

  return <div className="flex flex-col bg-[#EDEFF1] items-center justify-center h-[80vh] rounded-md border border-[#D0D4DB]">
    <div className=''>
      <SvgFile />
    </div>
    <p className='text-royalBlue text-lg mt-5'>{file?.name}</p>
    <div className='w-full my-7 px-20'>
      <p className="transition-all" style={{ marginLeft: `${Math.round(progress)}%` }}>{Math.round(progress)}%</p>
      <div className="w-full bg-[#DCDFE4] rounded-full overflow-hidden">
        <div className="bg-gradient-to-r from-[#18B5B7] to-[#084E8B] rounded-full p-1 transition-all" style={{ width: `${Math.round(progress)}%` }} />
      </div>
    </div>
  </div>
}

function FileDownloadAndUploadStep({ handleFileSelection, setButtonDisabled, documents, setDocuments, isUpdate }: any): JSX.Element {
  const { authState } = useOktaAuth()
  const [testData, setTestData] = useState([]);
  const [fetchingDocument, setFetchingDocument] = useState(true)
  const inputRef = useRef<any>()


  async function handleGetDocuments() {

    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`
      }
    }
    setFetchingDocument(true)
    try {
      const { data } = await http.viewSofs('/v1/documents?type=sof', config)
      setTestData(data)
      setFetchingDocument(false)
    } catch (error: any) {
      toastMessages.error('Failed to open documents')
      setFetchingDocument(false)
    }
  }

  const validateFile = (ext: any) => {
    // const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'png', 'jpg', 'jpeg', 'doc', 'docm', 'dot', 'docx', 'pdf']
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'png', 'jpg', 'jpeg', 'pdf']

    if (!validTypes.includes(ext)) {
      toastMessages.error(`Invalid file format`)
      return false
    }
    return true
  }

  function handleChange(e: any): any {
    e.preventDefault()
    e.stopPropagation()
    const file = e?.target?.files[0]

    const sizeInMB = file.size / 1024 / 1024
    if (sizeInMB > 50) {
      toastMessages.error(`File size limit is being exceeded`)

      // setFieldError(name, 'File size limit is being exceeded')
      return e.target.value = ''
    }
    if (validateFile(getExt(file.name)?.toLowerCase())) {
      handleFileSelection(file)
    }
  }

  function handleUpload(e: any): void {
    inputRef?.current?.click()
  }
  async function handleDrop(e: any) {
    e.preventDefault()
    const file = e?.dataTransfer?.files[0]

    const sizeInMB = file.size / 1024 / 1024
    if (sizeInMB > 50) {
      toastMessages.error(`File size limit is being exceeded`)
      return e.target.value = ''
    }
    if (validateFile(getExt(file.name)?.toLowerCase())) {
      handleFileSelection(file)
    }
  }

  useEffect(() => {
    handleGetDocuments()
  }, [])

  return <div className="flex flex-wrap bg-[#EDEFF1] items-center justify-center rounded-md border border-[#D0D4DB]">
    <div className="flex flex-col items-center justify-center overflow-auto">
      <div className='mt-12'>
        <SvgFile />
      </div>
      <p className="my-6 text-center text-base text-[#647188]">Please fill out PDF format to upload SOF(s)</p>
      <div className='flex flex-wrap align-items-center justify-center'>
        {fetchingDocument ? <DocumentLoadingIndicator /> : testData.map((test: any) => (<TestPdfDownloader key={test.id} {...test} />))}
      </div>
      <LineVertical />
      <p className=' text-center text-base text-[#647188]'>OR</p>
      <LineVertical />
      {isUpdate ? <Fragment><p className='text-center text-base text-[#647188] px-28 mt-5'>Please upload SOF. The document
        format must be PDF/JPEG/PNG with
        a maximum file size of 50MB.</p>
        <div className={`mt-5 flex  flex-col `}
          onDragOver={e => e.preventDefault()}
          onDragEnter={e => e.preventDefault()}
          onDragLeave={e => e.preventDefault()}
          onDrop={handleDrop}
        >      <Button
            btnType="secondary"
            onClick={handleUpload}
            type="button"
            title={'CHOOSE FILE'}
            className={'mt-2 mb-5 bg-white py-[5px] px-[15px]'}
          />
          <input
            type='file' id='file'
            name={'sof'}
            // accept="image/*,.doc,.pdf"
            accept=".png, .jpeg,.jpg, .pdf, .doc"
            ref={inputRef}
            // onChange={onChange}
            onChange={handleChange}
            style={{ display: 'none' }} />

        </div>
      </Fragment> :
        <MultipleFileUploader
          title="Please upload SOF. The document
        format must be PDF/JPEG/PNG with
        a maximum file size of 50MB."
          documents={documents}
          uploadButtonTitle={'Upload File(s)'}
          chooseFilesButtonText="Choose File(s)"
          setButtonDisabled={setButtonDisabled}
          setDocuments={setDocuments}
        />
      }
    </div>
  </div>
}

function UploadFlow({ step, fileUrl, documents, setStep, setButtonDisabled, isUpdate, setFileUrl, setFilePath, setDocuments }: any): JSX.Element {
  const [file, setFile] = useState<any>(null)

  if (step === 3)
    return <ViewFileStep fileUrl={fileUrl} file={file} />

  if (step === 2)
    return <UploadingStep file={file} setButtonDisabled={setButtonDisabled} setFileUrl={setFileUrl} setFilePath={setFilePath} setStep={setStep} />

  return <FileDownloadAndUploadStep
    documents={documents}
    isUpdate={isUpdate}
    setButtonDisabled={setButtonDisabled}
    setDocuments={setDocuments}
    handleFileSelection={(file: any) => {
      setFile(file)
      setStep(2)
    }} />
}

function CreateSOF({ createSof, setIsCreateSofOpen, userOrganizationId, closeSOF, isUpdate, isProfileCompletion, setProfileFilePath, sofID }: any) {
  const [step, setStep] = useState<number>(1)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
  const { authState } = useOktaAuth()
  const dispatch: any = useDispatch()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [fileUrl, setFileUrl] = useState<string>('')
  const [filePath, setFilePath] = useState<string>('')
  const { id } = useParams<{ id: string }>();
  const { location } = useHistory()
  const { profile } = useSelector((state: any) => state.Profile)
  const [documents, setDocuments] = useState<any[]>([])
  const isUserProfile = location.pathname === '/profile'

  async function handleCreateSOF() {
    if (isProfileCompletion) {
      setProfileFilePath(filePath)
      closeSOF()
      return
    }
    if (submitting) return
    setSubmitting(true)

    try {
      let body = null

      if (isUpdate) {
        body = { userId: isUserProfile ? `${profile.id}` : `${id}`, blobUrl: filePath }
      } else {
        body = { userId: isUserProfile ? `${profile.id}` : `${id}`, documents: documents.map((item: any) => item.filePath) }
      }

      const config = { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } }
      const response = isUpdate ? await http.updateSOF(`v1/sof/update-sof/${sofID}`, body, config) : await http.createSOF('v1/sof/create-sof', body, config)

      if (response.statusCode === 200) {

        const options = {
          accessToken: authState?.accessToken?.accessToken,
          onSuccess: () => {
          },
          onError: (message: string) => {
            toastMessages.error(message)
          }
        }
        if (isUserProfile) {
          dispatch(ProfileActions.fetchprofile(options))
        } else {
          dispatch(PortalUsersActions.fetchPortalUserById(id, {
            headers: {
              Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
            },
          }))
        }
        setSubmitting(false)
        closeSOF()
      }
    } catch (error: any) {
      setSubmitting(false)
      toastMessages.error('Something went wrong please try again')
    }
  }


  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className="fixed z-[1000] inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          <div className="fixed inset-0 bg-[#172B4D] bg-opacity-60"></div>
          <div className="relative bg-white w-11/12 md:w-3/4 lg:w-1/2 xl:w-[75%] rounded-lg shadow-lg flex flex-col mx-auto">

            <div className="p-6 flex-grow">
              <div className="flex justify-between items-center mb-4">
                <p className="text-md font-medium text-[#172B4D]">{isUpdate ? 'Update' : 'Create'} SOF</p>
                <div className="flex">
                  <button
                    type="button"
                    onClick={() => {
                      setIsCreateSofOpen(false)
                      closeSOF()
                    }}>
                    <CloseBigIcon />
                  </button>
                </div>
              </div>
              <UploadFlow
                step={step}
                fileUrl={fileUrl}
                documents={documents}
                setStep={setStep}
                isUpdate={isUpdate}
                setButtonDisabled={setButtonDisabled}
                setFileUrl={setFileUrl}
                setFilePath={setFilePath}
                setDocuments={setDocuments}
              />
              <div className='flex justify-end'>
                <Button
                  btnType="primary"
                  onClick={handleCreateSOF}
                  title={isUpdate ? 'Update' : 'CREATE'}
                  submitting={submitting}
                  type="button"
                  disabled={buttonDisabled || documents.length == 0}
                  className={'mt-6 py-[5px] px-[15px] mb-1'} />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default CreateSOF;