/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-undef-init */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import { useOktaAuth } from '@okta/okta-react'
import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import http from '../../../../Store/API/HttpMethods'
import validationHelper from '../../../../utils/helpers/orderFormValidationHelper'
import toastMessages from '../../../../utils/helpers/toastMessage'
import CustomInput from '../../../global/CustomInput'
import CustomPopup from '../../../global/CustomPopup'
import lodash from 'lodash'
import { fetchPatients, resetSelectedTest } from '../../../../Store/Actions/OnlineOrderFormActions'
import { InView } from 'react-intersection-observer'
import scrollHelper from '../../../../utils/helpers/scrollHelper'
import dataConstants from '../../../../constants/dataConstants'
import moment from 'moment'

export const PatientKeys: any = {
    firstName: 'patient_firstname',
    id: 'patientId',
    middleName: 'patient_middleinitial',
    lastName: 'patient_lastname',
    birthDate: 'patient_dob',
    gender: 'patient_gender',
    address: 'patient_address',
    city: 'patient_city',
    state: 'patient_state',
    zipcode: 'patient_zip_code',
    email: 'patient_email',
    phone: 'patient_phone',
    ssn: 'patient_ssn',
    mrn: 'patient_mrn'
}

interface PersonalInfoSectionProps {
    values: any
    setFieldValue: any
    setFieldError: any,
    errors: any
    touched: any
    patientType: string
    InputFields: any
    validateForm: (options: any) => any
    setValidSections: any
    enableSubmit: any
    disableSubmit: any
    patients: any[]
    setSelectedTab: (options: any) => any
    loading: boolean
    fetchPatients: (options: any) => any
    fetchingPatients?: boolean
    isUpdate?: any,
    error: any,
    rest?: any,
}

function PersonalInfoSection({ values, setFieldValue, setFieldError, errors, touched, patientType, InputFields, validateForm, setValidSections, enableSubmit, disableSubmit, patients, setSelectedTab, loading, fetchPatients, fetchingPatients, error, isUpdate, rest, /* handleDataValidation */ }: PersonalInfoSectionProps): JSX.Element {
    const { selectedTest } = useSelector((state: any) => state.OnlineOrderForm)
    const { authState } = useOktaAuth()
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [showFirstNameSuggestions, setShowFirstNameSuggestions] = useState<boolean>(false)
    const { selectedOrganization } = useSelector((state: any) => state.OnlineOrderForm)
    const { state }: any = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const { id } = useParams<any>();
    const date = new Date()
    const [searching, setSearching] = useState<boolean>(false)
    // const dateOffset = (24 * 60 * 60 * 1000) * 1
    // const maxDate = date.setTime(date.getTime())
    const maxDate = date
    const [orderId, setOrderId] = useState<string>('')

    function handleMouseScroll(inView: boolean) {
        scrollHelper.handleInView(inView, setSelectedTab, rest.firstScroll, 'patient info')

        // if (!inView && rest.touchedSections.includes('patient info'))
        //     Object.keys(PatientKeys).forEach((key) => {
        //         console.log({ rest })
        //         rest.setFieldTouched(PatientKeys[key], true, true)
        //     })
    }

    function viewTable(): void {
        history.replace('/test-orders')
    }

    function viewOrder(): void {
        if (orderId !== '') {
            history.replace(`/test-orders/summary/${orderId}`)
        }
    }

    function showAlertMessage(): void {
        setShowPopup(true)
    }

    function handleStay(): void {
        setShowPopup(false)
        const section: any = document.getElementById('select test')
        section.scrollIntoView({ behavior: 'smooth', block: 'start' })
        setSelectedTab('select test')
        dispatch(resetSelectedTest())
        setFieldValue('selectedTest', null)
        setValidSections((prevState: any) => prevState.filter((item: any) => item !== 'select test'))
    }
    function resetPatientInfo(): void {
        const updatedValues = { ...values }
        setFieldValue('patientNotFound', false)
        Object.keys(PatientKeys).forEach((field: string): void => {
            setFieldValue(PatientKeys[field], '')
            updatedValues[PatientKeys[field]] = ''
            updatedValues['patientNotFound'] = false
            validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'patient info', enableSubmit, disableSubmit)
        })
    }
    useEffect(() => {
        if (values.patientId && patientType === 'existing' && !(!!state?.orderValue && !values?.selectedTest?.id)) {
            detectPending(values.patientId)
        }
    }, [selectedTest.id])

    useEffect(() => {
        if (values.patientType) {
            if (values.patientType !== patientType) {
                resetPatientInfo()
            }
        }
    }, [patientType])
    async function detectPending(patientId: string): Promise<void> {
        try {
            const { data } = await http.checkPendingOrders(`/v1/patients/pending-order?patientId=${patientId}&testTypeId=${!!state?.orderValue ? values?.selectedTest?.id : selectedTest?.id}${isUpdate ? `&orderId=${id}` : ''}`, {
                headers: {
                    Authorization: `Bearer ${authState?.accessToken?.accessToken}`
                }
            })
            const { isExist, orderId } = data

            if (isExist) {
                showAlertMessage()
                setOrderId(orderId)
                disableSubmit()
            }
        } catch (error: any) {
            toastMessages.error(error.message ?? error)
        }
    }

    function handleAutoFill(input: any, validationHelper: any, name: string): void {
        let foundPatient: any = undefined
        let updatedValues: any = null
        updatedValues = { ...values }
        updatedValues[name] = input

        if (input !== '') {
            foundPatient = patients?.find((patient: any) => input?.includes(patient?.id))
            if (foundPatient) {
                Object.keys(foundPatient).forEach((field: string): string => {
                    if (field === 'birthDate') {
                        setFieldValue(PatientKeys[field], moment(foundPatient[field]).format('MM-DD-YYYY'))
                        updatedValues[PatientKeys[field]] = moment(foundPatient[field]).format('MM-DD-YYYY')
                    } else {
                        setFieldValue(PatientKeys[field], foundPatient[field])
                        updatedValues[PatientKeys[field]] = foundPatient[field]
                    }
                    rest?.setFieldTouched(PatientKeys[field])
                    return field
                })
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                detectPending(foundPatient.id)
                validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'patient info', enableSubmit, disableSubmit)
            }
        } else {
            resetPatientInfo()
        }
    }
    const debounceSearch = useCallback(lodash.debounce(searchPatient, 700), [])

    async function searchPatient(input: any): Promise<void> {
        const options = {
            onError: (message: string) => {
                toastMessages.error(message)
                setFieldError('patient_firstname', 'Patient not found')
                setFieldValue('patientNotFound', true)
                setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'patient info'))
                setSearching(false)
                setShowFirstNameSuggestions(false)
            },
            onSuccess: () => {
                setSearching(false)
                // setFieldValue('patientNotFound', false)
                setShowFirstNameSuggestions(true)
            },
            onEmpty: () => {
                disableSubmit(true)
                setFieldError('patient_firstname', 'Patient not found')
                setValidSections((prevState: any) => prevState.filter((item: string) => item !== 'patient info'))
                setSearching(false)
                setFieldValue('patientNotFound', true)
                setShowFirstNameSuggestions(false)
            },
            orgId: selectedOrganization?.id ?? '00g697s5s34SqUjzX5d7',
            name: input,
            // orgId: '00g6bk8hd92DAtN545d7',
            // name: 'SMASH CLOUD',
            config: {
                headers: {
                    Authorization: `Bearer ${authState?.accessToken?.accessToken}`
                }
            }
        }
        if (loading === false) {
            fetchPatients(options)
        }
    }

    function handleFirstName(e: any): void {
        setSearching(true)
        setFieldValue('patientNotFound', false)

        if (e.target) {
            let updatedValues = { ...values }
            updatedValues['patient_firstname'] = e.target.value
            setFieldValue('patient_firstname', e.target.value)
            setFieldValue('patientType', patientType)
            validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'patient info', enableSubmit, disableSubmit)
            debounceSearch(e.target.value)
        } else if (patients.length > 0) {
            let isObject = typeof (e) === 'object'
            setFieldValue('patient_firstname', isObject ? e?.firstName : e)
            handleAutoFill(isObject ? e.id : e, validationHelper, 'patient_firstname')
            debounceSearch(isObject ? e.firstName : e)
        } else {
            let updatedValues = { ...values }
            updatedValues['patient_firstname'] = e
            setFieldValue('patient_firstname', e)
            validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'patient info', enableSubmit, disableSubmit)
            debounceSearch(e)
        }
    }

    function handleChange(input: any, name: string): void {
        const updatedValues = { ...values }
        updatedValues[name] = input

        setFieldValue('patientType', patientType)
        setFieldValue(name, input)
        validationHelper(validateForm, setValidSections, updatedValues, InputFields, 'patient info', enableSubmit, disableSubmit)
    }

    function handleBlur() {
        rest.setTouchedSections((prevState: any) => [...prevState, 'patient info'])
    }

    return <InView threshold={0.8} onChange={handleMouseScroll}>
        <div tabIndex={1} className="focus:outline-primary p-5 my-5 shadow-sm border border-[0.5px] rounded-md flex flex-col hover:border-primary hover:border">
            <CustomPopup
                visible={showPopup}
                title={isUpdate ? 'Update Order' : 'Create Order'}
                buttons={[
                    { onClick: viewTable, title: 'Go Back To Table', type: 'secondary' },
                    { onClick: viewOrder, title: 'Go To Existing Test', type: 'secondary' },
                    { onClick: handleStay, title: 'Stay', type: 'primary' }
                ]}
            >
                <p className="mt-5 text-royalBlue">It seems that you already have a test order in-progress or in pending submission for this patient.</p>
                <p className="mb-2 text-royalBlue">Please change test type in order to proceed further</p>
            </CustomPopup>
            <p className="text-royalBlue font-medium text-base">Patient Information</p>

            {patientType === 'new' ? <>
                <CustomInput
                    id={'patient_firstname'}
                    key={'patient_firstname'}
                    name={'patient_firstname'}
                    label={'First Name'}
                    onBlurCapture={handleBlur}
                    isInternal
                    value={values.patient_firstname}
                    onChange={(e: any) => handleChange(e.target.value, 'patient_firstname')}
                    // onChange={(val: any) => setFieldValue('firstname', val)}
                    required
                    placeholder={'Enter first name'}
                    errors={errors}
                    touched={touched}
                    obscureInput
                />
                <CustomInput
                    id={'patient_middleinitial'}
                    key={'patient_middleinitial'}
                    onBlurCapture={handleBlur}
                    name={'patient_middleinitial'}
                    label={'Middle Initial'}
                    isInternal
                    value={values.patient_middleinitial}
                    // onChange={(val: any) => setFieldValue('patient_middleinitial', val)}
                    onChange={(e: any) => handleChange(e.target.value, 'patient_middleinitial')}
                    required={false}
                    placeholder={'Enter middle initial'}
                    errors={errors}
                    touched={touched}
                    obscureInput
                />
                <CustomInput
                    id={'patient_lastname'}
                    key={'patient_lastname'}
                    name={'patient_lastname'}
                    onBlurCapture={handleBlur}
                    label={'Last Name'}
                    obscureInput
                    isInternal
                    value={values?.patient_lastname}
                    // onChange={(val: any) => setFieldValue('patient_lastname', val)}
                    onChange={(e: any) => handleChange(e.target.value, 'patient_lastname')}
                    required
                    placeholder={'Enter last name'}
                    errors={errors}
                    touched={touched}
                />
            </>
                : <>
                    <CustomInput
                        id={'patient_firstname'}
                        key={'patient_firstname'}
                        name={'patient_firstname'}
                        onBlurCapture={handleBlur}
                        label={'First Name'}
                        suggestions={patients.length > 0 ? patients.map((patient: any) => ({ label: `${patient.id} | ${patient.firstName}`, value: patient })) : []}
                        isInternal
                        obscureInput
                        hideDropdownIcon
                        value={values?.patient_firstname}
                        showSuggestions={showFirstNameSuggestions}
                        // disableSuggestiveFiltering
                        isSearching={fetchingPatients || searching}
                        onChange={handleFirstName}
                        // onChange={(value: any) => handleChange(patients.length > 0 ? value : value.target.value, 'patient_firstname')}
                        required
                        placeholder={'Enter first name'}
                        errors={errors}
                        touched={touched}
                    />
                    <CustomInput
                        id={'patient_middleinitial'}
                        key={'patient_middleinitial'}
                        onBlurCapture={handleBlur}
                        name={'patient_middleinitial'}
                        label={'Middle Initial'}
                        obscureInput
                        isInternal
                        value={values?.patient_middleinitial}
                        // onChange={(val: any) => setFieldValue('patient_middleinitial', val)}
                        onChange={(e: any) => handleChange(e.target.value, 'patient_middleinitial')}
                        required={false}
                        placeholder={'Enter middle initial'}
                        errors={errors}
                        touched={touched}
                    />
                    <CustomInput
                        id={'patient_lastname'}
                        key={'patient_lastname'}
                        name={'patient_lastname'}
                        obscureInput
                        onBlurCapture={handleBlur}
                        label={'Last Name'}
                        hideDropdownIcon
                        // suggestions={['patient1lastname', 'patient2lastname']}
                        isInternal
                        value={values?.patient_lastname}
                        // onChange={(val: any) => setFieldValue('lastname', val)}
                        onChange={(e: any) => handleChange(e.target.value, 'patient_lastname')}
                        required
                        placeholder={'Enter last name'}
                        errors={errors}
                        touched={touched}
                    />
                </>
            }
            <div className="flex justify-between items-center">
                <CustomInput
                    id={'patient_dob'}
                    key={'patient_dob'}
                    obscureInput
                    name={'patient_dob'}
                    onBlurCapture={handleBlur}
                    label={'Date of Birth'}
                    isInternal
                    value={values?.patient_dob}
                    max={new Date(maxDate)}
                    type={'date'}
                    // onChange={(val: any) => setFieldValue('patient_dob', val)}
                    onChange={(e: any) => handleChange(e, 'patient_dob')}
                    required
                    placeholder={'MM/DD/YYYY'}
                    errors={errors}
                    className={'mr-5 relative'}
                    touched={touched}
                />
                <CustomInput
                    id={'patient_gender'}
                    key={'patient_gender'}
                    obscureInput
                    name={'patient_gender'}
                    onBlurCapture={handleBlur}
                    label={'Sex at Birth'}
                    isInternal
                    disableSuggestiveFiltering
                    value={values?.patient_gender ? dataConstants.genderData.find((item: any) => item.value === values?.patient_gender)?.label : values.patient_gender}
                    suggestions={dataConstants.genderData}
                    // className={'mb-5'}
                    // onChange={(val: any) => setFieldValue('gender', val)}
                    onChange={(val: any) => handleChange(val, 'patient_gender')}
                    required
                    placeholder={'Male/Female'}
                    errors={errors}
                    touched={touched}
                />
            </div>
        </div>
    </InView>
}

const mapStateToProps = (state: any): any => ({
    loading: state.OnlineOrderForm.fetchingPatients,
    error: state.OnlineOrderForm.fetchingPatientsError,
    patients: state.OnlineOrderForm.patients,
    fetchingPatients: state.OnlineOrderForm.fetchingPatients
})

const mapDispatchToProps = {
    fetchPatients: fetchPatients
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoSection)
