import SingleElementContainer from "../../Containers/SingleElementContainer"
import NotificationsSettingsIcon from "../../assets/svg/SideNav/NotificationsSettingsIcon"
import NotificationsForm from "../../components/organism/NotificationsPage/NotificationsForm"
import NotificationsList from "../../components/NotificationsScreen/NotificationsList"
import { useHistory } from "react-router-dom"
import { useEffect, } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { ActionArgs, RESET_UNREAD_COUNT, UPDATE_NOTIFICATION_UNREAD_COUNT } from "../../Store/Actions/types"
import UserNotificationActions from "../../Store/Actions/UserNotificationActions"
import { useOktaAuth } from "@okta/okta-react"
import NotificationsDataModel from "../../models/notificationsModels/notificationsData.model"
import toastMessages from "../../utils/helpers/toastMessage"
import ButtonStateActions, { setButtonState } from "../../Store/Actions/ButtonStateActions"
interface NotificationsPageProps {
  notificationsList: NotificationsDataModel[],
  fetchingNotifications: boolean,
  fetchingNotificationsError: boolean,
  fetchAllNotifications: (options: ActionArgs) => void
}

function NotificationSettingsButton(): JSX.Element {
  const history = useHistory()
  const { profile } = useSelector((state: any) => state.Profile);
  return <button type="button" className="flex justify-between items-center" onClick={() => { profile?.roles?.includes('Office Manager') ? history.push('/notifications/settings/organization-settings') : history.push('/notifications/settings/personal-settings') }}>
    <p className="font-normal">Notifications Settings</p>
    <NotificationsSettingsIcon className="ml-3.5" />
  </button>
}

function NotificationsPage({ notificationsList, fetchAllNotifications, fetchingNotificationsError, fetchingNotifications }: NotificationsPageProps): JSX.Element {
  const { authState } = useOktaAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    fetchAllNotifications({
      onError: (error: string) => { toastMessages.error(error) },
      onSuccess: () => { },
      data: {
        url: 'v1/notifications'
      },
      config: {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`
        }
      }
    })
    dispatch({ type: RESET_UNREAD_COUNT })
    
  dispatch(setButtonState('notifications screen'))
  }, [])

  return <SingleElementContainer
    headerTitle="Notifications"
    CustomButtons={NotificationSettingsButton}
    containerClassName="bg-[#F9F9FA]"
  >
    <div className="py-6 px-5 bg-white">
      <p className="font-bold text-2xl font-royalBlue">Notifications</p>
      <NotificationsForm fetchAllNotifications={fetchAllNotifications} />
    </div>

    <div className="mx-5">
      <NotificationsList
        notificationsList={notificationsList}
        fetchingNotifications={fetchingNotifications}
        fetchingNotificationsError={fetchingNotificationsError}
      />
    </div>

  </SingleElementContainer>
}

const mapStateToProps = (state: any) => ({
  notificationsList: state.UserNotifications.notificationsList,
  fetchingNotifications: state.UserNotifications.fetchingNotifications,
  fetchingNotificationsError: state.UserNotifications.fetchingNotificationsError
})

const mapDispatchToProps = {
  fetchAllNotifications: UserNotificationActions.fetchAllNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage)