import { useSelector } from "react-redux";
import Header from "../components/global/Header";
import HelpScreenTabs from "../components/HelpScreen/HelpScreenTabs";
import FormHeader from "../components/organism/OnlineOrderForm/FormHeader";

function HelpScreenLayout({
  children,
  headerTitle,
  selectedTab,
  setSelectedTab,
}: any): JSX.Element {
  return (
    <div className="flex flex-col justify-between h-full overflow-hidden">
      <Header isInternal title={headerTitle} />
      <div className="grid h-full grid-cols-5 overflow-hidden">
        <HelpScreenTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <div className="col-span-4 grid grid-cols-4 overflow-auto">
          {children}
        </div>
      </div>
    </div>
  );
}

export default HelpScreenLayout;
