import * as React from 'react'
import { SVGProps } from 'react'

const LoadingSvg = (props: any): JSX.Element => {

    if (props.isSecondary) {
        return <svg xmlns="http://www.w3.org/2000/svg" width={17.5} height={17.5} {...props}>
            <g
                data-name="Group 14303"
                fill="none"
                stroke={props.stroke ?? "#172b4d"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
            >
                <path data-name="Line 1325" opacity={0.6} d="M8.75 3.426V.75" />
                <path data-name="Line 1326" opacity={0.8} d="m12.528 5.058 1.911-1.997" />
                <path data-name="Line 1327" d="M14.083 8.75h2.667" />
                <path data-name="Line 1328" d="m12.528 12.528 1.911 1.911" />
                <path data-name="Line 1329" d="M8.75 14.083v2.667" />
                <path data-name="Line 1330" d="m4.972 12.528-1.911 1.911" />
                <path data-name="Line 1331" opacity={0.2} d="M3.417 8.75H.75" />
                <path data-name="Line 1332" opacity={0.5} d="M4.972 4.972 3.061 3.061" />
            </g>
        </svg>
    }

    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? 17.5} height={props.height ?? 17.5} {...props}>
        <g
            data-name="Group 14303"
            fill="none"
            stroke="#cbeeef"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        >
            <path data-name="Line 1325" opacity={0.6} d="M8.75 3.426V.75" />
            <path data-name="Line 1326" opacity={0.8} d="m12.528 5.058 1.911-1.997" />
            <path data-name="Line 1327" d="M14.083 8.75h2.667" />
            <path data-name="Line 1328" d="m12.528 12.528 1.911 1.911" />
            <path data-name="Line 1329" d="M8.75 14.083v2.667" />
            <path data-name="Line 1330" d="m4.972 12.528-1.911 1.911" />
            <path data-name="Line 1331" opacity={0.2} d="M3.417 8.75H.75" />
            <path data-name="Line 1332" opacity={0.5} d="M4.972 4.972 3.061 3.061" />
        </g>
    </svg>
}

export default LoadingSvg
