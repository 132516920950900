import reduxActionType from '../../models/reduxActionType'
import { LOGOUT, RESET_INVITE_MEMBERS, RESPONSE_FAIL, RESPONSE_SUCCESS, SET_INVITE_MEMBERS, SUBMIT_FORM } from '../Actions/types'

const initialState = {
  error: null,
  submitting: false,
  success: false,
  members: []
}

function InviteUserReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case SET_INVITE_MEMBERS: {
      return {
        ...state,
        members: [...action.payload]
      }
    }
    case RESET_INVITE_MEMBERS: {
      return {
        ...state,
        members: []
      }
    }
    case SUBMIT_FORM: {
      return {
        ...state,
        error: null,
        success: false,
        submitting: true
      }
    }
    case RESPONSE_SUCCESS: {
      return {
        ...state,
        error: null,
        submitting: false,
        success: true
      }
    }

    case RESPONSE_FAIL: {
      return {
        ...state,
        error: action.payload,
        submitting: false,
        success: false
      }
    }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default InviteUserReducer
