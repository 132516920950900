import ToggleSwitch from "../atomic/ToggleSwitch"
import HeaderColumnData, { SubColumn } from "../../models/notificationsSettingsTableModels/headerColumnData.model"
import NotificationsSettingsTableData from "./NotificationsSettingsTableData.model"
import BodyColumnData from "./BodyColumnData.model"
import { useCallback } from "react"
import RequiredFieldTooltip from "../global/RequiredFieldTooltip"
interface HeaderColumnProps extends HeaderColumnData {
  columnNumber?: number
  onSelectAll?: (id: string | number) => void
  isEnabled?: any
}
interface BodyColumnProps extends BodyColumnData {
  rowNumber: number
  columnNumber: number
  tableData: Array<BodyColumnData[]>
  setTableData: (data: Array<BodyColumnData[]>) => void
}
interface TableBodyProps {
  bodyData: Array<BodyColumnData[]>,
  setBodyData: (data: Array<BodyColumnData[]>) => void
}

function HeaderSubColumn({
  name,
  uniqueKey,
  allSelected,
  key,
  onSelectAll,
  id,
  disabled,
  isEnabled,
}: SubColumn) {
  const filteredValuesForMediumTable = isEnabled.filter(
    (item:object) => !(item[`${uniqueKey}_DISABLED` as keyof typeof item] && uniqueKey)
  );
  const isDisabled = disabled || isEnabled?.every((item:object) => item[`${uniqueKey}_DISABLED`  as keyof typeof item] === true);
  const allAgainstKeyEnabled = filteredValuesForMediumTable?.every((item:object) => item?.[`${uniqueKey}` as keyof typeof item]);
  const buttonText = allAgainstKeyEnabled ? 'Disable All' : 'Enable All';
  const memoizedShowPop = useCallback(() => {
    if (onSelectAll) {
      onSelectAll(id, !allAgainstKeyEnabled);
    }
  }, [id, onSelectAll, allAgainstKeyEnabled]);
  
  function showPop() {
    memoizedShowPop();
  }
  
  return (
    <th className="text-center px-6 py-4 pr-4 w-32">
      <p className="text-xs font-semibold text-royalBlue capitalize">{name}</p>
      <button
        disabled={isDisabled}
        onClick={showPop}
        className={`text-[10px] font-medium text-[#084E8B] ${!isDisabled && 'hover:underline'} disabled:cursor-not-allowed mt-auto`}
      >
        {buttonText}
      </button>
    </th>
  );
}

function HeaderColumn({ columnName, columnNumber, subColumns, onSelectAll, isEnabled}: HeaderColumnProps): JSX.Element {
  return <th className={`border-r border-[#DCDFE4] text-royalBlue text-left ${subColumns.length === 0 ? 'align-top' : ''}`}>
    <p className={`text-left px-6 py-4 ${subColumns.length > 0 ? 'border-b' : ''}`}> {columnName}</p>
    {
      subColumns && subColumns.length > 0 && <table className="table-auto">
        {
          subColumns
            .map((column) =>
              <HeaderSubColumn
                onSelectAll={onSelectAll}
                isEnabled={isEnabled}
                {...column}
                uniqueKey={column.key}
              />
            )
        }
      </table>
    }
  </th>
}

function TableBodyColumn({ data, rowNumber, columnNumber, tableData, setTableData }: BodyColumnProps): JSX.Element {
  
  function handleSwitchToggle(value: boolean, childIndex: number) {
    let temp = [...tableData]
    let foundRow = temp[rowNumber][columnNumber]
    if (Array.isArray(foundRow.data)) {
      foundRow.data[childIndex].value = value
      setTableData(temp)
    }
  }

  if (Array.isArray(data))
    return <td className="border-r border-[#DCDFE4] py-3.5 ml-7">
      <table className="table-auto w-full ml-7">
        {data
          .map((column, childIndex) =>
          <td className="px-6" key={childIndex}>
           <RequiredFieldTooltip 
           id={'Create Signature on File (SOF)'} 
           required={column.disabled} 
           tooltipText={column?.hoverText}>
            <ToggleSwitch
              name={column.name}
              disabled={column.disabled}
              id="switch"
              checked={typeof (column.value) == 'boolean' ? column.value : false}
              handleChange={(event) => handleSwitchToggle(event.target.checked, childIndex)}
            />
            
          </RequiredFieldTooltip>
          </td>
          )}
      </table>
    </td>

  return <td className="border-r border-[#DCDFE4] px-6 py-3.5">
    {data.name}
  </td>
}

function TableHeader({ columns, onSelectAll, isEnabled }: { columns: HeaderColumnProps[], onSelectAll: (id: string | number) => void, isEnabled: any }): JSX.Element {
  return <thead className="bg-[#F9F9FA] border-b border-[#DCDFE4]">
    <tr>
      {columns.map((column, index) => <HeaderColumn
        key={column.columnName}
        columnNumber={index}
        onSelectAll={onSelectAll}
        isEnabled={isEnabled}
        {...column}
      />)}
    </tr>
  </thead>
}

function TableBody({ bodyData, setBodyData }: TableBodyProps): JSX.Element {
  return <tbody>  
    {bodyData.length === 0 && <tr>
      <td colSpan={2} className="text-center p-6"> <p>No data found</p></td>
    </tr>
    }
    {bodyData
      .map((data, rowNumber) => <tr key={rowNumber} className="border-b border-[#DCDFE4]">
        {
          data
            .map((item, columnNumber) =>
              <TableBodyColumn
                {...item}
                rowNumber={rowNumber}
                tableData={bodyData}
                setTableData={setBodyData}
                columnNumber={columnNumber}
                key={Math.random()}
              />)
        }
      </tr>)}
  </tbody>
}

function NotificationsSettingsTable({
  tableColumns,
  tableBody,
  setTableBody,
  onSelectAll,
  isEnabled
}: NotificationsSettingsTableData): JSX.Element {
  return <table className="table-auto border border-[#DCDFE4] bg-white">
    <TableHeader
      columns={tableColumns}
      onSelectAll={onSelectAll}
      isEnabled={isEnabled}
    />
    <TableBody
      bodyData={tableBody}
      setBodyData={setTableBody}
    />
  </table>
}

export default NotificationsSettingsTable