import { ChangeEventHandler, useRef } from "react";

function RoleSelection({
  name,
  id,
  onChange,
  label,
  value,
  checked,
  description,
}: {
  name: string;
  id: string;
  label: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  description: string;
}): JSX.Element {
  const selectedStyles =
    "bg-gradient-to-r from-[#18B5B7] to-[#084E8B] text-white font-normal";
  const radioRef = useRef<any>(null);

  return (
    <label
      htmlFor={id}
      className={`rounded-xl my-3 mr-3 shadow-md p-4 flex items-start ${
        checked ? selectedStyles : "border hover:border hover:border-primary"
      }`}
    >
      <button
        type="button"
        className={`mt-1 w-5 h-5 flex-shrink-0 border-2 hover:border-[#647188] outline-[#647188] border-dropDownBorder flex flex-col justify-center items-center rounded-full ${
          checked ? "!border-white" : ""
        } mr-3`}
        onClick={() => {
          radioRef?.current?.click?.();
        }}
      >
        <div
          className={`w-2.5 h-2.5 ${checked ? "bg-white" : ""}`}
          style={{ borderRadius: "100%" }}
        />
      </button>

      <div>
        <h4 className={`text-royalBlue font-semibold ${checked && 'text-white'}`}>{label}</h4>
        <p className={`text-royalBlue ${checked && 'text-white'}`}> {description}</p>
      </div>

      <input
        ref={radioRef}
        type="radio"
        id={id}
        name={name}
        hidden
        onChange={onChange}
        value={value}
        className="mx-3 hidden"
      />
    </label>
  );
}

export default RoleSelection;
