import * as React from "react"
import { SVGProps } from 'react'

const SvgFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={77.773}
    height={98.852}
    {...props}
  >
    <g data-name="Group 17627">
      <g
        data-name="Group 17625"
        opacity={0.8}
        fill="none"
        stroke="#b1b8c3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
      >
        <path
          data-name="Path 5125"
          d="M49.426 2v21.078a5.27 5.27 0 0 0 5.27 5.27h21.078"
        />
        <path
          data-name="Path 5126"
          d="M65.234 96.852h-52.7A10.539 10.539 0 0 1 2 86.313V12.539A10.539 10.539 0 0 1 12.539 2h36.887l26.347 26.348v57.965a10.539 10.539 0 0 1-10.539 10.539Z"
        />
        <path data-name="Line 1255" d="M23.078 33.617h5.27" />
        <path data-name="Line 1256" d="M23.078 54.695h31.617" />
        <path data-name="Line 1257" d="M23.078 75.773h31.617" />
      </g>
    </g>
  </svg>
)

export default SvgFile
