/* eslint-disable @typescript-eslint/space-before-function-paren */
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import FormTabCheck from '../../assets/svg/FormTabCheck'
import SidebarLoadingIndicator from '../global/LoadingIndicators/CreateOnlineOrderIndicators/SidebarLoadingIndicator'

function Tab({ tab, handleTab, selectedTab }: { tab: { label: string, value: string }, handleTab: any, selectedTab: string, }): JSX.Element {
  return <button
    onClick={handleTab(tab)}
    className={`flex items-center text-royalBlue text-sm lg:text-base text-left block capitalize p-1 mb-1 hover:text-activeLink hover:lg:text-base hover:text-sm visited:text-royalBlue ${selectedTab === tab.label ? 'font-bold text-activeLink hover:font-bold' : ''}`}
  >   {/* <FormTabCheck
      className="mr-1 !text-xl"
      fill={isValid ? '#18B5B7' : 'none'}
      isvalid={isValid ? 'white' : '#d0d4db'}
      stroke={isValid ? '#18B5B7' : '#d0d4db'}
    /> */}
    {tab.label}
  </button>
}

function SummaryTabs({ selectedTab, setSelectedTab, validSections }: any): JSX.Element {
  const { fetchingOrderByID,orderData , selectedOrganization , selectedPhysician} = useSelector((state: any) => state.OnlineOrderForm)

  const tabs = [
    {
      label: 'Patient and Order Information',
      value: 'patient-order-info'
    },
    {
      label: 'additional info',
      value: 'additional info'
    },
    {
      label: 'Document Management',
      value: 'document-management'
    },
  ]
  // const filteredTabs = (selectedPhysician && selectedPhysician?.id === orderData?.clinician_id) ? tabs : tabs.filter(tab => tab.value !== 'additional info');

  const scroll = (tab: string): any => {
    const section: any = document.getElementById(tab)
    section.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  function handleTab(tab: { label: string, value: string }) {
    return () => {
      scroll(tab.value)
      setSelectedTab(tab.label)
    }
  }

  if (fetchingOrderByID) {
    return <SidebarLoadingIndicator />
  } else {
    return <div className="bg-[#F9F9FA] border-r col-span-1 p-6 break-words">
      {tabs.map((tab, index) => <Tab
        key={tab.value}
        tab={tab}
        selectedTab={selectedTab}
        handleTab={handleTab}
      />)}
    </div>
  }
}

export default SummaryTabs
