import React, { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import CustomPopup from "../../components/global/CustomPopup";

interface RouteLeavingGuardProps {
  navigate: (path: string) => void;
  when: boolean;
  shouldBlockNavigation: (nextLocation: any) => boolean; 
}

const RouteLeavingGuard: React.FC<RouteLeavingGuardProps> = ({
  navigate,
  when,
  shouldBlockNavigation,
}) => {
  const [modalVisible, updateModalVisible] = useState(false);
  const [lastLocation, updateLastLocation] = useState<any>();
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const history = useHistory();

  const showModal = (location: any) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (cb?: () => void) => {
    updateModalVisible(false);
    if (cb) {
      cb();
    }
  };

  const handleBlockedNavigation = (nextLocation: any) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (confirmedNavigation) {
      navigate(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <CustomPopup
        buttons={[
          { onClick: closeModal, title: "STAY", type: "secondary" },

          {
            onClick: handleConfirmNavigationClick,
            title: "LEAVE",
            type: "primary",
          },
        ]}
        visible={modalVisible}
        title={`Notifcation Settings`}
        routeLeaving={true}
      >
        <p className="mt-5 text-royalBlue items-start">
          The changes you've made may not be saved if you leave this page.
        </p>
      </CustomPopup>

    </>
  );
};

export default RouteLeavingGuard;
