/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/space-before-function-paren */
import { BlockBlobClient } from "@azure/storage-blob";
import { useOktaAuth } from "@okta/okta-react";
import { AnimatePresence, motion } from "framer-motion";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "../../../assets/svg/EditIcon";
import ButtonStateActions from "../../../Store/Actions/ButtonStateActions";
import { addNotes, fetchOrderById, retryOrder, toggleMode } from "../../../Store/Actions/OnlineOrderFormActions";
import http from "../../../Store/API/HttpMethods";
import toastMessages from "../../../utils/helpers/toastMessage";
import Button from "../../atomic/Button";
import FileDetail from "../../global/FileDetail";
import { connect } from "react-redux";
import DropDownIcon from "../../../assets/svg/DropdownIcon";
import { InView } from "react-intersection-observer";
import DownloadHelper from "../../../utils/helpers/fileDownloader";
import { useHistory } from "react-router-dom";
import useQuery from "../../../utils/helpers/QueryHelper";
import CustomInput from "../../global/CustomInput";
import { updateOnlineOrder } from "../../../Store/Actions/OnlineOrderFormActions";
import FieldValue from "../../TestOrders/FieldValue";
import dataConstants from "../../../constants/dataConstants";
import useSignCheckbox from "../../../utils/customHooks/showSignCheckbox";
import AdditionalInfoSection from "../TestOrderForms/Sections/AdditionalInfoSection";

const initialData = [
  {
    title: "Patient Information",
    scrollId: "patient info",
    sections: [
      {
        title: "Patient Information",
        fields: {
          patient_firstname: "First Name",
          patient_middleinitial: "Middle Initial",
          patient_lastname: "Last Name",
          patient_dob: "Date of Birth",
          patient_gender: "Sex at Birth",
        },
      },
      {
        title: "Additional Patient Identifier (Optional)",
        fields: {
          patient_ssn: "SSN",
          patient_mrn: "MRN",
        },
      },
      {
        title: "Patient Contact Information",
        fields: {
          patient_email: "Email ID",
          patient_address: "Address",
          patient_city: "City",
          patient_state: "State/Province",
          patient_phone: "Phone",
          patient_zip_code: "Zip Code",
        },
      },
    ],
  },
  {
    title: "Billing Information",
    scrollId: "billing info & medicare",
    sections: [
      {
        title: "Billing Information",
        fields: {
          icd_10: "ICD-10 Code",
          additionalCodes: "Additional Diagnosed ICD-10s.",
          // insurance: "Primary Insurance Company Name",
          insurance: 'Primary Insurance Co. Name. (If Insurance Cards/Billing Face Sheet is uploaded, this section can be left blank)',
          icn: "Insurance Company Name",
          billing_phone: "Phone",
          policy: "Policy Number",
          medicare: "Type of facility (where tissue was collected) ",
          date_of_discharge: "Date of Discharge",
        },
      },
      // {
      //   title: "Medicare",
      //   fields: {
      //     medicare: "Type of facility (where tissue was collected) ",
      //     date_of_discharge: "Date of Discharge",
      //   },
      // },
    ],
  },
  {
    title: "Clinician Information",
    sections: [
      {
        title: "Organization",
        fields: {
          organization_name: "Organization Name",
          organization_emai: "Organization Email ID ",
          organization_phone: "Phone",
          organization_fax: "Fax",
          organization_description: "Description",
          organization_address: "Address",
          organization_city: "City",
          organization_state: "State/Province",
        },
      },
      {
        title: "Clinician Information",
        fields: {
          clinician_full_name: "Full Name ",
          clinician_email: "Email ID",
          clinician_phone: "Phone",
          clinician_suffix: "M.D",
          clinician_speciality: "Speciality",
          clinician_npi: "NPI",
          clinician_account_number: "Account Number",
          clinician_address: "Address",
          clinician_city: "City",
          clinician_zip_code: "Zip Code",
          clinician_state: "State/Province",
        },
      },
    ],
  },
  {
    title: "Clinical Information",
    scrollId: "clinical info",
    sections: [],
  },
  {
    title: "Additional Information",
    scrollId: "additional info",
    sections: [
      {
        title: "",
        fields: {
          full_name: "Full Name",
          email_id: "Email ID",
          phone: "Phone",
          fax: "Fax",
          suffix: "Suffix",
          speciality: "Speciality",
        },
      },
    ],
  },
  {
    title: "Laboratory Information",
    scrollId: "laboratory info",
    subtitle1: "Type of specimen being submitted for testing",
    subtitle2: "Slides/Block",
    sections: [
      {
        title: "Laboratory",
        fields: {
          name_of_facility: "Name of facility where tissue is maintained",
          date_of_collection: "Date of Collection",
          specimenId: "Specimen ID",
          medicarePullDate: "Medicare Pull Date",
          isTeleHealth: 'Telehealth Order',
          tubeBarcode: "Tube Barcode",
          facilityType: 'Location of Sample Collection',
          // facility_contact_person: 'Facility of contact person',
          lab_phone: "Phone",
          lab_fax: "Fax",
          pathologist: 'Pathologist',
          tumorSite: 'Tumor Site',
          // workingDiagnosis: 'Working differential/differential diagnosis'
        },
      },
    ],
  },
  {
    title: "Supporting Documents",
    scrollId: "supporting documents",
    subtitle1:
      "Files will only be saved/uploaded on final submission after signature.",
    subtitle2:
      "If the Online Order is exited for future completion, documents will not be saved.",
    sections: [
      {
        title: "Upload Documents",
        fields: {
          pathology_report: "Pathology Report",
          insurance_cards: "Insurance Cards",
          patient_info_demographics: "Patient Info/Demographics sheet",
          signed_letter: "Signed letter of medical necessity",
          current_prescription_medications: "Current Prescription Medications"
        },
      },
      {
        title: "Additional Notes",
        fields: {
          additional_notes: "Notes",
        },
      },
      {
        title: "Upload and transmit more documents",
        fields: {},
      },
    ],
  },
];

function SectionCard({
  title,
  fields,
  values,
  hideEdit,
  onClick,
  className,
  isFile,
  isApproval
}: any): JSX.Element {
  return (
    <div className={`mt-4 ${className ?? ""}`}>
      <div className="flex justify-between items-center">

        {
          values?.selectedTest?.id !== 4 && title === 'Additional Patient Identifier (Optional)' ? null :
            <p className="text-royalBlue text-base font-medium">{title}</p>
        }
        {title === 'Upload Documents' ? values?.status < 40 && (
          <button type="button" onClick={onClick}>
            <EditIcon />
          </button>
        ) : (hideEdit || (title === 'Additional Patient Identifier (Optional)' && values?.selectedTest?.id !== 4)) ? null : (
          <button type="button" onClick={onClick}>
            <EditIcon />
          </button>
        )}

      </div>
      {fields && Object.keys(fields).length > 0 && (
        <div className="flex flex-wrap items-center">
          {Object.entries(fields).map(([field, value]: any) => (
            <FieldValue
              key={field}
              field={field}
              value={value}
              values={values}
              isFile={isFile}
              isPDF={false}
              isApproval={isApproval}
            />
          ))}
        </div>

      )}

    </div>
  );
}

function UploadMoreDocsSection({
  values,
  title,
  onClick,
  className,
  setFieldValue,
  isValid,
  isSummary,
  isUpdate,
  isApproval,
  localTransitDocuments,
  setLocalTransitDocuments,
}: any): JSX.Element {
  const inputRef = useRef<any>();
  const { authState } = useOktaAuth();
  const { discardForm } = useSelector((state: any) => state.OnlineOrderForm);
  const [previousDiscardValue, setPreviousDiscardValue] = useState(discardForm);
  const [files, setFiles] = useState<any[]>([]);
  const [newFiles, setNewFiles] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const dispatch = useDispatch();

  function removeFiles(): void {
    setFieldValue("transitDocuments", null);
    setFieldValue("transitDocumentsFiles", null);
  }

  useEffect(() => {
    if (isUpdate || isSummary || isApproval) {
      if (previousDiscardValue !== discardForm) {
        removeFiles();
        setFiles([]);
      }
    } else {
      removeFiles();
      setFiles([]);
    }
  }, [discardForm]);

  useEffect(() => {
    if (isApproval || isSummary || isUpdate) {
      setFiles(values?.transitDocumentsFiles ?? []);
    } else if (
      values?.transitDocuments &&
      values?.transitDocuments.length > 0
    ) {
      let temp = values.transitDocuments.map((file: any, index: number) => {
        let found = values.transitDocumentsFiles.find(
          (item: any, childIndex: number) => childIndex === index
        );
        return found;
      });
      setFiles(temp);
    }
  }, []);
  const validateFile = (file: any): boolean => {
    let sizeInMB = null;
    sizeInMB = file.size / 1024 / 1024;
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'png', 'jpg', 'jpeg', 'pdf']


    if (files.length > 0) {
      if (files.some((item: any) => item.name === file.name)) {
        alert("File already added");
        return false;
      }
    }
    if (!validTypes.includes(getExt(file.name)?.toLowerCase())) {
      toastMessages.error(`Invalid file format`)
      return false
    }
    if (sizeInMB > 50) {
      alert("File size limit is being exceeded");
      return false;
    }
    return true;
  };

  function handleFiles(event: any): void {
    let inputFiles = event.target.files;
    let temp: any[] = [];

    if (files.length > 0) {
      temp = [...files];
    }

    let temp2 = [...newFiles];
    for (const element of inputFiles) {
      if (validateFile(element)) {
        temp.push(element);
        temp2.push(element);
      }
    }
    setFiles(temp);
    setNewFiles(temp2);
  }
  // async function handleDrop(event: any) {
  //   event.preventDefault()
  //   event.stopPropagation()
  //   let inputFiles = event.target.files;
  //   let temp: any[] = [];

  //   if (files.length > 0) {
  //     temp = [...files];
  //   }

  //   let temp2 = [...newFiles];
  //   for (const element of inputFiles) {
  //     if (validateFile(element)) {
  //       temp.push(element);
  //       temp2.push(element);
  //     }
  //   }
  //   setFiles(temp);
  //   setNewFiles(temp2);
  // }
  async function handleDrop(event: any) {
    event.preventDefault()
    event.stopPropagation()
    let inputFiles = event.dataTransfer.files;
    let temp: any[] = [];

    if (files.length > 0) {
      temp = [...files];
    }

    let temp2 = [...newFiles];
    for (const element of inputFiles) {
      if (validateFile(element)) {
        temp.push(element);
        temp2.push(element);
      }
    }
    setFiles(temp);
    setNewFiles(temp2);
  }


  function handleRemove(mainIndex: number) {
    return () => {
      inputRef.current.value = "";
      setNewFiles((prevState: any) => prevState.filter((item: any) => item.name !== files[mainIndex].name))
      setFiles((prevState: any) =>
        prevState.filter((file: any, index: number) => index !== mainIndex)
      );

      if (values.transitDocuments) {
        let transitDocuments = [...values.transitDocuments];
        let transitDocumentsFiles = [...values.transitDocumentsFiles];

        setFieldValue(
          "transitDocuments",
          transitDocuments.filter(
            (item: any, index: number) => index !== mainIndex
          )
        );
        setFieldValue(
          "transitDocumentsFiles",
          transitDocumentsFiles.filter(
            (item: any, index: number) => index !== mainIndex
          )
        );
      }
    };
  }

  function getExt(filename: string): any {
    const ext = filename.split(".").pop();
    if (ext === filename) return "";
    return ext;
  }

  function uploadToAzure(
    inputFile: any,
    url: string,
    filePath: string,
    resolve: any,
    reject: any
  ): void {
    const blockBlobClient = new BlockBlobClient(url);
    blockBlobClient
      .uploadData(inputFile, {
        blobHTTPHeaders: { blobContentType: inputFile.type },
      })
      .then(() => {
        resolve({ filePath, url });
      })
      .catch((error: any) => reject(error));
  }

  function getPresignedUrl(inputFile: any, resolve: any, reject: any): any {
    if (!inputFile) return reject("File missing");
    const extension = getExt(inputFile.name);
    http
      .getPresignedUrl(`v1/pre-signed-url/documents/${inputFile.name.replace(/ /g, "_")}`, {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      })
      .then(({ url, filepath }: any) => {
        if (url) {
          uploadToAzure(inputFile, url, filepath, resolve, reject);
        } else {
          reject("URL not provided");
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  }

  function uploadFile(inputFile: any): any {
    return new Promise((resolve, reject): void => {
      getPresignedUrl(inputFile, resolve, reject);
    });
  }

  async function addMoreDocument(orderId: string, transitDocuments: any) {
    try {
      const response = await http.addDocuments(
        "/v1/orders/new-flow/add-document",
        { orderId, transitDocuments },
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }
      );
      const { statusCode, message } = response;
      if (statusCode === 200) {
        toastMessages.success(message ?? "Upload documents successful");
        setNewFiles([]);
      }
      setUploading(false);
    } catch (error: any) {
      const { response } = error;
      if (response) {
        toastMessages.error(response.data.message ?? "Upload documents failed");
      } else {
        toastMessages.error(error.message ?? "Upload documents failed");
      }
      setUploading(false);
    }
  }

  function handleTransmit(): void {
    if (uploading) return;
    setUploading(true);
    dispatch(ButtonStateActions.toggleSumbit(false));
    Promise.all([...newFiles.map((file: any) => uploadFile(file))])
      .then((results: any) => {
        if (values.transitDocuments && values.transitDocuments.length > 0) {
          setFieldValue("transitDocuments", [
            ...values.transitDocuments,
            ...results,
          ]);
          setLocalTransitDocuments([...values.transitDocuments, ...results]);
        } else {
          setFieldValue("transitDocuments", results);
          setLocalTransitDocuments(results);
        }
        setFieldValue("transitDocumentsFiles", files.map((file: any) => ({ ...file, existing: true })));
        if (isApproval || isSummary) {
          addMoreDocument(
            values.orderId,
            results.map((document: any) => document.filePath)
          );
        } else {
          setUploading(false);
          setNewFiles([]);
        }
        dispatch(ButtonStateActions.toggleSumbit(isValid));
      })
      .catch((error: any) => {
        toastMessages.error(error ?? "Something went wrong");
        setUploading(false);
      });
  }

  return (
    <div className={`mt-4 ${className ?? ""}`}>
      <div className="flex justify-between items-center">
        <p className="text-royalBlue text-base font-medium">{title}</p>
        <Button
          title={`transmit file (s) ${newFiles.length > 0 ? `(${newFiles.length})` : ""
            }`}
          btnType={"primary"}
          submitting={uploading}
          disabled={newFiles.length === 0 ?? false}
          onClick={handleTransmit}
        />
      </div>

      <div
        onDragOver={e => e.preventDefault()}
        onDragEnter={e => e.preventDefault()}
        onDragLeave={e => e.preventDefault()}
        onDrop={handleDrop}>
        <div className="my-2">
          <p className="text-royalBlue my-2">
            Please choose additional file(s) to upload and transmit to Clinical Services.
          </p>
          {files && files?.length > 0 && (
            <div className="my-3 flex items-center flex-wrap">
              {files.map((file: any, mainIndex: number) => (
                <FileDetail
                  key={file.name}
                  url={
                    values?.transitDocuments &&
                    values?.transitDocuments?.length > 0 &&
                    values?.transitDocuments[mainIndex]?.url
                  }
                  handleRemove={handleRemove(mainIndex)}
                  file={file}
                  // uploading={!!values?.transitDocuments[mainIndex]?.url == false && uploading}
                  uploading={!!(values?.transitDocuments && values.transitDocuments.length > 0 && values.transitDocuments[mainIndex]?.url === false) && uploading}
                  hideRemove={((values?.transmitDocumentsFiles && values?.transitDocumentsFiles[mainIndex]?.existing) || uploading) ?? false}
                  disabled={false}
                  isSummary
                />
              ))}
            </div>
          )}
          <Button
            btnType="secondary"
            title={"Choose more files"}
            className="py-2 bg-white"
            disabled={
              values?.status === 90 ||
                values?.status === 100
                ? true
                : false
            }
            onClick={() => inputRef.current.click()}
          />
          <input
            type="file"
            id="file"
            multiple
            accept=".png, .jpeg, .pdf, .doc/*"
            ref={inputRef}
            // onChange={onChange}
            onChange={handleFiles}
            style={{ display: "none" }}
          />
        </div>
      </div>
    </div>
  );
}
function AdditionalNotesSection({
  updateOrder,
  savingOrder,
  selectedOrganization,
  selectedPhysician,
  values,
  title,
  onClick,
  className,
  hideEdit,
  isApproval,
  isSummary,
  errors,
  touched,
  localTransitDocuments,
  setFieldValue,
  setSelectedtab,
  addingNotes,
  addNotesError,
  addNotesSuccess,
  addNotes,
  selectedTab,
}: any): JSX.Element | null {
  const dispatch = useDispatch();
  const [show, setShow] = useState<boolean>(false);
  const { location: { pathname } } = useHistory()

  const { authState } = useOktaAuth();
  function handleInView(inView: boolean): void {
    if (inView) {
      setSelectedtab("additional info");
    }
  }
  function handleAddNotes(): void {
    if (addingNotes) return

    const data = {
      orderId: values?.orderId,
      notes: values?.additional_notes
    }
    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
      },
    };

    const options = {
      data,
      config,
      onError: (message: string) => toastMessages.error(message),
      onSuccess: (message: string) => {
        toastMessages.success(message)
        setShow(false);
      },
    }
    addNotes(options)
  }


  if (pathname?.includes('summary') || pathname?.includes('order-review') || pathname?.includes('approvals'))
    return null

  return (
    <div className={`mt-4 ${className ?? ""}`}>
      {hideEdit ? null : (
        <div className="flex justify-between items-center">
          <p className="text-royalBlue text-base font-medium">{title}</p>
          <button type="button" onClick={onClick}>
            <EditIcon />
          </button>
        </div>
      )}
      <div className="flex justify-between items-center w-full">
        <div className="w-full">
          {!show && (
            <div className="my-2">
              <p className="text-royalBlue !text-xs font-semibold my-2">
                Notes
              </p>
              <p className="text-royalBlue text-base capitalize">
                {/* {!!notesValue ? notesValue : "---"} */}
                {!!values.additional_notes ? values.additional_notes : "---"}
              </p>
            </div>
          )}
          {(isSummary || isApproval) && show && (
            <div className="my-2 w-[100%]">
              {" "}
              <div className="w-[100%] flex flex-col justify-end items-end">
                <CustomInput
                  id="additional_notes"
                  name="additional_notes"
                  label="Notes"
                  required={false}
                  isTextArea={true}
                  placeholder={"Enter notes"}
                  value={values?.additional_notes}
                  errors={{}}
                  className={"w-[100%]"}
                  touched={{}}
                  onChange={(e: any) => {
                    setFieldValue(
                      "additional_notes",
                      e.currentTarget.textContent
                    );
                  }}
                />
                <div className="flex ">
                  <Button
                    title="CANCEL"
                    btnType="secondary"
                    className="mr-4"
                    onClick={() => {
                      setShow(false);
                      setFieldValue("additional_notes", values?.previous_notes);
                    }}
                  />
                  <Button
                    title="ADD"
                    btnType="primary"
                    className=""
                    submitting={addingNotes}
                    onClick={handleAddNotes}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {(isSummary || isApproval) && !show && (values.status < 90) && (
            <button type="button" onClick={() => setShow(!show)}>
              <EditIcon />
            </button>
          )}
        </div>
      </div>
    </div>

  );
}

function DXSccReview({
  values,
  setSelectedTab,
  setFieldValue,
  isValid,
  isSummary,
  isApproval,
  isApprovalOrUpdate,
  scrollId
}: any): JSX.Element {
  const [questions, setQuestions] = useState(dataConstants.SCCQuestions);
  const dispatch = useDispatch();
  const history = useHistory();
  function handleClick(): void {
    dispatch(toggleMode());
    setTimeout(() => {
      if (isApproval) {
        history.replace(
          `/test-orders/online-order/${values?.orderId}?section=clinical info`
        );
        return;
      }
      const section: any = document.getElementById("clinical info");
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      setSelectedTab("clinical info");
    }, 500);
  }

  return (
    <div className={`${scrollId ? 'scroll-my-5' : ''} mb-6`} id={scrollId ?? ''}>
      <p className="text-royalBlue font-medium uppercase mb-6 tracking-[0.18em]">
        Clinical Information
      </p>

      <div className="bg-[#F9F9FA] px-6 pb-3 border border-[#DCDFE4] rounded ">
        <div className="flex justify-between items-center mt-4">
          <p className="text-royalBlue text-base font-medium">Clinical Information</p>
          {/* {
            isApprovalOrUpdate && values?.status < 40 ? (
              <button type="button" onClick={handleClick}>
                <EditIcon />
              </button>
            ) : <button type="button" onClick={handleClick}>
              <EditIcon />
            </button>} */}
          {values?.status < 40 && <button type="button" onClick={handleClick}>
            <EditIcon />
          </button>}
        </div>
        {questions.map((question) => (
          <div key={question.questionId} className="mb-3">
            <p className="text-royalBlue font-xs font-semibold my-2">
              {question.label}
            </p>

            {question.options
              .filter((option) =>
                values[option.name]?.options?.includes(option.value)
              )
              .map((item: any) => (
                <p className="text-royalBlue text-base capitalize my-1">
                  {!!item?.customLabelHTML ? <span dangerouslySetInnerHTML={{ __html: item.customLabelHTML }} /> : item.label}
                </p>
              ))}
            {question.questionId === 1 && values.other && (
              <p className="text-royalBlue text-base capitalize my-1">
                {values.other}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
function IDGFormReview({
  values,
  setSelectedTab,
  setFieldValue,
  isValid,
  isSummary,
  isApproval,
  isApprovalOrUpdate,
  scrollId
}: any): JSX.Element {
  function updateLabelsWithSuperscript(arr: any) {
    return arr.map((item: any) => {
      const updatedLabel = item.label.replace(
        /®/g,
        `<sup style={{ verticalAlign: 'super', marginTop:'10px' }}>®</sup>`
      );
      return { ...item, label: updatedLabel };
    });
  }

  const updatedisAltheaDxNeuropsychotropicMedications = updateLabelsWithSuperscript(
    dataConstants.isAltheaDxNeuropsychotropicMedicationsValues
  );
  const updatedisIDgNeuropsychotropicMedicationsValues = updateLabelsWithSuperscript(
    dataConstants.isIDgNeuropsychotropicMedicationsValues
  );
  const updatedisIDgNeuropsychotropicMedicationsCurrentValues = updateLabelsWithSuperscript(
    dataConstants.isIDgNeuropsychotropicMedicationsCurrentValues
  );
  const [questions, setQuestions] = useState([
    {
      questionId: 1,
      label: 'Select ALL the NeuroIDgenetix medications you are considering for new treatment and/or dosage change for this patient.',
      tooltip: '',
      name: 'isAltheaDxNeuropsychotropicMedications',
      options: updatedisAltheaDxNeuropsychotropicMedications
    },

    // {
    //   questionId: 3,
    //   label: `Patient's condition appears difficult to treat as evidenced by therapeutic failure of the following current or previous psychiatric medications.`,
    //   name: 'isIDgNeuropsychotropicMedications',
    //   tooltip: '',
    //   options: updatedisIDgNeuropsychotropicMedicationsValues
    // },
    {
      questionId: 4,
      label: `CURRENT PRESCRIPTION MEDICATIONS - Please select all current psychiatric and non-psychiatric prescription medications. (If patient MAR sheet is uploaded, this section can be left blank)`,
      name: 'isIDgNeuropsychotropicMedicationsCurrent',
      tooltip: '',
      options: updatedisIDgNeuropsychotropicMedicationsCurrentValues
    },
    {
      questionId: 2,
      label: `LIFESTYLE FACTORS, OTC MEDICATIONS & OTHER - Please select all that the patient regularly takes or uses any of the items below`,
      name: 'isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplement',
      tooltip: '',
      options: dataConstants.isIDgOTCHerbalMedsEnvironmentalFactorDietarySupplementValues
    },
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  function handleClick(): void {
    dispatch(toggleMode());
    setTimeout(() => {
      if (isApproval) {
        history.replace(
          `/test-orders/online-order/${values?.orderId}?section=clinical info`
        );
        return;
      }
      const section: any = document.getElementById("clinical info");
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      setSelectedTab("clinical info");
    }, 500);
  }

  return (
    <div className={`${scrollId ? 'scroll-my-5' : ''} mb-6`} id={scrollId ?? ''} >
      <p className="text-royalBlue font-medium uppercase mb-6 tracking-[0.18em]">
        Patient Healthcare Information
      </p>
      <div className="bg-[#F9F9FA] px-6 pb-3 border border-[#DCDFE4] rounded ">
        <div className="flex justify-between items-center mt-4">
          <p className="text-royalBlue text-base font-medium">Patient Healthcare Information</p>
          {/* {
            isApprovalOrUpdate && values?.status < 40 ? '' : <button type="button" onClick={handleClick}>
              <EditIcon />
            </button>} */}
          {values?.status < 40 && <button type="button" onClick={handleClick}>
            <EditIcon />
          </button>}
        </div>
        {questions.map((question) => (
          <div key={question.questionId} className="mb-3">
            <p className="text-royalBlue font-xs font-semibold my-2">
              {question.label}
            </p>

            {question.options
              .filter((option: any) =>
                values[option.name]?.options?.includes(option.value)
              )
              .map((item: any) => (
                <p className="text-royalBlue text-base capitalize my-1" dangerouslySetInnerHTML={{ __html: item.label }} />
              ))}
            {question.questionId === 2 && values.other && (
              <p className="text-royalBlue text-base capitalize my-1">
                {values.other}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

function UMUMSeqReview({
  values,
  setSelectedTab,
  isApproval,
  isApprovalOrUpdate,
  scrollId
}: any): JSX.Element {
  const [questions, setQuestions] = useState<any>([
    {
      label: "Has the tissue in this sample been exposed to radiation?",
    },
    {
      label: "Add-On Testing: DecisionDx-Prame?",
    }
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  function handleClick(): void {
    dispatch(toggleMode());
    setTimeout(() => {
      localStorage.setItem('forceTab', 'clinical info')
      if (isApproval) {
        history.replace(
          `/test-orders/online-order/${values?.orderId}?section=clinical info`
        );
        return;
      }
      const section: any = document.getElementById("clinical info");
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      setSelectedTab("clinical info");
    }, 500);
  }

  useEffect(() => {
    if (values?.selectedTest.checkedIndexes.length == 1 && values?.selectedTest.checkedIndexes.includes(7)) {
      setQuestions((prevState: any) => prevState.filter((item: any) => item.label !== 'Add-On Testing: DecisionDx-Prame?'))
    } else {
      setQuestions([
        {
          label: "Has the tissue in this sample been exposed to radiation?",
        },
        {
          label: "Add-On Testing: DecisionDx-Prame?",
        }
      ])
    }
  }, [values?.selectedTest.checkedIndexes.length])

  return (
    <div className={`${scrollId ? 'scroll-my-5' : ''} mb-6`} id={scrollId ?? ''}>
      <p className="text-royalBlue font-medium uppercase mb-6 tracking-[0.18em]">Clinical Information</p>
      <div className="bg-[#F9F9FA] px-6 pb-3 border border-[#DCDFE4] rounded ">
        <div className="flex justify-between items-center mt-4">
          <p className="text-royalBlue text-base font-medium">Clinical Information</p>
          {/* {
            isApprovalOrUpdate && values?.status < 40 ? (
              <button type="button" onClick={handleClick}>
                <EditIcon />
              </button>
            ) : <button type="button" onClick={handleClick}>
              <EditIcon />
            </button>} */}
          {values?.status < 40 && <button type="button" onClick={handleClick}>
            <EditIcon />
          </button>}
        </div>
        {questions.map((question: any, index: number) => (
          <div key={question.label} className="mt-4">
            <p className="text-royalBlue text-base font-medium">
              {question.label}
            </p>
            <p className="text-royalBlue text-base capitalize">
              {values[`question${index + 1}`] == "N/A"
                ? "I don't know"
                : values[`question${index + 1}`]}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
function DiffDxReview({
  values,
  setSelectedTab,
  isApproval,
  isApprovalOrUpdate,
  scrollId
}: any): JSX.Element {
  const [questions, setQuestions] = useState<any>([
    // {
    //   id: 1,
    //   label: "Is the malignant potential of this melanocytic lesion uncertain?",
    //   tooltip:
    //     "Select Yes if the malignant potential of this melanocytic lesion uncertain.",
    // },
    {
      id: 1,
      label: `I attest the specimen being submitted for testing is a primary cutaneous melanocytic neoplasm for which the
      diagnosis is uncertain, (despite the performance of standard-of-care test procedures and relevant ancillary
      tests), the patient may be subjected to additional intervention as a result of the diagnostic uncertainty, and this
      patient was not receiving immunosuppressant or radiation therapy at the time of biopsy.`,
    },
    // {
    //   id: 2,
    //   label: "Does this lesion have spitzoid features?",
    //   tooltip: "Select Yes if  this lesion have spitzoid features.",
    // },
    // {
    //   id: 3,
    //   label: "Is this lesion junctional/intraepidermal?",
    //   tooltip: "Select Yes if this lesion junctional/intraepidermal.",
    // },
    // {
    //   id: 4,
    //   label: 'Please select if you only wish to have one of the three tests.',
    //   tooltip: '',
    // }
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  function handleClick(): void {
    dispatch(toggleMode());
    setTimeout(() => {
      localStorage.setItem('forceTab', 'clinical info')
      if (isApproval) {
        history.replace(
          `/test-orders/online-order/${values?.orderId}?section=clinical info`
        );
        return;
      }
      const section: any = document.getElementById("clinical info");
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      setSelectedTab("clinical info");
    }, 500);
  }

  return (
    <div className={`${scrollId ? 'scroll-my-5' : ''} mb-6`} id={scrollId ?? ''}>
      <p className="text-royalBlue font-medium uppercase mb-6 tracking-[0.18em]">Clinical Information</p>
      <div className="bg-[#F9F9FA] px-6 pb-3 border border-[#DCDFE4] rounded ">
        <div className="flex justify-between items-center mt-4">
          <p className="text-royalBlue text-base font-medium">Clinical Information</p>
          {/* {
            isApprovalOrUpdate && values?.status < 40 ? (
              <button type="button" onClick={handleClick}>
                <EditIcon />
              </button>
            ) : <button type="button" onClick={handleClick}>
              <EditIcon />
            </button>} */}
          {values?.status < 40 && <button type="button" onClick={handleClick}>
            <EditIcon />
          </button>}
        </div>
        {questions.map((question: any, index: number) => {
          // if (question.id == 4) {
          //   return <div key={question.label} className="mt-4">
          //     <p className="text-royalBlue text-base font-medium">
          //       {question.label}
          //     </p>
          //     <p className="text-royalBlue text-base capitalize">{values[`question${index + 1}`] === 'CST' ? "Castle Standard Testing"
          //       : values[`question${index + 1}`] === 'MPO' ? 'MyPath Only' : "DiffDx-Melanoma Only"}</p>
          //   </div>
          // }
          return <div key={question.label} className="mt-4">
            <p className="text-royalBlue text-base font-medium">
              {question.label}
            </p>
            <p className="text-royalBlue text-base capitalize">
              {values[`question${index + 1}`] == "N/A"
                ? "I don't know"
                : values[`question${index + 1}`] === undefined ? '---' : values[`question${index + 1}`]}
            </p>
          </div>
        })}
      </div>
    </div>
  );
}

function DXMelanomaReview({
  values,
  setSelectedTab,
  isApproval,
  isApprovalOrUpdate,
  scrollId,
}: any): JSX.Element {
  const [questions, setQuestions] = useState<any>([
    {
      label:
        "Has the patient had a sentinel lymph node biopsy for this melanoma?",
      note: "Please provide the surgical pathology report in 'Supporting Documents' tab, if available",
      tooltip:
        "Select Yes if a SLNB was performed for this melanoma.\nIf SLNB was performed, select the SLNB result.",
      extraField: {
        label: "Lymph Node Status",
        name: "lymph_node_status",
        placeholder: "Select lymph node status",
        suggestions: ["val1", "val2", "val3"],
      },
    },
    {
      label: "Does/Did this patient have clinically palpable nodes?",
      tooltip:
        "Select Yes if the patient has ever had clinically palpable nodes",
    },
    {
      label: "Does/Did this patient have intransit metastasis?",
      tooltip: "Select Yes if the patient has ever had  intransit metastasis",
    },
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  function handleClick(): void {
    dispatch(toggleMode());
    setTimeout(() => {
      localStorage.setItem('forceTab', 'clinical info')

      if (isApproval) {
        history.replace(
          `/test-orders/online-order/${values?.orderId}?section=clinical info`
        );
        return;
      }
      const section: any = document.getElementById("clinical info");
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      setSelectedTab("clinical info");
    }, 500);
  }
  return (
    <div className={`${scrollId ? 'scroll-my-5' : ''} mb-6`} id={scrollId ?? ''}>
      <p className="text-royalBlue font-medium uppercase mb-6 tracking-[0.18em]">
        Clinical Information
      </p>

      <div className="bg-[#F9F9FA] px-6 pb-3 border border-[#DCDFE4] rounded ">
        <div className="flex justify-between items-center mt-4">
          <p className="text-royalBlue text-base font-medium">Clinical Information</p>
          {/* {
            isApprovalOrUpdate && values?.status < 40 ? (
              <button type="button" onClick={handleClick}>
                <EditIcon />
              </button>
            ) : <button type="button" onClick={handleClick}>
              <EditIcon />
            </button>} */}
          {values?.status < 40 && <button type="button" onClick={handleClick}>
            <EditIcon />
          </button>}
        </div>
        {questions.map((question: any, index: number) => (
          <div key={question.label} className="mt-4">
            <p className="text-royalBlue text-base font-medium">
              {question.label}
            </p>
            <p className="text-royalBlue text-base capitalize">
              {values[`question${index + 1}`] === "N/A"
                ? "I don't know"
                : values[`question${index + 1}`]}
            </p>
            {question.extraField &&
              question.extraField.name === "lymph_node_status" &&
              values.lymphNodeStatus && (
                <p>Lymph Node Status: {values.lymphNodeStatus}</p>
              )}
          </div>
        ))}
      </div>
    </div>
  );
}

function ClinicalSection({ values, setSelectedTab, isApproval, scrollId, isUpdate }: any): any {
  const { selectedTest } = useSelector((state: any) => state.OnlineOrderForm);
  const dispatch = useDispatch();

  if (values?.selectedTest?.id === 3) {
    return <DXMelanomaReview values={values} isApproval={isApproval} isApprovalOrUpdate={isUpdate} scrollId={scrollId} />;
  }
  if (values?.selectedTest?.id === 2) {
    return <DXSccReview values={values} isApproval={isApproval} isApprovalOrUpdate={isUpdate} scrollId={scrollId} />;
  }
  if (values?.selectedTest?.id === 5) {
    return <DiffDxReview values={values} isApproval={isApproval} isApprovalOrUpdate={isUpdate} scrollId={scrollId} />;
  }

  if (values?.selectedTest?.id === 4) {
    return <UMUMSeqReview values={values} isApproval={isApproval} isApprovalOrUpdate={isUpdate} scrollId={scrollId} />;
  }

  if (values?.selectedTest?.id === 8)
    return <IDGFormReview values={values} isApproval={isApproval} isApprovalOrUpdate={isUpdate} scrollId={scrollId} dangerouslySetLabel={true} />;

  return null;
}

function AdditionalInfoReview({
  values,
  setSelectedTab,
  isApprovalOrUpdate,
  isSummary,
  scrollId,
  isApproval
}: any): any {
  const [clinicians, setClinicians] = useState<any>([]);
  const { selectedOrganization } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const history = useHistory();

  const fields = {
    // firstName: 'First Name',
    // lastName: 'Last Name',
    fullName: "Full Name",
    fax: 'Fax',
    npi: "NPI",
    email: "Email ID",
    // phone: "Phone",
    // fax: "Fax",
    // suffix: "Suffix",
    // speciality: "Speciality",
  };

  const dispatch = useDispatch();

  function handleClick(): void {
    dispatch(toggleMode());
    setTimeout(() => {
      localStorage.setItem('forceTab', 'additional info')
      if (isApproval) {
        history.replace(
          `/test-orders/online-order/${values?.orderId}?section=additional info`
        );
        return;
      }
      const section: any = document.getElementById("additional info");
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      setSelectedTab("additional info");
    }, 500);
  }

  useEffect(() => {
    setClinicians(values?.additionalClinician ? values.additionalClinician.map((item: any) => ({ ...item, fullName: `${item?.firstName} ${item?.lastName}` })) : []);
  }, [
    values?.additionalClinician
  ]);

  if (values?.has_additional_clinician) {
    return (
      <div className={`${scrollId ? 'scroll-my-5' : ''} mb-6`} id={scrollId ?? ""}>
        <p className="text-royalBlue font-medium uppercase mb-6 tracking-[0.18em]">
          Additional Information
        </p>
        <div className="bg-[#F9F9FA] px-6 pb-3 border border-[#DCDFE4] rounded ">
          <div className="flex justify-between items-center my-3">
            <p className="text-royalBlue text-base font-medium">
              Additional Clinician (to receive the final report)
            </p>
            {!!isApprovalOrUpdate == false ? (
              <button type="button" onClick={handleClick}>
                <EditIcon />
              </button>
            ) : (
              values?.status < 40 && (
                <button type="button" onClick={handleClick}>
                  <EditIcon />
                </button>
              )
            )}
          </div>
          {clinicians.length > 0 &&
            clinicians.map((clinician: string) => {
              return (
                <div className="flex flex-wrap items-center">
                  {Object.entries(fields).map(([field, value]: any) => (
                    <div key={field} className="mr-6">
                      <p className="text-royalBlue font-xs font-semibold my-2">
                        {value}
                      </p>
                      <p className="text-royalBlue text-base capitalize">
                        {clinician[field] ? clinician[field] : "---"}
                      </p>
                    </div>
                  ))}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
  return null;
}

function SupportingDocsReview({
  values,
  setSelectedTab,
  setFieldValue,
  isValid,
  scrollId,
  isSummary,
  updateOrder,
  savingOrder,
  addingNotes,
  addNotesError,
  addNotesSuccess,
  addNotes,
  isUpdate,
  isApproval,
}: any): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [localTransitDocuments, setLocalTransitDocuments] = useState<any>(values.transitDocuments);
  const showSignCheckbox = useSignCheckbox()

  function handleClick(): void {
    dispatch(toggleMode());
    setTimeout(() => {
      localStorage.setItem('forceTab', 'supporting documents')

      if (isApproval) {
        history.replace(
          `/test-orders/online-order/${values?.orderId}?section=supporting documents`
        );
        return;
      }
      const section: any = document.getElementById("supporting documents");
      section.scrollIntoView({ behavior: "smooth", block: "start" });
      setSelectedTab("supporting documents");
    }, 500);
  }
  useEffect(() => {
    setLocalTransitDocuments(values?.transitDocuments);
  }, [values?.transitDocuments]);

  let fields: any = {};
  // if (isSummary || isApproval) {
  //   fields = {
  //     pathology_report: "Pathology Report",
  //     insurance_cards: "Insurance Cards",
  //     patient_info_demographics: "Patient Info/Demographics sheet",
  //     signed_letter: "Signed letter of medical necessity",
  //   };
  // } else {
  fields = {
    pathology_reportFile: "Pathology Report",
    insurance_cardsFile: "Insurance Cards",
    patient_info_demographicsFile: "Patient Info/Demographics sheet",
    current_prescription_medicationsFile: "Current Prescription Medications (Patient MAR)"
    // signed_letterFile: "Signed letter of medical necessity",
  };
  // }

  function handleInView(inView: boolean): void {
    if (isSummary) {
      if (inView) {
        setSelectedTab("Document Management");
      }/*  else {
        setSelectedTab("Patient and Order Information");
      } */
    }
  }
  return (
    <InView
      id={scrollId ?? "document-management"}
      threshold={1}
      // className={"md:scroll-mt-36"}
      className={`${scrollId && !!isSummary == false ? 'scroll-my-5' : 'md:scroll-mt-36'} `}
      onChange={handleInView}
    >
      <div className={`mb-6 `}>
        <p
          className={`text-royalBlue font-medium uppercase mb-6 tracking-[0.18em] ${isSummary ? "tracking-[0.18em] " : ""
            }`}
        >
          Supporting Documents
        </p>
        <div className="bg-[#F9F9FA] px-6 pb-3 border border-[#DCDFE4] rounded ">
          <SectionCard
            title={isSummary ? "Uploaded Documents" : "Upload Documents"}
            values={values}
            onClick={handleClick}
            hideEdit={isSummary || (values?.status == 90 || values?.status === 100)}
            // isFile={isSummary || isApproval ? false : true}
            isFile
            className={"pb-4"}
            fields={fields}
          />

          <Fragment>
            <AdditionalNotesSection
              title="Additional Notes"
              hideEdit={isSummary || isApproval || (values?.status == 90 || values?.status === 100)}
              isApproval={isApproval}
              isSummary={isSummary}
              localTransitDocuments={localTransitDocuments}
              setFieldValue={setFieldValue}
              onClick={handleClick}
              updateOrder={updateOrder}
              savingOrder={savingOrder}
              addingNotes={addingNotes}
              addNotesError={addNotesError}
              addNotesSuccess={addNotesSuccess}
              addNotes={addNotes}
              className={"pb-4 break-all"}
              values={values}
            />{" "}
            <UploadMoreDocsSection
              isValid={isValid}
              isSummary={isSummary}
              isUpdate={isUpdate}
              isApproval={isApproval}
              setFieldValue={setFieldValue}
              localTransitDocuments={localTransitDocuments}
              setLocalTransitDocuments={setLocalTransitDocuments}
              title="Upload and transmit more documents"
              onClick={() => { }}
              className={"pb-4"}
              values={values}
            />
          </Fragment>
          {showSignCheckbox && <div>
            <p className="text-royalBlue text-base font-medium mt-3 mb-2">Signature Allowed</p>
            <p>{values?.isPhysicianSignPermission ? 'Yes' : 'No'}</p>
          </div>}

        </div>

      </div>
    </InView>
  );
}

function ClinicianInfoSection({ isApproval, values }: any): JSX.Element {
  const { selectedOrganization, selectedPhysician } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const { data: states, error } = useSelector(
    (state: any) => state.StateAndCity
  );
  let organizationKeys = {};
  let physicianKeys = {};

  if (isApproval) {
    organizationKeys = {
      // organization_id: 'Organization Record ID',
      organization_name: "Name",
      organization_address: "Address",
      organization_city: "City",
      organization_state: "State",
      zipcode: "Zip Code",
      organization_phone: "Phone",
      organization_fax: "Fax",
    };
    physicianKeys = {
      // clinician_id: 'Ordering Clinician Record Id',
      clinician_firstName: "First Name",
      clinician_lastName: "Last Name",
      clinician_suffix: "Suffix",
      clinician_npi: "NPI",
      clinician_speciality: "Speciality",
      clinician_email: "Email",
    };
  } else {
    organizationKeys = {
      // id: 'Record ID',
      name: "Name",
      address: "Address",
      phone: "Phone",
      fax: "Fax",
      city: "City",
      state: "State",
      zipcode: "Zip Code",
    };
    physicianKeys = {
      // id: 'Ordering Record Id',
      firstName: "First Name",
      lastName: "Last Name",
      suffix: "Suffix",
      npi: "NPI",
      specialty: "Speciality",
      email: "Email ID"
    };
  }
  return (
    <div className="mb-6">
      <p className="text-royalBlue font-medium uppercase mb-6 tracking-[0.18em]">
        Clinician Information
      </p>

      <div className="bg-[#F9F9FA] px-6 pb-3 border border-[#DCDFE4] rounded">
        <p className="text-royalBlue text-base font-medium my-3">
          {"Organization"}
        </p>

        <div className="flex flex-wrap items-center">
          {Object.entries(organizationKeys).map(([field, value]: any) => {
            type ObjectKey = keyof typeof organizationKeys;
            const myKey = field as ObjectKey;

            return (
              <div key={field} className="mr-6 items-center">
                <p className="text-royalBlue font-xs font-semibold my-2">
                  {value}
                </p>
                {isApproval ? (
                  <p className="text-royalBlue text-base capitalize">
                    {values[myKey] ? values[myKey] : "---"}
                  </p>
                ) : (
                  <p className="text-royalBlue text-base capitalize">
                    {/* {selectedOrganization?.profile[field]
                      ? selectedOrganization?.profile[field]
                      : "---"} */}
                    {!!selectedOrganization && selectedOrganization[field]
                      ? value?.toLowerCase().includes('state') ? states &&
                        states.length > 0 &&
                        states.find((state: any) =>
                          `${state?.name} ${state?.state_code}`.includes(
                            selectedOrganization?.state,
                          )
                        )?.name
                        ? states.find((state: any) =>
                          `${state?.name} ${state?.state_code}`.includes(
                            selectedOrganization?.state,
                          )
                        )?.name : '---' : selectedOrganization[field]
                      : "---"}
                  </p>
                )}
              </div>
            );
          })}
        </div>
        <p className="text-royalBlue text-base font-medium my-3">
          {"Contact Info"}
        </p>

        <div className="flex flex-wrap items-center">
          {Object.entries(physicianKeys).map(([field, value]: any) => {
            type ObjectKey = keyof typeof physicianKeys;
            const myKey = field as ObjectKey;

            return (
              <div key={field} className="mr-6  items-center">
                <p className="text-royalBlue font-xs font-semibold my-2">
                  {value}
                </p>
                {isApproval ? (
                  <p className="text-royalBlue text-base capitalize">
                    {values[myKey] ? values[myKey] : "---"}
                  </p>
                ) : (
                  // <p className="text-royalBlue text-base capitalize">{selectedOrganization?.profile[field] ? selectedOrganization?.profile[field] : '---'}</p>
                  <Fragment>
                    {selectedPhysician ? (
                      <p className="text-royalBlue text-base capitalize">
                        {selectedPhysician[field]
                          ? selectedPhysician[field]
                          : "---"}
                      </p>
                    ) : (
                      selectedPhysician && (
                        <p className="text-royalBlue text-base capitalize">
                          {selectedPhysician[field]
                            ? selectedPhysician[field]
                            : "---"}
                        </p>
                      )
                    )}
                  </Fragment>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
interface ReviewProps {
  values?: any;
  setSelectedTab?: any;
  setFieldValue?: any;
  isValid?: any;
  isSummary?: any;
  isApproval?: any;
  openOrder?: any;
  className?: any;
  id?: any;
  isUpdate?: any;
  errors?: any;
  touched?: any;
  updateOrder?: any;
  savingOrder?: any;
  setFieldError?: any;
  selectedTab?: any;
  addingNotes?: boolean,
  addNotesError?: boolean,
  addNotesSuccess?: boolean,
  addNotes?: any,
  orderData?: any
  retryOrder?: any
  initialValues?: any
  patientType?: any
  setValidSections?: any
  fetchOrderById?: any
}

function ReviewSection({
  values,
  setSelectedTab,
  setFieldValue,
  isValid,
  isSummary,
  isApproval,
  openOrder,
  className,
  updateOrder,
  orderData,
  savingOrder,
  addingNotes,
  addNotesError,
  addNotesSuccess,
  addNotes,
  id,
  isUpdate,
  retryOrder,
  initialValues,
  setFieldError,
  patientType,
  selectedTab,
  touched,
  setValidSections,
  errors,
  fetchOrderById
}: ReviewProps): JSX.Element {
  const dispatch = useDispatch();
  const { authState } = useOktaAuth();
  const [data, setData] = useState<any>(initialData);
  const [downloadingPDF, setDownloadingPDF] = useState<boolean>(false);
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);
  const [downloadingPDFOrderFiles, setDownloadingPDFOrderFiles] = useState<boolean>(false)
  const history = useHistory();
  const query = useQuery();
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { disableSave } = useSelector((state: any) => state.ButtonState)
  const { selectedPhysician } = useSelector((state: any) => state?.OnlineOrderForm)

  let retry = query.get('retry')
  const tabsData = {
    "Patient Information": "patient info",
    "Billing Information": "billing info & medicare",
    "Clinician Information": "clinical information",
    "Additional Information": "additional info",
    "Laboratory Information": "laboratory info",
    "Supporting Documents": "supporting documents",
  };

  function handleClick(title: string): any {
    // To access Object key's
    type ObjectKey = keyof typeof tabsData;
    const myKey = title as ObjectKey;

    return () => {
      localStorage.setItem('forceTab', tabsData[myKey])
      if (isApproval) {
        history.replace(
          `/test-orders/online-order/${values?.orderId}?section=${tabsData[myKey]}`
        );
        return;
      }
      dispatch(toggleMode());
      setTimeout(() => {
        const section: any = document.getElementById(tabsData[myKey]);
        section.scrollIntoView({ behavior: "smooth", block: "start" });
        setSelectedTab(tabsData[myKey]);
      }, 500);
    };
  }
  const summaryData = [
    {
      title: "Patient and Order Information",
      sections: [
        {
          title: "Patient Information",
          fields: {
            patient_firstname: "First Name",
            patient_middleinitial: "Middle Initial",
            patient_lastname: "Last Name",
            patient_dob: "Date of Birth",
            patient_email: "Email",
          },
        },
        {
          title: "Order Information",
          fields: {
            test_type: "Test Type",
            createdAt: "Date",
            organization_name: "Organization Name",
            clinician_full_name: "Clinician",
          },
        },
      ],
    },
    {
      title: "Additional info",
      sections: [
        {
          title: 'Additional Info'
        }
      ]
    },
    {
      title: "Supporting Documents",
      subtitle1:
        "Files will only be saved/uploaded on final submission after signature.",
      subtitle2:
        "If the Online Order is exited for future completion, documents will not be saved.",
      sections: [
        {
          title: "Upload Documents",
          fields: {
            pathology_report: "Pathology Report",
            insurance_cards: "Insurance Cards",
            patient_info_demographics: "Patient Info/Demographics sheet",
            // signed_letter: "Signed letter of medical necessity",
          },
        },
        {
          title: "Additional Notes",
          fields: {
            additional_notes: "Notes",
          },
        },
        {
          title: "Upload and transmit more documents",
          fields: {},
        },
      ],
    },
  ];
  // const filteredData = (selectedPhysician && selectedPhysician?.id === values?.clinician_id) ? summaryData : summaryData.filter(data => data.title !== 'Additional info');
  function PDFDownload() {
    if (downloadingPDF) return

    setDownloadingPDF(true)
    let name = `${values?.orderId}_${!!values?.patientName ? `${values?.patientName} ` : "Patient"}_${!!values?.selectedTest?.name ? values?.selectedTest?.name : ''}`;
    let token = authState?.accessToken?.accessToken ?? "";
    const onError = (error: any) => {
      toastMessages.error(error.message);
      setDownloadingPDF(false);
    };
    const onSuccess = () => {
      setDownloadingPDF(false);

    };

    DownloadHelper.downloadMultiplePDF([{ id: values.orderId, fileName: name }], token, false, onError, onSuccess)
  }
  function ReportDownload() {
    if (values?.status === 100) {
      setDownloadingReport(true);
      let name = `${values?.patient_firstname ? `${values?.patient_firstname} - ` : ""
        }${values.selectedTest.name}`;
      let token = authState?.accessToken?.accessToken ?? "";
      const onError = (error: any) => {
        toastMessages.error(error.message);
        setDownloadingReport(false);
      };
      const onSuccess = () => {
        setDownloadingReport(false);
      };

      DownloadHelper.downloadReport(
        values.orderId,
        name,
        token,
        onError,
        onSuccess
      );
    }
  }

  async function handleSaveAdditionalClinician() {

    setSubmitting(true);
    try {
      const body = {
        data: values.additionalClinician.filter((clinician: any) => clinician.status === undefined).map((additionalClinician: any) => ({
          firstName: additionalClinician.firstName,
          lastName: additionalClinician.lastName,
          fax: additionalClinician.fax,
          npi: additionalClinician.npi,
          email: additionalClinician.email,
          orderId: values?.orderId,
        })),
      };

      const config = { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } };
      const response = await http.addAdditionalClinician(`/v1/additional-clinicians`, body, config);

      setSubmitting(false);

      if (response.statusCode === 201) {

        toastMessages.success('Data saved successfully!');

        const options = {
          id: values?.orderId,
          config: {
            headers: {
              Authorization: `Bearer ${authState?.accessToken?.accessToken}`
            }
          },
          onSuccess: () => {
          },
          onError: (message: string) => {
            toastMessages.error(message);
          },
        };
        fetchOrderById(options)
      }
    } catch (error: any) {
      setSubmitting(false);
    }
  }

  function getPresignedUrl(inputFile: any, resolve: any, reject: any): any {
    if (!inputFile) return reject("File missing");
    http
      .getPresignedUrl(`v1/pre-signed-url/file?container=documents&filename=${inputFile.filename}`, {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      })
      .then(({ url, filename }: any) => {
        if (url) {
          DownloadHelper.fileDownloader(url, filename, () => { resolve(url) }, () => reject(`error downloading file ${filename}`))
        } else {
          reject("URL not provided");
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  }

  function downloadFile(inputFile: any): any {
    return new Promise((resolve, reject): void => {
      getPresignedUrl(inputFile, resolve, reject);
    });
  }
  function DownloadPDFOrderFiles() {
    if (downloadingPDFOrderFiles) return
    setDownloadingPDFOrderFiles(true)

    Promise.all([...values?.paperTRFDocuments.map((file: any) => downloadFile(file))])
      .then((results: any) => {
        setDownloadingPDFOrderFiles(false)
      })
      .catch((error: any) => {
        toastMessages.error(error ?? "Something went wrong");
        setDownloadingPDFOrderFiles(false)
      });
  }
  function handleResumeAndRetry() {
    if (values.status < 40)
      history.replace(
        `/test-orders/online-order/${values?.orderId}`
      );
    if (retry) {
      const options = {
        data: orderData,
        config: {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`
          }
        },
        onError: (message: string) => {
          toastMessages.error(message)
        },
        onSuccess: () => {
          toastMessages.success('Order has been updated successfully!')
        }
      }

      retryOrder(options)
    }
  }

  useEffect(() => {
    if (isSummary) {
      if (!!query.get("section")) {
        let sectionName = query.get("section") ?? "";
        const section: any = document.getElementById(sectionName);
        section?.scrollIntoView({ behavior: "smooth", block: "start" });
        setSelectedTab("Document Management");
      }
      setData(summaryData);
    }
  }, [values?.transitDocuments, selectedPhysician]);
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        id={`${id ? `${id}select test` : ''}`}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className={`pb-14 py-5 ${className ?? ""} ${id ? 'scroll-mt-14' : ''}`}
      >
        {data.map((mainSection: any) => {
          if (mainSection.title == "Supporting Documents") {
            return (
              <SupportingDocsReview
                isValid={isValid}
                isSummary={isSummary}
                scrollId={!!id ? `${id}supporting documents` : isSummary ? 'document-management' : 'review supporting documents'}
                isUpdate={isUpdate}
                isApproval={isApproval}
                updateOrder={updateOrder}
                savingOrder={savingOrder}
                setFieldValue={setFieldValue}
                addingNotes={addingNotes}
                addNotesError={addNotesError}
                addNotesSuccess={addNotesSuccess}
                addNotes={addNotes}
                values={values}
                setSelectedTab={setSelectedTab}
              />
            );
          }

          if (mainSection.title == "Additional info" && isSummary ) {
            return (
              <div
                key={mainSection.title}
                id={
                  (isSummary &&
                    mainSection.title === "Additional info")
                  && "additional-info"
                }
                className={`mb-6 ${isSummary ? "scroll-mt-[10px]" : ""} ${id ? 'scroll-my-1' : ''}`}>
                <div className={`flex justify-between ${selectedTab === 'additional info' && 'lg:sticky lg:top-0 bg-white pt-7 pb-5 lg:z-20 border border-white w-full'}${isSummary ? 'w-[110%]' : 'w-full'}`}>
                  <p
                    className={`text-royalBlue font-medium uppercase mb-6 tracking-[0.18em] ${isSummary ? "tracking-[0.18em] sticky mb-0" : ""
                      }`}
                  >
                    Additional Info</p>
                  <Button
                    btnType="primary"
                    className={'justify-end'}
                    onClick={handleSaveAdditionalClinician}
                    title={'SAVE'}
                    submitting={submitting}
                    disabled={submitting || disableSave || values?.additionalClinician?.filter((e: any) => e?.status === undefined).length === 0}
                  />
                </div>
                <AdditionalInfoSection
                  initialValues={initialValues}
                  values={values}
                  scrollId={'additional info'}
                  setFieldError={setFieldError}
                  patientType={patientType}
                  selectedTab={selectedTab}
                  isValid={isValid}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSelectedTab={setSelectedTab}
                  isSummary={isSummary}
                // setValidSections={setValidSections}
                />
              </div>
            );
          }

          if (mainSection.title == "Additional Information") {
            return (
              <AdditionalInfoReview
                isApprovalOrUpdate={isApproval || isUpdate}
                updateOrder={updateOrder}
                scrollId={!!id ? `${id}additional info` : 'review additional info'}
                savingOrder={savingOrder}
                isApproval={isApproval}
                key={mainSection.title}
                values={values}
                setSelectedTab={setSelectedTab}
              />
            );
          }

          if (mainSection.title == "Clinical Information") {
            return (
              <ClinicalSection
                scrollId={!!id ? `${id}clinical info` : 'review clinical info'}
                isApproval={isApproval}
                isUpdate={isUpdate}
                key={mainSection.title}
                values={values}
                setSelectedTab={setSelectedTab}
              />
            );
          }
          if (mainSection.title === "Clinician Information") {
            return (
              <ClinicianInfoSection
                id={id}
                isApproval={isApproval}
                values={values}
                key={mainSection.title}
              />
            );
          }
          return (
            <div
              key={mainSection.title}
              id={
                isSummary &&
                  mainSection.title === "Patient and Order Information"
                  ? "patient-order-info"
                  : !!id ? `${id}${mainSection.scrollId}` : `review ${mainSection.scrollId}`
              }
              className={`mb-6 ${isSummary ? "scroll-mt-64" : ""} ${id ? 'scroll-my-5' : ''}`}
            >
              <div
                className={`${isSummary ? "flex justify-between items-center mb-3" : ""
                  }`}
              >

                <p
                  className={`text-royalBlue font-medium uppercase mb-6 tracking-[0.18em] ${isSummary ? "tracking-[0.18em] mb-0" : ""
                    }`}
                >
                  {mainSection.title === 'Laboratory Information' && values?.selectedTest?.id === 8 ? 'Specimen Information' : mainSection.title}
                </p>
                {mainSection.subtitle1 && (
                  <p className="text-royalBlue mb-3 !text-base">
                    {mainSection.subtitle1}
                  </p>
                )}
                {isSummary && (
                  <div className="flex items-center mt-0 tab:space-x-3 py-0">
                    <Button
                      btnType="secondary"
                      submitting={downloadingPDF}
                      disabled={downloadingPDF ? true : !!values?.eRequestId == true ? false : true}
                      onClick={PDFDownload}
                      title={
                        values?.status < 40
                          ? "Download Order"
                          : "Download receipt"
                      }
                      // title={'Download Order'}
                      className={"lg:mr-1 xl:mr-5 2xl:mr-5"}
                    />

                    <Button
                      btnType="secondary"
                      onClick={ReportDownload}
                      submitting={downloadingReport}
                      disabled={downloadingReport ? true : values?.status === 100 &&
                        !!values.orderReport == true && (Array.isArray(values.orderReport) && values.orderReport.length > 0)
                        ? false
                        : true
                      }
                      title={"Download report"}
                      className={"mt-0 lg:mr-1 xl:mr-5 2xl:mr-5 "}
                    />
                    <Button
                      btnType="primary"
                      onClick={handleResumeAndRetry}
                      submitting={savingOrder}
                      // disabled={retry == null || retry == '0' || retry == '' || values.status > 40}
                      disabled={values?.status < 40 ? false : values.status == 40 && retry == '1' ? false : true}
                      title={!!retry && retry == '1' && values.status == 40 ? 'Retry' : 'Resume'}
                      className={"mt-0"}
                    />
                  </div>
                )}
                {mainSection.subtitle2 && (
                  <p className="text-royalBlue mb-6 !text-base">
                    {mainSection.title === "Laboratory Information" && !!values?.typeofspecimen ? values?.typeofspecimen?.includes('Slides') ? 'Slides/Block' : values.typeofspecimen : 'Slides/Block'}
                  </p>
                )}
              </div>
              {<div className="bg-[#F9F9FA] px-6 pb-3 border border-[#DCDFE4] rounded ">
                {mainSection?.sections?.length > 0 &&
                  mainSection.sections.map((section: any) => {
                    if (
                      section.title === "Medicare" &&
                      (values?.insurance?.includes("Medicare") ||
                        values?.insurance?.includes("Medicaid")) === false
                    ) {
                      return null;
                    }
                    return ((
                      <SectionCard
                        key={section.title}
                        hideEdit={
                          (mainSection.title === "Clinician Information" ||
                            isSummary ||
                            values?.status >= 40) ?? false
                        }
                        values={values}
                        title={(values?.selectedTest?.id === 8 && section.title === "Laboratory") ? 'Specimen' : section.title}
                        fields={section.fields}
                        onClick={handleClick(mainSection.title)}
                      />
                    )
                    );

                  })}
                {isSummary &&
                  mainSection.title === "Patient and Order Information" && (
                    <InView threshold={0.75} onChange={(inView) => {
                      if (inView)
                        setSelectedTab("Patient and Order Information");
                    }}>
                      <div className="flex items-center justify-end">
                        {values?.orderType == "Paper TRF" &&
                          <Button
                            btnType="secondary"
                            submitting={downloadingPDFOrderFiles}
                            disabled={values?.paperTRFDocuments && values?.paperTRFDocuments?.length == 0}
                            title={"Download PDF Order"}
                            onClick={DownloadPDFOrderFiles}
                            className={"lg:mr-1 xl:mr-5 2xl:mr-5"}

                          />
                        }
                        <Fragment>
                          <Button
                            btnType="transparent"
                            title={
                              values?.status < 40
                                ? "Click to Review Draft Order "
                                : "click to see entire order"
                            }
                            onClick={openOrder}
                            className={"my-3"}
                          />
                          <button
                            onClick={openOrder}
                            type="button"
                            className={"-rotate-90 ml-3 mb-3"}
                          >
                            <DropDownIcon fill="#18B5B7" height={20} width={25} />
                          </button>
                        </Fragment>
                      </div>
                    </InView>
                  )}
              </div>}
            </div>
          );
        })}
      </motion.div>
    </AnimatePresence>
  );
}
const mapStateToProps = (state: any): any => ({
  savingOrder: state.OnlineOrderForm.savingOrder,
  addingNotes: state.OnlineOrderForm.addingNotes,
  addNotesError: state.OnlineOrderForm.addNotesError,
  addNotesSuccess: state.OnlineOrderForm.addNotesSuccess,
  orderData: state.OnlineOrderForm.orderData
});

const mapDispatchToProps = {
  updateOrder: updateOnlineOrder,
  retryOrder: retryOrder,
  fetchOrderById: fetchOrderById,
  addNotes: addNotes
};
export default connect(mapStateToProps, mapDispatchToProps)(ReviewSection);
