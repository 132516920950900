function SummarySectionIndicator(): JSX.Element {
  return <div className="w-full">
    <div className="my-8">
      <div className="flex justify-between items-center">
        <div className="py-3 w-2/6 rounded-full bg-dropDownBorder animate-pulse" />
        <div className="py-5 w-2/6 rounded-md bg-dropDownBorder animate-pulse" />
      </div>
      <div className="bg-[#F9F9FA] p-6 rounded-md my-2">
        <div className="py-3 w-1/6 rounded-full bg-dropDownBorder animate-pulse" />
        <div className="flex w-full">
          <div className="w-1/5 my-5">
            <div className="p-2 w-3/6 bg-dropDownBorder rounded-full" />
            <div className="p-2 w-4/6 bg-dropDownBorder rounded-full my-2" />
          </div>
          <div className="w-1/5 my-5">
            <div className="p-2 w-3/6 bg-dropDownBorder rounded-full" />
            <div className="p-2 w-4/6 bg-dropDownBorder rounded-full my-2" />
          </div>
          <div className="w-1/5 my-5">
            <div className="p-2 w-3/6 bg-dropDownBorder rounded-full" />
            <div className="p-2 w-4/6 bg-dropDownBorder rounded-full my-2" />
          </div>
          <div className="w-1/5 my-5">
            <div className="p-2 w-3/6 bg-dropDownBorder rounded-full" />
            <div className="p-2 w-4/6 bg-dropDownBorder rounded-full my-2" />
          </div>
        </div>

      </div>
    </div>
    <div className="my-8">
      <div className="flex justify-between items-center">
        <div className="py-3 w-2/6 rounded-full bg-dropDownBorder animate-pulse" />
        <div className="py-5 w-2/6 rounded-md bg-dropDownBorder animate-pulse" />
      </div>
      <div className="bg-[#F9F9FA] p-6 rounded-md my-2">
        <div className="py-3 w-1/6 rounded-full bg-dropDownBorder animate-pulse" />
        <div className="flex w-full">
          <div className="w-1/5 my-5">
            <div className="p-2 w-3/6 bg-dropDownBorder rounded-full" />
            <div className="p-2 w-4/6 bg-dropDownBorder rounded-full my-2" />
          </div>
          <div className="w-1/5 my-5">
            <div className="p-2 w-3/6 bg-dropDownBorder rounded-full" />
            <div className="p-2 w-4/6 bg-dropDownBorder rounded-full my-2" />
          </div>
          <div className="w-1/5 my-5">
            <div className="p-2 w-3/6 bg-dropDownBorder rounded-full" />
            <div className="p-2 w-4/6 bg-dropDownBorder rounded-full my-2" />
          </div>
          <div className="w-1/5 my-5">
            <div className="p-2 w-3/6 bg-dropDownBorder rounded-full" />
            <div className="p-2 w-4/6 bg-dropDownBorder rounded-full my-2" />
          </div>
        </div>

      </div>
    </div>
  </div>
}

export default SummarySectionIndicator;