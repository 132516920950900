import NotificationCard from "./NotificationCard"
import moment from 'moment'
import { Fragment } from "react"
import NotificationsDataModel from "../../models/notificationsModels/notificationsData.model"
import EmptyDataSVG from "../../assets/svg/EmptyDataSVG"
import NotificationsListLoadingIndicator from "../global/LoadingIndicators/NotificationsListLoadingIndicator"
import { useSelector } from "react-redux"
interface NotificationListProps {
  notificationsList: NotificationsDataModel[],
  fetchingNotifications: boolean,
  fetchingNotificationsError: boolean,
}

function NotificationsSection(data: NotificationsDataModel): JSX.Element {
  const { filters } = useSelector((state: any) => state.UserNotifications)
  const filteredData = data.list.filter((item: any) => {
    const filtersWithValues = Object.keys(filters).filter((filter) => filters[filter] !== '' && filter !== 'createdAt');

    if (filtersWithValues.length > 0)
      return filtersWithValues.every((filter) => item[filter] === filters[filter])

    return true
  })

  return <div className="my-4">
    <p className="mb-4">{moment()?.isSame(data?.date, 'day') ? 'TODAY' : 'EARLIER'}</p>
    {filteredData.length === 0 && <div className="bg-white w-full px-10 py-6 flex items-center">
      <EmptyDataSVG />
      <p className="text-royalBlue font-semibold text-lg mx-2">No data found</p>
    </div>}
    {/* <div className="max-h-[22vh] lg:max-h-[25vh] overflow-auto"> */}
      {
        filteredData?.map((notification, index) =>
          <NotificationCard
            // key={notification?.time?.toString()}
            hideBorder={index + 1 === data.list.length}
            {...notification}
          />)
      }
    {/* </div> */}
  </div>
}

function NotificationsList({ notificationsList, fetchingNotifications, fetchingNotificationsError, }: NotificationListProps): JSX.Element {
  if (fetchingNotifications) {
    return <NotificationsListLoadingIndicator />
  }
  return <Fragment>
    <div className="max-h-[55vh] lg:max-h-[55vh] overflow-auto">
    {
      notificationsList
        ?.map((data) =>
          <NotificationsSection
            key={data?.date?.toString()}
            {...data}
          />
        )
    }
    </div>
  </Fragment>
}

export default NotificationsList