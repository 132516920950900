import Button from "../atomic/Button";
import "./styles.css";
import SearchIcon from "../../assets/svg/Search";
import { useCallback, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { connect } from "react-redux";
import HelpActions from "../../Store/Actions/HelpActions";
import lodash from "lodash";

function TopSearch({ fetchHelp }: any) {
  const { authState } = useOktaAuth();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = async (searchQuery: string) => {
    let options = {
      accessToken: authState?.accessToken?.accessToken,
      onSuccess: () => {
        console.log("success");
      },
      onError: (error: any) => {
        console.log(error);
      },
      searchQuery,
    };
    fetchHelp(options);
  };

  const debounceSearch = useCallback(lodash.debounce(handleSearch, 1000), []);

  const handleInput = (e: any) => {
    setSearchQuery(e.target.value)
    debounceSearch(e.target.value)
  }

  return (
    <>
      <div className="sticky top-0 bg-[#18B5B7] py-5 z-20 border border-primary align-center justify-center flex">
        <div className="p-2 px-4 rounded my-4 text-base font-medium text-[32px] text-[#FFFFFF] leading-10">
          <p>Hi, how can we help you today?</p>
          <div className="relative mt-7 flex justify-between items-center button-input">
            <div className="flex items-center w-full">
              <SearchIcon />
              <input
                type="text"
                placeholder="Search topics"
                className=" w-full custom-input mx-2"
                value={searchQuery}
                onChange={handleInput}
              />
            </div>
            <Button
              onClick={(e) => handleSearch(searchQuery)}
              title="Search"
              btnType="primary"
              type={'button'}
              className="h-full px-5 py-2 text-primary  bg-[#FFFFFF] -mt-2"
              buttonForSearch
            />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  fetching: state.Help.fetching,
  error: state.Help.error,
  data: state.Help.data,
});

const mapDispatchToProps = {
  fetchHelp: HelpActions.fetchHelp,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopSearch);
