import '../../DataTable/style.css'

function BodyItemLoader({ hideBorderBottom }: any) {
    return <tr >
        <td className={`flex justify-between items-center mt-[2px] ${hideBorderBottom ? '!border-b-0 ' : ''}`}>
            <div className='h-3 w-3 rounded-full bg-dropDownBorder mr-2' />
            <div className='p-1.5 bg-dropDownBorder w-5/6 rounded-lg ' />
        </td>
        <td className={hideBorderBottom ? '!border-0' : 'border-b border-dropDownBorder'}><div className='w-full p-1.5 bg-dropDownBorder rounded-lg' /></td>
        <td className={hideBorderBottom ? '!border-0' : 'border-b border-dropDownBorder'}><div className='w-3/6 p-1.5 bg-dropDownBorder rounded-lg' /></td>
        <td className={hideBorderBottom ? '!border-0' : 'border-b border-dropDownBorder'}><div className='w-3/6 p-1.5 bg-dropDownBorder rounded-lg' /></td>
        <td className={`flex justify-between items-center mt-[2px] ${hideBorderBottom ? '!border-b-0 ' : ''}`}>
            <div className='h-3 w-3 rounded-full bg-dropDownBorder mr-2' />
            <div className='p-1.5 bg-dropDownBorder w-5/6 rounded-lg ' />
        </td>
        {/* <td className={hideBorderBottom ? '!border-0' : 'border-b border-dropDownBorder'}><div className='w-4/6 p-1.5 bg-dropDownBorder rounded-lg' /></td>
        <td className={hideBorderBottom ? '!border-0' : 'border-b border-dropDownBorder'}><div className='w-2/6 p-1.5 bg-dropDownBorder rounded-lg' /></td>
        <td className={hideBorderBottom ? '!border-0' : 'border-b border-dropDownBorder'}><div className='w-2/6 p-1.5 bg-dropDownBorder rounded-lg' /></td>
        <td className={`flex justify-between items-center mt-[2px] ${hideBorderBottom ? '!border-b-0 ' : ''}`}>
            <div className='h-3 w-3 rounded-full bg-dropDownBorder mr-2' />
            <div className='p-1.5 bg-dropDownBorder w-5/6 rounded-lg ' />
        </td>
        <td className={hideBorderBottom ? '!border-0' : 'border-b border-dropDownBorder'}><div className='w-2/6 p-1.5 bg-dropDownBorder rounded-lg' /></td>
        <td className={hideBorderBottom ? '!border-0' : 'border-b border-dropDownBorder'}><div className='w-2/6 p-1.5 bg-dropDownBorder rounded-lg' /></td> */}
    </tr>
}

function TableLoadingIndicator() {
    const tableHeader = [1, 2, 3, 4, 5]
    return <div className="data-table z-1 overflow-auto mt-3 tab:w-[150%]">
        <table className='animate-pulse'>
            <thead className="data-table-header-container animate-pulse">
                <tr>
                    {tableHeader.map((item: any) => <th key={item}>
                        <div className='flex justify-between items-center w-full'>
                            <div className='bg-dropDownBorder w-5/6 p-1.5 rounded-lg' />
                            <div className='bg-dropDownBorder w-3 h-3 rounded-full' />
                        </div>
                        <div className='w-3/6 p-1.5 bg-dropDownBorder mt-2 rounded-lg' />
                    </th>)}

                </tr>
            </thead>
            <tbody>
                {tableHeader.map((item: any) => <BodyItemLoader hideBorderBottom={item === 10 ?? false} />)}
            </tbody>
        </table>
    </div>
}

export default TableLoadingIndicator
