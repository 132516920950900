import { useOktaAuth } from "@okta/okta-react"
import http from "../../../../Store/API/HttpMethods"
import CustomInput from "../../../../components/global/CustomInput"
import dataConstants from "../../../../constants/dataConstants"
import toastMessages from "../../../../utils/helpers/toastMessage"
import { useState } from "react"
import getKeyByValue from "../../../../utils/helpers/keyFromObjectValue"
import * as Yup from "yup";
import moment from "moment"
import dateFormatter from "../../../../utils/helpers/dateFormatter"

const availableTimeAPICallSchema =
  Yup.object().shape({
    contactName: Yup.string()
      .required("Contact name is required!")
      .test(
        "no-empty-blank-space",
        "Contact name cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    company: Yup.string()
      .required("Company is required!")
      .test(
        "no-empty-blank-space",
        "Company cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    address1: Yup.string()
      .required("Address is required!")
      .test(
        "no-empty-blank-space",
        "Address cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    city: Yup.string()
      .required("City is required!")
      .test(
        "no-empty-blank-space",
        "City cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    state: Yup.string()
      .required("State is required!")
      .test(
        "no-empty-blank-space",
        "State cannot be an empty blank space(s)",
        (value: any) => {
          return value && value.trim() !== "";
        }
      ),
    zipCode: Yup.string()
      .min(5, "Zip code length must equal to 5")
      .max(5, "Zip code length must equal to 5")
      .matches(/^[0-9]+$/, "Zip code must be numbers").required('Zip Code is required!'),
    phone: Yup.string().test("phone-validation", "Phone must contain 10 digits with the following format 000-000-0000", (value) => {
      if (!value) {
        return true;
      }
      return /\d{3}-\d{3}-\d{4}/.test(value);
    }).required('Phone is required!'),
    pickupDate: Yup.string()
      .test(
        "custom-date-validator",
        (value: string | undefined) => !!dateFormatter(value)
      )
      .required("Pickup Date is required!"),
  })
export interface AvailableTimePayload {
  contactName: string,
  company: string,
  address1: string,
  address2?: string,
  state: string,
  city: string,
  phone: string,
  zipCode: string,
  pickupDate: string,
  closeTime: string,
}

const RequiredValues: AvailableTimePayload = {
  contactName: '',
  company: '',
  address1: '',
  state: '',
  phone: '',
  city: '',
  zipCode: '',
  pickupDate: '',
  closeTime: '',
}

async function getAvailableTimeOptions({ data, authState }: { data: AvailableTimePayload, authState: any }) {

  try {
    const config = { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } }
    const response = http.fetchAvailableTimeOptions('/v1/fedex/schedule-pickup/availability-times', data, config)
    return response
  } catch (error: any) {
    return error
  }
}

function PickupInformation({ initialValues, values, setFieldValue, errors, touched, setFieldError, validateForm, fetchPatients, patients, loading, ...rest }: any): JSX.Element {
  const date = new Date()
  const maxDate = date
  const { authState } = useOktaAuth()
  const [availableTimeOptions, setAvailableTimeOptions] = useState<any[]>([])
  const isRequiredFieldMissing = (updatedValues: any) => Object.keys(RequiredValues).findIndex((key) => updatedValues[key] === '') !== -1
  const [fetchingAvailableTime, setFetchingAvailableTime] = useState<boolean>(false)



  function handleChange(input: any, name: string): void {
    const updatedValues = { ...values }
    updatedValues[name] = input
    setFieldValue(name, input)


    if (name === 'closeTime' || name === 'pickupDate' && !isRequiredFieldMissing(updatedValues)) {
      availableTimeAPICallSchema
        .validate(updatedValues, { abortEarly: false })
        .then(res => {
          setFetchingAvailableTime(true)
          getAvailableTimeOptions({
            data: {
              contactName: updatedValues.contactName,
              company: updatedValues.company,
              address1: updatedValues.address1,
              address2: updatedValues.address2,
              state: updatedValues.state,
              city: updatedValues.city,
              phone: updatedValues.phone,
              zipCode: updatedValues.zipCode,
              pickupDate: updatedValues.pickupDate,
              closeTime: getKeyByValue(dataConstants.TimeKey, updatedValues.closeTime) ? getKeyByValue(dataConstants.TimeKey, updatedValues.closeTime) : updatedValues.closeTime,

            }, authState
          })
            .then((res: any) => {
              const cutOffTime = res.options?.[0]?.cutOffTime

              if (res?.options?.[0]?.readyTimeOptions?.length > 0) {
                const availableTime = dataConstants.TimeData.filter((time) => {
                  let currentTime = moment().get('hours')
                  let timeHour = time.value.split(':')[0]

                  if (updatedValues.pickupDate !== moment().format('MM-DD-YYYY') && (time.value <= cutOffTime)) {
                    return true
                  }

                  if (availableTimeOptions.includes(time.value) && !(Number(timeHour) - currentTime <= 0) && (time.value <= cutOffTime))
                    return true

                  return false
                }).map((time) => ({ label: time.label, value: time.label }))

                setAvailableTimeOptions(availableTime)

                if (availableTime.length === 0) {
                  toastMessages.error('FedEx Response: No time available for selected date. Please select another date.')
                }

              }
              setFetchingAvailableTime(false)
            }).catch((error: any) => {
              const errorResponse = error.response
              const errors = error?.response?.errors
              if (error?.response?.data?.errors[0]?.code === 'POSTALCODE.INFO.INVALID') {
                toastMessages.error('Please enter valid zip code.')
              } else {
                toastMessages.error(errors?.length > 0 ? errors.map((error: any) => error.message).join('') : error.message)
              }
              setFetchingAvailableTime(false)
            }
            )
        }).catch(err => {
          const errors = err.errors
          return toastMessages.error(errors.join(', '))
        })
    }

    validateForm(updatedValues)
      .then((res: any) => {
        if (Object.keys(res).length == 0)
          rest?.setScheduleDisabled(false)
        else
          rest?.setScheduleDisabled(true)

        rest?.setValidFields(rest?.requiredFields - Object.keys(res).length)

      })
      .catch((errors: any) => {
        rest?.setScheduleDisabled(true)
      });
  }

  return <div className="focus:outline-primary p-5 relative my-5 shadow-sm border border-[0.5px] rounded-md flex flex-col hover:border-primary hover:border">

    <p className="text-royalBlue font-medium text-base">Pickup Information</p>
    <div className="flex justify-between items-center">
      <CustomInput
        id={'pickupDate'}
        key={'pickupDate'}
        name={'pickupDate'}
        label={'Pickup Date'}
        isInternal
        value={values?.pickupDate}
        max={new Date(maxDate)}
        type={'date'}
        onChange={(e: any) => handleChange(e, 'pickupDate')}
        required
        placeholder={'MM/DD/YYYY'}
        inputClassName="mt-1"
        errors={errors}
        className={'mr-5 relative'}
        // dropDownContainerStyle="p-0"
        touched={touched}
      />
      <CustomInput
        id={'closeTime'}
        key={'closeTime'}
        name={'closeTime'}
        label={'Close Time'}
        disableSort
        isInternal
        // value={values.closeTime ? dataConstants.TimeData.find((time) => time.value === values.closeTime)?.label : ''}
        // suggestions={dataConstants.TimeData}
        suggestions={dataConstants.TimeData.filter((time) => {
          let currentTime = moment().get('hours')
          let timeHour = time.value.split(':')[0]

          if (Number(timeHour) - currentTime <= 0) {
            return false
          }

          return true

        }).map((time) => ({ label: time.label, value: time.label }))}
        value={values.closeTime ? dataConstants.TimeKey[values.closeTime] : ''}
        onChange={(e: any) => handleChange(e, 'closeTime')}
        required
        placeholder={'Select close time'}
        errors={errors}
        className=""
        disableSuggestiveFiltering
        suggestionInputDisabled
        touched={touched}
      />
    </div>

    {fetchingAvailableTime && <div className='top-0 bottom-0 left-0 right-0  w-full h-full absolute' style={{ zIndex: 2000,/*  backgroundColor: 'rgba(0,0,0,0.2)' */ }}>
      <div className={`flex w-full h-full justify-center items-center`} >
        <div className='animate-spin'>
          <img src={require('../../../../assets/images/loadingPNG.png')} className={'h-[30px] w-[30px]'} />
        </div>
      </div>
    </div>}
    <CustomInput
      id={'readyDateTimestamp'}
      key={'readyDateTimestamp'}
      name={'readyDateTimestamp'}
      label={'Available Ready Time'}
      isInternal
      // value={values.readyTime ? dataConstants.TimeData.find((time) => time.value === values.readyTime)?.label : ''}
      value={values.readyDateTimestamp ? dataConstants.TimeKey[values.readyDateTimestamp] : ''}
      suggestions={availableTimeOptions}
      onChange={(e: any) => handleChange(e, 'readyDateTimestamp')}
      required
      placeholder={'Select available ready time'}
      errors={errors}
      disabled={isRequiredFieldMissing(values) || availableTimeOptions.length == 0}
      disableSuggestiveFiltering
      suggestionInputDisabled
      disableSort
      className={'mr-5'}
      touched={touched}
    />
  </div>
}

export default PickupInformation