import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { useSelector } from "react-redux"
import FileDetail from "../global/FileDetail"
import moment from "moment"

interface FieldValueProps {
  field: string,
  value: string,
  values: any,
  isFile: boolean
  isPDF: boolean
  isApproval?: any
}

interface Field {
  label: string;
  value: string;
}

interface Fields {
  [key: string]: Field;
}

function FieldValue({ field, value, values, isFile, isPDF }: FieldValueProps) {
  const { testsAndIcdCodes, selectedTest, icdCodes } = useSelector((state: any) => state.OnlineOrderForm)
  const { data: states, error } = useSelector((state: any) => state.StateAndCity)
  const [additionalCodes, setAdditionalCodes] = useState<any[]>([])
  const { selectedUser } = useSelector((state: any) => state.PortalUsers)
  const location = useLocation()
  const fields: Fields = {
    patient_firstname: {
      label: 'First Name',
      value: values?.patient_firstname || '[Pending Entry]'
    },
    patient_lastname: {
      label: 'Last Name',
      value: values?.patient_lastname || '[Pending Entry]'
    },
    patient_middleinitial: {
      label: 'Middle Initial',
      value: values?.patient_middleinitial || '[Pending Entry]'
    },
    patient_dob: {
      label: 'Date of Birth',
      value: values?.patient_dob || '[Pending Entry]'
    },
    patient_gender: {
      label: 'Sex at Birth',
      value: values?.patient_gender || '[Pending Entry]'
    }
  };
  useEffect(() => {
    if (values?.selectedTest?.id) {
      // let icdCodes = testsAndIcdCodes?.find((item: any) => item.id === values?.selectedTest?.id)?.icdCodes
      if (icdCodes && values?.additionalCodes?.length > 0) {
        let temp = icdCodes.filter((icdCode: any) => values?.additionalCodes?.includes(icdCode?.id?.toString()))
        setAdditionalCodes(temp)
      } else {
        setAdditionalCodes([])
      }
    }
  }, [values?.additionalCodes?.length])

  if ((field == 'pathologist' || field == 'tumorSite' || field === 'workingDiagnosis') && values?.selectedTest?.id !== 5) return null
  if ((field === 'medicare' || field === 'date_of_discharge') && values?.selectedTest?.id == 8) return null
  if (field === 'additionalCodes' && (selectedTest === 8 || values?.selectedTest?.id === 8)) {
    return <div /* key={field}*/ className={`mr-6 ${isPDF ? 'flex items-center' : ''}`}>
      <p className={`text-royalBlue font-xs font-semibold my-2 ${isPDF ? 'mr-2' : ''}`}>{value}</p>
      <p className="text-royalBlue text-base capitalize">
        <p>{additionalCodes.length > 0 ? additionalCodes.map((code: any) => `${code.value} ${code.name}, `) : "---"}</p>
      </p>

    </div>
  }
  if ((field === 'facilityType') && values?.selectedTest?.id !== 8) return null
  if (field === 'additionalCodes' && (selectedTest !== 8 || values?.selectedTest?.id !== 8)) {
    return null
  }
  if (field === "icd_10")
    return <div /* key={field}*/ className={`mr-6 ${isPDF ? 'flex items-center' : ''}`}>
      <p className={`text-royalBlue font-xs font-semibold my-2 ${isPDF ? 'mr-2' : ''}`}>{values?.selectedTest?.id === 8 ? 'Primary Diagnosed ICD-10' : 'ICD-10 Code'}</p>
      <p className="text-royalBlue text-base capitalize">
        {location?.pathname !== '/test-orders/online-order' ?
          <p>{values[field] ? `${values?.icdCodeValue} ${values?.icdCodeName}` : "---"}</p> :
          <p>{values[field] ? `${values?.icd_10}` : "---"}</p>}
      </p>
    </div>
  if ((field === 'name_of_facility' || field === 'lab_phone' || field === 'lab_fax' || field === 'specimenId') && (values?.selectedTest?.id === 8 || selectedTest?.id === 8)) {
    return null
  }
  if (field.toLowerCase().includes('state')) {
    let foundState = states.find((state: any) => `${state.name} ${state.state_code}`.includes(values[field]))
    return <div /* key={field}*/ data-openreplay-obscured className={`mr-6 ${isPDF ? 'flex items-center' : ''}`}>
      <p className={`text-royalBlue font-xs font-semibold my-2 ${isPDF ? 'mr-2' : ''}`}>{value}</p>
      <p className="text-royalBlue text-base capitalize">
        <p>{values[field] ? foundState ? foundState.name : values[field] : "---"}</p>
      </p>
    </div>
  }

  if (field === 'patient_dob') {
    return (
      <div /* key={field}*/ data-openreplay-obscured className={`mr-6 ${isPDF ? 'flex items-center mb-2' : ''}`}>
        <p className={`text-royalBlue font-xs font-semibold ${isPDF ? 'mr-2' : 'my-2'}`}>{value}</p>
        <p className="text-royalBlue text-base capitalize">
          <p>{!!values[field] ? values[field]?.replaceAll('-', '/') : '[Pending Entry]'}</p>
        </p>
      </div>
    );
  }
  if (field === 'date_of_discharge' || field === 'createdAt' || field === 'date_of_collection' || (field === 'medicarePullDate' && values?.selectedTest?.id !== 8)) {
    return (
      <div /* key={field}*/ data-openreplay-obscured className={`mr-6 ${isPDF ? 'flex items-center' : ''}`}>
        <p className={`text-royalBlue font-xs font-semibold my-2 ${isPDF ? 'mr-2' : ''}`}>{value}</p>
        <p className="text-royalBlue text-base capitalize">
          <p>{!!values[field] ? values[field]?.replaceAll('-', '/') : '---'}</p>

        </p>
      </div>
    );
  }


  if (isFile) {
    if (values?.selectedTest?.id === 8 && field == "pathology_reportFile") {
      return null
    }

    if (values?.selectedTest?.id !== 8 && field == 'current_prescription_medicationsFile') {
      return null
    }

    return <div /* key={field}*/ className={`mr-6 ${isPDF ? 'flex items-center' : ''}`}>
      <p className={`text-royalBlue font-xs font-semibold my-2 ${isPDF ? 'mr-2' : ''}`}>{value}</p>
      {values[field] ? (
        <FileDetail
          key={values[field]?.name}
          hideRemove
          url={values[field.replace("File", "Url") ?? field] ?? ""}
          handleRemove={() => { }}
          file={values[field]}
          disabled={false}
          isSummary
        />

      ) : (
        "---"
      )}
    </div>
  }

  if (field in fields) {
    const { label, value } = fields[field];
    return (
      <div data-openreplay-obscured className={`${isPDF ? 'flex items-center mb-2' : 'mr-4'}`}>
        <p className={`text-royalBlue font-xs font-semibold ${isPDF ? 'mr-2 flex' : 'my-2'}`}>{label}</p>
        <p className={`text-royalBlue text-base capitalize`}>
          {value}
        </p>
      </div>
    );
  }
  if (field === 'isTeleHealth' && (selectedTest?.id === 8 || values?.selectedTest?.id === 8)) {
    return (
      <div className={`${isPDF ? 'flex items-center mb-2' : 'mr-4'}`}>
        <p className={`text-royalBlue font-xs font-semibold  ${isPDF ? 'mr-2 flex' : 'my-2'}`}>Telehealth Order</p>
        <p className={`text-royalBlue text-base capitalize`}>
          {values?.isTeleHealth ? 'Yes' : "No"}
        </p>
      </div>
    );
  }
  if (field === 'isTeleHealth' && (selectedTest?.id !== 8 || values?.selectedTest?.id !== 8)) {
    return null
  }
  if (field === 'tubeBarcode' && (selectedTest?.id !== 8 || values?.selectedTest?.id !== 8)) {
    return null
  }

  if ((field === 'patient_ssn') && selectedTest?.id === 4) {
    return (
      <div data-openreplay-obscured className={`${isPDF ? 'flex items-center mb-2' : 'mr-4'}`}>
        <p className={`text-royalBlue font-xs font-semibold  ${isPDF ? 'mr-2 flex' : 'my-2'}`}>SSN</p>
        <p className={`text-royalBlue text-base capitalize`}>
          {values?.patient_ssn ? values?.patient_ssn : '---'}
        </p>
      </div>
    );
  }
  if ((field === 'patient_mrn') && selectedTest?.id === 4) {
    return (
      <div data-openreplay-obscured className={`${isPDF ? 'flex items-center mb-2' : 'mr-4'}`}>
        <p className={`text-royalBlue font-xs font-semibold  ${isPDF ? 'mr-2 flex' : 'my-2'}`}>MRN</p>
        <p className={`text-royalBlue text-base capitalize`}>
          {values?.patient_mrn ? values?.patient_mrn : '---'}
        </p>
      </div>
    );
  }
  if ((field === 'patient_mrn' || field === 'patient_ssn') && selectedTest?.id !== 4) {
    return null
  }
  return <div /* key={field}*/ className={`mr-6 ${isPDF ? 'flex items-center' : ''}`}>
    <p className={`text-royalBlue font-xs font-semibold my-2 ${isPDF ? 'mr-2' : ''}`}>{value}</p>
    <p className="text-royalBlue text-base capitalize">
      <p>{values[field] ? values[field] : "---"}</p>
    </p>
  </div>
}
export default FieldValue