import "./styles.css";
import scrollHelper from "../../utils/helpers/scrollHelper";
import VideoLoadingIndicator from "../global/LoadingIndicators/VideoSectionLoadingIndicator";
import { InView } from "react-intersection-observer";
import { useSelector } from "react-redux";

function videoSection({ setSelectedTab, firstScroll }: any) {
  const videos = useSelector((state: any) => state.Help);

  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(inView, setSelectedTab, firstScroll, "videos");
  }

  return (
    <InView
      id={"videos"}
      as={"div"}
      className="scroll-my-[190px]"
      threshold={window.innerWidth <= 1280 ? 0.5 : 0.7}
      onChange={handleMouseScroll}
    >
      <div className="border-b py-3">
        <p className="font-bold text-royalBlue text-[24px]">Videos</p>
        <div className="video-grid">
          {videos?.fetching ? (
            <VideoLoadingIndicator />
          ) : videos?.data?.videos?.length === 0 ? (
            <p className="font-medium text-royalBlue">No videos found</p>
          ) : (
            videos?.data?.videos?.map((video: any) => (
              <div className="video-item" key={video.id}>
                <video controls className="rounded-lg focus:outline-primary" controlsList="nodownload">
                  <source
                    src={video.blobUrl}
                    // src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                <p className="font-bold text-royalBlue py-5">
                  {video?.fileName.replace(".mp4", "")}
                </p>
              </div>
            ))
          )}
        </div>
      </div>
    </InView>
  );
}

export default videoSection;
