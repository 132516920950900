import * as Yup from "yup";

const ManagerSignupValidation = Yup.object().shape({
 first_name: Yup.string()
  .required("First name is required!")
  .test(
   "no-empty-blank-space",
   "First name cannot contain empty blank space(s)",
   (value: any) => {
    return value && value.trim() !== "";
   }
  ),
 last_name: Yup.string()
  .required("Last name is required!")
  .test(
   "no-empty-blank-space",
   "Last name cannot contain empty blank space(s)",
   (value: any) => {
    return value && value.trim() !== "";
   }
  ),
 manager_email: Yup.string()
  .email("Email ID is invalid.")
  .required("Email ID is required."),
});

// const ManagerSignupValidation = Yup.object().shape(
//  {
//   first_name: Yup.string()
//    .ensure()
//    .when(["last_name", "manager_email"], {
//     is: (last_name: any, manager_email: any) =>
//      last_name !== "" || manager_email !== "",
//     then: (ManagerSignupValidation) =>
//      ManagerSignupValidation.required("First name is required!").test(
//       "no-empty-blank-space",
//       "First name cannot contain empty blank space(s)",
//       (value: any) => {
//        return value && value.trim() !== "";
//       }
//      ),
//    }),
//   last_name: Yup.string()
//    .ensure()
//    .when(["first_name", "manager_email"], {
//     is: (first_name: any, manager_email: any) =>
//      first_name !== "" || manager_email !== "",
//     then: (ManagerSignupValidation) =>
//      ManagerSignupValidation.required("Last name is required!").test(
//       "no-empty-blank-space",
//       "First name cannot contain empty blank space(s)",
//       (value: any) => {
//        return value && value.trim() !== "";
//       }
//      ),
//    }),
//   manager_email: Yup.string()
//    .email("Email ID is invalid.")
//    .ensure()
//    .when(["first_name", "last_name"], {
//     is: (first_name: any, last_name: any) =>
//      first_name !== "" || last_name !== "",
//     then: Yup.string().required("Manager email is required!"),
//     otherwise: Yup.string(),
//    }),
//  },
//  [
//   ["first_name", "last_name"],
//   ["first_name", "manager_email"],
//   ["last_name", "manager_email"],
//  ]
// );

//  manager_phone: Yup.string()
//   .matches(
//    /\d{3}-\d{3}-\d{4}/,
//    // /\d?(\s?|-?|\+?|\.?)((\(\d{1,4}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)\d{3}(-|\.|\s)\d{4}/,
//    "Phone must contain 10 digits with following format 000-000-0000."
//   )
//   .required("Phone is required!"),
//  manager_fax: Yup.string()
//   .matches(
//    /\d{3}-\d{3}-\d{4}/,
//    //  /\d?(\s?|-?|\+?|\.?)((\(\d{1,4}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)\d{3}(-|\.|\s)\d{4}/,
//    "Fax must contain 10 digits with following format 000-000-0000."
//   ),
//  manager_zip_code: Yup.string()
//  .min(5, "Length must equal to 5")
//  .max(5, "Length must equal to 5")
//   .matches(/^[0-9]+$/, "Zip code must be numbers"),
// }

export default ManagerSignupValidation;
