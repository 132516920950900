import { Fragment } from "react"
import CustomImage from "../../global/CustomImage"
import CustomInput from "../../global/CustomInput"
import { useSelector } from "react-redux"

function TestRow({ test, handleChange, touched, errors }: any): JSX.Element {
  const { selectedTest } = useSelector((state: any) => state.OnlineOrderForm)
  return <div className="flex justify-between w-full">
    <div className="w-2/5 min-h-[89px] p-8 border-r border-b flex flex-col justify-center items-center">
      <CustomImage
        src={test?.imageUrl}
        alt={test?.name}
        className="h-auto w-[184px] object-fit"
      />
      {test?.id == 4 && <CustomImage
        src={test?.DecisionDxPRAMELogo}
        alt={test?.DecisionDxPRAMEDescription}
        className="h-auto w-[184px] object-fit mt-4"
      />}

    </div>
    <div className="w-3/5 px-6 py-2 border-b flex flex-col justify-center hover:bg-[#F9F9FA]">
      {test?.data?.length > 0 && test?.data?.map((item: any, index: number) => (<CustomInput
        id={item?.id}
        key={item?.label}
        name={item?.name}
        label={item?.label}
        addLabelSpan
        bulletPoint={item?.bulletPoint}
        required={false}
        placeholder={''}
        checkboxContainerStyle={`!justify-start w-full ${item?.id == 4 ? '!items-start' : '!items-center'}`}
        errors={errors}
        touched={touched}
        className={test.data.length > 1 ? 'my-1' : ''}
        checked={!!selectedTest && (test?.id === selectedTest?.id && selectedTest?.checkedIndexes?.includes(item?.id))}
        onChange={(e: any) => {
          handleChange(test?.id, item?.id)
        }}
        type='checkbox'
      />))}
    </div>
  </div>
}

function TestCard({ name, disease, tests, testTypes, touched, errors, handleChange, isCOA, allowedTestsCOA, ...props }: any): JSX.Element {
  const { selectedPhysician } = useSelector(
    (state: any) => state.OnlineOrderForm
  )
  const { profile } = useSelector(
    (state:any)=>state?.Profile
  )
  return <div
  >
    <div className="w-full py-2.5 px-5 bg-gradient-to-r from-[#31BDBF] to-[#3F76A5] border-b">
      <p className="text-royalBlue text-left text-white font-medium">
        {disease}
      </p>
    </div>
    {tests && tests?.filter((test: any) => {
      if (isCOA && selectedPhysician?.id !== profile?.id ) return test.id !== 7 && test.id !== 6 && allowedTestsCOA.includes(test.code)
      return test.id !== 7 && test.id !== 6
    }).map((test: any) => (<TestRow
      key={test.name}
      test={test}
      touched={touched}
      errors={errors}
      handleChange={handleChange}
    />))}
  </div>
}

export default TestCard