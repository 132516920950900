
function splitTextToLines(label: string, lineCount: number, maxChar: number): any {
  let numberOfLines = lineCount + 1;

  if (label?.length > maxChar) {
    return <div>
      {numberOfLines > 2 ? <span>{label.slice(0, maxChar)}</span> : <p>{label.slice(0, maxChar)}</p>}
      {numberOfLines > 2 ? '...' : splitTextToLines(label.slice(maxChar, label?.length), numberOfLines, maxChar)}
    </div>
  }
  return label
}

export default splitTextToLines