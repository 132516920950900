import { Fragment, useCallback, useEffect, useState } from "react";
import DataTable from "../../DataTable/DataTable";
import { header } from "../../DataTableHeader/DataTableHeader";
import TestOrdersTable, { Pagination } from "../../TestOrders/TestOrdersTable";
import { useSelector } from "react-redux";
import http from "../../../Store/API/HttpMethods";
import { useOktaAuth } from "@okta/okta-react";
import toastMessages from "../../../utils/helpers/toastMessage";
import TableLoadingIndicator from "../../global/LoadingIndicators/TableLoadingIndicator";
import { debounce } from "lodash";
import moment from "moment";

function PickupsTable({ className, }: { className?: string, }): JSX.Element {
  const { authState } = useOktaAuth()
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const { selectedOrganization, selectedPathLab } = useSelector((state: any) => state.OnlineOrderForm)
  const { fetching: loading, error, data: organizations, fetchingOrgUsers } = useSelector((state: any) => state.Organizations)
  const [fetching, setFetching] = useState<boolean>(true)
  const [paginationData, setPaginationData] = useState<any>(null)
  const [tableFilters, setTableFilters] = useState<any>({})
  const [searching, setSearching] = useState<boolean>(false)
  const [groupedData, setGroupedData] = useState<any>([])
  const [filters, setFilters] = useState<any>({})
  const [valueinFilter, setInfilter] = useState<boolean>(false)
  const { profile } = useSelector((state: any) => state.Profile)
  let isPLU = profile?.roles?.includes('PathLab User')

  const itemsPerPage = [15, 20]
  const [size, setSize] = useState(15)
  const initialColumns: header[] = [
    {
      name: 'Confirmation Code',
      preventAutoSearch: false,
      disableSuggestiveFiltering: true,
      filter: true,
      action: {
        type: 'sort',
        onClick(field: string, filter: string) {

        },
        dropdownList: [
          { label: 'Ascending', key: 'ascending' },
          { label: 'Descending', key: 'descending' }
        ],
        currentSelection: { label: '', key: '' }
      }
    },
    {
      name: 'Shipping Address',
      preventAutoSearch: false,
      disableSuggestiveFiltering: true,
      filter: true,
      action: {
        type: 'sort',
        onClick(field: string, filter: string) {

        },
        dropdownList: [
          { label: 'Ascending', key: 'ascending' },
          { label: 'Descending', key: 'descending' }
        ],
        currentSelection: { label: '', key: '' }
      }
    },
    {
      name: 'Schedule Time',
      preventAutoSearch: true,
      disableSuggestiveFiltering: true,
      filter: true
    },
    {
      name: 'Org',
      preventAutoSearch: false,
      disableSuggestiveFiltering: false,
      filter: false
    },
    {
      name: 'Status',
      preventAutoSearch: true,
      disableSuggestiveFiltering: true,
      filter: true,
      suggestions: [
        {
          label: 'To Be Scheduled',
          value: 'TO_BE_SCHEDULED',
          selected: false,
          key: 'status'
        },
        {
          label: 'Pick-Up Scheduled',
          value: 'PICKUP_SCHEDULED',
          selected: false,
          key: 'status'
        },
        {
          label: 'Pick-Up Completed',
          value: 'PICK_UP_COMPLETED',
          selected: false,
          key: 'status'
        },
        {
          label: 'Scheduling Failed',
          value: 'SCHEDULING_FAILED',
          selected: false,
          key: 'status'
        },
        {
          label: 'Pick-Up Cancelled',
          value: 'PICKUP_CANCELLED',
          selected: false,
          key: 'status'
        },
      ],

    },
    {
      name: 'Actions',
      preventAutoSearch: true
    },
  ]
  const [header, setHeader] = useState<any>({
    hasVerticalMenu: false,
    onFilter(filters: any) {
      handleFilters(filters)
    },
    NavLink: '',
    nnProps: '',
    check: false,
    hideCheckBox: true,
    data: initialColumns,
    list: [{ name: 'name' },
    { email: 'email' }]
  })
  const [tableBody, setTableBody] = useState<any[]>([])
  const [fetchingPage, setFetchingPage] = useState<boolean>(false)
  function search(apiFilters: any) {
    getTableData({ size, page: 1, ...apiFilters, })
  }
  const debounceSearch = useCallback(debounce(search, 500), [isPLU ? selectedPathLab?.id : selectedOrganization?.id, isPLU ? selectedPathLab : selectedOrganization])

  function handleFilters(filters: any) {
    let apiFilters: any = {
      organizationId: isPLU ? selectedPathLab?.id ? selectedPathLab?.id : '' : selectedOrganization?.id ? selectedOrganization?.id : '',
    }
    let keys: any = {}

    keys = {
      confirmationCode: 'confirmationCode',
      shippingAddress: 'shippingAddress',
      readyDateTime: 'readyDateTime',
      organizationId: 'organizationId',
      status: 'status',
      actions: 'actions'
    }

    Object.keys(filters).forEach((col: any) => {
      const input = filters[col]

      if (input) {

        //  if ((Number(col) === 7 || Number(col) === 8) && typeof (input) === 'string') {
        //     apiFilters[Object.keys(keys)[Number(col)].replace('Id', 'Name')] = input
        //   } else {
        //     apiFilters[Object.keys(keys)[Number(col)]] = input
        //   }
        apiFilters[Object.keys(keys)[Number(col)]] = input
      }
      debounceSearch(apiFilters)
    })

    let tableFilters: any = {}
    Object.keys(keys).forEach((key: string, index: number) => {
      tableFilters[index] = apiFilters[key]
      debounceSearch(apiFilters)
    })
    setTableFilters(tableFilters)
    setFilters(apiFilters)
    setInfilter(true)
  }

  function handleBack() {
    if (fetching || searching || fetchingPage) return
    setFetchingPage(true)
    getTableData({ ...filters, page: paginationData.currentPage - 1, size })
  }

  function handleNext() {
    if (fetching || searching || fetchingPage) return
    setFetchingPage(true)
    getTableData({ ...filters, page: paginationData.currentPage + 1, size })
  }

  function handleItemsPerPage(size: number) {
    if (fetching || searching || fetchingPage) return
    setFetchingPage(true)
    setSize(size)
    getTableData({ ...filters, page: 1, size })
  }

  const buildParams = (filters: any) => {
    const params = new URLSearchParams()
    Object.keys(filters).forEach((key: string) => {
      if (Array.isArray(filters[key])) {
        filters[key].map((item: any) => params.append(`${key}[]`, item))
      } else
        if (filters[key])
          params.append(key, filters[key])
    })

    return params.toString()
  }

  function onSort(sortField: string, sortDirection: string, data: any, filters: any) {
    let fields: any = {
      'Confirmation Code': 'confirmationCode',
      'Schedule Time': 'readyDateTime',
      'Org': 'organizationId',
      'Shipping Address': 'shippingAddress',
      'Status': 'status'
    }

    getTableData({
      ...filters,
      sortBy: fields[sortField],
      sortDirection: sortDirection === 'ascending' ? 'ASC' : 'DESC'
    })
  };

  async function cancelPickup(id: number, onRequestEnd: Function) {
    try {
      const response = await http.cancelPickup(
        `v1/fedex/cancel-pickup/${id}`, {},
        {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        }
      );
      if (response?.output?.cancelConfirmationMessage === 'Requested pickup has been cancelled Successfully.') {
        toastMessages.success("Pick-up cancelled!");
        getTableData({
          organizationId: selectedOrganization?.id, size, page: 1
        })
        onRequestEnd()
      }
    } catch (error: any) {
      toastMessages.error(error?.message)
      onRequestEnd()
    }
  }

  async function handleActionClick(item: any) {
    const { item: data, type: action, index } = item;
    function handleSubmitting(status: boolean) {
      setTableBody((prevState: any) => prevState.map((item: any) => {
        if (item?.id == data.id) {
          return { ...item, downloading: status }
        }
        return { ...item }
      }))
    }

    handleSubmitting(true);
    await cancelPickup(data.id, () => handleSubmitting(false))
  }

  async function getTableData(filters: any) {
    let tableData: any = []
    let updatedColumns: any = []
    setSearching(true)

    function updateTableHeader() {
      updatedColumns = initialColumns.map((column: header) => {
        if (column?.name === 'Org') {
          return {
            ...column,
            filter: selectedOrganization ? false : true,
            suggestions: organizations?.map((org: any) => ({ label: org?.name, value: org?.id })),
            // action: {
            //   ...column.action,
            //   type: 'sort',
            //   onClick(field: string, filter: string) {
            //     onSort(field, filter, tableData, filters)
            //   },
            //   dropdownList: [
            //     { label: 'Ascending', key: 'ascending' },
            //     { label: 'Descending', key: 'descending' }
            //   ],
            //   currentSelection: { label: '', key: '' }
            // },
          }
        }
        return {
          ...column,
          action: {
            ...column.action,
            dropdownList: [
              { label: 'Ascending', key: 'ascending' },
              { label: 'Descending', key: 'descending' }
            ],
            onClick(field: string, filter: string) {
              onSort(field, filter, tableData, filters)
            },
            currentSelection: { label: '', key: '' },
          }
        }
      })

      setHeader({
        hasVerticalMenu: false,
        onFilter(filters: any) {
          handleFilters(filters)
        },
        onSelectAll: () => {
          let checkValue = false
          setHeader((prevState: any) => {
            checkValue = !prevState.check
            return {
              ...prevState, check: checkValue
            }
          })
          setTableBody((prevState: any) => {
            let temp = [...prevState]
            let newArray = temp.map((item: any, childIndex: number) => {
              return {
                ...item,
                check: checkValue,
                data: {
                  ...item.data
                }
              }
            })

            return newArray
          })

        },
        NavLink: '',
        nnProps: '',
        check: false,
        hideCheckBox: true,
        data: updatedColumns,
        list: [{ name: 'name' },
        { email: 'email' }]
      })
    }
    try {
      const response = await http.getPickupsData(`/v1/fedex/pickups?${buildParams(filters)}`, { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` } })

      const { data, message, statusCode } = response

      if (data?.fedexPickups?.length > 0) {

        tableData = data?.fedexPickups?.map((record: any) => {

          let bodyData: any = {
            confirmationCode: record?.confirmationCode ? record.confirmationCode : 'N/A',
            shippingAddress: record?.shippingAddress ? record.shippingAddress : 'N/A',
            // scheduleTime: record?.readyDateTime ? moment(record.readyDateTime).format('dddd-MM-YYYY hh:mm A') : 'N/A',
            scheduleTime: record?.readyDateTime ? moment(record.readyDateTime).format('MM/DD/YYYY') : 'N/A',
            organizationId: record?.organization?.name ? record?.organization?.name : 'N/A',
            status: record?.status ? record?.status : 'N/A',
            actions: record?.status ? ['Cancel'] : 'N/A'
          }
          let obj = {
            id: record.id,
            onRowClick: (id: number) => {

            },
            selectOnIndex: (check: boolean, index: number, data: any) => {
            },
            actionClick: (item: any) => handleActionClick(item),
            disabled: record?.status !== 'PICKUP_SCHEDULED',
            data: bodyData,
            tooltips: [{
              key: 'organizationCode',
              value: `${record?.organization?.address || 'N/A'} 
              ${record?.organization?.state ? `, ${record.organization.state}` : ''} 
              ${record?.organization?.city ? `, ${record.organization.city}` : ''}`.trim() || 'N/A'
            },
            {
              key: 'pathlabId',
              value: `${record?.organization?.address || 'N/A'} 
              ${record?.organization?.state ? `, ${record.organization.state}` : ''} 
              ${record?.organization?.city ? `, ${record.organization.city}` : ''}`.trim() || 'N/A'
            }],
            showCheckbox: false,
            check: false
          }

          return obj
        })

        setTableBody(tableData)
        updateTableHeader()
        setFilters(filters)
        setPaginationData({
          totalItems: data.totalItems,
          totalPages: data.totalPages,
          currentPage: data.currentPage
        })
      } else {
        setTableBody([])
      }

      setFilters(filters)
      setFetching(false)
      setRefreshing(false)
      setSearching(false)
      setFetchingPage(false)

    } catch (error: any) {

      updateTableHeader()
      toastMessages.error(error?.message ? error.message : error.error ? error.error : 'Something went wrong')
      setFetching(false)
      setRefreshing(false)
      setSearching(false)
      setFetchingPage(false)
    }
  }

  useEffect(() => {
    if (isPLU && selectedPathLab?.id !== undefined) {
      getTableData({
        organizationId: selectedPathLab?.id, size, page: 1
      })
      return
    }
    if (selectedOrganization?.id !== undefined) {
      getTableData({
        organizationId: selectedOrganization?.id, size, page: 1
      })
    }
  }, [selectedOrganization?.id, selectedPathLab?.id])

  if (fetching)
    return <div className=''>
      <TableLoadingIndicator />
    </div>

  return <Fragment>
    <DataTable
      NavLink={''}
      groupedData={groupedData}
      externalCheckbox={false}
      initialFilters={tableFilters}
      className={` '!max-h-[65vh]'} border-t ${tableBody.length > 10 ? 'border-b' : ''} !min-h-[50vh] ${className}`}
      customEmptyText={valueinFilter === false ? 'Currently, there are no pick-ups available.' : 'There are no pick-ups that match your search criteria.'}
      body={tableBody}
      header={header}
      showLoader={searching}
    />
    {paginationData && <Pagination
      paginationData={paginationData}
      submitting={fetchingPage}
      handleBack={handleBack}
      handleNext={handleNext}
      handleItemsPerPage={handleItemsPerPage}
      size={size} />}
  </Fragment>
}

export default PickupsTable