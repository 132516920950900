import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import DropDownIcon from "../../assets/svg/DropdownIcon";
import ArrowUp from "../../assets/svg/ArrowUp";
import FilterIcon from "../../assets/svg/FilterIcon";
import SelectedCheckMark from "../../assets/svg/SelectedCheckMark";
import TableFilterDropdown from "../TestOrders/TableFilterDropdown";
import FilterItem from "./FilterItem";
import CloseIcon from "../../assets/svg/CloseIcon";
import { indexOf } from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import LoadingSvg from "../../assets/svg/LoadingSVG";
import RequiredFieldTooltip from "./RequiredFieldTooltip";
import NotificationActions from "../../Store/Actions/NotificationsActions";
import TermsAndConditionsPopup from "./TermsAndConditionsPopup";
import { useDispatch } from "react-redux";
import Button from "../atomic/Button";

interface multiselectProps {
  options: any[],
  name: string
  label: string
  errors: any
  touched: any
  required: boolean
  disabled: boolean
  className?: string
  labelStyle?: string
  placeholder?: string
  inputValuesStyle?: string
  optionsDisabled?: string[]
  onOptionClick: (item: any) => any
  id: string
  filterByInput?: boolean
  dangerouslySetLabel?: boolean
  notificationsDropdown?: any
  onBlurCapture?: any
  tooltipText?: string
  reset?: number
}
function MultiSelectDropdown({ options, errors, touched, className, required, id, name, label, filterByInput, onOptionClick, disabled, labelStyle, inputValuesStyle, optionsDisabled, dangerouslySetLabel, placeholder, notificationsDropdown, tooltipText, reset, onBlurCapture }: multiselectProps) {
  const [suggestionVisible, setSuggestionVisible] = useState(false)
  const [sortedList, setSortedList] = useState<any[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const dropDownRef = useRef<any>()
  const [page, setPage] = useState(0);
  const pageSize = 100;
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const [data, setData] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);
  const [prevItemCount, setPrevItemCount] = useState(0);

  const loadMoree = () => {
    const nextPage = page + 1;
    const startIndex = nextPage * pageSize;
    const endIndex = (nextPage + 1) * pageSize;

    // Get the next page of data from the options prop
    const newData = options.slice(startIndex, endIndex);

    // Append the new data to the existing data
    setData((prevData) => prevData + newData.join(''));

    // Update the current page
    setPage(nextPage);

    // Determine if there are more pages to load
    const hasMorePages = endIndex < options.length;
    setLoadMore(hasMorePages);
  };
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const toggleDropdownVisibility = () => setSuggestionVisible((prevState: any) => !prevState)
  const sortByAscending = (a: any, b: any) => {

    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  };
  const dispatch = useDispatch()
  useEffect(() => {
    const unsubscribe = window.addEventListener('mousedown', (event) => {
      if (dropDownRef?.current && !dropDownRef?.current?.contains(event.target)) {
        return setSuggestionVisible(false)
      }
    })
    return () => unsubscribe
  }, [])

  useEffect(() => {
    if (filterByInput && options?.length > 0)
      setSortedList(options.sort(sortByAscending))
  }, [])

  useEffect(() => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const containerScrollTop = container.scrollTop;
      const containerScrollHeight = container.scrollHeight;
      const containerHeight = container.clientHeight;
      const itemsAdded = options.length - prevItemCount;

      if (containerScrollHeight > containerHeight) {
        const scrollPositionAfterItemsAdded = (containerScrollHeight - containerHeight) + (containerScrollTop * itemsAdded);
        setScrollPosition(scrollPositionAfterItemsAdded);
      }

      setPrevItemCount(options.length);
    }
  }, [options]);
  // useEffect(() => {
  //   if (scrollContainerRef.current) {
  //     const container = scrollContainerRef.current;
  //     container.scrollTop = scrollPosition;
  //   }
  // }, [scrollPosition]);

  function handleClick(e: any) {
    if (disabled) return
    e.stopPropagation()
    setSuggestionVisible(prevState => !prevState)

  }

  function arrangeElements() {
    let temp = [...sortedList];
    let searchedArray: any = [];
    let finalArray: any = []

    // find search element 
    temp.forEach((element) => {
      if (element?.label?.toLowerCase().includes(inputValue?.toLowerCase()) && !!inputValue) {
        searchedArray.push(element)
      }
    })

    if (searchedArray.length > 0)
      finalArray = [...searchedArray.sort(sortByAscending), ...temp.filter(element => searchedArray.find((item: any) => item.label !== element.label))]
    else
      finalArray = [...temp]

    return finalArray
  }

  function SelectedOptionsPills() {
    if (filterByInput)
      return <Fragment>
        {
          options?.filter((option: any) => option?.selected)?.length > 0 ? <div className={`flex my-2 overflow-visible ${inputValuesStyle}`}>
            {options?.filter((option: any) => option?.selected)?.map((item: any, index: number) => <div className="relative mr-3 mt-2">
              <div className="p-2 border mr-2 rounded-md bg-primary">
                {
                  dangerouslySetLabel ? <p className="text-xs text-white" dangerouslySetInnerHTML={{ __html: item.label }} /> :
                    <p className="text-xs text-white">{item.label}</p>
                }
              </div>
              <button
                type="button" onClick={() => {
                  onOptionClick(item)
                  setSortedList((prevState) => prevState.map((currItem: any) => ({ ...currItem, selected: item.label === currItem.label ? !currItem.selected : currItem.selected })))
                }} className="p-1 absolute -top-2 bg-primary -right-0 shadow-sm  border rounded-full hover:border-[rgba(0,0,0,0.3)] hover:border-3">
                <CloseIcon stroke="#fff" />
              </button>
            </div>
            )}
          </div> : null
        }
      </Fragment>
    return null
  }
  function SelectedValuesOrInput() {
    if (filterByInput)
      return null
    return <Fragment>
      {
        options?.filter((option: any) => option?.selected)?.length > 0 ? <div className={`flex overflow-auto ${inputValuesStyle}`}>
          {options?.filter((option: any) => option?.selected)?.map((item: any, index: number) => <p>{item.label}{index + 1 === options?.filter((option: any) => option?.selected)?.length ? '' : `,`}</p>)}
        </div> : <p className="text-borderGray">{placeholder ? placeholder : 'Please select a value'}</p>
      }
    </Fragment>
  }

  function OptionsValues() {
    if (filterByInput)
      return <Fragment>
        {
          suggestionVisible && <div
            className={`drop-shadow-md py-2 bg-white rounded absolute z-50  w-full max-h-32 overflow-auto`} >
            {/* <InfiniteScroll
              loadMore={() => {
                if (options.length > (page + 1) * pageSize) {
                  loadMoree();
                } else {
                  setLoadMore(false);
                }
              }}
              hasMore={options.length > (page + 1) * pageSize ? true : false}
              loader={<div className="ml-12 my-2"><LoadingSvg className='animate-spin' isSecondary /></div>}
              getScrollParent={() => dropDownRef.current} // Set the scroll parent to the scroll container

            > */}
            {arrangeElements()?./* slice(0, (page + 1) * pageSize) */map((item: any, index: any) => (<FilterItem
              type={2}
              key={item.label}
              dangerouslySetLabel={dangerouslySetLabel}
              label={item.label}
              disabled={options?.filter((o: any) => o.selected)?.map((item: any) => item?.label)?.includes('-none-') || options?.filter((o: any) => o.selected)?.map((item: any) => item?.label)?.includes('n/a')}
              selected={item.selected}
              onClick={() => {
                onOptionClick(item)
                if (item?.label.includes('none')) {
                  setSortedList((prevState) => prevState.map((currItem: any) => ({ ...currItem, selected: item.label === currItem.label ? !currItem.selected : false })))
                } else {
                  setSortedList((prevState) => prevState.map((currItem: any) => ({ ...currItem, selected: item.label === currItem.label ? !currItem.selected : currItem.selected })))
                }
                setInputValue('')
              }}
              notificationsDropdown={notificationsDropdown}
            />
            ))}
            {/* </InfiniteScroll> */}
          </div>
        }
      </Fragment>

    return (<Fragment>
      {suggestionVisible && (
        <div
          ref={scrollContainerRef}
          className={`drop-shadow-md py-2 bg-white rounded absolute z-50 w-full max-h-32 overflow-auto mt-4`}>
          {/* <InfiniteScroll
            threshold={200}
            loadMore={() => {
              if (options.length > (page + 1) * pageSize) {
                loadMoree();
              } else {
                setLoadMore(false);
              }
            }}
            hasMore={options.length > (page + 1) * pageSize ? true : false}
            useWindow={false}
            loader={<div className="ml-12 my-2"><LoadingSvg className='animate-spin' isSecondary /></div>}
            getScrollParent={() => dropDownRef.current} // Set the scroll parent to the scroll container

          > */}
          {options./* slice(0, (page + 1) * pageSize). */map((item, index) => (
            <FilterItem
              type={2}
              key={item.label}
              label={item.label}
              disabled={optionsDisabled?.includes(item?.value)}
              selected={item.selected}
              onClick={() => {
                onOptionClick(item)
              }}
              notificationsDropdown={notificationsDropdown}
            />
          ))}
          {/* </InfiniteScroll> */}
        </div>
      )}
    </Fragment>
    )
  }

  return <div ref={dropDownRef} className={`my-3 w-full ${className ?? ''}`}>

    <RequiredFieldTooltip required={required ?? true} tooltipText={tooltipText ? tooltipText : 'Required Field'}>
      <label htmlFor={id} className={`text-xs text-royalBlue font-normal ${required ? "after:content-['*'] after:ml-1 after:text-requiredRed" : ''} ${errors[name] && touched[name] ? 'text-requiredRed' : ''} ${labelStyle}`}>{label}</label>
    </RequiredFieldTooltip>
    {filterByInput && <input
      placeholder="Search here"
      onBlurCapture={onBlurCapture}
      disabled={disabled}
      className={`w-full focus:outline-none py-3  border-b border-borderGray
         ${suggestionVisible ? 'border-primary hover:border-primary' : 'hover:border-royalBlue'}`}
      value={inputValue}
      onFocus={(e) => {
        // if (options.filter((item: any) => item.label.toLowerCase().includes(e.target.value.toLowerCase())).length > 0) {
        //   setSuggestionVisible(true)
        // }
      }}
      onClick={() => setSuggestionVisible((prevState: boolean) => !prevState)}
      onChange={(e: any) => {
        e.stopPropagation()
        setInputValue(e.target.value)
        if (options.filter((item: any) => item.label.toLowerCase().includes(e.target.value.toLowerCase())).length > 0) {
          setSuggestionVisible(true)
        }
        else {
          setSuggestionVisible(false)
        }
      }} />}

    <button type="button" disabled={disabled} onClick={handleClick} className={`flex justify-between relative items-center border-t-0 border-r-0 border-l-0 ${filterByInput ? '' : 'border-b border-borderGray'} focus:outline-none  ${suggestionVisible && !filterByInput ? 'border-primary hover:border-primary' : ''} ${errors[name] && touched[name] ? 'border-requiredRed foucs:border-requiredRed' : ''} w-full ${disabled ? 'cursor-not-allowed' : ''} mr-5 w-full`}>
      <div className={`w-full focus:ring-transparent placeholder:text-borderGray border-0 px-0 items-start text-left 
      ${filterByInput ? '' : 'py-2'}`}>
        <SelectedValuesOrInput />
        <OptionsValues />
      </div>
      {!!filterByInput == false && <button type="button" onClick={toggleDropdownVisibility} className='mt-3'>
        {suggestionVisible ? <ArrowUp onClick={toggleDropdownVisibility} height={20} width={25} /> :
          <DropDownIcon onClick={toggleDropdownVisibility} height={20} width={25} />}
      </button>}
    </button>
    <SelectedOptionsPills />
    {id === 'isAltheaDxNeuropsychotropicMedications' && <div className="justify-end flex mt-3 w-full"> <Button
      onClick={() => dispatch(NotificationActions.showNotification({
        type: 'success',
        title: 'View Drugs with Diagnosis Mapping',
        message: '',
        hideClose: true,
        Component: () => <TermsAndConditionsPopup onlyDownload={true} id='isAltheaDxNeuropsychotropicMedications' />,
        buttons: [],
      }))}
      title='VIEW DRUGS WITH DIAGNOSIS MAPPING'
      btnType='transparent' />
    </div>}
    {errors && errors[name] && touched && touched[name] && (
      <p className="text-xs text-requiredRed mt-1">{errors[name]?.options ? errors[name].options : errors[name]}</p>
    )}
  </div>
}
export default MultiSelectDropdown