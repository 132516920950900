
function SubColumnIndicator() {
  return <th className="text-left py-4 pr-4">
    <div className="bg-dropDownBorder w-full h-2.5 animate-pulse px-6 rounded-2xl" />
    <div className="bg-dropDownBorder w-2/5 my-2 h-2.5 animate-pulse rounded-2xl" />
  </th>
}
function HeaderIndicator({ subColumns }: { subColumns: number[] }) {
  return <th className="border-r px-6 py-4 border-[#DCDFE4] text-royalBlue text-left align-top">
    <div className="text-left bg-dropDownBorder animate-pulse rounded-2xl h-2.5 w-full" />
    {subColumns && subColumns.length > 0 && <table>
      <thead>
        <tr>
          {
            subColumns.map((column: number) => <SubColumnIndicator key={column} />)
          }
        </tr>
      </thead>
    </table>}
  </th>
}

function BodyColumnLoadingIndicator() {
  return <td className="px-6 py-4 border-r border-[#DCDFE4]">
    <div className="bg-dropDownBorder w-[100px] rounded-2xl h-2.5 animate-pulse" />
  </td>
}

function NotificationSettingsTableLoadingIndicator() {
  return <table className="table-auto border border-[#DCDFE4] bg-white">
    <thead className="bg-[#F9F9FA] border-b border-[#DCDFE4]">
      <tr>
        <HeaderIndicator subColumns={[]} />
        <HeaderIndicator subColumns={[1, 2]} />
        <HeaderIndicator subColumns={[1, 2, 3]} />
      </tr>
    </thead>
    <tbody>
      <tr>
        <BodyColumnLoadingIndicator />
        <BodyColumnLoadingIndicator />
        <BodyColumnLoadingIndicator />
      </tr>
    </tbody>
  </table>
}

export default NotificationSettingsTableLoadingIndicator