/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import React, { Fragment } from 'react'
import LoadingSvg from '../../assets/svg/LoadingSVG'
import buttonProps from '../../models/buttonproptypes.type'
import RefreshIcon from '../../assets/svg/RefreshIcon'
import ReactTooltip from 'react-tooltip'
import TooltipHelper from '../global/TooltipHelper'

interface Type {
    btnType: 'primary' | 'secondary' | 'transparent'
}

type ButtonType = buttonProps & Type

function Button({ btnType,place, onClick,buttonForSearch, title, className, disabled, type, submitting, showTooltip, tooltipText, refreshicon }: ButtonType): JSX.Element {
    if (btnType === 'secondary') {
        return <Fragment >
            <button
                onClick={onClick}
                id={title}
                type={type ?? 'button'}
                disabled={disabled}
                className={`${submitting === true ? 'flex items-center' : ''}text-royalBlue text-sm font-semibold uppercase px-5 py-2 border border-borderGray rounded-md hover:bg-secondaryHover disabled:opacity-[50%] disabled:hover:bg-white disabled:cursor-not-allowed	 ${className ?? ''}`}>
                {submitting === true && <LoadingSvg isSecondary className="animate-spin mr-3" />}
                <p
                    data-tip={tooltipText}
                    data-iscapture="true"
                    data-multiline={true}
                    data-for={title}
                >
                    {title}
                </p>
            </button>
            {showTooltip && <TooltipHelper
                // place={'bottom'}
                id={title}
                backgroundColor={'#18B5B7'}
                className={'!text-xs !p-2 z-[99999999]'}
                wrapper="span"
                // type={'dark'}
                // effect={'float'}
                place={place}
                scrollHide={true}
                multiline={true}
            />}
        </Fragment>
    }

    if (btnType === 'transparent') {
        return <button
            onClick={onClick}
            type={type ?? 'button'}
            disabled={disabled}
            className={`${(submitting === true || refreshicon === true) ? 'flex items-center' : ''} text-[#18b5b7] font-semibold hover:text-[#78dbdc]  text-sm uppercase  disabled:text-primaryDisabled disabled:hover:text-primaryDisabled disabled:cursor-not-allowed ${className ?? ''}`}>
            {submitting === true && <LoadingSvg stroke={'#18b5b7'} isSecondary className="animate-spin mr-3" />}
            {refreshicon === true && <RefreshIcon className="mr-1" />}
            {title}
        </button>
    }
    // if (btnType === 'forSof') {
    //     return <button
    //         onClick={onClick}
    //         type={type ?? 'button'}
    //         disabled={disabled}
    //         className={`${(submitting === true || refreshicon===true )? 'flex items-center' : ''} text-[#172B4D] bg-white rounded-md border border-[#B1B8C3] px-3 py-1.5 font-semibold hover:text-[#172B4D] hover:bg-[#B1B8C3] text-sm uppercase  disabled:text-primaryDisabled disabled:hover:text-primaryDisabled disabled:cursor-not-allowed ${className ?? ''}`}>
    //         {submitting === true && <LoadingSvg stroke={'#18b5b7'} isSecondary className="animate-spin mr-3" />}
    //         {refreshicon===true && <RefreshIcon className="mr-1"/>}
    //         {title}
    //     </button>
    // }

    return <Fragment>
        <button
            onClick={onClick}
            disabled={disabled}
            type={type ?? 'button'}
            className={`${submitting === true ? 'flex items-center' : ''} bg-primary px-5 py-2 ${disabled ? '' : 'border border-primary'} ${buttonForSearch ? 'hover:bg-[#EDEFF1]':'hover:bg-primaryHover'} hover:shadow-primaryHover shadow-sm shadow-primary rounded-md uppercase text-white text-sm font-semibold disabled:bg-primaryDisabled disabled:shadow-primaryDisabled disabled:cursor-not-allowed ${className}`}>

            {submitting === true && <LoadingSvg className="animate-spin mr-3" />}
            <p
                data-tip={tooltipText}
                data-iscapture="true"
                data-multiline={true}
                data-for={title}
            >
                {title}
            </p>
        </button>
        {showTooltip && <TooltipHelper
            place={'bottom'}
            id={title}
            backgroundColor={'#18B5B7'}
            className={'!text-xs !p-2 '}
            wrapper="span"
            // type={'dark'}
            // effect={'float'}
            scrollHide={true}
            multiline={true}
        />}
    </Fragment>
}

export default Button
