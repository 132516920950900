/* eslint-disable @typescript-eslint/indent */
import { AnimatePresence, motion } from 'framer-motion'
import React, { Fragment } from 'react'
import CloseIcon from '../../assets/svg/CloseIcon'
import Button from '../atomic/Button'
import CloseBigIcon from '../../assets/svg/CloseBigIcon'
import LoadingSvg from '../../assets/svg/LoadingSVG'

function CustomPopup({ generateLabel,routeLeaving,hidePopup, deleteAndUpdateSof, handleOk, title, children, visible, containerClass, contentContainerClass, hideButtons, buttons,submitting }: any): any {
    if (visible) {
        return <AnimatePresence exitBeforeEnter>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className={`flex flex-col justify-center items-center h-screen w-full bg-modalBackdrop oveflow-hidden absolute top-0 bottom-0 left-0 right-0 z-[999] ${containerClass}`}>
                <div className={`p-5 bg-white shadow-md  z-[1000] ${deleteAndUpdateSof ? 'rounded-lg' : 'rounded'} ${contentContainerClass}`}>
                    <div className={`flex justify-between items-center ${deleteAndUpdateSof && 'mt-1.5'}`}>
                        <p className={`text-royalBlue font-medium text-base ${generateLabel} `}>{title}</p>
                        { submitting ? <LoadingSvg/> : !routeLeaving && 
                          <button type="button" className={`mb-2`} onClick={hidePopup}>
                          <CloseBigIcon />
                          
                        </button> }
                      
                    </div>
                    {children}
                    {hideButtons ? null : <div className="flex justify-end mt-3">
                        {buttons !== null && buttons.length > 0
                            ? buttons.map((button: any, index: number) => <Button
                                key={index}
                                onClick={button.onClick}
                                type="button"
                                disabled={button.disabled}
                                submitting={button.submitting}
                                title={button.title} className={button.className ?? 'mr-2'}
                                btnType={button.type}
                            />)
                            : <Fragment>
                                <Button
                                    onClick={hidePopup}
                                    title={'cancel'}
                                    type="button"
                                    className={'mr-2'}
                                    btnType={'secondary'} />
                                <Button
                                    onClick={handleOk}
                                    title={'OK'}
                                    type="button"
                                    className={'shadow-none'}
                                    btnType={'primary'} />
                            </Fragment>}

                    </div>}
                </div>
            </motion.div>
        </AnimatePresence>
    }
    return null
}

export default CustomPopup
