import { motion } from 'framer-motion'
import { connect } from 'react-redux'
import LogoSVG from '../../assets/svg/Logo'
import SetPasswordForm from '../../components/organism/SetPasswordForm'
import actions from '../../Store/Actions/AuthActions'

function ResetPassword ({ user, login }: any) {
  return <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={'flex h-screen justify-between border border-gray'}
    >
        <section className='flex h-full w-3/6 bg-gradient-to-b from-[#18B5B7] to-[#084E8B] border border-darkGray' />
        <section className='flex flex-col h-full w-3/6 justify-center items-center'>
            <div className='w-4/6 flex flex-col h-full justify-center'>

                <div>
                    <LogoSVG height={38} width={112} className="my-4" />
                    <h3 className='text-2xl font-bold text-royalBlue text-left mt-1'>Reset Password</h3>
                    <h5 className='text-base font-normal text-royalBlue text-left py-2'>Please set up a new password below</h5>
                </div>
                <SetPasswordForm />
            </div>
        </section>
    </motion.section>
}

const mapStateToProps = (state: any) => ({ user: state.user })
const mapDispatchTopProps = {
  login: actions.login
}
export default connect(mapStateToProps, mapDispatchTopProps)(ResetPassword)
