import { useDispatch, useSelector } from "react-redux";
import NotificationSettingsLayout from "./NotificationsSettingsLayout";
import ButtonStateActions from "../../Store/Actions/ButtonStateActions";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import OrganizationsSettingsTable from "../../components/NotificationsScreen/OrganizationsSettingsTable";
import UserNotificationMediumTable from "../../components/NotificationsScreen/UserNotificationMediumTable";
import HeaderColumnData from "../../models/notificationsSettingsTableModels/headerColumnData.model";
import BodyColumnData from "../../components/NotificationsScreen/BodyColumnData.model";
import Button from "../../components/atomic/Button";
import UserNotificationActions from "../../Store/Actions/UserNotificationActions";
import { connect } from "react-redux";
import { ActionArgs } from "../../Store/Actions/types";
import NotificationSettingsTableLoadingIndicator from "../../components/global/LoadingIndicators/NotificationsSettingsTableLoadingIndicator";
import UserNotificationTypeTable from "../../components/NotificationsScreen/UserNotificationTypeTable";
import { useOktaAuth } from "@okta/okta-react";
import http from "../../Store/API/HttpMethods";
import toastMessages from "../../utils/helpers/toastMessage";
import updateTypeSettingsPayloadItem from "../../models/notificationsModels/updateTypeSettingsPayloadItem";
import UpdateOrganizationSettingsPayloadItem from "../../models/notificationsModels/updateOrganizationSettingsPayloadItem";
import { useHistory } from "react-router-dom";
import RouteLeavingGuard from "./RouteLeaving";

interface PersonalSettingsProps {
    fetching: boolean
    error: boolean
    fetchUserNotificationMedium: (options: ActionArgs) => void
    fetchUserNotificationType: (options: ActionArgs) => void
    fetchNotificationType: (options: ActionArgs) => void
}

function PersonalSettings({
    fetching,
    error,
    fetchUserNotificationMedium,
    fetchUserNotificationType,
    fetchNotificationType
}: PersonalSettingsProps) {
    const { screen } = useSelector((state: any) => state.ButtonState)
    const dispatch = useDispatch()
    const { authState } = useOktaAuth()
    const [tableColumns, setTableColumns] = useState<HeaderColumnData[]>([]);
    const [tableBody, setTableBody] = useState<Array<BodyColumnData[]>>([]);
    const [userNotificationMediumTableColum, setUserNotificationMediumTableColumn] = useState<HeaderColumnData[]>([]);
    const [userNotificationMediumTableBody, setUserNotificationMediumTableBody] = useState<Array<BodyColumnData[]>>([]);
    const [initialUserNotificationMediumTableBody, setInitialUserNotificationMediumTableBody] = useState<Array<BodyColumnData[]>>([]);
    const [initialUserNotificationTypeTableBody, setInitialUserNotificationTypeTableBody] = useState<Array<BodyColumnData[]>>([]);
    const [saving, setSaving] = useState<boolean>(false)
    const history = useHistory()

    if (initialUserNotificationMediumTableBody?.length === 0 && userNotificationMediumTableBody?.length > 0) {
        // Set the initial value when it's first populated
        setInitialUserNotificationMediumTableBody(userNotificationMediumTableBody);
    }

    if (initialUserNotificationTypeTableBody?.length === 0 && tableBody?.length > 0) {
        // Set the initial value when it's first populated
        setInitialUserNotificationTypeTableBody(tableBody);
    }

    // Use JSON.stringify for a deep comparison of arrays
    const isPersonalSettingsChanged = (JSON.stringify(userNotificationMediumTableBody) !== JSON.stringify(initialUserNotificationMediumTableBody) || JSON.stringify(tableBody) !== JSON.stringify(initialUserNotificationTypeTableBody));

    useEffect((): any => {
        dispatch(ButtonStateActions.setButtonState('notification settings'))
    }, [])

    async function handleSave() {
        let apiData: updateTypeSettingsPayloadItem[] = []
        let apiMediumData: UpdateOrganizationSettingsPayloadItem[] = []
        tableBody.forEach((data) => {
            data.forEach((value) => {
                if (Array.isArray(value.data)) {
                    let organizationId = !Array.isArray(data[0].data) ? Number(data[0].data.id) : 0

                    value.data.forEach((setting) => {
                        let settingItem: updateTypeSettingsPayloadItem = {
                            organizationId,
                            notificationTypeId: Number(setting.id),
                            value: typeof (setting.value) == 'boolean' ? setting.value : false
                        }

                        apiData.push(settingItem)
                    })
                }
            })

        })
        userNotificationMediumTableBody.forEach((data) => {
            data.forEach((value) => {
                if (Array.isArray(value.data)) {
                    let organizationId = !Array.isArray(data[0].data) ? Number(data[0].data.id) : 0
                    value.data.forEach((setting) => {
                        let setting2item: UpdateOrganizationSettingsPayloadItem = {
                            organizationId,
                            notificationMediumId: Number(setting.id),
                            value: typeof (setting.value) == 'boolean' ? setting.value : false
                        }
                        apiMediumData.push(setting2item)
                    })
                }
            })

        })

        const body = {
            data: apiData
        }
        const body2 = {
            data: apiMediumData
        }

        setSaving(true)

        const config = {
            headers: {
                Authorization: `Bearer ${authState?.accessToken?.accessToken}`

            }
        }

        Promise.allSettled([
            http.saveNotificationTypeSettings('v1/user-notification-type-settings', body, config),
            http.saveOrganizationNotificationsSettings('v1/user-notification-medium-settings', body2, config)])
            .then((results) => {
                setInitialUserNotificationMediumTableBody(userNotificationMediumTableBody);
                setInitialUserNotificationTypeTableBody(tableBody);
                const fullfilled = results.filter((result) => result.status === 'fulfilled')
                if (fullfilled.length == 2) {
                    Promise.allSettled([
                        fetchUserNotificationMedium({ config }),
                        fetchUserNotificationType({ config }),
                        fetchNotificationType({ config })
                    ]).then((results) => {
                        setSaving(false)
                    })
                } else {
                    setSaving(false)
                }
            })
            .catch((error: any) => {
                setSaving(false)
                toastMessages.error(error?.message)
            })

    }

    return <NotificationSettingsLayout CustomButtons={() => {
        return <div>
            <Button
                title={saving ? 'Saving' : 'Save'}
                onClick={handleSave}
                btnType='primary'
                disabled={saving}
                submitting={saving}
            />
        </div>
    }}
        isSettingsChanged={isPersonalSettingsChanged}>
        <RouteLeavingGuard
            when={isPersonalSettingsChanged}
            navigate={(path) => {
                history.push(path);
            }}
            shouldBlockNavigation={() => {
                if (isPersonalSettingsChanged) {
                    return true;
                }
                return false;
            }}
        />
        <AnimatePresence exitBeforeEnter>
            <motion.div
                key={screen}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
                <div>
                    <div className="px-6">
                        <p className="text-royalBlue font-bold text-xl pb-6 py-8">Notification Mediumy</p>
                        {saving || fetching ? <NotificationSettingsTableLoadingIndicator /> :
                            <UserNotificationMediumTable
                                tableBody={userNotificationMediumTableBody}
                                tableColumns={userNotificationMediumTableColum}
                                setTableBody={setUserNotificationMediumTableBody}
                                setTableColumns={setUserNotificationMediumTableColumn}
                            />
                        }
                    </div>
                    <div className="px-6 pt-8 pb-12">
                        <p className="text-royalBlue font-bold text-xl pb-6 ">Notification Types</p>
                        <div className="overflow-scroll">
                            {
                                saving || fetching ?
                                    <NotificationSettingsTableLoadingIndicator /> :
                                    <UserNotificationTypeTable
                                        tableBody={tableBody}
                                        tableColumns={tableColumns}
                                        setTableBody={setTableBody}
                                        setTableColumns={setTableColumns}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    </NotificationSettingsLayout>
}


const mapStateToProps = (state: any) => ({
    fetching: state.UserNotifications.fetching,
    error: state.UserNotifications.error,
})

const mapDispatchToProps = {
    fetchUserNotificationMedium: UserNotificationActions.fetchUserNotificationMedium,
    fetchUserNotificationType: UserNotificationActions.fetchUserNotificationType,
    fetchNotificationType: UserNotificationActions.fetchNotificationType,
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalSettings);