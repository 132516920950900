import { useEffect } from "react";
import { Socket } from "socket.io-client";
import useSocket from "./useSocket";
import ApiNotificationData from "../../models/notificationsModels/apiNotificationsData.model";
import {
 FETCH_NOTIFICATIONS_SUCCESS,
 UPDATE_NOTIFICATION_UNREAD_COUNT,
} from "../../Store/Actions/types";
import NotificationsListService from "../../services/NotificationsListService";
import { useDispatch, useSelector } from "react-redux";

function useSocketSubscribe(socket: Socket) {
 const { unReadCount, notificationsData } = useSelector(
  (state: any) => state.UserNotifications
 );
 const dispatch = useDispatch();

 useEffect(() => {
  if (socket === null) return;

  socket?.on("disconnect", (reason: any) => {
   console.log(`disconnected because of ${reason}`);
  });

  socket?.on("notification", (data: ApiNotificationData) => {
   console.log("NOTIFICATION ON EVENT");
   let temp: ApiNotificationData[] = [data, ...notificationsData];
   const NotificationsList = new NotificationsListService(temp);

   dispatch({
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: {
     notificationsList: NotificationsList.getNotificationsList(),
     notificationsData: temp,
    },
   });

   // const unReadCount = [data].filter(
   //     (notification: ApiNotificationData) => notification.isRead == false
   // ).length;

   dispatch({
    type: UPDATE_NOTIFICATION_UNREAD_COUNT,
    payload: unReadCount + 1,
   });
  });
 }, [socket?.connected, unReadCount, notificationsData.length]);
}

export default useSocketSubscribe;
