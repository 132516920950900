import TopSearch from "./TopSearch";
import DocumentsSection from "./DocumentsSection";
import FAQ from "./FAQ";
import { useEffect, useState } from "react";
import VideoSection from "./VideoSection";
import HelpActions from "../../Store/Actions/HelpActions";
import { connect, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

function HelpScreen({ selectedTab, setSelectedTab, fetchHelp }: any): any {
  const { authState } = useOktaAuth();
  const [searchResults, setSearchResults] = useState([]);
  const videos = useSelector((state: any) => state.Help?.data?.videos);
  const handleSearch = async (data: any) => {
    setSearchResults(data);
  };
  useEffect(() => {
    let options = {
      accessToken: authState?.accessToken?.accessToken,
      onSuccess: () => {
        console.log("success");
      },
      onError: (error: any) => {
        console.log(error);
      },
      searchQuery: ''
    };
    fetchHelp(options);
  }, []);

  return (
    <div className="overflow-auto justify-center items-center">
      <TopSearch onSearch={handleSearch} />
      <div className="py-5 2xl:mx-[140px] xl:mx-[140px] lg:mx-[140px] md:mx-[40px]">
        <DocumentsSection
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        { videos?.length !== 0 && 
        <VideoSection
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        }
        <FAQ
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          searchResults={searchResults}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  fetching: state.Help.fetching,
  error: state.Help.error,
  data: state.Help.data,
});

const mapDispatchToProps = {
  fetchHelp: HelpActions.fetchHelp,
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpScreen);
