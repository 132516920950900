import * as React from "react"
import { SVGProps } from "react"

const FilterClearIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10.121}
    height={10.121}
    {...props}
  >
    <g
      fill="none"
      stroke="#97a0af"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path data-name="Line 847" d="m9.061 1.061-8 8" />
      <path data-name="Line 846" d="m1.061 1.061 8 8" />
    </g>
  </svg>
)

export default FilterClearIcon
