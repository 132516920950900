function CheckBoxLoading({ count }: { count: number }): JSX.Element {
  return <div className="w-full">
    <div className="p-2 bg-dropDownBorder rounded-lg w-[85px] animate-pulse" />
    <ul className="my-2">
      {Array.from({ length: count }, (v, index) => index + 1).map((item: number) =>
        <li key={item} className="flex justify-between items-center mb-2">
          <div className="w-3 p-2 rounded-sm bg-dropDownBorder animate-pulse" />
          <div className="mx-3 w-full bg-dropDownBorder p-2" />
        </li>)}
    </ul>
  </div>
}

function TestsListLoadingIndicator(): JSX.Element {
  return <div className="flex justify-between items-start my-2">
    <CheckBoxLoading count={3} />
    <CheckBoxLoading count={1} />
    <CheckBoxLoading count={1} />
  </div>
}

export default TestsListLoadingIndicator