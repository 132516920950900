/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import React from 'react'
import Header from '../components/global/Header'
import OrderFormTabs from '../components/TestOrders/OrdersFormTabs'

function TestOrdersLayout({ children, headerTitle, selectedTab, setSelectedTab, validSections, isEdit, showTitleLoading, CustomButtons, touchedSections }: any): JSX.Element {
    return <div className="flex flex-col justify-between h-full overflow-hidden">
        <Header
            isInternal
            showTitleLoading={showTitleLoading}
            CustomButtons={CustomButtons}
            title={headerTitle ?? 'Online Order'}
            checkHeaderTitle
        />
        <div className="grid h-full grid-cols-5 overflow-hidden">
            <OrderFormTabs
                validSections={validSections}
                selectedTab={selectedTab}
                isEdit={isEdit}
                setSelectedTab={setSelectedTab}
                touchedSections={touchedSections}
            />
            <div className="col-span-4 grid grid-cols-4 overflow-auto">
                {children}
            </div>
        </div>
    </div>
}

export default TestOrdersLayout
