/* eslint-disable @typescript-eslint/space-before-function-paren */
import store from '..'
import reduxActionType from '../../models/reduxActionType'
import { ADD_SECTION, CREATE_ONLINE_ORDER, CREATE_ONLINE_ORDER_ERROR, CREATE_ONLINE_ORDER_SUCCESS, FETCH_PATIENTS, FETCH_PATIENTS_ERROR, FETCH_PATIENTS_SUCCESS, FETCH_TESTS_ICD_CODES, FETCH_TESTS_ICD_CODES_ERROR, FETCH_TESTS_ICD_CODES_SUCCESS, REMOVE_SECTION, RESET_SELECTED_TEST, RESET_SAVE_ORDER , TOGGLE_MODE, UPDATE_SELECTED_ORGANIZATION, UPDATE_SELECTED_PHYSICANS, UPDATE_SELECTED_TEST, FETCHING_INSURANCE_DATA, FETCHING_INSURANCE_ERROR, FETCHING_INSURANCE_SUCCESS, TOGGLE_CLINICAL_INFO_VALID, RESET_SELECTED_PHYSICIAN, FETCH_LABS, FETCH_LABS_ERROR, FETCH_LABS_SUCCESS, SAVE_ONLINE_ORDER, SAVE_ONLINE_ORDER_SUCCESS, SAVE_ONLINE_ORDER_ERROR, DISCARD_FORM, RESET_MODE, FETCH_ORDER_BY_ID, FETCH_ORDER_BY_ID_SUCCESS, FETCH_ORDER_BY_ID_ERROR, SET_MODE_EDIT, FETCH_LAB_BY_ID, FETCH_LAB_BY_ID_SUCCESS, ADD_NOTES, ADD_NOTES_ERROR, ADD_NOTES_SUCCESS, UPDATE_IS_PHYSICIAN_SIGN_PERMISSION, LOGOUT, FETCH_ORG_USERS_SUCCESS, UPDATE_SELECTED_PATHLAB, RESET_SELECTED_PATHLAB, RETRY_ONLINE_ORDER, RETRY_ONLINE_ORDER_SUCCESS, RETRY_ONLINE_ORDER_ERROR, FETCH_PORTAL_USER_BY_ID_SUCCESS } from '../Actions/types'

const initialState: any = {
  selectedTest: {
    id: 1,
    checkedIndexes: [0]
  },
  fetchingOrderByID: false,
  fetchingOrderByIDError: false,
  fetchingOrderByIDSuccess: false,
  orderData: null,
  fetchingTestsAndIcdCodes: false,
  fetchingTestsAndIcdCodesError: false,
  testsAndIcdCodes: [],
  fetchingPatients: false,
  fetchingPatientsError: false,
  creatingOrder: false,
  isPhysicianSignPermission: false,
  createError: false,
  savingOrder: false,
  saveError: false,
  addingNotes: false,
  addNotesError: false,
  addNotesSuccess: false,
  patients: [],
  mode: 'review',
  validSections: [],
  selectedOrganization: null,
  selectedPhysician: null,
  selectedPathLab: null,
  fetchingInsurance: false,
  fetchingInsuranceCompanyError: false,
  insurance_company: [],
  error: false,
  clinicalInfoValid: true,
  fetchingLabs: false,
  fetchingLab: false,
  fetchingLabError: false,
  labData: null,
  fetchingLabsError: false,
  alllabs: [],
  icdCodes: [],
  discardForm: false,
}

function handeTestAndIcdCodesResponse(testsData: any[]): any[] {
  function handleTests(tests: any[]) {
    return [...tests.map((item: any) => {
      let data = [{
        id: item.id,
        label: item.name,
        name: item.code,
        bulletPoint: item.id == 4 ? item.DecisionDxPRAMEDescription : undefined,
        checked: false,
        ...item
      }]

      data = [...data, ...item.testTypes.map((test: any) => {
        return {
          id: test.id,
          label: test.name,
          name: test.code,
          checked: false,
        }
      })]

      return { ...item, data }
    })]
  }
  let testsAndIcdCodes: any = []
  testsData.forEach((test) => {
    let item = {
      disease: test.name,
      tests: handleTests(test.testTypes)
    }

    testsAndIcdCodes.push(item)
  })

  return testsAndIcdCodes
}

function handleIcdCodes(checkedIndexes: number[], testsAndIcdCodes: any): any[] {
  let icdCodes: any = []

  testsAndIcdCodes.forEach((data: any) => {
    let foundTest = data?.tests?.find((test: any) => checkedIndexes?.includes(test?.id))
    if (foundTest) {
      icdCodes = [...foundTest.icdCodes]
    }
  })
  return icdCodes
}

function OnlineOrderFormReducer(state = initialState, action: reduxActionType): any {
  switch (action.type) {
    case UPDATE_SELECTED_TEST:
      return {
        ...state,
        selectedTest: {
          ...state?.selectedTest,
          id: action?.payload?.id,
          checkedIndexes: action?.payload?.checkedIndexes,
        },
        icdCodes: handleIcdCodes(action?.payload?.checkedIndexes, state.testsAndIcdCodes)
      }
    case RESET_SELECTED_TEST: {
      return {
        ...state,
        selectedTest: {
          ...state.selectedTest,
          id: 1,
          checkedIndexes: [],
        },
        icdCodes: []
      }
    }
    case ADD_SECTION: {
      const temp = [...state.validSections]
      const foundIndex = temp.findIndex(item => item === action.payload)

      if (foundIndex === -1) {
        return {
          ...state,
          validSections: [...state.validSections, action.payload]

        }
      }

      return {
        ...state
      }
    }
    case REMOVE_SECTION: {
      return {
        ...state,
        validSections: state.validSections.filter((item: any) => item !== action.payload)
      }
    }
    case TOGGLE_MODE: {
      return {
        ...state,
        mode: state.mode === 'edit' ? 'review' : 'edit'
      }
    }
    case SET_MODE_EDIT: {
      return {
        ...state,
        mode: 'edit'
      }
    }
    case RESET_MODE: {
      return {
        ...state,
        mode: 'review'
      }
    }
    case FETCH_TESTS_ICD_CODES: {
      return {
        ...state,
        fetchingTestsAndIcdCodes: true,
        fetchingTestsAndIcdCodesError: false
      }
    }
    case FETCH_TESTS_ICD_CODES_ERROR: {
      return {
        ...state,
        fetchingTestsAndIcdCodes: false,
        fetchingTestsAndIcdCodesError: true
      }
    }
    case FETCH_TESTS_ICD_CODES_SUCCESS: {
      return {
        ...state,
        fetchingTestsAndIcdCodes: false,
        fetchingTestsAndIcdCodesError: false,
        testsAndIcdCodes: handeTestAndIcdCodesResponse(action.payload)
        // testsAndIcdCodes: [...action.payload.map((item: any) => {
        //   let data = [{
        //     id: item.id,
        //     label: item.name,
        //     name: item.code,
        //     bulletPoint: item.id == 4 ? item.DecisionDxPRAMEDescription : undefined,
        //     checked: false,
        //     ...item
        //   }]

        //   data = [...data, ...item.testTypes.map((test: any) => {
        //     return {
        //       id: test.id,
        //       label: test.name,
        //       name: test.code,
        //       checked: false,
        //     }
        //   })]

        //   return { ...item, data }
        // })]
      }
    }
    case FETCH_PATIENTS: {
      return {
        ...state,
        fetchingPatients: true,
        fetchingPatientsError: false
      }
    }
    case FETCH_PATIENTS_ERROR: {
      return {
        ...state,
        fetchingPatients: false,
        fetchingPatientsError: true
      }
    }
    case FETCH_PATIENTS_SUCCESS: {
      return {
        ...state,
        fetchingPatients: false,
        fetchingPatientsError: false,
        patients: action.payload
      }
    }
    case UPDATE_SELECTED_ORGANIZATION: {
      return {
        ...state,
        selectedOrganization: action.payload
      }
    }
    case UPDATE_SELECTED_PHYSICANS: {
      return {
        ...state,
        selectedPhysician: action.payload
      }
    }
    case CREATE_ONLINE_ORDER: {
      return {
        ...state,
        creatingOrder: true,
        createError: false
      }
    }
    case CREATE_ONLINE_ORDER_SUCCESS: {
      return {
        ...state,
        creatingOrder: false,
        createError: false
      }
    }
    case CREATE_ONLINE_ORDER_ERROR: {
      return {
        ...state,
        creatingOrder: false,
        createError: true
      }
    }
    case RESET_SAVE_ORDER: {
      return {
        ...state,
        savingOrder: false,
        saveError: false
      }
    }
    case SAVE_ONLINE_ORDER: {
      return {
        ...state,
        savingOrder: true,
        saveError: false,
      }
    }
    case SAVE_ONLINE_ORDER_SUCCESS: {
      return {
        ...state,
        savingOrder: false,
        saveError: false,
      }
    }

    case SAVE_ONLINE_ORDER_ERROR: {
      return {
        ...state,
        savingOrder: false,
        saveError: true,
      }
    }
    case RETRY_ONLINE_ORDER: {
      return {
        ...state,
        savingOrder: true,
        saveError: false,
      }
    }
    case RETRY_ONLINE_ORDER_SUCCESS: {
      return {
        ...state,
        savingOrder: false,
        saveError: false,
      }
    }

    case RETRY_ONLINE_ORDER_ERROR: {
      return {
        ...state,
        savingOrder: false,
        saveError: true,
      }
    }

    case FETCHING_INSURANCE_DATA:
      return {
        ...state,
        fetchingInsurance: true,
        error: false
      }
    case FETCHING_INSURANCE_SUCCESS: {
      return {
        ...state,
        fetchingInsurance: false,
        error: false,
        insurance_company: [...action.payload, {
          id: 19,
          name: "Enter new insurance"
        }]
      }
    }
    case FETCHING_INSURANCE_ERROR: {
      return {
        ...state,
        fetchingInsurance: false,
        error: true
      }
    }
    case TOGGLE_CLINICAL_INFO_VALID: {
      return {
        ...state,
        clinicalInfoValid: action.payload
      }
    }
    case RESET_SELECTED_PHYSICIAN: {
      return {
        ...state,
        selectedPhysician: null
      }
    }
    case RESET_SELECTED_PATHLAB: {
      return {
        ...state,
        selectedPathLab: null
      }
    }
    case FETCH_LABS: {
      return {
        ...state,
        fetchingLabs: true,
        fetchingLabsError: false
      }
    }
    case FETCH_LABS_ERROR: {
      return {
        ...state,
        fetchingLabs: false,
        fetchingLabsError: true
      }
    }
    case FETCH_LABS_SUCCESS: {
      return {
        ...state,
        fetchingLabs: false,
        fetchingLabsError: false,
        alllabs: action.payload
      }
    }
    case DISCARD_FORM: {
      return {
        ...state,
        discardForm: !state.discardForm
      }
    }
    case FETCH_ORDER_BY_ID: {
      return {
        ...state,
        orderData: null,
        fetchingOrderByID: true,
        fetchingOrderByIDError: false,
        fetchingOrderByIDSuccess: false,
      }
    }
    case FETCH_ORDER_BY_ID_SUCCESS: {
      return {
        ...state,
        fetchingOrderByID: false,
        fetchingOrderByIDError: false,
        fetchingOrderByIDSuccess: true,
        orderData: action.payload
      }
    }
    case FETCH_PORTAL_USER_BY_ID_SUCCESS: {

      if (action?.payload?.user?.id == state?.selectedPhysician?.id)
        return {
          ...state,
          selectedPhysician: { ...state.selectedPhysician, ...action.payload.user }
        }
      return {
        ...state
      }
    }
    case FETCH_ORDER_BY_ID_ERROR: {
      return {
        ...state,
        fetchingOrderByID: false,
        fetchingOrderByIDError: true,
        fetchingOrderByIDSuccess: false,
      }
    }
    case FETCH_LAB_BY_ID: {
      return {
        ...state,
        fetchingLab: true,
        fetchingLabError: false,
      }
    }
    case FETCH_LAB_BY_ID_SUCCESS: {
      return {
        ...state,
        fetchingLab: false,
        fetchingLabError: false,
        labData: action.payload,
      }
    }
    case FETCHING_INSURANCE_ERROR: {
      return {
        ...state,
        fetchingLab: false,
        fetchingLabError: true,
      }
    }
    case ADD_NOTES: {
      return {
        ...state,
        addingNotes: true,
        addNotesError: false,
        addNotesSuccess: false,
      }
    }
    case ADD_NOTES_ERROR: {
      return {
        ...state,
        addingNotes: false,
        addNotesError: true,
        addNotesSuccess: false,
      }
    }
    case ADD_NOTES_SUCCESS: {
      return {
        ...state,
        addingNotes: false,
        addNotesError: false,
        addNotesSuccess: true,
      }
    }
    case UPDATE_IS_PHYSICIAN_SIGN_PERMISSION: {
      return {
        ...state,
        isPhysicianSignPermission: action.payload
      }
    }
    case FETCH_ORG_USERS_SUCCESS: {
      if (state.selectedOrganization) {
        let updatedOrganization: any = {
          ...state.selectedOrganization,
          users: action.payload.data
        }
        return {
          ...state,
          selectedOrganization: updatedOrganization
        }
      }
      return { ...state }
    }
    case UPDATE_SELECTED_PATHLAB: {
      return {
        ...state,
        selectedPathLab: action.payload
      }
    }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default OnlineOrderFormReducer
