import * as React from 'react'
import { SVGProps } from 'react'

const ProfileIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={11.667} height={17} {...props}> <g
    data-name="Component 6411 \u2013 4"
    transform="translate(.5 .5)"
    fill={props.fill ?? 'none'}
    stroke={props.stroke ?? '#172b4d'}
    strokeLinecap="round"
    strokeLinejoin="round"
>
            <circle
                data-name="Ellipse 602"
                cx={3.556}
                cy={3.556}
                r={3.556}
                transform="translate(1.778)"
            />
            <path
                data-name="Path 5231"
                d="M0 16v-1.777a3.556 3.556 0 0 1 3.556-3.556h3.556a3.556 3.556 0 0 1 3.556 3.556v1.778"
            />
        </g>
    </svg>
)

export default ProfileIcon
