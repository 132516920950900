import * as Yup from "yup";

const ApproveOrderValidation = Yup.object().shape({
 password: Yup.string().required("Password is required!").test('no-empty-blank-space', 'Password cannot be an empty blank space(s)', (value:any) => {
    return value && value.trim() !== '';
  }),
 username: Yup.string().required("Email or username is required!").test('no-empty-blank-space', 'Email or username cannot be an empty blank space(s)', (value:any) => {
    return value && value.trim() !== '';
  }),
});

export default ApproveOrderValidation;
