import { useEffect, useCallback, useState, Fragment, useRef } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { toggleSumbit } from "../../../../Store/Actions/ButtonStateActions";
import ButtonStateActions from "../../../../Store/Actions/ButtonStateActions";
import validationHelper from "../../../../utils/helpers/orderFormValidationHelper";
import CustomInput from "../../../global/CustomInput";
import moment from "moment";
import { useOktaAuth } from "@okta/okta-react";
import toastMessages from "../../../../utils/helpers/toastMessage";
import lodash from "lodash";
import {
  fetchLabById,
  fetchLabs,
} from "../../../../Store/Actions/OnlineOrderFormActions";
import { InView } from "react-intersection-observer";
import scrollHelper from "../../../../utils/helpers/scrollHelper";
import ReactTooltip from "react-tooltip";
import MultiSelectDropdown from "../../../global/MultiselectDropdown";
import dataConstants from "../../../../constants/dataConstants";
import { useLocation } from "react-router";

const InputFields: any = {
  name_of_facility: "name",
  date_of_collection: "date_of_collection",
  facility_contact_person: "",
  lab_fax: "fax",
  lab_phone: "phone",
  isTeleHealth: false,
  facilityType: "",
  specimenId: "",
  tubeBarcode: "",
  pathologist: "",
  workingDiagnosis: "",
  tumorSite: "",
};

function LaboratoryInfoSection({
  loading,
  initialValues,
  values,
  setFieldValue,
  isUpdate,
  errors,
  touched,
  setFieldError,
  validateField,
  validateForm,
  setValidSections,
  fetchLabs,
  selectedTab,
  setSelectedTab,
  fetchLabById,
  fetchingLab,
  fetchingLabError,
  labData,
  ...rest
}: any) {
  const dispatch = useDispatch();
  const [selectedLab, setSelectedLab] = useState<any>({});
  const date = new Date();

  const { state }: any = useLocation();
  const dateOffset = 24 * 60 * 60 * 1000 * 1;
  // const maxDate = date.setTime(date.getTime() - dateOffset)
  const maxDate = date;
  const debounceSearch = useCallback(lodash.debounce(searchLab, 700), []);
  const { authState } = useOktaAuth();
  const [searching, setSearching] = useState<boolean>(false);
  const [showLabSuggestions, setShowLabSuggestions] = useState<boolean>(false);
  const { alllabs } = useSelector((state: any) => state.OnlineOrderForm);
  const { selectedTest, selectedPathLab, selectedPhysician } = useSelector(
    (state: any) => state.OnlineOrderForm
  );
  const { profile } = useSelector((state: any) => state.Profile);
  const isDualRoleCOA =
    profile?.roles?.includes("Collaborative Order Agreement") &&
    profile?.roles?.includes("Physician");
  const isCOA = profile?.roles?.includes("Collaborative Order Agreement");
  const physicianSameAsUser = selectedPhysician?.id == profile?.id;
  const prevSelectedTestId = useRef();
  async function searchLab(input: any): Promise<void> {
    const options = {
      onError: (message: string) => {
        toastMessages.error(message);
        setFieldError("name_of_facility", "Facility not found");
        setSearching(false);
        setShowLabSuggestions(false);
      },
      onSuccess: () => {
        setSearching(false);
        setShowLabSuggestions(true);
      },
      onEmpty: () => {
        setFieldError("name_of_facility", "Facility not found");
        setSearching(false);
        setShowLabSuggestions(false);
      },
      search: encodeURIComponent(input),
      config: {
        headers: {
          Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
        },
      },
    };
    if (loading === false) {
      fetchLabs(options);
    }
  }

  function enableSubmit() {
    dispatch(toggleSumbit(true));
  }
  function disableSubmit() {
    dispatch(toggleSumbit(false));
  }

  function handleChange(name: string, val: any) {
    const updatedValues = { ...values };
    updatedValues[name] = val;
    setFieldValue(name, val);

    rest?.setFieldTouched?.(name, true, true);
    validationHelper(
      validateForm,
      setValidSections,
      updatedValues,
      InputFields,
      "laboratory info",
      enableSubmit,
      disableSubmit
    );
    if (name == "name_of_facility") {
      debounceSearch(val);
    }
    if (name === "tubeBarcode" && selectedTest?.id === 8) {
      if (values?.isTeleHealth === false && val !== null)
        dispatch(ButtonStateActions.toggleSumbit(false));
      setValidSections((prevState: any) =>
        prevState.filter((item: string) => item !== "laboratory info")
      );
    }
  }
  const handleLabChange = (name: string, val: any) => {
    const updatedValues = { ...values };
    updatedValues[name] = val;
    if (name == "name_of_facility") {
      setSearching(true);
      debounceSearch(val);
    }
    const id = Number(val?.split("|")[0]?.trim());
    const selectedItem = alllabs?.filter((e: any) => e.id === id)[0];
    if (selectedItem) {
      setFieldValue("labortaryId", selectedItem.id);
    }
    if (val === "") {
      setFieldValue("labortaryId", "");
    }
    setSelectedLab(selectedItem);
    setFieldValue(name, val);
    setFieldValue(
      "lab_phone",
      !!selectedItem?.PathologyLabPhone1 ? selectedItem?.PathologyLabPhone1 : ""
    );
    setFieldValue(
      "lab_fax",
      !!selectedItem?.PathologyLabFax1 ? selectedItem?.PathologyLabFax1 : ""
    );
    setFieldValue(
      "facility_contact_person",
      !!selectedItem?.CreatedBy ? selectedItem?.CreatedBy : ""
    );
    validationHelper(
      validateForm,
      setValidSections,
      updatedValues,
      InputFields,
      "laboratory info",
      enableSubmit,
      disableSubmit
    );
  };
  useEffect(() => {
    if (isUpdate && !!values?.labortaryId) {
      const ResponseFields = {
        name_of_facility: "DataRecordName",
        lab_fax: "PathologyLabFax1",
        lab_phone: "PathologyLabPhone1",
      };
      const options = {
        id: values?.labortaryId,
        onSuccess: (data: any) => {
          let updatedValues = { ...values };
          Object.keys(ResponseFields).forEach((key: string) => {
            type ObjectKey = keyof typeof ResponseFields;
            const myKey = key as ObjectKey;
            if (key === "name_of_facility") {
              setFieldValue(key, data[ResponseFields[myKey]]);
              updatedValues[key] = data[ResponseFields[myKey]];
            }
            rest.setFieldTouched(key);
          });
          validationHelper(
            validateForm,
            setValidSections,
            updatedValues,
            InputFields,
            "laboratory info",
            enableSubmit,
            disableSubmit
          );
        },
        config: {
          headers: {
            Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
          },
        },
        onError: (message: string) => toastMessages.error(message),
      };
      fetchLabById(options);
    } else {
      if (isDualRoleCOA && physicianSameAsUser) return;
      if (isCOA && selectedPathLab?.id) {
        let updatedValues = { ...values };
        Object.keys(InputFields).forEach((key: string) => {
          if (InputFields[key]) {
            updatedValues[key] = selectedPathLab[InputFields[key]];
            setFieldValue(key, selectedPathLab[InputFields[key]]);
          }
        });
        updatedValues.labortaryId = selectedPathLab?.id;
        setFieldValue("labortaryId", selectedPathLab?.id);
        validationHelper(
          validateForm,
          setValidSections,
          updatedValues,
          InputFields,
          "laboratory info",
          enableSubmit,
          disableSubmit
        );
      } else
        validationHelper(
          validateForm,
          setValidSections,
          values,
          InputFields,
          "laboratory info",
          enableSubmit,
          disableSubmit
        );
    }
  }, [selectedPathLab?.id, selectedPhysician?.id]);
  useEffect(() => {
    // {selectedTest.id !== 4 ? setFieldValue('typeofspecimen','Slides from Formalin Fixed Paraffin Embedded Tumor Tissue'): ''}

    if (selectedTest?.id === 8) {
      setFieldValue("typeofspecimen", "Buccal Swab");
      validationHelper(
        validateForm,
        setValidSections,
        { ...values, typeofspecimen: "Buccal Swab" },
        InputFields,
        "laboratory info",
        enableSubmit,
        disableSubmit
      );
    } else if (selectedTest.id !== 4) {
      setFieldValue(
        "typeofspecimen",
        "Slides from Formalin Fixed Paraffin Embedded Tumor Tissue"
      );
      validationHelper(
        validateForm,
        setValidSections,
        {
          ...values,
          typeofspecimen:
            "Slides from Formalin Fixed Paraffin Embedded Tumor Tissue",
        },
        InputFields,
        "laboratory info",
        enableSubmit,
        disableSubmit
      );
    } else
      validationHelper(
        validateForm,
        setValidSections,
        values,
        InputFields,
        "laboratory info",
        enableSubmit,
        disableSubmit
      );
  }, [selectedTest?.id]);

  useEffect(() => {
    if (
      prevSelectedTestId.current === 8 &&
      prevSelectedTestId.current !== values?.selectedTest?.id
    ) {
      setFieldValue("facilityType", "");
    }
    prevSelectedTestId.current = values?.selectedTest?.id;
  }, [selectedTest?.id, state?.orderValue]);

  function handleMouseScroll(inView: boolean) {
    scrollHelper.handleInView(
      inView,
      setSelectedTab,
      rest.firstScroll,
      "laboratory info"
    );
    if (!inView && rest.touchedSections.includes("laboratory info"))
      Object.keys(InputFields).forEach((key) => {
        rest.setFieldTouched(key, true, true);
      });
  }

  function handleTeleHealthandTubeBar(e: any): void {
    const val = e.target.checked;
    if (val) {
      toastMessages.info(`If this is a telehealth
            order and you'd like your patient to receive alerts about their kit,
            please enter patient email.`);
    }
    setFieldValue("isTeleHealth", val);
    setFieldValue("tubeBarcode", "");
    setFieldValue("date_of_collection", "");
    if (val) setFieldValue("facilityType", "Patient's Home");
    else setFieldValue("facilityType", "");

    validationHelper(
      validateForm,
      setValidSections,
      {
        ...values,
        isTeleHealth: val,
        tubeBarcode: "",
        date_of_collection: "",
        facilityType: val ? "Patient's Home" : "",
      },
      InputFields,
      "laboratory info",
      enableSubmit,
      disableSubmit
    );
  }

  function handleBlur() {
    rest.setTouchedSections((prevState: any) => [
      ...prevState,
      "laboratory info",
    ]);
  }

  return (
    <InView
      as="div"
      // className="scroll-mt-20"
      className={
        selectedTab === "select test"
          ? "scroll-mt-64 tab:scroll-my-72"
          : "scroll-mt-32"
      }
      id="laboratory info"
      threshold={window.innerWidth <= 1280 ? 0.5 : 0.9}
      onChange={handleMouseScroll}
    >
      <div className="pb-14 flex flex-col">
        <div className="flex flex-col items-start">
          {selectedTest?.id == 5 && (
            <p className="text-royalBlue mb-2">
              In the absence of preliminary path report, please submit the
              following form, or other document containing the following
              information.
            </p>
          )}
          <p className="text-royalBlue">
            Type of specimen submitted for testing
          </p>

          {selectedTest.id === 4 && (
            <CustomInput
              id={"typeofspecimen"}
              name={"typeofspecimen"}
              label={"Fine Needle Aspiration Biopsy"}
              isInternal
              type="radio"
              onBlurCapture={handleBlur}
              value={"Fine Needle Aspiration Biopsy"}
              checkboxContainerStyle={"mt-4"}
              checked={
                values.typeofspecimen === "Fine Needle Aspiration Biopsy" ??
                false
              }
              onChange={(e: any) => {
                setFieldValue("typeofspecimen", e.target.value);
                const newValues = { ...values };
                newValues.typeofspecimen = e.target.value;
                validationHelper(
                  validateForm,
                  setValidSections,
                  newValues,
                  InputFields,
                  "laboratory info",
                  enableSubmit,
                  disableSubmit
                );
              }}
              required={false}
              placeholder={""}
              errors={errors}
              touched={touched}
            />
          )}

          <CustomInput
            id={"typeofspecimen"}
            checkboxContainerStyle={"my-2"}
            name={"typeofspecimen"}
            label={selectedTest?.id === 8 ? "Buccal Swab" : "Slides/Block"}
            isInternal
            type="radio"
            value={
              selectedTest?.id === 8
                ? "Buccal Swab"
                : "Slides from Formalin Fixed Paraffin Embedded Tumor Tissue"
            }
            checked={
              selectedTest?.id === 8
                ? values?.typeofspecimen === "Buccal Swab" ?? false
                : values.typeofspecimen ===
                    "Slides from Formalin Fixed Paraffin Embedded Tumor Tissue" ??
                  false
            }
            onChange={(e: any) => {
              setFieldValue("typeofspecimen", e.target.value);
              const newValues = { ...values };
              newValues.typeofspecimen = e.target.value;
              validationHelper(
                validateForm,
                setValidSections,
                newValues,
                InputFields,
                "laboratory info",
                enableSubmit,
                disableSubmit
              );
            }}
            required={false}
            placeholder={""}
            onBlurCapture={handleBlur}
            errors={errors}
            touched={touched}
          />
        </div>
        <div className="focus:outline-primary p-6 my-5 shadow-sm border border-[0.5px] rounded-md flex flex-col hover:border-primary hover:border">
          <p className="text-royalBlue font-medium text-base">
            {selectedTest?.id === 8 ? "Specimen" : "Laboratory"}
          </p>
          {selectedTest?.id !== 8 && (
            <>
              <CustomInput
                id={"name_of_facility"}
                checkboxContainerStyle={"my-2"}
                name={"name_of_facility"}
                label={"Name of Facility Where Tissue is Maintained"}
                value={values?.name_of_facility}
                onBlurCapture={handleBlur}
                isInternal
                // disabled={profile?.roles?.includes('Collaborative Order Agreement')}
                disabled={!physicianSameAsUser && isCOA}
                showSuggestions={showLabSuggestions}
                onChange={(value: any) => {
                  setSearching(true);
                  handleLabChange(
                    "name_of_facility",
                    value?.target ? value.target.value : value
                  );
                }}
                isSearching={loading || searching}
                required={
                  values?.selectedTest?.id == 4 &&
                  values?.typeofspecimen === "Fine Needle Aspiration Biopsy"
                    ? false
                    : true
                }
                placeholder={"Select a facility"}
                suggestions={
                  alllabs && alllabs.length > 0
                    ? alllabs.map((val: any) => ({
                        label: `${val.id} | ${val.DataRecordName}`,
                        value: `${val.id} | ${val.DataRecordName}`,
                      }))
                    : []
                }
                hideDropdownIcon
                errors={errors}
                touched={touched}
              />
            </>
          )}
          <div
            className={` justify-between items-center relative ${
              selectedTest?.id !== 8 && "2xl:flex xl:flex lg:flex md:block"
            }`}
          >
            <div className={`${selectedTest?.id === 8 && "flex"} w-full mr-5`}>
              <CustomInput
                id={"date_of_collection"}
                key={"date_of_collection"}
                name={"date_of_collection"}
                label={"Date of Collection"}
                disabled={
                  selectedTest?.id === 8 && values?.isTeleHealth === true
                }
                isInternal
                onBlurCapture={handleBlur}
                value={values.date_of_collection}
                type={"date"}
                onChange={(e: any) => {
                  handleChange("date_of_collection", e);
                }}
                required={
                  values?.selectedTest?.id == 4 &&
                  values?.typeofspecimen === "Fine Needle Aspiration Biopsy"
                    ? false
                    : values?.selectedTest?.id === 8 && values?.isTeleHealth
                    ? false
                    : true
                }
                placeholder={"MM-DD-YYYY"}
                max={
                  values?.selectedTest?.id === 8
                    ? new Date()
                    : new Date(maxDate)
                }
                errors={errors}
                className={"mr-5 relative"}
                touched={touched}
              />

              {selectedTest?.id === 8 && (
                <CustomInput
                  id={"tubeBarcode"}
                  key={"tubeBarcode"}
                  name={"tubeBarcode"}
                  label={"Tube Barcode"}
                  isInternal
                  disabled={values?.isTeleHealth === true}
                  value={values.tubeBarcode}
                  type={"text"}
                  placeholder="9XYZW-A"
                  onBlurCapture={handleBlur}
                  onChange={(e: any) => {
                    handleChange("tubeBarcode", e.target.value);
                  }}
                  required={!values?.isTeleHealth}
                  errors={errors}
                  className={""}
                  touched={touched}
                />
              )}
            </div>
            {selectedTest?.id === 8 && (
              <div className="mt-2">
                <CustomInput
                  id={"isTeleHealth"}
                  key={"isTeleHealth"}
                  name={"isTeleHealth"}
                  label={"Telehealth Order"}
                  isInternal
                  value={values.isTeleHealth}
                  type={"checkbox"}
                  checked={values?.isTeleHealth ? true : false}
                  placeholder=""
                  // onChange={(e: any) => {
                  //   handleChange("isTeleHealth", e.target.checked)
                  // }
                  // }
                  onChange={handleTeleHealthandTubeBar}
                  required={false}
                  errors={errors}
                  onBlurCapture={handleBlur}
                  className={"mr-1"}
                  touched={touched}
                  showToolTip={true}
                  tooltipText="If this box is checked, date of collection and tube barcode field can be left blank. Not applicable if sample collected in office."
                />
              </div>
            )}
            {selectedTest?.id === 8 && (
              <div className="flex w-full">
                <MultiSelectDropdown
                  label="Location of Sample Collection (select only one)"
                  required={true}
                  errors={errors}
                  touched={{ ...touched }}
                  disabled={values.isTeleHealth}
                  name="facilityType"
                  id="facilityType"
                  onOptionClick={(item) => {
                    handleChange("facilityType", item.value);
                  }}
                  options={dataConstants.facilityTypeOptions.map(
                    (option: any) => {
                      if (
                        !!values.facilityType &&
                        option.value == values.facilityType
                      ) {
                        return {
                          ...option,
                          selected: true,
                        };
                      }
                      return {
                        ...option,
                      };
                    }
                  )}
                />
              </div>
            )}
            {selectedTest?.id !== 8 && (
              <CustomInput
                id={"specimenId"}
                key={"specimenId"}
                onBlurCapture={handleBlur}
                name={"specimenId"}
                label={
                  selectedTest?.id == 5
                    ? "Accession# / Specimen ID "
                    : "Specimen ID"
                }
                required={selectedTest?.id == 5}
                placeholder={
                  selectedTest?.id === 5
                    ? "Enter Accession# / Specimen ID"
                    : "Enter specimen ID"
                }
                isInternal
                value={values?.specimenId}
                onChange={(e: any) =>
                  handleChange("specimenId", e.target.value)
                }
                errors={errors}
                touched={touched}
              />
            )}
          </div>
          {selectedTest?.id !== 8 && (
            <div className="w-full">
              <p className="text-royalBlue font-normal text-sm">
                <em>
                  If specimen is stored for more than 30 days from the date of
                  collection, please provide the date specimen is pulled from
                  archive:
                </em>
              </p>
              <CustomInput
                id={"medicarePullDate"}
                key={"medicarePullDate"}
                name={"medicarePullDate"}
                label={"Medicare Pull Date"}
                disabled={
                  selectedTest?.id === 8 && values?.isTeleHealth === true
                }
                isInternal
                onBlurCapture={handleBlur}
                value={values.medicarePullDate}
                type={"date"}
                onChange={(e: any) => {
                  handleChange("medicarePullDate", e);
                }}
                required={false}
                placeholder={"MM-DD-YYYY"}
                max={
                  values?.selectedTest?.id === 8
                    ? new Date()
                    : new Date(maxDate)
                }
                errors={errors}
                className={" relative mt-0"}
                touched={touched}
              />
            </div>
          )}
          <div
            // className={`${selectedTest?.id === 5 && 'flex justify-between items-center relative'}`}
            className={`flex justify-between items-center relative`}
          >
            {selectedTest?.id === 5 && (
              <CustomInput
                id={"pathologist"}
                key={"pathologist"}
                name={"pathologist"}
                label={"Pathologist"}
                required={false}
                placeholder={"Enter Pathologist"}
                isInternal
                value={values?.pathologist}
                onChange={(e: any) =>
                  handleChange("pathologist", e.target.value)
                }
                errors={errors}
                onBlurCapture={handleBlur}
                touched={touched}
                className="mr-5"
              />
            )}
            {selectedTest?.id !== 8 && (
              <CustomInput
                id={"lab_phone"}
                key={"lab_phone"}
                name={"lab_phone"}
                label={"Phone"}
                required={false}
                placeholder={"000-000-0000"}
                isInternal
                value={values.lab_phone}
                onChange={(e: any) => handleChange("lab_phone", e.target.value)}
                errors={errors}
                onBlurCapture={handleBlur}
                touched={touched}
                // className={`2xl:ml-5 xl:ml-5 lg:ml-5 md:ml-0 ${selectedTest?.id !== 5 && '2xl:w-[60%] xl:w-[60%] lg:w-[60%] md:w-[48%]'}`}
                className={`w-full mr-5`}
              />
            )}
            {selectedTest?.id !== 8 && (
              <div
                className={`2xl:flex xl:flex lg:flex md:block justify-between items-center w-full`}
              >
                <CustomInput
                  id={"lab_fax"}
                  key={"lab_fax"}
                  name={"lab_fax"}
                  label={"Fax"}
                  required={false}
                  placeholder={"000-000-0000"}
                  value={values.lab_fax}
                  isInternal
                  onChange={(e: any) => handleChange("lab_fax", e.target.value)}
                  errors={errors}
                  onBlurCapture={handleBlur}
                  touched={touched}
                  // className={`${selectedTest?.id !== 5 && "2xl:w-[77%] xl:w-[77%] lg:w-[77%] md:w-full"}`}
                  className={`w-full`}
                />
              </div>
            )}
          </div>

          {selectedTest?.id === 5 && (
            <CustomInput
              id={"tumorSite"}
              key={"tumorSite"}
              name={"tumorSite"}
              label={"Tumor Site"}
              required
              placeholder={"Enter Tumor Site"}
              isInternal
              onBlurCapture={handleBlur}
              value={values?.tumorSite}
              onChange={(e: any) => handleChange("tumorSite", e.target.value)}
              errors={errors}
              touched={touched}
            />
          )}
        </div>
      </div>
    </InView>
  );
}

const mapStateToProps = (state: any): any => ({
  loading: state.OnlineOrderForm.fetchingLabs,
  error: state.OnlineOrderForm.fetchingLabsError,
  alllabs: state.OnlineOrderForm.alllabs,
  fetchingLab: state.OnlineOrderForm.fetchingLab,
  fetchingLabError: state.OnlineOrderForm.fetchingLabError,
  labData: state.OnlineOrderForm.labData,
});

const mapDispatchToProps = {
  fetchLabs: fetchLabs,
  fetchLabById: fetchLabById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LaboratoryInfoSection);
