/* eslint-disable @typescript-eslint/quotes */
import * as Yup from "yup";

const PhysicianProfileCompletionValidation = Yup.object().shape({
firstName: Yup.string().required("First name is required!"),
lastName: Yup.string().required("Last name is required!"),
 email_id: Yup.string().email("Email ID is invalid!"),
 suffix: Yup.string().required("Suffix is required!"),
 specialty: Yup.string().required("Speciality is required!"),
 npi: Yup.string().matches(
    /^[0-9]{10}$/,
    "NPI must be a 10-digit number"
  ).required("NPI is required!"),
//  primaryPhone: Yup.string()
//   .matches(
//    /\d{3}-\d{3}-\d{4}/,
//    //  /\d?(\s?|-?|\+?|\.?)((\(\d{1,4}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)\d{3}(-|\.|\s)\d{4}/,
//    "Phone must contain 10 digits with following format 000-000-0000."
//   )
//   .required("Phone is required!"),
//  fax: Yup.string()
//   .matches(
//    /\d{3}-\d{3}-\d{4}/,
//    //  /\d?(\s?|-?|\+?|\.?)((\(\d{1,4}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)\d{3}(-|\.|\s)\d{4}/,
//    "Fax must contain 10 digits with following format 000-000-0000."
//   )
//   .required("Fax is required!"),
//  city: Yup.string().required("City is required!"),
//  zipCode: Yup.string()
//   .matches(/^[0-9]+$/, "Zip code must be numbers")
//   .required("Zip code is required!")
//   .min(5, "Length must equal to 5")
//   .max(5, "Length must equal to 5"),
//  state: Yup.string().required("State/Province is required!"),
//  streetAddress: Yup.string().required('Address is required!')
});

export default PhysicianProfileCompletionValidation;
