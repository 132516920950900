import reduxActionType from "../../models/reduxActionType";
import SettingsItem from "../../models/settingsresponse.model";
import { FETCH_SETTINGS, FETCH_SETTINGS_ERROR, FETCH_SETTINGS_SUCCESS, LOGOUT, UPDATE_SETTINGS, UPDATE_SETTINGS_ERROR, UPDATE_SETTINGS_SUCCESS } from "../Actions/types";

const initialState: {
  fetching: boolean
  error: boolean
  updating: boolean
  updateSuccess: boolean
  updateError: boolean
  data: SettingsItem[]
} = {
  fetching: false,
  error: false,
  updateError: false,
  updateSuccess: false,
  updating: false,
  data: []
}

function SettingsReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case FETCH_SETTINGS: {
      return {
        ...state,
        fetching: true,
        error: false
      }
    }

    case FETCH_SETTINGS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        data: action.payload
      }
    }

    case FETCH_SETTINGS_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true,
      }
    }

    case UPDATE_SETTINGS: {
      return {
        ...state,
        updating: true,
        updatingError: false,
        updatingSuccess: false
      }
    }

    case UPDATE_SETTINGS_SUCCESS: {
      let temp = [...state.data]
      let updatedData = []

      if (state.data.length > 0) {
        updatedData = temp.map((setting: SettingsItem) => {
          if (setting.type === action.payload.type) {
            return { ...setting, ...action.payload }
          }
          return setting
        })
      } else {
        updatedData = [{ ...action.payload }]
      }

      return {
        ...state,
        updating: false,
        updatingError: false,
        updatingSuccess: true,
        data: updatedData
      }
    }

    case UPDATE_SETTINGS_ERROR: {
      return {
        ...state,
        updating: false,
        updatingError: true,
        updatingSuccess: false,
      }
    }

    case LOGOUT: {
      return initialState
    }

    default:
      return state
  }
}

export default SettingsReducer