import http from "../API/HttpMethods";
import {
 FETCH_ACTIVE_TESTS,
 FETCH_ACTIVE_TESTS_ERROR,
 FETCH_ACTIVE_TESTS_SUCCESS,
} from "./types";

const fetchActiveTests = (options: any) => async (dispatch: any) => {
 const { config, onError, onEmpty } = options;
 dispatch({ type: FETCH_ACTIVE_TESTS });

 try {
  const result = await http.getActiveTests("v1/test-type/get-active-tests");
  const { statusCode, data, error } = result;

  if (error) {
   onError(error);
   return dispatch({ type: FETCH_ACTIVE_TESTS_ERROR });
  }

  if (data.length == 0 && onEmpty) {
   onEmpty();
  }

  dispatch({ type: FETCH_ACTIVE_TESTS_SUCCESS, payload: data });
 } catch (error: any) {
  dispatch({ type: FETCH_ACTIVE_TESTS_ERROR });
  const { response } = error;
  if (response?.data) {
   onError(response?.data?.message);
  } else {
   onError(error?.message);
  }
 }
};

const ActiveTestsActions = {
 fetchActiveTests,
};

export default ActiveTestsActions;
