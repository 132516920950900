import { useEffect } from "react";
import NotificationsSettingsTable from "./NotificationsSettingsTable";
import HeaderColumnData from "../../models/notificationsSettingsTableModels/headerColumnData.model";
import BodyColumnData from "./BodyColumnData.model";
import { connect, useDispatch } from "react-redux";
import NotificationActions from "../../Store/Actions/UserNotificationActions";
import NotificationSettingsServices, { ApiMediumValue, ApiOrganizationMediumSettingsValue } from "../../services/NotificationSettingsServices";
import { ActionArgs } from "../../Store/Actions/types";
import { getFilteredValues } from "../../utils/helpers/enableDisableNotificationHelper";

interface OrganizationsSettingsTableProps {
  tableColumns: HeaderColumnData[]
  organizations: any
  fetching: boolean
  error: boolean
  mediumValues: ApiMediumValue[]
  organizationValues: ApiOrganizationMediumSettingsValue[]
  tableBody: Array<BodyColumnData[]>
  setTableBody: (data: Array<BodyColumnData[]>) => void
  setTableColumns: (data: HeaderColumnData[]) => void
  fetchNotificationMedium: (options: ActionArgs) => any
  fetchOrganizationNotificationMedium: (options: ActionArgs) => any
}

function OrganizationsSettingsTable({
  tableColumns,
  tableBody,
  setTableBody,
  setTableColumns,
  organizations,
  mediumValues,
  organizationValues,
  fetching,
  error,
  fetchNotificationMedium,
  fetchOrganizationNotificationMedium
}: OrganizationsSettingsTableProps): JSX.Element {
  function handleSubmit() {
    let data: any = []
    tableBody.forEach((item, index) => {
      item.forEach((subData) => {
        if (Array.isArray(subData.data)) {
          let organisation = item[index].data
          let values = subData.data

          data.push({ ...organisation, values })
        }
      })
    })
  }
  const NotificationsSettingsData = new NotificationSettingsServices(organizations, mediumValues, organizationValues)
  const dispatch = useDispatch()
  function handleSelectAll(id: string | number, value?: boolean) {
    setTableBody(NotificationsSettingsData.handleSelectAll(id, tableColumns, tableBody,dispatch, value).tableBody)
    setTableColumns(NotificationsSettingsData.handleSelectAll(id, tableColumns, tableBody,dispatch, value).tableColumn)
  }

  useEffect(() => {
    NotificationsSettingsData.createOrganizationsSettingsData()
    setTableColumns(NotificationsSettingsData.getOrganizationsSettingsData().tableColumn)
    setTableBody(NotificationsSettingsData.getOrganizationsSettingsData().tableBody)
  }, [])
  
  const filteredValues = getFilteredValues(tableBody);
  
  return <NotificationsSettingsTable
    tableColumns={tableColumns}
    onSelectAll={handleSelectAll}
    tableBody={tableBody}
    setTableBody={setTableBody}
    isEnabled={filteredValues}
  />
}
const mapStateToProps = (state: any) => ({
  fetching: state.UserNotifications.fetching,
  error: state.UserNotifications.error,
  organizations: state.Organizations.data,
  mediumValues: state.UserNotifications.notificationMediums,
  organizationValues: state.UserNotifications.organizationNotificationMediums
})

const mapDispatchToProps = {
  fetchNotificationMedium: NotificationActions.fetchNotificationMedium,
  fetchOrganizationNotificationMedium: NotificationActions.fetchOrganizationNotificationMedium
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsSettingsTable)