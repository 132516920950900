import { useEffect, useState } from "react"
import CustomInput from "./CustomInput"
import Button from "../atomic/Button"
import { connect, useDispatch } from "react-redux"
import { useOktaAuth } from "@okta/okta-react"
import { LOGOUT, TOGGLE_SESSION_STATE } from "../../Store/Actions/types"
import NotificationActions from "../../Store/Actions/NotificationsActions"
import toastMessages from "../../utils/helpers/toastMessage"
import http from "../../Store/API/HttpMethods"
import ProfileActions from "../../Store/Actions/ProfileActions"
import moment from "moment"
// import socket from "../../server"

function TermsAndConditionsPopup({ updateProfile, fetchprofile, profile, onlyDownload,id }: any): JSX.Element {
  const dispatch = useDispatch()
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false)
  const { oktaAuth, authState } = useOktaAuth();
  const [fetchingDocument, setFetchingDocument] = useState(true)
  const [documentLink, setDocumentLink] = useState<any>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  async function handleViewURL() {

    const config = {
      headers: {
        Authorization: `Bearer ${authState?.accessToken?.accessToken}`
      }
    }
    setFetchingDocument(true)
    try {
      const { data } = await http.viewSOF(id==='isAltheaDxNeuropsychotropicMedications' ? 'v1/documents?type=idg-mapping':'v1/documents', config)
      const termOfUse = data?.find((file: any) => file?.id === 1)
      if (id==='isAltheaDxNeuropsychotropicMedications' && data[0]?.blobUrl) {
        setDocumentLink(data[0]?.blobUrl)
      } else if(termOfUse?.blobUrl){
        setDocumentLink(termOfUse?.blobUrl)
      }
      setFetchingDocument(false)
    } catch (error: any) {
      toastMessages.error('Failed to open Terms and Conditions')
      setFetchingDocument(false)
    }
  }

  const logout = async () => {
    try {
      const response = await oktaAuth.signOut();
      dispatch({ type: LOGOUT, payload: true });
      dispatch({ type: TOGGLE_SESSION_STATE, payload: true })
      dispatch(NotificationActions.hideNotifications())
      // setTimeout(() => {
      //   socket.disconnect()
      // }, 1000)
    } catch (error) {
      // console.log(error)
    }
  };

  function handleAgreement() {
    setSubmitting(true)
    const apiData = {
      accessToken: authState?.accessToken?.accessToken,
      firstName: profile.firstName,
      email_id: profile.email,
      lastName: profile.lastName,
      acceptTerms: termsAgreed,
      acceptTermsDate: moment().format("MM-DD-YYYY"),
    };

    const onSuccess = (message: string) => {
      setSubmitting(false)
      dispatch(NotificationActions.hideNotifications())
      const options = {
        accessToken: authState?.accessToken?.accessToken,
        onSuccess: () => {
          // console.log("success");
        },
        onError: () => {
          // alert("Something went wrong");
        },
      };
      fetchprofile(options);
    }

    updateProfile({
      data: apiData,
      onSuccess,
      onError: (message: any) => {
        setSubmitting(false)
        toastMessages.error(message ?? 'Terms agreement updation failed')
      }
    })
  }
  useEffect(() => {
    handleViewURL()
  }, [])

  return <div className='my-2 block'>
    {!fetchingDocument && !!documentLink && <embed
      width={250}
      height={250}
      src={documentLink}
      className="h-[80vh] w-full object-contain"
    />}
    {fetchingDocument && <div className="flex h-[30vh] w-full justify-center items-center">
      <div className='animate-spin'>
        <img src={require('../../assets/images/loadingPNG.png')} className={'h-[50px] w-[50px]'} />
      </div>
    </div>}
    {!!fetchingDocument === false && !!onlyDownload === false && <div className="flex justify-between items-center my-2">
      <CustomInput
        id={'I have agreed to the Terms of Use.'}
        key={'I have agreed to the Terms of Use.'}
        name={'agreement'}
        label={'I have agreed to the Terms of Use.'}
        placeholder={''}
        errors={{}}
        checked={termsAgreed}
        required={false}
        disabled={submitting}
        withoutForm
        touched={{}}
        onChange={(e: any) => {
          setTermsAgreed(e.target.checked)
        }}
        type='checkbox'
        className='my-1'
        styleCheckbox
      />
      <div className="flex items-center">
        <Button
          btnType={'secondary'}
          title="Cancel"
          disabled={termsAgreed || submitting}
          onClick={logout}
          className="mx-3"
        />
        <Button
          btnType={'primary'}
          title="Ok"
          disabled={termsAgreed === false || submitting}
          submitting={submitting}
          onClick={handleAgreement}
        />
      </div>
    </div>}
    {onlyDownload && <Button
      btnType={'secondary'}
      title="Close"
      disabled={termsAgreed || submitting}
      onClick={() => dispatch(NotificationActions.hideNotifications())}
      className="mt-3"
    />}
  </div>
}

const mapStateToProps = (state: any) => ({
  profile: state.Profile.profile,
})

const mapDispatchToProps = {
  updateProfile: ProfileActions.updateUser,
  fetchprofile: ProfileActions.fetchprofile,
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsPopup)