import * as React from "react"
import { SVGProps } from "react"
const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.811}
    height={17.811}
    {...props}
  >
    <g
      fill="none"
      stroke="#edeff1"
      strokeWidth={1.5}
      data-name="search icon"
      transform="translate(3419.75 10508.75)"
    >
      <circle
        cx={6}
        cy={6}
        r={6}
        data-name="Ellipse 725"
        transform="translate(-3419 -10508)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m-3403-10492-5.333-5.333"
        data-name="Path 5354"
      />
    </g>
  </svg>
)
export default SearchIcon
