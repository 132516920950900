import store from "..";
import http from "../API/HttpMethods";
import {
 RESPONSE_FAIL,
 RESPONSE_SUCCESS,
 SUBMIT_FORM,
 TOGGLE_FORM_SUBMIT,
 SET_INVITE_MEMBERS,
 RESET_INVITE_MEMBERS,
} from "./types";
import { removeExtraSpace } from "../../utils/helpers/removeExtraSpace";

interface optionsType {
 data: any;
 accessToken: any;
 onSuccess: (message: any) => any;
 onError: (message: any) => any;
}

const submitForm = (options: optionsType) => async (dispatch: any) => {
    const { data: requestData, accessToken, onSuccess, onError } = options;
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
  
    dispatch({ type: SUBMIT_FORM });
    const { members }: any = store.getState().InviteUser;
  
    // Apply removeExtraSpace to each field in requestData
    const apiData = Object.fromEntries(
      Object.entries(requestData).map(([key, value]) => {
        if (typeof value === "string") {
          return [key, removeExtraSpace(value)];
        }
        return [key, value];
      })
    );
  
    apiData.role = requestData.role === "PathLab User" ? "PathLab User" : requestData.role;
  
    if (requestData.role === "Office Manager") {
      apiData.physicianIds = members.map((member: any) => member.id);
    } else {
      delete apiData.physicianIds;
    }
  
    try {
      const result = await http.inviteUser("v1/users/invite", apiData, config);
  
      const { success, error, data, message, statusCode } = result;
  
      if (error && onError) {
        onError(error);
        dispatch({ type: RESPONSE_FAIL });
      }
  
      dispatch({ type: RESPONSE_SUCCESS });
      dispatch({ type: TOGGLE_FORM_SUBMIT, payload: false });
      dispatch({ type: RESET_INVITE_MEMBERS });
  
      // console.log({ data })
      onSuccess && onSuccess(message);
    } catch (error: any) {
      const { data: errorData } = error?.response;
      const message = errorData ? errorData.message : !!error;
      dispatch({ type: RESPONSE_FAIL });
      onError && onError(message);
    }
  };
const setMembers = (members: boolean) => ({
 type: SET_INVITE_MEMBERS,
 payload: members,
});

const resetMembers = () => ({ type: RESET_INVITE_MEMBERS });

const InviteUserActions = {
 submitForm,
 setMembers,
 resetMembers,
};

export default InviteUserActions;
