import reduxActionType from '../../models/reduxActionType'
import physicianToClinician from '../../utils/helpers/physicianToClinician'
import {
  FETCHING_STATE_ERROR,
  FETCH_PORTAL_USERS,
  FETCH_PORTAL_USERS_SUCCESS,
  FETCH_PORTAL_USER_BY_ID,
  FETCH_PORTAL_USER_BY_ID_ERROR,
  FETCH_PORTAL_USER_BY_ID_SUCCESS,
  FETCH_PORTAL_USERS_BY_ORG,
  FETCH_PORTAL_USERS_BY_ORG_SUCCESS,
  FETCH_PORTAL_USERS_BY_ORG_ERROR,
  UPDATE_PORTAL_USER,
  UPDATE_PORTAL_USER_ERROR,
  UPDATE_PORTAL_USER_SUCCESS,
  UPDATE_SELECTED_USER_STAUTS,
  LOGOUT,
  FETCH_PORTAL_USERS_ERROR,
  FETCH_PORTAL_USER_OA,
  FETCH_PORTAL_USER_OA_SUCCESS,
  RESET_SELECTED_PORTAL_USER
} from '../Actions/types'
interface state {
  fetching: boolean
  error: boolean
  data: any[]
  selectedUser: any
  fetchUserError: boolean,
  fetchUserOAError: boolean
  fetchingUserOA: boolean
  fetchingUser: boolean,
}
const initialState: state = {
  fetching: true,
  error: false,
  fetchUserError: false,
  fetchingUser: true,
  fetchingUserOA: true,
  fetchUserOAError: false,
  data: [],
  selectedUser: null
}

function PortalUsersReducer(state = initialState, action: reduxActionType) {
  switch (action.type) {
    case FETCH_PORTAL_USERS: {
      return {
        ...state,
        fetching: true,
        error: false
      }
    }
    case FETCH_PORTAL_USERS_BY_ORG: {
      return {
        ...state,
        fetching: true,
        error: false
      }
    }
    case FETCH_PORTAL_USERS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        data: [
          ...action.payload.sort((a: any, b: any) => {
            let fullNameA = `${a?.firstName} ${a?.lastName}`
            let fullNameB = `${b?.firstName} ${b?.lastName}`

            if (fullNameA?.toLowerCase() < fullNameB?.toLowerCase()) {
              return -1
            }

            if (fullNameA?.toLowerCase() > fullNameB?.toLowerCase()) {
              return 1
            }

            return 0
          }).map((user: any) => {
            let validStatuses = ['Pending', 'Active', 'Blocked']
            return {
              id: user.id,
              onRowClick: (id: number, navigate: any) => {
                // if (user?.roles?.includes('PathLab User')/*  || user?.roles?.includes('Collaborative Ordering Agreement') */)
                //   return
                navigate.push(`/manage/portal-users/profile/${id}`)
              },
              data: {
                // imsi: 3641741342,
                name: {
                  value: `${user?.firstName ?? ''} ${user?.lastName ?? ''
                    }`,
                  img: user?.imageUrl ?? ''
                },

                email: user?.email ?? 'N/A',
                // role: roles,
                // organizations,
                // status: user?.status ?? 'Blocked'
                // role: user?.roles ?? 'N/A',
                // role: user?.roles ? user?.roles?.includes('Physician') ? user?.roles?.map((item: string) => item.replace('Physician', 'Clinician')) : user?.roles ?? 'N/A' : 'N/A',
                role: physicianToClinician(user?.roles),
                // role: user?.roles ?? 'N/A',
                pathLab: user?.pathLab ?? 'N/A',
                status: !!user?.status ? validStatuses.includes(user.status) ? user.status : 'Pending' : 'Pending',
              },
              // threeDotsActions: [
              //   {
              //     label: 'block',
              //     onClick: () => {
              //       // console.log(`block user id ${user.id}`)
              //     }
              //   }
              // ],
              check: false
            }
          })
        ]
        // data: [
        //   ...action.payload.sort((a: any, b: any) => {
        //     let fullNameA = `${a?.profile.firstName} ${a?.profile?.lastName}`
        //     let fullNameB = `${b?.profile?.firstName} ${b?.profile?.lastName}`

        //     if (fullNameA?.toLowerCase() < fullNameB?.toLowerCase()) {
        //       return -1
        //     }

        //     if (fullNameA?.toLowerCase() > fullNameB?.toLowerCase()) {
        //       return 1
        //     }

        //     return 0
        //   }).map((user: any) => {
        //     let validStatuses = ['Pending', 'Active', 'Blocked']
        //     // let organizations = ''
        //     // let roles = ''

        //     // // if (user?.profile?.roles) {
        //     // //   user?.profile?.roles.map((role: any) => {
        //     // //     roles = roles + `${role}`
        //     // //   })
        //     // // }

        //     // // if (user?.profile?.organizations) {
        //     // //   user?.profile?.organizations.map((organization: any) => {
        //     // //     organizations = organizations + `${organization.name},`
        //     // //   })
        //     // // }
        //     return {
        //       id: user.id,
        //       onRowClick: (id: number, navigate: any) => {
        //         // console.log(navigate)
        //         // navigate('/dashboard', { replace: true })
        //         // if (user?.roles?.includes('PathLab User') /* || user?.roles?.includes('Collaborative Ordering Agreement') */)
        //         //   return
        //         navigate.push(`/manage/portal-users/profile/${id}`)
        //       },
        //       data: {
        //         // imsi: 3641741342,
        //         name: {
        //           value: `${user?.firstName ?? ''} ${user?.lastName ?? ''
        //             }`,
        //           img: user?.imageUrl ?? ''
        //         },

        //         email: user?.email ?? '',
        //         // role: roles,
        //         // organizations,
        //         // status: !!user?.status ? user?.status : 'Pending'
        //         role: user?.roles ?? 'N/A',
        //         pathLab: user?.pathLab ?? 'N/A',
        //         status: !!user?.status ? validStatuses.includes(user.status) ? user.status : 'Pending' : 'Pending',
        //       },
        //       threeDotsActions: [
        //         {
        //           label: 'block',
        //           onClick: () => {
        //             // console.log(`block user id ${user.id}`)
        //           }
        //         }
        //       ],
        //       check: false
        //     }
        //   })
        // ]
      }
    }
    case FETCH_PORTAL_USERS_BY_ORG_SUCCESS: {
      return {
        ...state,
        fetching: false,
        error: false,
        data: [
          ...action.payload.sort((a: any, b: any) => {
            let fullNameA = `${a?.firstName} ${a?.lastName}`
            let fullNameB = `${b?.firstName} ${b?.lastName}`

            if (fullNameA?.toLowerCase() < fullNameB?.toLowerCase()) {
              return -1
            }

            if (fullNameA?.toLowerCase() > fullNameB?.toLowerCase()) {
              return 1
            }

            return 0
          }).map((user: any) => {
            let validStatuses = ['Pending', 'Active', 'Blocked']
            return {
              id: user.id,
              onRowClick: (id: number, navigate: any) => {
                // if (user?.roles?.includes('PathLab User')/*  || user?.roles?.includes('Collaborative Ordering Agreement') */)
                //   return
                navigate.push(`/manage/portal-users/profile/${id}`)
              },
              data: {
                // imsi: 3641741342,
                name: {
                  value: `${user?.firstName ?? ''} ${user?.lastName ?? ''
                    }`,
                  img: user?.imageUrl ?? ''
                },

                email: user?.email ?? 'N/A',
                // role: roles,
                // organizations,
                // status: user?.status ?? 'Blocked'
                // role: user?.roles ?? 'N/A',
                role: physicianToClinician(user?.roles),
                pathLab: user?.pathLab ?? 'N/A',
                status: !!user?.status ? validStatuses.includes(user.status) ? user.status : 'Pending' : 'Pending',
              },
              // threeDotsActions: [
              //   {
              //     label: 'block',
              //     onClick: () => {
              //       // console.log(`block user id ${user.id}`)
              //     }
              //   }
              // ],
              check: false
            }
          })
        ]
      }
    }
    case FETCHING_STATE_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true
      }
    }
    case FETCH_PORTAL_USERS_BY_ORG_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true
      }
    }
    case FETCH_PORTAL_USER_BY_ID: {
      return {
        ...state,
        fetchUserError: false,
        fetchingUser: true,
      }
    }
    case FETCH_PORTAL_USER_BY_ID_SUCCESS: {
      return {
        ...state,
        fetchUserError: false,
        fetchingUser: false,
        selectedUser: {
          ...state?.selectedUser,
          ...action.payload,
          sofTable: action?.payload?.user?.sofs && action?.payload?.user?.sofs?.length > 0 ? action.payload.user.sofs.map((sof: any) => ({
            id: sof.id,
            onRowClick: (id: number, navigate: any) => { },
            data: {
              'Test Form': sof.testForm ? sof.testForm : 'N/A',
              status: sof.status,
              SOF: sof
            }
          })) : [],
          tableData: action?.payload?.organizationsRolesAndSOF && action?.payload?.organizationsRolesAndSOF?.length > 0 ? action?.payload?.organizationsRolesAndSOF?.map((organisation: any) => ({
            id: organisation.id,
            onRowClick: (id: number, navigate: any) => {
              // console.log(navigate)
              // navigate('/dashboard', { replace: true })
              // navigate.push(`/manage/portal-users/profile/${id}`)
            },
            data: {
              organisation: organisation.name,
              // roles: organisation?.roles?.includes('Physician') ? organisation?.roles?.map((item: string) => item.replace('Physician', 'Clinician')) : organisation.roles,
              roles: physicianToClinician(organisation?.roles),
              SOF: organisation.SOF ? organisation.SOF : 'N/A'
            },
            check: false
          })) : []
        }
      }
    }
    case FETCH_PORTAL_USER_BY_ID_ERROR: {
      return {
        ...state,
        fetchUserError: true,
        fetchingUser: false,
      }
    }
    case FETCH_PORTAL_USER_OA: {
      return {
        ...state,
        fetchingUserOA: true,
        fetchUserOAError: false
      }
    }
    case FETCH_PORTAL_USER_OA_SUCCESS: {
      return {
        ...state,
        fetchingUserOA: true,
        fetchUserOAError: false,
        selectedUser: {
          ...state?.selectedUser,
          orderAgreementTable: action.payload && action.payload?.length > 0 ? action.payload?.map((item: any) => ({
            id: item.pathLabId,
            onRowClick: (id: number, navigate: any) => {
              // console.log(navigate)
              // navigate('/dashboard', { replace: true })
              // navigate.push(`/manage/portal-users/profile/${id}`)
            },
            data: {
              organisation: item?.pathLab?.name,
              // roles: item?.pathLab?.roles ? item.pathLab.roles : 'N/A',
              OA: {
                id: item?.id,
                blobUrl: item?.blobUrl,
                testForm: item?.testForm,
                expiryDate: item?.expiryDate,
                userId: item?.userId
              }
            },
            check: false
          })) : []
        }
      }
    }
    case UPDATE_PORTAL_USER: {
      return {
        ...state,
        updating: true,
        updateError: false
      }
    }
    case UPDATE_SELECTED_USER_STAUTS: {
      return {
        ...state,
        selectedUser: {
          ...state.selectedUser,
          status: action.payload
        }
      }
    }
    case UPDATE_PORTAL_USER_ERROR: {
      return {
        ...state,
        updating: false,
        updateError: true
      }
    }
    case UPDATE_PORTAL_USER_SUCCESS: {
      return {
        ...state,
        updating: false,
        updateError: false
      }
    }
    case FETCH_PORTAL_USERS_ERROR: {
      return {
        ...state,
        fetching: false,
        error: true,
      }
    }
    case RESET_SELECTED_PORTAL_USER: {
      return {
        ...state,
        selectedUser: null
      }
    }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export default PortalUsersReducer
