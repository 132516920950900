import { Component, ChangeEvent, createRef, Fragment } from 'react'
import linkIcon from '../../assets/Icon/Link-icon.svg'
import SuggestionsDropDown from '../organism/SuggstionsDropdown'
import './style.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import FilterClearIcon from '../../assets/svg/FilterClearIcon'
import TableFilterDropdown from '../TestOrders/TableFilterDropdown'
import FilterIcon from '../../assets/svg/FilterIcon'
import { useLocation } from 'react-router-dom'


export type TextInputCustomValidator = (
  inputValue: string,
  setErrorMsg: (errorMsg: string) => void
) => void
interface ValidationRecord {
  regex: RegExp
  message: string
}

type ValidationType = 'number' | 'string' | 'domain' | 'ip' | 'digits'

interface TextInputProps {
  usersListDebounce?: any
  variant?: 'compact' | 'boxed'
  statusShipment?: boolean
  label: string
  type?:
  | 'number'
  | 'password'
  | 'string'
  | 'domain'
  | 'ip'
  | 'ref'
  | 'digits'
  | 'blob'
  | 'pc'
  | 'date'
  placeholder?: string
  instructions?: string
  multiSelect?: boolean
  disableSuggestiveFiltering?: boolean
  preventAutoSearch: boolean
  suggestions?: any
  disabled?: boolean
  required?: boolean
  handleInput?: (input: string | any[]) => void
  handleMultiSelect?: (index: number) => void
  customValidator?: TextInputCustomValidator
  value?: string
  hasError?: (hasError: boolean) => void
  errorMsg?: string
  hideIds?: boolean
  portalUserHeader?: any
}

interface TextInputState {
  errorMsg: string
  suggestionValue?: any
  suggestionVisible?: any
  suggestions?: any
  inputValue?: string
  dateValue?: string
  validations: Record<ValidationType | string, ValidationRecord>
  debounceTimer?: any
}

export default class TextInput extends Component<
  TextInputProps,
  TextInputState
> {
  dropDownRef: any
  constructor(props: TextInputProps) {
    super(props)
    this.dropDownRef = createRef()
    const { type = 'string', errorMsg, value, multiSelect, suggestions } = this.props

    this.state = {
      errorMsg: errorMsg || '',
      suggestionValue: '',
      inputValue: value ?? '',
      dateValue: '',
      suggestionVisible: false,
      suggestions: [...suggestions],
      validations: {
        number: {
          regex: /^-?[0-9]+(?:\.[0-9]+)?$/,
          message: 'Value must be integer'
        },
        domain: {
          regex:
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
          message: 'Invalid host address'
        },
        ip: {
          regex: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/,
          message: 'Enter valid IP address'
        },
        digits: {
          regex: /\b[0-9A-Fa-f]+\b/,
          message: 'Must be a valid hex value'
        },
        string: {
          regex: /^(?!\s*$).+/,
          message: 'Enter a valid ' + type
        }
      },
      debounceTimer: null,
    }
  }
  componentDidMount() {
    const { hasError, suggestions } = this.props
    const { errorMsg } = this.state
      ; (hasError != null) && hasError(!!errorMsg)

    let selectedStatuses = suggestions.filter((suggestion: any) => suggestion.selected)
    let inputValues = ''

    selectedStatuses.forEach((item: any) => {
      if (inputValues === '') {
        inputValues = `${item.label}`
      } else
        inputValues += `${item.label},`
    })

    this.setState({ inputValue: inputValues, })
    this.setState({ suggestionValue: inputValues })
  }

  componentDidUpdate(prevProps: TextInputProps, prevState: TextInputState) {
    const { errorMsg } = this.state
    const { errorMsg: prevError, suggestions: prevSuggestions } = prevProps
    const { errorMsg: currentError, hasError, suggestions: currentSuggestions } = this.props

    if (!!currentError && currentError !== prevError) { this.setState({ errorMsg: currentError }) }

    if (prevSuggestions.length !== currentSuggestions.length) { this.setState({ suggestions: currentSuggestions }) }

    (hasError != null) && hasError(!!errorMsg)
  }

  setErrorMessage = (errorMsg: string) => {
    this.setState({ errorMsg })
  }

  processValueAccordingToType(inputValue: string) {
    const { validations } = this.state
    const { type = 'string', customValidator } = this.props

    if (customValidator != null) {
      customValidator(inputValue, this.setErrorMessage)
      return
    }

    const key: string = !validations[type] ? 'string' : type
    const { regex, message } = validations[key]
    let errorMsg = ''
    if (inputValue && !regex.test(inputValue)) {
      errorMsg = message
    }
    this.setState({ errorMsg })
  }

  handleDate = (date: string) => {
    const { handleInput } = this.props

    if (handleInput != null) handleInput(date)
    this.setState({ dateValue: date })

  }
  handleMultiSelect(index: number) {
    const { handleInput, handleMultiSelect } = this.props
    const { suggestions } = this.state

    !!handleMultiSelect && handleMultiSelect(index)

    if (handleInput != null) {

      if (index !== -1) {
        let currentSuggestions = suggestions.map((item: any, itemIndex: number) => {
          if (itemIndex === index) {
            return {
              ...item,
              selected: !item.selected
            }
          }
          return {
            ...item
          }
        })

        let selectedStatuses = currentSuggestions.filter((suggestion: any) => suggestion.selected)
        let inputValues = ''

        selectedStatuses.forEach((item: any) => {
          if (inputValues === '') {
            inputValues = `${item.label}`
          } else
            inputValues += `${item.label},`
        })

        handleInput(selectedStatuses.map((item: any) => item.value))

        this.setState({ inputValue: inputValues, })
        this.setState({ suggestionValue: inputValues, suggestions: currentSuggestions })
      } else {
        handleInput('')
        this.setState({ inputValue: '' })
        this.setState({ suggestionValue: '' })
        this.setState({ suggestions: suggestions.map((item: any) => ({ ...item, selected: false })) })
      }
    }
  }
  processInput = (event: any, handleInput: any) => {
    if (event?.target == undefined) {
      this.processValueAccordingToType(event);
      if (handleInput != null) {
        handleInput(event);
        this.setState({ inputValue: event, suggestionValue: event });
      }
    } else {
      const { value } = event?.target;
      this.processValueAccordingToType(value);
      if (handleInput != null) {
        handleInput(value);
        this.setState({ inputValue: value });
      }
    }
  };

  handleInput = (event: any) => {
    const { handleInput, usersListDebounce } = this.props;

    if (usersListDebounce) {
      // Apply debouncing only when usersListDebounce is true
      clearTimeout(this.state.debounceTimer);
      const newDebounceTimer = setTimeout(() => {
        this.processInput(event, handleInput);
      }, 300); // Adjust the debounce time as needed
      this.setState({ debounceTimer: newDebounceTimer });
    } else {
      // No debouncing, process input immediately
      this.processInput(event, handleInput);
    }
  };

  handleSuggestionChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.currentTarget

    if (!this.state.suggestionVisible || value !== '') { this.setState({ suggestionVisible: true }) }

    if (value == '') {
      this.setState({ suggestionVisible: false })
    }

    if (this.props.preventAutoSearch === false)
      this.handleInput(e)

    this.setState({ suggestionValue: value, inputValue: value })
  }

  render() {
    const { errorMsg, suggestionValue, dateValue, suggestionVisible, inputValue } = this.state
    const {
      variant = 'compact',
      label,
      placeholder,
      type = 'string',
      instructions,
      disabled,
      required,
      value,
      multiSelect,
      disableSuggestiveFiltering,
      statusShipment
    } = this.props
    const { suggestions } = this.state
    const currentPath = window.location.pathname
    
    return (
      <div className="text-input-container">
        <div className={'input-container ' + variant}>
          <div className="input-label">
            <p className="input-label__text">
              {label} {required && <span className="asterisk">*</span>}
            </p>
          </div>
          <div onClick={() => { this.setState({ suggestionVisible: !suggestionVisible }) }} className={'input-wrapper' + type}>
            <div ref={this.dropDownRef} className={'icon-and-input-field-wrapper'}>
              {/* <img className="link-icon" src={linkIcon} alt="link icon" /> */}

              {type === 'date' ? <div className='flex items-center justify-between border-b focus:ring-transparent focus:border-darkGray focus:!outline-none !w-full'>
                <DatePicker
                  selected={!!value ? new Date(value) : null}
                  placeholderText={'MM/DD/YYYY'}
                  maxDate={new Date()}
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                  showYearDropdown
                  // value={dateValue}
                  dateFormat={'MM/dd/yyyy'}
                  onChangeRaw={(event) => {
                    if (event.target.value === '') {
                      this.handleDate('')
                    }
                  }}
                  className={
                    'input' + (errorMsg ? ' has-error' : '') + (' ' + type) + 'placeholder:text-borderGray pr-6 placeholder:font-normal focus:ring-transparent focus:border-darkGray focus:!outline-none !border-b-0'
                  }
                  onChange={(date: Date) => {
                    if (date === null) {
                      this.handleDate('')
                    } else {
                      this.handleDate(moment(date).format('MM/DD/YYYY'))
                    }
                  }}
                />{dateValue && <button className='w-5 h-5 text-white absolute right-2 pl-6 z-20 font-bold z-999 text-xs' onClick={(e: any) => {
                  e.stopPropagation()
                  this.handleDate('')
                }}><FilterClearIcon />
                </button>}
              </div> : <div className={`${(statusShipment && currentPath === '/shipments/unshipped-orders') && 'w-[88%]'} flex items-center justify-between border-b focus:ring-transparent focus:border-darkGray focus:!outline-none`}>
          
                <input
                  type={type === 'password' ? type : 'text'}
                  disabled={disabled}
                  required={required}
                  className={
                    'input' + (errorMsg ? ' has-error' : '') + (' ' + type) + ' placeholder:text-borderGray mr-2 placeholder:font-normal !border-b-0'
                  }
                  placeholder={placeholder}
                  onChange={suggestions && suggestions.length > 0 ? this.handleSuggestionChange.bind(this) : this.handleInput}
                  // value={suggestionValue ? suggestionValue : value}
                  value={suggestionValue ? suggestionValue : inputValue}
                />
                {inputValue && <button className='w-5 h-5 text-white right-0 z-20 top-0 bottom-0 font-bold z-999 text-xs' style={{ zIndex: 2000 }} onClick={(e: any) => {
                  e.stopPropagation()
                  this.handleInput('')
                  multiSelect && this.handleMultiSelect ? this.handleMultiSelect(-1) : null
                }}><FilterClearIcon />
                </button>}
              </div>
              }
              {suggestions && suggestions.length > 0 && <Fragment>
                {multiSelect ? <TableFilterDropdown
                  title={'Hide/Unhide Columns'}
                  Icon={FilterIcon}
                  hideHeader
                  suggestionValue={suggestionValue}
                  suggestionVisible={suggestionVisible}
                  hideSuggestion={() => this.setState({ suggestionVisible: false })}
                  onItemClick={this.handleMultiSelect.bind(this)}
                  containerStyle=""
                  list={suggestions}
                /> : <SuggestionsDropDown
                  hideIds={this.props.hideIds ?? true}
                  dropDownRef={this.dropDownRef}
                  suggestionValue={suggestionValue}
                  suggestionVisible={suggestionVisible}
                  suggestions={suggestions}
                  disableSuggestiveFiltering={disableSuggestiveFiltering}
                  containerStyle={'top-5 py-3'}
                  itemStyle={'border-b-0 font-normal hover:bg-sideMenuHoverBg'}
                  onChange={this.handleInput}
                  setSuggestionValue={(val: any) => this.setState({ suggestionValue: suggestions.find((suggestion: any) => suggestion.value === val).label })}
                  setSuggestionVisible={(val: any) => this.setState({ suggestionVisible: val })}
                  portalUserHeader
                />
                }</Fragment>}
            </div>

            {errorMsg && <p className="error-message">{errorMsg}</p>}

            {instructions && <p className="instructions">{instructions}</p>}
          </div>
        </div>
      </div>
    )
  }
}
