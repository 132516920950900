import { motion } from "framer-motion";
import CheckMarkSVG from "../../assets/svg/CheckMarkSVG";
import { connect } from "react-redux";
import SignUpFormActions from "../../Store/Actions/SignUpFormActions";
import { useHistory } from "react-router-dom";
import Button from "../../components/atomic/Button";

function VerifyEmail({ message }: any) {
  const history = useHistory();

  function handleMain() {
    history.replace("/");
  }
  
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col h-full justify-center items-center"
    >
      <CheckMarkSVG />
      <div className="w-4/6 md:w-2/6 text-center p-5">
        {/* <p className="text-royalBlue font-medium">
          {message ? message : 'User and Organization has been created successfully, please check your email for further instructions.'}
        </p> */}
        <p className="text-royalBlue font-medium">
          Thank you for submitting your request. An email will be shared with you
          to confirm that we have received your request. You will be notified
          when your account is ready in the next 24 to 48 hours.
        </p>
        <div className="flex justify-center w-full items-center my-5">
          <Button
            btnType="secondary"
            title={"Back to Main Screen"}
            onClick={handleMain}
          />
        </div>
      </div>
    </motion.section>
  );
}

const mapStateToProps = (state: any) => ({
  message: state.SignUpFormValues.message,
});

const mapDispatchToProps = {
  signUp: SignUpFormActions.signUpUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
